<form #f="ngForm"(ngSubmit)="f.valid && updateSubTask()"
  *blockUI="'container-blockui-edit-subtask'">
  <div class="modal-header">
    <h5 class="modal-title">Edit Sub Task</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row"
      [ngClass]="{ 'validation-error' : f.submitted && unitId.invalid}">
      <label class="col-md-2">Unit:*</label>
      <div class="col-md-10">
          <select name="unitId" class="form-control" maxlength="1000"
            [(ngModel)]="subTask.unitId" #unitId="ngModel" rows="7"
            required [disabled]="!!workOrderUnitId || subTask.disableUnitEdit">
            <option *ngFor="let unit of units" value="{{unit.id}}">{{unit.name}}</option>
          </select>
          <div class="validation-error-message" *ngIf="f.submitted && unitId.invalid">
              <span *ngIf="unitId.errors.required">Unit is required.</span>
          </div>
      </div>
    </div>
    <div class="form-group row"
        [ngClass]="{ 'validation-error' : f.submitted && task.invalid}">
        <label class="col-md-2">Task:*</label>
        <div class="col-md-10">
            <textarea name="task" class="form-control" maxlength="1000"
                [(ngModel)]="subTask.task" #task="ngModel" rows="7"
                required>
              </textarea>
            <div class="validation-error-message" *ngIf="f.submitted && task.invalid">
                <span *ngIf="task.errors.required">Task is required.</span>
            </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col-md-12 text-right">
      <input type="submit" 
        class="btn btn-primary m-r-5"
        value="Submit" />
      <button type="button" 
        class="btn btn-default m-r-5"
        (click)="activeModal.dismiss()">
        Close
      </button>
    </div>
  </div>
</form>
