import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-company-configuration-tile',
    templateUrl: './company.configuration.tile.component.html'
})
export class CompanyConfigurationTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;

    ngOnInit() {
        setTimeout(() => {
            this.isModelLoaded = true;
        }, 2000);
    }
}
