export class WorkOrderNoteModel {
    public id: number;
    public workOrderId: number;
    public note: string;
    public createdOn: string;
    public createdById: string;
    public createdByName: string;
    public createdByRole: string;
    public userReadReceipts: { id: string, fullName: string }[];
}
