import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { StoredgeConfigurationModel, SyncSingleModel } from '../models';

@Injectable()
export class StoredgeService {
    constructor(private http: HttpClient) { }

    getFacilities(companyId: string, internalCompanyId: number) {
        return this.http.get(environment.apiBaseUrl + 'storedge/get-facilities/' + companyId + '/' + internalCompanyId);
    }

    getStoredgeCompanyId(internalCompanyId: number) {
        return this.http.get(environment.apiBaseUrl + 'storedge/get-storedge-company-id/' + internalCompanyId);
    }

    configureCompany(model: StoredgeConfigurationModel) {
        return this.http.post(environment.apiBaseUrl + 'storedge/configure-company', model);
    }

    synchronizeFacilities(internalCompanyId: number) {
        return this.http.post(environment.apiBaseUrl + 'storedge/synchronize-facilities/' + internalCompanyId, null);
    }

    synchronizeUnits(facilityId: string) {
        return this.http.post(environment.apiBaseUrl + 'storedge/synchronize-units/' + facilityId, null);
    }

    importSingle(model: SyncSingleModel) {
        return this.http.post(environment.apiBaseUrl + 'storedge/import-single', model);
    }
}
