<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-kiosk-detail [companyId]="companyId"> </app-breadcrumb-kiosk-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Overview
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-primary m-r-5" (click)="editTag()" *ngIf="showActionColumn">
                                Edit
                            </button>
                            <button type="button" class="btn btn-primary" (click)="applyChangesToKiosk()" *ngIf="showActionColumn && isDataLoaded && model.applicationType != 4">
                                Apply Changes
                            </button>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-delete-2 h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Tag</h5>
                                                <p class="card-text">{{model.tag}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-buildings h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Facility</h5>
                                                <p class="card-text">{{model.facilityName}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-globe h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Registration Key</h5>
                                                <p class="card-text">{{model.registrationKey}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-map-location h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Setup Location</h5>
                                                <p class="card-text">{{model.facilityAddress}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-calendar-1 h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Last Updated</h5>
                                                <p class="card-text">{{model.updatedOn}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-app h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Application Enabled</h5>
                                                <p *ngIf="model.applicationType == 0" class="card-text">None</p>
                                                <p *ngIf="model.applicationType == 1" class="card-text">Move In</p>
                                                <p *ngIf="model.applicationType == 2" class="card-text">Signage</p>
                                                <p *ngIf="model.applicationType == 3" class="card-text">Move In /
                                                    Signage</p>
                                                <p *ngIf="model.applicationType == 4" class="card-text">Call Machine</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-confetti h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Status</h5>
                                                <p>
                                                    <span class="badge badge-success" *ngIf="model.isOnline">
                                                        Online
                                                    </span>
                                                    <span class="badge badge-danger" *ngIf="!model.isOnline">
                                                        Offline
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="container py-3">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <em class="kt-menu__link-icon flaticon-calendar-2 h1 p-2"></em>
                                        </div>
                                        <div class="col-md-8 col-lg-9 pl-0">
                                            <div class="card-block">
                                                <h5 class="card-title m-0">Registered On</h5>
                                                <p class="card-text">{{model.createdOn}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isDataLoaded && model.applicationType != 4">
        <div class="col-6">
            <app-kiosk-detail-move-in #moveInDetail [companyId]="companyId" [facilityId]="facilityId"
                [kioskId]="model.id" (refreshKioskDetail)="loadKioskDetail()">
            </app-kiosk-detail-move-in>
        </div>
        <div class="col-6">
            <app-kiosk-detail-signage #signageDetail [companyId]="companyId" [facilityId]="facilityId"
                [kioskId]="model.id" (refreshKioskDetail)="loadKioskDetail()">
            </app-kiosk-detail-signage>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" *ngIf="isDataLoaded && model.applicationType != 4">
        <div class="row">
            <div class="col-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Logs
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <a [routerLink]="['/kiosk/activity-log', companyId, facilityId, model.id]"
                                class="btn btn-label-primary btn-sm btn-bold" *ngIf="model.activityLogs">View All
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body" data-scroll="true" data-height="300" data-mobile-height="200">
                        <app-kiosk-activity-log-item [activityLogs]="model.activityLogs">
                        </app-kiosk-activity-log-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-kiosk-edit-tag #kioskEditTagModal (change)="saveTag($event)">
</app-kiosk-edit-tag>