import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import {
    WorkOrderAddModel, WorkOrderEditModel, WorkOrderNoteModel, WorkOrderReportIssueModel,
    WorkOrderFilterModel, WorkOrderUpdatePriorityModel, WorkOrderCategoryAddModel, WorkOrderUpdateWorkOrderStatusModel, NotificationsPreferences, WorkOrderCategoryEditModel, WorkOrderDetailModel, WorkOrderListItemModel
} from '../models';

import { Observable } from 'rxjs';

@Injectable()
export class WorkOrderService {
    constructor(private http: HttpClient) { }

    uploadPhoto(file: any) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(environment.apiBaseUrl + 'workOrder/upload-photo', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    deletePhoto(fileKey: string) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/delete-photo/' + fileKey, fileKey);
    }

    add(model: WorkOrderAddModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/add', model);
    }

    edit(model: WorkOrderEditModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/edit', model);
    }

    assignMe(id: number) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/assign-to-me/' + id,
            {headers: new HttpHeaders().set('x-api-version', '2.0')});
    }

    udpatePriority(model: WorkOrderUpdatePriorityModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/update-priority', model);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-detail/' + id, 
            {headers : new HttpHeaders().set('x-api-version', '2.0')});
    }

    getDetailForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-detail-for-edit/' + id,
            {headers : new HttpHeaders().set('x-api-version', '2.0')});
    }

    getSummary(id: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-summary/' + id);
    }

    getCountByCompany(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company/${companyId}/${considerOnlyPersonalData}`);
    }

    getCountByCompanyNotStarted(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company-not-started/${companyId}/${considerOnlyPersonalData}`);
    }

    getCountByCompanyInProgress(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company-in-progress/${companyId}/${considerOnlyPersonalData}`);
    }

    getCountByCompanyOnHold(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company-on-hold/${companyId}/${considerOnlyPersonalData}`);
    }

    getCountByCompanyCompleted(companyId: number) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company-completed/${companyId}`);
    }

    getCountByCompanyComplaint(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}workOrder/get-count-by-company-complaint/${companyId}/${considerOnlyPersonalData}`);
    }

    getCountOfCompanyWorkOrderCategories(companyId: number, onlyActive: boolean) {
        return this.http.get<number>(
            `${environment.apiBaseUrl}workOrder/get-count-of-company-work-order-categories/${companyId}/${onlyActive}`);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/delete/' + id, null);
    }

    getActivityLogs(workOrderId: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-activity-logs/' + workOrderId);
    }

    getNotes(workOrderId: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-notes/' + workOrderId);
    }

    addNote(model: WorkOrderNoteModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/add-note', model);
    }

    editNote(model: WorkOrderNoteModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/edit-note', model);
    }

    deleteNote(noteId: number) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/delete-note/' + noteId, null);
    }

    reportIssue(model: WorkOrderReportIssueModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/report-issue', model);
    }

    filter(model: WorkOrderFilterModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/filter', model);
    }

    addLocalCategory(model: WorkOrderCategoryAddModel) {
        return this.http.post(environment.apiBaseUrl + 'WorkOrder/add-local-category',model);
    }

    editLocalCategory(model: WorkOrderCategoryEditModel) {
        return this.http.post(environment.apiBaseUrl + 'WorkOrder/edit-local-category',model);
    }

    getLocalCategory(companyId: number, categoryId: number):Observable<WorkOrderCategoryEditModel> {
        return this.http.get<WorkOrderCategoryEditModel>(environment.apiBaseUrl + `WorkOrder/${companyId}/local-category/${categoryId}`);
    }    

    addGlobalCategory(model: WorkOrderCategoryAddModel) {
        return this.http.post(environment.apiBaseUrl + 'WorkOrder/add-global-category', model);
    }

    toggleCategoryStatus(id: number, companyId?: number) {
        return this.http.post(`${environment.apiBaseUrl}WorkOrder/toggle-category-status/${id}/${companyId}`, null);
    }

    toggleCategoryFollow(id: number, companyId?: number) {
        return this.http.post(`${environment.apiBaseUrl}WorkOrder/toggle-category-follow/${id}/${companyId}`, null);
    }

    getCategorySelectListItems(companyId: number, includeInactiveHavingWorkOrders: boolean,
        considerOnlyOpenWorkOrdersElseClosed: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}WorkOrder/get-category-select-list-items/` +
            `${companyId}/${includeInactiveHavingWorkOrders}/${considerOnlyOpenWorkOrdersElseClosed}`);
    }

    getGlobalCategorySelectListItems(onlyActive: boolean, excludeDefault?: boolean, companyId?: number) {
        return this.http.get(`${environment.apiBaseUrl}WorkOrder/get-selected-global-categories-select-list-items?` +
            `companyId=${companyId}&excludeDefault=${excludeDefault}&onlyActive=${onlyActive}`);
    }


    saveSelectedGlobalCategories(companyId: number, selectedGlobalCategoriesIds: number[]) {
        return this.http.post(`${environment.apiBaseUrl}WorkOrder/select-global-categories`, {
            companyId,
            selectedGlobalCategoriesIds
        });
    }

    deleteLocalCategory(id: number, companyId: number) {
        return this.http.post(`${environment.apiBaseUrl}workOrder/delete-local-category/${id}/${companyId}`, null);
    }

    udpateWorkOrderStatus(model: WorkOrderUpdateWorkOrderStatusModel) {
        return this.http.post(environment.apiBaseUrl + 'workOrder/update-workorder-status', model);
    }

    getStatus(id: number) {
        return this.http.get(environment.apiBaseUrl + 'workOrder/get-status/' + id);
    }

    saveNotificationsPreferences(notificationsPreferences: NotificationsPreferences) {
        return this.http.post(`${environment.apiBaseUrl}workOrder/set-notifications-preferences`, notificationsPreferences);
    }

    markNoteAsRead(workOrderId: number, noteId: number) {
        return this.http.get(`${environment.apiBaseUrl}workOrder/notes/mark-note-as-read/${workOrderId}/${noteId}`);
    }

    getOpenWorkOrdersDetailByIds = (companyId: number, workOrderIds: number[]) =>
        this.http.post<WorkOrderListItemModel[]>(`${environment.apiBaseUrl}workOrder/get-work-orders-detail-by-ids/${companyId}`, workOrderIds);

    assignNewAssigneeToWorkOrders = (companyId: number, assigneeId: string, workOrderIds: number[]) =>
        this.http.post(`${environment.apiBaseUrl}workOrder/assign-new-assignee-to-work-orders/${companyId}/${assigneeId}`, workOrderIds);
}
