import { RecordStatus } from "../user-group/record.status";

export class FacilityAssignedToUserGroupModel {
    public id: number;
    public name: string;
    public status: RecordStatus;
    public defaultAssigneeId: string;
    public defaultAssigneeCustomerComplaints: string;
    public defaultAssigneeMoveOut: string;
    public defaultAssigneeMoveIn: string;
    public userGroupIds: string[];
}

export class ValidateUserGroupModel {
    singleUserGroupAssignedFactilities: FacilityAssignedToUserGroupModel[];
    multipleUserGroupAssignedFactilities: FacilityAssignedToUserGroupModel[];
}
