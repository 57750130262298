import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { VendorService, FacilityService } from '../../../services';
import { VendorDetailModel, VendorImageUploadModel, SelectListItemModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { isNullOrUndefined } from '../../../helpers/tools';

@Component({
    selector: 'app-vendor-detail',
    templateUrl: './vendor.detail.component.html'
})

export class VendorDetailComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('addVendorNote', { static: true }) addVendorNote: any;
    model: VendorDetailModel = new VendorDetailModel();
    imageUploadModel = new VendorImageUploadModel();
    isModelLoaded = false;
    showActionColumn = false;
    companyId = -1;
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedFacilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private facilityService: FacilityService,
        private vendorService: VendorService) {

        this.roleMatrix = new RoleMatrix();
        this.imageUploadModel.canEditProfileImage = this.roleMatrix.canAddVendor;

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.model.companyId = params['companyId'];
            this.model.id = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.showActionColumn
            = this.roleMatrix.canEditVendor || this.roleMatrix.canDeleteVendor;
    }

    ngOnInit(): void {
        this.loadVendor();
        this.loadFacilities();
    }

    loadFacilities() {
        this.blockUI.start();
        this.facilityService.getSelectListItems(this.companyId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.facilities = data;
                    this.updateSelectedFacilities();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }


    loadVendor() {
        this.blockUI.start();
        this.vendorService.getDetail(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                    this.model.notes.forEach(note => {
                        if (!isNullOrUndefined(note.createdByRole) && this.appUtils.is10FedUser(note.createdByRole)) {
                            note.createdByName = 'Admin';
                        }
                        note.createdOn = this.appUtils.getFormattedDateTimeFull(note.createdOn);
                    });

                    this.imageUploadModel.vendorId = this.model.id;
                    this.imageUploadModel.fileUrl = this.model.profileImageUrl;
                    this.isModelLoaded = true;
                    if (this.model.status === 3) {
                        this.roleMatrix.configureAllowActionsForVendor();
                        this.imageUploadModel.canEditProfileImage = false;
                    }
                    this.updateSelectedFacilities();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateSelectedFacilities() {
        if (!this.facilities
            || this.facilities.length === 0
            || !this.model.assignedFacilities
            || this.model.assignedFacilities.length === 0) {
            return;
        }

        const tempArr = new Array<SelectListItemModel>();
        this.facilities.forEach(item => {
            if (this.model.assignedFacilities.find(m => m == +item.id)) {
                tempArr.push(item)
            }
        });
        this.selectedFacilities = tempArr;
    }

    delete(): void {
        if (!confirm('Are you sure you want to delete the selected vendor?')) {
            return;
        }
        this.blockUI.start();
        this.vendorService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.router.navigate(['/vendor/manage', this.model.companyId]);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Vendor has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    openVendorNoteModal() {
        this.addVendorNote.openVendorNoteModal();
    }
}
