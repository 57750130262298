import { Component, HostBinding, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { DataTableResponseModel, WorkOrderFilterModel, WorkOrderSelectedListItemModel } from '../../../models';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';

@Component({
  selector: 'app-wo-bulk-changes',
  templateUrl: './wo-bulk-changes.component.html',
  styleUrls: ['./wo-bulk-changes.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WoBulkChangesComponent implements OnInit, OnDestroy {

  roleMatrix: RoleMatrix;

  @HostBinding('class.full_width')
  full_width = true;
  
  @BlockUI('container-blockui')
  blockUI: NgBlockUI;
  
  companyId = -1;
  viewType = '1';
  selectedWorkOrderIds: Set<number>;
  isOnStep1 = true;

  subscriptions: Subscription[] = [];

  constructor(private router: Router,
      private route: ActivatedRoute,
      public appUtils: AppUtils) {

    this.roleMatrix = new RoleMatrix();

    this.route.params.subscribe((params) => {
        this.companyId = params['id'];
        this.viewType = params['viewType'];
    });

    if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
        this.router.navigate(['/error/404']);
    }

    this.selectedWorkOrderIds = new Set();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  showWorkOrdersList() {
    this.isOnStep1 = true;
    window.scrollTo(0, 0);
  }

  showBulkOperationScreen(workOrderIds: Set<number>) {
    this.selectedWorkOrderIds = workOrderIds;
    this.isOnStep1 = false;
    window.scrollTo(0, 0);
  }
}
