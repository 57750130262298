export class FacilityDetailModel {
    public id: number;
    public companyId: number;
    public name: string;
    public address: string;
    public zipCode: string;
    public propertyManagerUserId: string;
    public propertyManagerName: string;
    public propertyManagerEmail: string;
    public propertyManagerPhone: string;
    public clientUserGroupsAssigned: string[];
    public clientUserGroupsAssignedNames: string[];
    public clientUsersAssigned: Array<string>;
    public climateControlled: boolean;
    public typeOfConstruction: string;
    public defaultAssigneeId: string;
    public complaintsAssigneeId: string;
    public moveOutAssigneeId: string;
    public moveInAssigneeId: string;
    public complaintsAssigneeName: string;
    public moveOutAssigneeName: string;
    public moveInAssigneeName: string;
    public unitsAssociated: number;
    public totalWorkOders: number;
    public unitType: string;
    public amenityType: string;
    public isImported: boolean;
    public storedgeId: string;
    public status: number;
    public isFollowing: boolean;
    public canFollowUser: boolean;
    public phone: number;
    public facilityNoteDetails: FacilityNoteDetailModel;
}

export class FacilityNoteDetailModel {
    public id: number;
    public note: string;
    public createdBy: string;
    public createdByName: string;
    public createdOn: string;
}
