import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breacrumb-company-user-manage',
    templateUrl: './breadcrumb.company.user.manage.component.html'
})

export class BreadcrumbCompanyUserManageComponent {
    roleMatrix: RoleMatrix;

    @Input() companyId: number;
    @Input() filterUserType: string;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}

