export class FacilityEditModel {
    public id: number;
    public companyId: number;
    public name: string;
    public address: string;
    public zipCode: string;
    public propertyManagerUserId: string;
    public propertyManagerName: string;
    public propertyManagerEmail: string;
    public propertyManagerPhone: string;
    public typeOfConstruction: string;
    public climateControlled: boolean;
    public clientUserGroupsAssigned: Array<string>;
    public clientUsersAssigned: Array<string>;
    public defaultAssigneeId: string;
    public defaultAssigneeName: string;
    public complaintsAssigneeId: string;
    public complaintsAssigneeName: string;
    public moveOutAssigneeId: string;
    public moveOutAssigneeName: string;
    public moveInAssigneeId: string;
    public moveInAssigneeName: string;
    public status: number;
    public phone:string;

    constructor() {
        this.complaintsAssigneeId = '';
        this.moveOutAssigneeId = '';
        this.moveInAssigneeId = '';
        this.propertyManagerUserId = '';
    }
}
