export class KioskFilterModel {
    public companyId: number;    
    public facilityIds: Array<string>;
    public applicationIds: Array<string>;
    public filterKey: string;
    
    constructor() {
        this.facilityIds = new Array<string>();
        this.applicationIds = new Array<string>();
    }
}
