<div class="kt-portlet kt-portlet--mobile" *blockUI="'container-blockui-category-list'">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 m-t-15 text-right">
                <a [routerLink]="['/work-order-comp-categories', companyId, 'add']" class="btn btn-primary">Add New Category</a>               
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="row">
                <div class="col-md-12">
                    <table datatable [dtOptions]="dtOptions" data-page="2" class="table table-hover break-all">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>