export class WorkOrderCategoryAddModel {
    public name: string;
    public companyId: number;
    public dueDateDuration: number;
    public workOrderPriority: any;
    public isUnrentable: boolean;

    constructor() {
        this.workOrderPriority = ''; 
    }
}

export class WorkOrderCategoryEditModel {
    public id: number;
    public name: string;
    public companyId: number;
    public dueDateDuration: number;
    public workOrderPriority: any;
    public isUnrentable: boolean;   
    public isDefault: boolean;   
    public type: number; 
}