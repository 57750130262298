import { Component, OnInit, Input } from '@angular/core';
import { VendorService } from '../../../../services';
@Component({
    selector: 'app-company-vendor-tile',
    templateUrl: './company.vendor.tile.component.html'
})

export class CompanyVendorTileComponent implements OnInit {
    @Input() companyId: number;

    isModelLoaded = false;
    totalVendors = 0;
    constructor(private vendorService: VendorService) {

    }

    ngOnInit(): void {
        this.vendorService.getCountByCompany(this.companyId, false)
            .subscribe(
                (data: number) => {
                    this.isModelLoaded = true;
                    this.totalVendors = data;
                },
                error => {
                    console.log('Vendors count failed');
                    console.log(error);
                });
    }
}
