export * from './add/kiosk.add.component';
export * from './detail/kiosk.detail.component';
export * from './manage/kiosk.manage.component';
export * from './detail-movein/kiosk.detail.move.in.component';
export * from './detail-signage/kiosk.detail.signage.component';
export * from './offline-log/kiosk.offline.log.component';
export * from './offline-timestamp/kiosk.offline.timestamp.component';

export * from './breadcrumb/manage/breadcrumb.kiosk.manage.component';
export * from './breadcrumb/add/breadcrumb.kiosk.add.component';
export * from './breadcrumb/detail/breadcrumb.kiosk.detail.component';
export * from './breadcrumb/offline-log/breadcrumb.kiosk.offline.log.component';
