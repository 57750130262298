export class JiveCommunicationModel {
    public clientId:string;
    public secretKey:string;
    public internalCompanyId: number;
    public JiveCommunicationConfigured: boolean;
    public IsAuthorized: boolean;
    constructor() {
        this.JiveCommunicationConfigured = false;
        this.IsAuthorized = false;
    }
}