<div class="kt-subheader__breadcrumbs" *ngIf="roleMatrix.is10FedUser">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/manage']" class="kt-subheader__breadcrumbs-link">
        Companies </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/detail',companyId]" class="kt-subheader__breadcrumbs-link">
        Detail </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company-user/manage-administrator']"
        [queryParams]="{companyId: companyId, userType: filteredUserType}" class="kt-subheader__breadcrumbs-link"
        *ngIf="filteredUserType === 'admin'">
        Administrators
    </a>
    <a [routerLink]="['/company-user/manage-personnel']"
        [queryParams]="{companyId: companyId, userType: filteredUserType}" class="kt-subheader__breadcrumbs-link"
        *ngIf="filteredUserType === 'user'">
        Personnel
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>Detail</span>
</div>
<div class="kt-subheader__breadcrumbs" *ngIf="!roleMatrix.is10FedUser && filteredUserType !== 'any'">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/detail',companyId]" class="kt-subheader__breadcrumbs-link">
        Company </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company-user/manage-administrator']" [queryParams]="{companyId: companyId}"
        class="kt-subheader__breadcrumbs-link" *ngIf="filteredUserType === 'admin'">
        Administrators
    </a>
    <a [routerLink]="['/company-user/manage-personnel']" [queryParams]="{companyId: companyId}"
        class="kt-subheader__breadcrumbs-link" *ngIf="filteredUserType === 'user'">
        Personnel
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>Detail</span>
</div>
<div class="kt-subheader__breadcrumbs" *ngIf="!roleMatrix.is10FedUser && filteredUserType === 'any'">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company-user/manage']" [queryParams]="{companyId: companyId}"
        class="kt-subheader__breadcrumbs-link">
        Users
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>Detail</span>
</div>