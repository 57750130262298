import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataSource } from 'src/models/kpi-report/datasource.list.model';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ReportQuery, SelectListItemModel} from '../../../models';
import {AppUtils} from '../../../helpers';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-kpi-report-report',
  templateUrl: './kpi-report.report.component.html',
  styleUrls: ['./style.css']
})
export class ReportComponent implements OnInit {
  @BlockUI('loader-blockui')loaderUI:NgBlockUI;
  datasources: DataSource[];
  multiSelectDropdownConfigs = {};
  public columnsDetails: object = [];

  reportQueryModel:ReportQuery = new ReportQuery();
 
  datacolumn:Array<SelectListItemModel> = new Array<SelectListItemModel>();
  selectedDataColumns:Array<SelectListItemModel> = new Array<SelectListItemModel>();

  companyId: number;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  maxDate={
        year:new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),   
  }

  constructor(private kpiReportService: KpiReportService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private apputlis:AppUtils) {
      
      this.route.params.subscribe((params) => {
        this.companyId = Number(params['companyId']);
      });
  
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 0);
      
      this.multiSelectDropdownConfigs = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };

  }
  ngOnInit():void  {
    this.loaderUI.start();
    this.doDataSourceList();
    setTimeout(() => {
      this.loaderUI.stop(); // Stop blocking
    }, 2000);
    this.reportQueryModel.datasource=0;
  }
  disableDate(){
    return false;
  }  

private doDataSourceList(){
    this.kpiReportService.getdatasourceList().subscribe(data=>{
      this.datasources=data; 
     })
  }

onSelectDatasource(id:number) {  
    let obj = this.datasources.find(m => m.id == id);  
    this.reportQueryModel.datasource=id;
    this.selectedDataColumns = [];
    this.datacolumn =( obj.dataColumns==null || obj.dataColumns.length <= 0  ? []: obj.dataColumns.map(({columnId,columnName})=>({
      id:columnId.toString(),
      name:columnName,
      key:columnId.toString(),
      value:columnName
    })));  
    return this.columnsDetails;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
   } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isUIDisabled(date:NgbDate){
    return date.after(this.maxDate)
  }
  
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  
  kpiReports(){
    this.reportQueryModel.startdate = this.apputlis.getFormattedDate(new Date(this.fromDate.year,this.fromDate.month-1,this.fromDate.day).toUTCString());
    this.reportQueryModel.enddate = this.apputlis.getFormattedDate(new Date(this.toDate.year,this.toDate.month-1,this.toDate.day).toUTCString());

    this.loaderUI.start();
    this.kpiReportService.downloadReports(this.companyId,this.reportQueryModel)
    .subscribe(response=>{
       this.downloadFile(response);
       this.loaderUI.stop();
       this.toastr.success('Generate a Reports Successfully !!');
    }, error => {
      this.loaderUI.stop();
      this.toastr.error('Generate a Reports Un Successfully !!');
    });
  }
  
  downloadFile(file:any){
    const blob = new Blob([file],{type: '.xlsx' })
    let obj = this.datasources.find(m => m.id == this.reportQueryModel.datasource); 
    var date = new Date();
    const fileSaver = new File([blob], obj.name.trim()+this.fromDate.day +"_"+this.fromDate.month +"_"+this.fromDate.year+" To " +this.toDate.day + "_"+ this.toDate.month+"_"+ this.toDate.year+'.xlsx', { type: 'application/vnd.ms.excel' });
    saveAs(fileSaver);

  }  

  onItemSelect(item:any)
  {
      const index:number = this.reportQueryModel.datacolumn.indexOf('0');
      if(index!== -1){
        this.reportQueryModel.datacolumn.splice(index,1);

      }
      this.reportQueryModel.datacolumn.push(item.id.toString());
  }

  onItemDeSelect(item:any)
  {
    this.reportQueryModel.datacolumn = [];
    this.selectedDataColumns.forEach((item)=>{
      this.reportQueryModel.datacolumn.push(item.id.toString())
    })
  }

  onSelectAll()
  {
    this.reportQueryModel.datacolumn = [];
    this.reportQueryModel.datacolumn.push('0');
  }
  
  resetFields()
  {
    
    this.selectedDataColumns=[];
    this.reportQueryModel.datasource=0;
    this.reportQueryModel.datacolumn=[];
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.datacolumn=[];
    this.toastr.info("Resetting the data !!");
    
  }
}

