<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
            <div class="col-12" *ngIf='model.actionType === 1'>
                <p class="kt-widget3__text">
                    Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    has been created by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12 m-l-2" *ngIf='model.actionType === 2'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Title'">
                    Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    renamed by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>

                <p class="kt-widget3__text" *ngIf="model.fieldName === 'WorkOrderStatus'">
                    Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    <span *ngIf="model.oldValue !== 'Completed'">
                        status changed from
                        <app-work-order-status-text [workOrderStatus]="model.oldValue"></app-work-order-status-text>
                        to
                        <app-work-order-status-text [workOrderStatus]="model.newValue"></app-work-order-status-text>
                    </span>
                    <span *ngIf="model.oldValue === 'Completed'">
                        has been re-opened
                    </span>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>

                <p class="kt-widget3__text" *ngIf="model.fieldName === 'WorkOrderPriority'">
                    <span *ngIf="model.oldValue && model.newValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        priority changed from
                        <app-work-order-priority-text [workOrderPriority]='model.oldValue'>
                        </app-work-order-priority-text>
                        to
                        <app-work-order-priority-text [workOrderPriority]='model.newValue'>
                        </app-work-order-priority-text>
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                    <span *ngIf="model.newValue && !model.oldValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        priority changed to
                        <app-work-order-priority-text [workOrderPriority]='model.newValue'>
                        </app-work-order-priority-text>
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                    <span *ngIf="model.oldValue && !model.newValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        priority changed from
                        <app-work-order-priority-text [workOrderPriority]='model.oldValue'>
                        </app-work-order-priority-text>
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'WorkOrderCategory'">
                    <span *ngIf="model.oldValue && model.newValue">
                        Work Order
                        <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        category changed from
                        {{model.oldValue}} to {{model.newValue}}
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                    <span *ngIf="model.newValue && !model.oldValue">
                        Category has been added to
                        <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                    <span *ngIf="!model.newValue && model.oldValue">
                        Category has been removed from
                        <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        by
                        <app-activity-log-author [model]="model"> </app-activity-log-author>
                    </span>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Description'">
                    Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    description has been changed by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'FacilityId'">
                    Work Order
                    <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    moved to facility:
                    <a [routerLink]="[model.newValueLink]">{{model.newValue}} </a>
                    from
                    <a [routerLink]="[model.oldValueLink]">{{model.oldValue}} </a>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'UnitId'">
                    Work Order
                    <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    moved to unit:
                    <a [routerLink]="[model.newValueLink]">{{model.newValue}} </a>
                    from
                    <a [routerLink]="[model.oldValueLink]">{{model.oldValue}} </a>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'AssignedToName'">
                    <span *ngIf="model.oldValue && model.newValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        assignee has been changed from
                        <a [routerLink]="[model.oldValueLink]" [queryParams]="model.oldValueParams">
                            {{model.oldValue}} </a>
                        to
                        <a [routerLink]="[model.newValueLink]" [queryParams]="model.newValueParams">
                            {{model.newValue}} </a>
                    </span>
                    <span *ngIf="model.newValue && !model.oldValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        has been assigned to
                        <a [routerLink]="[model.newValueLink]" [queryParams]="model.newValueParams">
                            {{model.newValue}} </a>
                    </span>
                    <span *ngIf="!model.newValue && model.oldValue">
                        Work Order <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                        assignee has been removed from
                        <a [routerLink]="[model.oldValueLink]" [queryParams]="model.oldValueParams">
                            {{model.oldValue}} </a>
                    </span>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'WorkOrderNote'">
                    A new note has been added to <a [routerLink]="model.entityLink"> {{model.entityId}} </a>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === 3'>
                <p class="kt-widget3__text">
                    Work Order {{model.entityId}}
                    has been deleted by <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
        </div>
    </div>
</div>