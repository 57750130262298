import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breacrumb-company-user-edit',
    templateUrl: './breadcrumb.company.user.edit.component.html'
})

export class BreadcrumbCompanyUserEditComponent {
    roleMatrix: RoleMatrix;

    @Input() companyId: number;
    @Input() filteredUserType: string;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
