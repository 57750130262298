import { Component, HostBinding, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { MoveInService } from 'src/services';
import { NgForm } from '@angular/forms';
import { MoveinAmenityAddModel } from 'src/models';
import { SelectListItemModel } from 'src/models';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'app-movein-amenity-add',
    templateUrl: './movein.amenity.add.component.html'
})

export class MoveinAmenityAddComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-amenity-add') blockUI: NgBlockUI;
    @Output() added = new EventEmitter();

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    model: MoveinAmenityAddModel = new MoveinAmenityAddModel();
    amenityTypes: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    icon: any;
    iconUploadPercent = 0;

    amenityIcon: any;

    constructor(private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private moveInService: MoveInService) {

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];

            this.model.companyId = this.companyId;
            this.model.facilityId = this.facilityId;
            this.model.kioskId = this.kioskId;
        });

        this.roleMatrix = new RoleMatrix();
    }

    ngOnInit() {
        this.loadAmenityTypes();
    }

    loadAmenityTypes() {
        this.blockUI.start();
        this.moveInService.getAmenityTypes(this.facilityId, this.kioskId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.amenityTypes, data);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onIconSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }
        this.amenityIcon = event.target.files.item(0);
        // this.model.originalIconName = this.amenityIcon.name;
    }

    deleteIcon() {
        this.model.originalIconName = null;
    }

    submit(f: NgForm) {
        this.blockUI.start();
        this.uploadIcon(f);
    }

    uploadIcon(f: NgForm) {
        this.moveInService.uploadIcon(this.amenityIcon)
            .subscribe(
                (e: HttpEvent<any>) => {
                    switch (e.type) {
                        case HttpEventType.UploadProgress:
                            const percentDone = Math.round(100 * e.loaded / e.total);
                            this.iconUploadPercent = percentDone;
                            break;
                        case HttpEventType.Response:
                            this.model.iconName = e.body.fileName;
                            this.addAmenity(f);
                            break;
                    }
                },
                (error) => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    addAmenity(f: NgForm) {

        this.model.companyId = this.companyId;
        this.model.facilityId = this.facilityId;
        this.model.kioskId = this.kioskId;

        this.moveInService.addAmenity(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        f.resetForm();
                        this.model = new MoveinAmenityAddModel();
                        this.toastr.success('Amenity has been added successfully.');
                        this.loadAmenityTypes();
                        this.added.emit();
                    }, 10);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
