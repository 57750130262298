import { Component, HostBinding, ViewChild, OnInit } from '@angular/core';
import { CompanyWoCategoryListComponent } from '../list/company.wo.category.list.component';
import { RoleMatrix } from 'src/helpers';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-comp-wo-category-manage',
    templateUrl: './company.wo.category.manage.component.html'
})
export class CompanyWoCategoryManageComponent  implements OnInit{
    activeTab: string;
    companyId: number;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('categoryList') CategoryListComponent: CompanyWoCategoryListComponent;

    constructor(private route: ActivatedRoute,
        private router: Router) {
        this.companyId = -1;
        const roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
        });

        this.route.queryParams.subscribe(params => {
            if (params['activeTab']) {
                this.activeTab = params['activeTab']
            } else {
                this.activeTab = '1';
            }
        });

        if (!roleMatrix.is10FedUser && roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit(): void {
        
    }

    onTabChange(item: { activeId: string, nextId: string }) {
        this.activeTab = item.nextId;
    }
}
