<ng-template #workOrderRecurrenceModal let-modal>
    <form class="kt-form form-horizontal" name="form" (ngSubmit)="submit(f)" #f="ngForm" novalidate>
        <div *blockUI="'container-blockui-recurrance-popup'">
            <div class="modal-header">
                <h5 class="modal-title">Set Custom Recurrence</h5>
                <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="kt-radio-list">
                            <div class="form-group">
                                <label class="kt-radio kt-radio--brand">
                                    <input type="radio" name="frequencyRadio" [(ngModel)]='model.frequency' [value]="2"
                                        (change)="onFrequencyChange()">
                                    Set weekly recurrence
                                    <span></span>
                                </label>
                            </div>
                            <div class="form-group">
                                <label class="kt-radio kt-radio--brand">
                                    <input type="radio" name="frequencyRadio" [(ngModel)]='model.frequency' [value]="3"
                                        (change)="onFrequencyChange()">
                                    Set monthly recurrence
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="model.frequency == 2">
                    <div class="form-group row"
                        [ngClass]="{ 'validation-error' : f.submitted && repeatEvery.invalid && repeatEveryNWeekRequired}">
                        <label class="col-3 control-label">Repeat Every:</label>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-4">
                                    <select class="form-control" name="repeatEvery" [(ngModel)]="repeatEveryNWeek"
                                        #repeatEvery="ngModel" required>
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of repeatEveryNWeekSelectList" [value]="item.value">
                                            {{item.id}}</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <label>week</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div>
                                        Select the interval of weeks when the workorder will recurr
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && repeatEvery.invalid && repeatEveryNWeekRequired">
                                        Repeat frequency is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-3 control-label">Repeats On:</label>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-12">
                                    <button type="button"
                                        class="btn {{model[item.field] ? 'btn-primary' : 'btn-secondary'}} m-r-15 rounded-day-button "
                                        *ngFor="let item of selectWeekDayList"
                                        (click)="model[item.field] = !model[item.field];">
                                        {{item.display}}</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div>
                                        Select the days of week when workorder will recurr
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && !anyRepeatWeekDaySelected">
                                        Repeat On is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="model.frequency == 3">

                    <div class="form-group row"
                        [ngClass]="{ 'validation-error' : f.submitted && repeatEveryNMonthField.invalid && repeatEveryNMonthFieldRequired}">
                        <label class="col-3 control-label">Repeat Every:</label>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-4">
                                    <select class="form-control" name="repeatEveryNMonthField"
                                        [(ngModel)]="repeatEveryNMonth" #repeatEveryNMonthField="ngModel" required>
                                        <option *ngFor="let item of repeatEveryNMonthSelectList" [value]="item.value">
                                            {{item.id}}</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <label>month</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div>
                                        Select the interval of months when the workorder will recurr
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && repeatEveryNMonthField.invalid && repeatEveryNMonthFieldRequired">
                                        Repeat monthly frequency is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row"
                        [ngClass]="{ 'validation-error' : f.submitted && !anyRepeatMonthSelected}">
                        <label class="col-3 control-label">Repeats On:</label>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-4">
                                    <ng-multiselect-dropdown name="repeatDay" [placeholder]="'[Select]'"
                                        [(ngModel)]="selectedRepeatEveryMonthOn"
                                        [settings]="multiSelectDropdownConfigs"
                                        [data]="repeatEveryMonthOnSelectList">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div>
                                        Select the day when the workorder will be automatically created every month
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && !anyRepeatMonthSelected">
                                        Repeat day is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && terminateOn.invalid}">
                    <label class="col-3 control-label">Terminate On:</label>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-6">
                                <div class="input-group date">
                                    <input class="form-control" placeholder="MM/DD/YYYY" name="terminateOn"
                                        [(ngModel)]="model.terminateOn" ngbDatepicker #d="ngbDatepicker"
                                        #terminateOn="ngModel" (focus)="d.open()" autocomplete="off"
                                        [minDate]="minTerminateOnDate" />
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer" (click)="d.toggle()">
                                            <i class="la la-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div>
                                    Select the date when recurring workorder will terminate
                                </div>
                                <div class="validation-error-message" *ngIf="f.submitted && terminateOn.invalid">
                                    <span> Terminatation date is invalid</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-default m-r-5" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    </form>
</ng-template>