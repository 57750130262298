<div class="kt-footer kt-grid__item" id="kt_footer">
    <div class="kt-container">
        <div class="kt-footer__wrapper">
            <div class="kt-footer__copyright">
                2019&nbsp;&copy;&nbsp;<a routerLink="/" class="kt-link">Work Order</a>
            </div>
            <div class="kt-footer__menu">
            </div>
        </div>
    </div>
</div>