export class UserDetailModel {
    public id: any;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public role: string;
    public normalizedRole: string;
    public status: number;
    public createdOn: string;
    public profileImage: string;
    public profileImageUrl: string;
    public timeZone: string;
    public timeZoneOffset: string;
    public sendInAppNotification: boolean;
    public sendPushNotification: boolean;
    public sendEmailNotification: boolean;
    public enableEmailNotification: boolean;
    public enablePushNotification: boolean;
    public enableInAppNotification: boolean;
}
