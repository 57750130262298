import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderService } from 'src/services';

@Component({
    selector: 'app-company-category-tile',
    templateUrl: './company.category.tile.component.html'
})
export class CompanyCategoryTileComponent implements OnInit {

    @Input() companyId: number;

    totalCategoriesCount: number;

    activeCategoriesCount: number;

    isModelLoaded = false;

    constructor(private workOrderService: WorkOrderService) {
    }

    ngOnInit() {
        this.workOrderService.getCountOfCompanyWorkOrderCategories(this.companyId, false)
            .subscribe(count => {
                this.totalCategoriesCount = count;
                this.isModelLoaded = true;
            },
            error => {
                console.log('Work order total categories count failed.');
                console.log(error);
            });

        this.workOrderService.getCountOfCompanyWorkOrderCategories(this.companyId, true)
            .subscribe(count => {
                this.activeCategoriesCount = count;
                this.isModelLoaded = true;
            },
            error => {
                console.log('Work order active categories count failed.');
                console.log(error);
            });
    }
}
