import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FacilityAssignedToUserGroupModel, RecordStatus, UserGroupModel } from 'src/models';

@Component({
    selector: 'app-user-group-challenge-deactivate',
    templateUrl: './user.group.challenge.deactivate.component.html'
})
export class UserGroupChallengeDeactivateComponent {
    @BlockUI('container-blockui-deactivate-popup') blockUI: NgBlockUI;
    @ViewChild('deactivateConfirmationModal', { static: true }) deactivateConfirmationModal: any;
    @Output() deactivate = new EventEmitter<UserGroupModel>();
    modalReference: NgbModalRef;
    deactivateConfirmationMessage: string;
    userGroupModel: UserGroupModel;
    status: RecordStatus;

    constructor(private modalService: NgbModal) { }

    openDefaultDeactivateConfirmationModal(userGroupModel: UserGroupModel) {
        this.userGroupModel = userGroupModel;
        this.modalReference = this.modalService.open(this.deactivateConfirmationModal);
        this.deactivateConfirmationMessage = `Are you sure you want to deactivate the user group ${userGroupModel.name}?`;
    }

    openDeactivateConfirmationForMultipleModal(userGroupModel: UserGroupModel, assignedFacilities: FacilityAssignedToUserGroupModel[]) {
        this.userGroupModel = userGroupModel;
        this.modalReference = this.modalService.open(this.deactivateConfirmationModal);
        assignedFacilities = assignedFacilities.filter(x => x.status == RecordStatus.Active);       
        let msg = assignedFacilities.length > 1 ?  `${assignedFacilities.length} facilities` : `${assignedFacilities[0].name}`
        this.deactivateConfirmationMessage = `This user group ${userGroupModel.name} is assigned to ${msg}. 
            If you deactivate this user group, workorders for customer complaints and move-outs will be assigned to account owner`;
    }

    openDeactivateConfirmationModal(userGroupModel: UserGroupModel, assignedFacilities: FacilityAssignedToUserGroupModel[]) {
        this.userGroupModel = userGroupModel;
        this.modalReference = this.modalService.open(this.deactivateConfirmationModal);
        assignedFacilities = assignedFacilities.filter(x => x.status == RecordStatus.Active);       
        let msg = assignedFacilities.length > 1 ?  `${assignedFacilities.length} facilities` : `${assignedFacilities[0].name}`
        this.deactivateConfirmationMessage = `This user group ${userGroupModel.name} is assigned to ${msg}. 
            If you deactivate this user group, all user assigned to the facility will be removed and workorders for customer 
            complaints and move-outs will be assigned to account owner`;
    }

    deactivateGroup() {
        this.close();
        setTimeout(() => {
            this.userGroupModel.raiseEvent = true;
            this.deactivate.emit(this.userGroupModel);
        });
    }

    close() {
        this.modalReference.close();
    }
}
