import * as jwt_decode from 'jwt-decode';

export class RoleMatrix {
    public role: string;
    public normalizedRole: string;
    public companyId: string;
    public is10FedUser: boolean;

    public canAddAdmin: boolean;
    public canEditAdmin: boolean;
    public canDeleteAdmin: boolean;
    public canViewAdmin: boolean;
    public canChangeStatusOfAdmin: boolean;

    public canAddUser: boolean;
    public canEditUser: boolean;
    public canDeleteUser: boolean;
    public canViewUser: boolean;
    public canChangeStatusOfUser: boolean;

    public canAddPersonnel: boolean;
    public canEditPersonnel: boolean;
    public canDeletePersonnel: boolean;
    public canViewPersonnel: boolean;
    public canChangeStatusOfPersonnel: boolean;

    public canAddCompany: boolean;
    public canEditCompany: boolean;
    public canDeleteCompany: boolean;
    public canViewCompany: boolean;
    public canChangeStatusOfCompany: boolean;

    public canAddOrgSuperAdmin: boolean;
    public canEditOrgSuperAdmin: boolean;
    public canDeleteOrgSuperAdmin: boolean;
    public canViewOrgSuperAdmin: boolean;
    public canChangeStatusOfOrgSuperAdmin: boolean;

    public canAddOrgAdmin: boolean;
    public canEditOrgAdmin: boolean;
    public canDeleteOrgAdmin: boolean;
    public canViewOrgAdmin: boolean;
    public canChangeStatusOfOrgAdmin: boolean;
    public canResetOrgAdminPassword: boolean;

    public canAddOrgUser: boolean;
    public canEditOrgUser: boolean;
    public canDeleteOrgUser: boolean;
    public canViewOrgUser: boolean;
    public canChangeStatusOfOrgUser: boolean;
    public canResetOrgUserPassword: boolean;

    public canAddFacility: boolean;
    public canEditFacility: boolean;
    public canDeleteFacility: boolean;
    public canViewFacility: boolean;
    public canChangeStatusOfFacility: boolean;

    public canAddUnit: boolean;
    public canEditUnit: boolean;
    public canDeleteUnit: boolean;
    public canViewUnit: boolean;
    public canChangeStatusOfUnit: boolean;

    public canAddWorkOrder: boolean;
    public canEditWorkOrder: boolean;
    public canDeleteWorkOrder: boolean;
    public canViewWorkOrder: boolean;
    public canChangeStatusOfWorkOrder: boolean;

    public canAddVendor: boolean;
    public canEditVendor: boolean;
    public canDeleteVendor: boolean;
    public canViewVendor: boolean;
    public canChangeStatusOfVendor: boolean;

    public canAddWoCategory: boolean;
    public canChangeStatusOfWoCategory: boolean;
    public canViewWoCategory: boolean;

    public canAddKiosk: boolean;
    public canViewKiosk: boolean;
    public canDeleteKiosk: boolean;

    public canConfigureStoredgeCompany: boolean;
    public canImportStoredgeData: boolean;
    public canSyncStoredgeData: boolean;

    public canAddUserGroup: boolean;
    public canEditUserGroup: boolean;
    public canDeleteUserGroup: boolean;
    public canViewUserGroup: boolean;
    public canChangeStatusOfUserGroup: boolean;

    public canViewKpiDashboard: boolean;

    public canAccessWorkOrderConfiguration: boolean;

    constructor() {
        this.canAddAdmin = false;
        this.canEditAdmin = false;
        this.canDeleteAdmin = false;
        this.canViewAdmin = false;
        this.canChangeStatusOfAdmin = false;

        this.canAddUser = false;
        this.canEditUser = false;
        this.canDeleteUser = false;
        this.canViewUser = false;
        this.canChangeStatusOfUser = false;

        this.canAddPersonnel = false;
        this.canEditPersonnel = false;
        this.canDeletePersonnel = false;
        this.canViewPersonnel = false;
        this.canChangeStatusOfPersonnel = false;

        this.canAddCompany = false;
        this.canEditCompany = false;
        this.canDeleteCompany = false;
        this.canViewCompany = false;
        this.canChangeStatusOfCompany = false;

        this.canAddOrgSuperAdmin = false;
        this.canEditOrgSuperAdmin = false;
        this.canDeleteOrgSuperAdmin = false;
        this.canViewOrgSuperAdmin = false;
        this.canChangeStatusOfOrgSuperAdmin = false;

        this.canAddOrgAdmin = false;
        this.canEditOrgAdmin = false;
        this.canDeleteOrgAdmin = false;
        this.canViewOrgAdmin = false;
        this.canChangeStatusOfOrgAdmin = false;
        this.canChangeStatusOfOrgAdmin = false;

        this.canAddOrgUser = false;
        this.canEditOrgUser = false;
        this.canDeleteOrgUser = false;
        this.canViewOrgUser = false;
        this.canChangeStatusOfOrgUser = false;
        this.canResetOrgUserPassword = false;

        this.canAddFacility = false;
        this.canEditFacility = false;
        this.canDeleteFacility = false;
        this.canViewFacility = false;
        this.canChangeStatusOfFacility = false;

        this.canAddUnit = false;
        this.canEditUnit = false;
        this.canDeleteUnit = false;
        this.canViewUnit = false;
        this.canChangeStatusOfUnit = false;

        this.canAddWorkOrder = false;
        this.canEditWorkOrder = false;
        this.canDeleteWorkOrder = false;
        this.canViewWorkOrder = false;
        this.canChangeStatusOfWorkOrder = false;

        this.canAddVendor = false;
        this.canEditVendor = false;
        this.canDeleteVendor = false;
        this.canViewVendor = false;
        this.canChangeStatusOfVendor = false;

        this.canAddWoCategory = false;
        this.canChangeStatusOfWoCategory = false;
        this.canViewWoCategory = false;

        this.canAddKiosk = false;
        this.canViewKiosk = false;
        this.canDeleteKiosk = false;

        this.canConfigureStoredgeCompany = false;
        this.canImportStoredgeData = false;
        this.canSyncStoredgeData = false;

        this.canAddUserGroup = false;
        this.canEditUserGroup = false;
        this.canDeleteUserGroup = false;
        this.canViewUserGroup = false;
        this.canChangeStatusOfUserGroup = false;

        this.canViewKpiDashboard = false;
        this.canAccessWorkOrderConfiguration = false;

        const authToken = localStorage.getItem('AuthToken');

        if (!authToken) {
            return;
        }
        const decodedToken = jwt_decode(authToken);

        this.role = decodedToken.role
            .toString()
            .toUpperCase();

        this.companyId = decodedToken.company_id;

        this.is10FedUser = this.role === 'SUPERADMIN' || this.role === 'ADMIN' || this.role === 'USER';

        switch (this.role) {
            case 'SuperAdmin':
            case 'SUPERADMIN': {
                this.normalizedRole = 'Super Administrator';

                this.canAddAdmin = true;
                this.canEditAdmin = true;
                this.canDeleteAdmin = true;
                this.canViewAdmin = true;
                this.canChangeStatusOfAdmin = true;

                this.canAddUser = true;
                this.canEditUser = true;
                this.canDeleteUser = true;
                this.canViewUser = true;
                this.canChangeStatusOfUser = true;

                this.canAddPersonnel = true;
                this.canEditPersonnel = true;
                this.canDeletePersonnel = true;
                this.canViewPersonnel = true;
                this.canChangeStatusOfPersonnel = true;

                this.canAddCompany = true;
                this.canEditCompany = true;
                this.canDeleteCompany = true;
                this.canViewCompany = true;
                this.canChangeStatusOfCompany = true;

                this.canAddOrgSuperAdmin = true;
                this.canEditOrgSuperAdmin = true;
                this.canDeleteOrgSuperAdmin = true;
                this.canViewOrgSuperAdmin = true;
                this.canChangeStatusOfOrgSuperAdmin = true;

                this.canAddOrgAdmin = true;
                this.canEditOrgAdmin = true;
                this.canDeleteOrgAdmin = true;
                this.canViewOrgAdmin = true;
                this.canChangeStatusOfOrgAdmin = true;

                this.canAddOrgUser = true;
                this.canEditOrgUser = true;
                this.canDeleteOrgUser = true;
                this.canViewOrgUser = true;
                this.canChangeStatusOfOrgUser = true;

                this.canAddFacility = true;
                this.canEditFacility = true;
                this.canDeleteFacility = true;
                this.canViewFacility = true;
                this.canChangeStatusOfFacility = true;

                this.canAddUnit = true;
                this.canEditUnit = true;
                this.canDeleteUnit = true;
                this.canViewUnit = true;
                this.canChangeStatusOfUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canDeleteWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;

                this.canAddVendor = true;
                this.canEditVendor = true;
                this.canDeleteVendor = true;
                this.canViewVendor = true;
                this.canChangeStatusOfVendor = true;

                this.canAddWoCategory = true;
                this.canChangeStatusOfWoCategory = true;
                this.canViewWoCategory = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;
                this.canDeleteKiosk = true;

                this.canConfigureStoredgeCompany = true;
                this.canImportStoredgeData = true;
                this.canSyncStoredgeData = true;

                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;
                this.canAccessWorkOrderConfiguration = true;
                break;
            }
            case 'Admin':
            case 'ADMIN': {
                this.normalizedRole = 'Administrator';

                this.canAddAdmin = true;
                this.canViewAdmin = true;

                this.canAddUser = true;
                this.canEditUser = true;
                this.canDeleteUser = true;
                this.canViewUser = true;
                this.canChangeStatusOfUser = true;

                this.canAddPersonnel = true;
                this.canEditPersonnel = true;
                this.canDeletePersonnel = true;
                this.canViewPersonnel = true;
                this.canChangeStatusOfPersonnel = true;

                this.canAddCompany = true;
                this.canEditCompany = true;
                this.canDeleteCompany = true;
                this.canViewCompany = true;
                this.canChangeStatusOfCompany = true;

                this.canAddOrgSuperAdmin = true;
                this.canEditOrgSuperAdmin = true;
                this.canDeleteOrgSuperAdmin = true;
                this.canViewOrgSuperAdmin = true;
                this.canChangeStatusOfOrgSuperAdmin = true;

                this.canAddOrgAdmin = true;
                this.canEditOrgAdmin = true;
                this.canDeleteOrgAdmin = true;
                this.canViewOrgAdmin = true;
                this.canChangeStatusOfOrgAdmin = true;

                this.canAddOrgUser = true;
                this.canEditOrgUser = true;
                this.canDeleteOrgUser = true;
                this.canViewOrgUser = true;
                this.canChangeStatusOfOrgUser = true;

                this.canAddFacility = true;
                this.canEditFacility = true;
                this.canDeleteFacility = true;
                this.canViewFacility = true;
                this.canChangeStatusOfFacility = true;

                this.canAddUnit = true;
                this.canEditUnit = true;
                this.canDeleteUnit = true;
                this.canViewUnit = true;
                this.canChangeStatusOfUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canDeleteWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;

                this.canAddVendor = true;
                this.canEditVendor = true;
                this.canDeleteVendor = true;
                this.canViewVendor = true;
                this.canChangeStatusOfVendor = true;

                this.canAddWoCategory = true;
                this.canChangeStatusOfWoCategory = true;
                this.canViewWoCategory = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;
                this.canDeleteKiosk = true;

                this.canConfigureStoredgeCompany = true;
                this.canImportStoredgeData = true;
                this.canSyncStoredgeData = true;

                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;
                this.canAccessWorkOrderConfiguration = true;

                break;
            }
            case 'User':
            case 'USER':
            case 'Personnel':
            case 'PERSONNEL': {
                this.normalizedRole = 'Personnel';

                this.canViewAdmin = true;
                this.canViewUser = true;

                this.canAddCompany = true;
                this.canEditCompany = true;
                this.canViewCompany = true;
                this.canChangeStatusOfCompany = true;

                this.canAddOrgSuperAdmin = true;
                this.canEditOrgSuperAdmin = true;
                this.canViewOrgSuperAdmin = true;
                this.canChangeStatusOfOrgSuperAdmin = true;

                this.canAddOrgAdmin = true;
                this.canEditOrgAdmin = true;
                this.canViewOrgAdmin = true;
                this.canChangeStatusOfOrgAdmin = true;

                this.canAddOrgUser = true;
                this.canEditOrgUser = true;
                this.canViewOrgUser = true;
                this.canChangeStatusOfOrgUser = true;

                this.canAddFacility = true;
                this.canEditFacility = true;
                this.canViewFacility = true;
                this.canChangeStatusOfFacility = true;

                this.canAddUnit = true;
                this.canEditUnit = true;
                this.canViewUnit = true;
                this.canChangeStatusOfUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;

                this.canAddVendor = true;
                this.canEditVendor = true;
                this.canViewVendor = true;

                this.canAddWoCategory = true;
                this.canChangeStatusOfWoCategory = true;
                this.canViewWoCategory = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;
                this.canDeleteKiosk = true;

                this.canConfigureStoredgeCompany = true;
                this.canImportStoredgeData = true;
                this.canSyncStoredgeData = true;

                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;
                this.canAccessWorkOrderConfiguration = true;

                break;
            }
            case 'OrgSuperAdmin':
            case 'ORGSUPERADMIN': {
                this.normalizedRole = 'Account Owner';

                this.canEditCompany = true;
                this.canViewCompany = true;

                this.canViewOrgSuperAdmin = true;

                this.canAddOrgAdmin = true;
                this.canEditOrgAdmin = true;
                this.canDeleteOrgAdmin = true;
                this.canViewOrgAdmin = true;
                this.canChangeStatusOfOrgAdmin = true;
                this.canResetOrgAdminPassword = true;

                this.canAddOrgUser = true;
                this.canEditOrgUser = true;
                this.canDeleteOrgUser = true;
                this.canViewOrgUser = true;
                this.canChangeStatusOfOrgUser = true;
                this.canResetOrgUserPassword = true;

                this.canAddFacility = true;
                this.canEditFacility = true;
                this.canDeleteFacility = true;
                this.canViewFacility = true;
                this.canChangeStatusOfFacility = true;

                this.canAddUnit = true;
                this.canEditUnit = true;
                this.canViewUnit = true;
                this.canChangeStatusOfUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canDeleteWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;

                this.canAddVendor = true;
                this.canEditVendor = true;
                this.canDeleteVendor = true;
                this.canViewVendor = true;
                this.canChangeStatusOfVendor = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;
                this.canDeleteKiosk = true;

                this.canConfigureStoredgeCompany = true;
                this.canImportStoredgeData = true;
                this.canSyncStoredgeData = true;

                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;

                this.canViewKpiDashboard = true;
                this.canAccessWorkOrderConfiguration = true;

                break;
            }
            case 'OrgAdmin':
            case 'ORGADMIN': {
                this.normalizedRole = 'Administrator';

                this.canEditCompany = true;
                this.canViewCompany = true;

                this.canAddOrgAdmin = true;
                this.canViewOrgAdmin = true;

                this.canViewOrgSuperAdmin = true;

                this.canAddOrgAdmin = true;
                this.canEditOrgAdmin = true;
                this.canDeleteOrgAdmin = true;
                this.canViewOrgAdmin = true;
                this.canChangeStatusOfOrgAdmin = true;
                this.canResetOrgAdminPassword = true;

                this.canAddOrgUser = true;
                this.canEditOrgUser = true;
                this.canViewOrgUser = true;
                this.canDeleteOrgUser = true;
                this.canChangeStatusOfOrgUser = true;
                this.canResetOrgUserPassword = true;

                this.canAddFacility = true;
                this.canEditFacility = true;
                this.canViewFacility = true;
                this.canChangeStatusOfFacility = true;

                this.canAddUnit = true;
                this.canEditUnit = true;
                this.canViewUnit = true;
                this.canChangeStatusOfUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canDeleteWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;

                this.canAddVendor = true;
                this.canEditVendor = true;
                this.canDeleteVendor = true;
                this.canViewVendor = true;
                this.canChangeStatusOfVendor = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;
                this.canDeleteKiosk = true;

                this.canConfigureStoredgeCompany = true;
                this.canImportStoredgeData = true;
                this.canSyncStoredgeData = true;

                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;

                this.canViewKpiDashboard = true;
                this.canAccessWorkOrderConfiguration = true;

                break;
            }
            case 'OrgUser':
            case 'ORGUSER': {
                this.normalizedRole = 'Personnel';

                this.canViewCompany = true;

                this.canViewOrgSuperAdmin = true;

                this.canViewOrgAdmin = true;

                this.canViewOrgUser = true;

                this.canViewFacility = true;

                this.canViewUnit = true;

                this.canAddWorkOrder = true;
                this.canEditWorkOrder = true;
                this.canViewWorkOrder = true;
                this.canChangeStatusOfWorkOrder = true;
                this.canDeleteWorkOrder = true;

                this.canViewVendor = true;

                this.canAddKiosk = true;
                this.canViewKiosk = true;

                this.canViewUserGroup = true;
                this.canAddUserGroup = true;
                this.canEditUserGroup = true;
                this.canDeleteUserGroup = true;
                this.canViewUserGroup = true;
                this.canChangeStatusOfUserGroup = true;

                break;
            }
        }
    }

    currentUserAccess(): RoleMatrix {
        return this;
    }

    configureAllowActionsForFacility() {
        this.canAddFacility = false;
        this.canEditFacility = false;
        this.canAddUnit = false;
    }

    configureAllowActionsForUnit() {
        this.canAddUnit = false;
        this.canEditUnit = false;
    }

    configureAllowActionsForCompany() {
        this.canAddCompany = false;
        this.canEditCompany = false;
        this.canEditOrgSuperAdmin = false;
    }

    configureAllowActionsForVendor() {
        this.canAddVendor = false;
        this.canEditVendor = false;
    }

    configureAllowActionsForWorkOrder() {
        this.canAddWorkOrder = false;
        this.canEditWorkOrder = false;
        this.canChangeStatusOfWorkOrder = false;
    }

    configureAllowActionsForUser() {
        this.canAddUser = false;
        this.canEditUser = false;
    }

    configureAllowActionsForPersonel() {
        this.canAddPersonnel = false;
        this.canEditPersonnel = false;
    }

    configureAllowActionsForOrgUser() {
        this.canAddOrgUser = false;
        this.canEditOrgUser = false;
    }

    configureAllowActionsForOrgAdmin() {
        this.canAddOrgAdmin = false;
        this.canEditOrgAdmin = false;
    }

    configureAllowActionsForKiosk() {
        this.canAddKiosk = false;
    }

}
