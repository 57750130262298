import { UserGroupService } from '@10fed/services/user.group.service';
import { UserService } from '@10fed/services/user.service';
import { WorkOrderService } from '@10fed/services/work.order.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AppUtils } from 'src/helpers';
import { SelectListItemModel, UserSelectListItemModel } from 'src/models';

@Component({
  selector: 'app-wo-bulk-operations',
  templateUrl: './wo-bulk-operations.component.html',
  styleUrls: ['./wo-bulk-operations.component.css']
})
export class WoBulkOperationsComponent implements OnInit {

  @Input()
  companyId: number;

  @Input()
  viewType: string;

  @Input()
  selectedWorkOrderIds: Set<number>;

  @Output('showWorkOrdersList')
  showWorkOrdersListEvent = new EventEmitter();

  @BlockUI('wo-bulk-operations-container-blockui')
  blockUi: NgBlockUI;

  @ViewChild('confirmationModal', { static: true })
  confirmationModal: NgbModalRef;

  assigneeId: string = '';
  get assigneeName() {
    if (!this.assigneeId) return;

    const assignee = this.assignees.find(x => x.key === this.assigneeId);
    return assignee && assignee.value;
  }

  assignees: UserSelectListItemModel[] = [];
  selectedWorkOrderAssigneeIds: Set<string> = new Set();
  selectedWorkOrderAssigneeNames: Set<string> = new Set();

  userGroupsToAddAssignee: SelectListItemModel[] = [];

  constructor(private userService: UserService,
              private workOrderService: WorkOrderService,
              private userGroupService: UserGroupService,
              private toastr: ToastrService,
              private appUtils: AppUtils,
              private modalService: NgbModal,
              private router: Router) {
  }

  ngOnInit() {
    this.loadCompanyUsers();
    this.loadWorkOrders();
  }

  goBackToWorkOrdersList() {
    this.showWorkOrdersListEvent.emit();
  }

  performOperation() {
    this.blockUi.start();
    this.userGroupService.fetchUserGroupsWhichUserIsNotPartOfByOtherAssignees(this.companyId, this.assigneeId,
      Array.from(this.selectedWorkOrderAssigneeIds)).subscribe(
        userGroups => {
          this.userGroupsToAddAssignee = userGroups;
          this.blockUi.stop();

          if (userGroups.length === 0) {
            this.performBulkOperationOnWorkOrders();
          } else {
            this.modalService.open(this.confirmationModal, {
              size: 'lg'
            });
          }
        },
        error => {
          this.appUtils.ProcessErrorResponse(this.toastr, error);
          this.blockUi.stop();
        }
      );
  }

  addUserToUserGroupsAndPerformBulkOperationOnWorkOrders(modal: NgbModalRef) {
    modal.close();
    this.blockUi.start();
    this.userGroupService.addUserToUserGroups(this.companyId, this.assigneeId, this.userGroupsToAddAssignee.map(x => x.key))
      .subscribe(resp => {
          this.blockUi.stop();
          this.performBulkOperationOnWorkOrders();
        },
        error => {
          this.appUtils.ProcessErrorResponse(this.toastr, error);
          this.blockUi.stop();
        }
      );
  }

  private performBulkOperationOnWorkOrders() {
    window.setTimeout(() => {
      this.blockUi.start();
    });
    this.workOrderService.assignNewAssigneeToWorkOrders(this.companyId, this.assigneeId, Array.from(this.selectedWorkOrderIds))
      .pipe(
        timeout(300000),
        catchError(e => {
          return of(null);
        })
      )
      .subscribe(resp => {
          this.blockUi.stop();
          this.toastr.success('Operation has been performed successfully.');
          this.router.navigate([this.viewType === '1' ? '/work-order/manage' : '/work-order/grid-view', this.companyId]);
        },
        error => {
          this.appUtils.ProcessErrorResponse(this.toastr, error);
          this.blockUi.stop();
        }
      );
  }

  private loadCompanyUsers() {
    this.blockUi.start();
    this.userService.getCompanyUsersSelectItem(this.companyId).subscribe(users => {
      this.assignees = users as UserSelectListItemModel[];
      this.blockUi.stop();
    }, error => {
      this.appUtils.ProcessErrorResponse(this.toastr, error);
      this.blockUi.stop();
    })
  }

  private loadWorkOrders() {
    this.blockUi.start();
    this.workOrderService.getOpenWorkOrdersDetailByIds(this.companyId, Array.from(this.selectedWorkOrderIds))
      .subscribe(workOrders => {
        this.selectedWorkOrderAssigneeIds = new Set(workOrders.filter(x => x.assignedToId).map(x => x.assignedToId));
        this.selectedWorkOrderAssigneeNames = new Set(workOrders.filter(x => x.assignedToName).map(x => x.assignedToName));
        this.blockUi.stop();
      }, error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUi.stop();
      })
  }
}
