import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../helpers';
import {
  UserAddGroupModel,
  UserGroupEditModel,
  UserGroupModel,
  SelectListItemModel,
  UserGroupMetricsModel,
  UserGroupDefaultAssigneeValidationModel,
  FacilityAssigneesValidationDto,
  UserGroupSelectList
} from '../models';
import { Observable } from 'rxjs';

@Injectable()
export class UserGroupService {
  constructor(private http: HttpClient, private appSettings: AppSettings) { }

  public add(model: UserAddGroupModel) {
    return this.http.post(environment.apiBaseUrl + 'user-group/add', model);
  }

  public edit(model: UserGroupEditModel) {
    return this.http.post(environment.apiBaseUrl + 'user-group/edit', model);
  }

  public getCompanyUserGroupsSelectItem = (companyId: number, includeUsers: boolean) =>
    this.http.get<UserGroupSelectList[]>(
      `${environment.apiBaseUrl}user-group/get-company-user-groups-select-item/${companyId}?includeUsers=${includeUsers}`)

  public getCompanyUserGroupsSelectItemFacilityWise = (companyId: number, includeUsers: boolean) =>
  this.http.get<UserGroupSelectList[]>(
    `${environment.apiBaseUrl}user-group/get-company-user-groups-select-item-facilitywise/${companyId}?includeUsers=${includeUsers}`)
  
  public getUserGroup(groupId: string): Observable<UserGroupModel> {
    return this.http.get<UserGroupModel>(environment.apiBaseUrl + 'user-group/' + groupId);
  }

  public getUserGroupForEdit(groupId: string): Observable<UserGroupModel> {
    return this.http.get<UserGroupModel>(environment.apiBaseUrl + 'user-group/get-for-edit/' + groupId);
  }

  public toggleStatus(model: UserGroupEditModel) {
    return this.http.post(environment.apiBaseUrl + 'user-group/toggle', model);
  }

  public delete(model: UserGroupEditModel) {
    return this.http.post(environment.apiBaseUrl + 'user-group/delete', model);
  }

  public getUserGroupMetrics(companyId: number) {
    return this.http.get<UserGroupMetricsModel>(`${environment.apiBaseUrl}user-group/get-user-groups-metrics/${companyId}`);
  }

  public getUsersGroups(userId: string) {
    return this.http.get<string[]>(`${environment.apiBaseUrl}user-group/get-users-groups/${userId}`);
  }

  public validateDefaultAssignees(model: UserGroupDefaultAssigneeValidationModel) {
    return this.http.post<FacilityAssigneesValidationDto[]>(environment.apiBaseUrl + 'user-group/validate-default-assignees', model);
  }

  public getCompanyUsersOfUserGroups = (companyId: number, groupIds: string[]) =>
    this.http.get(`${environment.apiBaseUrl}user-group/get-company-users-of-user-groups/${companyId}?onlyActiveUserGroups=true&onlyActiveUsers=true&groupIds=${groupIds.join('&groupIds=')}`)
  
  fetchUserGroupsWhichUserIsNotPartOfByOtherAssignees = (companyId: number, targetUserId: string, userIds: string[]) =>
    this.http.post<SelectListItemModel[]>(
        `${environment.apiBaseUrl}user-group/fetch-user-groups-which-user-is-not-part-of-by-other-assignees/${companyId}/${targetUserId}`,
        userIds);

  addUserToUserGroups = (companyId: number, userId: string, userGroupIds: string[]) =>
    this.http.post<SelectListItemModel[]>(`${environment.apiBaseUrl}user-group/add-user-to-user-groups/${companyId}/${userId}`, userGroupIds);
}
