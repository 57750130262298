import { Component } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-company-detail',
    templateUrl: './breadcrumb.company.detail.component.html'
})

export class BreadcrumbCompanyDetailComponent {
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
