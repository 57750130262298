export class ActivityDetailModel {
    public fieldName: string;
    public oldValue: string;
    public newValue: string;
}

export class ActivityInfoModel {
    field: string;
    value: string;
}
