import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { StoredgeConfigurationModel } from '../../../models';
import { StoredgeService, CompanyService } from '../../../services';

@Component({
    selector: 'app-storedge-configuration',
    templateUrl: './storedge.configuration.component.html'
})

export class StoredgeConfigurationComponent implements OnInit {
    @BlockUI('container-blockui-configuration') blockUI: NgBlockUI;
    @Input() model: StoredgeConfigurationModel;
    @Output() openNextTab = new EventEmitter();
    showActionColumn = true;
    isHiddenStoredge:boolean=true;

    constructor(private toastr: ToastrService,
        private storedgeService: StoredgeService,
        private appUtils: AppUtils,
        private companyService: CompanyService) { }

    ngOnInit() {
        this.loadStoredgeCompanyId();
        this.companyService.getStatus(this.model.internalCompanyId).subscribe(
            data => {
                if (data === 3) {
                    this.showActionColumn = false;
                }
            });
    }

    loadStoredgeCompanyId() {
        this.blockUI.start();
        this.storedgeService.getStoredgeCompanyId(this.model.internalCompanyId)
            .subscribe(
                (data: string) => {
                    this.blockUI.stop();
                    if (!data) {
                        return;
                    }
                    this.model.storedgeCompanyId = data;
                    this.model.storedgCompanyConfigured = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

    }

    submit() {
        this.blockUI.start();
        this.storedgeService.configureCompany(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.model.storedgCompanyConfigured = true;
                    setTimeout(() => {
                        this.openNextTab.emit();
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
