import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService } from '../../../services';
import { UserEditModel } from '../../../models';
import { RoleMatrix, AppUtils } from '../../../helpers';
import { TimeZone } from 'src/models/timezone.model';
import { TimeZoneService } from 'src/services/timezone.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user.edit.component.html'
})

export class UserEditComponent implements OnInit {
    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    roleMatrix: RoleMatrix;
    model: UserEditModel = new UserEditModel();
    timeZones: TimeZone[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canEditAdmin && !this.roleMatrix.canEditUser) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe(params => this.model.id = params['id']);
    }

    ngOnInit(): void {
        this.loadUserDetails();
        this.loadTimeZones();
    }

    submit() {
        if(!this.model.enableEmailNotification && !this.model.enablePushNotification) {
            this.toastr.error("Atleast one medium to receive notification should be selected.");
            return;
        }

        this.blockUI.start();
        this.userService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/user/detail', this.model.id]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('User has been updated successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadUserDetails() {
        this.blockUI.start();
        this.userService.getForEdit(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();
                Object.assign(this.model, data);
                if (this.model.role === 'Admin'
                    && !this.roleMatrix.canEditAdmin) {
                    this.router.navigate(['/error/403']);
                    return;
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
    
    onEmailNotificationDisabled() {
        if(!this.model.enableEmailNotification) {
            this.model.sendEmailNotification = false;
        }
    }

    onWatchersEmailNotificationEnabled() {
        if(this.model.sendEmailNotification){
            this.model.enableEmailNotification = true;
        }
    }

    onPushNotificationDisabled() {
        if(!this.model.enablePushNotification) {
            this.model.sendPushNotification = false;
        }
    }

    onWatchersPushNotificationEnabled() {
        if(this.model.sendPushNotification){
            this.model.enablePushNotification = true;
        }
    }
}
