import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FacilityAssignedToUserGroupModel, RecordStatus } from 'src/models';

@Component({
    selector: 'app-user-group-challenge-delete-user',
    templateUrl: './user.group.challenge.delete.user.component.html'
})
export class UserGroupChallengeDeleteUserComponent {
    @BlockUI('container-blockui-delete-user-popup') blockUI: NgBlockUI;
    @ViewChild('userDeleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @Output() delete = new EventEmitter<any>();
    modalReference: NgbModalRef;
    deleteConfirmationMessage: string;

    constructor(private modalService: NgbModal) { }

    openDeleteConfirmationModal(userGroupName: string, assignedFacilities: FacilityAssignedToUserGroupModel[]) {
        this.modalReference = this.modalService.open(this.deleteConfirmationModal);
        assignedFacilities = assignedFacilities.filter(x => x.status == RecordStatus.Active);       
        let msg = assignedFacilities.length > 1 ?  `${assignedFacilities.length} facilities` : `${assignedFacilities[0].name}`
        this.deleteConfirmationMessage = `This user is assigned to a user group ${userGroupName} which  is assigned to ${msg}.
         If you delete this user, workorders for customer complaints and move-outs will be assigned to account owner`
    }

    deleteLastUser() {
        this.close();
        setTimeout(() => {
            this.delete.emit();
        });
    }

    close() {
        this.modalReference.close();
    }
}
