export class UnitDetailModel {
    public id: number;
    public companyId: number;
    public facilityId: number;
    public name: string;
    public totalWorkOrders: number;
    public status: number;
    public facilityName: string;
    public isImported: boolean;
}
