import { CompanyUserAssignedFacilityModel } from './company.user.assigned.facility.model';

export class CompanyUserDetailModel {
    public id: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public role: string;
    public normalizedRole: string;
    public status: number;
    public createdOn: string;
    public companyId: string;
    public profileImage: string;
    public profileImageUrl: string;
    public isFollowing: boolean;
    public timeZone: string;

    public sendInAppNotification: boolean;
    public sendEmailNotification: boolean;
    public sendPushNotification: boolean;

    public enableEmailNotification: boolean;
    public enableInAppNotification: boolean;
    public enablePushNotification: boolean;

    public assignedFacilities: Array<CompanyUserAssignedFacilityModel>;
}
