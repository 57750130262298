import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable, fromEvent } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { UserDetailModel } from 'src/models/user/user.detail.model';

@Injectable()
export class AppUtils {

    private _appTimeZoneOffset: string;
    private _appTimeZone: string;

    // public emailRegexPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
    public emailRegexPattern = '^.+@.+..+$';
    // tslint:disable-next-line:max-line-length
    public urlRegexPattern = '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$';

    public ProcessErrorResponse(toastr: any, response: any, customErrorMessage = '') {
        const error = response.error;
        console.log(error);
        console.log(response.status);
        if (response.status === 400) {
            if (error instanceof Array) {
                error.forEach(function (item) {
                    toastr.error(item);
                });
            } else {
                toastr.error(error);
            }
        } else {
            if (customErrorMessage === '') {
                toastr.error('Internal server error.');
            } else {
                toastr.error(customErrorMessage);
            }
        }
    }

    public setAppTimeZoneOffset (userInfo: UserDetailModel) { 
        this._appTimeZoneOffset = userInfo.timeZoneOffset; 
        this._appTimeZone = userInfo.timeZone;
    }

    private formatDateTime (date, format: string, skipTimeZoneConversion?: boolean) {
        if (typeof(date) === 'string' && date.length === 19) {
            date = `${date}+00:00`;
        }

        return new DatePipe('en-US')
            .transform(date, format, skipTimeZoneConversion === true ? '' : this._appTimeZoneOffset);
    }

    public getFormattedDate = (date, skipTimeZoneConversion = false) => this.formatDateTime(date, 'MM/dd/yyyy', skipTimeZoneConversion);

    public getFormattedDateTime = (date) => this.formatDateTime(date, 'MM/dd/yyyy hh:mm a');

    public getFormattedDateTimeFull = (date) => this.formatDateTime(date, 'MM/dd/yyyy hh:mm:ss a');

    public getFormattedTime = (date) => this.formatDateTime(date, 'hh:mm a');

    public getFormattedTimeFull = (date) => this.formatDateTime(date, 'hh:mm:ss a');

    public getFormattedDay = (date) => this.formatDateTime(date, 'd');

    public getUtcDateObject(date: string) {
        if (!date) { return null; }
        const m = moment(date);
        return Date.UTC(m.year(), m.month(), m.date());
    }

    public isUserAuthenticated() {
        if (localStorage.getItem('AuthToken')) {
            return true;
        }
        return false;
    }

    public removeAuthToken() {
        localStorage.removeItem('AuthToken');
    }

    public getUserRole() {
        const authToken = localStorage.getItem('AuthToken');
        if (!authToken) {
            return '';
        }
        const decodedToken = jwt_decode(authToken);
        return decodedToken
            .role
            .toString()
            .toUpperCase();
    }

    public getUserId() {
        const authToken = localStorage.getItem('AuthToken');
        if (!authToken) {
            return '';
        }
        const decodedToken = jwt_decode(authToken);
        return decodedToken
            .nameid
            .toString();
    }

    public getNormalizedRole(role: string) {
        role = role.toUpperCase();
        switch (role) {
            case 'SUPERADMIN':
                return 'Super Administrator';
            case 'ADMIN':
                return 'Administrator';
            case 'USER':
                return 'Personnel';
            case 'ORGSUPERADMIN':
                return 'Account Owner';
            case 'ORGADMIN':
                return 'Administrator';
            case 'ORGUSER':
                return 'Personnel';
            case 'USER':
                return 'Personnel';
        }
    }

    public is10FedUser(role: string) {
        role = role.toUpperCase();
        return (role === 'SUPERADMIN' || role === 'ADMIN' || role === 'USER');
    }

    public imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
        fileReader.readAsDataURL(fileToRead);
        return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
    }

    public getMonthList() {
        const monthList = [
            { id: 1, name: 'Jan' },
            { id: 2, name: 'Feb' },
            { id: 3, name: 'Mar' },
            { id: 4, name: 'Apr' },
            { id: 5, name: 'May' },
            { id: 6, name: 'Jun' },
            { id: 7, name: 'Jul' },
            { id: 8, name: 'Aug' },
            { id: 9, name: 'Sep' },
            { id: 10, name: 'Oct' },
            { id: 11, name: 'Nov' },
            { id: 12, name: 'Dec' }
        ];

        return monthList.filter(x => x.id <= (moment().month() + 1));
    }

    public getFileExtension(filename: string) {
        return filename.indexOf('.') !== -1 ? filename.split('.').pop() : null;
    }

    public sort(items: any[], propertyName: string) {
        if (!items) {
            return;
        }

        items.sort((item1, item2) => {
            const text1 = item1[propertyName].toUpperCase();
            const text2 = item2[propertyName].toUpperCase();
            return (text1 < text2)
                ? -1
                : (text1 > text2) ? 1 : 0;
        });
    }

    public getPriorityList() {
        const priorityList = [
            { id: 1, name: 'Low' },
            { id: 2, name: 'Medium' },
            { id: 3, name: 'High' }
        ];

        return priorityList;
    }

    public getUserTimeZone() {
        return this._appTimeZone;
    } 
}
