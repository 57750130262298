import { Component, HostBinding, ViewChild, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as moment from 'moment';
import {
    SelectListItemModel, AssignedUserModel, WorkOrderPhotoModel, WorkOrderEditModel, WorkOrderRecurrenceModel,
    CategorySelectListItemModel,
    AssignedUserGroupModel,
    AssigneeSelectListItemModel
} from '../../../models';
import { FacilityService, UnitService, WorkOrderService, CloudStorageService } from '../../../services';
import { AppUtils, RoleMatrix, AppSettings, CategoryType, WorkOrderAssigneeType } from '../../../helpers';
import { isNullOrUndefined } from '../../../helpers/tools';
import { BlobService, UploadParams, UploadConfig } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { NgModel, NgForm } from '@angular/forms';

@Component({
    selector: 'app-work-order-edit',
    templateUrl: './work.order.edit.component.html'
})
export class WorkOrderEditComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('workOrderPhoto') workOrderPhoto: ElementRef;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('addWorkOrderNote', { static: true }) addWorkOrderNote: any;
    @ViewChild('workOrderRecurrenceModal', { static: true }) workOrderRecurrenceModal: any;

    multiSelectDropdownConfigs: IDropdownSettings;

    companyId = -1;
    viewType = 1;
    isModelLoaded = false;
    workOrderCategories: Array<CategorySelectListItemModel> = new Array<CategorySelectListItemModel>();
    selectedCategory = new CategorySelectListItemModel();
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    workOrderStatuses: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    units: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    facilityUsers: Array<AssignedUserModel> = new Array<AssignedUserModel>();
    facilityGroups: Array<AssignedUserGroupModel> = new Array<AssignedUserGroupModel>();
    workOrderAssignees: Array<AssigneeSelectListItemModel> = Array<AssigneeSelectListItemModel>();
    facilityWatchers: Array<AssignedUserModel> = new Array<AssignedUserModel>();
    selectedAssignee: AssigneeSelectListItemModel;
    
    isOnHoldNoteAdded = false;
    oldWorkOrderStatus = '';
    oldAssigneeId: string;
    oldFollowerId: string;
    oldFollowerInstruction: string;

    selectedClientWatchers: AssignedUserModel[] = [];

    model: WorkOrderEditModel = new WorkOrderEditModel();

    isUserCreator = false;
    isUserWatcher = false;
    currentUserId: string;

    minDueDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };


    note = '';
    uploadedPercent = 0;

    config: UploadConfig;

    isRecurringWOAllowed: boolean = true;
    isUnitSelectAllowed: boolean = true;
    isFacilitySelectAllowed: boolean = true;
    currentFacilityId: string = "";

    get categoryTypeEnum() {
        return CategoryType;
    }

    get selectedCategoryIsUnrentable() {
        return (this.selectedCategory && this.selectedCategory.isUnrentable) || false;
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private unitService: UnitService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private cloudStorageService: CloudStorageService,
        private appSettings: AppSettings,
        private blobService: BlobService,
        private cd: ChangeDetectorRef) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddWorkOrder) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.viewType = params['viewType'];
            this.model.id = params['id'];
            this.companyId = params['companyId'];
            this.model.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'fullName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            noDataAvailablePlaceholderText: '[Select]'
        };

        this.currentUserId = this.appUtils.getUserId();
    }

    ngOnInit() {
        this.loadWorkOrderCategories();
        this.loadFacilities();
        this.loadWorkOrder();
    }

    loadWorkOrderCategories() {
        this.workOrderService.getCategorySelectListItems(this.companyId, false, false)
            .subscribe(
                (data: any) => {
                    Object.assign(this.workOrderCategories, data);
                    this.setCategoryOptions();
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFacilities() {
        this.facilityService.getSelectListItems(this.companyId)
            .subscribe(
                (data: any) => {
                    Object.assign(this.facilities, data);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadUnits() {
        this.blockUI.start();
        this.unitService.getSelectListItems(Number(this.model.facilityId))
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.units, data);
                    const unitToRemove = this.units.find(x => x.name === 'Default');
                    const index = this.units.indexOf(unitToRemove);
                    if (index !== -1 && !this.model.createdAutomatically) {
                        this.units.splice(index, 1);
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFacilityUsers(isInitialLoad: boolean) {
        this.blockUI.start();
        this.facilityService.getUsersAndUserGroupsList(Number(this.model.facilityId), true, true, true)
            .subscribe(
                (data: any) => {
                    this.facilityGroups = data.userGroups;
                    this.facilityUsers = data.users;
                    this.facilityUsers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                    this.facilityWatchers = this.facilityUsers;
                    if (isInitialLoad) {
                        this.selectedClientWatchers = this.facilityWatchers.filter(x => this.model.watchersIds.includes(x.id));
                    }
                    if(data.watchers != null)
                    {
                        if(this.roleMatrix.role === 'ORGSUPERADMIN')
                        {
                            this.facilityWatchers = this.facilityWatchers.concat(data.watchers);
                            this.facilityWatchers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                            this.facilityWatchers.sort((t1, t2) => {
                                const name1 = t1.firstName.toLowerCase();
                                const name2 = t2.firstName.toLowerCase();
                                if (name1 > name2) { return 1; }
                                if (name1 < name2) { return -1; }
                                return 0;
                            });

                            if (isInitialLoad) 
                            {
                                if(this.model.watchersIds.includes(data.watchers.id))
                                {
                                    this.selectedClientWatchers = this.facilityWatchers.filter(x => this.model.watchersIds.includes(x.id)); 
                                }
                            }
                        }
                        else
                        {
                            if (isInitialLoad) 
                            {
                                if(this.model.watchersIds.includes(data.watchers.id))
                                {
                                    this.facilityWatchers = this.facilityWatchers.concat(data.watchers);
                                    this.facilityWatchers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                                    this.facilityWatchers.sort((t1, t2) => {
                                        const name1 = t1.firstName.toLowerCase();
                                        const name2 = t2.firstName.toLowerCase();
                                        if (name1 > name2) { return 1; }
                                        if (name1 < name2) { return -1; }
                                        return 0;
                                    });
                                    this.selectedClientWatchers = this.facilityWatchers.filter(x => this.model.watchersIds.includes(x.id)); 
                                }
                            }
                        }
                    }
                    this.blockUI.stop();

                    this.workOrderAssignees = this.facilityGroups.map(x => 
                        {
                            let model = new AssigneeSelectListItemModel();
                            model.id = x.id,
                            model.name = x.name,
                            model.type = WorkOrderAssigneeType.group
                            model.image = location.origin + "/assets/media/icons/user-group.png"
                            return model;
                        })
                        .concat(
                            this.facilityUsers.map(x=>{
                            let model = new AssigneeSelectListItemModel();
                            model.id = x.id,
                            model.name = x.fullName,
                            model.type = WorkOrderAssigneeType.user,
                            model.image = location.origin + "/assets/media/icons/user.png"
                            return model;
                            })
                        );

                    this.setAssignee(isInitialLoad);
                    this.setFollower();
                },
                error => {
                    this.blockUI.stop();
                    this.facilityUsers = new Array<AssignedUserModel>();
                    this.facilityGroups = new Array<AssignedUserGroupModel>();
                    this.workOrderAssignees = new Array<AssigneeSelectListItemModel>();
                    this.facilityWatchers = new Array<AssignedUserModel>();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    setAssignee(isInitialLoad: boolean) {
        if(isNullOrUndefined(this.model.facilityId) || this.model.facilityId == ''){
            this.model.assignedToId = null;
            this.model.assignedToGroupId = null;
            this.selectedAssignee = null;
            return;
        }
        
        if (!isNullOrUndefined(this.oldAssigneeId) && this.workOrderAssignees.find(x => x.id === this.oldAssigneeId)) {
            this.selectedAssignee = this.workOrderAssignees.find(x => x.id == this.oldAssigneeId);
            if(this.selectedAssignee.type == WorkOrderAssigneeType.user) {
                this.model.assignedToId = this.oldAssigneeId;
                this.model.assignedToGroupId = '';
            }
            else {
                this.model.assignedToId = null;
                this.model.assignedToGroupId = this.oldAssigneeId;
            }
            if(!isInitialLoad) {
                this.setReminderInterval();
            }
            return;
        }

        this.blockUI.start();
        this.facilityService.getDetailLight(+this.model.facilityId)
            .subscribe(data => {
                this.blockUI.stop();
                this.model.assignedToId = data.defaultAssigneeId ? data.defaultAssigneeId : '';
                this.model.assignedToGroupId = null;
                this.selectedAssignee = this.workOrderAssignees.find(x => x.id == this.model.assignedToId);
                if(!isInitialLoad) {
                    this.setReminderInterval();
                }
            }, error => {
                this.blockUI.stop();
            });
    }

    onAssigneeChange(assignee: AssigneeSelectListItemModel) {
        if(assignee) {
            this.selectedAssignee = assignee;
            this.oldAssigneeId = this.model.assignedToId;
            
            if(this.selectedAssignee.type == WorkOrderAssigneeType.group) {
                this.model.assignedToGroupId = assignee.id;
                this.model.assignedToId = null;
            }
            else{
                this.model.assignedToId = assignee.id;
                this.model.assignedToGroupId = null;
            }
            this.setReminderInterval();
        }
        else
        {
            this.selectedAssignee =  null;
            this.model.assignedToId = null;
            this.model.assignedToGroupId = null;
        }
    }

    setFollower() {
        if (isNullOrUndefined(this.model.facilityId) || this.model.facilityId == '' || 
            isNullOrUndefined(this.oldFollowerId) || !this.facilityUsers.find(x => x.id === this.oldFollowerId)) {
            this.model.followUpAssigneeId = '';
            this.model.followUpInstruction = '';
            return;
        }

        this.model.followUpAssigneeId = this.oldFollowerId;
        this.model.followUpInstruction = this.oldFollowerInstruction;
    }

    setOldFollower() {
        this.oldFollowerId = this.model.followUpAssigneeId;
        if (!this.model.followUpAssigneeId) {
            this.oldFollowerInstruction = '';
            this.model.followUpInstruction = '';
        }
    }

    setOldInstruction() {
        this.oldFollowerInstruction = this.model.followUpInstruction;
    }

    loadWorkOrder() {
        this.blockUI.start();
        this.workOrderService.getDetailForEdit(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();

                    Object.assign(this.model, data);
                    this.oldWorkOrderStatus = this.model.workOrderStatus;
                    this.isUserCreator = this.model.createdById.toLowerCase() === this.currentUserId.toLowerCase();
                    this.isUserWatcher = this.model.watchersIds && !!this.model.watchersIds.find(x => x.toLowerCase() === this.currentUserId.toLowerCase());

                    if (isNullOrUndefined(this.model.unitId)) {
                        this.model.unitId = '';
                    }

                    if (this.model.dueDate) {
                        const dueDate = new Date(this.model.dueDate);
                        if (dueDate < new Date()) {
                            this.minDueDate = {
                                year: dueDate.getFullYear(),
                                month: dueDate.getMonth() + 1,
                                day: dueDate.getDate()
                            };
                        }
                    }

                    if (!this.model.assignedToId) {
                        this.model.assignedToId = '';
                    } else {
                        this.oldAssigneeId = this.model.assignedToId;
                    }

                    if (!this.model.followUpAssigneeId) {
                        this.model.followUpAssigneeId = '';
                        this.model.followUpInstruction = '';
                    } else {
                        this.oldFollowerId = this.model.followUpAssigneeId;
                        this.oldFollowerInstruction = this.model.followUpInstruction;
                    }

                    if (this.model.status === 3) {
                        this.router.navigate(['/error/404']);
                    }

                    this.currentFacilityId = this.model.facilityId; 

                    this.loadWorkOrderStatuses();
                    setTimeout(() => {
                        this.setCategoryOptions();
                        this.loadUnits();
                        this.loadFacilityUsers(true);
                    }, 100);

                    this.isRecurring = this.model.isRecurring.toString();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    setCategoryOptions() {
        if (!this.workOrderCategories || this.workOrderCategories.length === 0 || !this.model.createdById) {
            return;
        }

        if (!this.model.createdAutomatically) {
            this.workOrderCategories = this.workOrderCategories.filter(c => c.type !== CategoryType.Reminder);
        }

        if (!this.model.workOrderCategoryId) {
            this.model.workOrderCategoryId = '';
            this.selectedCategory = null;
        } else {
            const filterCategory = this.workOrderCategories.find(s => s.id === this.model.workOrderCategoryId);
            this.selectedCategory = filterCategory;

            if (!filterCategory) {
                const disabledOption = new CategorySelectListItemModel();
                disabledOption.id = this.model.workOrderCategoryId;
                disabledOption.name = this.model.workOrderCategoryName;
                this.workOrderCategories.push(disabledOption);
            }

            this.checkForOverlockUnitsWO(this.selectedCategory);
        }

    }


    loadWorkOrderStatuses() {
        let item = new SelectListItemModel();
        item.key = '1';
        item.value = 'Not Started';
        this.workOrderStatuses.push(item);

        item = new SelectListItemModel();
        item.key = '2';
        item.value = 'In Progress';
        this.workOrderStatuses.push(item);

        item = new SelectListItemModel();
        item.key = '3';
        item.value = 'On Hold';
        this.workOrderStatuses.push(item);

        item = new SelectListItemModel();
        item.key = '4';
        item.value = 'Closed';
        this.workOrderStatuses.push(item);
    }

    onFacitlityChanged() {
        this.model.unitId = '';
        this.units = new Array<SelectListItemModel>();
        this.facilityUsers = new Array<AssignedUserModel>();
        this.selectedClientWatchers = [];
        if (!this.model.facilityId) {
            this.setAssignee(false);
            this.setFollower();
            this.facilityUsers = new Array<AssignedUserModel>();
            this.facilityGroups = new Array<AssignedUserGroupModel>();
            this.workOrderAssignees = new Array<AssigneeSelectListItemModel>();
            this.facilityWatchers = new Array<AssignedUserModel>();
            return;
        }
        this.loadUnits();
        this.loadFacilityUsers(false);
    }

    onPhotoSelect(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }

        const workOrderPhoto = new WorkOrderPhotoModel();
        workOrderPhoto.originalFileName = event.target.files[0].name;
        const file = event.target.files.item(0);
        this.blockUI.start();
        this.cloudStorageService.getSasToken()
            .subscribe(
                async (data) => {
                    const containerName = 'work-order';
                    const CloudConfig: UploadParams = {
                        sas: data.toString(),
                        storageAccount: this.appSettings.AzureAccount,
                        containerName: containerName
                    };
                    const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
                    const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
                    AppSettings.NonBearerUrl = blobUrl;

                    this.config = {
                        baseUrl: `${blobUrl}?`,
                        sasToken: CloudConfig.sas,
                        blockSize: 1024 * 64,
                        file: file,
                        complete: () => {
                            workOrderPhoto.fileName = fileName;
                            this.cloudStorageService.getPublicUrl(containerName, fileName)
                                .subscribe(res => {
                                    workOrderPhoto.fileUrl = res.toString();
                                });
                            this.model.photos.push(workOrderPhoto);
                            this.blockUI.stop();
                            this.workOrderPhoto.nativeElement.value = null;
                        },
                        error: (err) => {
                            console.log(err);
                            this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                            this.blockUI.stop();
                        },
                        progress: (percent) => {
                            this.uploadedPercent = percent;
                        }
                    };
                    setTimeout(() => {
                        this.blobService.upload(this.config);
                    });

                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    deletePhoto(fileKey: string, index: number) {
        this.model.photos.splice(index, 1);
    }

    updateWorkOrderStatus() {
        this.isOnHoldNoteAdded = true;
        this.submit();
    }

    workOrderRecurranceChange(workOrderRecurrenceModel: WorkOrderRecurrenceModel) {
        this.model.workOrderRecurrence = workOrderRecurrenceModel;
    }

    isRecurring: string;
    onRecurranceChange($event: any, ngModelDir: NgModel) {
        ngModelDir.control.markAsTouched();
        this.model.isRecurring = this.isRecurring === 'true';
        if (this.model.isRecurring) {
            this.workOrderRecurrenceModal.openModal(this.model.workOrderRecurrence);
        } else {
            this.model.workOrderRecurrence = null;
        }
    }

    openRecurrance() {
        this.workOrderRecurrenceModal.openModal(this.model.workOrderRecurrence);
    }

    onCategoryChange(f: NgForm, category: CategorySelectListItemModel) {
        this.selectedCategory = category;

        if (!category) {
            this.model.workOrderCategoryId = '';
            return;
        }

        this.model.workOrderCategoryId = category.id;
        this.model.dueDate = category && category.dueDateDuration
            ? moment.utc().add('days', category.dueDateDuration).format('YYYY-M-D')
            : null;
        f.form.controls['DueDate'].markAsUntouched();

        this.model.workOrderPriority = category.workOrderPriority || '';
        f.form.controls['workOrderPriority'].markAsUntouched();

        this.checkForOverlockUnitsWO(category);

        this.cd.detectChanges();
    }

    // Apply reminder settings for work order on the basis of the WO priority and assignee selected.
    setReminderInterval() {
        if(this.selectedAssignee && this.selectedAssignee.type == WorkOrderAssigneeType.group) {
            let group = this.facilityGroups.find(x => x.id == this.selectedAssignee.id); 
            if(this.model.workOrderPriority == "1")
                this.model.reminderInterval = group.lowPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "2")
                this.model.reminderInterval = group.mediumPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "3")
                this.model.reminderInterval = group.highPriorityWorkOrdersReminderInterval;
        }
        if(this.selectedAssignee && this.selectedAssignee.type == WorkOrderAssigneeType.user) {
            let user = this.facilityUsers.find(x => x.id == this.selectedAssignee.id);
            if(this.model.workOrderPriority == "1")
                this.model.reminderInterval = user.lowPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "2")
                this.model.reminderInterval = user.mediumPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "3")
                this.model.reminderInterval = user.highPriorityWorkOrdersReminderInterval;
        }
    }

    submit() {
        if (this.model.workOrderStatus === '3' && !this.isOnHoldNoteAdded && this.oldWorkOrderStatus != '3') {
            this.addWorkOrderNote.isWorkOrderStatusOnHold = true;
            this.addWorkOrderNote.openWorkOrderNoteModal();
            return;
        } else {
            this.isOnHoldNoteAdded = true;
        }

        if (this.selectedClientWatchers) {
            this.model.watchersIds = this.selectedClientWatchers.map(x => x.id);
        } else {
            this.model.watchersIds = [];
        }
        if(this.model.workOrderStatus == "4" && this.model.openSubTaskCount > 0){
            this.model.agreeCloseAllSubTaskAutomatically = confirm("Some tasks have not been completed. These will be closed along with the work order.");
        }
        this.blockUI.start();
        this.workOrderService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success('Work Order has been updated successfully');
                    setTimeout(() => {
                        this.router.navigate(['/work-order/detail', this.viewType, this.companyId, this.model.id]);
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onWatcherSelectOrDeselect() {
        this.isUserWatcher = this.isUserCreator ||
            !!this.selectedClientWatchers.find(x => x.id.toLowerCase() === this.currentUserId.toLowerCase());
    }

    onWatcherSelectAll(items: { id: string; fullName: string }[]) {
        this.isUserWatcher = this.isUserCreator ||
            !!items.find(x => x.id.toLowerCase() === this.currentUserId.toLowerCase());
    }

    onWatcherDeSelectAll() {
        this.isUserWatcher = this.isUserCreator;
    }

    checkForOverlockUnitsWO(category: CategorySelectListItemModel){
        this.isRecurringWOAllowed = !(category.type == CategoryType.OverlockUnits);
        this.isUnitSelectAllowed = !(category.type == CategoryType.OverlockUnits);
        this.isFacilitySelectAllowed = !(category.type == CategoryType.OverlockUnits);
        if(!this.isRecurringWOAllowed){
            this.isRecurring = 'false';
            this.model.isRecurring = false;
            this.model.workOrderRecurrence = null;
        }
        if(!this.isUnitSelectAllowed){
            this.model.unitId = ""; 
        }
        if(!this.isFacilitySelectAllowed){
            this.model.facilityId = this.currentFacilityId;
            this.onFacitlityChanged()
        }
    }
}

