export class VendorImageUploadModel {
    public vendorId: number;
    public file: any;
    public fileName: string;
    public fileUrl: string;
    public originalFileName: string;
    public processingWidth: number;
    public processingHeight: number;
    public dimensionX1: number;
    public dimensionX2: number;
    public dimensionY1: number;
    public dimensionY2: number;
    public fileUploadPercent: number;

    // additional properties
    public canEditProfileImage: boolean;
    
    constructor() {
        this.fileUploadPercent = 0;
    }
}
