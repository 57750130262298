import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountResetModel, LeftBannerModel } from '../../../models';
import { AccountService } from '../../../services';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-account-reset',
    templateUrl: './account.reset.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})

export class AccountResetComponent {
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    model: AccountResetModel = new AccountResetModel();
    leftBannerModel: LeftBannerModel = new LeftBannerModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private accountService: AccountService) {

        if (this.appUtils.isUserAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.route.params.subscribe((params) => {
            this.model.key = params['key'];
            this.model.secret = params['secret'];
        });
    }

    submit() {
        if (this.model.newPassword !== this.model.confirmPassword) {
            return;
        }
        this.blockUI.start();
        this.accountService.reset(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/account/login']);
                    }, 100);
                    setTimeout(() => {
                        this.toastr.success('Your account has been reset successfully. Please login to continue');
                    }, 500);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
