<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-work-order-note [companyId]="companyId" [workOrderId]="workOrderId">
            </app-breadcrumb-work-order-note>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                All Notes <span *ngIf="notes.length === 1">(1 Note)</span> <span
                                    *ngIf="notes.length > 1">({{notes.length}} Notes)</span>
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-primary" (click)="openWorkOrderNoteModal()" *ngIf="showActionColumn">
                                Add
                            </button>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of notes; let $index=index">
                                <div class="row">
                                    <div class="col-10">
                                        <div>
                                            <div class="word-wrap dis-inline" [innerHTML]="item.note | hyperlink : 100">
                                            </div>
                                            <a href="javascript:0"
                                                *ngIf="item.note.length > 100" 
                                                (click)="showNote(workOrderNoteDetailModal, item)">see more</a>
                                        </div>
                                        
                                        <p class="badge badge-light m-b-5">
                                            <span>{{item.createdByName}}</span>
                                            ({{item.createdOn}})
                                        </p>
                                        <br />
                                        <ng-template ngFor let-readReceipt [ngForOf]="item.userReadReceipts">
                                            <p class="badge badge-secondary m-b-5 m-r-2" *ngIf="readReceipt.id !== currentUserId">
                                                {{readReceipt.fullName}}
                                            </p>
                                        </ng-template>
                                    </div>
                                    <div class="col-2 text-right">
                                        <button type="button" class="btn btn-outline-primary btn-icon"
                                            (click)="openWorkOrderNoteEditModal(workOrderNoteEditModal,item)">
                                            <i class="fa fa-edit">
                                            </i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="(notes.length - 1) > $index">
                                    <div class="col-12">
                                        <div class="kt-divider m-t-10 m-b-10">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p *ngIf="notes.length === 0">No note found</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-work-order-note-add #addWorkOrderNote [workOrderId]="workOrderId" (reloadNotes)="loadNotes()">
</app-work-order-note-add>

<ng-template #workOrderNoteEditModal let-modal>
    <form name="form" #noteModalF="ngForm" (ngSubmit)="noteModalF.form.valid && editNote(noteModalF)" novalidate
        *blockUI="'container-blockui-note'">
        <div class="modal-header">
            <h5 class="modal-title">Edit Note</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeWorkOrderNoteEditModal(noteModalF)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group"
                    [ngClass]="{ 'validation-error' : noteModalF.submitted && workOrderNote.invalid}">
                    <label class="control-label">Note *</label>
                    <textarea name="workOrderNote" class="form-control" rows="5" autocomplete="Off"
                        [(ngModel)]="note" #workOrderNote="ngModel" required>
                        </textarea>
                    <div class="validation-error-message" *ngIf="noteModalF.submitted && workOrderNote.invalid">
                        Note is required
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="closeWorkOrderNoteEditModal(noteModalF)">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #workOrderNoteDetailModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Note</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="note | hyperlink"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Close</button>
      </div>
</ng-template>