import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { KioskAddModel, KioskChangeApplicationModel, KioskTagAddModel } from '../models';
import { AppSettings } from '../helpers';
import { time } from 'highcharts';
import { timeout } from 'rxjs/operators';

@Injectable()
export class KioskService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    add(model: KioskAddModel) {
        return this.http.post(environment.apiBaseUrl + 'kiosk/add', model);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'kiosk/delete/' + id, null);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'kiosk/get-detail/' + id);
    }

    changeApplicationType(model: KioskChangeApplicationModel) {
        return this.http.post(environment.apiBaseUrl + 'kiosk/change-application-type', model);
    }

    applyChangesToKiosk(registrationKey: string) {
        return this.http.post(environment.apiBaseUrl + 'kiosk/apply-changes-to-kiosk/' + registrationKey, null);
    }

    getCountByCompany(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'kiosk/get-count-by-company/' + companyId);
    }

    getHealthStatisticsByCompany(companyId: number, considerOnlyPersonalData: boolean, filterCallMachineData: boolean) {
        return this.http.get(`${environment.apiBaseUrl}kiosk/get-health-statistics/${companyId}/${considerOnlyPersonalData}/${filterCallMachineData}`);
    }

    getOfflineKioskLogs(companyId: number, month: number, considerOnlyPersonalData: boolean) {
        return this.http.get(`${environment.apiBaseUrl}kiosk/get-offline-kiosk-logs/${companyId}/${month}/${considerOnlyPersonalData}`);
    }

    saveTag(kioskTagAddModel: KioskTagAddModel) {
        return this.http.post(environment.apiBaseUrl + 'kiosk/tag', kioskTagAddModel);
    }

    getApplicationTypes() {
        return this.http.get(`${environment.apiBaseUrl}kiosk/get-application-types`);
    }

    getActivityLogs(kioskId: number) {
        return this.http.get(environment.apiBaseUrl + 'kiosk/get-activity-logs/' + kioskId);
    }
}
