import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { NotificationService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { Notification } from 'src/models';
declare var appConfig: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})

export class NotificationComponent implements OnInit {
  @Output() updateNotificationCount = new EventEmitter<number>();
  @Output() closeNotificationDropdown = new EventEmitter<any>();
  @BlockUI('container-notification-blockui') blockUI: NgBlockUI;

  roleMatrix: RoleMatrix;
  notifications: Array<Notification> = new Array<Notification>();

  pageNumber = 1;
  pageSize = 100;
  totalPages = 0;
  totalUnreadNotifications = 0;

  constructor(private router: Router,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private appUtils: AppUtils) {
    this.roleMatrix = new RoleMatrix();
  }

  ngOnInit() {
    this.loadNotifications(this.pageNumber);
    setTimeout(() => {
      appConfig.initKTDefaults();
    });
  }

  loadNotifications(pageNumber) {
    this.blockUI.start();
    this.pageNumber = pageNumber;
    this.notificationService.getPagedResult(this.pageNumber, this.pageSize)
      .subscribe((data) => {
        this.blockUI.stop();
        data.notifications.map((item) => {
          item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
        });

        if (this.pageNumber === 1) {
          this.notifications = data.notifications;
        } else {
          this.notifications.push(...data.notifications);
        }


        this.totalPages = data.totalPages;
        this.setTotalUnreadNotifications(data.totalUnreadNotifications);
      },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  doAction(notification: any) {

    if (notification.action) {

      if (notification.action.toString().indexOf('?') === -1) {

        this.router.navigate([notification.action]);

      } else {

        const actionString = notification.action.toString();
        const baseUrl = actionString.substring(0, actionString.indexOf('?'));
        const queryString = actionString.substring(actionString.indexOf('?') + 1);
        const tempArr = queryString.split('&');

        let queryParamString = '{';

        tempArr.map((queryParam: string) => {
          const innerArr = queryParam.split('=');
          queryParamString += `"${innerArr[0]}":"${innerArr[1]}",`;
        });

        queryParamString = queryParamString.substring(0, queryParamString.length - 1);
        queryParamString += '}';
        console.log(queryParamString);
        this.router.navigate([baseUrl], { queryParams: JSON.parse(queryParamString) });
      }
    }

    this.closeNotificationDropdown.next();

    this.markAsRead(notification);
  }

  markAsRead(notification: Notification) {
    if (notification.isRead) {
      return;
    }
    this.blockUI.start();
    this.notificationService.markAsRead(notification.id)
      .subscribe(
        () => {
          this.blockUI.stop();
          notification.isRead = true;
          this.decrementTotalUnreadNotifications();
          this.closeNotificationDropdown.next();
        },
        error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
  }

  markAllAsRead() {
    this.blockUI.start();
    this.notificationService.markAllAsRead()
      .subscribe(
        () => {
          this.blockUI.stop();
          this.notifications.forEach(x => x.isRead = true);
          this.setTotalUnreadNotifications(0);
          this.closeNotificationDropdown.next();
        },
        error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
  }

  removeNotification(notification: Notification, index: number) {
    if (!confirm('Are you sure you want to delete?')) {
      this.closeNotificationDropdown.next();
      return;
    }
    this.blockUI.start();
    this.notificationService.removeAssignee(notification.id)
      .subscribe(
        () => {
          this.blockUI.stop();
          this.notifications.splice(index, 1);
          if (!notification.isRead) {
            this.decrementTotalUnreadNotifications();
          }

          this.closeNotificationDropdown.next();
        },
        error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
  }

  loadMoreNotifications() {
    if (this.pageNumber >= this.totalPages) {
      return;
    }

    this.pageNumber++;
    this.loadNotifications(this.pageNumber);
  }

  private decrementTotalUnreadNotifications() {
    this.setTotalUnreadNotifications(--this.totalUnreadNotifications);
  }

  private setTotalUnreadNotifications(count) {
    this.totalUnreadNotifications = count;
    this.updateNotificationCount.next(this.totalUnreadNotifications);
  }
}
