<div class="kt-widget24 widget-tile m-b-15">
    <div class="kt-widget24__details">
        <div class="kt-widget24__info">
            <h4 class="kt-widget24__title text-center fs-20">
                <a [routerLink]="['/', 'work-order-comp-categories', companyId]">Categories</a>
            </h4>
        </div>
    </div>
    <div class="separator m-t-25 m-b-15">
    </div>
    <div class="kt-widget24__action">
        <span class="kt-widget24__change">
            Total <span class="m-l-10">{{totalCategoriesCount}}</span>
        </span>
        <span class="kt-widget24__number">
            Active <span class="m-l-10">{{activeCategoriesCount}}</span>
        </span>
    </div>
</div>
