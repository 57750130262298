import { Component, HostBinding, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { WorkOrderCategoryAddModel } from '../../../../models';
import { AppUtils, RoleMatrix } from '../../../../helpers';
import { WorkOrderService } from 'src/services';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-work-order-category-add',
    templateUrl: './work.order.category.add.component.html'
})

export class WorkOrderCategoryAddComponent {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @Output() added = new EventEmitter();
    model: WorkOrderCategoryAddModel = new WorkOrderCategoryAddModel();

    constructor(private router: Router,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private workOrderService: WorkOrderService) {
        this.roleMatrix = new RoleMatrix();
        if (!this.roleMatrix.canAddWoCategory) {
            this.router.navigate(['/error/403']);
        }
    }

    submit(f: NgForm) {
        this.blockUI.start();
        this.workOrderService.addGlobalCategory(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.model = new WorkOrderCategoryAddModel();
                        f.resetForm();
                        this.toastr.success('Category has been added successfully.');
                        this.added.emit();
                    }, 10);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
