import { Component, HostBinding, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, RoleMatrix, AppSettings } from '../../../helpers';
import { MoveInService, CloudStorageService } from 'src/services';
import { UploadParams, BlobService, UploadConfig } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';
import { SkinningColorModel, SkinningLogoModel, MasterDataModel, ConfigurationModel } from 'src/models';


@Component({
    selector: 'app-movein-skinning',
    templateUrl: './movein.skinning.component.html'
})

export class MoveinSkinningComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-skinning') blockUI: NgBlockUI;

    @ViewChild('documentFileName') documentFileName: ElementRef;

    containerName = 'move-in';

    configurationModel: ConfigurationModel = new ConfigurationModel();
    skinningColorModel: SkinningColorModel = new SkinningColorModel();
    skinningLogoModel: SkinningLogoModel = new SkinningLogoModel();

    masterDataModel: MasterDataModel = new MasterDataModel();

    documentUploadPercent: number = 0;
    isDocumentUploading: boolean = false;
    config: UploadConfig;

    color: any;

    constructor(
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private cloudStorageService: CloudStorageService,
        private blobService: BlobService,
        private appSettings: AppSettings,
        private moveInService: MoveInService) {

        this.route.params.subscribe((params) => {
            this.masterDataModel.companyId = params['companyId'];
            this.masterDataModel.facilityId = params['facilityId'];
            this.masterDataModel.kioskId = params['kioskId'];
        });

        this.roleMatrix = new RoleMatrix();
    }

    ngOnInit() {
        this.loadConfiguration();
    }

    onAttachmentSelected(event: any) {
        const file = event.target.files.item(0);

        this.blockUI.start();
        this.cloudStorageService.getSasToken().subscribe(async (data) => {

            const CloudConfig: UploadParams = {
                sas: data.toString(),
                storageAccount: this.appSettings.AzureAccount,
                containerName: this.containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
            AppSettings.NonBearerUrl = blobUrl;

            this.config = {
                baseUrl: `${blobUrl}?`,
                sasToken: CloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.blockUI.stop();

                    this.documentFileName.nativeElement.value = null;

                    this.skinningLogoModel.skinningLogoOriginalFileName = file.name;
                    this.skinningLogoModel.skinningLogoFileName = fileName;
                    this.saveLogoModel();
                },
                error: (err) => {
                    console.log(err);
                    this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.documentUploadPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(this.config);
            });
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }

    deleteAttachment() {
        this.skinningLogoModel.skinningLogoFileName = null;
        this.skinningLogoModel.skinningLogoOriginalFileName = null;
        this.blockUI.start();
        this.moveInService.saveAmenitySkinningLogo(this.masterDataModel, this.skinningLogoModel)
            .subscribe((data: any) => {
                this.blockUI.stop();
                this.toastr.success(`Logo has been removed successfully.`);
                this.skinningLogoModel.skinningLogoFileUrl = null;
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    deletePrimaryColor() {
        this.saveColorModel(null);
    }

    loadConfiguration() {
        this.blockUI.start();
        this.moveInService.getConfiguration(this.masterDataModel)
            .subscribe(
                (data: any) => {
                    Object.assign(this.configurationModel, data);

                    this.skinningColorModel.configurationId = this.configurationModel.id;
                    this.skinningColorModel.skinningPrimaryColor = this.configurationModel.skinningPrimaryColor;

                    this.skinningLogoModel.configurationId = this.configurationModel.id;
                    this.skinningLogoModel.skinningLogoFileName = this.configurationModel.skinningLogoFileName;
                    this.skinningLogoModel.skinningLogoOriginalFileName = this.configurationModel.skinningLogoOriginalFileName;
                    this.skinningLogoModel.skinningLogoFileUrl = this.configurationModel.skinningLogoFileUrl;

                    this.color = this.skinningColorModel.skinningPrimaryColor;
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                }, () => {
                    this.blockUI.stop();
                });
    }

    saveLogoModel() {
        this.blockUI.start();
        this.moveInService.saveAmenitySkinningLogo(this.masterDataModel, this.skinningLogoModel)
            .subscribe((data: any) => {
                if (this.skinningLogoModel.skinningLogoFileUrl == null) {
                    this.toastr.success(`Logo has been uploaded successfully.`);
                } else {
                    this.toastr.success(`Logo has been changed successfully.`);
                }
                //Reload configuration
                this.loadConfiguration();
            }, error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }, () => {
                this.blockUI.stop();
            });
    }

    saveColorModel($event: string) {
        let addColor = $event != null && this.skinningColorModel.skinningPrimaryColor == null;
        this.skinningColorModel.skinningPrimaryColor = $event;
        this.blockUI.start();
        this.moveInService.saveAmenitySkinningColor(this.masterDataModel, this.skinningColorModel)
            .subscribe((data: any) => {
                if (this.skinningColorModel.skinningPrimaryColor == null) {
                    this.toastr.success('Primary color has been removed successfully.');
                } else if (addColor) {
                    this.toastr.success('Primary color has been uploaded/added successfully.');
                } else {
                    this.toastr.success('Primary color has been changed successfully.');
                }

                //Reload configuration
                this.loadConfiguration();
            }, error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }, () => {
                this.blockUI.stop();
            });
    }

    colorPickerCancel() {
        this.color = this.skinningColorModel.skinningPrimaryColor;
    }
}
