import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FacilityAssignedToUserGroupModel, UserGroupModel, RecordStatus } from 'src/models';

@Component({
    selector: 'app-user-group-challenge-delete',
    templateUrl: './user.group.challenge.delete.component.html'
})
export class UserGroupChallengeDeleteComponent {
    @BlockUI('container-blockui-delete-popup') blockUI: NgBlockUI;
    @ViewChild('groupDeleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @Output() delete = new EventEmitter<UserGroupModel>();
    modalReference: NgbModalRef;
    deleteConfirmationMessage: string;
    userGroupModel: UserGroupModel;

    constructor(private modalService: NgbModal) { }

    openDefaultDeleteConfirmationModal(userGroupModel: UserGroupModel) {
        this.userGroupModel = userGroupModel;
        this.userGroupModel.raiseEvent = true;
        this.modalReference = this.modalService.open(this.deleteConfirmationModal);
        this.deleteConfirmationMessage =
            `Are you sure you want to delete the user group ${userGroupModel.name}? This action is irreversible.`;
    }

    openDeleteConfirmationModal(userGroupModel: UserGroupModel, assignedFacilities: FacilityAssignedToUserGroupModel[]) {
        this.userGroupModel = userGroupModel;
        this.userGroupModel.raiseEvent = true;
        this.modalReference = this.modalService.open(this.deleteConfirmationModal);
        assignedFacilities = assignedFacilities.filter(x => x.status == RecordStatus.Active);        
        let msg = assignedFacilities.length > 1 ?  `${assignedFacilities.length} facilities` : `${assignedFacilities[0].name}`
        this.deleteConfirmationMessage = `This user group ${userGroupModel.name} is assigned to ${msg}. 
         If you delete this user group, 
         all user assigned to the facility will be 
         removed and workorders for customer 
         complaints and move-outs will be assigned to account owner`;
    }

    openDeleteConfirmationForMultipleModal(userGroupModel: UserGroupModel, assignedFacilities: FacilityAssignedToUserGroupModel[]) {
        this.userGroupModel = userGroupModel;
        this.userGroupModel.raiseEvent = true;
        this.modalReference = this.modalService.open(this.deleteConfirmationModal);
        assignedFacilities = assignedFacilities.filter(x => x.status == RecordStatus.Active);       
        let msg = assignedFacilities.length > 1 ?  `${assignedFacilities.length} facilities` : `${assignedFacilities[0].name}`
        this.deleteConfirmationMessage = `This user group ${userGroupModel.name} is assigned to ${msg}.
         If you delete this user group, 
            workorders for customer complaints and 
            move-outs will be assigned to account owner`;
    }

    deleteGroup() {
        this.close();
        setTimeout(() => {
            this.delete.emit(this.userGroupModel);
        });
    }

    close() {
        this.modalReference.close();
    }
}
