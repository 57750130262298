import { Component, HostBinding, ViewChild, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, UnitFilterModel, RecordStatus } from '../../../models';
import { UnitService, FacilityService } from '../../../services';
import { AppUtils, AppSettings, RoleMatrix } from '../../../helpers';
import { isNullOrUndefined } from '../../../helpers/tools';

@Component({
    selector: 'app-unit-manage',
    templateUrl: './unit.manage.component.html'
})

export class UnitManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @Input() companyId: number;
    @Input() facilityId: number;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-unit') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    @ViewChild('unitChallengeDeleteModal', { static: true }) unitChallengeDeleteModal: any;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    gridState: any;
    lastGridState: any;
    gridStateName = 'gridStateUnit';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: UnitFilterModel = new UnitFilterModel();
    showActionColumn = false;
    unitIdToDelete: number;
    deletingDataIndex: number;
    facilityStatus: RecordStatus;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastr: ToastrService,
        private unitService: UnitService,
        private facilityService: FacilityService,
        private appSettings: AppSettings,
        private appUtils: AppUtils) {

        this.facilityStatus = RecordStatus.Active;

        this.roleMatrix = new RoleMatrix();

        if (this.roleMatrix.canEditUnit
            || this.roleMatrix.canChangeStatusOfUnit
            || this.roleMatrix.canDeleteUnit) {
            this.showActionColumn = true;
        }

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/unit/detail/') !== -1
                    || event.url.indexOf('/unit/add/') !== -1
                    || event.url.indexOf('/unit/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
        });
    }


    handleOnNavigationEnd() {
        if (!this.lastGridState) {
            return;
        }

        this.rowIndex = this.lastGridState.start;
        this.pageLength = this.lastGridState.length;

        if (this.lastGridState.status) {
            this.filterModel.status = this.lastGridState.status;
        }
        if (this.lastGridState.filterKey) {
            this.filterModel.filterKey = this.lastGridState.filterKey;
        }
        console.log(this.lastGridState);
        const page = this.lastGridState.start / this.lastGridState.length;
        console.log(page);
        this.dtInstance.page(Math.floor(page));
        this.dtInstance.ajax.reload(null, false);
        this.lastGridState = null;

    }

    ngOnInit(): void {

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.filterModel.facilityId = this.facilityId;

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter units...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[0, 'asc']],
            // stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                    self.lastGridState = gridState;
                    console.log(gridState);
                } else {
                    dataTablesParameters.facilityId = self.filterModel.facilityId;
                    dataTablesParameters.status = self.filterModel.status;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                self.http.post<DataTableResponseModel>
                    (environment.apiBaseUrl + 'unit/paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Unit Name',
                    width: '20%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'totalWorkOrders',
                    title: 'Work Orders',
                    width: '15%'
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        const statusHtml = self.facilityStatus !== RecordStatus.Inactive
                            ? (row.status === 2
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === 3
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '')
                            : '';

                        const htmlString = (self.roleMatrix.canChangeStatusOfUnit ? statusHtml : '')
                            + (self.roleMatrix.canEditUnit && row.status !== 3
                                ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteUnit
                                ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');

                        return row.isDefault ? null : htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/unit/edit', self.companyId, self.facilityId, data.id]);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.challengeDelete(data.id, data.name, dataIndex);
                });

                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/unit/detail', self.companyId, self.facilityId, data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.unitService.toggleStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(`Unit has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    challengeDelete(id: number, unitName: string, dataIndex: number): void {
        this.unitIdToDelete = id;
        this.deletingDataIndex = dataIndex;
        this.unitChallengeDeleteModal.openUnitDeleteConfirmationModal(id, unitName);
    }

    delete(): void {
        this.blockUI.start();
        this.unitService.delete(this.unitIdToDelete).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (this.deletingDataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.toastr.success('Unit has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    doFilter() {
        this.dtInstance.ajax.reload();
    }

    resetFilter() {
        this.filterModel.status = '';
        this.filterModel.filterKey = '';
        localStorage.removeItem(this.gridStateName);
        this.doFilter();
    }

    refreshUnitsAfterFacilityToggled(facilityStatus: RecordStatus) {
        this.facilityStatus = facilityStatus;
        if (facilityStatus === 2) {
            this.roleMatrix = new RoleMatrix();
            if (this.roleMatrix.canEditUnit
                || this.roleMatrix.canChangeStatusOfUnit
                || this.roleMatrix.canDeleteUnit) {
                this.showActionColumn = true;
            }
        } else {
            this.updateShowActionColumn(this.facilityStatus);
        }
        this.doFilter();
    }

    updateShowActionColumn(status: RecordStatus) {
        this.facilityStatus = status;

        this.roleMatrix.configureAllowActionsForUnit();
        if (this.roleMatrix.canEditUnit
            || this.roleMatrix.canChangeStatusOfUnit
            || this.roleMatrix.canDeleteUnit) {
            this.showActionColumn = true;
        } else {
            this.showActionColumn = false;
        }

        setTimeout(() => {
            if (!isNullOrUndefined(this.dtInstance)) {
                this.dtInstance.ajax.reload(null, false);
                this.dtInstance.column(3).visible(this.showActionColumn);
            }
        }, 100);
    }
}
