import { Component, HostBinding, OnInit, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { FacilityService, KioskService, CompanyService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { SelectListItemModel, KioskAddModel } from '../../../models';

@Component({
    selector: 'app-kiosk-add',
    templateUrl: './kiosk.add.component.html'
})

export class KioskAddComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    model: KioskAddModel = new KioskAddModel();
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    constructor(private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private facilityService: FacilityService,
        private kioskService: KioskService,
        private companyService: CompanyService) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddKiosk) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.model.companyId = Number(params['companyId']);
        });
        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.checkCompanyStatus();
        this.loadFacilities();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.model.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.router.navigate(['/error/404']);
                }
            }
        );
    }

    loadFacilities() {
        this.blockUI.start();
        this.facilityService.getSelectListItemsImportedOnly(this.model.companyId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.facilities, data);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        this.blockUI.start();
        this.kioskService.add(this.model)
            .subscribe(
                (data) => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/kiosk/detail', this.model.companyId, this.model.facilityId, data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Kiosk has been registered successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
