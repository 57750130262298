<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breacrumb-company-user-detail [companyId]="model.companyId" [filteredUserType]="userType">
            </app-breacrumb-company-user-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-10" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                User Detail
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold mr-3"
                                (click)="followUser()"
                                *ngIf="showFollowButton && (model.status === 2 || model.isFollowing)"
                                [disabled]="model.status === 3 && model.isFollowing">
                                {{ model.isFollowing ? 'Unfollow' : 'Follow' }}
                            </button>
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown" *ngIf="showActionColumn">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right" *ngIf="showActionColumn">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item"
                                        *ngIf="(model.role === 'OrgAdmin' && roleMatrix.canEditOrgAdmin) || (model.role === 'OrgUser' && roleMatrix.canEditOrgUser)">
                                        <a [routerLink]="['/company-user/edit',model.id]"
                                            [queryParams]="{companyId: model.companyId, userType: userType}"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="(model.role === 'OrgAdmin' && roleMatrix.canChangeStatusOfOrgAdmin) || (model.role === 'OrgUser' && roleMatrix.canChangeStatusOfOrgUser)">
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===3">
                                            <em class="kt-nav__link-icon fa fa-check"></em>
                                            <span class="kt-nav__link-text">
                                                Activate
                                            </span>
                                        </a>
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===2">
                                            <em class="kt-nav__link-icon fa fa-ban"></em>
                                            <span class="kt-nav__link-text">
                                                Deactivate
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="(model.role === 'OrgAdmin' && roleMatrix.canDeleteOrgAdmin) || (model.role === 'OrgUser' && roleMatrix.canDeleteOrgUser)">
                                        <a href="javascript:;" class="kt-nav__link" (click)="delete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="(model.role === 'OrgAdmin' && roleMatrix.canResetOrgAdminPassword) || (model.role === 'OrgUser' && roleMatrix.canResetOrgUserPassword)">
                                        <a href="javascript:;" class="kt-nav__link" (click)="resetPassword()">
                                            <em class="kt-nav__link-icon fa fa-unlock-alt"></em>
                                            <span class="kt-nav__link-text">
                                                Reset Password
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-form detail-form">
                        <div class="kt-portlet__body" *ngIf='isModelLoaded'>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Profile Image:</label>
                                <div class="col-md-9">
                                    <app-user-image-upload [model]='imageUploadModel'></app-user-image-upload>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">First Name:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.firstName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Last Name:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.lastName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Email:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.email}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Phone:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.phone}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Role:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.normalizedRole}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Time Zone:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{timeZoneFormattedName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Status:</label>
                                <div class="col-md-6">
                                    <label class="kt-badge kt-badge--success kt-badge--inline"
                                        *ngIf="model.status === 2">Active</label>
                                    <label class="kt-badge kt-badge--danger kt-badge--inline"
                                        *ngIf="model.status === 3">Inactive</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Created On:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.createdOn}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Company Account ID:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">ID{{model.companyId}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label break-word">Notification Preferences :</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data" [innerHTML]="notificationPreferencesText"></p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label break-word">Notification Preference as watchers:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data" [innerHTML]="notificationPreferencesAsWatcherText"></p>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="userGroups.length">
                                <label class="col-md-3 kt-portlet__label">User Groups Assigned:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">
                                        <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                            *ngFor="let item of userGroups">
                                            {{item}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Facility Assigned:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">
                                        <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                            *ngFor="let item of model.assignedFacilities">
                                            {{item.name}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot d-none" *ngIf='isModelLoaded'>
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/company-user/manage-administrator']"
                                    [queryParams]="{companyId: model.companyId, userType: userType}"
                                    class="btn btn-secondary m-r-5" *ngIf="userType === 'admin'">
                                    Back
                                </a>
                                <a [routerLink]="['/company-user/manage-personnel']"
                                    [queryParams]="{companyId: model.companyId, userType: userType}"
                                    class="btn btn-secondary m-r-5" *ngIf="userType === 'user'">
                                    Back
                                </a>
                                <a [routerLink]="['/company-user/manage']" [queryParams]="{companyId: model.companyId}"
                                    class="btn btn-secondary m-r-5" *ngIf="userType === 'any'">
                                    Back
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>