export class WorkOrderRecurrenceModel {
    id: number;
    workOrderId: number;
    isSunday: boolean;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    repeatEveryNWeek: number;
    repeatEveryMonthOn: Array<number>;
    repeatEveryNMonth: number;
    terminateOn: string;
    startOn:string;
    frequency:number;
    isProcessed:boolean;

    constructor() {
        this.frequency = 2;//Weekly
        this.terminateOn = null;
        this.repeatEveryMonthOn = new Array<number>();
        this.repeatEveryNWeek = null;
        this.repeatEveryNMonth = null;
        this.isProcessed = false;
    }
}