import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { WorkOrderFilterModel, SelectListItemModel, AssignedUserModel, CategorySelectListItemModel, AssignedUserGroupModel, AssigneeListModel } from '../../../models';
import { AccountService, FacilityService, MasterService, UnitService, WorkOrderService } from '../../../services';
import { AppUtils } from '../../../helpers';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-work-order-filter-box',
    templateUrl: './work.order.filter.box.component.html'
})
export class WorkOrderFilterBoxComponent implements OnInit, OnDestroy {
    @BlockUI('container-blockui-filter-box') blockUI: NgBlockUI;
    @Input() companyId: number;
    @Input() isClosedOnly: boolean;
    @Input() filterModel: WorkOrderFilterModel = new WorkOrderFilterModel();
    @Input() allowBulkChange: boolean = false;
    @Input() viewType: number = 1;
    @Output() applyFilterEmitter = new EventEmitter<boolean>();

    multiSelectDropdownConfigs = {};
    isDropdownDisabled = false;
    status: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedStatus: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    priority: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedPriority: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedFacilities : Array<SelectListItemModel> = new Array<SelectListItemModel>();
    units: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedUnits : Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedAssignedUsers : Array<AssignedUserModel> = new Array<AssignedUserModel>();
    assignedUsers: Array<AssigneeListModel> = new Array<AssigneeListModel>();
    createdByUsers: Array<AssignedUserModel> = new Array<AssignedUserModel>();
    selectedCreatedByUsers : Array<AssignedUserModel> = new Array<AssignedUserModel>();

    selectedCategory: Array<CategorySelectListItemModel> = new Array<CategorySelectListItemModel>();
    workOrderCategories: Array<CategorySelectListItemModel> = new Array<CategorySelectListItemModel>();

    groupedFacilities: Array<any> = new Array<any>();
    groupedAssignedUsers: Array<any> = new Array<any>();
    groupedUnits: Array<any> = new Array<any>();
    groupedWorkOrderCategories: Array<any> = new Array<any>();

    gridStateName = 'gridStateWorkOrders';
    queryParamCategory: string;

    subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private unitService: UnitService,
        private accountService: AccountService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private masterService: MasterService) {
        this.route.queryParams.subscribe((params) => {
            this.queryParamCategory = params['category'];
        });

        if (this.isClosedOnly) {
            this.filterModel.statusIds = [];
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
          };

        this.status.push({id:"1",name:"Not Started",key:"1",value:"Not Started"});
        this.status.push({id:"2",name:"In Progress",key:"2",value:"In Progress"});
        this.status.push({id:"3",name:"On Hold",key:"3",value:"On Hold"});

        this.priority.push({id:"1",name:"Low",key:"1",value:"Low"});
        this.priority.push({id:"2",name:"Medium",key:"2",value:"Medium"});
        this.priority.push({id:"3",name:"High",key:"3",value:"High"});
    }

    ngOnInit(): void {
        this.loadWorkOrderCategories();
        this.loadAssignedUsers(true);
        this.loadCreatedByUsers(true);

        const subscription = this.masterService.WorkOrderShowPersonnalData$
            .subscribe(showPersonalData => this.loadFacilities(showPersonalData));
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    loadFacilities(showPersonalData: boolean) {
        this.facilityService.getSelectListItemsWithAssignedToWorkOrder(this.companyId, showPersonalData)
            .subscribe(
                (data: any) => {
                    this.facilities = data;
                    if (this.filterModel.facilityIds && this.filterModel.facilityIds.length > 0 && !this.facilities.find(f => this.filterModel.facilityIds.includes(f.id))) {
                        this.filterModel.facilityIds = [];
                        this.onFacitlityChanged(true, true);
                    }
                    this.applyFilter(false);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadUnits(showLoader: boolean, loadInitFilter: boolean = false) {
        if (showLoader === true) {
            this.blockUI.start();
        }
        this.unitService.getSelectListItemsWithAssignedToWorkOrder(this.filterModel.facilityIds.map(Number))
            .subscribe(
                (data: any) => {
                    if (showLoader === true) {
                        this.blockUI.stop();
                    }
                    this.units = data;
                    if(loadInitFilter){
                        this.selectedUnits = this.units.filter(x=> this.filterModel.unitIds.indexOf(x.id) != -1)
                            .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });
                    }
                },
                error => {
                    if (showLoader === true) {
                        this.blockUI.stop();
                    }
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadAssignedUsers(loadAllCompanyUsers: boolean, loadInitFilter: boolean = false) {
        if (loadAllCompanyUsers === true) {
            this.accountService.getSelectItemsByCompanyIdWithAssignedToWorkOrder(Number(this.filterModel.companyId))
                .subscribe(
                    (data: any) => {
                        this.assignedUsers = data.users.map(({id,firstName,lastName}) => ({id:id,name:firstName+' '+(lastName == null || lastName == '' ? '':lastName),isUserGroup:false,image:location.origin + '/assets/media/icons/user.png'}));
                        this.assignedUsers = data.userGroups.map(({id,name}) => ({id:id,name:name,isUserGroup:true,image:location.origin + '/assets/media/icons/user-group.png'}))
                                                .concat(this.assignedUsers);
                        if(loadInitFilter){
                            this.selectedAssignedUsers = this.assignedUsers.filter(x => this.filterModel.assigneeIds.indexOf(x.id) != -1)
                                .map((y) => { return { id: y.id, name: y.name } as AssignedUserModel; });
                        }
                    },
                    error => {
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        } else {
            this.facilityService.getAssignedUsersListForMultiSelect(this.filterModel.facilityIds.map(Number), true, true)
                .subscribe(
                    (data: any) => {
                        this.assignedUsers = data.users.filter(x=>x.isSystem == false).map(({id,firstName,lastName})=>({id:id,name:firstName+' '+(lastName == null || lastName == ''? '':lastName),isUserGroup:false,image:location.origin + '/assets/media/icons/user.png'}));
                        this.assignedUsers = data.userGroups.map(({id,name})=>({id:id,name:name,isUserGroup:true,image:location.origin + '/assets/media/icons/user-group.png'})).concat(this.assignedUsers);
                        if(loadInitFilter){
                            this.selectedAssignedUsers = this.assignedUsers.filter(x => this.filterModel.assigneeIds.indexOf(x.id) != -1)
                                .map((y) => { return { id: y.id, name: y.name } as AssignedUserModel; });
                        }
                    },
                    error => {
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        }
    }

    get getItems() {
        return this.assignedUsers.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
      }

    loadWorkOrderCategories() {
        this.workOrderService.getCategorySelectListItems(this.companyId, true, !this.isClosedOnly)
            .subscribe(
                (data: any) => {    
                this.workOrderCategories = data.map(
                    ({id,name,type,dueDateDuration,workOrderPriority,isUnrentable})=>({
                        id:id,
                        type:type,
                        name: !!isUnrentable ? name +' ' +'(Unrentable)':name,
                        dueDateDuration:dueDateDuration,
                        workOrderPriority:workOrderPriority,
                        isUnrentable:isUnrentable
                    }));
                    if (this.queryParamCategory) {
                        const categorys = this.workOrderCategories
                            .find(x => x.name.toLowerCase() === this.queryParamCategory.toLowerCase());
                        const categoryArray=[categorys.id];
                        if (categoryArray.length > 0) {
                            localStorage.removeItem(this.gridStateName);
                            this.filterModel.statusIds = [];
                            this.updateCategory(categoryArray);
                        } else {
                            this.updateCategory(this.filterModel.workOrderCategoryIds);
                        }
                        this.applyFilterEmitter.emit(false);
                    } else {
                        this.updateCategory(this.filterModel.workOrderCategoryIds);
                    }
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadCreatedByUsers(loadAllCompanyUsers: boolean, loadInitFilter: boolean = false) {
        this.blockUI.start();
        if (loadAllCompanyUsers) {
            this.accountService.getSelectItemsByCompanyIdWithWorkOrderCreators(+this.filterModel.companyId, !this.isClosedOnly)
                .subscribe((data: any) => {
                        this.blockUI.stop();
                        this.createdByUsers = data.map(({id,firstName,lastName})=>({id:id,name:firstName+' '+(lastName == null || lastName == '' ? '':lastName)}));
                        if(loadInitFilter){
                            this.selectedCreatedByUsers = this.createdByUsers.filter(x => this.filterModel.creatorIds.indexOf(x.id) != -1)
                                .map((y) => { return { id: y.id, name: y.name } as AssignedUserModel; });
                        }
                    },
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        } else {
            this.facilityService.getAssignedUsersListForMultiSelect(this.filterModel.facilityIds.map(Number), true, true)
                .subscribe(
                    (data: any) => {
                        this.blockUI.stop();
                        this.createdByUsers = data.users.map(({id,firstName,lastName})=>({id:id,name:firstName+' '+(lastName == null || lastName == ''? '':lastName)}));
                        if(loadInitFilter){
                            this.selectedCreatedByUsers = this.createdByUsers.filter(x => this.filterModel.creatorIds.indexOf(x.id) != -1)
                                .map((y) => { return { id: y.id, name: y.name } as AssignedUserModel; });
                        }
                    },
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        }
    }

    onFacitlityChanged(showLoader: boolean, isResetUnitAndAssignee: boolean = true) {
        if (showLoader || isResetUnitAndAssignee) {
            this.units = new Array<SelectListItemModel>();
            this.assignedUsers = new Array<AssigneeListModel>();
            this.createdByUsers = new Array<AssignedUserModel>();
            this.filterModel.facilityIds = [];
            this.filterModel.assigneeIds = [];
            this.filterModel.creatorIds = [];
            this.filterModel.unitIds = [];
        }
        if (isResetUnitAndAssignee) {
            this.filterModel.unitIds = [];
            this.filterModel.assigneeIds = [];
            this.filterModel.creatorIds = [];
        }

        if(!showLoader && !isResetUnitAndAssignee){
            this.selectedFacilities = this.facilities.filter(x => this.filterModel.facilityIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });
            
            this.selectedPriority = this.priority.filter(x => this.filterModel.priorityIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });

            this.selectedStatus = this.status.filter(x => this.filterModel.statusIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });

            this.selectedCategory = this.workOrderCategories.filter(x => this.filterModel.workOrderCategoryIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as CategorySelectListItemModel; });
        }

        if (this.selectedFacilities.length <= 0) {
            this.loadAssignedUsers(true, !showLoader && !isResetUnitAndAssignee);
            this.loadCreatedByUsers(true, !showLoader && !isResetUnitAndAssignee);
            return;
        }
        this.selectedFacilities.map((item)=>{
            if(this.filterModel.facilityIds.indexOf(item.id) == -1){
                this.filterModel.facilityIds.push(item.id)
            }
        });
        this.loadUnits(showLoader, !showLoader && !isResetUnitAndAssignee);
        this.loadAssignedUsers(false, !showLoader && !isResetUnitAndAssignee);
        this.loadCreatedByUsers(false, !showLoader && !isResetUnitAndAssignee);
    }

    onSelectAllForFacility(items: any) {
        this.selectedFacilities = [];
        this.selectedFacilities = items;
        this.isDropdownDisabled = true;
        this.selectedUnits = [];
        this.onFacitlityChanged(true);
    }

    onItemSelectForFacility() {
        this.isDropdownDisabled = this.selectedFacilities.length > 1 ? true:false;
        this.selectedUnits = [];
        this.onFacitlityChanged(true);
    }

    onItemDeSelectForFacility()
    {
        this.selectedUnits = [];
        this.units = [];
        this.selectedAssignedUsers = [];
        this.assignedUsers = [];
        this.selectedCreatedByUsers = [];
        this.createdByUsers = [];
        this.isDropdownDisabled = this.selectedFacilities.length > 1 ? true:false;
        this.onFacitlityChanged(true);
    }

    onDeSelectAllForFacility() {
        this.selectedFacilities = [];
        this.selectedUnits = [];
        this.units = [];
        this.selectedAssignedUsers = [];
        this.assignedUsers = [];
        this.selectedCreatedByUsers = [];
        this.createdByUsers = [];
        this.isDropdownDisabled = false;
        this.onFacitlityChanged(true);
    }

    resetFilter(isManualTrigger: boolean) {
        this.filterModel.statusIds = [];
        this.filterModel.priorityIds = [];
        this.filterModel.facilityIds = [];
        this.filterModel.unitIds = [];
        this.filterModel.assigneeIds = [];
        this.filterModel.creatorIds = [];
        this.filterModel.workOrderCategoryIds = [];
        this.filterModel.filterKey = null;
        this.selectedStatus = [];
        this.selectedPriority = [];
        this.selectedFacilities = [];
        this.selectedUnits = [];
        this.selectedAssignedUsers = [];
        this.selectedCreatedByUsers = [];
        this.selectedCategory = [];
        this.isDropdownDisabled = false;
        this.updateCategory();
        localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
        this.units = new Array<SelectListItemModel>();
        this.assignedUsers = new Array<AssigneeListModel>();
        this.createdByUsers = new Array<AssignedUserModel>();
        this.loadAssignedUsers(true);
        this.loadCreatedByUsers(true);
        this.applyFilterEmitter.next(isManualTrigger);
    }

    applyFilter(isManualTrigger: boolean) {
        if (isManualTrigger) {

            this.filterModel.statusIds = [];
            this.filterModel.priorityIds = [];
            this.filterModel.unitIds = [];
            this.filterModel.assigneeIds = [];
            this.filterModel.creatorIds = [];
            this.filterModel.workOrderCategoryIds = [];
            this.selectedAssignedUsers.map((item) => {
                this.filterModel.assigneeIds.push(item.id)
            });
            this.selectedCreatedByUsers.map((item) => {
                this.filterModel.creatorIds.push(item.id)
            });
            this.selectedUnits.map((item) => {
                this.filterModel.unitIds.push(item.id)
            });
            this.selectedPriority.map((item) => {
                this.filterModel.priorityIds.push(item.id)
            });
            this.selectedStatus.map((item) => {
                this.filterModel.statusIds.push(item.id)
            });
            this.selectedCategory.map((item) => {
                this.filterModel.workOrderCategoryIds.push(item.id)
            });
        }
        
        this.applyFilterEmitter.emit(isManualTrigger);
    }

    // setCategory(category: Array<CategorySelectListItemModel>) {
    //     this.selectedCategory = category.length > 0 ? category : Object.keys(category).map(function(it) { 
    //         return category[it]
    //      });
    //     this.filterModel.workOrderCategoryIds = category.length > 0
    //         ? category.map(x=>x.id)
    //         : [];
    // }

    
    updateCategory(categoryIds?:Array<string>) {
        if (!categoryIds || categoryIds.length <= 0 || !this.workOrderCategories) {
            this.selectedCategory = [];
            this.filterModel.workOrderCategoryIds = [];
            return;
        }

        var categories:Array<CategorySelectListItemModel> = new Array<CategorySelectListItemModel>();
        for(var categoryId of categoryIds)
        {
            categories.push(this.workOrderCategories.find(x => +x.id === +categoryId));
        }
        // const category = this.workOrderCategories.find(x => +x.id === +categoryId);
        if (categories.length <= 0) {
            this.selectedCategory = [];
            this.filterModel.workOrderCategoryIds = [];
            return;
        }

        //this.setCategory(categories);
        this.selectedCategory = categories;
        categories.map((item)=>{
            this.filterModel.workOrderCategoryIds.push(item.id)
        });
    }
}

