import { WorkOrderNoteModel } from './work.order.note.model';
import { ActivityLogModel } from './activity.log.model';
import { WorkOrderPhotoModel } from './work.order.photo.model';
import { WorkOrderRecurrenceModel } from '..';
import { WoSubTaskDto } from './sub-tasks/work.order.sub.task.model';

export class WorkOrderDetailModel {
    public id: number;
    public title: string;
    public description: string;
    public workOrderStatus: number;
    public workOrderPriority: number;
    public workOrderCategoryId: number;
    public workOrderCatgoryName: string;
    public companyName: number;
    public facilityId: number;
    public facilityName: string;
    public unitId: number;
    public unitName: string;
    public createdBy: string;
    public assignedToId: string;
    public assignedToName: string;
    public AssignedToGroupId: string;
    public assignedToGroupName: string;
    public note: WorkOrderNoteModel;
    public activityLog: ActivityLogModel;
    public notes: Array<WorkOrderNoteModel>;
    public activityLogs: Array<ActivityLogModel>;
    public photos: Array<WorkOrderPhotoModel>;
    public dueDate: string;
    public status: number;
    public watchersIds: string[];
    public createdById: string;
    public enableReadNotification: boolean;
    public isRecurring: boolean;
    public workOrderRecurrence: WorkOrderRecurrenceModel;
    public followUpAssigneeId: string;
    public followUpAssigneeName: string;
    public followUpInstruction: string;
    createdAutomatically: boolean;
    public subTasks: WoSubTaskDto[];
    public createdOn: string;
    public storedgeUnitDetailURL: string;
    public isUnitImported: boolean;
    public storedgeTenantId: string;
    public tenantAccountNumber: number;
    public storedgeTenantDetailURL: number;
    public reminderInterval: number;
    public openSubTaskCount: number;

    constructor() {
        this.note = new WorkOrderNoteModel();
        this.activityLog = new ActivityLogModel();
        this.notes = new Array<WorkOrderNoteModel>();
        this.activityLogs = new Array<ActivityLogModel>();
        this.photos = new Array<WorkOrderPhotoModel>();
        this.watchersIds = [];
        this.subTasks = [];
    }
}
