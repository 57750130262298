<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
            <div class="col-12" *ngIf='model.actionType === ActivityLogType.Insert'>
                <p class="kt-widget3__text">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    was created by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12 m-l-2" *ngIf='model.actionType === ActivityLogType.Update'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Name'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    name was updated from {{model.oldValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Address'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    address was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'ZipCode'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    zip code was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'PropertyManagerName'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    property manager was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'PropertyManagerPhone'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    property manager's contact number was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'PropertyManagerEmail'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    property manager's email was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'TypeOfConstruction'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    type of construction was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'ClimateControlled'">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    climate control was updated from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Status'  && model.newValue === 'Inactive'">
                    <a [routerLink]=" model.entityLink"> {{model.entityValue}} </a>
                    has been deactivated by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Status'  && model.newValue === 'Active'">
                    <a [routerLink]=" model.entityLink"> {{model.entityValue}} </a>
                    has been activated by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Assignee' && model.newId">
                    <a [routerLink]="[model.newValueLink]" [queryParams]="model.newValueParams">
                        {{model.newValue}} </a>
                    has been assigned to the facility
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Assignee' && model.oldId">
                    <a [routerLink]="[model.oldValueLink]" [queryParams]="model.oldValueParams">
                        {{model.oldValue}} </a>
                    has been removed from the facility
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === ActivityLogType.Delete'>
                <p class="kt-widget3__text">
                    {{model.entityValue}} has been deleted by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === ActivityLogType.AssignedUserGroups'>
                <p class="kt-widget3__text">
                    <app-list-with-link [items]="assignedUserGroups"></app-list-with-link>
                    was added to
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === ActivityLogType.RemovedUserGroups'>
                <p class="kt-widget3__text">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    user group
                    <app-list-with-link [items]="removedUserGroups"></app-list-with-link>
                    was removed by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === ActivityLogType.UpdatedUserGroups'>
                <p class="kt-widget3__text">
                    <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    user group was updated from
                    <app-list-with-link [items]="removedUserGroups"></app-list-with-link>
                    to
                    <app-list-with-link [items]="assignedUserGroups"></app-list-with-link>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
        </div>
    </div>
</div>