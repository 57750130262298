<div class="kt-portlet__head kt-portlet__head--lg">
  <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
          Work Orders
      </h3>
  </div>
</div>
<div class="kt-portlet__body">
  <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui'">
      <app-work-order-filter-box [companyId]="companyId" [isClosedOnly]="false" [allowBulkChange]="false"
          [filterModel]="filterModel" (applyFilterEmitter)="doFilter($event)" #filterBox>
      </app-work-order-filter-box>
      <div class="row">
          <div class="col-12">
              <table datatable [dtOptions]="dtOptions" class="table table-hover break-all" id="tblWorkOrderBulkChanges">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" class="check-all-work-orders" [(ngModel)]="isCheckAllCheckboxChecked"
                          (change)="checkUncheckAllWorkOrders($event)" />
                      </th>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Assigned To</th>
                      <th>Creation Date</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Prority</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="workOrders?.length > 0">
                    <tr *ngFor="let workOrder of workOrders">
                      <td>
                        <input type='checkbox' class='wo-checkbox' [checked]="workOrder.selected" (change)="onWorkOrderChecked(workOrder, $event)" />
                      </td>
                      <td>{{ workOrder.id }}</td>
                      <td>
                        <a [routerLink]="['/work-order/detail', 1, companyId, workOrder.id]" 
                          class='link' action-type='view-detail'>
                          {{workOrder.title}}
                        </a>
                      </td>
                      <td>{{ workOrder.assignedToName }}</td>
                      <td>{{ appUtils.getFormattedDate(workOrder.createdOn) }}</td>
                      <td class="text-center">
                        <span *ngIf="workOrder.workOrderStatus === 1" class="kt-badge kt-badge--danger kt-badge--inline">Not Started</span>
                        <span *ngIf="workOrder.workOrderStatus === 2" class="kt-badge kt-badge--primary kt-badge--inline">In Progress</span>
                        <span *ngIf="workOrder.workOrderStatus === 3" class="kt-badge kt-badge--warning kt-badge--inline">On Hold</span>
                        <span *ngIf="workOrder.workOrderStatus === 4" class="kt-badge kt-badge--success kt-badge--inline">Closed</span>
                      </td>
                      <td class="text-center">
                        <span *ngIf="workOrder.workOrderPriority === 1" class="kt-badge kt-badge--success kt-badge--inline">Low</span>
                        <span *ngIf="workOrder.workOrderPriority === 2" class="kt-badge kt-badge--warning kt-badge--inline">Medium</span>
                        <span *ngIf="workOrder.workOrderPriority === 3" class="kt-badge kt-badge--danger kt-badge--inline">High</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="workOrders?.length == 0">
                    <tr class="text-center">
                      <td colspan="7" class="no-data-available">No matching records found</td>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
<div class="kt-portlet__foot">
  <div class="kt-form__actions">
    <div class="d-flex justify-content-between">
      <div class="form-group">
        <label class="kt-badge kt-badge--primary kt-badge--inline">
          {{selectedWorkOrderIds.size}} selected of {{totalFilteredWorkOrdersCount}}
        </label>
      </div>
      <div>
        <a [routerLink]="['/work-order/manage', companyId]" class="btn btn-default btn-elevate btn-icon-sm" *ngIf="viewType === '1'">
          Cancel
        </a>
        <a [routerLink]="['/work-order/grid-view', companyId]" class="btn btn-default btn-elevate btn-icon-sm" *ngIf="viewType === '2'">
          Cancel
        </a>
        <button type="button" class="btn btn-brand btn-elevate btn-icon-sm m-l-5"
          [disabled]="selectedWorkOrderIds.size === 0" (click)="performBulkOperation()">
          Next <em class="la la-arrow-right"></em>
        </button>
      </div>
    </div>
  </div>
</div>
