import { Component, HostBinding, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel } from '../../../../models';
import { AppUtils, AppSettings, RoleMatrix } from '../../../../helpers';
import { WorkOrderService } from 'src/services';

@Component({
    selector: 'app-work-order-category-manage',
    templateUrl: './work.order.category.manage.component.html'
})

export class WorkOrderCategoryManageComponent implements OnInit, AfterViewInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective, { static: true })
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    rowIndex = 0;
    pageLength = 10;
    search: any = null;

    constructor(private http: HttpClient,
        private router: Router,
        private tostr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private appSettings: AppSettings) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewWoCategory) {
            this.router.navigate(['/error/403']);
        }
    }

    ngOnInit(): void {

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter category...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[1, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {
                self.http
                    .post<DataTableResponseModel>(environment.apiBaseUrl + 'workOrder/get-categories', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Category Name',
                    width: '20%'
                },
                {
                    data: 'createdOn',
                    title: 'Added On',
                    width: '15%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.roleMatrix.canChangeStatusOfWoCategory,
                    render: function (data, type, row) {
                        const statusHtml =
                            row.status === 2
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === 3
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '';

                        return row.isDefault ? null : statusHtml;
                    }
                }
            ],
            rowCallback: function (row, data: any) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/work-order/edit-category', data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.workOrderService.toggleCategoryStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.tostr.success(`Category has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    refreshTableData() {
        this.dtInstance.ajax.reload(null, false);
    }
}
