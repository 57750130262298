<form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit(f)" #f="ngForm" novalidate>
    <div class="kt-portlet__body">
        <div class="form-group row justify-content-md-center  mt-5 mb-5"
            [ngClass]="{ 'validation-error' : f.submitted && !name.valid}">
            <label class="col-md-2 mt-4 pt-3">Add Category:*</label>
            <div class="col-md-4">
                <label class="text-muted">Type the category you want to create</label>
                <input type="text" name="name" class="form-control" minlength="2" maxlength="30" autocomplete="off"
                    [(ngModel)]="model.name" #name="ngModel" required />
                <div class="validation-error-message" *ngIf="f.submitted && name.invalid">
                    <span *ngIf="name.errors.required">Category name is required</span>
                    <span *ngIf="name.errors.minlength">Minimum 2 characters are required</span>
                    <span *ngIf="name.errors.maxlength">Maximum 30 characters are allowed</span>
                </div>
            </div>
            <div class="col-md-2 mt-4 pt-2">
                <button type="submit" class="btn btn-primary m-t-5">Add</button>
            </div>
        </div>
    </div>
</form>