import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginModel, LeftBannerModel } from '../../../models';
import { ListenerService, AccountService, MasterService } from '../../../services';
import { AppUtils } from '../../../helpers';
import { UserInfoSynchronizer } from 'src/synchronizers/user-info.synchronizer';
import { ResetPasswordModel } from 'src/models/account/reset.password.model';

@Component({
    selector: 'app-login',
    templateUrl: './account.login.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})
export class LoginComponent {
    @BlockUI('frm-login')
    blockUI: NgBlockUI;

    returnUrl: string;
    token: string;
    showResetPasswordForm: boolean = false;
    model: LoginModel = new LoginModel();
    resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
    leftBannerModel: LeftBannerModel = new LeftBannerModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private listenerService: ListenerService,
        private accountService: AccountService,
        private masterService: MasterService,
        private userInfoSynchronizer: UserInfoSynchronizer) {
        if (this.appUtils.isUserAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
    }

    login() {
        this.blockUI.start();
        this.accountService.login(this.model).subscribe(
            (data: any) => {
                this.token = data.toString();
                this.accountService.checkIsUserNeedPasswordReset(data.toString()).subscribe(
                    (isUserNeedPasswordReset: boolean) => {
                        if (!isUserNeedPasswordReset) {
                            this.setToken();
                        }
                        else {
                            this.showResetPasswordForm = true;
                        }
                    },
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    setToken() {
        this.masterService.setDashboardShowPersonnalData(false);
        this.masterService.setFacilityShowPersonnalData(false);
        this.masterService.setWorkOrderShowPersonnalData(false);
        this.masterService.setVendorShowPersonnalData(false);
        this.masterService.setKioskShowPersonnalData(false);

        localStorage.setItem('AuthToken', this.token);

        this.userInfoSynchronizer.loadUserInfo$().subscribe(
            data => {
                this.blockUI.stop();

                setTimeout(() => {
                    this.listenerService.topNavlistner.next();
                    this.listenerService.layoutlistner.next();

                    if (this.returnUrl) {
                        this.router.navigate([this.returnUrl]);
                    } else {
                        this.router.navigate(['/']);
                    }
                }, 100);
            },
            error => {
                this.blockUI.stop();
            }
        );  
    }


    submitResetPasswordForm() {
        if (this.resetPasswordModel.newPassword !== this.resetPasswordModel.confirmPassword) {
            return;
        }
        this.blockUI.start();
        this.accountService.resetUserPasswordOnLogin(this.resetPasswordModel, this.token)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.setToken()
                    setTimeout(() => {
                        this.toastr.success('Your password has been changed successfully.');
                    }, 500);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
