export class WorkOrderFilterModel {
    public companyId: number;
    public statusIds: Array<string>;
    public facilityIds: Array<string>;
    public unitIds: Array<string>;
    public assigneeIds: Array<string>;
    public workOrderCategoryIds: Array<string>;
    public creatorIds: Array<string>;
    public priorityIds: Array<string>;
    public filterKey: string;
    public start: number;
    public limit: number;
    public sortBy: string;
    public sortDir: string;
    public considerOnlyPersonalData: boolean;

    constructor() {
        this.statusIds = new Array<string>();
        this.facilityIds = new Array<string>();
        this.unitIds = new Array<string>();
        this.assigneeIds = new Array<string>();
        this.workOrderCategoryIds = new Array<string>();
        this.creatorIds = new Array<string>();
        this.priorityIds = new Array<string>();
        this.limit = 1000;
    }
}
