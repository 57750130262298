export class CompanyEditModel {
    public id: number;
    public name: string;
    public address: string;
    public zipCode: string;
    public website: string;
    public contactNumber: string;
    public email: string;
    public status: number;
}
