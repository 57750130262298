import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService, AccountService, ListenerService } from '../../../services';
import { CompanyUserEditModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { TimeZone } from 'src/models/timezone.model';
import { UserInfoSynchronizer } from 'src/synchronizers/user-info.synchronizer';

@Component({
    selector: 'app-company-user-profile-edit',
    templateUrl: './company.user.profile.edit.component.html'
})
export class CompanyUserProfileEditComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model = new CompanyUserEditModel();

    timeZones: TimeZone[];

    isModelLoaded = false;

    constructor(private router: Router,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private accountService: AccountService,
        private listenerService: ListenerService,
        private timeZoneService: TimeZoneService,
        private userInfoSynchronizer: UserInfoSynchronizer) {
        this.model.id = this.appUtils.getUserId();
        this.roleMatrix = new RoleMatrix();
        if (this.appUtils.is10FedUser(this.roleMatrix.role)) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.loadTimeZones();
        this.loadUserDetails();
    }

    submit() {
        if(!this.model.enableEmailNotification && !this.model.enablePushNotification) {
            this.toastr.error("Atleast one medium to receive notification should be selected.");
            return;
        }

        this.blockUI.start();
        this.userService.editCompanyUser(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.updateCurrentUserData();
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateCurrentUserData() {
        this.blockUI.start();
        this.userInfoSynchronizer.loadUserInfo$().subscribe(
            data => {
                this.accountService.getUpdatedToken().subscribe(
                    (data) => {
                        this.blockUI.stop();

                        localStorage.setItem('AuthToken', data.toString());
                        this.listenerService.topNavlistner.next();
                        setTimeout(() => {
                            this.router.navigate(['/company-user/profile-detail']);
                        }, 10);
                        setTimeout(() => {
                            this.toastr.success('Your profile has been updated successfully.');
                        }, 300);
                    },
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
            },
            error => {
                this.blockUI.stop();
            }
        );
    }

    private loadUserDetails() {
        this.blockUI.start();
        this.userService.getCompanyUserForEdit(this.model.id).subscribe(
            data => {
                this.blockUI.stop();
                Object.assign(this.model, data);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    onEmailNotificationDisabled() {
        if(!this.model.enableEmailNotification) {
            this.model.sendEmailNotification = false;
        }
    }

    onWatchersEmailNotificationEnabled() {
        if(this.model.sendEmailNotification){
            this.model.enableEmailNotification = true;
        }
    }

    onPushNotificationDisabled() {
        if(!this.model.enablePushNotification) {
            this.model.sendPushNotification = false;
        }
    }

    onWatchersPushNotificationEnabled() {
        if(this.model.sendPushNotification){
            this.model.enablePushNotification = true;
        }
    }
}
