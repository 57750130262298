<div class="kt-subheader__breadcrumbs"
  *ngIf="roleMatrix.role === 'SUPERADMIN' || roleMatrix.role === 'ADMIN' || roleMatrix.role === 'USER'">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
      <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/manage']" class="kt-subheader__breadcrumbs-link">
      Companies </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/detail',companyId]" class="kt-subheader__breadcrumbs-link">
      Company Details </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a class="kt-subheader__breadcrumbs-link">
      Work Order Configurations
    </a>
</div>

<div class="kt-subheader__breadcrumbs"
  *ngIf="roleMatrix.role === 'ORGSUPERADMIN' || roleMatrix.role === 'ORGADMIN' || roleMatrix.role === 'ORGUSER'">
  <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
    <em class="flaticon2-shelter"></em>
  </a>
  <span class="kt-subheader__breadcrumbs-separator"></span>
  <a class="kt-subheader__breadcrumbs-link">
    Work Order Configurations
  </a>
</div>