import { StoredgeFacilitySyncModel } from './storedge.facility.sync.model';

export class SyncSingleModel {
    public internalCompanyId: number;
    public storedgeCompanyId: string;

    public facility: StoredgeFacilitySyncModel;

    constructor() {
        this.facility = new StoredgeFacilitySyncModel();
    }
}
