import { Component, Input } from '@angular/core';
import { RoleMatrix } from 'src/helpers';

@Component({
    selector: 'app-breadcrumb-comp-work-order-categories-manage',
    templateUrl: './breadcrumb.comp.work.order.category.component.html'
})

export class BreadcrumbCompanyWorkOrderCategoriesManageComponent {
    @Input() companyId: number;
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
