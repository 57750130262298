<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-signage-edit [companyId]="companyId" [facilityId]="facilityId" [kioskId]="kioskId">
            </app-breadcrumb-signage-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Content
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row">
                                <label class="col-md-3">Upload Content:*</label>
                                <div class="col-md-9" *ngIf="!changingCotentFile">
                                    <a [attr.href]="model.contentFileUrl" target="_blank" class="fs-15">
                                        <em class="kt-menu__link-icon flaticon-attachment"></em>
                                        {{currentContentOriginalFileName}}
                                    </a>
                                    <button type="button" class="btn btn-outline-dark btn-xs m-l-15"
                                        (click)="changeContentFile()">Change</button>
                                </div>
                                <div class="col-md-9" *ngIf="changingCotentFile">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-primary m-b-5"
                                                (click)="signageContentElem.click()">
                                                Choose file
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-dark btn-xs m-t-5"
                                                (click)="keepExistingContentFile()">Keep Existing Content</button>
                                        </div>
                                    </div>
                                    <ngb-progressbar class="m-t-5" type="info" [value]="contentUploadPercent"
                                        height="5px" *ngIf="contentUploadPercent > 0 && contentUploadPercent < 100">
                                    </ngb-progressbar>
                                    <input type="file" name="signageContent" #signageContent="ngModel" class="d-none"
                                        [(ngModel)]="model.originalContentFileName"
                                        accept="application/pdf,video/mp4,video/x-m4v,image/png,image/jpg,image/jpeg"
                                        #signageContentElem (change)="onContentSelected($event)" required />
                                    <div class="validation-error-message" *ngIf="f.submitted && signageContent.invalid">
                                        <span *ngIf="signageContent.errors.required"> Content is required</span>
                                    </div>
                                    <div *ngIf="model.originalContentFileName">
                                        <span class="fs-15 text-overflow">
                                            <em class="kt-menu__link-icon flaticon-attachment"></em>
                                            <span class="m-l-5 text-overflow">{{model.originalContentFileName}}</span>
                                            <span class="btn btn-xs cursor-pointer m-l-5" (click)="deleteContent()">
                                                <i class="la la-trash"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row" *ngIf="contentType === 'video/mp4'">
                                <label class="col-md-3">Upload Thumbnail:*</label>
                                <div class="col-md-9" *ngIf="!changingThumbnailFile && !isThumbnailExistsForChange">
                                    <a [attr.href]="model.thumbnailFileUrl" target="_blank" class="fs-15">
                                        <em class="kt-menu__link-icon flaticon-attachment"></em>
                                        {{currentThumbnailOriginalFileName}}
                                    </a>
                                    <button type="button" class="btn btn-outline-dark btn-xs m-l-15"
                                        (click)="changeThumbnailFile()">Change</button>
                                </div>
                                <div class="col-md-9" *ngIf="changingThumbnailFile || isThumbnailExistsForChange">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-primary m-b-5"
                                                (click)="signageThumbnailElem.click()">
                                                Choose file
                                            </button>
                                        </div>
                                        <div class="col-md-6" *ngIf="isThumbnailElemExist">
                                            <button type="button" class="btn btn-outline-dark btn-xs m-t-5"
                                                (click)="keepExistingThumbnailFile()">Keep Existing Thumbnail</button>
                                        </div>
                                    </div>
                                    <ngb-progressbar class="m-t-5" type="info" [value]="thumbnailUploadPercent"
                                        height="5px" *ngIf="thumbnailUploadPercent > 0 && thumbnailUploadPercent < 100">
                                    </ngb-progressbar>
                                    <input type="file" name="signageThumbnail" #signageThumbnail="ngModel"
                                        class="d-none" [(ngModel)]="model.originalThumbnailFileName"
                                        accept="image/png,image/jpg,image/jpeg" #signageThumbnailElem
                                        (change)="onThumbnailSelected($event)" required />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && signageThumbnail.invalid">
                                        <span *ngIf="signageThumbnail.errors.required"> Thumbnail is required</span>
                                    </div>
                                    <div *ngIf="model.originalThumbnailFileName">
                                        <span class="fs-15 text-overflow">
                                            <em class="kt-menu__link-icon flaticon-attachment"></em>
                                            <span class="m-l-5 text-overflow">{{model.originalThumbnailFileName}}</span>
                                            <span class="btn btn-xs cursor-pointer m-l-5" (click)="deleteThumbnail()">
                                                <i class="la la-trash"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && title.invalid}">
                                <label class="col-md-3 p-3">Title:*</label>
                                <div class="col-md-9">
                                    <span>Edit the title to be displayed for the context to the customers</span>
                                    <input type="text" name="title" class="form-control" maxlength="60"
                                        autocomplete="off" [(ngModel)]="model.title" #title="ngModel" required />
                                    <div class="validation-error-message" *ngIf="f.submitted && title.invalid">
                                        <span *ngIf="title.errors.required"> Title is required</span>
                                        <span *ngIf="title.errors.maxlength">Maximum 60 characters are
                                            allowed</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && description.invalid}">
                                <label class="col-md-3 p-3">Description:*</label>
                                <div class="col-md-9">
                                    <span>Edit the description to be displayed for the content to the
                                        customers</span>
                                    <textarea type="text" name="description" rows="3" class="form-control"
                                        maxlength="250" autocomplete="off" [(ngModel)]="model.description"
                                        #description="ngModel" required></textarea>
                                    <div class="validation-error-message" *ngIf="f.submitted && description.invalid">
                                        <span *ngIf="description.errors.required"> Description is required</span>
                                        <span *ngIf="description.errors.maxlength">Maximum 60 characters are
                                            allowed</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 p-3">Description:</label>
                                <div class="col-md-9">
                                    <span>Edit the description to be displayed for the context to the internal
                                        users</span>
                                    <textarea type="text" name="internalDescription" rows="3" class="form-control"
                                        maxlength="250" autocomplete="off" [(ngModel)]="model.internalDescription"
                                        #internalDescription="ngModel"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/signage/manage', companyId, facilityId, kioskId]"
                                    class="btn btn-secondary">Cancel</a>
                                <button type="submit" class="btn btn-primary m-l-5">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-signage-image-cropper #signageContentImageCropper [processingDimension]="processingDimension">
</app-signage-image-cropper>
<app-signage-image-cropper #signageImageCropper [processingDimension]="processingDimension">
</app-signage-image-cropper>