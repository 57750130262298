<div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui-unit'">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3">
                    <select class="form-control" name="status" [(ngModel)]="filterModel.status">
                        <option value="">[Status]</option>
                        <option value="2">Active</option>
                        <option value="3">Inactive</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" placeholder="Filter units"
                        [(ngModel)]="filterModel.filterKey" autocomplete="Off" maxlength="50"
                        (keydown.enter)="doFilter()" />
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" (click)="doFilter()">
                        <em class="la la-search"></em> Filter
                    </button>
                    <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="resetFilter()">
                        <em class="la la-close"></em> Reset
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" data-page="2" class="table table-hover break-all">
            </table>
        </div>
    </div>
</div>
<app-unit-challenge-delete #unitChallengeDeleteModal (deleteUnit)="delete()"></app-unit-challenge-delete>