<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-comp-work-order-categories-add-edit [companyId]="companyId" [label]="'Edit'">
            </app-breadcrumb-comp-work-order-categories-add-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head kt-portlet__head--lg">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Category
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="submit(f)" #f="ngForm" novalidate>
                        <div class="kt-portlet__body" *blockUI="'container-blockui-add-category'">

                            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && name.invalid}">
                                <label class="col-md-3">Category Name:*</label>
                                <div class="col-md-6">
                                    <input type="text" name="name" class="form-control" minlength="2" maxlength="30"
                                        autocomplete="off" [(ngModel)]="model.name" #name="ngModel" required
                                        [disabled]="model.isDefault" [readonly]="model.isDefault"
                                        [ngClass]="{ 'disabled-text-input' : model.isDefault }" />
                                    <div class="validation-error-message" *ngIf="f.submitted && name.invalid">
                                        <span *ngIf="name.errors.required">Category name is required</span>
                                        <span *ngIf="name.errors.minlength">Minimum 2 characters are required</span>
                                        <span *ngIf="name.errors.maxlength">Maximum 30 characters are allowed</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && dueDateDuration.invalid === 0}">
                                <label class="col-md-3">Default Due Date Duration:</label>
                                <div class="col-md-3">
                                    <input type="text" name="dueDateDuration" class="form-control" maxlength="3"
                                        autocomplete="off" [(ngModel)]="model.dueDateDuration"
                                        #dueDateDuration="ngModel" appNumbersOnly />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && dueDateDuration.invalid">
                                        <span>Default due date duration should be between 1 and 365</span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <label>Days</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3">Unit Rentability:</label>
                                <div class="col-md-3">
                                    <div class="float-left">
                                        <label class="kt-checkbox kt-checkbox--brand">
                                            <input type="checkbox" id="unitRentability" name="unitRentability"
                                                [(ngModel)]="model.isUnrentable" />
                                            <span></span>
                                        </label>
                                    </div>
                                    <label class="float-left m-t-4" for="unitRentability">
                                        Make Unit Unrentable
                                    </label>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && workOrderPriority.invalid }">
                                <label class="col-md-3">Priority:</label>
                                <div class="col-md-3">
                                    <select class="form-control" name="workOrderPriority"
                                        [(ngModel)]="model.workOrderPriority" #workOrderPriority="ngModel">
                                        <option value="">[Select]</option>
                                        <option [value]="item.id" *ngFor="let item of priorityList">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/work-order-comp-categories', companyId]"
                                    [queryParams]="{ activeTab: 2 }" class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>