<ng-template #facilityImportConfirmationModal let-modal>
    <div *blockUI="'container-blockui-delete-popup'">
        <div class="modal-header">
            <h5 class="modal-title">Confirm Import</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeImportConfirmationModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="max-height: 400px;overflow-y: scroll;">
            <div class="col-12">
                <p>
                    Below are some facilities with incomplete data. Do you still want to import?
                </p>
            </div>
            <div class="col-12">
                <p class="kt-font-bold" *ngFor="let facility of facilities">
                    <em _ngcontent-c1="" class="la la-exclamation-triangle text-danger fs-18"></em> {{facility}}
                </p>
            </div>
        </div>
        <div class="modal-footer bg-white">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="closeImportConfirmationModal()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="import()">OK</button>
            </div>
        </div>
    </div>
</ng-template>