export class CompanyFilterModel {
    public plan: string;
    public status: string;
    public filterKey: string;

    constructor() {
        this.plan = 'Basic';
        this.status = '';
    }
}
