<div class="kt-portlet__body">
    <div class="row">
        <div class="col-xl-9 col-md-8">
            <div class="m-t-25" *ngIf="isSynching || isImporting">
                <ngb-progressbar type="success" [max]="pbMaxVal" [value]="pbCurrentVal" [striped]="true"
                    [animated]="true">
                </ngb-progressbar>
            </div>
            <p class="m-t-5 m-b-0" *ngIf="isSynching">
                <span class="kt-font-bolder">{{pbCurrentVal}}</span>
                out of
                <span class="kt-font-bolder">{{pbMaxVal}}</span>
                facilities synced
            </p>
            <p class="m-t-5 m-b-0" *ngIf="isImporting">
                <span class="kt-font-bolder">{{pbCurrentVal}}</span>
                out of
                <span class="kt-font-bolder">{{pbMaxVal}}</span>
                facilities imported
            </p>
        </div>
        <div class="col-xl-3 col-md-4 text-right facility-import-action-buttons">
            <button type="button" class="btn btn-warning" *ngIf="!readyToImport" (click)="synchronize()"
                [disabled]="isSynching || isImporting">
                <i class="la la-refresh"></i>
                Sync
            </button>
            <button type="button" class="btn btn-primary m-l-10" *ngIf="!readyToImport" (click)="verifyDetail()"
                [disabled]="isSynching || isImporting">
                <i class="la la-cloud-download"></i> Proceed to Import
            </button>
            <button type="button" class="btn btn-warning" *ngIf="readyToImport" (click)="goBackToPreview()"
                [disabled]="isSynching || isImporting">
                <i class="la la-angle-left"></i>
                Back to Sync List
            </button>
            <button type="button" class="btn btn-primary m-l-10" *ngIf="readyToImport" (click)="initImport()"
                [disabled]="isSynching || isImporting">
                <i class="la la-cloud-download"></i> Import
            </button>
        </div>
    </div>
    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui-preview'">
        <div class="row" *ngIf="!readyToImport">
            <div class="col-md-12" *ngIf="isModelLoaded && facilities.length > 0">
                <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                    <thead>
                        <tr>
                            <th style="width: 10%;">
                                &nbsp;
                            </th>
                            <th style="width: 55%;">
                                <div class="float-left">
                                    <label class="kt-checkbox kt-checkbox--brand">
                                        <input type="checkbox" [(ngModel)]="selectAllFacilities"
                                            (change)="onSelectAllFacilitiesChanged()" />
                                        <span class="m-t--10"></span>
                                    </label>
                                </div>
                                <div class="float-left m-t-4">
                                    Facility
                                </div>
                            </th>
                            <th style="width: 15%;">
                                Status
                            </th>
                            <th style="width: 20%;">
                                Last Updated
                            </th>
                        </tr>
                    </thead>
                </table>
                <ngb-accordion #a="ngbAccordion">
                    <ngb-panel id="custom-panel-{{f.id}}" *ngFor="let f of facilities">
                        <ng-template ngbPanelHeader>
                            <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                                <tbody>
                                    <tr>
                                        <td style="width: 10%;">
                                            <button ngbPanelToggle class="btn p-0" (click)="f.isOpen =! f.isOpen">
                                                <i *ngIf="!f.isOpen" class="fa fa-caret-right  color-primary fs-30"></i>
                                                <i *ngIf="f.isOpen" class="fa fa-caret-down  color-primary fs-30"></i>
                                            </button>
                                        </td>
                                        <td style="width: 55%;">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" [(ngModel)]="f.isSelected"
                                                        (change)="onFacilityChanged(f)" [disabled]="f.status === 3" />
                                                    <span class=" m-t--10"></span>
                                                </label>
                                            </div>
                                            <div class="float-left m-t-4">
                                                {{f.name}}
                                            </div>
                                        </td>
                                        <td style="width: 15%;">
                                            <app-storedge-record-status [status]="f.status">
                                            </app-storedge-record-status>
                                        </td>
                                        <td style="width: 20%;">
                                            {{f.lastUpdated}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-storedge-preview-unit [facility]="f"></app-storedge-preview-unit>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
            <div class="col-12" *ngIf="isModelLoaded && facilities.length === 0">
                <p>No record found</p>
            </div>
        </div>
        <app-storedge-verify-facility #storedgeVerifyFacility [facilities]="seletedFacilities" [configurationModel]="model"
            [totalFacilities]="totalFacilities" (goBackToPreview)="goBackToPreview()" *ngIf="readyToImport">
        </app-storedge-verify-facility>
    </div>
</div>

<app-storedge-confirm-import #storedgeConfirmImport (importFacilities)="import()">
</app-storedge-confirm-import>