<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-configuration [companyId]="companyId" [facilityId]="facilityId" [kioskId]="kioskId">
            </app-breadcrumb-configuration>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <ngb-tabset [activeId]="activeTab" type="string" class="custom-tab-set">
                        <ngb-tab id="1" title="Amenities & Icons">
                            <ng-template ngbTabContent>
                                <app-movein-amenity-manage></app-movein-amenity-manage>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="2" title="Fields & Criteria">
                            <ng-template ngbTabContent>
                                <app-movein-addon></app-movein-addon>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="3" title="Skinning">
                            <ng-template ngbTabContent>
                                <app-movein-skinning></app-movein-skinning>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>