import { Component, OnInit, Input } from '@angular/core';
import { UserGroupService } from 'src/services';
import { UserGroupMetricsModel } from 'src/models';

@Component({
    selector: 'app-company-user-group-tile',
    templateUrl: './company.user.group.tile.component.html'
})

export class CompanyUserGroupTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;
    totalUserGroups = 0;
    totalActiveUserGroups = 0;

    constructor(private userGroupService: UserGroupService) {
    }

    ngOnInit(): void {
        this.userGroupService.getUserGroupMetrics(this.companyId)
            .subscribe(
                (data: UserGroupMetricsModel) => {
                    this.isModelLoaded = true;
                    this.totalActiveUserGroups = data.activeUserGroups;
                    this.totalUserGroups = data.totalUserGroups;
                },
                error => {
                    console.log('User group count failed');
                    console.log(error);
                });
    }
}
