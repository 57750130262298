export class UserFilterModel {
    public role: string;
    public status: string;
    public filterKey: string;

    constructor() {
        this.role = '';
        this.status = '';
    }
}
