import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { FacilityService, WorkOrderService } from '../../../services';
import { WorkOrderSummaryModel, PropertyManagerDetailModel, WorkOrderReportIssueModel, AssignedUserModel, SelectListItemModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
    selector: 'app-work-order-report-issue',
    templateUrl: './work.order.report.issue.component.html'
})
export class WorkOrderReportIssueComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    companyId = -1;
    workOrderId = -1;
    isModelLoaded = true;

    workOrderDetail: WorkOrderSummaryModel = new WorkOrderSummaryModel();   
    propertyManagerDetail: PropertyManagerDetailModel = new PropertyManagerDetailModel();
    model: WorkOrderReportIssueModel = new WorkOrderReportIssueModel();

    facilityUsers: AssignedUserModel[] = [];
    multiSelectDropdownConfigs: IDropdownSettings;
    selectedReceivers: { id: string, fullName: string }[] = [];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private facilityService: FacilityService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewWorkOrder) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.workOrderId = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'fullName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            noDataAvailablePlaceholderText: '[Select]'
        };
    }

    ngOnInit() {
        this.loadWorkOrder();
    }

    submit() {
        this.blockUI.start();

        this.model.receivers = this.selectedReceivers.map(x => {
            return {
                id: x.id,
                email: this.facilityUsers.find(u => u.id === x.id).email
            };
        });

        this.model.workOrderId = this.workOrderId;
        this.workOrderService.reportIssue(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/work-order/detail', 1, this.companyId, this.workOrderId]);
                    }, 100);
                    setTimeout(() => {
                        this.toastr.success('Issue has been reported successfully');
                    }, 200);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadWorkOrder() {
        this.blockUI.start();
        this.workOrderService.getSummary(this.workOrderId).subscribe(
            (data: any) => {
                Object.assign(this.workOrderDetail, data);

                if (this.workOrderDetail.workOrderStatus === 1 || this.workOrderDetail.workOrderStatus === 4) {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/error/404']);
                    }, 100);
                    return;
                }

                this.model.subject = `Work Order: ${this.workOrderDetail.facilityName} - ${this.workOrderDetail.title}`;

                this.loadAndPreSelectPropertyManager();
                this.loadFacilityUsers(this.workOrderDetail.facilityId);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private loadAndPreSelectPropertyManager() {
        this.facilityService.getPropertyManagerDetail(this.workOrderDetail.facilityId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.propertyManagerDetail, data);
                    
                    this.selectedReceivers = [
                        {
                            id: this.propertyManagerDetail.id,
                            fullName: this.propertyManagerDetail.name
                        }
                    ];
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadFacilityUsers(facilityId: number) {
        this.blockUI.start();
        this.facilityService.getAssignedUsersList(facilityId, true, true)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.facilityUsers = data;
                    this.facilityUsers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                },
                error => {
                    this.blockUI.stop();
                    this.facilityUsers = [];
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
