export class CompanyUserEditModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public role: string;
    public facility: string;
    public companyId: number;
    public status: number;
    public timeZone: string;
    public sendInAppNotification: boolean;
    public sendPushNotification: boolean;
    public sendEmailNotification: boolean;
    public enableEmailNotification: boolean;
    public enableInAppNotification: boolean;
    public enablePushNotification: boolean;

    constructor() {
        this.role = '';
        this.facility = '';
    }
}
