export class CompanyDetailModel {
    public id: number;
    public name: string;
    public address: string;
    public zipCode: string;
    public website: string;
    public contactNumber: string;
    public email: string;
    public accountOwnerName: string;
    public accountOwnerEmail: string;
    public accountOwnerContactNumber: string;
    public createdOn: string;
    public createdBy: string;
    public updatedOn: string;
    public updatedBy: string;
    public status: number;
}
