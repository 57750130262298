import { WorkOrderNoteModel } from './work.order.note.model';
import { WorkOrderPhotoModel } from './work.order.photo.model';
import { WorkOrderRecurrenceModel, WorkOrderWatcherModel } from '..';

export class WorkOrderAddModel {
    public title: string;
    public description: string;
    public workOrderStatus: string;
    public workOrderPriority: string;
    public workOrderCategoryId: string;
    public companyId: number;
    public facilityId: string;
    public unitId: string;
    public assignedToId: string;
    public assignedToGroupId: string;
    public photos: Array<WorkOrderPhotoModel>;
    public dueDate: string;
    public watchersIds: string[];
    public isRecurring: boolean;
    public workOrderRecurrence: WorkOrderRecurrenceModel;
    public followUpAssigneeId: string;
    public followUpInstruction: string;
    public reminderInterval: number;
    public enableReadNotification:boolean;

    constructor() {
        this.workOrderStatus = '1';
        this.workOrderPriority = '';
        this.workOrderCategoryId = '';
        this.facilityId = '';
        this.unitId = '';
        this.assignedToId = '';
        this.followUpAssigneeId = '';
        this.photos = new Array<WorkOrderPhotoModel>();
        this.watchersIds = [];
        this.enableReadNotification = true;
        this.isRecurring = false;
        this.reminderInterval = 1;
    }
}
