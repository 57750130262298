import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../helpers';

@Injectable()
export class CloudStorageService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    getSasToken() {
        return this.http.get(environment.apiBaseUrl + 'cloudstorage/get-sas-token');
    }

    getPublicUrl(containerName: string, fileKey: string) {
        return this.http.get(environment.apiBaseUrl + `cloudstorage/get-public-url/${containerName}/${fileKey}`);
    }
}
