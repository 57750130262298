<div class="kt-widget24 widget-tile m-b-15">
    <div class="kt-widget24__details">
        <div class="kt-widget24__info">
            <h4 class="kt-widget24__title text-center fs-20">
                <a [routerLink]="['/storedge/intergration', companyId]">Configurations</a>
            </h4>
        </div>
    </div>
    <div class="m-t-10 m-b-10">&nbsp;
    </div>
    <div class="kt-widget24__action">
        <span class="kt-widget24__change">
            <span>&nbsp;</span>
        </span>
        <span class="kt-widget24__number">
            <span>&nbsp;</span>
        </span>
    </div>
</div>