import { EntityTypesEnum, ActivityLogIdentifiersEnum } from 'src/helpers';

export class ActivityLogDetailModel {
    public id: string;
    public activityLogId: string;
    public entityType: number;
    public companyId: number;
    public entityTypeName: string;
    public entityId: string;
    public entityValue: string;
    public parentEntityId: string;
    public createdById: string;
    public createdByName: string;
    public createdByRole: string;
    public createdOn: string;
    public totalAssignees: number;
    public fieldEntityType: number;
    public fieldEntityTypeName: string;
    public fieldName: string;
    public oldId: string;
    public oldParentId: string;
    public oldValue: string;
    public newId: string;
    public newParentId: string;
    public newValue: string;
    public actionType: number;
    public actionTypeName: string;
    public currentDateTime: string;

    public is10FedUser: boolean;
    public createdByLink: string;
    public createdByParams: any;

    public entityLink: string;
    public entityParams: string;
    public parentEntityLink: string;
    public parentEntityParams: string;
    public oldValueLink: string;
    public oldValueParams: string;
    public newValueLink: string;
    public newValueParams: string;
    public customData: ActivityLogCustomData[];
}

export class ActivityLogCustomData {
    entityType: EntityTypesEnum;
    entityId: string;
    entityValue: string;
    entityLink: string;
    identifier: ActivityLogIdentifiersEnum;
}
