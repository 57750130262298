import { Component, ViewChild } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image.viewer.modal.component.html'
})

export class ImageViewerModalComponent {
    @ViewChild('imageViewerModal', { static: true }) imageViewerModal: any;

    modalReferece: NgbModalRef;
    title: string;
    imageUrl: string;

    constructor(private modalService: NgbModal) { }

    openImageViewerModal(title: string, imageUrl: string) {
        this.title = title;
        this.imageUrl = imageUrl;
        this.modalReferece = this.modalService
            .open(this.imageViewerModal,
                {
                    size: 'lg'
                });
    }

    closeImageViewerModal() {
        this.modalReferece.close();
    }
}

