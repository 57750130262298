import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import {AppUtils} from '../../../helpers';

@Component({
  selector: 'app-kpi-report-datasource',
  templateUrl: './kpi-report.datasource.component.html'
})
export class DatasourceComponent implements OnInit {
  companyId: number;
  StoredgeLastSyncDate: string;
  WorkOrderLastSyncDate:string;
  DavinciLockLastSyncDate:string;
  QuickBooksLastSyncDate:string;
  JiveLastSyncDate:string;
  @BlockUI('container-blockui') blockUI: NgBlockUI;
  @BlockUI('loader-blockui')loaderUI:NgBlockUI;
  @BlockUI('container-blockui-workorder')workOrderUI:NgBlockUI;
  @BlockUI('container-blockui-davincilock')davinciLockUI:NgBlockUI;
  @BlockUI('container-blockui-quickbooks')quickBooksUI:NgBlockUI;
  @BlockUI('container-blockui-jive')jiveUI:NgBlockUI;

  constructor(private kpiReportService: KpiReportService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private appUtils:AppUtils) {

    this.route.params.subscribe((params) => {
      this.companyId = Number(params['companyId']);
    });


  }

  ngOnInit() {
    this.loadDataSourceSyncHistory();
  }

  startSync() {
    this.blockUI.start();
    this.kpiReportService.synchronizeStorEdge(this.companyId).subscribe(
      () => {
        this.blockUI.stop();
        this.loadDataSourceSyncHistory();
        this.toastr.success('Storedge Sync Successfully !!')
    },
    error => {
      this.blockUI.stop();
      this.appUtils.ProcessErrorResponse(this.toastr, error,'Storedge Sync Un Successfully !!');
    },
    ()=>{
      this.blockUI.stop();
    }      
    );
  }

  SyncWorkOrder(){
    this.workOrderUI.start();
    this.kpiReportService.synchronizeWorkOrder(this.companyId).subscribe(
      ()=>{
        this.workOrderUI.stop();
        this.loadDataSourceSyncHistory();
        this.toastr.success("WorkOrder Sync Successfully !!")
      },
      error => {
        this.workOrderUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error,'WorkOrder Sync Un Successfully !!');
      },
      ()=>{
        this.workOrderUI.stop();
      }      

    );
  }

  SyncDavinciLock(){
    this.davinciLockUI.start();
    this.kpiReportService.synchronizeDavinciLock(this.companyId).subscribe(
      ()=>{
        this.davinciLockUI.stop();
        this.loadDataSourceSyncHistory();
        this.toastr.success("Sync Successfully !!")
      },
      error => {
        this.davinciLockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error,"Sync Un-Successfully !!");
      },
      ()=>{
        this.davinciLockUI.stop();
      }      

    );
  }

  SyncQuickBooks(){
    this.quickBooksUI.start();
    this.kpiReportService.synchronizeQuickBooks(this.companyId).subscribe(
      ()=>{
        this.quickBooksUI.stop();
        this.loadDataSourceSyncHistory();
        this.toastr.success("Sync Successfully !!")
      },
      error => {
        this.quickBooksUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error,"Sync Un-Successfully !!");
      },
      ()=>{
        this.quickBooksUI.stop();
      }      

    );
  }

  SyncJiveCommunication(){
    this.jiveUI.start();
    this.kpiReportService.synchronizeJive(this.companyId).subscribe(
      ()=>{
        this.jiveUI.stop();
        this.loadDataSourceSyncHistory();
        this.toastr.success("Sync Successfully !!")
      },
      error => {
        this.jiveUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error,"Sync Un-Successfully !!");
      },
      ()=>{
        this.jiveUI.stop();
      }      

    );
  }

  loadDataSourceSyncHistory()
  {
    this.loaderUI.start();
    this.kpiReportService.getDataSourceSynchnorize(this.companyId).subscribe(
      (data:any)=>{
        if(data !=null && data.length > 0)
        {
            this.StoredgeLastSyncDate = "Last Synced at "+this.appUtils.getFormattedDateTime(data.filter(x=>x.dataSourceId == 1 && x.internalCompanyId == this.companyId).map(o=>o.syncDate).toString());
            this.WorkOrderLastSyncDate = "Last Synced at "+this.appUtils.getFormattedDateTime(data.filter(x=>x.dataSourceId == 3 && x.internalCompanyId == this.companyId).map(o=>o.syncDate).toString());
            this.DavinciLockLastSyncDate = "Last Synced at "+this.appUtils.getFormattedDateTime(data.filter(x=>x.dataSourceId == 4 && x.internalCompanyId == this.companyId).map(o=>o.syncDate).toString());
            this.QuickBooksLastSyncDate = "Last Synced at "+this.appUtils.getFormattedDateTime(data.filter(x=>x.dataSourceId == 5 && x.internalCompanyId == this.companyId).map(o=>o.syncDate).toString());
            this.JiveLastSyncDate = "Last Synced at "+this.appUtils.getFormattedDateTime(data.filter(x=>x.dataSourceId == 2 && x.internalCompanyId == this.companyId).map(o=>o.syncDate).toString());
        }
        this.loaderUI.stop();
      },
      ()=>{
        this.loaderUI.stop();
      }     
    );
  }

}
