import { Component, Input, } from '@angular/core';
import { StoredgeFacilityPreview, StoredgeUnitPreview } from '../../../models';
import { isNullOrUndefined } from '../../../helpers/tools';

@Component({
    selector: 'app-storedge-preview-unit',
    templateUrl: './storedge.preview.unit.component.html'
})

export class StoredgePreviewUnitComponent {
    @Input() facility: StoredgeFacilityPreview;

    onSelectAllUnitsChanged() {

        if (this.facility.selectAllUnits) {
            this.facility.isSelected = true;
        } else {
            const selectedUnits = this.facility.units.filter(item => {
                if (item.isSelected) {
                    return item;
                }
            });
            if (isNullOrUndefined(selectedUnits) || selectedUnits.length === 0) {
                this.facility.isSelected = false;
            }
        }

        this.facility.units.map((unit) => {
            if (unit.status === 3) {
                return;
            }
            unit.isSelected = this.facility.selectAllUnits;
        });
    }

    onUnitChanged(unit: StoredgeUnitPreview) {
        if (unit.isSelected) {
            this.facility.isSelected = true;
        } else {
            this.facility.selectAllUnits = false;

            const selectedUnits = this.facility.units.filter(item => {
                if (item.isSelected) {
                    return item;
                }
            });
            if (isNullOrUndefined(selectedUnits) || selectedUnits.length === 0) {
                this.facility.isSelected = false;
            }
        }
    }

}
