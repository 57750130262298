import { Component, HostBinding, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyService } from '../../../services';
import { CompanyEditModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-company-edit',
    templateUrl: './company.edit.component.html'
})

export class CompanyEditComponent implements OnInit, AfterViewInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model: CompanyEditModel = new CompanyEditModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private companyService: CompanyService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canEditCompany) {
            this.router.navigate(['/error/403']);
            return;
        }

        console.log(this.roleMatrix.role);

        this.route.params.subscribe(params => this.model.id = params['id']);

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.id.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.companyService.getForEdit(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                    if (this.model.status === 3) {
                        this.router.navigate(['/error/404']);
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const elem = this.renderer.selectRootElement('#name');
            elem.focus();
        });
    }

    submit() {
        this.blockUI.start();
        this.companyService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        if (this.roleMatrix.role === 'ORGSUPERADMIN'
                            || this.roleMatrix.role === 'ORGADMIN') {
                            this.router.navigate(['/company/detail', this.model.id]);
                        } else {
                            this.router.navigate(['/company/manage']);
                        }
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Company has been updated successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
