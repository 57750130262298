import { Component, HostBinding, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { RoleMatrix, AppUtils, AppSettings } from 'src/helpers';
import { UserGroupService, FacilityService } from 'src/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
    RecordStatus,
    DataTableResponseModel,
    ValidateUserGroupModel,
    FacilityAssigneeValidationModel,
    UserGroupDefaultAssigneeValidationModel,
    FacilityAssigneesValidationDto,
    UserGroupModel,
    UserGroupEditModel
} from 'src/models';

@Component({
    selector: 'app-user-group',
    templateUrl: './user.group.component.html'
})
export class UserGroupComponent {
    companyId = -1;

    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-manage-user-group') blockUI: NgBlockUI;
    @ViewChild('userGroupChallengeDeleteModal', { static: true }) userGroupChallengeDeleteModal: any;
    @ViewChild('userGroupChallengeDeactivateModal', { static: true }) userGroupChallengeDeactivateModal: any;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    groupIdToDelete: string;
    showActionColumn: boolean;

    constructor(
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private userGroupService: UserGroupService,
        private facilityService: FacilityService,
        private appUtils: AppUtils,
        private appSettings: AppSettings
    ) {
        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
        });
        this.showActionColumn = this.roleMatrix.canEditUserGroup || this.roleMatrix.canChangeStatusOfUserGroup || this.roleMatrix.canDeleteUserGroup;
    }

    ngOnInit(): void {
        if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter user groups...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[0, 'asc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {
                self.http
                    .post<DataTableResponseModel>(`${environment.apiBaseUrl}user-group/${this.companyId}`, dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Group Name',
                    width: '20%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'memberCount',
                    title: 'Members Added',
                    width: '20%'
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === RecordStatus.Active
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        if((self.roleMatrix.role.toUpperCase() === 'ORGADMIN' || self.roleMatrix.role.toUpperCase() === 'ORGUSER') && 
                                row.members.some(x => x.userId === self.appUtils.getUserId()) 
                                || self.roleMatrix.role.toUpperCase() === 'ORGSUPERADMIN'
                                || self.roleMatrix.role.toUpperCase() === 'PERSONNEL'
                                || self.roleMatrix.role.toUpperCase() === 'ADMIN'
                                || self.roleMatrix.role.toUpperCase() === 'SUPERADMIN')
                        {
                            const statusHtml =
                            row.status === RecordStatus.Active
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === RecordStatus.Inactive
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '';

                            const htmlString = (self.roleMatrix.canChangeStatusOfUserGroup ? statusHtml : '')
                                + (self.roleMatrix.canEditUserGroup ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                                + (self.roleMatrix.canDeleteUserGroup ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');

                            return htmlString;
                        }
                        return '';
                    }
                }
            ],
            rowCallback: function (row, data: any) {
                const $row = $(row);
                $row.find('[action-type = "toggle-status"]').off('click').on('click', function () {
                    self.toggleStatus(data as UserGroupModel);
                });

                $row.find('[action-type = "edit"]').off('click').on('click', function () {
                    self.router.navigate(['/company', self.companyId, 'user-group', data.id, 'edit']);
                });

                $row.find('[action-type = delete]').off('click').on('click', function () {
                    self.challengeDelete(data as UserGroupModel);
                });

                $row.find('[action-type=link-detail]').off('click').on('click', function () {
                    self.router.navigate(['/company', self.companyId, 'user-group', data.id, 'detail']);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit() {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    //#region Activate Deactivate
    toggleStatus(userGroupModel: UserGroupModel): void {
        this.loadAssignedFacilities(userGroupModel, (validateUserGroupModel: ValidateUserGroupModel, userGroupModel: UserGroupModel) => {
            if (userGroupModel.status == RecordStatus.Active) {
                if (validateUserGroupModel.singleUserGroupAssignedFactilities.length == 0 && validateUserGroupModel.multipleUserGroupAssignedFactilities.length == 0) {
                    this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(userGroupModel);
                } else if (validateUserGroupModel.singleUserGroupAssignedFactilities.length) {
                    let hasActiveFacility = validateUserGroupModel.singleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
                    if (hasActiveFacility) {
                        this.userGroupChallengeDeactivateModal.openDeactivateConfirmationModal(userGroupModel, (validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
                    } else {
                        this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(userGroupModel);
                    }

                } else if (validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
                    let hasActiveFacility = validateUserGroupModel.multipleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
                    if (hasActiveFacility) {
                        //Check and Get only those facilities in which any default assignees member of user group
                        let hasDefaultAssignee = userGroupModel.multipleUserGroupFacilities.find(x =>
                            x.defaultAssigneeExistsInOtherUserGroup == false ||
                            x.defaultAssigneeCustomerComplaintExistsInOtherUserGroup == false ||
                            x.defaultAssigneeMoveOutExistsInOtherUserGroup == false ||
                            x.defaultAssigneeMoveInExistsInOtherUserGroup == false)
                        if (hasDefaultAssignee) {
                            this.userGroupChallengeDeactivateModal.openDeactivateConfirmationForMultipleModal(userGroupModel,
                                (validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
                        } else {
                            userGroupModel.raiseEvent = true;
                            this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(userGroupModel);
                        }
                    } else {
                        this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(userGroupModel);
                    }

                } else {
                    this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(userGroupModel);
                }
            } else {
                this.initiateToggleStatus(userGroupModel);
            }
        });
    }

    initiateToggleStatus(userGroupModel: UserGroupModel) {
        this.blockUI.start();
        let editModel = new UserGroupEditModel();
        editModel.id = userGroupModel.id;
        editModel.raiseEvent = true;
        editModel.multipleUserGroupFacilities = userGroupModel.multipleUserGroupFacilities;
        editModel.onlyUserGroupFacilities = userGroupModel.onlyUserGroupFacilities;
        this.userGroupService.toggleStatus(editModel).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(`User Group been has been ${(userGroupModel.status === RecordStatus.Active ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    //#endregion Activate Deactivate

    //#region Delete

    challengeDelete(userGroupModel: UserGroupModel) {

        this.loadAssignedFacilities(userGroupModel, (validateUserGroupModel: ValidateUserGroupModel, userGroupModel: UserGroupModel) => {
            //Get Master List of Facilities affected.
            if (validateUserGroupModel.singleUserGroupAssignedFactilities.length == 0 &&
                validateUserGroupModel.multipleUserGroupAssignedFactilities.length == 0) {
                //No Facility assigned with user group, Directly delete
                // this.delete();      
                this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(userGroupModel);
            } else if (validateUserGroupModel.singleUserGroupAssignedFactilities.length) {

                let hasActiveFacility = validateUserGroupModel.singleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
                // Case 1:
                // When a user tries to delete the 'ONLY' user group assigned to a facility
                if (hasActiveFacility == true && userGroupModel.status === RecordStatus.Active) {
                    this.userGroupChallengeDeleteModal.openDeleteConfirmationModal(userGroupModel, (validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
                }

                // Case 2:
                // When a user tries to delete a deactivated user group from an active facility and it is the only user group assigned to the facility
                if (hasActiveFacility == true && userGroupModel.status === RecordStatus.Inactive) {
                    this.userGroupChallengeDeleteModal.openDeleteConfirmationModal(userGroupModel, (validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
                }

                //Case 4
                //When a user tries to delete an active user group associated with a deactivated facility, then the system will let the user delete the 
                if (hasActiveFacility == false && userGroupModel.status === RecordStatus.Active) {
                    // this.delete();      
                    this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(userGroupModel);
                }

                // Case 5:
                // When a user tries to delete a deactivated group associated with a deactivated facility, then the system will allow the user to do that. 
                if (hasActiveFacility == false && userGroupModel.status === RecordStatus.Inactive) {
                    // this.delete();      
                    this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(userGroupModel);
                }
            } else if (validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
                let hasActiveFacility = validateUserGroupModel.multipleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
                if (hasActiveFacility) {
                    // There are multiple user groups assigned to a facility,
                    // Case 3: 
                    // WHEN a user tries to delete the user group whose user is the default assignee for the customer complaints and move out
                    // THEN the system will allow the user to delete the group and pop will be shown

                    //Check and Get only those facilities in which any default assignees member of user group
                    let hasDefaultAssignee = userGroupModel.multipleUserGroupFacilities.find(x =>
                        x.defaultAssigneeExistsInOtherUserGroup == false ||
                        x.defaultAssigneeCustomerComplaintExistsInOtherUserGroup == false ||
                        x.defaultAssigneeMoveOutExistsInOtherUserGroup == false ||
                        x.defaultAssigneeMoveInExistsInOtherUserGroup == false)

                    if (hasDefaultAssignee) {
                        this.userGroupChallengeDeleteModal.openDeleteConfirmationForMultipleModal(userGroupModel,
                            (validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
                    } else {
                        this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(userGroupModel);
                    }
                } else {
                    this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(userGroupModel);
                }
            }
        });
    }

    delete(userGroupModel: UserGroupModel) {
        this.blockUI.start();
        let editModel = new UserGroupEditModel();
        editModel.id = userGroupModel.id;
        editModel.raiseEvent = userGroupModel.raiseEvent;
        editModel.multipleUserGroupFacilities = userGroupModel.multipleUserGroupFacilities;
        editModel.onlyUserGroupFacilities = userGroupModel.onlyUserGroupFacilities;

        this.userGroupService.delete(editModel).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            setTimeout(() => {
                this.toastr.success('User Group has been deleted successfully.');
            }, 500);
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }
    //#endregion Delete

    loadAssignedFacilities(userGroupModel: UserGroupModel, callback: any) {
        this.blockUI.start();
        this.facilityService.getFacilitiesUserGroups(this.companyId, userGroupModel.id)
            .subscribe((validateUserGroupModel: ValidateUserGroupModel) => {

                //Populate only factilitues
                userGroupModel.multipleUserGroupFacilities = [];
                userGroupModel.onlyUserGroupFacilities = validateUserGroupModel
                    .singleUserGroupAssignedFactilities
                    .filter(x => x.status != RecordStatus.Deleted)
                    .map(x => {
                        let model = new FacilityAssigneesValidationDto();
                        model.id = x.id;
                        model.defaultAssigneeId = x.defaultAssigneeId;
                        model.defaultAssigneeMoveOut = x.defaultAssigneeMoveOut;
                        model.defaultAssigneeMoveIn = x.defaultAssigneeMoveIn;
                        model.defaultAssigneeCustomerComplaints = x.defaultAssigneeCustomerComplaints;
                        return model;
                    });
                if (validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
                    //Check and Get only those facilities in which any default assignees member of user group
                    let validateFacilities: FacilityAssigneeValidationModel[] = validateUserGroupModel.multipleUserGroupAssignedFactilities.filter(x => {
                        return userGroupModel.members.some(member => {
                            return x.defaultAssigneeCustomerComplaints == member.userId || x.defaultAssigneeMoveOut == member.userId || x.defaultAssigneeMoveIn == member.userId ||
                                x.defaultAssigneeId == member.userId;
                        });
                    }).map(x => {
                        let model = new FacilityAssigneeValidationModel();
                        model.id = x.id;
                        model.defaultAssigneeId = x.defaultAssigneeId;
                        model.defaultAssigneeCustomerComplaints = x.defaultAssigneeCustomerComplaints;
                        model.defaultAssigneeMoveOut = x.defaultAssigneeMoveOut;
                        model.defaultAssigneeMoveIn = x.defaultAssigneeMoveIn;
                        model.userGroupIds = x.userGroupIds;
                        return model;
                    });
                    if (validateFacilities.length) {
                        let model = new UserGroupDefaultAssigneeValidationModel();
                        model.companyId = userGroupModel.companyId;
                        model.userGroupInOperation = userGroupModel.id;
                        model.facilityAssigneeValidationModels = validateFacilities;

                        //Validate if user-group is assigned to multiple facilities
                        this.blockUI.start();
                        this.userGroupService.validateDefaultAssignees(model)
                            .subscribe((data: FacilityAssigneesValidationDto[]) => {
                                userGroupModel.multipleUserGroupFacilities = data;
                                callback(validateUserGroupModel, userGroupModel);
                            }, error => {
                                this.appUtils.ProcessErrorResponse(this.toastr, error);
                            }, () => {
                                this.blockUI.stop();
                            });
                    } else {
                        userGroupModel.multipleUserGroupFacilities = validateUserGroupModel.multipleUserGroupAssignedFactilities.map(x => {
                            let model = new FacilityAssigneesValidationDto();
                            model.id = x.id;
                            model.defaultAssigneeExistsInOtherUserGroup = true;
                            model.defaultAssigneeCustomerComplaintExistsInOtherUserGroup = true;
                            model.defaultAssigneeMoveOutExistsInOtherUserGroup = true;
                            model.defaultAssigneeMoveInExistsInOtherUserGroup = true;
                            return model;
                        });
                        callback(validateUserGroupModel, userGroupModel);
                    }
                } else {
                    callback(validateUserGroupModel, userGroupModel);
                }
            }, error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }, () => {
                this.blockUI.stop();
            });
    }
}
