import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { SignageAddModel, SignageEditModel, SignageDisplayOrderModel, ImageCropperModel } from '../models';
import { AppSettings } from '../helpers';

@Injectable()
export class SignageService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    uploadContent(file: any, model: ImageCropperModel) {
        const formData: FormData = new FormData();

        formData.append('file', file);

        if (model.fileName) {
            formData.append('fileName', model.fileName);
            formData.append('processingWidth', model.processingWidth.toString());
            formData.append('processingHeight', model.processingHeight.toString());
            formData.append('dimensionX1', model.dimensionX1.toString());
            formData.append('dimensionX2', model.dimensionX2.toString());
            formData.append('dimensionY1', model.dimensionY1.toString());
            formData.append('dimensionY2', model.dimensionY2.toString());
        }

        return this.http.post(environment.apiBaseUrl + 'signage/upload-content', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    uploadThumbnail(file: any, model: ImageCropperModel) {
        const formData: FormData = new FormData();

        formData.append('file', file);
        formData.append('fileName', model.fileName);
        formData.append('processingWidth', model.processingWidth.toString());
        formData.append('processingHeight', model.processingHeight.toString());
        formData.append('dimensionX1', model.dimensionX1.toString());
        formData.append('dimensionX2', model.dimensionX2.toString());
        formData.append('dimensionY1', model.dimensionY1.toString());
        formData.append('dimensionY2', model.dimensionY2.toString());

        return this.http.post(environment.apiBaseUrl + 'signage/upload-thumbnail', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    add(model: SignageAddModel) {
        return this.http.post(environment.apiBaseUrl + 'signage/add', model);
    }

    edit(model: SignageEditModel) {
        return this.http.post(environment.apiBaseUrl + 'signage/edit', model);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'signage/delete/' + id, null);
    }

    getForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'signage/get-for-edit/' + id);
    }

    getList(kioskId: number) {
        return this.http.get(environment.apiBaseUrl + 'signage/get-list/' + kioskId);
    }

    getSummaryList(kioskId: number) {
        return this.http.get(environment.apiBaseUrl + 'signage/get-summary-list/' + kioskId);
    }

    updateDisplayOrder(model: Array<SignageDisplayOrderModel>) {
        return this.http.post(environment.apiBaseUrl + 'signage/update-display-order', model);
    }
}
