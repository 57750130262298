<div class="row">
    <div class="col-12">
        <app-dashboard-work-orders [companyId]="companyId"></app-dashboard-work-orders>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <app-dashboard-company-tiles [companyId]="companyId"></app-dashboard-company-tiles>
    </div>
    <div class="col-4">
        <div class="row">
            <div class="col-md-12">
                <app-dashboard-kiosk-health-status [companyId]="companyId" [showCallMachineData]="false"></app-dashboard-kiosk-health-status>
            </div>
            <div class="col-md-12">
                <app-dashboard-kiosk-health-status [companyId]="companyId" [showCallMachineData]="true"></app-dashboard-kiosk-health-status>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="row">
            <div class="col-12">
                <app-activity-log-recent></app-activity-log-recent>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <app-dashboard-offline-kiosk-status [companyId]="companyId"></app-dashboard-offline-kiosk-status>
    </div>
</div>