<ng-template #kioskTimestampModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Offline Kiosk Timestamp</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeTimestampModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table break-all">
            <tr class="pb-5" *ngFor="let timestamp of timestampLogs">
                <div class="row border-bottom m-b-5 m-t-10">
                    <div class="col-12">
                        <h6>{{ timestamp.date}}</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <span class="kt-font-bold">Uptime</span>
                    </div>
                    <div class="col-6">
                        <span class="kt-font-bold">Downtime</span>
                    </div>
                </div>
                <div class="row m-t-5" *ngFor="let log of timestamp.offlineLogs">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <span *ngIf="log.connectedAt">
                                    <em class="la la-arrow-up text-success m-r-5"></em> {{log.connectedAt}}
                                </span>
                            </div>
                            <div class="col-6" *ngIf="log.disconnectedAt">
                                <span>
                                    <em class="la la-arrow-down text-danger m-r-5"></em> {{log.disconnectedAt}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </tr>
        </table>
    </div>
</ng-template>