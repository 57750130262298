<div class="row break-word">
    <div class="col-12" *ngFor="let item of activityLogs; let $index=index">
        <span *ngIf="checkForApplicationType(item.activityDetails, 'Apply Changes')"><span class="kt-font-bold">Kiosk</span> - Changes applied to the kiosk Machine </span>
        <span *ngIf="item.signageId || checkForApplicationType(item.activityDetails, 'Signage')" class="kt-font-bold">Signage </span> 
        <span *ngIf="item.amenityId || item.configurationId || item.fieldDetailId || checkForApplicationType(item.activityDetails, 'Move-In')" class="kt-font-bold">Move in </span>
        - <span class="kt-font-bold">{{item.createdByName}}</span> on
        <span class="kt-font-bold"> {{item.createdOn}}</span>
        <div *ngIf="item.activityType == 1">
            <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                <div class="col-1"></div>
                <div class="col-11" *ngIf="activity.fieldName == 'Signage' || activity.fieldName == 'Amenity'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> for </span>
                    <span><em>{{activity.newValue}}</em></span>
                    <span> has been added </span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName != 'Signage' && activity.fieldName != 'Amenity'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> has been added </span>
                </div>
            </div>
        </div>
        <div *ngIf="item.activityType == 2">
            <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                <div class="col-1"></div>
                <div class="col-11" *ngIf="activity.fieldName == 'Upload Content' || activity.fieldName == 'Document File' ||  activity.fieldName == 'Logo'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> has been updated </span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName == 'Signage' && !activity.signageId || activity.fieldName == 'Move-In'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> application has been <em>{{activity.newValue}}</em></span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName == 'Driver License Number'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">Mandatory Fields</span>
                    <span> <em> {{activity.fieldName}}</em> has been <em>{{activity.newValue}}</em></span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName == 'Internal Description'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span *ngIf="!activity.oldValue && activity.newValue"> has been added </span>
                    <span *ngIf="activity.oldValue && activity.newValue"> changed from <em>{{activity.oldValue}} </em> to <em>{{activity.newValue}} </em></span>
                    <span *ngIf="activity.oldValue && !activity.newValue"> has been removed </span>
                </div>
                <div class="col-11" 
                    *ngIf="activity.fieldName != 'Upload Content' && activity.fieldName != 'Document File' 
                    && activity.fieldName != 'Logo' && activity.fieldName != 'Move-In' && activity.fieldName != 'Apply Changes' 
                    && activity.fieldName != 'Internal Description' && activity.fieldName != 'Driver License Number' 
                    && activity.fieldName != 'Signage' || (activity.fieldName == 'Signage' && activity.signageId)">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> changed </span>
                    <span *ngIf="activity.oldValue">from <em>{{activity.oldValue}} </em></span>
                    <span> to <em>{{activity.newValue}} </em></span>
                </div>
            </div>
        </div>
        <div *ngIf="item.activityType == 3">
            <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                <div class="col-1"></div>
                <div class="col-11" *ngIf="activity.fieldName != 'Signage' && activity.fieldName != 'Amenity' && activity.fieldName != 'Call Center Phone Number'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> has been deleted </span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName == 'Call Center Phone Number'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> <em>{{activity.oldValue}}</em> has been removed</span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName == 'Signage' || activity.fieldName == 'Amenity'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}}</span>
                    <span> for </span>
                    <span><em>{{activity.oldValue}}</em></span>
                    <span> has been deleted </span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(activityLogs.length - 1) > $index">
            <div class="col-12">
                <div class="kt-divider m-t-15 m-b-15">
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="activityLogs && activityLogs.length === 0">
        <p>No log found</p>
    </div>
</div>