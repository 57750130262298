import { Component, HostBinding, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DragulaService } from 'ng2-dragula';
import { SignageService, KioskService } from 'src/services';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { SignageListItemModel, SignageDisplayOrderModel, KioskChangeApplicationModel } from 'src/models';
import { ImageViewerModalComponent, VideoViewerModalComponent, PdfViewerModalComponent } from 'src/components/partial';

@Component({
    selector: 'app-signage-manage',
    templateUrl: './signage.manage.component.html'
})

export class SignageManageComponent implements OnInit, OnDestroy {
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('containerSignageContentList') containerSignageContentList: ElementRef;
    @ViewChild('imageViewerModal', { static: true }) imageViewerModal: ImageViewerModalComponent;
    @ViewChild('videoViewerModal', { static: true }) videoViewerModal: VideoViewerModalComponent;
    @ViewChild('pdfViewerModal', { static: true }) pdfViewerModal: PdfViewerModalComponent;
    dragulaSubscription: Subscription;

    roleMatrix: RoleMatrix;
    companyId = -1;
    facilityId = -1;
    kioskId = -1;
    isModelLoaded = false;
    signages: Array<SignageListItemModel> = new Array<SignageListItemModel>();
    displayOrders: Array<SignageDisplayOrderModel>;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private dragulaService: DragulaService,
        private appUtils: AppUtils,
        private signageService: SignageService,
        private kioskService: KioskService) {
        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];
        });

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }

        this.dragulaSubscription = new Subscription();

        this.dragulaSubscription.add(this.dragulaService.drop('signage_contents')
            .subscribe(({ name, el, target, source, sibling }) => {
                this.updateOrder();
            })
        );
    }

    ngOnInit(): void {
        this.loadSignages();
    }

    updateOrder() {
        this.displayOrders = new Array<SignageDisplayOrderModel>();
        let displayOrder = 1;
        for (const item of this.containerSignageContentList.nativeElement.children) {
            const signageUpdateOrderModel = new SignageDisplayOrderModel();
            signageUpdateOrderModel.id = Number(item.id);
            signageUpdateOrderModel.displayOrder = displayOrder++;
            this.displayOrders.push(signageUpdateOrderModel);
        }

        this.blockUI.start();
        this.signageService.updateDisplayOrder(this.displayOrders)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success('Display order has been udpated successfully');
                    this.displayOrders.map((item: SignageDisplayOrderModel) => {
                        const siganeContent = this.signages.find(x => x.id === item.id);
                        siganeContent.displayOrder = item.displayOrder;
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadSignages() {
        this.blockUI.start();
        this.signageService.getList(this.kioskId).subscribe(
            (data: any) => {
                this.blockUI.stop();
                this.signages = new Array<SignageListItemModel>();
                Object.assign(this.signages, data);
                if (this.signages.length === 0) {
                    this.chagneApplicationType();
                }
                this.signages.map((item) => {
                    item.createdOn = this.appUtils.getFormattedDate(item.createdOn);
                    if (item.contentFileType === 'PDF') {
                        item.thumbnailFileUrl = './assets/media/pdf-thumbnail.png';
                    }

                });
                this.isModelLoaded = true;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    edit(id: number) {
        this.router.navigate(['/signage/edit', this.companyId, this.facilityId, this.kioskId, id]);
    }

    delete(id: number) {
        if (!confirm('Are you sure you want to delete the selected signage content?')) {
            return;
        }
        this.blockUI.start();
        this.signageService.delete(id).subscribe(
            () => {
                this.blockUI.stop();
                this.toastr.success('Signage content has been deleted successfully.');
                setTimeout(() => {
                    this.loadSignages();
                });
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    ngOnDestroy() {
        this.dragulaSubscription.unsubscribe();
    }

    showContent(selectedItem: SignageListItemModel) {
        if (selectedItem.contentFileType === 'Image') {
            this.imageViewerModal.openImageViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        } else if (selectedItem.contentFileType === 'Video') {
            this.videoViewerModal.openVideoViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        } else if (selectedItem.contentFileType === 'PDF') {
            this.pdfViewerModal.openPdfViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        }
    }

    chagneApplicationType() {
        const model = new KioskChangeApplicationModel();

        model.id = this.kioskId;
        model.applicationType = 2;
        model.isActive = false;

        this.kioskService.changeApplicationType(model)
            .subscribe(
                () => {
                    // TODO If message needed
                },
                error => {
                    this.blockUI.stop();
                });
    }
}
