import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { UnitAddModel, UnitEditModel } from '../models';
import { AppSettings } from '../helpers';

@Injectable()
export class UnitService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    add(model: UnitAddModel) {
        return this.http.post(environment.apiBaseUrl + 'unit/add', model);
    }

    edit(model: UnitEditModel) {
        return this.http.post(environment.apiBaseUrl + 'unit/edit', model);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'unit/get-detail/' + id);
    }

    getForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'unit/get-for-edit/' + id);
    }

    toggleStatus(id: number) {
        return this.http.post(environment.apiBaseUrl + 'unit/toggle-status/' + id, null);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'unit/delete/' + id, null);
    }

    getCountByCompany(id: number) {
        return this.http.get(environment.apiBaseUrl + 'unit/get-count-by-company/' + id);
    }

    getSelectListItems(facilityId: number) {
        return this.http.get(environment.apiBaseUrl + 'unit/get-select-list-items/' + facilityId);
    }

    getSelectListItemsByFacilityIds(facilityIds: Array<string>) {
        let queryString = '?';
        facilityIds.map((facilityId) => {
            queryString += 'facilityIds=' + facilityId + '&';
        });
        return this.http.get(environment.apiBaseUrl + 'unit/get-select-list-items-by-facility-ids' + queryString);
    }

    getSelectListItemsWithAssignedToWorkOrder(facilityIds: Array<number>) {
        let queryString = facilityIds.length > 0 ? '?':'';
        facilityIds.map((facilityId) => {
            queryString += 'facilityIds=' + facilityId + '&';
        });
        return this.http.get(environment.apiBaseUrl + 'unit/get-select-list-items-with-assigned-to-work-order' + queryString);
    }

    getUnitStatisticsOfActiveRecords(id: number) {
        return this.http.get(environment.apiBaseUrl + 'unit/get-unit-statistics-of-active-records/' + id);
    }
}
