import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services';

@Component({
    selector: 'app-dashboard-company-admin-tile',
    templateUrl: './dashboard.company.admin.tile.component.html'
})

export class DashboardCompanyAdminTileComponent implements OnInit {
    @Input() companyId: number;
    @Output() tileLoaded = new EventEmitter<any>();
    isModelLoaded = false;
    totalAdmins = 0;

    constructor(private userService: UserService,
                private router: Router) { }

    ngOnInit(): void {
        this.userService.getCompanyAdministratorsCount(this.companyId)
            .subscribe(
                (data: any) => {
                    this.totalAdmins = data;
                    this.isModelLoaded = true;
                    this.tileLoaded.emit();
                },
                error => {
                    console.log('DashboardCompanyAdminTileComponent load failed');
                    console.log(error);
                });
    }

    onAdminTileClicked () {
        this.router.navigateByUrl(`/company-user/manage-administrator?companyId=${this.companyId}`);
    }
}
