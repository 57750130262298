import { Component, Input } from '@angular/core';
import { WorkOrderListItemModel } from '../../../models';

@Component({
    selector: 'app-work-order-grid-view-item',
    templateUrl: './work.order.grid.view.item.component.html'
})

export class WorkOrderGridItemComponent {
    @Input() model: WorkOrderListItemModel;
}
