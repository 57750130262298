import { Component, HostBinding, Renderer2, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyService } from '../../../services';
import { CompanyAddModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { TimeZone } from 'src/models/timezone.model';

@Component({
    selector: 'app-company-add',
    templateUrl: './company.add.component.html'
})
export class CompanyAddComponent implements OnInit, AfterViewInit {
    roleMatrix: RoleMatrix;

    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    model: CompanyAddModel = new CompanyAddModel();

    timeZones: TimeZone[];

    constructor(private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddCompany) {
            this.router.navigate(['/error/403']);
            return;
        }
    }

    ngOnInit() {
        this.loadTimeZones();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const elem = this.renderer.selectRootElement('#name');
            elem.focus();
        });
    }

    submit() {
        this.blockUI.start();
        this.companyService.add(this.model)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/company/detail', data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Company has been added successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
}
