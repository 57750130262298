import { Component, HostBinding, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import {
    WorkOrderDetailModel, WorkOrderUpdatePriorityModel, WorkOrderUpdateWorkOrderStatusModel, SelectListItemModel, AssigneeSelectListItemModel, AssignedUserModel
} from '../../../models';
import { WorkOrderService, UserService, FacilityService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { NgImageSliderComponent } from 'ng-image-slider';
import { SetNotificationPreferenceComponent } from '../set-notification-preference/set-notification-preference.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/components/shared';
import { WoSubTaskDto } from 'src/models/work-order/sub-tasks/work.order.sub.task.model';
import { ManageSubTasksComponent } from '../sub-tasks/manage/manage-sub-tasks.component';
declare var appConfig: any;

@Component({
    selector: 'app-work-order-detail',
    templateUrl: './work.order.detail.component.html'
})
export class WorkOrderDetailComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('addWorkOrderNote', { static: true }) addWorkOrderNote: any;
    @ViewChild('setNotificationPreference', { static: true }) setNotificationPreference: SetNotificationPreferenceComponent;
    @ViewChild('imageSlider') imageSlider: NgImageSliderComponent;
    @ViewChild('subTaskSection') subTaskSection: ManageSubTasksComponent;
    @BlockUI('container-blockui-sub-tasks') blockUI: NgBlockUI;
    
    workOrderAssignees: Array<AssigneeSelectListItemModel> = Array<AssigneeSelectListItemModel>();
    showActionColumn = false;
    companyId = -1;
    viewType = 1;
    isModelLoaded = false;
    showAssigneeToMeButton = false;
    isUserExistForFacility = false;
    model: WorkOrderDetailModel = new WorkOrderDetailModel();
    imageObject: Array<object> = [];

    clientUsers: SelectListItemModel[] = [];
    watchers: SelectListItemModel[] = [];

    isUserCreator = false;
    isUserWatcher = false;
    currentUserId: string;
    note: string;
    instruction: string;

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.imageSlider.close();
        return;
    }

    get daysDelayed() {
        if (!this.model || !this.model.dueDate) {
            return 0;
        }

        const dueDate = new Date(this.model.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24));
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private workOrderService: WorkOrderService,
        private userService: UserService,
        private appUtils: AppUtils,
        private modalService: NgbModal) {
        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.viewType = params['viewType'];
            this.companyId = params['companyId'];
            this.model.id = params['id'];
            
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.showActionColumn = this.roleMatrix.canEditWorkOrder || this.roleMatrix.canDeleteWorkOrder;

        this.updateShowActionColumn();

        this.currentUserId = this.appUtils.getUserId();
        
    }

    ngOnInit() {
        
        this.loadClientUsers();
        this.loadWorkOrder(true);
        
        
    }

    loadIsUsersExistFacility() {
        this.blockUI.start();
        this.facilityService.getUsersAndUserGroupsList(Number(this.model.facilityId), true, true, true)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    for(let item of data.users) {
                        if (item.id == this.currentUserId) {
                            this.isUserExistForFacility = true;
                        }
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
            
        }

    loadClientUsers() {
        this.blockUI.start();
        this.userService.getCompanyUsersSelectItem(this.companyId)
            .subscribe(
                (data: Array<any>) => {
                    this.blockUI.stop();
                    if (!data || data.length === 0) {
                        return;
                    }
                    this.clientUsers = data;
                    this.setWatchers();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
    
    loadWorkOrder(isFirstTimeOnLoad: boolean) {
        this.blockUI.start();
        this.workOrderService.getDetail(this.model.id)
            .subscribe(
                (data) => {
                    if (data === null) {
                        this.router.navigate(['/error/404']);
                        return;
                    }
                    this.blockUI.stop();

                    Object.assign(this.model, data);
                    this.loadIsUsersExistFacility();
                    if (!isFirstTimeOnLoad) {
                        this.subTaskSection.refreshData();
                    }

                    this.isUserCreator = this.model.createdById.toLowerCase() === this.currentUserId.toLowerCase();
                    this.isUserWatcher = this.model.watchersIds &&
                        !!this.model.watchersIds.find(x => x.toLowerCase() === this.currentUserId.toLowerCase());

                    this.model.dueDate = this.appUtils.getFormattedDate(this.model.dueDate, true);
                    this.model.createdOn = this.appUtils.getFormattedDateTimeFull(this.model.createdOn);

                    if (this.model.note) {
                        this.model.note.createdOn
                            = this.appUtils.getFormattedDateTimeFull(this.model.note.createdOn);
                        this.model.notes.forEach((item) => {
                            if ((item.createdByName !== 'System' && item.createdByRole === 'SUPERADMIN') ||
                                 item.createdByRole === 'ADMIN' || item.createdByRole === 'USER' ||
                                (item.createdByName !== 'System' && item.createdByRole === 'SuperAdmin') ||
                                 item.createdByRole === 'Admin' || item.createdByRole === 'User') {
                                item.createdByName = 'Admin';
                            }
                            item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                        });
                    }
                    if (this.model.activityLog) {
                        this.model.activityLog.createdOn
                            = this.appUtils.getFormattedDateTimeFull(this.model.activityLog.createdOn);
                        this.model.activityLogs.forEach((item) => {
                            item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                        });
                    }
                    if (this.model.status === 3) {
                        this.roleMatrix.configureAllowActionsForWorkOrder();
                        this.updateShowActionColumn();
                    }

                    this.imageObject = [];
                    this.model.photos.forEach(photo => {
                        this.imageObject.push({
                            image: photo.fileUrl,
                            thumbImage: photo.fileUrl,
                            alt: photo.originalFileName
                        });
                    });

                    this.setWatchers();

                    this.isModelLoaded = true;

                    setTimeout(() => {
                        appConfig.initKTDefaults();
                    });

                    this.setRecurringContent();

                    if ((this.isUserCreator || this.isUserWatcher ||  this.model.assignedToId === this.currentUserId) &&
                        this.model.notes && this.model.notes.length > 0) {
                        this.markNotesAsRead(this.model.id, this.model.notes[0].id);
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    recurringContent: string = '';
    setRecurringContent() {
        if (this.model.isRecurring == false || this.model.workOrderRecurrence == null) {
            return;
        }

        let terminates = "";
        if (this.model.workOrderRecurrence.terminateOn != null) {
            terminates = `until ${this.appUtils.getFormattedDate(this.model.workOrderRecurrence.terminateOn, true)}`;
        }
        if(this.model.workOrderRecurrence.isProcessed){
            terminates += `(Recurred workorder created)`;
        }

        if (this.model.workOrderRecurrence.frequency == 2) {
            //Weekly
            let daylabel = "";
            let selectedWeekDayList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].filter((element, i) => {
                return this.model.workOrderRecurrence['is' + element] === true;
            });

            
            if (selectedWeekDayList.length == 1) {
                daylabel = selectedWeekDayList[0];
            } else {
                var allExceptLast = selectedWeekDayList.slice(0, selectedWeekDayList.length - 1);
                daylabel = `${allExceptLast.join(',&nbsp;')} and ${selectedWeekDayList[selectedWeekDayList.length - 1]}`
            }

            let everyNWeek =  '&nbsp;week';
            if(this.model.workOrderRecurrence.repeatEveryNWeek != null && this.model.workOrderRecurrence.repeatEveryNWeek > 1){
                everyNWeek = `&nbsp;${this.model.workOrderRecurrence.repeatEveryNWeek}&nbsp;weeks`;
            }
            this.recurringContent = `Every${everyNWeek} on ${daylabel} ${terminates}`;
        } else if (this.model.workOrderRecurrence.frequency == 3) {
            //Monthly
            let monthlyLabel = "";
            if (this.model.workOrderRecurrence.repeatEveryMonthOn.length == 1) {
                monthlyLabel = this.model.workOrderRecurrence.repeatEveryMonthOn[0].toString();
            } else {
                var allExceptLast = this.model.workOrderRecurrence.repeatEveryMonthOn.slice(0, this.model.workOrderRecurrence.repeatEveryMonthOn.length - 1).map(x => x.toString());
                monthlyLabel = `${allExceptLast.join(',&nbsp;')} and ${this.model.workOrderRecurrence.repeatEveryMonthOn[this.model.workOrderRecurrence.repeatEveryMonthOn.length - 1]}`
            }

            let everyNMonth =  '&nbsp;month';
            if(this.model.workOrderRecurrence.repeatEveryNMonth != null && this.model.workOrderRecurrence.repeatEveryNMonth > 1){
              everyNMonth = `&nbsp;${this.model.workOrderRecurrence.repeatEveryNMonth}&nbsp;months`;
            }
            this.recurringContent = `Every${everyNMonth} on ${monthlyLabel} ${terminates}`;
        }
    }

    delete(): void {
        if (!confirm('Are you sure you want to delete the selected work order?')) {
            return;
        }
        this.blockUI.start();
        this.workOrderService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.router.navigate(['/work-order/manage', this.companyId]);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Work order has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    openWorkOrderNoteModal() {
        this.addWorkOrderNote.openWorkOrderNoteModal();
    }

    updatePriority(priority: number) {
        if (priority === this.model.workOrderPriority) {
            return;
        }
        const priorityModel = new WorkOrderUpdatePriorityModel();
        priorityModel.id = this.model.id;
        priorityModel.priority = priority;
        this.blockUI.start();
        this.workOrderService.udpatePriority(priorityModel)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.loadWorkOrder(false);
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    getOnHoldNote() {
        this.addWorkOrderNote.isWorkOrderStatusOnHold = true;
        this.addWorkOrderNote.openWorkOrderNoteModal();
    }

    updateWorkOrderStatus(status: number) {
        this.blockUI.start();
        const statusModel = new WorkOrderUpdateWorkOrderStatusModel();
        statusModel.id = this.model.id;
        statusModel.workOrderStatus = status;
        if(status == 4 && this.model.openSubTaskCount > 0){
            statusModel.agreeCloseAllSubTaskAutomatically = confirm("Some tasks have not been completed. These will be closed along with the work order.");
        }
        this.workOrderService.udpateWorkOrderStatus(statusModel)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.loadWorkOrder(false);
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateShowActionColumn() {
        this.showActionColumn = this.roleMatrix.canEditWorkOrder ||
            (this.roleMatrix.canDeleteWorkOrder && this.model.workOrderStatus === 4);
    }

    showSetNotificationPreferenceModal() {
        this.setNotificationPreference.openModal();
    }

    showNote(modelContent: any, note: string) {
        this.note = note;
        this.modalService.open(modelContent,
            {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });
    }

    showInstruction(modelContent: any, instruction: string) {
        this.instruction = instruction;
        this.modalService.open(modelContent, 
            {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });
    }

    private setWatchers() {
        if (!this.clientUsers || this.clientUsers.length === 0 ||
            !this.model.watchersIds || this.model.watchersIds.length === 0) {
            return;
        }

        this.watchers = this.model.watchersIds
            .map(x => this.clientUsers.find(u => u.key === x))
            .filter(x => x)
            .sort(function (item1, item2) {
                const item1Name = item1.value.toUpperCase();
                const item2Name = item2.value.toUpperCase();
                return (item1Name < item2Name) ? -1 : (item1Name > item2Name) ? 1 : 0;
            });
    }

    private markNotesAsRead(workOrderId: number, noteId: number) {
        this.workOrderService.markNoteAsRead(workOrderId, noteId)
            .subscribe(data => {
                // success
            });
    }

    assignToMe(){
        this.blockUI.start();
        this.workOrderService.assignMe(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.toastr.success('Work order has been Assign successfully.');
                }, 100);
                this.loadWorkOrder(true);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
}
