<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-company-owner-edit></app-breadcrumb-company-owner-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui'">
        <div class="row">
            <div class="col-md-10">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Account Owner
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && !accountOwnerName.valid}">
                                        <label class="col-md-3">Account Owner Name:*</label>
                                        <div class="col-md-9">
                                            <input type="text" name="accountOwnerName" class="form-control"
                                                maxlength="50" autocomplete="off" [(ngModel)]="model.accountOwnerName"
                                                #accountOwnerName="ngModel" appAlphabatesWithSpaceOnly required />
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && !accountOwnerName.valid">
                                                Account owner name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && accountOwnerEmail.invalid}">
                                        <label class="col-md-3">Account Owner Email:*</label>
                                        <div class="col-md-9">
                                            <input type="email" name="accountOwnerEmail" class="form-control"
                                                minlength="5" maxlength="50" autocomplete="off"
                                                [(ngModel)]="model.accountOwnerEmail" #accountOwnerEmail="ngModel"
                                                required />
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && accountOwnerEmail.invalid">
                                                <span *ngIf="accountOwnerEmail.errors.required">
                                                    Account owner email is required
                                                </span>
                                                <span *ngIf="accountOwnerEmail.errors.minlength">
                                                    Account owner email address must be greater than 4 characters
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && accountOwnerContactNumber.invalid}">
                                        <label class="col-md-3">Account Owner Contact Number:*</label>
                                        <div class="col-md-6">
                                            <input type="tel" name="accountOwnerContactNumber" class="form-control"
                                                minlength="7" maxlength="13" autocomplete="off"
                                                [(ngModel)]="model.accountOwnerContactNumber"
                                                #accountOwnerContactNumber="ngModel" appPhoneNumberOnly required />
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && accountOwnerContactNumber.invalid">
                                                <span *ngIf="accountOwnerContactNumber.errors.required">
                                                    Account owner contact number is required
                                                </span>
                                                <span *ngIf="accountOwnerContactNumber.errors.minlength">
                                                    Contact number must be in between 7 to 13 characters
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && accountOwnerContactNumber.invalid}">
                                        <label class="col-md-3">Account Owner Time Zone:*</label>
                                        <div class="col-md-9">
                                            <select name="accountOwnerTimeZone" class="form-control"
                                                [(ngModel)]="model.accountOwnerTimeZone"
                                                #accountOwnerTimeZone="ngModel"
                                                required>
                                                <option *ngFor="let timeZone of timeZones"
                                                    [value]="timeZone.standardName">
                                                    ({{timeZone.currentUtcOffset}}) {{timeZone.standardName}}
                                                </option>
                                            </select>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && accountOwnerTimeZone.invalid">
                                                <span *ngIf="accountOwnerTimeZone.errors.required">
                                                    Time zone is required.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/company/detail', model.id]"
                                    class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>