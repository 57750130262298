<div class="panel-heading report-pointerCursor" style="position:relative;" (click)="isHiddenDavinci = !isHiddenDavinci">
            <div class="row">
                <i *ngIf="isHiddenDavinci" class="fa fa-caret-right  color-primary fs-30" style="padding-left: 20px;"></i>
                <i *ngIf="!isHiddenDavinci" class="fa fa-caret-down  color-primary fs-30" style="padding-left: 20px;"></i>
                <h5 class="panel-title" style="padding-top: 5px; padding-left: 12px;">Davinci Lock</h5>               
            </div>
</div>
<form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
<div class="report-panel-body" [hidden]="isHiddenDavinci" *blockUI="'container-blockui-configuration'">
    <div class="row" [ngClass]="{ 'validation-error' : f.submitted && clientId.invalid && secretKey.invalid}">
        <h5 class="col-sm-3 text-right" style="margin-top: 33px; margin-left: 24px; margin-right: 10px;">Client ID:*</h5>
        <div class="col-md-6 report-margin-content">
            <input type="text" name="clientId" class="form-control" maxlength="50" autocomplete="off" 
            [(ngModel)]="model.clientId" #clientId="ngModel" required 
            [disabled]="model.davinciLockCompanyConfigured" appAnythingButWhiteSpace/>
             
            <div class=" validation-error-message" *ngIf="f.submitted && clientId.invalid">
                <span *ngIf="clientId.errors.required"> Client ID is required</span>
            </div>
        </div>
                           
            <h5 class="col-sm-3 text-right" style="margin-top: 33px; margin-left: 24px; margin-right: 10px;">Client Secret:*</h5>
                <div class="col-md-6 report-margin-content">
                    <input type="password" name="secretKey" class="form-control" maxlength="50" autocomplete="off" 
                    [(ngModel)]="model.secretKey" #secretKey="ngModel" required 
                    [disabled]="model.davinciLockCompanyConfigured" appAnythingButWhiteSpace/>

                    <div class="validation-error-message" *ngIf="f.submitted && secretKey.invalid">
                        <span *ngIf="secretKey.errors.required"> Client Secret is required</span>
                    </div>
                    <span class="m-t-2">
                     Enter your Client ID & Client Secret in Davinci Lock</span>
                    <p class="m-t-2"><b>**</b> This will configure your application with your data in Davinci Lock and the same will be reflected in the application</p>
                
                </div>
    </div>
        <div class="kt-portlet__foot" *ngIf="!model.davinciLockCompanyConfigured">
            <div class="kt-form__actions text-center">
                    <button type="submit" class="btn btn-primary" [disabled]="model.davinciLockCompanyConfigured && !showActionColumn">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-eye"></em>
                        </span>Configure</button>
            </div>
        </div>  
                        
</div>
</form>