<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-unit-add [companyId]="model.companyId" [facilityId]="model.facilityId">
            </app-breadcrumb-unit-add>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Add Unit
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && unitName.invalid}">
                                <label class="col-md-3">Unit Name:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="unitName" class="form-control" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.name" #unitName="ngModel"
                                        appAlphaNumericsLevelThree required />
                                    <div class="validation-error-message" *ngIf="f.submitted && unitName.invalid">
                                        <span *ngIf="unitName.errors.required"> Unit name is required</span>
                                        <span *ngIf="unitName.errors.maxlength">Maximum 50 characters are allowed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/facility/detail',model.companyId, model.facilityId]"
                                    class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>