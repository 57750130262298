import { Component, HostBinding, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, KioskChangeApplicationModel } from '../../../models';
import { AppUtils, AppSettings, RoleMatrix } from '../../../helpers';
import { MoveInService, KioskService } from 'src/services';

@Component({
    selector: 'app-movein-amenity-manage',
    templateUrl: './movein.amenity.manage.component.html'
})

export class MoveinAmenityManageComponent implements OnInit, AfterViewInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-amenity-manage') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    rowIndex = 0;
    pageLength = 10;
    search: any = null;

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private tostr: ToastrService,
        private moveInService: MoveInService,
        private appUtils: AppUtils,
        private appSettings: AppSettings,
        private kioskService: KioskService) {

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];
        });
    }

    ngOnInit(): void {

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter amenity...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[1, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                dataTablesParameters.kioskId = self.kioskId;

                self.http
                    .post<DataTableResponseModel>(environment.apiBaseUrl + 'moveIn/get-amenities', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        if (resp.recordsTotal === 0) {
                            this.chagneApplicationType();
                        }
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Amenity Name',
                    width: '20%'
                },
                {
                    data: 'isParkingAmenity',
                    title: 'Type',
                    width: '20%',
                    render: function (data) {
                        return data ? 'Parking' : 'Unit';
                    }
                },
                {
                    data: 'createdOn',
                    title: 'Added On',
                    width: '15%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'originalIconName',
                    title: 'Icon Uploaded',
                    width: '20%',
                    render: function (data) {
                        return '<em class="kt-menu__link-icon flaticon-attachment pr-2"></em>' + data;
                    }
                },
                {
                    data: 'iconUrl',
                    title: 'Icon Image',
                    width: '15%',
                    orderable: false,
                    render: function (data) {
                        return `<img src="${data}" style="width:70px;height:70px"/>`;
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, row) {
                        return `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>`;
                    }
                }
            ],
            rowCallback: function (row, data: any) {
                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.delete(data.id);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }

            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    delete(id: number): void {
        if (!confirm('Are you sure you want to delete the selected amenity?')) {
            return;
        }
        this.blockUI.start();
        this.moveInService.deleteAmenity(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.tostr.success('Amenity has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    refreshTableData() {
        this.dtInstance.ajax.reload(null, false);
    }

    chagneApplicationType() {
        const model = new KioskChangeApplicationModel();

        model.id = this.kioskId;
        model.applicationType = 1;
        model.isActive = false;

        this.kioskService.changeApplicationType(model)
            .subscribe(
                () => {
                    // TODO If message needed
                },
                error => {
                    this.blockUI.stop();
                });
    }
}
