
<div class="kt-portlet__body">
   
        
    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui'">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                    <thead>
                        <tr>
                            <th style="width: 10%;">
                                &nbsp;
                            </th>
                            <th style="width: 55%;">
                                <div class="float-left m-t-2">
                                    Facility Name
                                </div>
                            </th>
                            <th style="width: 35%;">
                                Realm ID
                            </th>
                            <th style="width: 20%;">
                                Action
                            </th>
                        </tr>
                    </thead>
                   
                </table>
                <ngb-accordion>
                    <ngb-panel>
                        <ng-template ngbPanelHeader>
                            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
                                <div class="row" [ngClass]="{ 'validation-error' : f.submitted && realmId.invalid && facilityId.invalid}">
                            <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                                <tbody>
                                   
                                    <tr>
                                        
                                        <td style="width: 40%;">
                                          <div class="col-md-12">
                                           <select name="facilityId" class="form-control"
                                                [(ngModel)]="model.facilityId" #facilityId="ngModel" required >
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of facilities" [value]="item.id">
                                                    {{item.name}}</option>
                                            </select>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && facilityId.invalid">
                                                Facility name is required
                                            </div>
                                            </div>

                                                    
                                        </td>
                                        <td>
                                            <div>
                                                <input type="text" name="realmId" #realmId="ngModel"
                                                [(ngModel)]="model.realmId"  required class="form-control" placeholder="Realm Id"
                                                 appAnythingButWhiteSpace/>
                                            </div>

                                            <div class="validation-error-message"  *ngIf="f.submitted && realmId.invalid">
                                                <span *ngIf="realmId.errors.required"> RealmId  is required</span>
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            <button type="submit" class="btn btn-primary">Save</button>
                                        </td>
                                       
                                    </tr>
                                
                                </tbody>
                            </table>
                            
                            
                                </div>
                            </form>
                            <br><br><br>
                        
                            <div class="row">
                                <div class="col-12">
                                    <table datatable [dtOptions]="dtOptions" data-page="2"
                                        class="table table-hover break-all">
                                    </table>
                                </div>
                            </div>

                        </ng-template>
                        <app-quickbooks-edit [facilities]="facilities"></app-quickbooks-edit>
                    </ngb-panel>
                </ngb-accordion>
        </div>
    </div>
</div>
