export class DavinciLockConfigurationModel {

    public clientId:string
    public secretKey:string

    public internalCompanyId: number;
    

    public davinciLockCompanyConfigured: boolean;

    constructor() {
        this.davinciLockCompanyConfigured = false;
    }
}