import { BehaviorSubject } from 'rxjs';
import { UserDetailModel } from 'src/models';
import { Injectable } from "@angular/core";

@Injectable()
export class MasterService {

    private _kpiDashboardSelectedTabIndex: string;
    get kpiDashboardSelectedTabIndex() {
        return this._kpiDashboardSelectedTabIndex;
    }
    set kpiDashboardSelectedTabIndex(index) {
        this._kpiDashboardSelectedTabIndex = index;
        localStorage.setItem('KPI_Dashboard_SelectedTabIndex', index);
    }

    private _userInfo$: BehaviorSubject<UserDetailModel>;
    get UserInfo$() {
        return this._userInfo$.asObservable();
    }

    private _dashboardShowPersonnalData$: BehaviorSubject<boolean>;
    get DashboardShowPersonnalData$() {
        return this._dashboardShowPersonnalData$.asObservable();
    }

    private _facilityhowPersonnalData$: BehaviorSubject<boolean>;
    get FacilityhowPersonnalData$() {
        return this._facilityhowPersonnalData$.asObservable();
    }

    private _workOrderShowPersonnalData$: BehaviorSubject<boolean>;
    get WorkOrderShowPersonnalData$() {
        return this._workOrderShowPersonnalData$.asObservable();
    }

    private _vendorShowPersonnalData$: BehaviorSubject<boolean>;
    get VendorShowPersonnalData$() {
        return this._vendorShowPersonnalData$.asObservable();
    }

    private _kioskShowPersonnalData$: BehaviorSubject<boolean>;
    get KioskShowPersonnalData$() {
        return this._kioskShowPersonnalData$.asObservable();
    }

    constructor() {
        this._userInfo$ = new BehaviorSubject<UserDetailModel>(new UserDetailModel());
        this._dashboardShowPersonnalData$ = new BehaviorSubject<boolean>(false);
        this._facilityhowPersonnalData$ = new BehaviorSubject<boolean>(false);
        this._workOrderShowPersonnalData$ = new BehaviorSubject<boolean>(false);
        this._vendorShowPersonnalData$ = new BehaviorSubject<boolean>(false);
        this._kioskShowPersonnalData$ = new BehaviorSubject<boolean>(false);
        this._kpiDashboardSelectedTabIndex = localStorage.getItem('KPI_Dashboard_SelectedTabIndex') || '1';
    }

    setUserInfo(userInfo: UserDetailModel) {
        this._userInfo$.next(userInfo);
    }

    setDashboardShowPersonnalData(showPersonalData: boolean) {
        this._dashboardShowPersonnalData$.next(showPersonalData);
    }

    setFacilityShowPersonnalData(showPersonalData: boolean) {
        this._facilityhowPersonnalData$.next(showPersonalData);
    }

    setWorkOrderShowPersonnalData(showPersonalData: boolean) {
        this._workOrderShowPersonnalData$.next(showPersonalData);
    }

    setVendorShowPersonnalData(showPersonalData: boolean) {
        this._vendorShowPersonnalData$.next(showPersonalData);
    }

    setKioskShowPersonnalData(showPersonalData: boolean) {
        this._kioskShowPersonnalData$.next(showPersonalData);
    }
}
