import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService } from '../../../services';
import { SelectListItemModel, CompanyUserAddModel, UserImageUploadModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZone } from 'src/models/timezone.model';
import { TimeZoneService } from 'src/services/timezone.service';

@Component({
    selector: 'app-company-user-add',
    templateUrl: './company.user.add.component.html'
})
export class CompanyUserAddComponent implements OnInit {
    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    roleMatrix: RoleMatrix;
    filteredUserType: string;
    model: CompanyUserAddModel = new CompanyUserAddModel();
    imageUploadModel: UserImageUploadModel = new UserImageUploadModel();
    roles: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    timeZones: TimeZone[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        this.route.queryParams.subscribe((params) => {
            if (params['companyId']) {
                this.model.companyId = params['companyId'];
            }
            if (params['userType']) {
                this.filteredUserType = params['userType'];
            }
            if (this.filteredUserType === 'admin') {
                this.model.role = 'OrgAdmin';
            } else if (this.filteredUserType === 'user') {
                this.model.role = 'OrgUser';
            }
        });

        if (!this.model.companyId
            || !this.filteredUserType
            || ((this.roleMatrix.role === 'ORGSUPERADMIN'
                || this.roleMatrix.role === 'ORGADMIN'
                || this.roleMatrix.role === 'ORGUSER')
                && (this.roleMatrix.companyId !== this.model.companyId.toString()))
            || (this.filteredUserType !== 'admin' && this.filteredUserType !== 'user' && this.filteredUserType !== 'any')) {
            this.router.navigate(['/error/404']);
        }

        if ((this.filteredUserType === 'admin' && !this.roleMatrix.canAddOrgAdmin)
            || (this.filteredUserType === 'user' && !this.roleMatrix.canAddOrgUser)) {
            this.router.navigate(['/error/403']);
        }

        if (this.roleMatrix.canAddOrgAdmin) {
            let role = new SelectListItemModel();
            role.key = 'OrgAdmin';
            role.value = 'Administrator';
            this.roles.push(role);

            role = new SelectListItemModel();
            role.key = 'OrgUser';
            role.value = 'Personnel';
            this.roles.push(role);
        } else {
            const role = new SelectListItemModel();
            role.key = 'OrgUser';
            role.value = 'Personnel';
            this.roles.push(role);
        }

        this.imageUploadModel.canEditProfileImage = true;
    }

    ngOnInit() {
        this.loadTimeZones();
    }

    submit() {
        if(!this.model.enableEmailNotification && !this.model.enablePushNotification) {
            this.toastr.error("Atleast one medium to receive notification should be selected.");
            return;
        }

        this.model.profileImage = this.imageUploadModel.fileName;
        this.blockUI.start();
        this.userService.addCompanyUser(this.model)
            .subscribe(
                (data) => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/company-user/detail', data],
                            { queryParams: { companyId: this.model.companyId, userType: this.filteredUserType } });
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('User has been added successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    onEmailNotificationDisabled() {
        if(!this.model.enableEmailNotification) {
            this.model.sendEmailNotification = false;
        }
    }

    onWatchersEmailNotificationEnabled() {
        if(this.model.sendEmailNotification){
            this.model.enableEmailNotification = true;
        }
    }

    onPushNotificationDisabled() {
        if(!this.model.enablePushNotification) {
            this.model.sendPushNotification = false;
        }
    }

    onWatchersPushNotificationEnabled() {
        if(this.model.sendPushNotification){
            this.model.enablePushNotification = true;
        }
    }
}
