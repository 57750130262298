import { Component, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AppUtils, RoleMatrix } from 'src/helpers';

@Component({
    selector: 'app-movein.configuration',
    templateUrl: './movein.configuration.component.html'
})

export class MoveinConfigurationComponent {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    activeTab = '1';

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    constructor(private route: ActivatedRoute) {
        this.roleMatrix = new RoleMatrix();
        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];
        });
    }

    openPreviewTab() {
        this.activeTab = '2';
    }
}
