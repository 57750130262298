<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-work-order-edit [companyId]="companyId" [viewType]="viewType">
            </app-breadcrumb-work-order-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Work Order
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate [appMustNotMatch]="['assignedToId', 'followUpAssigneeId']">
                        <div class="kt-portlet__body">
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && title.invalid}">
                                <label class="col-md-2">Title:*</label>
                                <div class="col-md-10">
                                    <input type="text" name="title" class="form-control" maxlength="100"
                                        autocomplete="off" [(ngModel)]="model.title" #title="ngModel"
                                        required />
                                    <div class="validation-error-message" *ngIf="f.submitted && title.invalid">
                                        <span *ngIf="title.errors.required"> Title is required</span>
                                        <span *ngIf="title.errors.maxlength">Maximum 100 characters are allowed</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && description.invalid}">
                                <label class="col-md-2">Description:*</label>
                                <div class="col-md-10">
                                    <textarea name="description" class="form-control" rows="2"
                                        autocomplete="off" [(ngModel)]="model.description" #description="ngModel"
                                        required></textarea>
                                    <div class="validation-error-message" *ngIf="f.submitted && description.invalid">
                                        <span *ngIf="description.errors.required"> Description is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label class="col-4">Category:</label>
                                        <div class="col-8">
                                            <div ngbDropdown class="convert-to-native-dropdown-container">
                                                <button type="button" class="btn btn-outline-primary heading" id="dropdownBasic1" ngbDropdownToggle
                                                    [disabled]="model.createdAutomatically && !model.isDelinquent">
                                                    {{ selectedCategory ? selectedCategory.name : '[Select]'}}
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="content">
                                                    <div ngbDropdownItem (click)="onCategoryChange(f, null)">
                                                        <span class="word-wrap2">
                                                            [Select]
                                                        </span>
                                                    </div>
                                                    <div ngbDropdownItem *ngFor="let item of workOrderCategories" (click)="onCategoryChange(f, item)">
                                                        <span class="word-wrap2"><span *ngIf="!!item.isUnrentable">*</span>{{item.name}}</span>
                                                        <span *ngIf="!!item.isUnrentable" class="kt-badge kt-badge--danger kt-badge--inline tag">
                                                            Unrentable
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && workOrderPriority.invalid}">
                                        <label class="col-4">Priority:*</label>
                                        <div class="col-8">
                                            <select class="form-control" name="workOrderPriority"
                                                [(ngModel)]="model.workOrderPriority" #workOrderPriority="ngModel"
                                                (change)="setReminderInterval()" required>
                                                <option value="">[Select]</option>
                                                <option value="1">Low</option>
                                                <option value="2">Medium</option>
                                                <option value="3">High</option>
                                            </select>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && workOrderPriority.invalid">
                                                Priority is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && dueDate.invalid}">
                                        <label class="col-4">Due Date:</label>
                                        <div class="col-8">
                                            <div class="input-group date">
                                                <input class="form-control" placeholder="MM/DD/YYYY" name="DueDate"
                                                    [(ngModel)]="model.dueDate" ngbDatepicker #d="ngbDatepicker"
                                                    #dueDate="ngModel" (focus)="d.open()" autocomplete="off"
                                                    [minDate]="minDueDate" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text cursor-pointer" (click)="d.toggle()">
                                                        <i class="la la-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && dueDate.invalid">
                                                <span> Due date is invalid</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && workOrderStatus.invalid}">
                                        <label class="col-4">Status:</label>
                                        <div class="col-8">
                                            <select class="form-control" name="workOrderStatus"
                                                [(ngModel)]="model.workOrderStatus" #workOrderStatus="ngModel" required>
                                                <option *ngFor="let item of workOrderStatuses" [value]="item.key">
                                                    {{item.value}}</option>
                                            </select>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && workOrderStatus.invalid">
                                                Status is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label class="col-4">Set As Recurring:</label>
                                        <div class="col-8">
                                            <select class="form-control" name="ngModelRecurring"
                                                [(ngModel)]="isRecurring"
                                                (ngModelChange)="onRecurranceChange($event, ngModelRecurring)"
                                                [disabled]="model.createdAutomatically || (model.workOrderRecurrence && model.workOrderRecurrence.isProcessed) || !isRecurringWOAllowed"
                                                #ngModelRecurring="ngModel">
                                                <option value="false">Does not Repeat</option>
                                                <option value="true">Set Custom Recurrence</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6"
                                    *ngIf="model.isRecurring && model.workOrderRecurrence && model.workOrderRecurrence.isProcessed">
                                    <span>Recurred workorder created</span>
                                </div>
                                <div class=" col-6"
                                    *ngIf="model.isRecurring && (!model.workOrderRecurrence  || (model.workOrderRecurrence && !model.workOrderRecurrence.isProcessed)) ">
                                    <button type="button" class="btn btn-primary" (click)="openRecurrance()"
                                        [disabled]="model.createdAutomatically || (model.workOrderRecurrence && model.workOrderRecurrence.isProcessed)">
                                        <span class="kt-menu__link-icon">
                                            <em class="kt-menu__link-icon flaticon-edit"></em>
                                        </span>
                                        <span class="kt-menu__link-text">Edit</span>
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <span class="kt-font-bold">Add Facility Details</span>
                                    <div class="kt-divider m-t-5 m-b-25">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && facilityId.invalid}">
                                        <label class="col-md-4">Facility:*</label>
                                        <div class="col-md-8">
                                            <select name="facilityId" class="form-control"
                                                [(ngModel)]="model.facilityId" #facilityId="ngModel"
                                                (change)="onFacitlityChanged()" [disabled]="model.createdAutomatically || !isFacilitySelectAllowed"
                                                required>
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of facilities" [value]="item.id">
                                                    {{item.name}}</option>
                                            </select>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && facilityId.invalid">
                                                Facility name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group row" 
                                        [ngClass]="{ 'validation-error' : f.submitted && unitId.invalid}">
                                        <label class="col-4">Unit<span *ngIf="selectedCategoryIsUnrentable">*</span>:</label>
                                        <div class="col-8">
                                            <select name="unitId" class="form-control" [(ngModel)]="model.unitId"
                                                #unitId="ngModel" [required]="selectedCategoryIsUnrentable"
                                                [disabled]="model.createdAutomatically && (!selectedCategory || selectedCategory.type !== categoryTypeEnum.CustomerComplaint) || !isUnitSelectAllowed">
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of units" [value]="item.id">
                                                    {{item.name}}</option>
                                            </select> 
                                            <div class="validation-error-message" *ngIf="f.submitted && unitId.invalid">
                                                The unit name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span class="kt-font-bold">Add Assignee</span>
                                    <div class="kt-divider m-t-5 m-b-25">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label class="col-4">Assignee:</label>
                                        <!-- <div class="col-8">
                                            <select name="assignedToId" class="form-control" (change)="setOldAssigne()"
                                                [(ngModel)]="model.assignedToId" #assignedToId="ngModel">
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of facilityUsers" [value]="item.id">
                                                    {{item.firstName}} {{item.lastName}}
                                                </option>
                                            </select>
                                        </div> -->
                                        <div class="col-8">
                                            <div ngbDropdown class="convert-to-native-dropdown-container">
                                                <button type="button" class="btn btn-outline-primary heading" id="dropdownBasicAssignee" ngbDropdownToggle>
                                                    <img *ngIf="selectedAssignee" [src]="selectedAssignee.image" alt="">
                                                    {{ selectedAssignee ? '&nbsp;&nbsp;' + selectedAssignee.name : '[Select]'}}
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasicAssignee" class="content">
                                                    <div ngbDropdownItem (click)="onAssigneeChange(null)">
                                                            <span class="word-wrap2">
                                                                [Select]
                                                            </span>
                                                    </div>
                                                    <div ngbDropdownItem *ngFor="let item of workOrderAssignees" (click)="onAssigneeChange(item)">
                                                        <span class="word-wrap2"><span><img [src]="item.image" alt=""> &nbsp; &nbsp; </span>{{item.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span class="kt-font-bold">Add Follower</span>
                                    <div class="kt-divider m-t-5 m-b-25">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row" 
                                        [ngClass]="{ 'validation-error' : f.submitted && followUpAssigneeId.invalid}">
                                        <label class="col-4">Follow up assignee:</label>
                                        <div class="col-8">
                                            <select name="followUpAssigneeId" class="form-control"
                                                [(ngModel)]="model.followUpAssigneeId" #followUpAssigneeId="ngModel" 
                                                (change)="setOldFollower()">
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of facilityUsers" [value]="item.id">
                                                    {{item.firstName}} {{item.lastName}}
                                                </option>
                                            </select> 
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && followUpAssigneeId.invalid">
                                                <span *ngIf="followUpAssigneeId.errors.mustNotMatch">
                                                    Follower can't be same as the assignee.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label class="col-2">Follow up instruction:</label>
                                        <div class="col-10">
                                            <textarea name="followUpInstruction" class="form-control" rows="2"
                                                autocomplete="off" [(ngModel)]="model.followUpInstruction" 
                                                [disabled]="!model.followUpAssigneeId"
                                                (change)="setOldInstruction()">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span class="kt-font-bold">Add Watchers</span>
                                    <div class="kt-divider m-t-5 m-b-25">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label class="col-4">Watchers:</label>
                                        <div class="col-8">
                                            <ng-multiselect-dropdown name="ClientUserWatchers"
                                                [placeholder]="'[Select]'" 
                                                [(ngModel)]="selectedClientWatchers"
                                                [settings]="multiSelectDropdownConfigs"
                                                [data]="facilityWatchers"
                                                (onSelect)="onWatcherSelectOrDeselect()"
                                                (onSelectAll)="onWatcherSelectAll($event)"
                                                (onDeSelect)="onWatcherSelectOrDeselect()"
                                                (onDeSelectAll)="onWatcherDeSelectAll()">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <i class="fa fa-info-circle mt-2 pt-1 fs-18"
                                        ngbTooltip="The preferences you select will be applicable to you. The other watchers assigned will be sent notification based on the default preferences."></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span class="kt-font-bold">Notifications</span>
                                    <div class="kt-divider m-t-5 m-b-25">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isUserCreator">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label class="col-6">
                                            Send read receipt notifications to me:
                                        </label>
                                        <!-- <div class="col-3">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendReadReceiptEmailNotifications"
                                                        name="sendReadReceiptEmailNotifications"
                                                        [(ngModel)]="model.sendReadReceiptEmailNotifications" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendReadReceiptEmailNotifications">
                                                Send email notification
                                            </label>
                                        </div> -->
                                        <!-- <div class="col-3">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendReadReceiptInAppNotifications"
                                                        name="sendReadReceiptInAppNotifications"
                                                        [(ngModel)]="model.sendReadReceiptInAppNotifications" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendReadReceiptInAppNotifications">
                                                Send In app notification
                                            </label>
                                        </div> -->
                                        <div class="col-3">
                                            <div class="float-left">
                                                <label class="fs-md">Disable</label>
                                                <label class="switch switch-sm ml-2 mr-2">
                                                    <input type="checkbox" id="enableReadNotification"
                                                    name="enableReadNotification" [(ngModel)]="model.enableReadNotification" class="primary"/>
                                                    <span class="slider slider-sm round"></span>
                                                </label>
                                            </div>
                                            <label class="float-left" for="enableReadNotification">
                                                Enable
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row" *ngIf="isUserCreator || isUserWatcher">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label class="col-6">
                                            Send edit workorder notifications to me:
                                        </label>
                                        <div class="col-3">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendEmailNotificationsToWatchers"
                                                        name="sendEmailNotificationsToWatchers"
                                                        [(ngModel)]="model.sendEmailNotificationsToWatchers" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendEmailNotificationsToWatchers">
                                                Send email notification
                                            </label>
                                        </div>
                                        <div class="col-3">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendInAppNotificationsToWatchers"
                                                        name="sendInAppNotificationsToWatchers"
                                                        [(ngModel)]="model.sendInAppNotificationsToWatchers" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendInAppNotificationsToWatchers">
                                                Send In app notification
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label [ngClass]="{'col-6' : isUserCreator || isUserWatcher, 'col-2' : !isUserCreator && !isUserWatcher}">
                                            Reminder Interval (days):*
                                        </label>
                                        <div class="col-6">
                                            <div class="col-8">
                                                <div class="form-group row"
                                                    [ngClass]="{ 'validation-error' : f.submitted && workOrderReminderInterval.invalid}">
                                                    <select class="form-control" name="workOrderReminderInterval"
                                                        [(ngModel)]="model.reminderInterval" #workOrderReminderInterval="ngModel" required>
                                                        <option value="">[Select]</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                    </select>
                                                    <div class="validation-error-message" *ngIf="f.submitted && workOrderReminderInterval.invalid">
                                                        Reminder interval is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-15">
                                <div class="col-12">
                                    <span class="kt-font-bold">Add Photos
                                        <button type="button"
                                            class="btn btn-xs btn-outline-brand btn-elevate btn-icon m-l-5"
                                            (click)="workOrderPhoto.click()">
                                            <i class="la la-plus"></i>
                                        </button>
                                    </span>
                                    <div class="kt-divider m-t-10 m-b-0" *ngIf="model.photos.length > 0">
                                        <span></span>
                                    </div>
                                    <ngb-progressbar class="m-t-2" type="info" [value]="uploadedPercent" height="5px"
                                        *ngIf="uploadedPercent > 0 && uploadedPercent < 100">
                                    </ngb-progressbar>
                                    <input type="file" name="file" class="d-none" accept="image/*" #workOrderPhoto
                                        (change)="onPhotoSelect($event)" />
                                </div>
                            </div>
                            <div class="row" *ngIf="model.photos.length > 0">
                                <div class="col-2" *ngFor="let photo of model.photos; let $index = index">
                                    <div class="form-group m-t-15">
                                        <a [href]="photo.fileUrl" target="_blank">
                                            <img [src]="photo.fileUrl" alt="" class="mw-100" />
                                        </a>
                                        <div class="col-12 text-center">
                                            <button type="button"
                                                class="btn btn-xs btn-outline-danger btn-elevate btn-icon m-t-5"
                                                (click)="deletePhoto(photo.fileName, $index)">
                                                <i class="la la-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/work-order/manage',companyId]" class="btn btn-secondary m-r-5"
                                    *ngIf="viewType === '1'">Cancel</a>
                                <a [routerLink]="['/work-order/grid-view',companyId]" class="btn btn-secondary m-r-5"
                                    *ngIf="viewType === '2'">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-work-order-note-add #addWorkOrderNote [workOrderId]="model.id" (reloadNotes)="loadWorkOrder()"
    (updateWorkOrderStatus)="updateWorkOrderStatus()">
</app-work-order-note-add>

<app-work-order-recurrance #workOrderRecurrenceModal (change)="workOrderRecurranceChange($event)">
</app-work-order-recurrance>