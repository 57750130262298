export class UserAddModel {
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public role: string;
    public profileImage: string;
    public timeZone: string;

    public sendInAppNotification: boolean;
    public sendPushNotification: boolean;
    public sendEmailNotification: boolean;
    public enableEmailNotification: boolean;
    public enableInAppNotification: boolean;
    public enablePushNotification: boolean;

    constructor() {
        this.role = '';
        this.sendInAppNotification = true;
        this.sendPushNotification = true;
        this.sendEmailNotification = true;
        this.enableEmailNotification = true;
        this.enableInAppNotification = true;
        this.enablePushNotification = true;
    }
}
