export class StoredgeConfigurationModel {
    public internalCompanyId: number;
    public storedgeCompanyId: string;

    public storedgCompanyConfigured: boolean;

    constructor() {
        this.storedgCompanyConfigured = false;
    }
}
