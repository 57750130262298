<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-vendor-note [companyId]="companyId" [vendorId]="vendorId">
            </app-breadcrumb-vendor-note>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                All Notes <span *ngIf="notes.length === 1">(1 Note)</span> <span
                                    *ngIf="notes.length > 1">({{notes.length}} Notes)</span>
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-primary"
                                (click)="openVendorNoteModal(vendorNoteModal)" *ngIf="showActionColumn">
                                Add
                            </button>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of notes; let $index=index">
                                <div class="row">
                                    <div class="col-10">
                                        <p class="word-wrap">{{item.note}}</p>
                                        <p class="badge badge-light">
                                            <span>{{item.createdByName}}</span>
                                            ({{item.createdOn}})</p>
                                    </div>
                                    <div class="col-2 text-right" *ngIf="showActionColumn">
                                        <button type="button" class="btn btn-outline-primary btn-icon"
                                            (click)="openVendorNoteEditModal(vendorNoteEditModal,item)">
                                            <i class="fa fa-edit">
                                            </i></button>
                                    </div>

                                    <div class="row" *ngIf="(notes.length - 1) > $index">
                                        <div class="col-12">
                                            <div class="kt-divider m-t-15 m-b-15">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p *ngIf="notes.length === 0">No note found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #vendorNoteModal let-modal>
        <form name="form" #noteModalF="ngForm" (ngSubmit)="noteModalF.form.valid && addNote(noteModalF)" novalidate
            *blockUI="'container-blockui-note'">
            <div class="modal-header">
                <h5 class="modal-title">Add Note</h5>
                <button type="button" class="close" aria-label="Close" (click)="closeVendorNoteModal(noteModalF)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{ 'validation-error' : noteModalF.submitted && vendorNote.invalid}">
                        <label class="control-label">Note *</label>
                        <textarea name="vendorNote" class="form-control" maxlength="60" rows="2" autocomplete="Off"
                            [(ngModel)]="note" #vendorNote="ngModel" appAlphaNumericsLevelFour required>
                        </textarea>
                        <div class="validation-error-message" *ngIf="noteModalF.submitted && vendorNote.invalid">
                            Note is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-default m-r-5"
                        (click)="closeVendorNoteModal(noteModalF)">Close</button>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>
    </ng-template>



    <ng-template #vendorNoteEditModal let-modal>
        <form name="form" #noteModalF="ngForm" (ngSubmit)="noteModalF.form.valid && editNote(noteModalF)" novalidate
            *blockUI="'container-blockui-note'">
            <div class="modal-header">
                <h5 class="modal-title">Edit Note</h5>
                <button type="button" class="close" aria-label="Close" (click)="closeVendorNoteEditModal(noteModalF)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{ 'validation-error' : noteModalF.submitted && vendorNote.invalid}">
                        <label class="control-label">Note *</label>
                        <textarea name="vendorNote" class="form-control" maxlength="60" rows="2" autocomplete="Off"
                            [(ngModel)]="note" #vendorNote="ngModel" appAlphaNumericsLevelFour required>
                        </textarea>
                        <div class="validation-error-message" *ngIf="noteModalF.submitted && vendorNote.invalid">
                            Note is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-default m-r-5"
                        (click)="closeVendorNoteEditModal(noteModalF)">Close</button>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>
    </ng-template>