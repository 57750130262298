export class AssignedUserModel {
    public id: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public fullName: string;
    public name: string;
    public sendInAppNotification: boolean;
    public sendEmailNotification: boolean;
    public lowPriorityWorkOrdersReminderInterval: number;
    public mediumPriorityWorkOrdersReminderInterval: number;
    public highPriorityWorkOrdersReminderInterval: number;
}

export class AssignedUserGroupModel {
    public id: string;
    public createdOn: Date;
    public name: string;
    public status: string;
    public members: AssignedUserModel[];
    public lowPriorityWorkOrdersReminderInterval: number;
    public mediumPriorityWorkOrdersReminderInterval: number;
    public highPriorityWorkOrdersReminderInterval: number;
}

export class AssigneeListModel {
    public id: string;
    public name: string;
    public isUserGroup: boolean;
}