<ng-template #facilityDeleteConfirmationModal let-modal>
    <div *blockUI="'container-blockui-delete-popup'">
        <div class="modal-header">
            <h5 class="modal-title">Confirm Delete</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeFacilityDeleteConfirmationModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body word-wrap">
            <div class="col-md-12">
                <div class="form-group">
                    {{deleteConfirmationMessage}}
                </div>
                <div class="form-group" *ngIf="isModelLoaded && dualConfirmationRequired">
                    <input type="text" class="form-control" [(ngModel)]="deleteChallengeMessage"
                        [ngClass]="{'text-uppercase' : deleteChallengeMessage}" placeholder="Type DELETE to confirm"
                        appAlphaNumericsOnly maxlength="6" (keyup.enter)="delete()" (keyup)="deleteChallengeMessageChanged()" />
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="isModelLoaded">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="closeFacilityDeleteConfirmationModal()">Cancel</button>
                <button type="button" class="btn btn-primary"
                    [disabled]="(deleteChallengeMessage !== 'DELETE' && deleteChallengeMessage !== 'delete')"
                    (click)="delete()">Delete</button>
            </div>
        </div>
    </div>
</ng-template>