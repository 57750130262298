import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ChangePasswordModel } from '../../../models';
import { AccountService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-change-password',
    templateUrl: './change.password.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})

export class ChangePasswordComponent {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @HostBinding('class.full_width') full_width = true;
    roleMatrix: RoleMatrix;
    is10FedUsers = false;
    formSubmitted = false;
    model: ChangePasswordModel = new ChangePasswordModel();
    backUrl: string;

    constructor(private router: Router,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private accountService: AccountService) {
        this.roleMatrix = new RoleMatrix();
        this.is10FedUsers = this.appUtils.is10FedUser(this.roleMatrix.role);
        this.backUrl = this.is10FedUsers ? '/user/profile' : '/company-user/profile-detail';
    }

    submit() {
        this.blockUI.start();
        this.accountService.changePassword(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();

                    setTimeout(() => {
                        this.router.navigate([this.backUrl]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Your password has been changed successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
