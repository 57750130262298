<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
      <div class="kt-subheader__main">
          <app-breadcrumb-wo-bulk-changes [companyId]="companyId" [viewType]="viewType">
          </app-breadcrumb-wo-bulk-changes>
      </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="col-md-12 p-0">
          <div class="kt-portlet kt-portlet--mobile">
              <app-select-bulk-work-orders *ngIf="isOnStep1" [companyId]="companyId" [viewType]="viewType"
                [selectedWorkOrderIds]="selectedWorkOrderIds" (performBulkOperation)="showBulkOperationScreen($event)">
              </app-select-bulk-work-orders>
              <app-wo-bulk-operations *ngIf="!isOnStep1" [companyId]="companyId" [viewType]="viewType"
                [selectedWorkOrderIds]="selectedWorkOrderIds" (showWorkOrdersList)="showWorkOrdersList()">
              </app-wo-bulk-operations>
          </div>
      </div>
  </div>
</div>
