<div class="kt-subheader__breadcrumbs" *ngIf="!isClientUser">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/manage']" class="kt-subheader__breadcrumbs-link">
        Companies </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>Edit</span>
</div>

<div class="kt-subheader__breadcrumbs" *ngIf="isClientUser">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <a [routerLink]="['/company/detail', roleMatrix.companyId]" class="kt-subheader__breadcrumbs-link">
        Company </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>Edit</span>
</div>