<ng-template #imageViewerModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeImageViewerModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-md-12 text-center">
            <img [src]="imageUrl" class="mw-100" />
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-outline-dark" (click)="closeImageViewerModal()">Cancel</button>
            </div>
        </div>
    </div>
</ng-template>