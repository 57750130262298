import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-vendor-manage',
    templateUrl: './breadcrumb.vendor.manage.component.html'
})

export class BreadcrumbVendorManageComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
