<div
    class="kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--skin-solid kt-portlet--height-fluid">
    <div class="kt-portlet__head kt-portlet__head--noborder kt-portlet__space-x">
        <div class="kt-portlet__head-label"></div>
        <div class="kt-portlet__head-toolbar"></div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-widget17">
            <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
                style="background-color: #fd397a">
                <div class="kt-widget17__chart" style="height:120px;">
                    <div class="chartjs-size-monitor">
                        <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                        </div>
                    </div>
                    <canvas id="kt_chart_activities" width="696" height="324" class="chartjs-render-monitor"
                        style="display: block; height: 116px; width: 464px;"></canvas>
                </div>
            </div>
            <div class="kt-widget17__stats" *blockUI="'container-blockui-tiles-company'">
                <div class="kt-widget17__items">
                    <app-dashboard-company-admin-tile [companyId]="companyId" (tileLoaded)="adminTileLoaded()">
                    </app-dashboard-company-admin-tile>
                    <app-dashboard-company-user-tile [companyId]="companyId" (tileLoaded)="personalTileLoaded()">
                    </app-dashboard-company-user-tile>
                </div>
                <div class="kt-widget17__items">
                    <app-dashboard-company-vendor-tile #vendorTile [companyId]="companyId" (tileLoaded)="facitlityTileLoaded()">
                    </app-dashboard-company-vendor-tile>
                    <app-dashboard-company-facility-tile #facilityTile [companyId]="companyId" (tileLoaded)="vendorTileLoaded()">
                    </app-dashboard-company-facility-tile>
                </div>
            </div>
        </div>
    </div>
</div>