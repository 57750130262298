import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleMatrix } from 'src/helpers';

@Component({
  selector: 'app-breadcrumb-quickbooks-manage',
  templateUrl: './breadcrumb.quickbooks.manage.component.html'  
})
export class BreadcrumbQuickBooksManageComponent implements OnInit {
  
  companyId:number;
  roleMatrix: RoleMatrix;
  
constructor(private route: ActivatedRoute,){
  this.roleMatrix = new RoleMatrix();
  this.route.params.subscribe((params) => {
      this.companyId = params['companyId'];
     
      
      
  });  
}
  ngOnInit() {
  }

}
