export const Roles = {
    get SuperAdmin() {
        return 'SUPERADMIN';
    },
    get Admin() {
        return 'ADMIN';
    },
    get User() {
        return 'USER';
    },
    get Personnel() {
        return 'PERSONNEL';
    },
    get OrgSuperAdmin() {
        return 'ORGSUPERADMIN';
    },
    get OrgAdmin() {
        return 'ORGADMIN';
    },
    get OrgUser() {
        return 'ORGUSER';
    }
};
