<div class="kt-widget24 widget-tile m-b-15" *ngIf="isModelLoaded">
    <div class="kt-widget24__details">
        <div class="kt-widget24__info">
            <h4 class="kt-widget24__title text-center fs-20">
                <a href="javascript:;">Unit & Amenity Type</a>
            </h4>
        </div>
    </div>
    <div class="separator m-t-25 m-b-15">
    </div>
    <div class="kt-widget24__action">
        <span class="kt-widget24__change">
            Unit Type <span class="m-l-10">0</span>
        </span>
        <span class="kt-widget24__number">
            Amenity Type <span class="m-l-10">0</span>
        </span>
    </div>
</div>