import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils, QuickBooksAccountTypesEnum } from '../../../helpers';
import { QuickBooksAccountModel, SelectListItemModel } from 'src/models';

@Component({
    selector: 'app-quickbooks-preview-accounts',
    templateUrl: './quickbooks.preview.accounts.component.html'
})

export class QuickBooksPreviewAccountsComponent implements OnInit{
    @BlockUI('container-blockui-preview') blockUI: NgBlockUI;
    
    accountSelectList: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    
    model:Array<QuickBooksAccountModel>=new Array<QuickBooksAccountModel>();

    internalCompanyId:number;
    facilityId:string;

    
    selectedRevenue:string;
    selectedRepair:string;
    selectedGoogle:string;
    selectedSpare:string;
    selectedMarketing:string;
    

    constructor(private route: ActivatedRoute,
                private kpiReportService:KpiReportService,
                private toastr: ToastrService,
                private appUtils: AppUtils,
                private router:Router){
        this.route.params.subscribe((params) => {
            this.internalCompanyId = params['companyId'];
            this.facilityId=params['facilityId'];
            this.selectedRevenue = '';
            this.selectedRepair = '';
            this.selectedGoogle = '';
            this.selectedSpare = '';
            this.selectedMarketing = '';
        });  
    }

    ngOnInit(){
        this.getQuickBookAccountList();
        this.getQuickBooksAccountData();
       
    }

    getQuickBookAccountList(){
        this.blockUI.start();
        this.kpiReportService.getQuickBooksAccountSelectList(this.internalCompanyId,this.facilityId).subscribe(
            (data: any) => {
                this.blockUI.stop();
                this.accountSelectList=data;
                
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
    
    getQuickBooksAccountData(){
        this.blockUI.start();
        this.kpiReportService.getQuickBooksAccountData(this.internalCompanyId,this.facilityId).subscribe(
            (quickbooksData:Array<QuickBooksAccountModel>)=>{
                 this.blockUI.stop();
                 if(quickbooksData.length >0){
                    this.selectedRevenue = quickbooksData.find(x=>x.accountType==QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalRevenue).accountId.toString();
                    this.selectedRepair = quickbooksData.find(x=>x.accountType==QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalRepairAndMaintenanceCost).accountId.toString();
                    this.selectedGoogle = quickbooksData.find(x=>x.accountType==QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalGoogleAdCost).accountId.toString();
                    this.selectedSpare = quickbooksData.find(x=>x.accountType==QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalSparefootAdCost).accountId.toString();
                    this.selectedMarketing = quickbooksData.find(x=>x.accountType==QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalMarketingCost).accountId.toString();
                }
                 
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
            
    }
    submit(){
        this.model = [];
        this.model.push({
            accountId:this.selectedRevenue,
            accountType:QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalRevenue,
            facilityId:this.facilityId,
            internalCompanyId:this.internalCompanyId});

        this.model.push({
            accountId:this.selectedRepair,
            accountType:QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalRepairAndMaintenanceCost,
            facilityId:this.facilityId,
            internalCompanyId:this.internalCompanyId});

        this.model.push({
            accountId:this.selectedGoogle,
            accountType:QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalGoogleAdCost,
            facilityId:this.facilityId,
            internalCompanyId:this.internalCompanyId});   
            
        this.model.push({
            accountId:this.selectedSpare,
            accountType:QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalSparefootAdCost,
            facilityId:this.facilityId,
            internalCompanyId:this.internalCompanyId});

        this.model.push({
            accountId:this.selectedMarketing,
            accountType:QuickBooksAccountTypesEnum.ChartOfAccountCodeForTotalMarketingCost,
            facilityId:this.facilityId,
            internalCompanyId:this.internalCompanyId});    

        this.blockUI.start();
        this.kpiReportService.addQuickBooksAccountList(this.model)
                .subscribe(
                    () => {
                        this.blockUI.stop();
                        this.toastr.success("Account Added Successfully...!!!");
                        this.router.navigate(['/storedge/intergration',this.internalCompanyId]);

                    },
                   
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    }
        );
    }
}