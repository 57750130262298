<button class="kt-aside-close " id="kt_aside_close_btn">
    <em class="la la-close"></em>
</button>
<div class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
    <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div id="kt_aside_menu" class="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1">
            <ul class="kt-menu__nav" *ngIf="roleMatrix.is10FedUser">
                <li class="kt-menu__item" [ngClass]="{ 'kt-menu__item--active' : currentUrl === '/'}"
                    aria-haspopup="true">
                    <a [routerLink]="['/']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-squares-4"></em>
                        </span>
                        <span class="kt-menu__link-text">Dashboard</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : (currentUrl.indexOf('user/') !== -1 && currentUrl.indexOf('profile') === -1)}"
                    aria-haspopup="true">
                    <a [routerLink]="['/user/manage']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-users"></em>
                        </span>
                        <span class="kt-menu__link-text">Users</span></a>
                </li>
                <li class="kt-menu__item" [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('company/') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/company/manage']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-buildings"></em>
                        </span>
                        <span class="kt-menu__link-text">Companies</span></a>
                </li>
               

                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('/categories') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/work-order/categories']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-settings"></em>
                        </span>
                        <span class="kt-menu__link-text">Settings</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : (currentUrl.indexOf('profile') !== -1 || currentUrl.indexOf('change-password') !== -1) }"
                    aria-haspopup="true">
                    <a [routerLink]="['/user/profile']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-user"></em>
                        </span>
                        <span class="kt-menu__link-text">My Profile</span></a>
                </li>
                <li class="kt-menu__item" aria-haspopup="true">
                    <a [routerLink]="['/account/logout']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-logout"></em>
                        </span>
                        <span class="kt-menu__link-text">Sign Out</span></a>
                </li>
            </ul>
            <ul class="kt-menu__nav" *ngIf="!roleMatrix.is10FedUser">
                <li class="kt-menu__item" [ngClass]="{ 'kt-menu__item--active' : currentUrl === '/'}"
                    aria-haspopup="true">
                    <a [routerLink]="['/']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-squares-4"></em>
                        </span>
                        <span class="kt-menu__link-text">Dashboard</span></a>
                </li>
                <li class="kt-menu__item" routerLinkActive="kt-menu__item--active" aria-haspopup="true"
                    *ngIf="roleMatrix.role === Roles.OrgSuperAdmin || roleMatrix.role === Roles.OrgAdmin">
                    <a [routerLink]="['/kpi-dashboard']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-statistics"></em>
                        </span>
                        <span class="kt-menu__link-text">KPI Dashboard</span></a>
                </li>
                <li class="kt-menu__item" [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('company/') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/company/detail', roleMatrix.companyId]" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-buildings"></em>
                        </span>
                        <span class="kt-menu__link-text">Company</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : (currentUrl.indexOf('user/') !== -1 && currentUrl.indexOf('profile') === -1)}"
                    aria-haspopup="true">
                    <a [routerLink]="['/company-user/manage']" [queryParams]="{companyId: roleMatrix.companyId}"
                        class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-users"></em>
                        </span>
                        <span class="kt-menu__link-text">Users</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('facility/') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/facility/manage',roleMatrix.companyId]" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-tabs"></em>
                        </span>
                        <span class="kt-menu__link-text">Facilities</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('work-order/') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/work-order/grid-view',roleMatrix.companyId]" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-web"></em>
                        </span>
                        <span class="kt-menu__link-text">Work Orders</span></a>
                </li>
                <li class="kt-menu__item" [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('vendor/') !== -1}"
                    aria-haspopup="true">
                    <a [routerLink]="['/vendor/manage',roleMatrix.companyId]" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-cart"></em>
                        </span>
                        <span class="kt-menu__link-text">Vendors</span></a>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : (currentUrl.indexOf('/kiosk') !== -1 || currentUrl.indexOf('/signage') !== -1 || currentUrl.indexOf('/movein') !== -1) }"
                    aria-haspopup="true" *ngIf="roleMatrix.canViewKiosk">
                    <a [routerLink]="['/kiosk/manage',roleMatrix.companyId]" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-presentation-1"></em>
                        </span>
                        <span class="kt-menu__link-text">Manage Kiosk</span></a>
                </li>
                
                <li class="kt-menu__item" aria-haspopup="true">
                    <a class="kt-menu__link " (click)="isKPICollapsed = !isKPICollapsed"
                    [attr.aria-expanded]="!isKPICollapsed" aria-controls="side-nave-setting-collapse">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-file"></em>
                        </span>
                        <span class="kt-menu__link-text">KPI Reports</span></a>

                        <ul class="kt-menu__nav p-t-0 p-b-0" id="side-nave-setting-collapse" [ngbCollapse]="isKPICollapsed">
                            <li class="kt-menu__item" routerLinkActive="kt-menu__item--active">
                                <a [routerLink]="['/kpi-report/datasource', roleMatrix.companyId]" class="kt-menu__link ">
                                    <span class="kt-menu__link-icon">
                                        <em class="la la-th-large"></em>
                                    </span>
                                    <span class="kt-menu__link-text">Data Sources</span></a>
                            </li>

                           <li class="kt-menu__item"
                           routerLinkActive="kt-menu__item--active">
                                <a [routerLink]="['/kpi-report/report',roleMatrix.companyId]" class="kt-menu__link ">
                                    <span class="kt-menu__link-icon">
                                        <em class="la la-edit"></em>
                                    </span>
                                    <span class="kt-menu__link-text">Reports</span></a>
                           </li>
                            
                        </ul>
                </li>
              
                <li class="kt-menu__item" aria-haspopup="true">
                    <a class="kt-menu__link " (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="side-nave-setting-collapse">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-settings"></em>
                        </span>
                        <span class="kt-menu__link-text">Settings</span></a>
                    <ul class="kt-menu__nav p-t-0 p-b-0" id="side-nave-setting-collapse" [ngbCollapse]="isCollapsed">
                        <li class="kt-menu__item" routerLinkActive="kt-menu__item--active">
                            <a [routerLink]="['/work-order-comp-categories', roleMatrix.companyId]" class="kt-menu__link ">
                                <span class="kt-menu__link-icon">
                                    <em class="kt-menu__link-icon flaticon-buildings"></em>
                                </span>
                                <span class="kt-menu__link-text">Categories</span></a>
                        </li>
                        <li class="kt-menu__item"
                            [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('/storedge') !== -1}"
                            *ngIf="roleMatrix.canConfigureStoredgeCompany">
                            <a [routerLink]="['/storedge/intergration',roleMatrix.companyId]" class="kt-menu__link ">
                                <span class="kt-menu__link-icon">
                                    <em class="kt-menu__link-icon flaticon-settings"></em>
                                </span>
                                <span class="kt-menu__link-text">Configurations</span></a>
                        </li>
                        <li class="kt-menu__item"
                            [ngClass]="{ 'kt-menu__item--active' : currentUrl.indexOf('/user-group') !== -1}">
                            <a [routerLink]="['/company', roleMatrix.companyId, 'user-group']" class="kt-menu__link ">
                                <span class="kt-menu__link-icon">
                                    <em class="kt-menu__link-icon flaticon-squares-4"></em>
                                </span>
                                <span class="kt-menu__link-text">User Group</span></a>
                        </li>
                        <li class="kt-menu__item" routerLinkActive="kt-menu__item--active"
                            *ngIf="roleMatrix.canAccessWorkOrderConfiguration">
                            <a [routerLink]="['/config', roleMatrix.companyId]" class="kt-menu__link ">
                                <span class="kt-menu__link-icon">
                                    <em class="kt-menu__link-icon flaticon-settings"></em>
                                </span>
                                <span class="kt-menu__link-text">Work Order Configurations</span></a>
                        </li>
                    </ul>
                </li>
                <li class="kt-menu__item"
                    [ngClass]="{ 'kt-menu__item--active' : (currentUrl.indexOf('profile') !== -1 || currentUrl.indexOf('change-password') !== -1)}"
                    aria-haspopup="true">
                    <a [routerLink]="['/company-user/profile-detail']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-user"></em>
                        </span>
                        <span class="kt-menu__link-text">My Profile</span></a>
                </li>
                <li class="kt-menu__item" aria-haspopup="true">
                    <a [routerLink]="['/account/logout']" class="kt-menu__link ">
                        <span class="kt-menu__link-icon">
                            <em class="kt-menu__link-icon flaticon-logout"></em>
                        </span>
                        <span class="kt-menu__link-text">Sign Out</span></a>
                </li>
            </ul>
        </div>
    </div>
</div>