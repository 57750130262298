import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService } from '../../../services';
import { UserAddModel, UserImageUploadModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { TimeZone } from 'src/models/timezone.model';

@Component({
    selector: 'app-user-add',
    templateUrl: './user.add.component.html'
})

export class UserAddComponent implements OnInit {
    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    roleMatrix: RoleMatrix;
    model: UserAddModel = new UserAddModel();
    imageUploadModel: UserImageUploadModel = new UserImageUploadModel();
    timeZones: TimeZone[];

    constructor(private router: Router,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private timeZoneService: TimeZoneService) {
        this.roleMatrix = new RoleMatrix();
        if (!this.roleMatrix.canAddAdmin) {
            this.router.navigate(['/error/403']);
        }
        this.imageUploadModel.canEditProfileImage = true;
    }

    ngOnInit() {
        this.loadTimeZones();
    }

    submit() {
        if(!this.model.enableEmailNotification && !this.model.enablePushNotification) {
            this.toastr.error("Atleast one medium to receive notification should be selected.");
            return;
        }

        this.model.profileImage = this.imageUploadModel.fileName;
        this.blockUI.start();
        this.userService.add(this.model)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/user/detail', data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('User has been added successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    onEmailNotificationDisabled() {
        if(!this.model.enableEmailNotification) {
            this.model.sendEmailNotification = false;
        }
    }

    onWatchersEmailNotificationEnabled() {
        if(this.model.sendEmailNotification){
            this.model.enableEmailNotification = true;
        }
    }

    onPushNotificationDisabled() {
        if(!this.model.enablePushNotification) {
            this.model.sendPushNotification = false;
        }
    }

    onWatchersPushNotificationEnabled() {
        if(this.model.sendPushNotification){
            this.model.enablePushNotification = true;
        }
    }
}
