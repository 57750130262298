import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-kiosk-detail',
    templateUrl: './breadcrumb.kiosk.detail.component.html'
})

export class BreadcrumbKioskDetailComponent {
    @Input() companyId: number;
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
