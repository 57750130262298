import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { WorkOrderRecurrenceModel, SelectListItemModel, MultiselectItem } from 'src/models';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-work-order-recurrance',
  templateUrl: './work.order.recurrance.component.html'
})
export class WorkOrderRecurranceComponent {
  @BlockUI('container-blockui-recurrance-popup') blockUI: NgBlockUI;
  @ViewChild('workOrderRecurrenceModal', { static: true }) workOrderRecurrenceModal: any;
  @Output() change = new EventEmitter<WorkOrderRecurrenceModel>();
  modalReference: NgbModalRef;
  model: WorkOrderRecurrenceModel;
  multiSelectDropdownConfigs = {};
  repeatEveryNWeekSelectList: Array<SelectListItemModel>;
  repeatEveryMonthOnSelectList: Array<SelectListItemModel>;
  repeatEveryNMonthSelectList: Array<SelectListItemModel>;

  selectWeekDayList: Array<any>;
  anyRepeatWeekDaySelected: boolean = false;
  anyRepeatMonthSelected: boolean = false;
  // selectedRepeatEveryMonthOn: Array<string> = new Array<string>();
  selectedRepeatEveryMonthOn:any=[];
  repeatEveryNWeek: string = '';
  repeatEveryNMonth: number = 1;
  repeatEveryNWeekRequired: boolean = false;

  minTerminateOnDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };

  constructor(private modalService: NgbModal) {
    this.repeatEveryNWeekSelectList = Array.apply(null, Array(50)).map(function (_, i) {
      let item = new SelectListItemModel();
      item.id = i + 1;
      item.value = i + 1;
      return item;
    });

    this.repeatEveryMonthOnSelectList = Array.apply(null, Array(31)).map(function (_, i) {
      let item = new SelectListItemModel();
      item.id = (i + 1).toString();
      item.value = (i + 1).toString();
      return item;
    });

    this.repeatEveryNMonthSelectList = Array.apply(null, Array(12)).map(function (_, i) {
      let item = new SelectListItemModel();
      item.id = (i + 1).toString();
      item.value = (i + 1).toString();
      return item;
    });

    this.selectWeekDayList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((element, i) => {
      return {
        "field": 'is' + element,
        "display": element.charAt(0)
      };
    });
    
    this.multiSelectDropdownConfigs = {
      singleSelection: false,
      idField: 'id',
      textField: 'id',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  openModal(model: WorkOrderRecurrenceModel) {
    this.model = new WorkOrderRecurrenceModel();
    if (model) {
      this.model = Object.assign({}, model)
    }

    // If recurrence is processed, exit write away
    if (this.model.isProcessed) {
      this.close();
    }

    this.setViewValues();
    this.modalReference = this.modalService.open(this.workOrderRecurrenceModal, {
      size: 'lg'
    });
  }

  submit(f: NgForm) {
    //Custom Validation
    if (this.model.frequency == 2) {
      this.repeatEveryNWeekRequired = this.repeatEveryNWeek === '';
      this.anyRepeatWeekDaySelected = this.selectWeekDayList.some((elem) => {
        return this.model[elem["field"]] === true;
      });
      if (this.repeatEveryNWeekRequired || this.anyRepeatWeekDaySelected == false) {
        return;
      }
    }

    //Custom Validation for monthly        
    if (this.model.frequency == 3) {
      this.anyRepeatMonthSelected = this.selectedRepeatEveryMonthOn != null && this.selectedRepeatEveryMonthOn.length > 0;
      if (this.anyRepeatMonthSelected == false) {
        return;
      }
    }

    if (f.form.invalid) {
      return;
    }   

    if (this.selectedRepeatEveryMonthOn != null) {
      //this.model.repeatEveryMonthOn = this.selectedRepeatEveryMonthOn.map((x) => Number(x));
      this.model.repeatEveryMonthOn = (this.selectedRepeatEveryMonthOn as Array<MultiselectItem>).map((x) => Number(x.id));

    }
    this.model.repeatEveryNMonth = this.model.frequency == 3 ? +this.repeatEveryNMonth : null;
    this.model.repeatEveryNWeek = this.repeatEveryNWeek == '' ? null : Number(this.repeatEveryNWeek);
    this.close();
    setTimeout(() => {
      this.change.emit(this.model);
    });
  }

  close() {
    this.modalReference.close();
  }

  onFrequencyChange() {
    if (this.model.frequency == 2) {
      //Weekly
      this.model.repeatEveryMonthOn = null;
    } else if (this.model.frequency == 3) {
      //Use has selected monthly option hence clean up other properties
      this.selectWeekDayList.forEach((elem) => {
        this.model[elem["field"]] = false;
      });
      this.model.repeatEveryNWeek = null;
    }

    this.model.terminateOn = null;
    this.setViewValues();
  }

  setViewValues() {

    this.selectedRepeatEveryMonthOn.length = 0;
    if (this.model.repeatEveryMonthOn != null && Array.isArray(this.model.repeatEveryMonthOn)) {
      this.repeatEveryMonthOnSelectList.forEach(item => {
        if (this.model.repeatEveryMonthOn.find(m => m.toString() == item.id)) {
          this.selectedRepeatEveryMonthOn.push(item.id);
        }
      });
    }

    this.repeatEveryNMonth = this.model.repeatEveryNMonth || 1;
    this.repeatEveryNWeek = this.model.repeatEveryNWeek == null ? '' : this.model.repeatEveryNWeek.toString();
  }
}

