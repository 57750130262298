import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from '../../../models';

@Component({
    selector: 'app-activity-log-vendor-activities',
    templateUrl: './activity.log.vendor.activities.component.html'
})

export class ActivityLogVendorActivitiesComponent implements OnInit {
    @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

    ngOnInit() {
        this.model.entityLink = '/vendor/detail/' + this.model.companyId + '/' + this.model.entityId;
    }
}
