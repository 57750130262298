<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
            <div class="col-12" *ngIf='model.actionType === 1'>
                <p class="kt-widget3__text">
                    New vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    was created
                </p>
            </div>
            <div class="col-12 m-l-2" *ngIf='model.actionType === 2'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Name'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    has been changed from {{model.oldValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'VendorType'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    type has been changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Address'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    location changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Phone'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    contact number changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Email'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    <span *ngIf="model.oldValue && model.newValue">
                        email changed from {{model.oldValue}} to {{model.newValue}}
                    </span>
                    <span *ngIf="!model.oldValue && model.newValue">
                        email changed to {{model.newValue}}
                    </span>
                    <span *ngIf="model.oldValue && !model.newValue">
                        email changed from {{model.oldValue}}
                    </span>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'BankAccount'">
                    Vendor <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    account number changed from
                    {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === 3'>
                <p class="kt-widget3__text">
                    {{model.entityValue}} has been deleted by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
        </div>
    </div>
</div>