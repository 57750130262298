import { Component, Input } from '@angular/core';
import { RoleMatrix } from 'src/helpers';

@Component({
    selector: 'app-breadcrumb-user-group-edit',
    templateUrl: './breadcrumb.user.group.edit.component.html'
})

export class BreadcrumbUserGroupEditComponent {
    @Input() companyId: number;
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
