<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
           
            <div class="col-12 m-l-2" *ngIf='model.actionType === 2'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Name'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                     Name has been changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Address'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    Address changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'ZipCode'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    ZipCode changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Website'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    Website changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'ContactNumber'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    contact number changed from {{model.oldValue}} to {{model.newValue}} by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Email'">
                    Company <a [routerLink]="[model.entityLink]"> {{model.entityValue}}</a>
                    <span *ngIf="model.oldValue && model.newValue">
                        email changed from {{model.oldValue}} to {{model.newValue}}
                    </span>
                    <span *ngIf="!model.oldValue && model.newValue">
                        email changed to {{model.newValue}}
                    </span>
                    <span *ngIf="model.oldValue && !model.newValue">
                        email changed from {{model.oldValue}}
                    </span>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                
            </div>
        </div>
    </div>
</div>
