import { Component, ViewChild, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { FacilityService, VendorService } from '../../../services';
import { FacilityDetailModel, VendorDetailModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';

import { UnitManageComponent } from '../../unit';
import { FacilityNoteAddModel } from 'src/models/facility/facility.note.add.model';

@Component({
    selector: 'app-facility-detail',
    templateUrl: './facility.detail.component.html'
})

export class FacilityDetailComponent implements OnInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @ViewChild('appUnitManage', { static: true }) unitManageComponent: UnitManageComponent;
    @ViewChild('facilityChallengeDeleteModal', { static: true }) facilityChallengeDeleteModal: any;
    @ViewChild('facilityAddNoteModal', { static: true }) facilityAddNoteModal: any;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model: FacilityDetailModel = new FacilityDetailModel();
    routerSubscription: Subscription;
    isModelLoaded = false;
    showActionColumn = false;
    vendors: Array<VendorDetailModel>;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private facilityService: FacilityService,
        private vendorService: VendorService) {

        this.roleMatrix = new RoleMatrix();
        this.vendors = new Array<VendorDetailModel>();
        this.updateShowActionColumn();

        this.route.params.subscribe((params) => {
            this.model.companyId = params['companyId'];
            this.model.id = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }



        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.unitManageComponent.handleOnNavigationEnd();
                }, 1000);
            }
        });
    }

    ngOnInit(): void {
        this.loadFacilityDetails();
        this.loadVendorDetails();
    }

    loadFacilityDetails() {
        this.blockUI.start();
        this.facilityService.getDetail(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                    if (this.model.status === 3) {
                        this.roleMatrix.configureAllowActionsForFacility();
                        this.updateShowActionColumn();
                        this.unitManageComponent.updateShowActionColumn(this.model.status);
                    }
                    if(this.model.facilityNoteDetails != null) {
                        this.model.facilityNoteDetails.createdOn = this.appUtils.getFormattedDateTimeFull(this.model.facilityNoteDetails.createdOn);
                    }
                    this.isModelLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadVendorDetails(){
        this.blockUI.start();
        this.vendorService.getVendorsByFacilityId(this.model.id)
            .subscribe(
                (data: VendorDetailModel[]) => {
                    this.vendors = data;
                    this.blockUI.stop();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    toggleStatus(): void {
        this.blockUI.start();
        this.facilityService.toggleStatus(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.toastr.success(`Facility has been ${(this.model.status === 2 ? 'deactivated' : 'activated')} successfully.`);
                this.model.status = this.model.status === 3 ? 2 : 3;
                if (this.model.status === 3) {

                    this.roleMatrix.canAddUnit = false;
                } else {
                    this.roleMatrix.canAddUnit = true;
                }
                this.unitManageComponent.refreshUnitsAfterFacilityToggled(this.model.status);
                if (this.model.status === 3) {
                    this.roleMatrix.configureAllowActionsForFacility();
                    this.updateShowActionColumn();
                    this.roleMatrix.canAddUnit = false;
                } else {
                    this.roleMatrix = new RoleMatrix();
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    challengeDelete(): void {
        this.facilityChallengeDeleteModal.openFacilityDeleteConfirmationModal(this.model.id, this.model.name);
    }

    delete(): void {
        this.blockUI.start();
        this.facilityService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.router.navigate(['/facility/manage', this.model.companyId]);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Facility has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    updateShowActionColumn() {
        this.showActionColumn
            = this.roleMatrix.canEditFacility || this.roleMatrix.canChangeStatusOfFacility || this.roleMatrix.canDeleteFacility;
    }

    followFacility() {
        this.blockUI.start();
        this.model.isFollowing = !this.model.isFollowing;
        this.facilityService.followCompanyUser(this.model.id, this.model.isFollowing)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success(this.model.isFollowing
                        ? 'You have started following the facility.'
                        : 'You have unfollowed the facility.');
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    editNote() {
        this.facilityAddNoteModal.openModal(this.model.facilityNoteDetails);
    }

    saveNote(note: string) {
        let facilityNoteAddModel = new FacilityNoteAddModel();
        facilityNoteAddModel.facilityId = this.model.id;
        facilityNoteAddModel.note = note;
        this.blockUI.start();
        this.facilityService.addNote(facilityNoteAddModel).subscribe(() => {
            let notificationMsg = this.model.facilityNoteDetails !=null ? "Note has been updated successfully." : "Note has been added successfully.";  
            this.toastr.success(notificationMsg);
            this.loadFacilityDetails();
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
