import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-facility-detail',
    templateUrl: './breadcrumb.facility.detail.component.html'
})

export class BreadcrumbFacilityDetailComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
