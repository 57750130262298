export enum EntityTypesEnum {
    TenFedUser = 1,
    ClientUser = 2,
    Company = 3,
    Facility = 4,
    Unit = 5,
    WorkOrder = 6,
    Vendor = 7,
    ClientUserGroup = 8
}

export enum ActivityLogIdentifiersEnum {
    UserGroupAssigned = 1,
    UserGroupRemoved = 2
}

export enum ActivityLogActionTypeEnum {
    Insert = 1,
    Update = 2,
    Delete = 3,
    AssignedUserGroups = 4,
    RemovedUserGroups = 5,
    UpdatedUserGroups = 6,
    StatusUpdated = 7
}

export enum CategoryType {
    CustomerComplaint = 1,
    MoveOutInspection,
    TenantMovedOut,
    Reminder,
    Other,
    OverlockUnits
}

export enum WorkOrderStatus {
    NotStarted = 1,
    InProgress = 2,
    OnHold = 3,
    Completed = 4
}

export enum WoSubTaskState {
    Open = 1,
    Closed = 2
}

export enum ConfigType {
    CronOverLockWorkOrderRunDate1 = 1,
    CronOverLockWorkOrderRunDate2 = 2
}

export enum WorkOrderAssigneeType {
    group = 1,
    user = 2
}

export enum QuickBooksAccountTypesEnum{
    ChartOfAccountCodeForTotalRevenue=15,
    ChartOfAccountCodeForTotalRepairAndMaintenanceCost=16,
    ChartOfAccountCodeForTotalGoogleAdCost=17,
    ChartOfAccountCodeForTotalSparefootAdCost=18,
    ChartOfAccountCodeForTotalMarketingCost=19
}

export enum DataSourceEnum{
    All = 0,
    Storedge = 1,
    JiveCommunications = 2,
    WorkOrder = 3,
    DavinciLock = 4,
    QuickBooks = 5
}