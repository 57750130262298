<div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
    <div class="kt-header-mobile__logo">
        <a [routerLink]="['/']">
            <img alt="Logo" src="./assets/media/logos/logo.png" class="log-sm" />
        </a>
    </div>
    <div class="kt-header-mobile__toolbar">
        <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
            id="kt_aside_mobile_toggler"><span></span></button>
        <button class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler">
            <em class="flaticon-more-1"></em></button>
    </div>
</div>

<ng-template #loggedOut>
    <router-outlet></router-outlet>
</ng-template>

<div class="kt-grid kt-grid--hor kt-grid--root" *ngIf="layoutType === 2  else loggedOut">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            <app-header></app-header>
            <div class="kt-container kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch"
                id="kt_body">
                <app-side-nav></app-side-nav>
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>
