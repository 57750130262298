export * from './add/user.add.component';
export * from './edit/user.edit.component';
export * from './manage/user.manage.component';
export * from './detail/user.detail.component';
export * from './profile/user.profile.component';
export * from './profile-edit/user.profile.edit.component';

export * from './breadcrumb/add/breadcrumb.user.add.component';
export * from './breadcrumb/edit/breadcrumb.user.edit.component';
export * from './breadcrumb/manage/breadcrumb.user.manage.component';
export * from './breadcrumb/detail/breadcrumb.user.detail.component';
export * from './breadcrumb/profile/breadcrumb.user.profile.component';
export * from './breadcrumb/profile-edit/breadcrumb.user.profile.edit.component';
