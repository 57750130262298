<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader kt-grid__item" id="kt_subheader">
      <div class="kt-subheader__main">
          <app-breadcumb-work-order-configuration [companyId]="companyId">
          </app-breadcumb-work-order-configuration>
      </div>
      <div class="kt-subheader__toolbar">
          <div class="kt-subheader__wrapper">
          </div>
      </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui-wo-config'">
      <div class="row">
          <div class="col-12">
              <div class="kt-portlet">
                  <ngb-tabset type="string" class="custom-tab-set" [activeId]="1">
                      <ngb-tab id="1" title="Delinquent Workorder Configuration">
                          <ng-template ngbTabContent>
                            <form class="kt-form form-horizontal" name="form"
                              [appDateTimeMustBeGreaterThan]="['jrd2', 'jrt2', 'jrd1', 'jrt1']"
                              (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
                              <div class="kt-portlet__body" *blockUI="'container-blockui-opt-global-category'">
                                  <div class="form-group row"
                                    [ngClass]="{ 'validation-error': jrd1.invalid}">
                                    <div class="col-12">
                                      <p>
                                        Select the date on which you want to run the CRON for fetching the list of delinquent units from storEDGE.
                                        Once the list of units are fetched, a workorder with subtask will be created for the same.
                                      </p>
                                    </div>
                                    <div class="col-2 offset-1">
                                      <label>Job Run Date & Time (UTC):</label>
                                    </div>
                                    <div class="col-3">
                                      <select name="jrd1" class="form-control" 
                                        [(ngModel)]="jobRunDate1Config.dateValue"  #jrd1="ngModel"
                                        (change)="onJobDate1Change()" required>
                                        <option [value]="dayOfMonth" *ngFor="let dayOfMonth of dayOfMonthsForJob1">{{dayOfMonth}}</option>
                                      </select>
                                    </div>
                                    <div class="col-3">
                                      <select name="jrt1" class="form-control" 
                                        [(ngModel)]="jobRunDate1Config.timeValue"  #jrt1="ngModel" 
                                        (change)="onJobDate1Change()" required>
                                        <option [value]="time" *ngFor="let time of times">{{time}}</option>
                                      </select>
                                    </div>
                                    <div class="offset-3 col-9">
                                      <div class="validation-error-message" *ngIf="jrd1.invalid">
                                        Job Run Date and Time must be lesser than Job Run Date and Time 2.
                                      </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-8 offset-3">
                                    <p>Local Run Time {{localDay1}} of the Month at {{localTime1}} {{userTimeZone}}</p>
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-12">
                                    <p>
                                      On the date you select, the system will run another CRON to fetch the list of
                                      active delinquent units from storEDGE.
                                      If the selected day exceeds the number of days in the month the CRON will run 
                                      on the last day of the month.
                                    </p>
                                  </div>
                                  <div class="col-2 offset-1">
                                    <label>Job Run Date & Time 2 (UTC):</label>
                                  </div>
                                  <div class="col-3">
                                    <select name="jrd2" class="form-control"
                                      [(ngModel)]="jobRunDate2Config.dateValue"  #jrd2="ngModel" 
                                      (change)="onJobDate2Change()" required>
                                      <option [value]="dayOfMonth" *ngFor="let dayOfMonth of dayOfMonths">{{dayOfMonth}}</option>
                                    </select>
                                  </div>
                                  <div class="col-3">
                                    <select name="jrt2" class="form-control" 
                                      [(ngModel)]="jobRunDate2Config.timeValue"  #jrt2="ngModel" 
                                      (change)="onJobDate2Change()" required>
                                      <option [value]="time" *ngFor="let time of times">{{time}}</option>
                                    </select>
                                  </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-8 offset-3">
                                  <p>Local Run Time {{localDay2}} of the Month at {{localTime2}} {{userTimeZone}}</p>
                                </div>
                              </div>

                              </div>
                              <div class="kt-portlet__body separator">
                                  <div class="row flex-r">
                                      <input type="submit" class="btn btn-primary" value="Save" />
                                  </div>
                              </div>
                            </form>
                          </ng-template>
                      </ngb-tab>
                  </ngb-tabset>
              </div>
          </div>
      </div>
  </div>
</div>
