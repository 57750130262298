import { Inject, Injectable, Injector } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { of, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppUtils } from "src/helpers";
import { UserDetailModel } from "src/models";
import { MasterService, UserService } from "src/services";


@Injectable()
export class UserInfoSynchronizer {

    constructor(
                private masterService: MasterService,
                private userService: UserService,
                private appUtils: AppUtils,
                @Inject(Injector) private readonly injector: Injector
                ) {
    }

    private get toastr() {
        return this.injector.get(ToastrService);
    }

    public loadUserInfo$() {
        if (!this.appUtils.isUserAuthenticated()) {
            return of(undefined);
        }

        return this.userService.getDetail(this.appUtils.getUserId())
            .pipe(tap((userInfo: UserDetailModel) => {
                    this.masterService.setUserInfo(userInfo);
                    this.appUtils.setAppTimeZoneOffset(userInfo);
                }),
                catchError(error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                    throw throwError(error);
                })
            );
    }
}
