import { Component, OnInit, Input } from '@angular/core';
import { ListWithLinkModel } from 'src/models';

@Component({
  selector: 'app-list-with-link',
  templateUrl: './list-with-link.component.html'
})
export class ListWithLinkComponent implements OnInit {

  @Input('items')
  items: ListWithLinkModel[];

  constructor() { }

  ngOnInit() {
    this.items.sort((item1, item2) => {
      const text1 = item1.name.toUpperCase();
      const text2 = item2.name.toUpperCase();
      return (text1 < text2)
        ? -1
        : (text1 > text2) ? 1 : 0;
    });
  }
}
