import { Component, HostBinding, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { WorkOrderService } from 'src/services';
import { NgForm } from '@angular/forms';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { WorkOrderCategoryEditModel } from 'src/models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-comp-wo-category-edit',
    templateUrl: './company.wo.category.edit.component.html'
})
export class CompanyWoCategoryEditComponent implements OnInit {
    companyId: number;
    categoryId: number;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-add-category') blockUI: NgBlockUI;

    model: WorkOrderCategoryEditModel = new WorkOrderCategoryEditModel();
    roleMatrix: RoleMatrix;
    priorityList: any;

    constructor(private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private appUtils: AppUtils,
        private workOrderService: WorkOrderService
    ) {

        this.roleMatrix = new RoleMatrix();

        this.companyId = this.route.snapshot.params['companyId'];
        this.categoryId = this.route.snapshot.params['categoryId'];

        this.priorityList = this.appUtils.getPriorityList();
    }

    ngOnInit(): void {
        if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.getLocalCategory();
    }

    getLocalCategory() {
        this.blockUI.start();
        this.workOrderService.getLocalCategory(this.companyId, this.categoryId)
            .subscribe(
                (data: WorkOrderCategoryEditModel) => {
                    this.model = data;
                    this.model.workOrderPriority = this.model.workOrderPriority || '';
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                },
                () => {
                    this.blockUI.stop();
                });
    }

    submit(f: NgForm) {

        if (this.model.dueDateDuration && (this.model.dueDateDuration < 1 || this.model.dueDateDuration > 365)) {
            f.form.controls['dueDateDuration'].setErrors({ 'range': true });
        } else {
            f.form.controls['dueDateDuration'].setErrors(null);
        }

        if (f.form.invalid) {
            return;
        }

        this.blockUI.start();
        this.workOrderService.editLocalCategory(this.model)
            .subscribe(
                () => {
                    setTimeout(() => {
                        this.router.navigate(['/work-order-comp-categories', this.companyId], { queryParams: { activeTab: 2 } });
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Category has been updated successfully.');
                    }, 500);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                },
                () => {
                    this.blockUI.stop();
                });
    }
}
