import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService, UserGroupService } from '../../../services';
import { CompanyUserDetailModel, UserImageUploadModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { first } from 'rxjs/operators';
import { TimeZoneService } from 'src/services/timezone.service';

@Component({
    selector: 'app-company-user-detail',
    templateUrl: './company.user.detail.component.html'
})
export class CompanyUserDetailComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model = new CompanyUserDetailModel();
    imageUploadModel: UserImageUploadModel = new UserImageUploadModel();
    companyId: number;
    userType: string;
    isModelLoaded = false;
    userGroups: string[] = [];
    showActionColumn = false;
    showFollowButton = false;
    notificationPreferencesText = '';
    notificationPreferencesAsWatcherText = '';
    timeZoneFormattedName: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private userGroupService: UserGroupService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.model.id = params['id'];
        });

        this.route.queryParams.subscribe((params) => {
            this.companyId = params['companyId'];
            this.userType = params['userType'];
        });

        if (!this.companyId
            || !this.userType
            || ((this.roleMatrix.role === 'ORGSUPERADMIN'
                || this.roleMatrix.role === 'ORGADMIN'
                || this.roleMatrix.role === 'ORGUSER')
                && (this.roleMatrix.companyId !== this.companyId.toString()))
            || (this.userType !== 'admin' && this.userType !== 'user' && this.userType !== 'any')) {
            this.router.navigate(['/error/404']);
        }

        if (!this.roleMatrix.canViewOrgAdmin && !this.roleMatrix.canViewOrgUser) {
            this.router.navigate(['/error/403']);
        }
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.loadUsersGroups();
        this.userService.getCompanyUserDetail(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();
                Object.assign(this.model, data);
                this.model.createdOn = this.appUtils.getFormattedDateTime(this.model.createdOn);
                this.model.normalizedRole = this.appUtils.getNormalizedRole(this.model.role);
                this.imageUploadModel.userId = this.model.id;
                this.imageUploadModel.fileUrl = this.model.profileImageUrl;
                this.isModelLoaded = true;
                this.showFollowButton = !this.appUtils.is10FedUser(this.roleMatrix.role) &&
                    this.model.id.toLowerCase() !== this.appUtils.getUserId().toLowerCase();

                if ((this.model.role === 'OrgAdmin' && this.roleMatrix.canEditOrgAdmin)
                    || (this.model.role === 'OrgUser' && this.roleMatrix.canEditOrgUser)) {
                    this.imageUploadModel.canEditProfileImage = true;
                }

                this.loadTimeZoneFormattedName();
                this.setNotificationText();
                this.updateConfigureAllowActions();
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    setNotificationText() {
        var tempArr = [];
        if (this.model.sendEmailNotification) {
            tempArr.push('Email');
        }
        if (this.model.sendInAppNotification) {
            tempArr.push('In App');
        }
        if (this.model.sendPushNotification) {
            tempArr.push('Push');
        }
        this.notificationPreferencesAsWatcherText = tempArr.join(',&nbsp;')

        tempArr = [];
        if (this.model.enableEmailNotification) {
            tempArr.push('Email');
        }
        if(this.model.enableInAppNotification){
            tempArr.push('In-App');
        }
        if(this.model.enablePushNotification) {
            tempArr.push('Push')
        }
        this.notificationPreferencesText = tempArr.join(',&nbsp;')
    }

    loadUsersGroups() {
        this.blockUI.start();
        this.userGroupService.getUsersGroups(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();
                this.userGroups = data;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    toggleStatus(): void {
        this.blockUI.start();
        this.userService.toggleStatus(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.model.status = this.model.status === 3 ? 2 : 3;
                this.toastr.success(`User has been ${(this.model.status === 3 ? 'deactivated' : 'activated')} successfully.`);
                if (this.model.status === 3) {
                    this.updateConfigureAllowActions();
                } else {
                    this.roleMatrix = new RoleMatrix();
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    delete(): void {
        if (!confirm('Are you sure you want to delete the selected user?')) {
            return;
        }

        const callBackRoute = '/company-user/'
            + (this.userType === 'admin' ? 'manage-administrator' : this.userType === 'user' ? 'manage-personnel' : 'manage');

        this.blockUI.start();

        this.userService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();

                setTimeout(() => {
                    this.router.navigate([callBackRoute],
                        { queryParams: { companyId: this.model.companyId, userType: this.userType } });
                }, 10);

                setTimeout(() => {
                    this.toastr.success('User has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    updateShowActionColumn() {
        if (((this.model.role === 'OrgAdmin'
            && (this.roleMatrix.canChangeStatusOfOrgAdmin
                || this.roleMatrix.canEditOrgAdmin
                || this.roleMatrix.canDeleteOrgAdmin
                || this.roleMatrix.canResetOrgAdminPassword))
            || (this.model.role === 'OrgUser'
                && (this.roleMatrix.canChangeStatusOfOrgUser
                    || this.roleMatrix.canEditOrgUser
                    || this.roleMatrix.canDeleteOrgUser
                    || this.roleMatrix.canResetOrgUserPassword)))) {
            this.showActionColumn = true;
        }
    }

    updateConfigureAllowActions() {
        if (this.model.status === 3) {
            this.roleMatrix.configureAllowActionsForOrgUser();
            this.roleMatrix.configureAllowActionsForOrgAdmin();
            this.imageUploadModel.canEditProfileImage = false;
        }
        this.updateShowActionColumn();
    }

    followUser() {
        this.blockUI.start();
        this.model.isFollowing = !this.model.isFollowing;
        this.userService.followCompanyUser(this.model.id, this.model.isFollowing)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success(this.model.isFollowing
                        ? 'You have started following the user.'
                        : 'You have unfollowed the user.');
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    resetPassword(): void {
        if (!confirm('Are you sure you want to reset password for the selected user?')) {
            return;
        }
        this.blockUI.start();
        this.userService.resetPasswordForUser(this.model.id).subscribe(
            (resp: string) => {
                this.blockUI.stop();
                this.toastr.success(resp);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private loadTimeZoneFormattedName() {
        this.timeZoneService.getTimeZoneFormattedName(this.model.timeZone)
            .pipe(first())
            .subscribe(
                name => {
                    this.timeZoneFormattedName = name;
                }
            );
    }
}
