import { Routes, RouterModule } from '@angular/router';

import {
    LoginComponent, LogoutComponent, AccountRecoverComponent, AccountResetComponent, AccountActivateComponent, ChangePasswordComponent
} from '../components/account';
import {
    UserAddComponent, UserEditComponent, UserManageComponent, UserDetailComponent, UserProfileComponent, UserProfileEditComponent
} from '../components/user';
import {
    CompanyAddComponent, CompanyEditComponent, CompanyOwnerEditComponent, CompanyDetailComponent, CompanyManageComponent
} from '../components/company';
import {
    CompanyUserAddComponent, CompanyUserEditComponent, CompanyUserManageComponent, CompanyUserDetailComponent,
    CompanyUesrProfileDetailComponent, CompanyUserProfileEditComponent,
    CompanyUserManageAdministratorComponent, CompanyUserManagePersonnelComponent
} from '../components/company-user';

import { FacilityManageComponent, FacilityAddComponent, FacilityEditComponent, FacilityDetailComponent } from '../components/facility';

import { UnitAddComponent, UnitEditComponent, UnitDetailComponent } from '../components/unit';

import {
    WorkOrderManageComponent, WorkOrderAddComponent, WorkOrderEditComponent, WorkOrderDetailComponent, WorkOrderActivityLogComponent,
    WorkOrderNoteComponent, WorkOrderReportIssueComponent, WorkOrderGridViewComponent, WorkOrderCategoryManageComponent, 
    WorkOrderListClosedComponent
} from '../components/work-order';

import {
    VendorManageComponent, VendorAddComponent, VendorDetailComponent, VendorEditComponent, VendorNoteComponent
} from '../components/vendor';

import { KioskManageComponent, KioskAddComponent, KioskDetailComponent, KioskOfflineLogComponent } from '../components/kiosk';
import { SignageManageComponent, SignageAddComponent, SignageEditComponent } from '../components/signage';
import { StoredgeIntegrationComponent } from '../components/storedge';

import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { Error403Component, Error404Component } from '../components/error';

import { AuthGuard } from '../helpers';
import { MoveinConfigurationComponent } from 'src/components/movein';
import { UserGroupComponent, UserGroupAddComponent, UserGroupEditComponent, UserGroupDetailComponent } from '../components/user-group';
import {
    CompanyWoCategoryManageComponent,
    CompanyWoCategoryAddComponent,
    CompanyWoCategoryEditComponent
} from 'src/components/company-work-order-category';
import { KpiDashboardComponent } from 'src/components/kpi-dashboard';
import { ConfigurationComponent } from 'src/components/configuration/configuration.component';
import { WoBulkChangesComponent } from 'src/components/work-order/bulk-changes/wo-bulk-changes.component';

import { DatasourceComponent, ReportComponent } from 'src/components/kpi-report';
import { FacilityNoteHistoryComponent } from 'src/components/facility/note-history/facility-note-history.component';
import { KioskActivityLogComponent } from 'src/components/kiosk/activity-log/kiosk.activity.log.component';
import { QuickBooksCallBackComponent, QuickBooksEditComponent } from 'src/components/quickbooks';
import { JiveCallBackComponent } from 'src/components/jive-communication/callback/jive.callback.component';

const appRoutes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    
    { path: 'error/403', component: Error403Component },
    { path: 'error/404', component: Error404Component },
    
    { path: 'account/login', component: LoginComponent },
    { path: 'account/logout', component: LogoutComponent },
    { path: 'account/recover', component: AccountRecoverComponent },
    { path: 'account/reset/:key/:secret', component: AccountResetComponent },
    { path: 'account/activate/:key/:secret', component: AccountActivateComponent },
    { path: 'account/change-password', component: ChangePasswordComponent },
    
    { path: 'user/add', component: UserAddComponent, canActivate: [AuthGuard] },
    { path: 'user/edit/:id', component: UserEditComponent, canActivate: [AuthGuard] },
    { path: 'user/manage', component: UserManageComponent, canActivate: [AuthGuard] },
    { path: 'user/detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
    { path: 'user/profile', component: UserProfileComponent },
    { path: 'user/edit-profile', component: UserProfileEditComponent },
    
    { path: 'company/add', component: CompanyAddComponent, canActivate: [AuthGuard] },
    { path: 'company/edit/:id', component: CompanyEditComponent, canActivate: [AuthGuard] },
    { path: 'company-owner/edit/:id', component: CompanyOwnerEditComponent, canActivate: [AuthGuard] },
    { path: 'company/detail/:id', component: CompanyDetailComponent, canActivate: [AuthGuard] },
    { path: 'company/manage', component: CompanyManageComponent, canActivate: [AuthGuard] },
    { path: 'company-user/add', component: CompanyUserAddComponent, canActivate: [AuthGuard] },
    { path: 'company-user/edit/:id', component: CompanyUserEditComponent, canActivate: [AuthGuard] },
    { path: 'company-user/manage', component: CompanyUserManageComponent, canActivate: [AuthGuard] },
    { path: 'company-user/manage-administrator', component: CompanyUserManageAdministratorComponent, canActivate: [AuthGuard] },
    { path: 'company-user/manage-personnel', component: CompanyUserManagePersonnelComponent, canActivate: [AuthGuard] },
    { path: 'company-user/detail/:id', component: CompanyUserDetailComponent, canActivate: [AuthGuard] },
    { path: 'company-user/profile-detail', component: CompanyUesrProfileDetailComponent },
    { path: 'company-user/profile-edit', component: CompanyUserProfileEditComponent },
    
    { path: 'facility/manage/:id', component: FacilityManageComponent, canActivate: [AuthGuard] },
    { path: 'facility/add/:id', component: FacilityAddComponent, canActivate: [AuthGuard] },
    { path: 'facility/edit/:companyId/:id', component: FacilityEditComponent, canActivate: [AuthGuard] },
    { path: 'facility/detail/:companyId/:id', component: FacilityDetailComponent, canActivate: [AuthGuard] },
    { path: 'facility/notes/:companyId/:id', component: FacilityNoteHistoryComponent, canActivate: [AuthGuard] },
    
    { path: 'unit/add/:companyId/:facilityId', component: UnitAddComponent, canActivate: [AuthGuard] },
    { path: 'unit/edit/:companyId/:facilityId/:id', component: UnitEditComponent, canActivate: [AuthGuard] },
    { path: 'unit/detail/:companyId/:facilityId/:id', component: UnitDetailComponent, canActivate: [AuthGuard] },
    
    { path: 'work-order/manage/:id', component: WorkOrderManageComponent, canActivate: [AuthGuard] },
    { path: 'work-order/grid-view/:id', component: WorkOrderGridViewComponent, canActivate: [AuthGuard] },
    { path: 'work-order/add/:viewType/:id', component: WorkOrderAddComponent, canActivate: [AuthGuard] },
    { path: 'work-order/edit/:viewType/:companyId/:id', component: WorkOrderEditComponent, canActivate: [AuthGuard] },
    { path: 'work-order/detail/:viewType/:companyId/:id', component: WorkOrderDetailComponent, canActivate: [AuthGuard] },
    { path: 'work-order/activity-log/:companyId/:id', component: WorkOrderActivityLogComponent, canActivate: [AuthGuard] },
    { path: 'work-order/note/:companyId/:id', component: WorkOrderNoteComponent, canActivate: [AuthGuard] },
    { path: 'work-order/report-issue/:companyId/:id', component: WorkOrderReportIssueComponent, canActivate: [AuthGuard] },
    { path: 'work-order/categories', component: WorkOrderCategoryManageComponent, canActivate: [AuthGuard] },
    { path: 'work-order/list-closed/:id', component: WorkOrderListClosedComponent, canActivate: [AuthGuard] },
    { path: 'work-order-comp-categories/:companyId', component: CompanyWoCategoryManageComponent, canActivate: [AuthGuard] },
    { path: 'work-order-comp-categories/:companyId/add', component: CompanyWoCategoryAddComponent, canActivate: [AuthGuard] },
    { path: 'work-order-comp-categories/:companyId/edit/:categoryId', component: CompanyWoCategoryEditComponent, canActivate: [AuthGuard] },
    { path: 'work-order/bulk-change/:id/:viewType', component: WoBulkChangesComponent, canActivate: [AuthGuard] },

    { path: 'vendor/manage/:companyId', component: VendorManageComponent, canActivate: [AuthGuard] },
    { path: 'vendor/add/:companyId', component: VendorAddComponent, canActivate: [AuthGuard] },
    { path: 'vendor/detail/:companyId/:id', component: VendorDetailComponent, canActivate: [AuthGuard] },
    { path: 'vendor/edit/:companyId/:id', component: VendorEditComponent, canActivate: [AuthGuard] },
    { path: 'vendor/note/:companyId/:id', component: VendorNoteComponent, canActivate: [AuthGuard] },
    
    { path: 'kiosk/manage/:companyId', component: KioskManageComponent, canActivate: [AuthGuard] },
    { path: 'kiosk/add/:companyId', component: KioskAddComponent, canActivate: [AuthGuard] },
    { path: 'kiosk/detail/:companyId/:facilityId/:id', component: KioskDetailComponent, canActivate: [AuthGuard] },
    { path: 'kiosk/offline-log/:companyId', component: KioskOfflineLogComponent, canActivate: [AuthGuard] },
    { path: 'kiosk/activity-log/:companyId/:facilityId/:id', component: KioskActivityLogComponent, canActivate: [AuthGuard] },
    

    { path: 'signage/manage/:companyId/:facilityId/:kioskId', component: SignageManageComponent, canActivate: [AuthGuard] },
    { path: 'signage/add/:companyId/:facilityId/:kioskId', component: SignageAddComponent, canActivate: [AuthGuard] },
    { path: 'signage/edit/:companyId/:facilityId/:kioskId/:id', component: SignageEditComponent, canActivate: [AuthGuard] },
    
    { path: 'storedge/intergration/:companyId', component: StoredgeIntegrationComponent, canActivate: [AuthGuard] },
    
    { path: 'movein/configuration/:companyId/:facilityId/:kioskId', component: MoveinConfigurationComponent, canActivate: [AuthGuard] },

    { path: 'company/:companyId/user-group', component: UserGroupComponent, canActivate: [AuthGuard] },
    { path: 'company/:companyId/user-group/add', component: UserGroupAddComponent, canActivate: [AuthGuard] },
    { path: 'company/:companyId/user-group/:groupId/edit', component: UserGroupEditComponent, canActivate: [AuthGuard] },
    { path: 'company/:companyId/user-group/:groupId/detail', component: UserGroupDetailComponent, canActivate: [AuthGuard] },

    { path: 'kpi-dashboard', component: KpiDashboardComponent, canActivate: [AuthGuard] },
    { path: 'config/:companyId', component: ConfigurationComponent, canActivate: [AuthGuard] },

    //reports components path
    
    {path: 'kpi-report/datasource/:companyId',component:DatasourceComponent,canActivate: [AuthGuard] },
    {path: 'kpi-report/report/:companyId',component:ReportComponent,canActivate: [AuthGuard] },
    {path: 'quickbooks/edit/:companyId/:facilityId', component: QuickBooksEditComponent, canActivate: [AuthGuard] },   
    {path: 'quickbooks/callback', component: QuickBooksCallBackComponent, canActivate: [AuthGuard] },
    {path: 'jivecb', component: JiveCallBackComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }

    
];


export const appRouting = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
