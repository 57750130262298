import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-facility-add',
    templateUrl: './breadcrumb.facility.add.component.html'
})

export class BreadcrumbFacilityAddComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
