<div class="kt-portlet__body kt-portlet__body--fit">
    <div class="row " [ngClass]="{ 'm-t--40' : !isImporting , 'm-t--70' : isImporting}">
        <div class="col-12">
            <h3 class="kt-portlet__head-title m-b-15">
                Configure the facility details
            </h3>
        </div>
    </div>
    <div class="row m-t-40" [ngClass]="{ 'm-t-40' : isImporting}">
        <div class="col-7">
            <span class="fs-15">
                <em class="la la-check-circle text-success fs-20"></em> All details filled
            </span>
            <span class="fs-15 m-l-10">
                <em class="la la-eye text-warning fs-20"></em> Review the prefilled details
            </span>
            <span class="fs-15 m-l-10">
                <em class="la la-exclamation-triangle text-danger fs-20"></em> Details missing
            </span>
        </div>
        <div class="col-5">

        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="kt-grid  kt-wizard-v2 kt-wizard-v2--white">
                <div class="kt-grid__item kt-wizard-v2__aside p-l-0 p-t-0 m-t-20">
                    <div class="row">
                        <div class="col-12">
                            <p class="fs-15 m-b-0">
                                {{totalSelectedFacilities}} out of {{totalFacilities}} facilities selected
                            </p>
                        </div>
                        <div class="col-12">
                            <div class="kt-wizard-v2__nav" data-scroll="true" data-height="800">
                                <div class="kt-wizard-v2__nav-items">
                                    <div class="kt-wizard-v2__nav-item cursor-pointer"
                                        *ngFor="let item of facilities;index as $index"
                                        [ngClass]="{ 'active' : item.id === model.id}"
                                        (click)="selectFacility($index, f)">
                                        <div class="kt-wizard-v2__nav-body verify-facility-wrap">
                                            <div class="kt-wizard-v2__nav-icon">
                                                <i class="flaticon-bus-stop"></i>
                                            </div>
                                            <div class="kt-wizard-v2__nav-label">
                                                <div class="kt-wizard-v2__nav-label-title">
                                                    {{item.name}}
                                                </div>
                                                <div class="kt-wizard-v2__nav-label-desc">
                                                    {{item.phone}}
                                                </div>
                                            </div>
                                            <div class="kt-wizard-v2__nav-icon kt-wizard-v2__nav-icon__right">
                                                <span class="icons-wrapper">
                                                    <i class="la la-exclamation-triangle text-danger fs-24"
                                                        *ngIf="item.modelState == 0"></i>
                                                    <i class="la la-eye text-warning  fs-24"
                                                        *ngIf="item.modelState == 1"></i>
                                                    <i class="la la-check-circle text-success  fs-24"
                                                        *ngIf="item.modelState == 2"></i>
                                                    <i class="la la-remove text-danger fs-24"
                                                        (click)="removeFacility(item)"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                    <div class="kt-form p-0 pt-4 pr-4 w-100">
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-md-12" *blockUI="'container-blockui-verify'">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <p class="m-b-0 p-b-0 fs-15">Fill out the details for the Facility</p>
                                            </div>
                                        </div>
                                        <form class="kt-form form-horizontal p-0" name="form" (ngSubmit)="next(f)"
                                            #f="ngForm" novalidate>
                                            <div class="kt-portlet__body">
                                                <div class="form-group"
                                                    [ngClass]="{ 'validation-error' : f.submitted && propertyManager.invalid}">
                                                    <label>Property Manager:</label>
                                                    <select class="form-control" name="propertyManager"
                                                        [(ngModel)]="model.propertyManagerUserId"
                                                        (change)="setPropertyManagerFields()"
                                                        #propertyManager="ngModel">
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of companyUsers" [value]="item.key">
                                                            {{item.value}}</option>
                                                    </select>
                                                    <div class="validation-error-message"
                                                        *ngIf="f.submitted && propertyManager.invalid">
                                                        <span *ngIf="propertyManager.errors.required">
                                                            Property manager is required
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-group"
                                                    [ngClass]="{ 'validation-error' : f.submitted && typeOfConstruction.invalid}">
                                                    <label>Type of Construction:</label>
                                                    <input type="text" name="typeOfConstruction" class="form-control"
                                                        maxlength="20" autocomplete="off"
                                                        [(ngModel)]="model.typeOfConstruction"
                                                        #typeOfConstruction="ngModel" appAlphaNumericsOnly required />
                                                    <div class="validation-error-message"
                                                        *ngIf="f.submitted && typeOfConstruction.invalid">
                                                        Type of construction is required
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-5">Climate Controller:</label>
                                                    <div class="col-1 kt-checkbox-top-raised">
                                                        <label class="kt-checkbox kt-checkbox--brand">
                                                            <input type="checkbox" class="form-control"
                                                                name="climateControlled"
                                                                [(ngModel)]="model.climateControlled"
                                                                #climateControlled="ngModel" />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>User Groups Assigned:</label>
                                                    <ng-multiselect-dropdown name="ClientUserGroupsAssigned"
                                                        [placeholder]="'All'" 
                                                        [(ngModel)]="selectedClientUserGroups"
                                                        (ngModelChange)="getChangesOnMemberAssigned($event)"
                                                        [settings]="multiSelectDropdownConfigs"
                                                        [data]="clientUserGroups"
                                                        (onSelect)="onUserGroupSelectDeSelect()"
                                                        (onSelectAll)="onUserGroupSelectDeSelectAll($event)"
                                                        (onDeSelect)="onUserGroupSelectDeSelect()"
                                                        (onDeSelectAll)="onUserGroupSelectDeSelectAll($event)">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                                <div class="form-group"
                                                    [ngClass]="{ 'validation-error' : f.submitted && defaultAssigneeIdControl.invalid}">
                                                    <label>Default work order assignee:</label>
                                                    <select class="form-control" name="defaultAssigneeIdControl"
                                                        [(ngModel)]="defaultAssigneeId"
                                                        (change)="setDefaultAssignee()"
                                                        #defaultAssigneeIdControl="ngModel" required>
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of clientUsers" [value]="item.key">
                                                            {{item.value}}
                                                        </option>
                                                    </select>
                                                    <div class="validation-error-message"
                                                        *ngIf="f.submitted && defaultAssigneeIdControl.invalid">
                                                        Default work order assignee is required
                                                    </div>
                                                </div>
                                                <div class="form-group"
                                                    [ngClass]="{ 'validation-error' : f.submitted && complaintsAssigneeId.invalid}">
                                                    <label>Default Assignee for customers complaints:</label>
                                                    <select class="form-control" name="complaintsAssigneeId"
                                                        [(ngModel)]="complaintAssigneeId"
                                                        (change)="setComplaintsAssignee()"
                                                        #complaintsAssigneeId="ngModel" required>
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of clientUsers" [value]="item.key">
                                                            {{item.value}}</option>
                                                    </select>
                                                    <div class="validation-error-message"
                                                        *ngIf="f.submitted && complaintsAssigneeId.invalid">
                                                        Default assignee for customers complaints is required
                                                    </div>
                                                </div>
                                                <div class="form-group"
                                                    [ngClass]="{ 'validation-error' : f.submitted && moAssigneeId.invalid}">
                                                    <label>Default Assignee for Move Out:</label>
                                                    <select class="form-control" name="moAssigneeId"
                                                        [(ngModel)]="moveOutAssigneeId" (change)="setMoveOutAssignee()"
                                                        #moAssigneeId="ngModel" required>
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of clientUsers" [value]="item.key">
                                                            {{item.value}}</option>
                                                    </select>
                                                    <div class="validation-error-message"
                                                        *ngIf="f.submitted && moAssigneeId.invalid">
                                                        Default assignee for move out is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot" *ngIf="totalSelectedFacilities > 1">
                                                <div class="kt-form__actions text-right">
                                                    <button type="button" class="btn btn-secondary m-r-5"
                                                        (click)="prev(f)" *ngIf="currentItemIndex !== 0">
                                                        <em class="la la-arrow-left"></em> Prev
                                                    </button>
                                                    <button type="button" class="btn btn-primary"
                                                        *ngIf="currentItemIndex !== (totalSelectedFacilities - 1)"
                                                        (click)="next(f)">
                                                        Next <em class="la la-arrow-right"></em>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>