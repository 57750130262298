<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-3" *ngIf="!isClosedOnly">
                <div class="form-group">
                    <!-- <select class="form-control" name="status" [(ngModel)]="filterModel.status">
                        <option value="">[Status]</option>
                        <option value="1">Not Started</option>
                        <option value="2">In Progress</option>
                        <option value="3">On Hold</option>
                    </select> -->
                    <ng-multiselect-dropdown name="status" [placeholder]="'[Status]'" 
                  [(ngModel)]="selectedStatus" [settings]="multiSelectDropdownConfigs"
                  [data]="status">
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <!-- <select class="form-control" name="priority" [(ngModel)]="filterModel.priority">
                        <option value="">[Priority]</option>
                        <option value="1">Low</option>
                        <option value="2">Medium</option>
                        <option value="3">High</option>
                    </select> -->
                    <ng-multiselect-dropdown name="priority" [placeholder]="'[Priority]'" 
                  [(ngModel)]="selectedPriority" [settings]="multiSelectDropdownConfigs"
                  [data]="priority">
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group facility-dropdown">
                    <!-- <select class="form-control" name="facilityId" [(ngModel)]="filterModel.facilityId"
                        (change)="onFacitlityChanged(true)">
                        <option value="">[Facility]</option>
                        <option *ngFor="let item of facilities" [value]="item.id">
                            {{item.name}}</option>
                    </select> -->
                    <ng-multiselect-dropdown name="facilityId" [placeholder]="'[Facility]'" 
                  [(ngModel)]="selectedFacilities" [settings]="multiSelectDropdownConfigs" 
                  [data]="facilities"
                  (onSelect)="onItemSelectForFacility()" (onSelectAll)="onSelectAllForFacility($event)" (onDeSelect)="onItemDeSelectForFacility()" (onDeSelectAll)="onDeSelectAllForFacility()">
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group unit-dropdown">
                    <!-- <select class="form-control" name="unitId" [(ngModel)]="filterModel.unitId">
                        <option value="">[Unit]</option>
                        <option *ngFor="let item of units" [value]="item.id">
                            {{item.name}}</option>
                    </select> -->
                    <ng-multiselect-dropdown name="unitIds" [placeholder]="'[Unit]'" 
                  [(ngModel)]="selectedUnits" [settings]="multiSelectDropdownConfigs" 
                  [data]="units"
                  [disabled]="isDropdownDisabled">
                </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group assignee-dropdown">
                    <!-- <select class="form-control" name="assigneeId" [(ngModel)]="filterModel.assigneeId">
                        <option value="">[Assignee]</option>
                        <option *ngFor="let item of assignedUsers" [value]="item.id">
                            {{item.firstName}} {{item.lastName}}
                        </option>
                    </select> -->
                    <ng-multiselect-dropdown name="assigneeId" [placeholder]="'[Assignee]'"  
                        [(ngModel)]="selectedAssignedUsers" [settings]="multiSelectDropdownConfigs"
                        [data]="assignedUsers">

                        <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                            <div class="dropdown-img-custom">
                                <img [src]="getItems[id]?.image" alt="" style="width: 12px; margin: 0 0 12px 6px;" />
                            </div>
                            <div class="dropdown-option-custom">
                                {{option}}
                            </div>
                       </ng-template>
                       <ng-template #optionSelectedTemplate let-option="option"  let-id="id">
                         <div>
                           <img [src]="getItems[id]?.image" alt="" style="width: 12px; margin-right: 2px;" />
                          {{option}}
                         </div>
                       </ng-template>
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group created-by-dropdown">
                    <!-- <select class="form-control" name="createdById" [(ngModel)]="filterModel.creatorId">
                        <option value="">[Created By]</option>
                        <option *ngFor="let user of createdByUsers" [value]="user.id">
                            {{user.firstName}} {{user.lastName}}
                        </option>
                    </select> -->
                    <ng-multiselect-dropdown name="createdById" [placeholder]="'[Created By]'" 
                  [(ngModel)]="selectedCreatedByUsers" [settings]="multiSelectDropdownConfigs"
                  [data]="createdByUsers">
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="category-dropdown">
                    <!-- <button type="button" class="btn btn-outline-primary heading" id="dropdownBasic1" ngbDropdownToggle>
                        {{ selectedCategory ? selectedCategory.name : '[Category]'}}
                    </button> -->
                    <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="content">
                        <div ngbDropdownItem (click)="setCategory(null)">
                            <span class="word-wrap2">[Category]</span>
                        </div>
                        <div ngbDropdownItem *ngFor="let item of workOrderCategories" (click)="setCategory(item)">
                            <span class="word-wrap2"><span *ngIf="!!item.isUnrentable">*</span>{{item.name}}</span>
                            <span *ngIf="!!item.isUnrentable" class="kt-badge kt-badge--danger kt-badge--inline tag">
                                Unrentable
                            </span>
                        </div>
                    </div> -->
                    <ng-multiselect-dropdown name="workOrderCategoryIds" [placeholder]="'[Category]'" 
                        [(ngModel)]="selectedCategory" [settings]="multiSelectDropdownConfigs"
                        [data]="workOrderCategories">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group m-b-0">
                    <input type="text" class="form-control" placeholder="Filter Work Orders"
                        [(ngModel)]="filterModel.filterKey" autocomplete="Off" maxlength="50"
                        (keydown.enter)="applyFilter(true)" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="allowBulkChange">
                <div class="form-group">
                    <a [routerLink]="['/work-order/bulk-change', companyId, viewType]" class="btn btn-warning btn-elevate btn-icon-sm">
                        Bulk Change
                    </a>
                </div>
            </div>
            <div class="text-right" [ngClass]="{ 'col-6': allowBulkChange, 'col-12': !allowBulkChange }">
                <div class="form-group">
                    <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" (click)="applyFilter(true)">
                        <em class="la la-search"></em> Filter
                    </button>
                    <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="resetFilter(true)">
                        <em class="la la-close"></em> Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

