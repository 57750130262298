import { KioskOfflineLogModel } from './kiosk.offline.log.model';

export class KioskOfflineTimestampModel {
    public date: any;
    public offlineLogs: Array<KioskOfflineLogModel>;

    constructor() {
        this.offlineLogs = new Array<KioskOfflineLogModel>();
    }
}
