<div class="row">

    <div class="col-12">
        <img *ngIf="model.fileUrl" [src]="model.fileUrl" alt="" class="profile-image" />
        <img *ngIf="!model.fileUrl" src="https://via.placeholder.com/200x200.png?text=No%20Image" alt="" />

        <input type="file" name="vendorImage" #vendorImage="ngModel" class="d-none w-50"
            [(ngModel)]="model.originalFileName" accept="image/png,image/jpg,image/jpeg" #vendorImageElem
            (change)="onImageSelected($event)" required />
        <div class="m-t-15" *ngIf="!model.fileUrl && model.canEditProfileImage">
            <button type="button" class="btn btn-sm btn-outline-brand" (click)="vendorImageElem.click()">
                Choose file
            </button>
        </div>

        <div class="m-t-15" *ngIf="model.fileUrl && model.canEditProfileImage">
            <button type="button" class="btn btn-xs btn-outline-brand" (click)="vendorImageElem.click()">
                Change
            </button>
            <button type="button" class="btn btn-xs btn-outline-danger m-l-15" (click)="deleteFile()">
                Remove
            </button>
        </div>
    </div>
</div>
<ng-template #vendorImageCropper let-modal>
    <div *blockUI="'container-blockui-image-cropper'">
        <div class="modal-header">
            <h5 class="modal-title text-overflow">{{model.fileName}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeImageCropperModal(true)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="false"
                [onlyScaleDown]="true"
                [roundCropper]="false"
                [alignImage]="'left'"
                [style.display]="showCropper ? null : 'none'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
            </div>
            <div class="col-md-12 m-t-15">
                <ngb-progressbar type="info" [value]="model.fileUploadPercent" height="5px"
                    *ngIf="model.fileUploadPercent > 0 && model.fileUploadPercent < 100">
                </ngb-progressbar>
            </div>
        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary" (click)="uploadImage()">Upload</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>