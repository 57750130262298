import { Component, ViewChild, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UnitService } from '../../../services';
import { UnitDetailModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-unit-detail',
    templateUrl: './unit.detail.component.html'
})

export class UnitDetailComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('unitChallengeDeleteModal', { static: true }) unitChallengeDeleteModal: any;
    model: UnitDetailModel = new UnitDetailModel();
    isModelLoaded = false;
    showActionColumn = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private unitService: UnitService) {

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.model.companyId = params['companyId'];
            this.model.facilityId = params['facilityId'];
            this.model.id = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.updateShowActionColumn();
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.unitService.getDetail(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                    this.isModelLoaded = true;
                    this.updateConfigureAllowActions(this.model.status);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    toggleStatus(): void {
        this.blockUI.start();
        this.unitService.toggleStatus(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.toastr.success(`Unit has been ${(this.model.status === 2 ? 'deactivated' : 'activated')} successfully.`);
                this.model.status = this.model.status === 3 ? 2 : 3;
                this.updateConfigureAllowActions(this.model.status);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    challengeDelete(): void {
        this.unitChallengeDeleteModal.openUnitDeleteConfirmationModal(this.model.id, this.model.name);
    }

    delete(): void {
        this.blockUI.start();
        this.unitService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.router.navigate(['/facility/detail', this.model.companyId, this.model.facilityId]);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Unit has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    updateShowActionColumn() {
        this.showActionColumn
            = this.roleMatrix.canEditUnit || this.roleMatrix.canChangeStatusOfUnit || this.roleMatrix.canDeleteUnit;
    }

    updateConfigureAllowActions(status) {
        if (status === 3) {
            this.roleMatrix.configureAllowActionsForUnit();
            this.updateShowActionColumn();
        }
    }
}
