<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
            <div class="col-12" *ngIf='model.actionType === 1'>
                <p class="kt-widget3__text">
                    Unit <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    has been added to facility <a [routerLink]="model.parentEntityLink"> {{model.parentEntityValue}}
                    </a>
                </p>
            </div>
            <div class="col-12 m-l-2" *ngIf='model.actionType === 2'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Name'">
                    Unit <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    details have been updated by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Status' && model.newValue === 'Inactive'">
                    Unit <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    from facility
                    <a [routerLink]="model.parentEntityLink"> {{model.parentEntityValue}}</a>
                    has been deactivated by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Status' && model.newValue === 'Active'">
                    Unit <a [routerLink]="model.entityLink"> {{model.entityValue}} </a>
                    from facility
                    <a [routerLink]="model.parentEntityLink"> {{model.parentEntityValue}}</a>
                    has been activated by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === 3'>
                <p class="kt-widget3__text">
                    {{model.entityValue}} has been deleted by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
            </div>
        </div>
    </div>
</div>