import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Chart } from 'angular-highcharts';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OfflineKioskStatusChartItemModel, OfflineKioskStatusChartModel } from '../../../models';
import { KioskService, ListenerService, MasterService } from '../../../services';
import { AppUtils } from '../../../helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-offline-kiosk-status',
    templateUrl: './dashboard.offline.kiosk.status.component.html'
})
export class DashboardOfflineKioskStatusComponent implements AfterViewInit, OnDestroy {
    @Input() companyId: number;
    @BlockUI('container-blockui-tiles-kiosk-offline-status') blockUI: NgBlockUI;

    chart: Chart;
    selectedMonth: number;
    seriesData = new Array<Array<number>>();
    series2Data = new Array<Array<number>>();
    months = new Array<any>();

    showPersonalData: boolean;

    subscriptions: Subscription[] = [];

    constructor(private toastr: ToastrService,
        private kioskService: KioskService,
        private listenerService: ListenerService,
        private masterService: MasterService,
        private appUtils: AppUtils,
        private router: Router) {
        this.months = this.appUtils.getMonthList();
        this.selectedMonth = this.months[this.months.length - 1].id;

        let subscription = this.masterService.DashboardShowPersonnalData$.subscribe(showPersonalData => {
            this.showPersonalData = showPersonalData;
            this.loadSeriesData();
        });
        this.subscriptions.push(subscription);

        subscription = this.listenerService
            .listenSignalRevent
            .subscribe(() => {
                this.loadSeriesData();
            });
        this.subscriptions.push(subscription);
    }

    ngAfterViewInit() {
        this.loadSeriesData();
    }

    initChart() {
        const chart = new Chart({
            chart: {
                type: 'line',
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: 'Date'
                }
            },
            yAxis: {
                title: {
                    text: 'Offline Count'
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: [{
                type: 'line',
                tooltip: {
                    valueDecimals: 0
                },
                name: 'Offline Count Kiosk',
                data: this.seriesData
            },
            {
                type: 'line',
                tooltip: {
                    valueDecimals: 0
                },
                name: 'Offline Count Call Machine',
                data: this.series2Data
            }]
        });
        this.chart = chart;
        chart.ref$.subscribe(console.log);
    }

    loadSeriesData() {
        this.blockUI.start();
        this.kioskService.getOfflineKioskLogs(this.companyId, this.selectedMonth, this.showPersonalData)
            .subscribe(
                (data: OfflineKioskStatusChartModel) => {
                    this.seriesData = new Array<Array<number>>();
                    this.series2Data = new Array<Array<number>>();
                    this.blockUI.stop();
                    data.kioskLogs.forEach((item) => {
                        item.date = this.appUtils.getUtcDateObject(item.date);
                        this.seriesData.push([item.date, item.count]);
                    });
                    data.callMachineLogs.forEach((item) => {
                        item.date = this.appUtils.getUtcDateObject(item.date);
                        this.series2Data.push([item.date, item.count]);
                    });
                    if (this.chart) {
                        this.updateChart();
                    } else {
                        this.initChart();
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateChart() {
        const series = this.chart.ref.series[0];
        series.setData(this.seriesData);
        const series2 = this.chart.ref.series[1];
        series2.setData(this.series2Data);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onViewRecordClicked() {
        localStorage.removeItem('offlineKioskLogFilterModel');
        this.masterService.setKioskShowPersonnalData(this.showPersonalData);
        this.router.navigate(['/kiosk/offline-log', this.companyId], { queryParams: { m: this.selectedMonth } });
    }
}
