import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { SelectListItemModel, FacilityEditModel, UserGroupSelectList, UserSelectListItemModel } from '../../../models';

import { FacilityService, UserGroupService, UserService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';
import { isNullOrUndefined } from  '../../../helpers/tools';
import { NgForm } from '@angular/forms';
import { FacilityUserGroupsAssignedUsersModel } from 'src/models/facility/FacilityUserGroupsAssignedUsers.model';

@Component({
    selector: 'app-facility-edit',
    templateUrl: './facility.edit.component.html'
})
export class FacilityEditComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    multiSelectDropdownConfigs = {};

    companyId = -1;

    //clientUsers: SelectListItemModel[] = [];

    userGroups: UserGroupSelectList[] = [];
    selectedUserGroups: UserGroupSelectList[] = [];
    prevMoveOutAssigneeId: any;
    prevMoveInAssigneeId: any;
    prevComplaintAssigneeId: any;
    prevDefaultAssigneeId: string;
    prevPropertyManagerId: string = '';

    model: FacilityEditModel = new FacilityEditModel();
    companyUsers: Array<UserSelectListItemModel> = new Array<UserSelectListItemModel>();


    defaultAssigneeClientUsers: SelectListItemModel[] = [];
    complaintsAssigneeClientUsers: SelectListItemModel[] = [];
    moveOutAssigneeClientUsers: SelectListItemModel[] = [];
    moveInAssigneeClientUsers: SelectListItemModel[] = [];

    currentMoveInAssigneeId: any;
    currentComplaintAssigneeId: any;
    currentMoveOutAssigneeId: any;
    currentDefaultAssigneeId: string;
    currentMoveOutAssigneeName: any;
    currentMoveInAssigneeName: any;
    currentComplaintAssigneeName: any;
    currentDefaultAssigneeName: string;
    currrentPropertyManagerId: string;
    currrentPropertyManagerName: string;
    currrentPropertyManagerEmail: string;
    currrentPropertyManagerPhone: string;

    isUserGroupChange: boolean = false;
    isUserGroupLoaded: boolean = false;
    isInit = true; 
    
    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private userGroupService: UserGroupService,
        private userService: UserService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canEditFacility) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.model.companyId = this.companyId;
            this.model.id = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'key',
            textField: 'value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit() {
        this.loadUserGroups();
        this.loadFacility();
    }

    loadUserGroups() {
        this.userGroupService.getCompanyUserGroupsSelectItem(this.companyId, true).subscribe(
            data => {
                this.userGroups = data;
                if(!this.isInit){
                    this.setSelectedUserGroups();
                }
                this.isInit = false;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    loadFacility() {
        this.blockUI.start();
        this.facilityService.getForEdit(this.model.id)
            .subscribe(
                (data) => {
                    this.blockUI.stop();

                    Object.assign(this.model, data);

                    this.currentMoveOutAssigneeId = this.model.moveOutAssigneeId;
                    this.currentMoveOutAssigneeName = this.model.moveOutAssigneeName;
                    this.currentMoveInAssigneeId = this.model.moveInAssigneeId;
                    this.currentMoveInAssigneeName = this.model.moveInAssigneeName;
                    this.currentComplaintAssigneeId = this.model.complaintsAssigneeId;
                    this.currentComplaintAssigneeName = this.model.complaintsAssigneeName;
                    this.currentDefaultAssigneeId = this.model.defaultAssigneeId;
                    this.currentDefaultAssigneeName = this.model.defaultAssigneeName;
                    this.currrentPropertyManagerId = this.model.propertyManagerUserId;
                    this.currrentPropertyManagerName = this.model.propertyManagerName; 
                    this.currrentPropertyManagerEmail = this.model.propertyManagerEmail;
                    this.currrentPropertyManagerPhone = this.model.propertyManagerPhone; 

                    if(!this.isInit){
                        this.setSelectedUserGroups();
                    }
                    this.isInit = false;

                    if (!this.model.moveOutAssigneeId) {
                        this.model.moveOutAssigneeId = '';
                    } else {
                        this.prevMoveOutAssigneeId = this.model.moveOutAssigneeId;
                    }

                    if (!this.model.moveInAssigneeId) {
                        this.model.moveInAssigneeId = '';
                    } else {
                        this.prevMoveInAssigneeId = this.model.moveInAssigneeId;
                    }

                    if (!this.model.complaintsAssigneeId) {
                        this.model.complaintsAssigneeId = '';
                    } else {
                        this.prevComplaintAssigneeId = this.model.complaintsAssigneeId;
                    }

                    if (!this.model.defaultAssigneeId) {
                        this.model.defaultAssigneeId = '';
                    } else {
                        this.prevDefaultAssigneeId = this.model.defaultAssigneeId;
                    }

                    if (this.model.status === 3) {
                        this.router.navigate(['/error/404']);
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private setSelectedUserGroups() {
        this.isUserGroupLoaded = true;
        if (!this.userGroups || this.userGroups.length === 0) {
            return;
        }

        if (!this.model.clientUserGroupsAssigned || this.model.clientUserGroupsAssigned.length === 0) {
            this.setUsersFromUserGroups([], false);
            return;
        }

        const tempArr = new Array<UserGroupSelectList>();

        this.model.clientUserGroupsAssigned.map((item) => {
            const clientUserGroup = this.userGroups.find(x => x.key === item);
            if (clientUserGroup) {
                tempArr.push(clientUserGroup);
            }
        });

        this.selectedUserGroups = tempArr;

        this.setUsersFromUserGroups(this.selectedUserGroups.map(x => x.key), false);
    }

    submit(formData: NgForm) {
        if (this.model.propertyManagerUserId == null || this.model.propertyManagerUserId.length == 0) {
            formData.form.controls['propertyManager'].setErrors({ 'required': true });
        } else {
            formData.form.controls['propertyManager'].setErrors(null);
        }
        if (formData.form.invalid) {
            return;
        }
        //Update Redundant Properties
        let user = this.companyUsers.find(x => x.key == this.model.propertyManagerUserId);
        this.model.propertyManagerName = user.value;
        this.model.propertyManagerEmail = user.email;
        this.model.propertyManagerPhone = user.phoneNumber;

        this.model.clientUserGroupsAssigned = [];
        if (this.selectedUserGroups) {
            this.model.clientUserGroupsAssigned = this.selectedUserGroups.map(item => item.key);
        }

        this.blockUI.start();
        this.facilityService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/facility/detail/', this.companyId, this.model.id]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Facility has been updated successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onUserGroupSelectDeSelect() {
        this.isUserGroupChange = true;
        this.setUsersFromUserGroups(this.selectedUserGroups.map(x => x.key), true);
    }

    onUserGroupSelectDeSelectAll(items: SelectListItemModel[]) {
        this.isUserGroupChange = true;
        setTimeout(() => {
            this.setUsersFromUserGroups(this.selectedUserGroups.map(x => x.key), true);
        }, 100);
    }

    setPrevMoveOutAssignee() {
        this.prevMoveOutAssigneeId = this.model.moveOutAssigneeId;
    }

    setPrevMoveInAssignee() {
        this.prevMoveInAssigneeId = this.model.moveInAssigneeId;
    }

    setPrevComplainAssignee() {
        this.prevComplaintAssigneeId = this.model.complaintsAssigneeId;
    }

    setPrevDefaultAssignee() {
        this.prevDefaultAssigneeId = this.model.defaultAssigneeId;
    }

    setUsersFromUserGroups(selectedUserGroupsIds: string[], resetAssignees: boolean) {
        const selectedUsers: SelectListItemModel[] = [];

        if(selectedUserGroupsIds.length <= 0){
            this.moveInAssigneeClientUsers =  isNullOrUndefined(this.model.moveInAssigneeId) || this.model.moveInAssigneeId == '' ? [] : [{key: this.currentMoveInAssigneeId, value: this.currentMoveInAssigneeName} as SelectListItemModel];
            this.moveOutAssigneeClientUsers = isNullOrUndefined(this.model.moveOutAssigneeId) || this.model.moveOutAssigneeId == '' ? [] : [{key: this.currentMoveOutAssigneeId, value: this.currentMoveOutAssigneeName} as SelectListItemModel];
            this.defaultAssigneeClientUsers = isNullOrUndefined(this.model.defaultAssigneeId) || this.model.defaultAssigneeId == '' ? [] : [{key: this.currentDefaultAssigneeId, value: this.currentDefaultAssigneeName} as SelectListItemModel];
            this.complaintsAssigneeClientUsers = isNullOrUndefined(this.model.complaintsAssigneeId) || this.model.complaintsAssigneeId == '' ? [] :[{key: this.currentComplaintAssigneeId, value: this.currentComplaintAssigneeName} as SelectListItemModel];
        }
        else {
            selectedUserGroupsIds.forEach(x => selectedUsers.push(...this.userGroups.find(ug => ug.key === x).users));

            const distinctSelectedUsers: SelectListItemModel[] = [];
            const map = new Map();
            for (const item of selectedUsers) {
                if (!map.has(item.key)) {
                    map.set(item.key, true);
                    distinctSelectedUsers.push({
                        id: null,
                        key: item.key,
                        value: item.value,
                        name: item.name
                    });
                }
            }

            distinctSelectedUsers.sort((a, b) => a.value.localeCompare(b.value));

            //this.clientUsers = distinctSelectedUsers;
            this.moveInAssigneeClientUsers =  [...distinctSelectedUsers];
            if(!isNullOrUndefined(this.currentMoveInAssigneeId) && this.currentMoveInAssigneeId != '' && isNullOrUndefined(distinctSelectedUsers.find(x => x.key === this.currentMoveInAssigneeId))){
                this.moveInAssigneeClientUsers.push({key: this.currentMoveInAssigneeId, value: isNullOrUndefined(this.currentMoveInAssigneeName) ? '' : this.currentMoveInAssigneeName } as SelectListItemModel)
                this.moveInAssigneeClientUsers.sort((a, b) => a.value.localeCompare(b.value));
            }
            this.moveOutAssigneeClientUsers = [...distinctSelectedUsers];
            if(!isNullOrUndefined(this.currentMoveOutAssigneeId) && this.currentMoveOutAssigneeId != '' && isNullOrUndefined(distinctSelectedUsers.find(x => x.key === this.currentMoveOutAssigneeId))){
                this.moveOutAssigneeClientUsers.push({key: this.currentMoveOutAssigneeId, value: isNullOrUndefined(this.currentMoveOutAssigneeName) ? '' : this.currentMoveOutAssigneeName} as SelectListItemModel)
                this.moveOutAssigneeClientUsers.sort((a, b) => a.value.localeCompare(b.value));
            }
            this.defaultAssigneeClientUsers = [...distinctSelectedUsers];
            if(!isNullOrUndefined(this.currentDefaultAssigneeId) && this.currentDefaultAssigneeId != '' && isNullOrUndefined(distinctSelectedUsers.find(x => x.key === this.currentDefaultAssigneeId))){
                this.defaultAssigneeClientUsers.push({key: this.currentDefaultAssigneeId, value: isNullOrUndefined(this.currentDefaultAssigneeName) ? '' : this.currentDefaultAssigneeName} as SelectListItemModel)
                this.defaultAssigneeClientUsers.sort((a, b) => a.value.localeCompare(b.value));
            }
            this.complaintsAssigneeClientUsers = [...distinctSelectedUsers];
            if(!isNullOrUndefined(this.currentComplaintAssigneeId) && this.currentComplaintAssigneeId != '' && isNullOrUndefined(distinctSelectedUsers.find(x => x.key === this.currentComplaintAssigneeId))){
                this.complaintsAssigneeClientUsers.push({key: this.currentComplaintAssigneeId, value: isNullOrUndefined(this.currentComplaintAssigneeName) ? '' : this.currentComplaintAssigneeName} as SelectListItemModel)
                this.complaintsAssigneeClientUsers.sort((a, b) => a.value.localeCompare(b.value));
            }
        }

        let currentMoveOutAssignee = this.moveOutAssigneeClientUsers.find(x => x.key === this.model.moveOutAssigneeId);
        let currentMoveInAssignee = this.moveInAssigneeClientUsers.find(x => x.key === this.model.moveInAssigneeId);
        let currentComplaintsAssignee = this.complaintsAssigneeClientUsers.find(x => x.key === this.model.complaintsAssigneeId);
        let currentDefaultAssignee = this.defaultAssigneeClientUsers.find(x => x.key === this.model.defaultAssigneeId);

        if (isNullOrUndefined(currentMoveOutAssignee)) {
            if (isNullOrUndefined(this.prevMoveOutAssigneeId)) {
                this.model.moveOutAssigneeId = '';
            } else {
                const checkForPrev = this.moveOutAssigneeClientUsers.find(x => x.key === this.prevMoveOutAssigneeId);
                this.model.moveOutAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevMoveOutAssigneeId;
                currentMoveOutAssignee = this.moveOutAssigneeClientUsers.find(x => x.key === this.model.moveOutAssigneeId);
            }
        }
        else{
            this.model.moveOutAssigneeId = '';
            setTimeout(() => {
                this.model.moveOutAssigneeId = currentMoveOutAssignee.key;
            }, 100);  
        }

        if (isNullOrUndefined(currentMoveInAssignee)) {
            if (isNullOrUndefined(this.prevMoveInAssigneeId)) {
                this.model.moveInAssigneeId = '';
            } else {
                const checkForPrev = this.moveInAssigneeClientUsers.find(x => x.key === this.prevMoveInAssigneeId);
                this.model.moveInAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevMoveInAssigneeId;
                currentMoveInAssignee = this.moveInAssigneeClientUsers.find(x => x.key === this.model.moveInAssigneeId);
            }
        }
        else{
            this.model.moveInAssigneeId = '';
            setTimeout(() => {
                this.model.moveInAssigneeId = currentMoveInAssignee.key;
            }, 100);  
        }

        if (isNullOrUndefined(currentComplaintsAssignee)) {
            if (isNullOrUndefined(this.prevComplaintAssigneeId)) {
                this.model.complaintsAssigneeId = '';
            } else {
                const checkForPrev = this.complaintsAssigneeClientUsers.find(x => x.key === this.prevComplaintAssigneeId);
                this.model.complaintsAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevComplaintAssigneeId;
                currentComplaintsAssignee = this.complaintsAssigneeClientUsers.find(x => x.key === this.model.complaintsAssigneeId);
            }
        }
        else{
            this.model.complaintsAssigneeId = '';
            setTimeout(() => {
                this.model.complaintsAssigneeId = currentComplaintsAssignee.key;
            }, 100);  
        }

        if (isNullOrUndefined(currentDefaultAssignee)) {
            if (isNullOrUndefined(this.prevDefaultAssigneeId)) {
                this.model.defaultAssigneeId = '';
            } else {
                const checkForPrev = this.defaultAssigneeClientUsers.find(x => x.key === this.prevDefaultAssigneeId);
                this.model.defaultAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevDefaultAssigneeId;
                currentDefaultAssignee = this.defaultAssigneeClientUsers.find(x => x.key === this.model.defaultAssigneeId);
            }
        }
        else{
            this.model.defaultAssigneeId = '';
            setTimeout(() => {
                this.model.defaultAssigneeId = currentDefaultAssignee.key;
            }, 100);  
        }

        this.loadCompanyUsers();
        
        if(this.isUserGroupChange)
        {
            this.isUserGroupChange = false;
            let facilityInfo: FacilityUserGroupsAssignedUsersModel =
            {
                id: this.model.id,
                companyId: this.model.companyId,
                clientUserGroupsAssigned: this.selectedUserGroups.map(x => x.key),
                propertyManagerUserId: this.model.propertyManagerUserId,
                defaultAssigneeId: isNullOrUndefined(currentDefaultAssignee) ? this.model.defaultAssigneeId : currentDefaultAssignee.key,
                complaintsAssigneeId: isNullOrUndefined(currentComplaintsAssignee) ? this.model.complaintsAssigneeId : currentComplaintsAssignee.key,
                moveOutAssigneeId: isNullOrUndefined(currentMoveOutAssignee) ? this.model.moveOutAssigneeId : currentMoveOutAssignee.key,
                moveInAssigneeId: isNullOrUndefined(currentMoveInAssignee) ? this.model.moveInAssigneeId : currentMoveInAssignee.key
            };
            this.blockUI.start();
            this.facilityService.getFacilityUserGroupsAndUserForEditFacility(facilityInfo)
                .subscribe((data : FacilityUserGroupsAssignedUsersModel) => {
                    if(isNullOrUndefined(data.moveInAssigneeId) || data.moveInAssigneeId != this.model.moveInAssigneeId){
                        this.moveInAssigneeClientUsers = this.moveInAssigneeClientUsers.filter(x => x.key !== this.model.moveInAssigneeId);
                        this.model.moveInAssigneeId = '';
                        this.model.moveInAssigneeName = '';
                    }
                    if(isNullOrUndefined(data.moveOutAssigneeId) || data.moveOutAssigneeId != this.model.moveOutAssigneeId){
                        this.moveOutAssigneeClientUsers = this.moveOutAssigneeClientUsers.filter(x => x.key !== this.model.moveOutAssigneeId);
                        this.model.moveOutAssigneeId = '';
                        this.model.moveOutAssigneeName = '';
                    }
                    if(isNullOrUndefined(data.defaultAssigneeId) || data.defaultAssigneeId != this.model.defaultAssigneeId){
                        this.defaultAssigneeClientUsers = this.defaultAssigneeClientUsers.filter(x => x.key !== this.model.defaultAssigneeId);
                        this.model.defaultAssigneeId = '';
                        this.model.defaultAssigneeName = '';
                    }
                    if(isNullOrUndefined(data.complaintsAssigneeId) || data.complaintsAssigneeId != this.model.complaintsAssigneeId){
                        this.complaintsAssigneeClientUsers = this.complaintsAssigneeClientUsers.filter(x => x.key !== this.model.complaintsAssigneeId);
                        this.model.complaintsAssigneeId = '';
                        this.model.complaintsAssigneeName = '';
                    }
                    if(isNullOrUndefined(data.propertyManagerUserId) || data.propertyManagerUserId != this.model.propertyManagerUserId){
                        this.model.propertyManagerUserId = '';
                    }
                    this.blockUI.stop();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
        }
    }

    loadCompanyUsers() {
        if(!this.isUserGroupLoaded)
            return;
        
        this.blockUI.start();
        this.facilityService.getCompanyUsersOfUserGroups(this.companyId, this.model.id, this.selectedUserGroups.map(x=>x.key))
            .subscribe((data: Array<any>) => {
                this.blockUI.stop();
                if (!data || data.length === 0) {
                    return;
                }
                this.companyUsers = data.map((x) => { return {
                    id: x.userId,
                    name: x.fullName,
                    key: x.userId,
                    value: x.fullName,
                    email: x.email,
                    phoneNumber: x.phoneNumber
                } as UserSelectListItemModel });
                this.setPropertyManager();
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private setPropertyManager(){
        if(!isNullOrUndefined(this.model.propertyManagerUserId) && this.model.propertyManagerUserId != '')
        {
            this.prevPropertyManagerId = this.model.propertyManagerUserId;
        }

        if(this.model.propertyManagerUserId != '' &&
            !this.companyUsers.some(x => x.id == this.model.propertyManagerUserId)){
                if(this.model.propertyManagerUserId == this.currrentPropertyManagerId){
                    this.companyUsers.push({
                        id: this.currrentPropertyManagerId,
                        name: this.currrentPropertyManagerName,
                        key: this.currrentPropertyManagerId,
                        value: this.currrentPropertyManagerName,
                        email: this.currrentPropertyManagerEmail,
                        phoneNumber: this.currrentPropertyManagerPhone
                    } as UserSelectListItemModel);
                    this.companyUsers.sort((a, b) => a.value.localeCompare(b.value));
                }
                else
                    this.model.propertyManagerUserId = '';
        }
        else {
            this.model.propertyManagerUserId = '';
            setTimeout(() => {
                if(this.prevPropertyManagerId != '' &&
                    this.companyUsers.some(x => x.id == this.prevPropertyManagerId)) {
                    this.model.propertyManagerUserId = this.prevPropertyManagerId;
                }
            }, 100);
        }
    }

}
