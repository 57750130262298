import { Component, Input } from '@angular/core';
import { LeftBannerModel } from 'src/models';

@Component({
    selector: 'app-left-banner',
    templateUrl: './left.banner.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})

export class LeftBannerComponent {
    @Input() model: LeftBannerModel;

}
