<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <!-- TODO breadCrumb -->
            <app-breadcrumb-work-order-list-closed [companyId]="companyId">
            </app-breadcrumb-work-order-list-closed>
        </div>
        <!-- <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper d-flex align-items-center" *ngIf="roleMatrix.role === roles.OrgAdmin">
                <label class="fs-md">All Data</label>
                <label class="switch switch-sm ml-2 mr-2">
                    <input type="checkbox" [(ngModel)]="showPersonalData" class="primary"
                        (change)="showPersonalDataChanged()" />
                    <span class="slider slider-sm round"></span>
                </label>
                <label class="fs-md">Facility Filtered Data</label>
            </div>
        </div> -->
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="col-md-12 p-0">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Closed Work Orders
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <a [routerLink]="['/work-order/manage',companyId]"
                                    class="btn btn-brand btn-elevate btn-icon-sm m-r-5">
                                    <em class="la la-table"></em>
                                    Open Work Orders
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui'">
                        <app-work-order-filter-box [companyId]="companyId" [isClosedOnly]="true" [allowBulkChange]="false"
                            [filterModel]="filterModel" (applyFilterEmitter)="doFilter()" #filterBox>
                        </app-work-order-filter-box>
                        <div class="row">
                            <div class="col-12">
                                <table datatable [dtOptions]="dtOptions" data-page="2"
                                    class="table table-hover break-all">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>