<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-change-password></app-breadcrumb-change-password>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Change Password
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && !currentPassword.valid}">
                                <label class="col-md-3">Old Password:*</label>
                                <div class="col-md-9">
                                    <input type="password" name="currentPassword" class="form-control" minlength="8"
                                        maxlength="20" autocomplete="off" [(ngModel)]="model.currentPassword"
                                        #currentPassword="ngModel" appAnythingButWhiteSpace required />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && currentPassword.invalid">
                                        <span *ngIf="currentPassword.errors.required">
                                            Please enter old password
                                        </span>
                                        <span *ngIf="currentPassword.errors.minlength">
                                            Minimum 8 characters are required
                                        </span>
                                        <span *ngIf="newPassword.errors.maxlength">
                                            Maximum 20 characters are allowed
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && !newPassword.valid}">
                                <label class="col-md-3">New Password:*</label>
                                <div class="col-md-9">
                                    <input type="password" name="newPassword" class="form-control" minlength="8"
                                        maxlength="20" autocomplete="off" [(ngModel)]="model.newPassword"
                                        #newPassword="ngModel" appAnythingButWhiteSpace required />
                                    <div class="validation-error-message" *ngIf="f.submitted && newPassword.invalid">
                                        <span *ngIf="newPassword.errors.required">
                                            Please enter new password
                                        </span>
                                        <span *ngIf="newPassword.errors.minlength">
                                            Minimum 8 characters are required
                                        </span>
                                        <span *ngIf="newPassword.errors.maxlength">
                                            Maximum 20 characters are allowed
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && !confirmPassword.valid}">
                                <label class="col-md-3">Confirm Password:*</label>
                                <div class="col-md-9">
                                    <input type="password" name="confirmPassword" class="form-control" minlength="8"
                                        maxlength="20" autocomplete="off" [(ngModel)]="model.confirmPassword"
                                        #confirmPassword="ngModel" appAnythingButWhiteSpace required />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && confirmPassword.invalid">
                                        <span *ngIf="confirmPassword.errors.required">
                                            Please enter password again
                                        </span>
                                        <span *ngIf="confirmPassword.errors.minlength">
                                            Minimum 8 characters are required
                                        </span>
                                        <span *ngIf="confirmPassword.errors.maxlength">
                                            Maximum 20 characters are allowed
                                        </span>
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && confirmPassword.valid && newPassword.valid  && model.newPassword !==  model.confirmPassword">
                                        <span>
                                            New password and confirm password does not match
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-portlet__foot">
                                <div class="kt-form__actions text-right">
                                    <a [routerLink]="[backUrl]" class="btn btn-secondary m-r-5">Cancel</a>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>