import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-kiosk-offline-log',
    templateUrl: './breadcrumb.kiosk.offline.log.component.html'
})

export class BreadcrumbKioskOfflineLogComponent {
    @Input() companyId: number;
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
