import { Component, HostBinding, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { DataTableResponseModel } from '../../../models';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { ListenerService, KioskService, CompanyService, MasterService } from '../../../services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { KioskFilterModel } from 'src/models/kiosk/kiosk.filter.model';
import { KioskFilterBoxComponent } from '../filter-box/kiosk.filter.box.component';

@Component({
    selector: 'app-kiosk-manage',
    templateUrl: './kiosk.manage.component.html'
})
export class KioskManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    @BlockUI('container-blockui-delete-popup') blockUIDeletePopup: NgBlockUI;
    @ViewChild('kioskDeleteConfirmationModal', { static: true }) kioskDeleteConfirmationModal;
    @ViewChild('filterBox') filterBox: KioskFilterBoxComponent;

    modalReferece: NgbModalRef;
    deleteChallengeMessage: string;
    deleteConfirmationMessage: string;
    kioskIdToDelete: number;
    deletingDataIndex: number;

    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    signalREventSubscription: Subscription;
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    showPersonalData: boolean;
    subscriptions: Subscription[] = [];

    companyId = -1;
    filterApplicationTypes: number[] = [];
    filterModel: KioskFilterModel = new KioskFilterModel();

    get roles() {
        return Roles;
    }

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private tostr: ToastrService,
        private modalService: NgbModal,
        private kioskService: KioskService,
        private listenerService: ListenerService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private masterService: MasterService) {

        this.masterService.KioskShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.filterModel.companyId = this.companyId;
        });

        this.route.queryParams.subscribe((params) => {
            if(params['filter']){
                let arr: number[] = Array.from(params['filter']);
                if(arr.length > 0) 
                    this.filterApplicationTypes = arr;
            }
            
        });

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.signalREventSubscription = this.listenerService
            .listenSignalRevent
            .subscribe(() => {
                this.reloadDataTable();
            });

        this.loadFilterModelFromLocal();
    }

    ngOnInit(): void {

        const self = this;
        this.checkCompanyStatus();

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter facilities...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[0, 'asc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                dataTablesParameters.companyId = self.companyId;
                dataTablesParameters.considerOnlyPersonalData = this.showPersonalData;
                //dataTablesParameters.filterApplicationType = this.filterApplicationType == 0 ? null : this.filterApplicationType;
                dataTablesParameters.filterApplicationTypes = this.filterModel.applicationIds;
                dataTablesParameters.filterFacilities = this.filterModel.facilityIds;
                dataTablesParameters.filterKey = this.filterModel.filterKey;

                self.http
                    .post<DataTableResponseModel>(environment.apiBaseUrl + 'kiosk/paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    }, error => {
                        self.appUtils.ProcessErrorResponse(self.tostr, error);
                    });
            },
            columns: [
                {
                    data: 'tag',
                    title: 'Tag',
                    width: '15%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'facilityName',
                    title: 'Facility',
                    width: '15%'
                },
                {
                    data: 'facilityAddress',
                    title: 'Address',
                    width: '25%'
                },
                {
                    data: 'applicationType',
                    title: 'Application Type',
                    width: '20%',
                    className: 'text-center',
                    render: function (data) {
                        let htmlString = '';
                        switch (data) {
                            case 1: htmlString = '<span class="badge">Move-In</span>'; break;
                            case 2: htmlString = '<span class="badge">Signage</span>'; break;
                            case 3: htmlString = '<span class="badge">Move-In/Signage</span>'; break;
                            case 4: htmlString = '<span class="badge">Call Machine</span>'; break;
                        }
                        return htmlString;
                    }
                },
                {
                    data: 'isOnline',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data
                            ? '<span class="badge badge-success">Online</span>'
                            : '<span class="badge badge-danger">Offline</span>';
                    }
                },
                {
                    data: 'wentOfflineAt',
                    title: 'Offline Since',
                    width: '15%',
                    render: function (data) {
                        const htmlString = self.appUtils.getFormattedDateTimeFull(data);
                        return htmlString;
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    className: 'text-center',
                    orderable: false,
                    visible: (self.roleMatrix.canDeleteKiosk),
                    render: function (data, type, row) {
                        const htmlString = `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>`;
                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.challengeDelete(data.id, dataIndex);
                });

                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/kiosk/detail', self.companyId, data.facilityId, data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    reloadDataTable() {
        this.dtInstance.ajax.reload();
    }

    delete(): void {
        this.blockUIDeletePopup.start();
        this.kioskService.delete(this.kioskIdToDelete).subscribe(
            () => {
                this.kioskIdToDelete = null;
                this.blockUIDeletePopup.stop();
                setTimeout(() => {
                    this.closeKioskDeleteConfirmationModal();
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (this.deletingDataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.tostr.success('Kiosk has been deleted successfully.');
            },
            error => {
                this.blockUIDeletePopup.stop();
                setTimeout(() => {
                    this.closeKioskDeleteConfirmationModal();
                });
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    openKioskDeleteConfirmationModal() {
        this.modalReferece = this.modalService.open(this.kioskDeleteConfirmationModal);
    }

    closeKioskDeleteConfirmationModal() {
        this.kioskIdToDelete = null;
        this.deletingDataIndex = null;
        this.deleteChallengeMessage = null;
        this.modalReferece.close();
    }

    challengeDelete(id: number, dataIndex: number): void {
        this.openKioskDeleteConfirmationModal();
        this.kioskIdToDelete = id;
        this.deletingDataIndex = dataIndex;
    }

    ngOnDestroy() {
        this.signalREventSubscription.unsubscribe();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForKiosk();
                }
            }
        );
    }

    showPersonalDataChanged() {
        this.masterService.setKioskShowPersonnalData(this.showPersonalData);
        this.dtInstance.ajax.reload();
    }

    doFilter() {
        localStorage.setItem('kioskFilterModel', JSON.stringify(this.filterModel));
        if (this.dtInstance) {
            this.dtInstance.ajax.reload();
        }
    }

    resetFilter() {
        this.filterModel.facilityIds = [];
        this.filterModel.applicationIds = [];
        this.filterModel.filterKey = null;
        localStorage.setItem('kioskFilterModel', JSON.stringify(this.filterModel));
    }

    loadFilterModelFromLocal() {
        const savedFilterModel = localStorage.getItem('kioskFilterModel');
        if (savedFilterModel) {
            const comapnyId = this.filterModel.companyId;
            this.filterModel = JSON.parse(savedFilterModel);
            if (comapnyId !== this.filterModel.companyId) {
                this.filterModel.companyId = comapnyId;
                localStorage.setItem('kioskFilterModel', JSON.stringify(this.filterModel));
            }

            if(this.filterApplicationTypes.length > 0) {
                this.filterModel.applicationIds = this.filterApplicationTypes.map(x => x.toString());
                this.filterModel.facilityIds = [];
                this.filterModel.filterKey = null;
            }

            let loadFilterIntervalCounter: number = 0;
            let loadFilterInterval = setInterval(() => {
                loadFilterIntervalCounter++;
                if(this.filterBox.facilities.length > 0) {
                      clearInterval(loadFilterInterval);
                      this.filterBox.loadFilter();
                }
                else if(loadFilterIntervalCounter > 10){
                    clearInterval(loadFilterInterval);
                }
              }, 1000);
        }
    }
}
