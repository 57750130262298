<div class="kt-widget24 widget-tile m-b-15" *ngIf="isModelLoaded">
    <div class="kt-widget24__details">
        <div class="kt-widget24__info">
            <h4 class="kt-widget24__title text-center fs-20">
                <a [routerLink]="['/facility/manage', companyId]">Facility</a>
            </h4>
        </div>
    </div>
    <div class="separator m-t-25 m-b-15">
    </div>
    <div class="kt-widget24__action">
        <span class="kt-widget24__change">
            Total Facilities <span class="m-l-10">{{totalFacilities}}</span>
        </span>
        <span class="kt-widget24__number">
            Total Units <span class="m-l-10">{{totalUnits}}</span>
        </span>
    </div>
</div>
