import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyService } from '../../../services';
import { CompanyOwnerEditModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { TimeZone } from 'src/models/timezone.model';

@Component({
    selector: 'app-company-owner-edit',
    templateUrl: './company.owner.edit.component.html'
})
export class CompanyOwnerEditComponent implements OnInit {

    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    roleMatrix: RoleMatrix;

    model: CompanyOwnerEditModel = new CompanyOwnerEditModel();

    timeZones: TimeZone[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canEditOrgSuperAdmin) {
            this.router.navigate(['/error/403']);
            return;
        }

        this.route.params.subscribe(params => this.model.id = params['id']);

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.id.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.companyService.getForEdit(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

        this.loadTimeZones();
    }

    submit() {
        this.blockUI.start();
        this.companyService.editOwner(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        if (this.roleMatrix.role === 'ORGSUPERADMIN'
                            || this.roleMatrix.role === 'ORGADMIN') {
                            this.router.navigate(['/company/detail', this.model.id]);
                        } else {
                            this.router.navigate(['/company/manage']);
                        }
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Company owner has been updated successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
}
