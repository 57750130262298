<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-work-order-detail [companyId]="companyId" [viewType]="viewType">
            </app-breadcrumb-work-order-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-sm-12 col-xl-6" *blockUI="'container-blockui-sub-tasks'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title break-word">
                                {{model.title}}
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar" *ngIf="showActionColumn">
                            <label class="btn btn-sm btn-label-warning-highlighted mb-0 mr-3"
                                *ngIf="daysDelayed > 0 && model.workOrderStatus >= 1 && model.workOrderStatus < 4">
                                {{daysDelayed}} {{daysDelayed === 1 ? 'Day Delayed' : 'Days Delayed'}}
                            </label>
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown">
                                Action
                            </button>
                           
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item"
                                        *ngIf="roleMatrix.canEditWorkOrder && model.workOrderStatus !== 4">
                                        <a [routerLink]="['/work-order/edit',viewType, companyId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="roleMatrix.canEditWorkOrder && isModelLoaded && model.workOrderStatus === 4">
                                        <a href="javascript:;" class="kt-nav__link" (click)="updateWorkOrderStatus(1)">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">
                                                Re-open
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="roleMatrix.canDeleteWorkOrder && isModelLoaded && model.workOrderStatus === 4">
                                        <a href="javascript:;" class="kt-nav__link" (click)="delete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="model.workOrderStatus !== 1 && model.workOrderStatus !== 4">
                                        <a [routerLink]="['/work-order/report-issue',companyId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-envelope"></em>
                                            <span class="kt-nav__link-text">Report Issue</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item"
                                        *ngIf="model.workOrderStatus !== 4 && (isUserCreator || isUserWatcher)">
                                        <a href="javascript:;"
                                            class="kt-nav__link"
                                            (click)="showSetNotificationPreferenceModal()">
                                            <em class="kt-nav__link-icon fa fa-cog"></em>
                                            <span class="kt-nav__link-text">Turn Off Notification</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">ID:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.id}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 button-wrap">
                                <div class="row">
                                    <div class="col-9 text-right">
                                        <app-work-order-status-label [workOrderStatus]="model.workOrderStatus"
                                            *ngIf="model.workOrderStatus === 4">
                                        </app-work-order-status-label>
                                        <div class="btn-group" *ngIf="model.workOrderStatus !== 4">
                                            <button type="button" class="btn btn-sm dropdown-toggle" [ngClass]="{ 'btn-outline-danger' : model.workOrderStatus === 1 , 
                                                'btn-outline-primary' : model.workOrderStatus === 2 , 
                                                'btn-outline-warning' : model.workOrderStatus === 3 , 
                                                'btn-outline-success' : model.workOrderStatus === 4}"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                [disabled]="!roleMatrix.canChangeStatusOfWorkOrder">
                                                <span *ngIf="model.workOrderStatus === 1">Not Started</span>
                                                <span *ngIf="model.workOrderStatus === 2">In Progress</span>
                                                <span *ngIf="model.workOrderStatus === 3">On Hold</span>
                                                <span *ngIf="model.workOrderStatus === 4">Closed</span>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item cursor-pointer"
                                                    (click)="updateWorkOrderStatus(1)"
                                                    *ngIf="model.workOrderStatus !== 1">
                                                    Not Started
                                                </a>
                                                <a class="dropdown-item cursor-pointer"
                                                    (click)="updateWorkOrderStatus(2)"
                                                    *ngIf="model.workOrderStatus !== 2">
                                                    In Progress
                                                </a>
                                                <a class="dropdown-item cursor-pointer" (click)="getOnHoldNote()"
                                                    *ngIf="model.workOrderStatus !== 3">
                                                    On Hold</a>
                                                <a class="dropdown-item cursor-pointer"
                                                    (click)="updateWorkOrderStatus(4)"
                                                    *ngIf="model.workOrderStatus !== 4">
                                                    Closed
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <app-work-order-priority-label [workOrderPriority]="model.workOrderPriority"
                                            *ngIf="model.workOrderStatus === 4"></app-work-order-priority-label>
                                        <div class="btn-group" *ngIf="model.workOrderStatus !== 4">
                                            <button type="button" class="btn btn-sm dropdown-toggle"
                                                [ngClass]="{ 'btn-outline-success' : model.workOrderPriority === 1 , 'btn-outline-warning' : model.workOrderPriority === 2 , 'btn-outline-danger' : model.workOrderPriority === 3}"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                [disabled]="!roleMatrix.canChangeStatusOfWorkOrder">
                                                <span *ngIf="model.workOrderPriority === 1">Low</span>
                                                <span *ngIf="model.workOrderPriority === 2">Medium</span>
                                                <span *ngIf="model.workOrderPriority === 3">High</span>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item cursor-pointer"
                                                    *ngIf="model.workOrderPriority !== 1"
                                                    (click)="updatePriority(1)">Low</a>
                                                <a class="dropdown-item cursor-pointer"
                                                    *ngIf="model.workOrderPriority !== 2"
                                                    (click)="updatePriority(2)">Medium</a>
                                                <a class="dropdown-item cursor-pointer"
                                                    *ngIf="model.workOrderPriority !== 3"
                                                    (click)="updatePriority(3)">High</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Title:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Facility Details</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Facility:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.facilityName}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Unit:</label>
                                    <div class="col-8">
                                       <a *ngIf="(model.isUnitImported == true)" href="{{model.storedgeUnitDetailURL}}" target="_blank" class="kt-portlet__data" style="text-decoration: underline;">{{model.unitName}}</a>
                                       <p *ngIf="(model.isUnitImported != true)" class="kt-portlet__data">{{model.unitName}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="model.storedgeTenantId">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Tenant:</label>
                                    <div class="col-8">
                                       <a href="{{model.storedgeTenantDetailURL}}" target="_blank" class="kt-portlet__data" style="text-decoration: underline;">{{model.tenantAccountNumber}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Description:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data" [innerHTML]="model.description | hyperlink"></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Category:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.workOrderCategoryName}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Due Date:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.dueDate}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="model.isRecurring">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-2 kt-portlet__label">Recurrence:</label>
                                    <div class="col-10">
                                        <p class="kt-portlet__data" [innerHTML]="recurringContent"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Assignee Details</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Assignee:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data" *ngIf="model.assignedToId">{{model.assignedToName}}</p>
                                        <p class="kt-portlet__data" *ngIf="model.assignedToGroupId">{{model.assignedToGroupName}}</p>
                                        <button type="button" class="btn btn-label-success btn-sm btn-bold" *ngIf="model.assignedToId != currentUserId && model.workOrderStatus != 4 && showActionColumn && isUserExistForFacility" (click)="assignToMe()">
                                                Assign To Me
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-6 kt-portlet__label">Created By:</label>
                                    <div class="col-6">
                                        <p class="kt-portlet__data">{{model.createdBy}}</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                        <label class="col-6 kt-portlet__label">Created On:</label>
                                        <div class="col-6">
                                            <p class="kt-portlet__data">{{model.createdOn}}</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label"></label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data"></p>
                                        <p class="kt-portlet__data"></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Follower Details</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-2 kt-portlet__label">Follow up assignee:</label>
                                    <div class="col-10">
                                        <p class="kt-portlet__data">{{model.followUpAssigneeName}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-2 kt-portlet__label">Follow up instruction:</label>
                                    <div class="col-10">
                                        <div class="kt-portlet__data word-wrap dis-inline" [innerHTML]="model.followUpInstruction | hyperlink: 100">
                                        </div>
                                        <a href="javascript:0"
                                            *ngIf="model.followUpInstruction?.length > 100" 
                                            (click)="showInstruction(workOrderInstructionDetailModal, model.followUpInstruction)">see more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Watchers Assigned</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-12 kt-portlet__label">Watchers:</label>
                                    <div class="col-12">
                                        <ul>
                                            <li *ngFor="let watcher of watchers" class="kt-portlet__data">{{watcher.value}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Notifications</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-4 kt-portlet__label">Reminder Interval:</label>
                                    <div class="col-8">
                                        <p class="kt-portlet__data">{{model.reminderInterval}}
                                            <span *ngIf="model.reminderInterval > 1"> days</span>
                                            <span *ngIf="model.reminderInterval == 1"> day</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="kt-font-bold">Photos</span>
                                <div class="kt-divider m-t-5 m-b-10">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" *ngIf="model.photos.length > 0">
                                <ng-image-slider #imageSlider [imagePopup]="true" [showArrow]="model.photos.length > 4"
                                    [images]="imageObject"></ng-image-slider>
                            </div>
                            <div class="col-12 m-t-15 m-b-15" *ngIf="model.photos.length === 0">
                                <p class="text-mute">No photo uploaded</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-6" *ngIf="isModelLoaded">
                <app-manage-sub-tasks #subTaskSection [workOrder]="model" (refresh)="loadWorkOrder()">
                </app-manage-sub-tasks>
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Logs
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <a [routerLink]="['/work-order/activity-log', companyId, model.id]"
                                class="btn btn-label-primary btn-sm btn-bold" *ngIf="model.activityLog">View All
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body" data-scroll="true" data-height="300" data-mobile-height="200">
                        <app-work-order-activity-log-item [activityLogs]="model.activityLogs">
                        </app-work-order-activity-log-item>
                    </div>
                </div>

                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Notes
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold m-r-5"
                                *ngIf="roleMatrix.canEditWorkOrder" (click)="openWorkOrderNoteModal()">
                                Add Note
                            </button>
                            <a [routerLink]="['/work-order/note', companyId, model.id]"
                                class="btn btn-label-primary btn-sm btn-bold" *ngIf="model.note">
                                View All
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body" data-scroll="true" data-height="300" data-mobile-height="200">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of model.notes; let $index=index">
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            <div class="word-wrap dis-inline" [innerHTML]="item.note | hyperlink: 100">
                                            </div>
                                            <a href="javascript:0"
                                                    *ngIf="item.note.length > 100" 
                                                    (click)="showNote(workOrderNoteDetailModal, item.note)">see more</a>
                                        </div>
                                        <p class="badge badge-light m-b-5">
                                            <span>{{item.createdByName}}</span>
                                            ({{item.createdOn}})
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <ng-template ngFor let-readReceipt [ngForOf]="item.userReadReceipts">
                                            <p class="badge badge-secondary m-b-5 m-r-2" *ngIf="readReceipt.id !== currentUserId">
                                                {{readReceipt.fullName}}
                                            </p>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row" *ngIf="(model.notes.length - 1) > $index">
                                    <div class="col-12">
                                        <div class="kt-divider m-t-10 m-b-10">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="!model.note">No note found</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #workOrderNoteDetailModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Note</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p style="word-wrap:break-word;" [innerHTML]="note | hyperlink"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Close</button>
      </div>
</ng-template>

<ng-template #workOrderInstructionDetailModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Instruction</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p style="word-wrap:break-word;" [innerHTML]="instruction | hyperlink"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Close</button>
      </div>
</ng-template>

<app-work-order-note-add #addWorkOrderNote [workOrderId]="model.id" (reloadNotes)="loadWorkOrder(true)"
    (updateWorkOrderStatus)="updateWorkOrderStatus(3)">
</app-work-order-note-add>
<app-set-notification-preference #setNotificationPreference
    [isCreator]="isUserCreator"
    [workOrder]="model">
</app-set-notification-preference>

