import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'defaultIfEmpty' })
export class DefaultIfEmpty implements PipeTransform {
    transform(value: string) {
        return value ? value : 'N/A';
    }
}


@Pipe({
    name: 'maxStringLength',
    pure: true
})
export class MaxStringLengthPipe implements PipeTransform {
    transform(str: string, maxLength: number) {
        return str && str.length > maxLength
            ? `${str.substr(0, maxLength - 1)}...`
            : str;
    }
}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: string, limitChars: number): SafeHtml {
    value = value.split('\n').join('<br/>');
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

}