import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { QuickBooksModel } from '../../../models';


@Component({
    selector: 'app-quick-configuration',
    templateUrl: './quick.configuration.component.html'
})

export class QuickConfigurationComponent implements OnInit {
    @BlockUI('container-blockui-configuration') blockUI: NgBlockUI;
    model:QuickBooksModel=new QuickBooksModel();
    showActionColumn = true;
    
    constructor(private toastr: ToastrService,
        private kpiReportService: KpiReportService,
        private route: ActivatedRoute,
        private appUtils: AppUtils) { 


            this.route.params.subscribe((params) => {
                this.model.internalCompanyId = params['companyId'];
            });       
        }

    isHiddenQuickBooks:boolean=true;

    ngOnInit() {
        this.loadQuickBooksConfigure();
    }

    loadQuickBooksConfigure(){
        this.blockUI.start();
        this.kpiReportService.getQuickBooksConfigure(this.model.internalCompanyId)
            .subscribe(
                (data: QuickBooksModel) => {
                    this.blockUI.stop();
                    if (!data) {
                        return;
                    }
                    this.model.clientId = data.clientId;
                    this.model.secretKey = data.secretKey;
                    this.model.quickBooksConfigured = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        this.blockUI.start();
        this.kpiReportService.addQuickbooksconfigure(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success("Data Enter Successfully...!!!");
                    this.model.quickBooksConfigured = true;
                },
               
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}