<div class="kt-widget24 widget-tile m-b-15" *ngIf="isModelLoaded">
    <div class="kt-widget24__details">
        <div class="kt-widget24__info">
            <h4 class="kt-widget24__title text-center fs-20">
                <a [routerLink]="['/vendor/manage', companyId]">Vendors Associated</a>
            </h4>
        </div>
    </div>
    <div class="separator m-t-25 m-b-15">
    </div>
    <div class="kt-widget24__action">
        <span class="kt-widget24__change">
            Total
        </span>
        <span class="kt-widget24__number">
            {{totalVendors}}
        </span>
    </div>
</div>