import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[appDateTimeMustBeGreaterThan]',
    providers: [{ provide: NG_VALIDATORS, useExisting: AppDateTimeMustBeGreaterThanDirective, multi: true }]
})
export class AppDateTimeMustBeGreaterThanDirective implements Validator {
    @Input('appDateTimeMustBeGreaterThan')
    appDateTimeMustBeGreaterThan: string[] = [];

    validate(formGroup: FormGroup): ValidationErrors {
        return MustBeGreaterThan(this.appDateTimeMustBeGreaterThan[0], this.appDateTimeMustBeGreaterThan[1], this.appDateTimeMustBeGreaterThan[2], this.appDateTimeMustBeGreaterThan[3])(formGroup);
    }
}

export function MustBeGreaterThan(controlDate1: string, controlTime1: string, controlDate2: string, controlTime2: string): ValidatorFn {
    return (formGroup: FormGroup) => {
        const controlDate = formGroup.controls[controlDate1];
        const controlTime = formGroup.controls[controlTime1];
        const matchingControlDate = formGroup.controls[controlDate2];
        const matchingControlTime = formGroup.controls[controlTime2];

        // return null if controls haven't initialised yet
        if (!controlDate || !controlTime || !matchingControlDate || !matchingControlTime || 
            isNaN(controlDate.value) || isNaN(matchingControlDate.value)) {
          return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (matchingControlDate.errors && !matchingControlDate.errors.mustBeGreaterThan) {
            return null;
        }
        // set error on matchingControl if validation fails
        let today = new Date();
        let date1 = new Date(`01/${controlDate.value}/2000 ${controlTime.value}`);
        let date2 = new Date(`01/${matchingControlDate.value}/2000 ${matchingControlTime.value}`);
        if (date1 > date2) {
            matchingControlDate.setErrors(null);
        } else {
            matchingControlDate.setErrors({ mustBeGreaterThan: true });
        }
    };
}
