import { Component, HostBinding, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataTableResponseModel, KioskOfflineTimestampModel } from '../../../models';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { ListenerService, MasterService } from '../../../services';
import { take } from 'rxjs/operators';
import { KioskFilterBoxComponent } from '../filter-box/kiosk.filter.box.component';
import { KioskFilterModel } from 'src/models/kiosk/kiosk.filter.model';
import {Location, LocationStrategy} from '@angular/common';

declare var appConfig: any;

@Component({
    selector: 'app-kiosk-offline-log',
    templateUrl: './kiosk.offline.log.component.html'
})
export class KioskOfflineLogComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('kioskTimestamp', { static: true }) kioskTimestamp: any;
    @ViewChild('filterBox') filterBox: KioskFilterBoxComponent;
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    signalREventSubscription: Subscription;
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    showPersonalData: boolean;

    companyId = -1;
    selectedMonth: number;
    months = new Array<any>();
    selectedKioskTimestamps: Array<KioskOfflineTimestampModel>;
    filterModel: KioskFilterModel = new KioskFilterModel();

    get roles() {
        return Roles;
    }

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private tostr: ToastrService,
        private listenerService: ListenerService,
        private appUtils: AppUtils,
        private masterService: MasterService,
        private location: Location,
        private locationStrategy: LocationStrategy) {

        this.masterService.KioskShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => {
                this.showPersonalData = showPersonalData;
            });

        this.months = this.appUtils.getMonthList();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.filterModel.companyId = this.companyId;
        });

        this.route.queryParams.subscribe((params) => {
            if (params['m']) {
                this.selectedMonth = params['m'];
            } else {
                this.selectedMonth = this.months[this.months.length - 1].id;
            }
        });

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.signalREventSubscription = this.listenerService
            .listenSignalRevent
            .subscribe(() => {
                this.reloadDataTable();
            });

        this.loadFilterModelFromLocal();
    }

    ngOnInit(): void {

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter facilities...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[1, 'desc']],
            stateSave: false,
            ajax: (dataTablesParameters: any, callback) => {

                dataTablesParameters.companyId = self.companyId;
                dataTablesParameters.month = self.selectedMonth;
                dataTablesParameters.considerOnlyPersonalData = this.showPersonalData;
                dataTablesParameters.filterApplicationTypes = this.filterModel.applicationIds;
                dataTablesParameters.filterFacilities = this.filterModel.facilityIds;
                dataTablesParameters.filterKey = this.filterModel.filterKey;

                self.http
                    .post<DataTableResponseModel>(environment.apiBaseUrl + 'kiosk/offline-paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        setTimeout(() => {
                            appConfig.initKTDefaults();
                        }, 1000);
                    }, error => {
                        self.appUtils.ProcessErrorResponse(self.tostr, error);
                    });
            },
            columns: [
                {
                    data: 'tag',
                    title: 'Tag',
                    width: '15%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'facilityName',
                    title: 'Facility',
                    width: '15%'
                },
                {
                    data: 'facilityAddress',
                    title: 'Address',
                    width: '20%'
                },
                {
                    data: 'applicationType',
                    title: 'Application Type',
                    width: '20%',
                    className: 'text-center',
                    render: function (data) {
                        let htmlString = '';
                        switch (data) {
                            case 1: htmlString = '<span class="badge">Move-In</span>'; break;
                            case 2: htmlString = '<span class="badge">Signage</span>'; break;
                            case 3: htmlString = '<span class="badge">Move-In/Signage</span>'; break;
                            case 4: htmlString = '<span class="badge">Call Machine</span>'; break;
                        }
                        return htmlString;
                    }
                },
                {
                    data: 'frequency',
                    title: 'Frequency',
                    className: 'text-right',
                    width: '10%'
                },
                {
                    data: null,
                    title: 'Actions',
                    width: '20%',
                    orderable: false,
                    className: 'text-right',
                    render: function (data, type, row, meta) {
                        const htmlString = '<span class="badge badge-success" style="cursor: pointer;"'
                            + 'action-type="view-timestamp">Timestamp</span>';
                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/kiosk/detail', self.companyId, data.facilityId, data.id]);
                });

                const timestampElem = $(row).find('[action-type = view-timestamp]');
                $(timestampElem).on('click', function () {
                    self.openKioskTimestampModal(data.offlineTimestamps);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    reloadDataTable() {
        this.router.navigate([], {
            queryParams: {m: this.selectedMonth},
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
        //this.dtInstance.ajax.reload();
    }

    ngOnDestroy() {
        this.signalREventSubscription.unsubscribe();
        this.blockUI.stop();
    }

    openKioskTimestampModal(timestamps: Array<KioskOfflineTimestampModel>) {
        this.selectedKioskTimestamps = timestamps;
        setTimeout(() => {
            this.kioskTimestamp.openTimestampModal();
        }, 100);
    }

    showPersonalDataChanged() {
        this.masterService.setKioskShowPersonnalData(this.showPersonalData);
        this.dtInstance.ajax.reload();
    }

    doFilter() {
        localStorage.setItem('offlineKioskLogFilterModel', JSON.stringify(this.filterModel));
        if (this.dtInstance) {
            this.dtInstance.ajax.reload();
        }
    }

    resetFilter() {
        this.filterModel.facilityIds = [];
        this.filterModel.applicationIds = [];
        this.filterModel.filterKey = null;
        localStorage.setItem('offlineKioskLogFilterModel', JSON.stringify(this.filterModel));
    }

    loadFilterModelFromLocal() {
        const savedFilterModel = localStorage.getItem('offlineKioskLogFilterModel');
        if (savedFilterModel) {
            const comapnyId = this.filterModel.companyId;
            this.filterModel = JSON.parse(savedFilterModel);
            if (comapnyId !== this.filterModel.companyId) {
                this.filterModel.companyId = comapnyId;
                localStorage.setItem('offlineKioskLogFilterModel', JSON.stringify(this.filterModel));
            }

            let loadFilterIntervalCounter: number = 0;
            let loadFilterInterval = setInterval(() => {
                loadFilterIntervalCounter++;
                if(this.filterBox.facilities.length > 0) {
                      clearInterval(loadFilterInterval);
                      this.filterBox.loadFilter();
                }
                else if(loadFilterIntervalCounter > 10){
                    clearInterval(loadFilterInterval);
                }
              }, 1000);
        }
    }
}
