import { Component, ViewChild, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, WorkOrderFilterModel, WorkOrderSelectedListItemModel } from '../../../../models';
import { AppUtils } from '../../../../helpers';
import { WorkOrderFilterBoxComponent } from '../../filter-box/work.order.filter.box.component';

@Component({
  selector: 'app-select-bulk-work-orders',
  templateUrl: './select-bulk-work-orders.component.html',
  styleUrls: ['./select-bulk-work-orders.component.css']
})
export class SelectBulkWorkOrdersComponent implements OnInit, AfterViewInit {

  @Input()
  companyId = -1;

  @Input()
  viewType = '1';

  @Input()
  selectedWorkOrderIds: Set<number>

  @Output('performBulkOperation')
  performBulkOperationEvent: EventEmitter<Set<number>> = new EventEmitter();

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  
  @ViewChild('filterBox')
  filterBox: WorkOrderFilterBoxComponent;
  
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  filterModel: WorkOrderFilterModel = new WorkOrderFilterModel();
  queryParamCategory: string;

  isCheckAllCheckboxChecked: boolean = false;
  workOrders: WorkOrderSelectedListItemModel[] = [];
  totalFilteredWorkOrdersCount: number = 0;

  constructor(private http: HttpClient,
      private toastr: ToastrService,
      public appUtils: AppUtils) {
    this.selectedWorkOrderIds = new Set<number>();
    this.loadFilterModelFromLocal();
  }

  ngOnInit() {
    this.filterModel.companyId = this.companyId;
    this.initDataTable();
  }

  ngAfterViewInit() {
      this.datatableElement.dtInstance
          .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
  }

  doFilter(isManualTrigger: boolean) {
    if (isManualTrigger) {
      this.selectedWorkOrderIds.clear();
    }

    localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
    if (this.dtInstance) {
        this.dtInstance.ajax.reload();
    }
  }

  onWorkOrderChecked(workOrder: WorkOrderSelectedListItemModel, $event: Event) {
    workOrder.selected = ($event.target as HTMLInputElement).checked;
    
    if (workOrder.selected) {
      this.selectedWorkOrderIds.add(workOrder.id);
    } else {
      this.selectedWorkOrderIds.delete(workOrder.id);
    }
  }

  checkUncheckAllWorkOrders($event: Event) {
    if (($event.target as HTMLInputElement).checked) {
      this.workOrders.forEach(w => {
        w.selected = true;
        this.selectedWorkOrderIds.add(w.id);
      });
    } else {
      this.workOrders.forEach(w => {
        w.selected = false;
        this.selectedWorkOrderIds.delete(w.id);
      });
    }
  }

  performBulkOperation() {
    this.performBulkOperationEvent.emit(this.selectedWorkOrderIds);
  }

  private loadFilterModelFromLocal() {
      const savedFilterModel = localStorage.getItem('filterModel');
      if (!savedFilterModel) {
        return;
      }

      const comapnyId = this.filterModel.companyId;
      this.filterModel = JSON.parse(savedFilterModel);
      if (comapnyId !== this.filterModel.companyId) {
          this.filterModel.companyId = comapnyId;
          localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
      }

        let loadFilterIntervalCounter: number = 0;
        let loadFilterInterval = setInterval(() => {
          loadFilterIntervalCounter++;
          if (this.filterBox.facilities.length > 0) {
            clearInterval(loadFilterInterval);
            this.filterBox.onFacitlityChanged(false, false);
          }
          else if (loadFilterIntervalCounter > 10) {
            clearInterval(loadFilterInterval);
          }
        }, 1000);
  }

  private initDataTable() {
    const self = this;

      this.dtOptions = {
          dom: '<"top">rt<"bottom"lip><"clear">',
          serverSide: true,
          processing: true,
          language: {
              infoFiltered: '',
              loadingRecords: '&nbsp;',
              processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
              searchPlaceholder: 'Filter Work Orders...',
              paginate: {
                  first: '<i class="fa fa-angle-double-left">',
                  last: '<i class="fa fa-angle-double-right">',
                  previous: '<i class="fa fa-angle-left">',
                  next: '<i class="fa fa-angle-right">'
              }
          },
          search: { search: null },
          searching: false,
          displayStart: 0,
          paging: true,
          pagingType: 'full_numbers',
          pageLength: 50,
          lengthMenu: [50],
          order: [[4, 'desc']],
          stateSave: true,
          stateLoaded: function(settings: any, data: any) {
            settings.iInitDisplayStart = 0;
          },
          ajax: (dataTablesParameters: any, callback) => {
            dataTablesParameters.companyId = self.filterModel.companyId;
            dataTablesParameters.statusIds = self.filterModel.statusIds;
            dataTablesParameters.priorityIds = self.filterModel.priorityIds;
            dataTablesParameters.facilityIds = self.filterModel.facilityIds;
            dataTablesParameters.unitIds = self.filterModel.unitIds;
            dataTablesParameters.assigneeIds = self.filterModel.assigneeIds;
            dataTablesParameters.creatorIds = self.filterModel.creatorIds;
            dataTablesParameters.workOrderCategoryIds = self.filterModel.workOrderCategoryIds;
            dataTablesParameters.filterKey = self.filterModel.filterKey;
            dataTablesParameters.considerOnlyPersonalData = false;

            self.http.post<DataTableResponseModel>
                (environment.apiBaseUrl + 'workOrder/paged-result', dataTablesParameters, {})
                .subscribe(resp => {
                  this.workOrders = resp.data as WorkOrderSelectedListItemModel[];

                  let isAllWoChecked = true;
                  this.selectedWorkOrderIds.forEach((id) => {
                    const workOrder = this.workOrders.find(x => x.id === id);
                    if (workOrder) {
                      workOrder.selected = true;
                    } else {
                      isAllWoChecked = false;
                    }
                  });

                  this.isCheckAllCheckboxChecked = isAllWoChecked && this.selectedWorkOrderIds.size > 0 &&
                    this.selectedWorkOrderIds.size >= this.workOrders.length;

                  this.totalFilteredWorkOrdersCount = resp.recordsFiltered;

                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: []
                  });
                }, error => {
                  self.appUtils.ProcessErrorResponse(self.toastr, error);
                });
          },
          columns: [
            {
              data: 'selected',
              width: '5%',
              orderable: false
            },
            {
                data: 'id',
                width: '7%',
            },
            {
                data: 'title',
                width: '27%',
            },
            {
                data: 'assignedToName',
                width: '17%'
            },
            {
                data: 'createdOn',
                width: '16%',
            },
            {
                data: 'workOrderStatus',
                width: '15%',
            },
            {
                data: 'workOrderPriority',
                width: '13%',
            }
          ]
      };
  }
}
