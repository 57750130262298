import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Configuration } from "src/models/config/configuration.model";

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    constructor(private http: HttpClient) {
    }

    fetchConfigs = (companyId: number) => 
        this.http.get<Configuration[]>(`${environment.apiBaseUrl}configs/${companyId}`);

    updateConfigs = (companyId: number, configs: Configuration[]) => 
        this.http.put(`${environment.apiBaseUrl}configs/${companyId}`, configs);
}
