<div class="kt-portlet__head kt-portlet__head--lg">
  <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
          Bulk Change
      </h3>
  </div>
</div>
<form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && performOperation()" #f="ngForm" novalidate>
  <div class="kt-portlet__body" *blockUI="'wo-bulk-operations-container-blockui'">
    <div class="form-group row">
      <span class="col-12">
        Select the value for fields that you want to change in all the selected work orders.
      </span>
    </div>
    <div class="form-group row">
      <label class="col-2 offset-1">Current Assignee(s):</label>
      <div class="col-9">
        <label *ngFor="let assignee of selectedWorkOrderAssigneeNames; let i = index">{{assignee}}<span *ngIf="selectedWorkOrderAssigneeNames.size > i + 1"> ; </span></label>
      </div>
    </div>
    <div class="form-group row"
      [ngClass]="{ 'validation-error' : f.submitted && assignee.invalid}">
      <label class="col-2 offset-1">Assignee:</label>
      <div class="col-3">
        <select class="form-control" name="assignee" [(ngModel)]="assigneeId" #assignee="ngModel" required>
            <option value="">[Select]</option>
            <option [value]="user.key" *ngFor="let user of assignees">
              {{user.value}}
            </option>
        </select>
        <div class="validation-error-message" *ngIf="f.submitted && assignee.invalid">
            Assignee is required
        </div>
      </div>
    </div>
  </div>
  <div class="kt-portlet__foot">
    <div class="kt-form__actions text-right">
      <div class="form-group">
        <a [routerLink]="['/work-order/manage', companyId]" class="btn btn-default btn-elevate btn-icon-sm" *ngIf="viewType === '1'">
          Cancel
        </a>
        <a [routerLink]="['/work-order/grid-view', companyId]" class="btn btn-default btn-elevate btn-icon-sm" *ngIf="viewType === '2'">
          Cancel
        </a>
        <button type="button" class="btn btn-warning btn-elevate btn-icon-sm m-l-5" (click)="goBackToWorkOrdersList()">
          <em class="la la-arrow-left"></em> Back to List
        </button>
        <button type="submit" class="btn btn-brand btn-elevate btn-icon-sm m-l-5">
          Apply Changes
        </button>
      </div>
    </div>
  </div>
</form>

<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group col-12">
        <label>
          Action not completed. <b>{{assigneeName}}</b> does not have access to the required user groups. Unless the assignee is added to the respective user groups they won't be able to access the workorders. To add them to the user groups click on "Add and Proceed".
          User Groups are: 
        </label>
        <ul>
          <li *ngFor="let ug of userGroupsToAddAssignee">
            <label><b>{{ug.name}}</b></label>
          </li>
        </ul>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="modal.close()">
      Cancel
    </button>
    <button type="button" class="btn btn-brand btn-elevate btn-icon-sm m-l-5" (click)="addUserToUserGroupsAndPerformBulkOperationOnWorkOrders(modal)">
      Add and Proceed
    </button>
  </div>
</ng-template>
