import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService } from '../../../services';
import { SelectListItemModel, CompanyUserEditModel } from '../../../models';
import { RoleMatrix, AppUtils } from '../../../helpers';
import { TimeZone } from 'src/models/timezone.model';
import { TimeZoneService } from 'src/services/timezone.service';

@Component({
    selector: 'app-company-user-edit',
    templateUrl: './company.user.edit.component.html'
})

export class CompanyUserEditComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model: CompanyUserEditModel = new CompanyUserEditModel();
    filteredUserType: string;
    roles: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    existingRole: string;

    timeZones: TimeZone[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe(params => this.model.id = params['id']);

        this.route.queryParams.subscribe((params) => {
            if (params['companyId']) {
                this.model.companyId = params['companyId'];
            }
            if (params['userType']) {
                this.filteredUserType = params['userType'];
            }
        });

        if (!this.model.companyId
            || !this.filteredUserType
            || ((this.roleMatrix.role === 'ORGSUPERADMIN'
                || this.roleMatrix.role === 'ORGADMIN'
                || this.roleMatrix.role === 'ORGUSER')
                && (this.roleMatrix.companyId !== this.model.companyId.toString()))
            || (this.filteredUserType !== 'admin' && this.filteredUserType !== 'user' && this.filteredUserType !== 'any')) {
            this.router.navigate(['/error/404']);
        }

        if ((this.filteredUserType === 'admin' && !this.roleMatrix.canEditOrgAdmin)
            || (this.filteredUserType === 'user' && !this.roleMatrix.canEditOrgUser)) {
            this.router.navigate(['/error/403']);
        }

        if (this.roleMatrix.canAddOrgAdmin) {
            let role = new SelectListItemModel();
            role.key = 'OrgAdmin';
            role.value = 'Administrator';
            this.roles.push(role);

            role = new SelectListItemModel();
            role.key = 'OrgUser';
            role.value = 'Personnel';
            this.roles.push(role);
        } else {
            const role = new SelectListItemModel();
            role.key = 'OrgUser';
            role.value = 'Personnel';
            this.roles.push(role);
        }
    }

    ngOnInit(): void {
        this.loadUserDetails();
        this.loadTimeZones();
    }

    submit() {
        if(!this.model.enableEmailNotification && !this.model.enablePushNotification) {
            this.toastr.error("Atleast one medium to receive notification should be selected.");
            return;
        }
        
        this.blockUI.start();
        this.userService.editCompanyUser(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        if (this.existingRole !== this.model.role) {
                            switch (this.filteredUserType) {
                                case 'admin':
                                    this.router.navigate(['/company-user/manage-administrator'],
                                        { queryParams: { companyId: this.model.companyId, userType: this.filteredUserType } });
                                    break;
                                case 'user':
                                    this.router.navigate(['/company-user/manage-personnel'],
                                        { queryParams: { companyId: this.model.companyId, userType: this.filteredUserType } });
                                    break;
                                default:
                                    this.router.navigate(['/company-user/manage'],
                                        { queryParams: { companyId: this.model.companyId, userType: this.filteredUserType } });
                                    break;
                            }
                        } else {
                            this.router.navigate(['/company-user/detail', this.model.id],
                                { queryParams: { companyId: this.model.companyId, userType: this.filteredUserType } });
                        }
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('User has been updated successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    private loadUserDetails() {
        this.blockUI.start();
        this.userService.getCompanyUserForEdit(this.model.id).subscribe(
            data => {
                this.blockUI.stop();
                Object.assign(this.model, data);
                this.existingRole = this.model.role;
                if ((this.model.role === 'OrgAdmin' && !this.roleMatrix.canEditOrgAdmin)
                    || (this.model.role === 'OrgUser' && !this.roleMatrix.canEditOrgUser)) {
                    this.router.navigate(['/error/403']);
                }
                if (this.model.status === 3) {
                    this.router.navigate(['/error/404']);
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private loadTimeZones() {
        this.blockUI.start();
        this.timeZoneService.getTimeZones().subscribe(
            timeZones => {
                this.blockUI.stop();
                this.timeZones = timeZones;
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    onEmailNotificationDisabled() {
        if(!this.model.enableEmailNotification) {
            this.model.sendEmailNotification = false;
        }
    }

    onWatchersEmailNotificationEnabled() {
        if(this.model.sendEmailNotification){
            this.model.enableEmailNotification = true;
        }
    }

    onPushNotificationDisabled() {
        if(!this.model.enablePushNotification) {
            this.model.sendPushNotification = false;
        }
    }

    onWatchersPushNotificationEnabled() {
        if(this.model.sendPushNotification){
            this.model.enablePushNotification = true;
        }
    }
}
