export class UserAddGroupModel {
  public name: string;
  public companyId: number;
  public members: Array<string>;
  public lowPriorityWorkOrdersReminderInterval: number;
  public mediumPriorityWorkOrdersReminderInterval: number;
  public highPriorityWorkOrdersReminderInterval: number;

  constructor() {
    this.lowPriorityWorkOrdersReminderInterval = 1;
    this.mediumPriorityWorkOrdersReminderInterval = 1;
    this.highPriorityWorkOrdersReminderInterval = 1;
  }
}