<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-comp-work-order-categories-manage [companyId]="companyId">
            </app-breadcrumb-comp-work-order-categories-manage>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <ngb-tabset type="string" class="custom-tab-set" (tabChange)="onTabChange($event)" [activeId]="activeTab">
                        <ngb-tab id="1" title="Global Categories">
                            <ng-template ngbTabContent>
                                <app-comp-wo-opt-global-category></app-comp-wo-opt-global-category>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="2" title="Local Categories">
                            <ng-template ngbTabContent>
                                <app-comp-wo-category-list #categoryList></app-comp-wo-category-list>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>