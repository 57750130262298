import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { CompanyAddModel, CompanyEditModel, CompanyOwnerEditModel } from '../models';
import { AppSettings } from '../helpers';

@Injectable()
export class CompanyService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    add(model: CompanyAddModel) {
        return this.http.post(environment.apiBaseUrl + 'company/add', model);
    }

    toggleStatus(id: number) {
        return this.http.post(environment.apiBaseUrl + 'company/toggle-status/' + id, null);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'company/delete/' + id, null);
    }

    getForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'company/get-for-edit/' + id);
    }

    edit(model: CompanyEditModel) {
        return this.http.post(environment.apiBaseUrl + 'company/edit', model);
    }

    editOwner(model: CompanyOwnerEditModel) {
        return this.http.post(environment.apiBaseUrl + 'company/edit-owner', model);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'company/get-detail/' + id);
    }

    getAccountOwnerDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'company/get-account-owner-detail/' + id);
    }

    getCompanyStatisticsOfActiveRecords(id: number) {
        return this.http.get(environment.apiBaseUrl + 'company/get-company-statistics-of-active-records/' + id);
    }

    getStatus(id: number) {
        return this.http.get(environment.apiBaseUrl + 'company/get-status/' + id);
    }
}
