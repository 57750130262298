<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-quickbooks-manage></app-breadcrumb-quickbooks-manage>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui-edit'">
        <div class="col-md-12 p-0">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Accounts
                        </h3>
                    </div>
               </div>
               
                <div class="kt-portlet__body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">

                       
                            <div class="row">
                                <div class="col-sm-2">
                                 <h5><label>companyId:</label></h5> 
                                </div>
                                <div>
                                    <h5>{{model.internalCompanyId}}</h5> 
                                </div>
                                
                                <div class="col-sm-2" style="margin-left: 50px;">
                                    <h5><label>Facility ID:</label></h5> 
                                   </div>
                                    <h5>{{model.facilityId}}</h5>
                               
                            </div>
                            <app-quickbooks-preview-accounts></app-quickbooks-preview-accounts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

