import { Component, HostBinding, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { UserAddGroupModel, UserGroupModel } from 'src/models';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { UserGroupService, UserService } from 'src/services';
import { NgForm } from '@angular/forms';
import { SelectListItemModel } from 'src/models';

@Component({
  selector: 'app-user-group-add',
  templateUrl: './user.group.add.component.html'
})
export class UserGroupAddComponent implements OnInit {

  companyId = -1;
  @HostBinding('class.full_width') full_width = true;
  @BlockUI('container-blockui-add-user') blockUI: NgBlockUI;
  roleMatrix: RoleMatrix;
  model: UserAddGroupModel = new UserAddGroupModel();

  multiSelectDropdownConfigs = {};
  clientUsers: Array<SelectListItemModel> = new Array<SelectListItemModel>();
  selectedClients: Array<SelectListItemModel> = new Array<SelectListItemModel>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private appUtils: AppUtils,
    private userService: UserService,
    private userGroupService: UserGroupService) {

    this.roleMatrix = new RoleMatrix();

    if (!this.roleMatrix.canAddUserGroup) {
      this.router.navigate(['/error/403']);
    }

    this.route.params.subscribe((params) => {
      this.companyId = params['companyId'];
    });

    this.multiSelectDropdownConfigs = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  ngOnInit() {

    if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
      this.router.navigate(['/error/404']);
    }

    this.loadClientUsers();
  }

  submit(f: NgForm) {
    this.model.members = new Array<string>();
    if (this.selectedClients && this.selectedClients.length > 0) {
      this.selectedClients.map((item) => {
        this.model.members.push(item.key);
      });
    }
    this.model.companyId = this.companyId;

    this.blockUI.start();
    this.userGroupService.add(this.model)
      .subscribe((data: UserGroupModel) => {
        f.resetForm();
        this.blockUI.stop();
        this.model = new UserAddGroupModel();
        setTimeout(() => {
          this.router.navigate(['/company', this.companyId, 'user-group', data.id, 'detail']);
        }, 10);
        setTimeout(() => {
          this.toastr.success('User Group has been created successfully.');
        }, 500);        
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  loadClientUsers() {
    this.blockUI.start();
    this.userService.getCompanyUsersSelectItem(this.companyId)
      .subscribe((data: Array<any>) => {
        this.blockUI.stop();
        if (!data || data.length === 0) {
          return;
        }
        this.clientUsers = data;
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }
}
