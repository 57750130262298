<div class="flex">
    <div class="kt-name">
        <p>{{model.title}}</p>
        <p class="work-order">Work Order ID : {{model.id}}</p>
    </div>
    <span class="btn btn-bold btn-sm btn-font-sm btn-label btn-label-danger" *ngIf="model.workOrderStatus === 1">
        Not Started
    </span>
    <span class="btn btn-bold btn-sm btn-font-sm  btn-label btn-label-primary" *ngIf="model.workOrderStatus === 2">
        In Progress
    </span>
    <span class="btn btn-bold btn-sm btn-font-sm  btn-label btn-label-warning" *ngIf="model.workOrderStatus === 3">
        On Hold
    </span>
    <span class="btn btn-bold btn-sm btn-font-sm btn-label  btn-label-success" *ngIf="model.workOrderStatus === 4">
        Closed
    </span>
</div>
<div class="flex mt-3">
    <div class="flex w-50">
        <img src="./assets/media/building.png" alt="building">
        <div>
            <p>Facility Name</p>
            <span class="break-word">{{model.facilityName}}</span>
        </div>
    </div>
    <div class="flex w-50">
        <img src="./assets/media/home.png" alt="building">
        <div>
            <p>Unit Name</p>
            <span class="break-word">{{model.unitName}}</span>
        </div>
    </div>
</div>
<hr>
<div class="flex">
    <span class="assign-date">Assigned To: 
        <span *ngIf="model.assignedToId">{{model.assignedToName}}</span>
        <span *ngIf="model.assignedToGroupId">{{model.assignedToGroupName}}</span>
    </span>
    <span class="assign-date"><i class="fa fa-clock-o" aria-hidden="true"></i>
        {{model.createdOn}}
        <span class="data">Date of creation</span>
    </span>
</div>