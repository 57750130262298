<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-kiosk-manage [companyId]="companyId"></app-breadcrumb-kiosk-manage>
        </div>
        <!-- <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper d-flex align-items-center" *ngIf="roleMatrix.role === roles.OrgAdmin">
                <label class="fs-md">All Data</label>
                <label class="switch switch-sm ml-2 mr-2">
                    <input type="checkbox" [(ngModel)]="showPersonalData" class="primary"
                        (change)="showPersonalDataChanged()" />
                    <span class="slider slider-sm round"></span>
                </label>
                <label class="fs-md">Facility Filtered Data</label>
            </div>
        </div> -->
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="col-md-12 p-0">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Kiosks
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <a [routerLink]="['/kiosk/add', companyId]"
                                    class="btn btn-brand btn-elevate btn-icon-sm" *ngIf="roleMatrix.canAddKiosk">
                                    <em class="la la-plus"></em>
                                    Register New Kiosk
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui'">
                        <app-kiosk-filter-box [companyId]="companyId"
                            [filterModel]="filterModel" (applyFilterEmitter)="doFilter()" (resetFilterEmitter)="resetFilter()" #filterBox>
                        </app-kiosk-filter-box>
                        <div class="row">
                            <div class="col-md-12">
                                <table datatable [dtOptions]="dtOptions" data-page="2"
                                    class="table table-hover break-all">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #kioskDeleteConfirmationModal let-modal>
    <div *blockUI="'container-blockui-delete-popup'">
        <div class="modal-header">
            <h5 class="modal-title">Confirm Delete</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeKioskDeleteConfirmationModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group">
                    Deleting the record will de-register this kiosk machine and the applications running on the kiosk
                    machine will get disabled. Are you sure you want to proceed?
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="deleteChallengeMessage" (keyup.enter)="delete()"
                        [ngClass]="{'text-uppercase' : deleteChallengeMessage}" placeholder="Type DELETE to confirm"
                        appAlphaNumericsOnly maxlength="6" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="closeKioskDeleteConfirmationModal()">Cancel</button>
                <button type="button" class="btn btn-primary"
                    [disabled]="(deleteChallengeMessage !== 'DELETE' && deleteChallengeMessage !== 'delete')"
                    (click)="delete()">OK</button>
            </div>
        </div>
    </div>
</ng-template>