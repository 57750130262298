import { SubTasksService } from '@10fed/services/sub-tasks.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { UnitDetailModel } from 'src/models';
import { WoSubTask } from 'src/models/work-order/sub-tasks/work.order.sub.task.model';

@Component({
  selector: 'app-add-sub-task',
  templateUrl: './add-sub-task.component.html',
  styleUrls: ['./add-sub-task.component.css']
})
export class AddSubTaskComponent implements OnInit {

  @Input()
  workOrderId: number;

  @Input()
  workOrderUnitId: number;

  @Input()
  units: UnitDetailModel[];

  @BlockUI('container-blockui-add-subtask') blockUI: NgBlockUI;

  subTask: WoSubTask;

  constructor(public activeModal: NgbActiveModal,
              private subTasksService: SubTasksService,
              private toastr: ToastrService,
              private appUtils: AppUtils) { }

  ngOnInit() {
    this.subTask = new WoSubTask();
    this.subTask.workOrderId = this.workOrderId;
    this.subTask.unitId = this.workOrderUnitId;
  }

  saveSubTask() {
    this.blockUI.start();
    this.subTasksService.addSubTask(this.subTask).subscribe(
      subTaskDto => {
        this.blockUI.stop();
        this.toastr.success('Sub task has been created successfully.');
        this.activeModal.close(subTaskDto);
      },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }
    );
  }
}
