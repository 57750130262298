import { Component, HostBinding, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, SelectListItemModel, VendorFilterModel } from '../../../models';
import { VendorService, CompanyService, MasterService } from '../../../services';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { isNullOrUndefined } from '../../../helpers/tools';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-vendor-manage',
    templateUrl: './vendor.manage.component.html'
})
export class VendorManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    vendorTypes: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    gridState: any;
    gridStateName = 'gridStateVendors';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: VendorFilterModel = new VendorFilterModel();
    showActionColumn = false;
    showPersonalData: boolean;
    subscriptions: Subscription[] = [];

    companyId = -1;

    get roles() {
        return Roles;
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastr: ToastrService,
        private vendorService: VendorService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private masterService: MasterService) {

        this.masterService.VendorShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.filterModel.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        if (this.roleMatrix.canEditVendor
            || this.roleMatrix.canChangeStatusOfVendor
            || this.roleMatrix.canDeleteVendor) {
            this.showActionColumn = true;
        }

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/vendor/detail/') !== -1
                    || event.url.indexOf('/vendor/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
            if (event instanceof NavigationEnd) {
                let gridState: any = localStorage.getItem(this.gridStateName);
                if (!gridState) {
                    return;
                }
                gridState = JSON.parse(gridState);
                this.rowIndex = gridState.start;
                this.pageLength = gridState.length;

                if (gridState.type) {
                    this.filterModel.vendorTypeId = gridState.vendorTypeId;
                }
                if (gridState.filterKey) {
                    this.filterModel.filterKey = gridState.filterKey;
                }
            }
        });
    }

    ngOnInit(): void {

        this.loadVendorTypes();

        const self = this;
        this.checkCompanyStatus();

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter vendors...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[0, 'asc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                } else {
                    dataTablesParameters.companyId = self.filterModel.companyId;
                    dataTablesParameters.vendorTypeId = self.filterModel.vendorTypeId;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                dataTablesParameters.considerOnlyPersonalData = this.showPersonalData;

                self.http.post<DataTableResponseModel>
                    (environment.apiBaseUrl + 'vendor/paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Vendor Name',
                    width: '20%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'type',
                    title: 'Vendor Type',
                    width: '15%'
                },
                {
                    data: 'phone',
                    title: 'Contact Number',
                    width: '18%'
                },
                {
                    data: 'email',
                    title: 'Email',
                    width: '20%'
                },
                {
                    data: 'address',
                    title: 'Location',
                    width: '20%'
                },
                {
                    data: null,
                    title: 'Action',
                    width: '7%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        const htmlString = (self.roleMatrix.canEditVendor
                            ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteVendor
                                ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');
                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/vendor/edit', self.companyId, data.id]);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.delete(data.id, dataIndex);
                });

                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/vendor/detail', self.companyId, data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            },
            stateSaveParams: function (settings, data: any) {
                data.vendorTypeId = self.filterModel.vendorTypeId;
                data.filterKey = self.filterModel.filterKey;
            },
            stateLoadParams: function (settings, data: any) {
                if (data.vendorTypeId) {
                    self.filterModel.vendorTypeId = data.vendorTypeId;
                }
                if (data.filterKey) {
                    self.filterModel.filterKey = data.filterKey;
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    loadVendorTypes() {
        this.vendorService.getTypeSelectItems()
            .subscribe(
                (data: any) => {
                    Object.assign(this.vendorTypes, data);
                },
                error => {
                    console.log('Failed to load vendor types');
                    console.log(error);
                });
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.vendorService.toggleStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(`Vendor has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    delete(id: number, dataIndex: number): void {
        if (!confirm('Are you sure you want to delete the selected vendor?')) {
            return;
        }
        this.blockUI.start();
        this.vendorService.delete(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (dataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.toastr.success('Vendor has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    doFilter() {
        this.dtInstance.ajax.reload();
    }

    resetFilter() {
        this.filterModel.vendorTypeId = '';
        this.filterModel.filterKey = '';
        this.doFilter();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForVendor();
                    this.showActionColumn = this.roleMatrix.canEditVendor
                        || this.roleMatrix.canChangeStatusOfVendor
                        || this.roleMatrix.canDeleteVendor;
                    if (!isNullOrUndefined(this.dtInstance)) {
                        this.dtInstance.column(5).visible(this.showActionColumn);
                    }
                }
            }
        );
    }

    showPersonalDataChanged() {
        this.masterService.setVendorShowPersonnalData(this.showPersonalData);
        this.dtInstance.ajax.reload();
    }
}
