import { Component, HostBinding, ViewChild, ElementRef, OnInit, OnDestroy, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, AppSettings, RoleMatrix } from 'src/helpers';
import { SignageEditModel, ImageCropperModel } from 'src/models';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SignageService, CloudStorageService } from 'src/services';
import { UploadParams, BlobService, UploadConfig } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';
import { isNullOrUndefined } from '../../../helpers/tools';

@Component({
    selector: 'app-signage-edit',
    templateUrl: './signage.edit.component.html'
})

export class SignageEditComponent implements OnInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('signageContentElem') signageContentElem: ElementRef;
    @ViewChild('signageThumbnailElem') signageThumbnailElem: ElementRef;
    @ViewChild('signageImageCropper', { static: true }) signageImageCropper: any;
    @ViewChild('signageContentImageCropper', { static: true }) signageContentImageCropper: any;

    model: SignageEditModel = new SignageEditModel();
    processingDimension: ImageCropperModel = new ImageCropperModel();
    oldOriginalContentFileName = '';

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    content: any;
    contentType: string;
    isContentUploaded = false;
    contentUploadPercent = 0;
    currentContentOriginalFileName: string;
    currentContentFileName: string;
    changingCotentFile = false;

    thumbnail: any;
    isThumbnailElemExist = true;
    isThumbnailUploaded = false;
    thumbnailUploadPercent = 0;
    currentThumbnailOriginalFileName: string;
    currentThumbnailFileName: string;
    changingThumbnailFile = false;
    isThumbnailExistsForChange = false;

    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private cloudStorageService: CloudStorageService,
        private signageService: SignageService,
        private blobService: BlobService,
        private appSettings: AppSettings) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];

            this.model.companyId = this.companyId;
            this.model.facilityId = this.facilityId;
            this.model.kioskId = this.kioskId;
            this.model.id = params['id'];
        });
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.signageService.getForEdit(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();

                this.currentContentOriginalFileName = data.originalContentFileName;
                this.currentContentFileName = data.contentFileName;
                data.originalContentFileName = null;
                data.contentFileName = null;

                this.currentThumbnailOriginalFileName = data.originalThumbnailFileName;
                this.currentThumbnailFileName = data.thumbnailFileName;
                data.originalThumbnailFileName = null;
                data.thumbnailFileName = null;

                Object.assign(this.model, data);

                this.contentType = this.model.contentFileType;

                if (isNullOrUndefined(this.model.thumbnailFileUrl)) {
                    this.isThumbnailExistsForChange = true;
                    this.isThumbnailElemExist = false;
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    changeContentFile() {
        this.contentType = '';
        this.changingCotentFile = true;
    }

    keepExistingContentFile() {
        this.deleteContent();
        this.contentType = this.model.contentFileType;
        this.changingCotentFile = false;
    }

    onContentSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            this.deleteContent();
            return;
        }

        this.content = event.target.files.item(0);
        this.contentType = this.content.type;

        if (this.contentType !== 'video/mp4'
            && this.contentType !== 'application/pdf'
            && this.contentType !== 'image/png'
            && this.contentType !== 'image/jpg'
            && this.contentType !== 'image/jpeg') {
            this.toastr.error('Only MP4, JPEG, PNG and PDF formats are allowed');
            this.content = null;
            this.model.originalContentFileName = null;
            this.signageContentElem.nativeElement.value = null;
            return;
        }

        if (this.contentType === 'image/png'
            || this.contentType === 'image/jpg'
            || this.contentType === 'image/jpeg') {
                this.processingDimension.fileName = this.content.name;
                this.signageContentImageCropper.openImageCropper(event);
        }
    }

    changeThumbnailFile() {
        this.changingThumbnailFile = true;
    }

    keepExistingThumbnailFile() {
        this.deleteThumbnail();
        this.changingThumbnailFile = false;
    }

    onThumbnailSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            this.thumbnail = null;
            return;
        }

        this.thumbnail = event.target.files.item(0);
        const file = event.target.files.item(0);
        const thumbnailType = this.thumbnail.type;

        if (thumbnailType !== 'image/png'
            && thumbnailType !== 'image/jpg'
            && thumbnailType !== 'image/jpeg') {
            this.toastr.error('Only PNG and PDF formats are allowed');
            this.thumbnail = null;
            this.model.originalThumbnailFileName = null;
            this.signageThumbnailElem.nativeElement.value = null;
            return;
        }
        this.model.thumbnailFileName = file.name;
        this.processingDimension.fileName = file.name;
        this.signageImageCropper.openImageCropper(event);
    }

    deleteContent() {
        this.content = null;
        this.contentType = null;
        this.model.originalContentFileName = null;
        this.model.contentFileName = null;
    }

    deleteThumbnail() {
        this.thumbnail = null;
        this.model.originalThumbnailFileName = null;
        this.model.thumbnailFileName = null;
    }

    submit() {
        this.blockUI.start();

        if (this.contentType === 'application/pdf') {
            this.thumbnail = null;
            this.model.originalThumbnailFileName = null;
            this.model.thumbnailFileName = null;
            this.model.thumbnailFileOriginalName = null;
            this.model.thumbnailFileUrl = null;
            this.isThumbnailUploaded = true;
        } else if (this.contentType !== 'video/mp4' && this.contentType !== 'image/png'
        && this.contentType !== 'image/jpg'
        && this.contentType !== 'image/jpeg') {
            this.thumbnail = this.content;
            this.changingThumbnailFile = true;
        }

        if (!this.content && !this.thumbnail) {
            this.updateContent();
            return;
        }

        if (this.content) {
            this.uploadContent();
        }

        if (this.thumbnail) {
            this.uploadThumbnail();
        }
    }

    async uploadContent() {
        this.cloudStorageService.getSasToken()
            .subscribe(
                async (data) => {
                    const CloudConfig: UploadParams = {
                        sas: data.toString(),
                        storageAccount: this.appSettings.AzureAccount,
                        containerName: 'signage'
                    };
                    const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(this.content.name)}`;
                    const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
                    AppSettings.NonBearerUrl = blobUrl;

                    this.config = {
                        baseUrl: `${blobUrl}?`,
                        sasToken: CloudConfig.sas,
                        blockSize: 1024 * 64,
                        file: this.content,
                        complete: () => {
                            this.model.contentFileName = fileName;
                            this.model.contentFileType = this.contentType;
                            this.isContentUploaded = true;
                            this.updateContent();
                        },
                        error: (err) => {
                            console.log(err);
                            this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                            this.blockUI.stop();
                        },
                        progress: (percent) => {
                            this.contentUploadPercent = percent;
                        }
                    };
                    setTimeout(() => {
                        this.blobService.upload(this.config);
                    }, 300);

                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

    }

    uploadThumbnail() {
        this.signageService.uploadThumbnail(this.thumbnail, this.processingDimension)
            .subscribe(
                (e: HttpEvent<any>) => {
                    switch (e.type) {
                        case HttpEventType.UploadProgress:
                            const percentDone = Math.round(100 * e.loaded / e.total);
                            this.contentUploadPercent = percentDone;
                            break;
                        case HttpEventType.Response:
                            this.model.thumbnailFileName = e.body.fileName;
                            this.isThumbnailUploaded = true;
                            this.updateContent();
                            break;
                    }
                },
                (error) => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateContent() {
        if ((this.changingCotentFile && !this.isContentUploaded)
            || (this.changingThumbnailFile && !this.isThumbnailUploaded)) {
            return;
        }

        if (!this.model.contentFileName) {
            this.model.originalContentFileName = this.currentContentOriginalFileName;
            this.model.contentFileName = this.currentContentFileName;
        }

        if (!this.model.thumbnailFileName && this.contentType !== 'application/pdf') {
            this.model.originalThumbnailFileName = this.currentThumbnailOriginalFileName;
            this.model.thumbnailFileName = this.currentThumbnailFileName;
        }

        this.signageService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/signage/manage', this.companyId, this.facilityId, this.kioskId]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Signage content has been updated successfully.');
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    ngOnDestroy() {
        this.blockUI.stop();
        this.deleteContent();
        this.deleteThumbnail();
    }
}
