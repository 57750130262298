import { Component, HostBinding } from '@angular/core';
import { take } from 'rxjs/operators';
import { MasterService } from 'src/services';

import { RoleMatrix, Roles } from '../../helpers';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent {
    @HostBinding('class.full_width') full_width = true;
    roleMatrix: RoleMatrix;

    showPersonalData: boolean;

    isAdmin: boolean;

    constructor(private masterService: MasterService) {
        this.roleMatrix = new RoleMatrix();

        this.isAdmin = this.roleMatrix.role === Roles.OrgAdmin;

        this.masterService.DashboardShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);
    }

    showPersonalDataChanged() {
        this.masterService.setDashboardShowPersonnalData(this.showPersonalData);
    }
}
