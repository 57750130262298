<div class="kt-portlet">
  <div class="kt-portlet__head">
      <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
              Sub Tasks
          </h3>
      </div>
      <div class="kt-portlet__head-toolbar">
        <button class="btn btn-label-primary btn-sm btn-bold" 
            (click)="sortSubTaskByUnit()" *ngIf="isNewSubTaskAdded && subTasks.length > 1">
            Sort by Unit
        </button>
        &nbsp;
        <button class="btn btn-label-primary btn-sm btn-bold"
            (click)="addSubTask()" [disabled]="workOrder.workOrderStatus === WorkOrderStatuses.Completed">
            Add
        </button>
      </div>
  </div>
  <div class="kt-portlet__body" data-scroll="true" data-height="300" data-mobile-height="200"
    *blockUI="'container-blockui'" #subTaskList>
    <div class="row">
        <div class="col-12 m-b-10" *ngFor="let st of subTasks; let $index = index">
            <div class="row">
                <div class="col-1">
                    <label class="kt-checkbox kt-checkbox--brand">
                        <input type="checkbox" id="sendEmailNotification"
                            name="sendEmailNotification"
                            [ngModel]="st.state === WoSubTaskStates.Closed"
                            (click)="toggleSubTaskState(st)"
                            [disabled]="workOrder.workOrderStatus === WorkOrderStatuses.Completed" />
                        <span></span>
                    </label>
                </div>
                <div class="col-8">
                    <p class="word-wrap" [ngClass]="{ 'line-through': st.state === WoSubTaskStates.Closed }">
                        {{st.task}}
                    </p>
                </div>
                <div class="col-2 word-wrap">
                    {{st.unitName}}
                </div>
                <div class="col-1 p-0 pt-1 d-flex justify-content-center">
                    <em class="fa fa-edit cursor-pointer m-r-3"
                        *ngIf="st.state !== WoSubTaskStates.Closed"
                        (click)="editSubTask(st, $index)"></em>
                    <em class="fa fa-trash cursor-pointer"
                        *ngIf="st.state !== WoSubTaskStates.Closed"
                        (click)="deleteSubTask(st.id, $index)"></em>
                </div>
            </div>
            <div class="row" *ngIf="(subTasks.length - 1) > $index">
                <div class="col-12">
                    <div class="kt-divider m-t-10 m-b-10">
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <p *ngIf="!subTasks || subTasks.length === 0">No sub task found</p>      
  </div>
</div>
