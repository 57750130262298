<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-3">
                <div class="form-group facility-dropdown">
                    <ng-multiselect-dropdown name="facilityId" [placeholder]="'[Facility]'" 
                  [(ngModel)]="selectedFacilities" [settings]="multiSelectDropdownConfigs"
                  [data]="facilities">
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                <ng-multiselect-dropdown name="applicationTypes" [placeholder]="'[Application Type]'" 
                  [(ngModel)]="selectedApplicationTypes" [settings]="multiSelectDropdownConfigs"
                  [data]="applicationTypes"> 
                </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group m-b-0">
                    <input type="text" class="form-control" placeholder="Filter Kiosks"
                        [(ngModel)]="filterModel.filterKey" autocomplete="Off" maxlength="50"
                        (keydown.enter)="applyFilter(true)" />
                </div>
            </div>
            <div class="text-right col-3">
                <div class="form-group">
                    <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" (click)="applyFilter(true)">
                        <em class="la la-search"></em> Filter
                    </button>
                    <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="resetFilter(true)">
                        <em class="la la-close"></em> Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

