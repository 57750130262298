<div class="col-md-12">
    <div class="row">
        <div class="col-md-12">
            <app-movein-amenity-add (added)="refreshTableData()"></app-movein-amenity-add>
        </div>
        <div class="col-md-12" *blockUI="'container-blockui-amenity-manage'">
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                    <div class="col-md-12">
                        <table datatable [dtOptions]="dtOptions" data-page="2" class="table table-hover break-all">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>