export class SignageListItemModel {
    public id: number;
    public originalFileName: string;
    public contentFileName: string;
    public contentFileUrl: string;
    public contentFileType: string;
    public thumbnailFileName: string;
    public thumbnailFileUrl: string;
    public kioskId: number;
    public title: string;
    public description: string;
    public internalDescription: string;
    public createdOn: string;
    public displayOrder: number;
}
