import { Component, HostBinding, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { DataTableResponseModel, WorkOrderFilterModel, WorkOrderListItemModel, WorkOrderUpdatePriorityModel } from '../../../models';
import { WorkOrderService, CompanyService, MasterService } from '../../../services';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { WorkOrderFilterBoxComponent } from '../filter-box/work.order.filter.box.component';
import { take } from 'rxjs/operators';

declare var appConfig: any;

@Component({
    selector: 'app-work-order-grid-view',
    templateUrl: './work.order.grid.view.component.html'
})
export class WorkOrderGridViewComponent implements OnInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('filterBox') filterBox: WorkOrderFilterBoxComponent;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    filterModel: WorkOrderFilterModel = new WorkOrderFilterModel();
    model: WorkOrderUpdatePriorityModel = new WorkOrderUpdatePriorityModel();

    dataTableResponseModel: DataTableResponseModel = new DataTableResponseModel();

    workOrders: Array<WorkOrderListItemModel> = new Array<WorkOrderListItemModel>();
    lowPriorityWorkOrders: Array<WorkOrderListItemModel> = new Array<WorkOrderListItemModel>();
    mediumPriorityWorkOrders: Array<WorkOrderListItemModel> = new Array<WorkOrderListItemModel>();
    highPriorityWorkOrders: Array<WorkOrderListItemModel> = new Array<WorkOrderListItemModel>();

    showPersonalData: boolean;

    subscriptions: Subscription[] = [];

    companyId = -1;

    get roles() {
        return Roles;
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private dragulaService: DragulaService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private masterService: MasterService) {

        this.masterService.WorkOrderShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['id'];
            this.filterModel.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        const dragulaSubscription = new Subscription();
        this.subscriptions.push(dragulaSubscription);

        dragulaSubscription.add(this.dragulaService.drop('work_orders')
            .subscribe(({ name, el, target, source, sibling }) => {
                this.updatePriority(source.id, target.id, Number(el.id));
            })
        );

        this.dragulaService.createGroup('work_orders', {
            moves: (el, container, handle, sibling) => {
                const workOrder = this.workOrders.find(x => x.id === Number(el.id));
                if (workOrder.status === 3 || !this.roleMatrix.canEditWorkOrder) {
                    return false;
                }
                if (!workOrder) {
                    return false;
                }
                return workOrder.workOrderStatus !== 4;
            }
        });

    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForWorkOrder();
                }
            }
        );
    }

    loadWorkOrders() {
        this.blockUI.start();
        this.filterModel.sortBy = 'createdOn';
        this.filterModel.sortDir = ' asc';
        this.filterModel.considerOnlyPersonalData = this.showPersonalData;
        this.workOrderService.filter(this.filterModel)
            .subscribe(
                (response: DataTableResponseModel) => {
                    this.blockUI.stop();

                    Object.assign(this.dataTableResponseModel, response);
                    Object.assign(this.workOrders = this.dataTableResponseModel.data);
                    this.workOrders.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDate(item.createdOn);
                    });

                    this.updateWorkOrderPriorityLists();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateWorkOrderPriorityLists() {
        this.lowPriorityWorkOrders = this.workOrders.filter(x => x.workOrderPriority === 1);
        this.mediumPriorityWorkOrders = this.workOrders.filter(x => x.workOrderPriority === 2);
        this.highPriorityWorkOrders = this.workOrders.filter(x => x.workOrderPriority === 3);

        setTimeout(() => {
            appConfig.initKTDefaults();
        }, 1000);
    }

    ngOnInit(): void {
        this.checkCompanyStatus();
        this.loadFilterModelFromLocal();
        this.loadWorkOrders();

        setTimeout(() => {
            appConfig.initGridViewFilter();
        }, 1000);
    }

    doFilter() {
        localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
        this.loadWorkOrders();
    }

    goToDetail(id: any) {
        this.router.navigate(['/work-order/detail', 2, this.companyId, id]);
    }

    updatePriority(sourceElementId: string, targetElementId: string, elementId: number) {
        switch (targetElementId) {
            case 'container-low-priority-work-orders':
                this.model.priority = 1;
                break;
            case 'container-medium-priority-work-orders':
                this.model.priority = 2;
                break;
            case 'container-high-priority-work-orders':
                this.model.priority = 3;
                break;
            default:
                this.toastr.error('Something went wrong. Please try again.');
                break;
        }
        this.model.id = elementId;
        this.blockUI.start();
        this.workOrderService.udpatePriority(this.model)
            .subscribe(
                () => {
                    const workOrder = this.workOrders.find(x => x.id === elementId);
                    workOrder.workOrderPriority = this.model.priority;
                    this.updateWorkOrderPriorityLists();
                    this.blockUI.stop();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    addWorkOrder() {
        this.router.navigate(['/work-order/add', 2, this.companyId]);
    }

    loadFilterModelFromLocal() {
        const savedFilterModel = localStorage.getItem('filterModel');
        if (savedFilterModel) {
            const comapnyId = this.filterModel.companyId;
            this.filterModel = JSON.parse(savedFilterModel);
            if (comapnyId !== this.filterModel.companyId) {
                this.filterModel.companyId = comapnyId;
                localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
            }

            let loadFilterIntervalCounter: number = 0;
            let loadFilterInterval = setInterval(() => {
                loadFilterIntervalCounter++;
                if(this.filterBox.facilities.length > 0) {
                      clearInterval(loadFilterInterval);
                      this.filterBox.onFacitlityChanged(false, false);
                }
                else if(loadFilterIntervalCounter > 10){
                    clearInterval(loadFilterInterval);
                }
              }, 1000);
        }
    }

    goToClosedWorkOrders() {
        this.router.navigate(['/work-order/list-closed', this.companyId]);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        const dragulaGroup = this.dragulaService.find('work_orders');
        if (dragulaGroup) {
            this.dragulaService.destroy('work_orders');
        }
    }

    showPersonalDataChanged() {
        this.masterService.setWorkOrderShowPersonnalData(this.showPersonalData);
    }
}
