<form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit(f)" #f="ngForm" novalidate>
    <div class="kt-portlet__body" *blockUI="'container-blockui-amenity-add'">
        <div class="form-group row justify-content-md-center"
            [ngClass]="{ 'validation-error' : f.submitted && !amenityTypeId.valid}">
            <label class="col-md-2">Select Amenity:*</label>
            <div class="col-md-4">
                <select name="amenityTypeId" class="form-control" [(ngModel)]="model.amenityTypeId"
                    #amenityTypeId="ngModel" required>
                    <option value="">[Select]</option>
                    <option *ngFor="let item of amenityTypes" [value]="item.id">{{ item.name }}</option>
                </select>
                <div class="validation-error-message" *ngIf="f.submitted && !amenityTypeId.valid">
                    Amenity is required
                </div>
            </div>
        </div>
        <div class="form-group row justify-content-md-center "
            [ngClass]="{ 'validation-error' : f.submitted && amenityIcon.invalid}">
            <label class="col-md-2">Upload Icon:*</label>
            <div class="col-md-4">
                <button type="button" class="btn btn-sm btn-outline-brand m-b-5" (click)="amenityIconElem.click()">
                    Choose file
                </button>
                <ngb-progressbar class="m-t-5" type="info" [value]="iconUploadPercent" height="5px"
                    *ngIf="iconUploadPercent > 0 && iconUploadPercent < 100">
                </ngb-progressbar>
                <input type="file" name="amenityIcon" #amenityIcon="ngModel" class="d-none"
                    [(ngModel)]="model.originalIconName" accept="image/png,image/jpg,image/jpeg" #amenityIconElem
                    (change)="onIconSelected($event)" required />
                <div class="validation-error-message" *ngIf="f.submitted && amenityIcon.invalid">
                    <span *ngIf="amenityIcon.errors.required"> Icon is required</span>
                </div>
                <div class="m-t-15" *ngIf="model.originalIconName">
                    <em class="kt-menu__link-icon flaticon-attachment text-overflow"></em>
                    <span class="fs-15 m-l-5 text-overflow">{{model.originalIconName}}</span>
                    <div class="btn btn-xs cursor-pointer" (click)="deleteIcon()">
                        <i class="la la-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row justify-content-md-center">
            <label class="col-md-2">Parking</label>
            <div class="col-md-4">
                <label class="kt-checkbox kt-checkbox--brand">
                    <input type="checkbox" name="isParkingAmenity" [(ngModel)]="model.isParkingAmenity" />
                    <span></span>
                </label>
            </div>
        </div>
        <div class="form-group row justify-content-md-center">
            <div class="col-md-2">
                <button type="submit" class="btn btn-primary">Add</button>
            </div>
        </div>
    </div>
</form>