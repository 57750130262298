import { SubTasksService } from '@10fed/services/sub-tasks.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { UnitDetailModel } from 'src/models';
import { WoSubTaskDto } from 'src/models/work-order/sub-tasks/work.order.sub.task.model';

@Component({
  selector: 'app-edit-sub-task',
  templateUrl: './edit-sub-task.component.html',
  styleUrls: ['./edit-sub-task.component.css']
})
export class EditSubTaskComponent implements OnInit {

  @Input()
  subTask: WoSubTaskDto;

  @Input()
  workOrderUnitId: number;

  @Input()
  units: UnitDetailModel[];


  @BlockUI('container-blockui-edit-subtask') blockUI: NgBlockUI;

  constructor(public activeModal: NgbActiveModal,
              private subTasksService: SubTasksService,
              private toastr: ToastrService,
              private appUtils: AppUtils) { }

  ngOnInit() {
  }

  updateSubTask() {
    this.blockUI.start();
    this.subTasksService.updateSubTask(this.subTask).subscribe(
      subTaskDto => {
        this.blockUI.stop();
        this.toastr.success('Sub task has been updated successfully.');
        this.activeModal.close(subTaskDto);
      },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }
    );
  }
}
