import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountActivateModel, LeftBannerModel } from '../../../models';
import { AccountService } from '../../../services';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-account-activate',
    templateUrl: './account.activate.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})

export class AccountActivateComponent {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    isViewInitiated = false;
    model: AccountActivateModel = new AccountActivateModel();
    leftBannerModel: LeftBannerModel = new LeftBannerModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private accountService: AccountService) {

        if (this.appUtils.isUserAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.route.params.subscribe((params) => {
            this.model.key = params['key'];
            this.model.secret = params['secret'];
        });

        this.checkEmailConfirmationToken();
    }

    checkEmailConfirmationToken() {
        this.accountService.checkEmailConfirmationToken(this.model.key)
            .subscribe(
                (response: string) => {
                    if (response === '') {
                        this.isViewInitiated = true;
                        return;
                    }
                    this.router.navigate(['/account/login']);
                    let errorMessage = '';
                    if (response === 'INVALID_TOKEN') {
                        errorMessage = 'Invalid or expired token';
                    } else if (response === 'ALREADY_ACTIVATED') {
                        errorMessage = 'User account is already activated';
                    }
                    if (errorMessage !== '') {
                        setTimeout(() => {
                            this.toastr.error(errorMessage);
                        }, 500);
                    }
                },
                error => {
                    this.isViewInitiated = true;
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        if (this.model.password !== this.model.confirmPassword) {
            return;
        }
        this.blockUI.start();
        this.accountService.activate(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/account/login']);
                    }, 100);
                    setTimeout(() => {
                        this.toastr.success('Your account has been activated please log in to continue');
                    }, 500);

                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
