import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { VendorNoteModel } from '../../../models';

import { VendorService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-vendor-note',
    templateUrl: './vendor.note.component.html'
})

export class VendorNoteComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @BlockUI('container-blockui-note') noteBlockUI: NgBlockUI;

    companyId = -1;
    vendorId = -1;
    vendorNoteId = -1;
    isModelLoaded = false;
    note: string;
    modalReference: any;
    notes: Array<VendorNoteModel> = new Array<VendorNoteModel>();
    showActionColumn = true;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private vendorService: VendorService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewVendor) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.vendorId = params['id'];

        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.checkVendorStatus();
        this.loadNotes();
    }

    checkVendorStatus() {
        this.vendorService.getStatus(this.vendorId).subscribe(
            data => {
                if (data === 3) {
                    this.showActionColumn = false;
                }
            });
    }

    loadNotes() {
        this.notes = new Array<VendorNoteModel>();
        this.blockUI.start();
        this.vendorService.getNotes(this.vendorId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.notes, data);
                    this.isModelLoaded = true;
                    this.notes.map((item) => {
                        if (this.appUtils.is10FedUser(item.createdByRole)) {
                            item.createdByName = 'Admin';
                        }
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    openVendorNoteModal(modelContent: any) {
        this.modalReference = this.modalService.open(modelContent,
            {
                backdrop: 'static',
                keyboard: false
            });
    }

    closeVendorNoteModal(form: NgForm) {

        this.note = '';
        form.resetForm();
        this.modalReference.close();
    }

    openVendorNoteEditModal(modelContent: any, item: VendorNoteModel) {
        this.vendorNoteId = item.id;
        this.note = item.note;
        this.modalReference = this.modalService.open(modelContent,
            {
                backdrop: 'static',
                keyboard: false
            });
    }

    closeVendorNoteEditModal(form: NgForm) {
        this.vendorNoteId = -1;
        this.note = '';
        form.resetForm();
        this.modalReference.close();
    }

    addNote(form: NgForm) {
        const vendorNote = new VendorNoteModel();
        vendorNote.vendorId = this.vendorId;
        vendorNote.note = this.note;
        this.noteBlockUI.start();
        this.vendorService.addNote(vendorNote)
            .subscribe(
                () => {
                    this.noteBlockUI.stop();
                    this.toastr.success('Note has been added successfully');
                    setTimeout(() => {
                        this.closeVendorNoteModal(form);
                        this.loadNotes();
                    }, 100);
                },
                error => {
                    this.noteBlockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    editNote(form: NgForm) {
        const vendorNote = new VendorNoteModel();
        vendorNote.id = this.vendorNoteId;
        vendorNote.vendorId = this.vendorId;
        vendorNote.note = this.note;
        this.noteBlockUI.start();
        this.vendorService.editNote(vendorNote)
            .subscribe(
                () => {
                    this.noteBlockUI.stop();
                    this.toastr.success('Note has been edit successfully');
                    setTimeout(() => {
                        this.closeVendorNoteEditModal(form);
                        this.loadNotes();
                    }, 100);

                },
                error => {
                    this.noteBlockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }


}
