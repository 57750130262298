export * from './add/user.group.add.component';
export * from './manage/user.group.component';
export * from './detail/user.group.detail.component';
export * from './edit/user.group.edit.component';

export * from './challenge-delete/user.group.challenge.delete.component';
export * from './challenge-deactivate/user.group.challenge.deactivate.component';
export * from './challenge-delete-user/user.group.challenge.delete.user.component';

export * from './breadcrumb/add/breadcrumb.user.group.add.component';
export * from './breadcrumb/edit/breadcrumb.user.group.edit.component';
export * from './breadcrumb/manage/breadcrumb.user.group.manage.component';
export * from './breadcrumb/manage/breadcrumb.user.group.manage.component';
