import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppSettings, AppUtils, JwtInterceptor, AuthGuard, RoutingState } from '../helpers';
import {
    ListenerService, AccountService, UserService, CompanyService, FacilityService,
    UnitService, WorkOrderService, VendorService, NotificationService, ActivityLogService, KioskService,
    SignageService, StoredgeService, MoveInService, CloudStorageService, 
    UserGroupService, MasterService, KpiReportService
} from '../services';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateAdapter, CustomNgbDateParserFormatter } from 'src/services/custom-ngb-datepicker.service';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { loadPreInfoFactory } from 'src/helpers/user-info.resolver';
import { UserInfoSynchronizer } from 'src/synchronizers/user-info.synchronizer';

export const appProviders: Provider[] = [
    AppSettings,
    AppUtils,
    AuthGuard,
    RoutingState,
    ListenerService,
    UserService,
    AccountService,
    CompanyService,
    FacilityService,
    UnitService,
    WorkOrderService,
    VendorService,
    ActivityLogService,
    NotificationService,
    KioskService,
    SignageService,
    StoredgeService,
    MoveInService,
    UserGroupService,
    CloudStorageService,
    KpiReportService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },
    { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    MasterService,
    UserInfoSynchronizer,
    { provide: APP_INITIALIZER, useFactory: loadPreInfoFactory, deps: [UserInfoSynchronizer], multi: true }
];
