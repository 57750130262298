import { Component, HostBinding, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { SelectListItemModel, VendorEditModel, VendorInsurancePolicyDocumentModel } from '../../../models';

import { VendorService, FacilityService, CloudStorageService } from '../../../services';

import { AppUtils, RoleMatrix, AppSettings } from '../../../helpers';
import { UploadConfig, BlobService, UploadParams } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-vendor-edit',
    templateUrl: './vendor.edit.component.html'
})

export class VendorEditComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('vendorInsuranceDocumentAttachment') vendorInsuranceDocumentAttachment: ElementRef;

    companyId = -1;
    isModelLoaded = false;
    vendorTypes: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    multiSelectDropdownConfigs = {};
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedFacilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    model: VendorEditModel = new VendorEditModel();
    deletedDocuments: Array<string> = new Array<string>();
    minDueDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };
    uploadedPercent = 0;
    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private vendorService: VendorService,
        private cloudStorageService: CloudStorageService,
        private appSettings: AppSettings,
        private blobService: BlobService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canEditVendor) {
            this.router.navigate(['/error/403']);

        }
        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.model.companyId = this.companyId;
            this.model.id = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit(): void {
        this.loadVendorTypes();
        this.loadVendor();
        this.loadFacilities();
    }

    loadVendorTypes() {
        this.vendorService.getTypeSelectItems()
            .subscribe(
                (data: any) => {
                    Object.assign(this.vendorTypes, data);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFacilities() {
        this.blockUI.start();
        this.facilityService.getSelectListItems(this.companyId)
            .subscribe((data: any) => {
                this.blockUI.stop();
                this.facilities = data;
                this.updateSelectedFacilities();
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    loadVendor() {
        this.blockUI.start();
        this.vendorService.getDetailForEdit(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.model = data;
                    this.isModelLoaded = true;
                    if (this.model.status === 3) {
                        this.router.navigate(['/error/404']);
                    }
                    this.updateSelectedFacilities();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateSelectedFacilities() {
        if (!this.facilities
            || this.facilities.length === 0
            || !this.model.assignedFacilities
            || this.model.assignedFacilities.length === 0) {
            return;
        }

        const tempArr = new Array<SelectListItemModel>();
        this.facilities.forEach(item => {
            if (this.model.assignedFacilities.find(m => m == +item.id)) {
                tempArr.push(item)
            }
        });
        this.selectedFacilities = tempArr;

        console.log(this.selectedFacilities)
    }

    submit() {
        // console.log(this.selectedFacilities)

        //Add Selected Facilities
        this.model.assignedFacilities = new Array<number>();
        if (this.selectedFacilities && this.selectedFacilities.length > 0) {
            this.model.assignedFacilities = this.selectedFacilities.map(item => +item.id);
        }        

        this.blockUI.start();
        this.vendorService.edit(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    if (this.deletedDocuments.length) {                       
                        this.deletedDocuments.forEach((fileKey) => {                
                            this.vendorService.deleteAttachment(fileKey);
                        });            
                    }
                    setTimeout(() => {
                        this.router.navigate(['/vendor/detail', this.companyId, this.model.id]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Vendor has been updated successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onAttachmentSelect(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }

        // if (this.model.insurancePolicyDocuments.length >= 5) {
        //     this.toastr.error('Maximum 5 documents can be uploaded');   
        //     return;
        // }

        let attachment = new VendorInsurancePolicyDocumentModel();
        attachment.originalFileName = event.target.files[0].name;
        const file = event.target.files.item(0);

        this.blockUI.start();
        this.cloudStorageService.getSasToken().subscribe(async (data) => {
            const containerName = 'vendor';
            const CloudConfig: UploadParams = {
                sas: data.toString(),
                storageAccount: this.appSettings.AzureAccount,
                containerName: containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
            AppSettings.NonBearerUrl = blobUrl;

            this.config = {
                baseUrl: `${blobUrl}?`,
                sasToken: CloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    attachment.fileName = fileName;
                    this.cloudStorageService.getPublicUrl(containerName, fileName)
                        .subscribe(res => {
                            attachment.fileUrl = res.toString();
                        });
                    this.model.insurancePolicyDocuments.push(attachment);
                    this.blockUI.stop();
                    this.vendorInsuranceDocumentAttachment.nativeElement.value = null;
                },
                error: (err) => {
                    console.log(err);
                    this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(this.config);
            });
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }

    deleteAttachment(fileKey: string, index: number) {
        this.model.insurancePolicyDocuments.splice(index, 1);
        this.deletedDocuments.push(fileKey);
    }
}
