import { Component, HostBinding, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, WorkOrderFilterModel } from '../../../models';
import { WorkOrderService, CompanyService, MasterService } from '../../../services';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { isNullOrUndefined } from '../../../helpers/tools';
import { WorkOrderFilterBoxComponent } from '../filter-box/work.order.filter.box.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-work-order-manage',
    templateUrl: './work.order.manage.component.html'
})
export class WorkOrderManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    @ViewChild('filterBox') filterBox: WorkOrderFilterBoxComponent;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    gridState: any;
    gridStateName = 'gridStateWorkOrders';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: WorkOrderFilterModel = new WorkOrderFilterModel();
    showActionColumn = false;
    queryParamCategory: string;

    showPersonalData: boolean;

    subscriptions: Subscription[] = [];

    companyId = -1;

    get roles() {
        return Roles;
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private masterService: MasterService) {

        this.masterService.WorkOrderShowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['id'];
            this.filterModel.companyId = this.companyId;
        });

        this.route.queryParams.subscribe((params) => {
            this.queryParamCategory = params['category'];
            const status = Number(params['status']);
            const resetFilter = params['resetFilter'] ? true : false;
            if (resetFilter) {
                localStorage.removeItem(this.gridStateName);
                localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
            }
            if (this.queryParamCategory) {
                localStorage.removeItem(this.gridStateName);
                localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
            }
            if (status && status >= 1 && status <= 4) {
                localStorage.removeItem(this.gridStateName);
                this.filterModel.workOrderCategoryIds = [];
                this.filterModel.statusIds = [status.toString()];
                localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
            }
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        if (this.roleMatrix.canEditWorkOrder
            || this.roleMatrix.canChangeStatusOfWorkOrder
            || this.roleMatrix.canDeleteWorkOrder) {
            this.showActionColumn = true;
        }

        this.loadFilterModelFromLocal();

        const subscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/work-order/detail/') !== -1
                    || event.url.indexOf('/work-order/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit(): void {
        this.checkCompanyStatus();
        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                infoFiltered: '',
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter Work Orders...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[3, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                } else {
                    dataTablesParameters.companyId = self.filterModel.companyId;
                    dataTablesParameters.statusIds = self.filterModel.statusIds;
                    dataTablesParameters.priorityIds = self.filterModel.priorityIds;
                    dataTablesParameters.facilityIds = self.filterModel.facilityIds;
                    dataTablesParameters.unitIds = self.filterModel.unitIds;
                    dataTablesParameters.assigneeIds = self.filterModel.assigneeIds;
                    dataTablesParameters.creatorIds = self.filterModel.creatorIds;
                    dataTablesParameters.workOrderCategoryIds = self.filterModel.workOrderCategoryIds;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                dataTablesParameters.ConsiderOnlyPersonalData = this.showPersonalData;

                self.http.post<DataTableResponseModel>
                    (environment.apiBaseUrl + 'workOrder/paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'id',
                    title: 'ID',
                    width: '7%',
                    render: function (data) {
                        return data;
                    }
                },
                {
                    data: 'title',
                    title: 'Title',
                    width: '21%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'assignedToName',
                    title: 'Assigned To',
                    width: '18%'
                },
                {
                    data: 'createdOn',
                    title: 'Creation Date',
                    width: '16%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'workOrderStatus',
                    title: 'Status',
                    width: '15%',
                    className: 'text-center',
                    render: function (data) {
                        let htmlString: string;
                        switch (data) {
                            case 1:
                                htmlString = '<span class="kt-badge kt-badge--danger kt-badge--inline">Not Started</span>';
                                break;
                            case 2:
                                htmlString = '<span class="kt-badge kt-badge--primary kt-badge--inline">In Progress</span>';
                                break;
                            case 3:
                                htmlString = '<span class="kt-badge kt-badge--warning kt-badge--inline">On Hold</span>';
                                break;
                            case 4:
                                htmlString = '<span class="kt-badge kt-badge--success kt-badge--inline">Closed</span>';
                                break;
                            default:
                                htmlString = '';
                                break;
                        }
                        return htmlString;
                    }
                },
                {
                    data: 'workOrderPriority',
                    title: 'Prority',
                    width: '13%',
                    className: 'text-center',
                    render: function (data) {
                        let htmlString: string;
                        switch (data) {
                            case 1:
                                htmlString = '<span class="kt-badge kt-badge--success kt-badge--inline">Low</span>';
                                break;
                            case 2:
                                htmlString = '<span class="kt-badge kt-badge--warning kt-badge--inline">Medium</span>';
                                break;
                            case 3:
                                htmlString = '<span class="kt-badge kt-badge--danger kt-badge--inline">High</span>';
                                break;
                            default:
                                htmlString = '';
                                break;
                        }
                        return htmlString;
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        const htmlString = (self.roleMatrix.canEditWorkOrder && data.workOrderStatus !== 4 && row.status !== 3
                            ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteWorkOrder && data.workOrderStatus === 4
                                ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');
                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/work-order/edit', 1, self.companyId, data.id]);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.delete(data.id, dataIndex);
                });

                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/work-order/detail', 1, self.companyId, data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            },
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    delete(id: number, dataIndex: number) {
        if (!confirm('Are you sure you want to delete the selected work order?')) {
            return;
        }
        this.blockUI.start();
        this.workOrderService.delete(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (dataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.toastr.success('Work order has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    doFilter() {
        localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
        if (this.dtInstance) {
            this.dtInstance.ajax.reload();
        }
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForWorkOrder();
                    this.showActionColumn = this.roleMatrix.canEditWorkOrder
                        || this.roleMatrix.canChangeStatusOfWorkOrder;
                    setTimeout(() => {
                        if (!isNullOrUndefined(this.dtInstance)) {
                            this.dtInstance.column(6).visible(this.showActionColumn);
                        }
                    }, 100);
                }
            }
        );
    }

    loadFilterModelFromLocal() {
        const savedFilterModel = localStorage.getItem('filterModel');
        if (savedFilterModel) {
            const comapnyId = this.filterModel.companyId;
            this.filterModel = JSON.parse(savedFilterModel);
            if (comapnyId !== this.filterModel.companyId) {
                this.filterModel.companyId = comapnyId;
                localStorage.setItem('filterModel', JSON.stringify(this.filterModel));
            }

            let loadFilterIntervalCounter: number = 0;
            let loadFilterInterval = setInterval(() => {
                loadFilterIntervalCounter++;
                if(this.filterBox.facilities.length > 0) {
                      clearInterval(loadFilterInterval);
                      this.filterBox.onFacitlityChanged(false, false);
                }
                else if(loadFilterIntervalCounter > 10){
                    clearInterval(loadFilterInterval);
                }
              }, 1000);
        }
    }

    showPersonalDataChanged() {
        this.masterService.setWorkOrderShowPersonnalData(this.showPersonalData);
    }
}
