import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-404',
    templateUrl: './error.404.component.html',
    styleUrls: ['./error.404.component.css']
})

export class Error404Component {
    @HostBinding('class.full_width') full_width = true;
}

