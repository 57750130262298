<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-work-order-manage [companyId]="companyId">
            </app-breadcrumb-work-order-manage>
        </div>
        <!-- <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper d-flex align-items-center" *ngIf="roleMatrix.role === roles.OrgAdmin">
                <label class="fs-md">All Data</label>
                <label class="switch switch-sm ml-2 mr-2">
                    <input type="checkbox" [(ngModel)]="showPersonalData" class="primary"
                        (change)="showPersonalDataChanged()" />
                    <span class="slider slider-sm round"></span>
                </label>
                <label class="fs-md">Facility Filtered Data</label>
            </div>
        </div> -->
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="work-wrapper">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xs-12 col-sm-3 col-md-4 col-xl-4 ">
                            <h1>Work Order</h1>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-8 col-xl-8 filterSection">
                            <div class="filter-btns">
                                <button type="button" class="btn btn-primary m-r-5"
                                     (click)="goToClosedWorkOrders()">
                                    <i class="fa fa-table"></i>Closed Work Orders
                                </button>
                                <button type="button" class="btn btn-primary" (click)="addWorkOrder()"
                                    *ngIf="roleMatrix.canAddWorkOrder">
                                    <i class="fa fa-plus" aria-hidden="true"></i>Add New
                                </button>
                            </div>
                            <div class="iconss viewicons">
                                <a [routerLink]="['/work-order/manage',companyId]">
                                    <div class="btn">
                                        <i class="fa fa-list"></i>
                                    </div>
                                </a>
                                <a [routerLink]="['/work-order/grid-view',companyId]">
                                    <div class="btn active">
                                        <i class="fa fa-th-large"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="prefrence" *blockUI="'container-blockui'">
                <app-work-order-filter-box [companyId]="companyId" [isClosedOnly]="false" [filterModel]="filterModel"
                    [allowBulkChange]="true" [viewType]="2"
                    (applyFilterEmitter)="doFilter()" #filterBox></app-work-order-filter-box>

                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 high">
                        <div class="high-wrapper">
                            <div class="priority">
                                <p><span class="dot red"></span>High Priority</p>
                                <p>{{highPriorityWorkOrders.length}}</p>
                            </div>
                            <div class="scroll" id="container-high-priority-work-orders" [dragula]="'work_orders'">
                                <div class="kt-portlet cursor-pointer show" [id]='wo.id'
                                    *ngFor="let wo of highPriorityWorkOrders" (click)="goToDetail(wo.id);">
                                    <app-work-order-grid-view-item [model]="wo"></app-work-order-grid-view-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 medium">
                        <div class="medium-wrapper">
                            <div class="priority">
                                <p><span class="dot yellow"></span>Medium Priority</p>
                                <p>{{mediumPriorityWorkOrders.length}}</p>
                            </div>
                            <div class="scroll" id="container-medium-priority-work-orders" [dragula]="'work_orders'">
                                <div class="kt-portlet cursor-pointer show" [id]='wo.id'
                                    *ngFor="let wo of mediumPriorityWorkOrders" (click)="goToDetail(wo.id);">
                                    <app-work-order-grid-view-item [model]="wo"></app-work-order-grid-view-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 low">
                        <div class="low-wrapper">
                            <div class="priority">
                                <p><span class="dot green"></span>Low Priority</p>
                                <p>{{lowPriorityWorkOrders.length}}</p>
                            </div>
                            <div class="scroll" id="container-low-priority-work-orders" [dragula]="'work_orders'">
                                <div class="kt-portlet cursor-pointer show" [id]='wo.id'
                                    *ngFor="let wo of lowPriorityWorkOrders" (click)="goToDetail(wo.id);">
                                    <app-work-order-grid-view-item [model]="wo"></app-work-order-grid-view-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>