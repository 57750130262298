import { Component, Input } from '@angular/core';
import { ActivityLogActionTypeEnum, WoSubTaskState } from 'src/helpers';
import { ActivityInfoModel, ActivityLogModel    } from '../../../models';

@Component({
    selector: 'app-work-order-activity-log-item',
    templateUrl: './work.order.activity.log.item.component.html'
})

export class WorkOrderActivityLogItemComponent {
    @Input() activityLogs: Array<ActivityLogModel>;

    get activityTypes() {
        return ActivityLogActionTypeEnum;
    }

    isStatusOpen(activityInfo: ActivityInfoModel[]) {
        return !!activityInfo.find(x => x.field === 'Status' && +x.value === WoSubTaskState.Open);
    }
}
