<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-integration [companyId]="model.internalCompanyId">
            </app-breadcrumb-integration>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">

            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'blockui'">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="panel-heading report-pointerCursor" style="position:relative;" (click)="isHiddenStoredge = !isHiddenStoredge">
                        <div class="row">
                            <i *ngIf="isHiddenStoredge" class="fa fa-caret-right color-primary fs-30" style="padding-left: 20px;"></i>
                            <i *ngIf="!isHiddenStoredge" class="fa fa-caret-down  color-primary fs-30" style="padding-left: 20px;"></i>
                            <h5 style="padding-top: 5px; padding-left: 12px;">Storedge</h5>
                        </div>
                    </div>
                    <div class="report-panel-body" [hidden]="isHiddenStoredge">
                            <ngb-tabset [activeId]="activeTab" type="string" class="custom-tab-set">
                                <ngb-tab id="1" title="Configure Database">
                                    <ng-template ngbTabContent>
                                        <app-storedge-configuration [model]="model" (openNextTab)="openPreviewTab($event)">
                                        </app-storedge-configuration>
                                    </ng-template>
                                </ngb-tab>
                                <ngb-tab id="2" title="Facilities & Units" [disabled]="!model.storedgCompanyConfigured || !showActionColumn">
                                    <ng-template ngbTabContent>
                                        <app-storedge-preview-facility [model]="model"></app-storedge-preview-facility>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                     </div>
                     <app-davincilock-configuration></app-davincilock-configuration>
                     <app-quick-configuration></app-quick-configuration>
                     <app-jive-configuration></app-jive-configuration>
                </div>
            </div>
        </div>
    </div>
</div>