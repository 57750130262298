import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pdf-viewer-modal',
    templateUrl: './pdf.viewer.modal.component.html'
})

export class PdfViewerModalComponent {
    @ViewChild('pdfViewerModal', { static: true }) pdfViewerModal: any;

    modalReferece: NgbModalRef;
    title: string;
    pdfUrl: any;

    constructor(private modalService: NgbModal,
        private sanitizer: DomSanitizer) { }

    openPdfViewerModal(title: string, pdfUrl: string) {
        this.title = title;
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
        this.modalReferece = this.modalService
            .open(this.pdfViewerModal,
                {
                    size: 'lg'
                });
    }

    closePdfViewerModal() {
        this.modalReferece.close();
    }
}

