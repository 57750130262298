<div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
    <div class="row">
        <div class="col-12" *blockUI="'container-blockui-movein'">
            <div class="kt-portlet">
                <div class="kt-portlet__head head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Move-In Application
                        </h3>
                        <p> Please enable the toggle button in order to activate the move-in-application on the kiosk
                            machine. </p>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <label class="switch ">
                                    <input type="checkbox" [(ngModel)]="isMoveInType" class="primary"
                                        (change)="chagneApplicationType()"
                                        [disabled]="totalAmenity === 0 || !showActionColumn" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" data-scroll="true" data-height="500" data-mobile-height="250">
                    <div class="row  justify-content-center row-data-configure"
                        *ngIf="isModelLoaded && totalAmenity === 0 && showActionColumn">
                        <a [routerLink]="['/movein/configuration', companyId, facilityId, kioskId]"
                            class="btn btn-lg btn-warning">
                            <i class="la la-gear"></i>
                            Configure
                        </a>
                        <p class="w-100 p-3 text-center">There are no configuration settings for this
                            application. Please click the configure button to choose your preferences.</p>
                    </div>
                    <div class="row" *ngIf="isModelLoaded && totalAmenity !== 0">
                        <div class="col-12 text-right">
                            <a [routerLink]="['/movein/configuration', companyId, facilityId, kioskId]"
                                *ngIf="showActionColumn" class="btn btn-sm btn-outline-primary">Manage</a>
                        </div>
                        <div class="col-12">
                            <div class="form-group row">
                                <div class="col-5 kt-font-bold m-b-0">Total Amenities Added : </div>
                                <div class="col-7 m-b-15">{{totalAmenity}}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group row">
                                <div class="col-5 m-b-15 kt-font-bold">
                                    State View :
                                </div>
                                <div class="col-7 m-b-15" *ngIf="configurationModel.stateView == 0">
                                    <p class="kt-font-bold  m-b-0">Dropdown View</p>
                                    <p>The states will be visible in a dropdown to the users.</p>
                                </div>
                                <div class="col-7 m-b-15" *ngIf="configurationModel.stateView == 1">
                                    <p class="kt-font-bold m-b-0">Pop Up View</p>
                                    <p>A pop up will open up displaying all the states in the kiosk machine with button
                                        to confirm or discard the selection.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group row">
                                <div class="col-5 m-b-15 kt-font-bold">
                                    Mandatory Field :
                                </div>
                                <div class="col-7">
                                    <div class="row">
                                        <div class="col-8 m-b-15" *ngFor="let item of fieldDetails">
                                            <p class="kt-font-bold m-b-0">{{item.name}}</p>
                                            <p>{{item.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group row">
                                <div class="col-5 m-b-15 kt-font-bold">
                                    KeyBoard Preference :
                                </div>
                                <div class="col-7 m-b-15" *ngIf="configurationModel.keyboardPreference == 0">
                                    <p class="kt-font-bold  m-b-0">Software Keyboard</p>
                                    <p>The software keyboard will open up in the kiosk machine.</p>
                                </div>
                                <div class="col-7 m-b-15" *ngIf="configurationModel.keyboardPreference == 1">
                                    <p class="kt-font-bold m-b-0">Hardware Keyboard</p>
                                    <p>The hardware keyboard will open up in the kiosk machine.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="configurationModel.documentFileName">
                            <div class="form-group row">
                                <div class="col-5 m-b-15 kt-font-bold">
                                    Document File :
                                </div>
                                <div class="col-7 m-b-15">
                                    <button type="button" class="btn btn-xs btn-outline-brand"
                                        (click)="openPdfViewer()">
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-pdf-viewer-modal #pdfViewerModal></app-pdf-viewer-modal>