import { Component } from '@angular/core';
import { AppUtils, RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-change-password',
    templateUrl: './breadcrumb.change.password.component.html'
})

export class BreadcrumbChangePasswordComponent {
    roleMatrix: RoleMatrix;
    is10FedUser = false;
    profileUrl: string;

    constructor(private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();
        this.is10FedUser = this.appUtils.is10FedUser(this.roleMatrix.role);
        this.profileUrl = this.is10FedUser ? '/user/profile' : '/company-user/profile-detail';
    }
}
