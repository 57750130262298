import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import { AppUtils, RoleMatrix } from 'src/helpers';
import { UserGroupService, FacilityService } from 'src/services';
import {
  SelectListItemModel,
  UserGroupModel,
  RecordStatus,
  ValidateUserGroupModel,
  FacilityAssigneeValidationModel,
  UserGroupDefaultAssigneeValidationModel,
  FacilityAssigneesValidationDto,
  UserGroupEditModel
} from 'src/models';


@Component({
  selector: 'app-user-group-detail',
  templateUrl: './user.group.detail.component.html'
})
export class UserGroupDetailComponent implements OnInit {

  companyId = -1;
  groupId: string;
  isActive = true;
  @HostBinding('class.full_width') full_width = true;
  @BlockUI('container-blockui-add-user') blockUI: NgBlockUI;
  @ViewChild('userGroupChallengeDeleteModal', { static: true }) userGroupChallengeDeleteModal: any;
  @ViewChild('userGroupChallengeDeactivateModal', { static: true }) userGroupChallengeDeactivateModal: any;

  model: UserGroupModel = new UserGroupModel();
  roleMatrix: RoleMatrix;
  clientUsers: Array<SelectListItemModel> = new Array<SelectListItemModel>();
  selectedClients: Array<SelectListItemModel> = new Array<SelectListItemModel>();
  showActionColumn: boolean = false;

  private validateUserGroupModel: ValidateUserGroupModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private appUtils: AppUtils,
    private userGroupService: UserGroupService,
    private facilityService: FacilityService) {

    this.roleMatrix = new RoleMatrix();

    this.route.params.subscribe((params) => {
      this.companyId = params['companyId'];
      this.groupId = params['groupId'];
    });
  }

  ngOnInit() {
    if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
      this.router.navigate(['/error/404']);
    }

    this.loadUserGroup();
    this.loadAssignedFacilities();
  }

  loadUserGroup() {
    this.blockUI.start();
    this.userGroupService.getUserGroup(this.groupId)
      .subscribe((data: UserGroupModel) => {
        this.model = data;
        this.isActive = data.status == RecordStatus.Active;
        this.setShowAction();
        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  loadAssignedFacilities() {
    this.blockUI.start();
    this.facilityService.getFacilitiesUserGroups(this.companyId, this.groupId)
      .subscribe((validateUserGroupModel: ValidateUserGroupModel) => {
        this.blockUI.stop();

        this.validateUserGroupModel = validateUserGroupModel;

        // Populate only factilitues
        this.model.multipleUserGroupFacilities = [];
        this.model.onlyUserGroupFacilities = this.validateUserGroupModel
          .singleUserGroupAssignedFactilities
          .filter(x => x.status != RecordStatus.Deleted)
          .map(x => {
            const model = new FacilityAssigneesValidationDto();
            model.id = x.id;
            return model;
          });

        if (this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
          // Check and Get only those facilities in which any default assignees member of user group
          const validateFacilities = this.validateUserGroupModel.multipleUserGroupAssignedFactilities.filter(x => {
              return Array.isArray(this.model.members) && this.model.members.some(member => {
                return x.defaultAssigneeCustomerComplaints == member.userId || x.defaultAssigneeMoveOut == member.userId ||
                x.defaultAssigneeMoveIn == member.userId || x.defaultAssigneeId == member.userId;
              });
            }).map(x => {
              const model = new FacilityAssigneeValidationModel();
              model.id = x.id;
              model.defaultAssigneeId = x.defaultAssigneeId;
              model.defaultAssigneeCustomerComplaints = x.defaultAssigneeCustomerComplaints;
              model.defaultAssigneeMoveOut = x.defaultAssigneeMoveOut;
              model.defaultAssigneeMoveIn = x.defaultAssigneeMoveIn;
              model.userGroupIds = x.userGroupIds;
              return model;
            });

          if (validateFacilities.length) {
            const model = new UserGroupDefaultAssigneeValidationModel();
            model.companyId = this.model.companyId;
            model.userGroupInOperation = this.model.id;
            model.facilityAssigneeValidationModels = validateFacilities;

            // Validate if user-group is assigned to multiple facilities
            this.blockUI.start();
            this.userGroupService.validateDefaultAssignees(model)
              .subscribe((data: FacilityAssigneesValidationDto[]) => {
                this.model.multipleUserGroupFacilities = data;
                this.blockUI.stop();
              }, error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
                this.blockUI.stop();
              });
          } else {
            this.model.multipleUserGroupFacilities = validateUserGroupModel.multipleUserGroupAssignedFactilities.map(x => {
              const model = new FacilityAssigneesValidationDto();
              model.id = x.id;
              model.defaultAssigneeExistsInOtherUserGroup = true;
              model.defaultAssigneeCustomerComplaintExistsInOtherUserGroup = true;
              model.defaultAssigneeMoveOutExistsInOtherUserGroup = true;
              model.defaultAssigneeMoveInExistsInOtherUserGroup = true;
              return model;
            });
          }
        }
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  challengeDelete() {
    if (this.validateUserGroupModel.singleUserGroupAssignedFactilities.length == 0 &&
      this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length == 0) {
      // No Facility assigned with user group, Directly delete      
      this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(this.model);
    } else if (this.validateUserGroupModel.singleUserGroupAssignedFactilities && this.validateUserGroupModel.singleUserGroupAssignedFactilities.length) {

      let hasActiveFacility = this.validateUserGroupModel.singleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
      // Case 1:
      // When a user tries to delete the 'ONLY' user group assigned to a facility
      if (hasActiveFacility == true && this.model.status === RecordStatus.Active) {
        this.userGroupChallengeDeleteModal.openDeleteConfirmationModal(this.model, (this.validateUserGroupModel.singleUserGroupAssignedFactilities || [])
          .concat(this.validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
      }

      // Case 2:
      // When a user tries to delete a deactivated user group from an active facility and it is the only user group assigned to the facility
      if (hasActiveFacility == true && this.model.status === RecordStatus.Inactive) {
        this.userGroupChallengeDeleteModal.openDeleteConfirmationModal(this.model, (this.validateUserGroupModel.singleUserGroupAssignedFactilities || [])
          .concat(this.validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
      }

      //Case 4
      //When a user tries to delete an active user group associated with a deactivated facility, then the system will let the user delete the 
      if (hasActiveFacility == false && this.model.status === RecordStatus.Active) {
        this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(this.model);
      }

      // Case 5:
      // When a user tries to delete a deactivated group associated with a deactivated facility, then the system will allow the user to do that. 
      if (hasActiveFacility == false && this.model.status === RecordStatus.Inactive) {
        this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(this.model);
      }
    } else if (this.validateUserGroupModel.multipleUserGroupAssignedFactilities && this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
      let hasActiveFacility = this.validateUserGroupModel.multipleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
      if (hasActiveFacility) {
        // There are multiple user groups assigned to a facility,
        // Case 3: 
        // WHEN a user tries to delete the user group whose user is the default assignee for the customer complaints and move out
        // THEN the system will allow the user to delete the group and pop will be shown

        //Check and Get only those facilities in which any default assignees member of user group
        let hasDefaultAssignee = (this.model.multipleUserGroupFacilities || []).find(x =>
          x.defaultAssigneeExistsInOtherUserGroup == false ||
          x.defaultAssigneeCustomerComplaintExistsInOtherUserGroup == false ||
          x.defaultAssigneeMoveOutExistsInOtherUserGroup == false || 
          x.defaultAssigneeMoveInExistsInOtherUserGroup == false);

        if (hasDefaultAssignee) {
          this.userGroupChallengeDeleteModal.openDeleteConfirmationForMultipleModal(this.model,
            this.validateUserGroupModel.multipleUserGroupAssignedFactilities);
        } else {
          this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(this.model);
        }
      } else {
        this.userGroupChallengeDeleteModal.openDefaultDeleteConfirmationModal(this.model);
      }
    }
  }

  delete(userGroupModel: UserGroupModel) {
    this.blockUI.start();
    let editModel = new UserGroupEditModel();
    editModel.id = userGroupModel.id;
    editModel.raiseEvent = true;
    editModel.multipleUserGroupFacilities = userGroupModel.multipleUserGroupFacilities;
    editModel.onlyUserGroupFacilities = userGroupModel.onlyUserGroupFacilities;

    this.userGroupService.delete(editModel).subscribe(() => {
      this.blockUI.stop();
      setTimeout(() => {
        this.router.navigate(['/company', this.companyId, 'user-group']);
      }, 10);
      setTimeout(() => {
        this.toastr.success('User Group has been deleted successfully.');
      }, 500);
    }, error => {
      this.blockUI.stop();
      this.appUtils.ProcessErrorResponse(this.toastr, error);
    });
  }

  toggleStatus() {
    if (this.model.status == RecordStatus.Active) {
      //Has No Facility associated
      if (this.validateUserGroupModel.singleUserGroupAssignedFactilities.length == 0 && this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length == 0) {
        this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(this.model);
      } else if (this.validateUserGroupModel.singleUserGroupAssignedFactilities.length) {
        //Is Last User Group 
        let hasActiveFacility = this.validateUserGroupModel.singleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
        if (hasActiveFacility) {
          this.userGroupChallengeDeactivateModal.openDeactivateConfirmationModal(this.model, (this.validateUserGroupModel.singleUserGroupAssignedFactilities || [])
            .concat(this.validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
        } else {
          this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(this.model);
        }
      } else if (this.validateUserGroupModel.multipleUserGroupAssignedFactilities && this.validateUserGroupModel.multipleUserGroupAssignedFactilities) {
        let hasActiveFacility = this.validateUserGroupModel.multipleUserGroupAssignedFactilities.some(facility => facility.status == RecordStatus.Active);
        if (hasActiveFacility) {
          //Check and Get only those facilities in which any default assignees member of user group
          let hasDefaultAssignee = (this.model.multipleUserGroupFacilities || []).find(x => x.defaultAssigneeExistsInOtherUserGroup == false || 
            x.defaultAssigneeCustomerComplaintExistsInOtherUserGroup == false ||
            x.defaultAssigneeMoveOutExistsInOtherUserGroup == false || 
            x.defaultAssigneeMoveInExistsInOtherUserGroup == false)
          if (hasDefaultAssignee) {
            this.userGroupChallengeDeactivateModal.openDeactivateConfirmationForMultipleModal(this.model,
              (this.validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat(this.validateUserGroupModel.multipleUserGroupAssignedFactilities || []));
          } else {
            this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(this.model);
          }
        } else {
          this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(this.model);
        }
      } else {
        this.userGroupChallengeDeactivateModal.openDefaultDeactivateConfirmationModal(this.model);
      }
    } else {
      this.initiateToggleStatus(this.model);
    }
  }

  initiateToggleStatus(userGroupModel: UserGroupModel) {
    this.blockUI.start();
    let editModel = new UserGroupEditModel();
    editModel.id = userGroupModel.id;
    editModel.raiseEvent = true;
    editModel.multipleUserGroupFacilities = userGroupModel.multipleUserGroupFacilities;
    editModel.onlyUserGroupFacilities = userGroupModel.onlyUserGroupFacilities;

    this.userGroupService.toggleStatus(editModel).subscribe(() => {
      this.toastr.success(`User Group has been ${(this.model.status === RecordStatus.Active ? 'deactivated' : 'activated')} successfully.`);
      this.blockUI.stop();
      this.loadUserGroup();
    }, error => {
      this.blockUI.stop();
      this.appUtils.ProcessErrorResponse(this.toastr, error);
    });
  }

  private setShowAction(){
    this.showActionColumn = ((this.roleMatrix.role.toUpperCase() === 'ORGADMIN' || this.roleMatrix.role.toUpperCase() === 'ORGUSER') 
                                  && this.model.members.some(x => x.userId === this.appUtils.getUserId()) 
                                  || this.roleMatrix.role.toUpperCase() === 'ORGSUPERADMIN'
                                  || this.roleMatrix.role.toUpperCase() === 'PERSONNEL'
                                  || this.roleMatrix.role.toUpperCase() === 'ADMIN'
                                  || this.roleMatrix.role.toUpperCase() === 'SUPERADMIN')
                                && (this.roleMatrix.canEditUserGroup || this.roleMatrix.canChangeStatusOfUserGroup || this.roleMatrix.canDeleteUserGroup);
  }
}
