import { Component, OnInit, Input } from '@angular/core';
import { UnitService } from '../../../../services';
@Component({
    selector: 'app-company-unit-tile',
    templateUrl: './company.unit.tile.component.html'
})

export class CompanyUnitTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;
    totalUnits = 0;

    constructor(private unitService: UnitService) {

    }

    ngOnInit(): void {
        this.unitService.getCountByCompany(this.companyId)
            .subscribe(
                (data: number) => {
                    this.isModelLoaded = true;
                    this.totalUnits = data;
                },
                error => {
                    console.log('Vendors count failed');
                    console.log(error);
                });
    }
}
