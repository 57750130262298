<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
      <div class="kt-subheader__main">
        <app-breadcrumb-datasource-manage></app-breadcrumb-datasource-manage>
      </div>
      <div class="kt-subheader__toolbar">
          <div class="kt-subheader__wrapper">
          </div>
      </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'loader-blockui'">
      <div class="col-md-12 p-0">
        <div class="row">
          <!-- Storedge Cart -->
          <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                          <div class="col-md-4">
                            <div class="card" style="width: 18rem;">
                              <img src="./assets/kpi-report/storedge-logo.png" style="height: 25px; width: 150px;" class="logo" alt=""/><br>
                              <div class="card-body">
                                <p class="card-text">{{StoredgeLastSyncDate}}</p>
                                <p>Want to sync manually?</p>
                                <button class="btn btn-success btn-block" *blockUI="'container-blockui'" (click)="startSync()">
                                Start Sync</button>
                              </div>
                            </div>

                          </div>
                          
                      </div>
                      
                  </div>
              </div>
              
          </div>
          </div>

          <!-- Work Order Cart -->
          <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                          <div class="col-md-4">
                            <div class="card" style="width: 18rem;">
                              <img src="./assets/kpi-report/workorder.png" style="height: 25px; width: 150px;" class="logo" alt=""/><br>
                              <div class="card-body">
                                <p class="card-text">{{WorkOrderLastSyncDate}}</p>
                                <p>Want to sync manually?</p>
                                <button class="btn btn-success btn-block" *blockUI="'container-blockui-workorder'" (click)="SyncWorkOrder()">
                                Start Sync</button>
                              </div>
                            </div>

                          </div>
                          
                      </div>
                      
                  </div>
              </div>
            </div>
          </div>

          <!-- Davinvi Lock Cart -->
          <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                          <div class="col-md-4">
                            <div class="card" style="width: 18rem;">
                              <img src="./assets/kpi-report/davinci-lock.png" style="height: 25px; width: 130px;" class="logo" alt=""/><br>
                              <div class="card-body">
                                <p class="card-text">{{DavinciLockLastSyncDate}}</p>
                                <p>Want to sync manually?</p>
                                <button class="btn btn-success btn-block" *blockUI="'container-blockui-davincilock'" (click)="SyncDavinciLock()">
                                Start Sync</button>
                              </div>
                            </div>

                          </div>
                          
                      </div>
                      
                  </div>
              </div>
            </div>
          </div>
          


          
        </div>
        
        <div class="row">
          <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                          <div class="col-md-4">
                            <div class="card" style="width: 18rem;">
                              <img src="./assets/kpi-report/quick.png" style="height: 25px; width: 150px;" class="logo" alt=""/><br>
                              <div class="card-body">
                                <p class="card-text">{{QuickBooksLastSyncDate}}</p>
                                <p>Want to sync manually?</p>
                                <button class="btn btn-success btn-block" *blockUI="'container-blockui-quickbooks'" (click)="SyncQuickBooks()">
                                Start Sync</button>
                              </div>
                            </div>

                          </div>
                          
                      </div>
                      
                  </div>
              </div>
              
          </div>
          </div>
          <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="card" style="width: 18rem;">
                         <img src="./assets/kpi-report/jive-communication.png" style="height: 25px; width: 150px;" class="logo" alt=""/><br>
                          <div class="card-body">
                            <p class="card-text">{{JiveLastSyncDate}}</p>
                            <p>Want to sync manually?</p>
                           <button class="btn btn-success btn-block" *blockUI="'container-blockui-jive'" (click)="SyncJiveCommunication()">
                            Start Sync</button>
                          </div>
                        </div>

                    </div>
          
                  </div>
                      
                  </div>
              </div>
              
          </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body" >
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                          <div class="col-md-4">
                            <div class="card" style="width: 18rem;">
                              <img src="./assets/kpi-report/storedge-logo.png" style="height: 25px; width: 150px;" class="logo" alt=""/><br>
                              <div class="card-body">
                                <p class="card-text">{{LastSyncDate}}</p>
                                <p>Want to sync manually?</p>
                                <button class="btn btn-success btn-block" *blockUI="'container-blockui'" (click)="startSync()">
                                Start Sync</button>
                              </div>
                            </div>

                          </div>
                          
                      </div>
                      
                  </div>
              </div>
              
            </div>
          </div> -->
          
        </div>
      </div>
  </div>
</div>