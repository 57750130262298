import { WoSubTaskState } from "src/helpers";
import { RecordStatus } from "src/models/user-group/record.status";

export class WoSubTask {
    id: number;
    workOrderId: number;
    unitId: number;
    task: string;
    state: WoSubTaskState;
    status: RecordStatus;
}

export class WoSubTaskDto {
    id: number;
    workOrderId: number;
    unitId: number;
    unitName: number;
    task: string;
    state: WoSubTaskState;
    status: RecordStatus;
    disableUnitEdit: boolean;
}
