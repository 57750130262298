import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { UserGroupModel, UserGroupEditModel, ValidateUserGroupModel, RecordStatus, FacilityAssigneesValidationDto, FacilityAssigneeValidationModel, UserGroupDefaultAssigneeValidationModel } from 'src/models';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { UserGroupService, UserService, FacilityService } from 'src/services';
import { SelectListItemModel } from 'src/models';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user.group.edit.component.html'
})
export class UserGroupEditComponent implements OnInit {

  companyId = -1;
  groupId: string;
  @HostBinding('class.full_width') full_width = true;
  @BlockUI('container-blockui-add-user') blockUI: NgBlockUI;
  @ViewChild('userDeleteChallengeModal', { static: true }) userDeleteChallengeModal: any;

  model: UserGroupModel = new UserGroupModel();
  roleMatrix: RoleMatrix;
  multiSelectDropdownConfigs = {};
  clientUsers: Array<SelectListItemModel> = new Array<SelectListItemModel>();
  selectedClients: Array<SelectListItemModel> = new Array<SelectListItemModel>();

  private validateUserGroupModel: ValidateUserGroupModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private appUtils: AppUtils,
    private userService: UserService,
    private facilityService: FacilityService,
    private userGroupService: UserGroupService) {

    this.roleMatrix = new RoleMatrix();

    if (!this.roleMatrix.canEditUserGroup) {
      this.router.navigate(['/error/403']);
    }

    this.route.params.subscribe((params) => {
      this.companyId = params['companyId'];
      this.groupId = params['groupId'];
    });

    this.multiSelectDropdownConfigs = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  ngOnInit() {
    if (!this.roleMatrix.is10FedUser && this.roleMatrix.companyId !== this.companyId.toString()) {
      this.router.navigate(['/error/404']);
    }

    this.loadClientUsers();
    this.loadUserGroup();
    this.loadAssignedFacilities();
  }

  loadUserGroup() {
    this.blockUI.start();
    this.userGroupService.getUserGroupForEdit(this.groupId)
      .subscribe((data: UserGroupModel) => {
        this.model = data;
        this.updateSelectedClientList();
      }, error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }, () => {
        this.blockUI.stop();
      });
  }

  loadAssignedFacilities() {
    this.blockUI.start();
    this.facilityService.getFacilitiesUserGroups(this.companyId, this.groupId)
      .subscribe((validateUserGroupModel: ValidateUserGroupModel) => {
        this.validateUserGroupModel = validateUserGroupModel;

        //Populate only factilitues
        this.model.multipleUserGroupFacilities = [];
        this.model.onlyUserGroupFacilities = this.validateUserGroupModel
          .singleUserGroupAssignedFactilities
          .filter(x => x.status != RecordStatus.Deleted)
          .map(x => {
            let model = new FacilityAssigneesValidationDto();
            model.id = x.id;
            model.defaultAssigneeId = x.defaultAssigneeId;
            model.defaultAssigneeMoveOut = x.defaultAssigneeMoveOut;
            model.defaultAssigneeMoveIn = x.defaultAssigneeMoveIn;
            model.defaultAssigneeCustomerComplaints = x.defaultAssigneeCustomerComplaints;
            return model;
          });

        if (this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length) {
          // Check and Get only those facilities in which any default assignees member of user group
          let validateFacilities: FacilityAssigneeValidationModel[] = this.validateUserGroupModel.multipleUserGroupAssignedFactilities.filter(x => {
            return Array.isArray(this.model.members) && this.model.members.some(member => {
              return x.defaultAssigneeCustomerComplaints == member.userId || x.defaultAssigneeMoveOut == member.userId || x.defaultAssigneeMoveIn == member.userId ||
                x.defaultAssigneeId == member.userId;
            });
          }).map(x => {
            let model = new FacilityAssigneeValidationModel();
            model.id = x.id;
            model.defaultAssigneeId = x.defaultAssigneeId;
            model.defaultAssigneeCustomerComplaints =x.defaultAssigneeCustomerComplaints;
            model.defaultAssigneeMoveOut = x.defaultAssigneeMoveOut;
            model.defaultAssigneeMoveIn = x.defaultAssigneeMoveIn;
            model.userGroupIds = x.userGroupIds;
            return model;
          });
          if (validateFacilities.length) {
            let model = new UserGroupDefaultAssigneeValidationModel();
            model.companyId = this.model.companyId;
            model.userGroupInOperation = this.model.id;
            model.facilityAssigneeValidationModels = validateFacilities;

            // Validate if user-group is assigned to multiple facilities
            this.blockUI.start();
            this.userGroupService.validateDefaultAssignees(model)
              .subscribe((data: FacilityAssigneesValidationDto[]) => {
                this.model.multipleUserGroupFacilities = data;
              }, error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
              }, () => {
                this.blockUI.stop();
              });
          } else {
            this.model.multipleUserGroupFacilities = validateUserGroupModel.multipleUserGroupAssignedFactilities.map(x => {
              let model = new FacilityAssigneesValidationDto();
              model.id = x.id;
              model.defaultAssigneeExistsInOtherUserGroup = true;
              model.defaultAssigneeCustomerComplaintExistsInOtherUserGroup = true;
              model.defaultAssigneeMoveOutExistsInOtherUserGroup = true;
              model.defaultAssigneeMoveInExistsInOtherUserGroup = true;
              return model;
            });
          }
        }
      }, error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }, () => {
        this.blockUI.stop();
      });
  }

  loadClientUsers() {
    this.userService.getCompanyUsersSelectItem(this.companyId)
      .subscribe((data: Array<any>) => {
        if (!data || data.length === 0) {
          return;
        }
        this.clientUsers = data;
        this.updateSelectedClientList();
      }, error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }, () => {
        this.blockUI.stop();
      });
  }

  updateSelectedClientList() {
    if (!this.clientUsers
      || this.clientUsers.length === 0
      || !this.model.members
      || this.model.members.length === 0) {
      return;
    }

    const tempArr = new Array<SelectListItemModel>();
    this.clientUsers.forEach(item => {
      if (this.model.members.find(m => m.userId === item.key)) {
        tempArr.push(item)
      }
    });
    this.selectedClients = tempArr;
  }

  submit() {
    // A user tries to delete the last or the only user from a deactivated user group which is assigned to an active facility 
    if (this.model.status == RecordStatus.Inactive) {
      this.sendModelForUpdation();
    } else {
      let activeFacility = this.getActiveFacility();
      // A user tries to delete the last or the only user from an active user group which is assigned to an active facility
      if (activeFacility != null && this.selectedClients && this.selectedClients.length == 0) {
        this.userDeleteChallengeModal.openDeleteConfirmationModal(this.model.name, this.getFacilities())
      } else {
        this.sendModelForUpdation();
      }
    }
  }

  sendModelForUpdation() {
    const editModel = new UserGroupEditModel();
    editModel.id = this.model.id;
    editModel.name = this.model.name;
    editModel.companyId = this.companyId;
    editModel.lowPriorityWorkOrdersReminderInterval = this.model.lowPriorityWorkOrdersReminderInterval;
    editModel.mediumPriorityWorkOrdersReminderInterval = this.model.mediumPriorityWorkOrdersReminderInterval;
    editModel.highPriorityWorkOrdersReminderInterval = this.model.highPriorityWorkOrdersReminderInterval;

    editModel.members = new Array<string>();
    if (this.selectedClients && this.selectedClients.length > 0) {
      this.selectedClients.map((item) => {
        editModel.members.push(item.key);
      });
    } else {
      editModel.raiseEvent = true;
      editModel.multipleUserGroupFacilities = this.model.multipleUserGroupFacilities;
      editModel.onlyUserGroupFacilities = this.model.onlyUserGroupFacilities;
    }

    this.blockUI.start();
    this.userGroupService.edit(editModel)
      .subscribe(() => {
        this.blockUI.stop();
        setTimeout(() => {
          this.router.navigate(['/company', this.companyId, 'user-group', this.groupId, 'detail']);
        }, 10);
        setTimeout(() => {
          this.toastr.success('User Group has been updated successfully.');
        }, 500);
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  getActiveFacility() {
    if (this.validateUserGroupModel == null) {
      return null;
    }

    if (this.validateUserGroupModel.singleUserGroupAssignedFactilities.length == 0 && this.validateUserGroupModel.multipleUserGroupAssignedFactilities.length == 0) {
      return null;
    }

    let activeFacility = this.validateUserGroupModel.singleUserGroupAssignedFactilities.find(x => x.status == RecordStatus.Active);
    if (activeFacility == null) {
      activeFacility = this.validateUserGroupModel.multipleUserGroupAssignedFactilities.find(x => x.status == RecordStatus.Active);
    }

    return activeFacility;
  }

  getFacilities(){
    return (this.validateUserGroupModel.singleUserGroupAssignedFactilities || []).concat( this.validateUserGroupModel.multipleUserGroupAssignedFactilities || []);
  }

}
