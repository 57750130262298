<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-facility-detail [companyId]="model.companyId"></app-breadcrumb-facility-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Facility Detail
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold mr-3"
                                (click)="followFacility()"
                                *ngIf="!roleMatrix.is10FedUser && model.canFollowUser && (model.status === 2 || model.isFollowing)"
                                [disabled]="model.status === 3 && model.isFollowing">
                                {{ model.isFollowing ? 'Unfollow' : 'Follow' }}
                            </button>
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown"
                                *ngIf="showActionColumn">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
                                *ngIf="showActionColumn">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canEditFacility">
                                        <a [routerLink]="['/facility/edit',model.companyId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canChangeStatusOfFacility">
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===3">
                                            <em class="kt-nav__link-icon fa fa-check"></em>
                                            <span class="kt-nav__link-text">
                                                Activate
                                            </span>
                                        </a>
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===2">
                                            <em class="kt-nav__link-icon fa fa-ban"></em>
                                            <span class="kt-nav__link-text">
                                                Deactivate
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canDeleteFacility">
                                        <a href="javascript:;" class="kt-nav__link" (click)="challengeDelete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a href="javascript:;" class="kt-nav__link" (click)="editNote()">
                                            <em class="kt-nav__link-icon fa fa-file"></em>
                                            <span class="kt-nav__link-text">
                                                Add/Edit Note
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="row">
                            <div class="col-md-12 detail-form">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-home" title="Facility Name"></em> {{model.name}}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-user" title="Property Manager Name"></em>
                                            {{model.propertyManagerName}}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-envelope" title="Property Manager Email"></em>
                                            {{model.propertyManagerEmail}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-map-marker-alt" title="Address"></em> {{model.address}},
                                            {{model.zipCode}}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-phone-square" title="Contact Number"></em>
                                            {{model.propertyManagerPhone}}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-wrench" title="Type of Construction"></em>
                                            {{model.typeOfConstruction}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-cloud-sun" title="Cliente Controlled"></em>
                                            {{(model.climateControlled ? 'Yes' : 'No')}}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-users" title="User Groups Assigned"></em>
                                            <span class="kt-portlet__data m-l-5">
                                                <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                                    *ngFor="let item of model.clientUserGroupsAssignedNames">
                                                    {{item}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-users" title="Assoiciated Vendors"></em>
                                            <span class="kt-portlet__data m-l-5">
                                                <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                                    *ngFor="let item of vendors">
                                                    {{item.name}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group" *ngIf="model.isImported">
                                            <em class="fa fa-download" title="Imported"></em>
                                            <span class="kt-portlet__data m-l-5" *ngIf="model.isImported">
                                                <span class="kt-badge kt-badge--success kt-badge--inline">
                                                    Imported
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                                
                                            <img src="./assets/kpi-report/support.png" style="height:20px;" title="Support Line">
                                            {{(model.phone ? model.phone : 'Not Defined')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-none">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-users"
                                                title="Default Assignee for customers complains"></em>
                                            <span class="kt-portlet__data m-l-5">
                                                {{model.complaintsAssigneeName}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-users" title="Default Assignee for Move out"></em>
                                            <span class="kt-portlet__data m-l-5">
                                                {{model.moveOutAssigneeName}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-users" title="Default Assignee for Move in"></em>
                                            <span class="kt-portlet__data m-l-5">
                                                {{model.moveInAssigneeName}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <em class="fa fa-file-import" title="Is Imported"></em>
                                            <span class="kt-portlet__data m-l-5" *ngIf="model.isImported">Yes</span>
                                            <span class="kt-portlet__data m-l-5" *ngIf="!model.isImported">No</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Note
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold mr-3"
                                (click)="editNote()">
                                {{model.facilityNoteDetails == null ? 'Add Note' : 'Edit Note'}}
                            </button>
                            <a [routerLink]="['/facility/notes', model.companyId, model.id]"
                                class="btn btn-label-primary btn-sm btn-bold" *ngIf="model.facilityNoteDetails">
                                View All
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="row">
                            <div class="col-md-12 detail-form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group" *ngIf="model.facilityNoteDetails != null">
                                            {{model.facilityNoteDetails.note}}
                                            <br><br>
                                            <p class="badge badge-light" style="font-size: 0.8rem;">
                                                <span>{{model.facilityNoteDetails.createdByName}}</span>
                                                ({{model.facilityNoteDetails.createdOn}})
                                            </p>
                                        </div>
                                        <div class="form-group text-danger" *ngIf="model.facilityNoteDetails == null">
                                            No note found.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Unit Management
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <a [routerLink]="['/unit/add',model.companyId, model.id]"
                                class="btn btn-brand btn-elevate btn-icon-sm" *ngIf="roleMatrix.canAddUnit">
                                <em class="la la-plus"></em>
                                New Unit
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-12">
                                <app-unit-manage #appUnitManage [companyId]="model.companyId" [facilityId]="model.id">
                                </app-unit-manage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-facility-challenge-delete #facilityChallengeDeleteModal (deleteFacility)="delete()">
</app-facility-challenge-delete>

<app-facility-add-note #facilityAddNoteModal (change)="saveNote($event)"></app-facility-add-note>