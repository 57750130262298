import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginModel, AccountRecoverModel, AccountResetModel, AccountActivateModel, ChangePasswordModel, AssignedUserModel, SelectListItemModel } from '../models';
import { AppSettings } from '../helpers';
import { environment } from 'src/environments/environment';
import { ResetPasswordModel } from 'src/models/account/reset.password.model';

@Injectable()
export class AccountService {
    constructor(private http: HttpClient) { }

    login(model: LoginModel) {
        return this.http.post(environment.apiBaseUrl + 'account/token', model);
    }

    getUpdatedToken() {
        return this.http.get(environment.apiBaseUrl + 'account/get-updated-token');
    }

    recover(model: AccountRecoverModel) {
        return this.http.post(environment.apiBaseUrl + 'account/recover', model);
    }

    reset(model: AccountResetModel) {
        return this.http.post(environment.apiBaseUrl + 'account/reset', model);
    }

    activate(model: AccountActivateModel) {
        return this.http.post(environment.apiBaseUrl + 'account/activate', model);
    }

    checkEmailConfirmationToken(key: string) {
        return this.http.post(environment.apiBaseUrl + 'account/check-email-confirmation-token/' + key, null);
    }

    changePassword(model: ChangePasswordModel) {
        return this.http.post(environment.apiBaseUrl + 'account/change-password', model);
    }

    getSelectItemsByCompanyId(companyId: any) {
        return this.http.get(environment.apiBaseUrl + 'account/get-list-by-company-id/' + companyId);
    }

    getSelectItemsByCompanyIdWithAssignedToWorkOrder(companyId: any) {
        return this.http.get(environment.apiBaseUrl + 'account/get-list-by-company-id-with-assigned-to-work-order/' + companyId);
    }

    getSelectItemsByCompanyIdWithWorkOrderCreators(companyId: number, onlyOpenWorkOrders: boolean) {
        return this.http.get<AssignedUserModel[]>(
            `${environment.apiBaseUrl}account/get-users-by-company-id-with-work-order-creators/${companyId}/${onlyOpenWorkOrders}`);
    }

    checkIsUserNeedPasswordReset(token: string){
        return this.http.get(`${environment.apiBaseUrl}account/check-is-user-need-password-reset`,{ 
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    resetUserPasswordOnLogin(model: ResetPasswordModel, token: string){
        return this.http.post(environment.apiBaseUrl + 'account/reset-password-on-login', model,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
}
