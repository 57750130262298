<div class="row">
    <div class="col-md-8 offset-md-2 dataTables_wrapper dt-bootstrap4 no-footer">
        <table class="table table-hover break-all dataTable no-footer m-t-0 p-t-0">
            <thead>
                <tr>
                    <th style="width: 45%;">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox" [(ngModel)]="facility.selectAllUnits"
                                    (change)="onSelectAllUnitsChanged()" />
                                <span class="m-t--10"></span>
                            </label>
                        </div>
                        <div class="float-left m-t-4">
                            Unit Name
                        </div>
                    </th>
                    <th style="width: 25%;">
                        Status
                    </th>
                    <th style="width: 30%;">
                        Last Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of facility.units">
                    <td style="width: 35%;">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox" [(ngModel)]="u.isSelected" (change)="onUnitChanged(u)"
                                    [disabled]="u.status === 3" />
                                <span class="m-t--10"></span>
                            </label>
                        </div>
                        <div class="float-left m-t-4">
                            {{u.name}}
                        </div>
                    </td>
                    <td style="width: 15%;">
                        <app-storedge-record-status [status]="u.status">
                        </app-storedge-record-status>
                    </td>
                    <td style="width: 15%;">
                        {{u.lastUpdated}}
                    </td>
                </tr>
            </tbody>
        </table>
        <p *ngIf="facility.units.length === 0">No unit found</p>
    </div>
</div>