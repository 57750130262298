import { Component, HostBinding, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, RoleMatrix, AppSettings } from 'src/helpers';
import { SelectListItemModel, SignageAddModel, ImageCropperModel } from 'src/models';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { SignageService, CloudStorageService } from 'src/services';
import { UploadParams, BlobService, UploadConfig } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-signage-add',
    templateUrl: './signage.add.component.html'
})

export class SignageAddComponent implements OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('signageContentElem') signageContentElem: ElementRef;
    @ViewChild('signageThumbnailElem') signageThumbnailElem: ElementRef;
    @ViewChild('signageImageCropper', { static: true }) signageImageCropper: any;
    @ViewChild('signageContentImageCropper', { static: true }) signageContentImageCropper: any;

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    model: SignageAddModel = new SignageAddModel();
    processingDimension = new ImageCropperModel();

    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    content: any;
    contentType: string;
    isContentUploaded = false;
    contentUploadPercent = 0;

    thumbnail: any;
    isThumbnailUploaded = false;
    thumbnailUploadPercent = 0;

    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private cloudStorageService: CloudStorageService,
        private signageService: SignageService,
        private blobService: BlobService,
        private appSettings: AppSettings) {

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];

            this.model.companyId = this.companyId;
            this.model.facilityId = this.facilityId;
            this.model.kioskId = this.kioskId;
        });

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }
    }

    onContentSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            this.deleteContent();
            return;
        }

        this.content = event.target.files.item(0);
        this.contentType = this.content.type;
        this.isContentUploaded = false;

        if (this.contentType !== 'video/mp4'
            && this.contentType !== 'application/pdf'
            && this.contentType !== 'image/png'
            && this.contentType !== 'image/jpg'
            && this.contentType !== 'image/jpeg') {
            this.toastr.error('Only MP4, JPEG, PNG and PDF formats are allowed');
            this.content = null;
            this.model.originalContentFileName = null;
            this.signageContentElem.nativeElement.value = null;
            return;
        }

        if ((this.contentType === 'image/png'
            || this.contentType === 'image/jpg'
            || this.contentType === 'image/jpeg') && this.content.size > this.appSettings.defaultImageSize) {
            this.toastr.error('Image must be less than or equal to 15 MB');
            this.deleteContent();
            return;
        } else if (this.content.size > this.appSettings.defaultVideoSize) {
            this.toastr.error('Content file must be less than or equat to 250 MB');
            return;
        }

        if (this.contentType === 'image/png'
            || this.contentType === 'image/jpg'
            || this.contentType === 'image/jpeg') {
                this.processingDimension.fileName = this.content.name;
                this.signageContentImageCropper.openImageCropper(event);
        }
    }

    onThumbnailSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            this.content = null;
            return;
        }

        this.thumbnail = event.target.files.item(0);
        const file = event.target.files.item(0);
        const thumbnailType = this.thumbnail.type;
        this.isThumbnailUploaded = false;

        if (thumbnailType !== 'image/png'
            && thumbnailType !== 'image/jpg'
            && thumbnailType !== 'image/jpeg') {
            this.toastr.error('Only PNG and PDF formats are allowed');
            this.thumbnail = null;
            this.model.originalThumbnailFileName = null;
            this.signageThumbnailElem.nativeElement.value = null;
            return;
        }

        this.model.thumbnailFileName = file.name;
        this.processingDimension.fileName = file.name;
        this.signageImageCropper.openImageCropper(event);
    }

    deleteContent() {
        this.content = null;
        this.contentType = null;
        this.model.originalContentFileName = null;
        this.model.contentFileName = null;
        this.isContentUploaded = false;
    }

    deleteThumbnail() {
        this.thumbnail = null;
        this.model.originalThumbnailFileName = null;
        this.model.thumbnailFileName = null;
        this.isThumbnailUploaded = false;
    }

    submit() {
        this.blockUI.start();
        if (this.contentType === 'application/pdf') {
            this.isThumbnailUploaded = true;
        } else if (this.contentType !== 'video/mp4') {
            this.thumbnail = this.content;
        }
        if (this.isContentUploaded && this.isThumbnailUploaded) {
            this.addContent();
        }
        if (!this.isContentUploaded) {
            this.uploadContent();
        }
        if (!this.isThumbnailUploaded) {
            this.uploadThumbnail();
        }
    }

    async uploadContent() {
        this.cloudStorageService.getSasToken()
            .subscribe(
                async (data) => {
                    const CloudConfig: UploadParams = {
                        sas: data.toString(),
                        storageAccount: this.appSettings.AzureAccount,
                        containerName: 'signage'
                    };
                    const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(this.content.name)}`;
                    const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
                    AppSettings.NonBearerUrl = blobUrl;
                    this.config = {
                        baseUrl: `${blobUrl}?`,
                        sasToken: CloudConfig.sas,
                        blockSize: 1024 * 64,
                        file: this.content,
                        complete: () => {
                            this.model.contentFileName = fileName;
                            this.model.contentFileType = this.contentType;
                            this.isContentUploaded = true;
                            this.addContent();
                        },
                        error: (err) => {
                            console.log(err);
                            this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                            this.blockUI.stop();
                        },
                        progress: (percent) => {
                            this.contentUploadPercent = percent;
                        }
                    };
                    setTimeout(() => {
                        this.blobService.upload(this.config);
                    });
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    uploadThumbnail() {
        this.signageService.uploadThumbnail(this.thumbnail, this.processingDimension)
            .subscribe(
                (e: HttpEvent<any>) => {
                    switch (e.type) {
                        case HttpEventType.UploadProgress:
                            const percentDone = Math.round(100 * e.loaded / e.total);
                            this.contentUploadPercent = percentDone;
                            break;
                        case HttpEventType.Response:
                            this.model.thumbnailFileName = e.body.fileName;
                            this.isThumbnailUploaded = true;
                            this.addContent();
                            break;
                    }
                },
                (error) => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                    this.blockUI.stop();
                });
    }

    addContent() {
        if (!this.isContentUploaded || !this.isThumbnailUploaded) {
            return;
        }
        this.signageService.add(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/signage/manage', this.companyId, this.facilityId, this.kioskId]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Signage content has been added successfully.');
                    }, 300);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    ngOnDestroy() {
        this.blockUI.stop();
        this.deleteContent();
        this.deleteThumbnail();
    }
}
