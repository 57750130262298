import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel, ListWithLinkModel } from '../../../models';
import { ActivityLogActionTypeEnum, ActivityLogIdentifiersEnum } from 'src/helpers';

@Component({
    selector: 'app-activity-log-facility-activities',
    templateUrl: './activity.log.facility.activities.component.html'
})

export class ActivityLogFacilityActivitiesComponent implements OnInit {
    @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

    assignedUserGroups: ListWithLinkModel[];
    removedUserGroups: ListWithLinkModel[];

    get ActivityLogType () {
        return ActivityLogActionTypeEnum;
    }

    ngOnInit() {
        this.model.entityLink = '/facility/detail/' + this.model.companyId + '/' + this.model.entityId;

        if (this.model.fieldEntityType === 2) {

            const queryParams = '{"companyId" : "' + this.model.companyId + '", "userType":"any"}';

            if (this.model.oldId) {
                this.model.oldValueLink = '/company-user/detail/' + this.model.oldId;
                this.model.oldValueParams = JSON.parse(queryParams);
            }

            if (this.model.newId) {
                this.model.newValueLink = '/company-user/detail/' + this.model.newId;
                this.model.newValueParams = JSON.parse(queryParams);
            }
        }
        if (this.model.actionType === ActivityLogActionTypeEnum.AssignedUserGroups) {
            this.assignedUserGroups = this.model.customData.map(x => ({
                name: x.entityValue,
                link: `/company/${this.model.companyId}/user-group/${x.entityId}/detail`
            }));
        } else if (this.model.actionType === ActivityLogActionTypeEnum.RemovedUserGroups) {
            this.removedUserGroups = this.model.customData.map(x => ({
                name: x.entityValue,
                link: `/company/${this.model.companyId}/user-group/${x.entityId}/detail`
            }));
        } else if (this.model.actionType === ActivityLogActionTypeEnum.UpdatedUserGroups) {
            this.assignedUserGroups = this.model.customData
                .filter(x => x.identifier === ActivityLogIdentifiersEnum.UserGroupAssigned)
                .map(x => ({
                    name: x.entityValue,
                    link: `/company/${this.model.companyId}/user-group/${x.entityId}/detail`
                }));

            this.removedUserGroups = this.model.customData
                .filter(x => x.identifier === ActivityLogIdentifiersEnum.UserGroupRemoved)
                .map(x => ({
                    name: x.entityValue,
                    link: `/company/${this.model.companyId}/user-group/${x.entityId}/detail`
                }));
        }
    }
}
