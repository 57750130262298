import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DragulaModule } from 'ng2-dragula';
import { ChartModule } from 'angular-highcharts';
import { BlobModule } from 'angular-azure-blob-service';
import { appRouting } from './app.routing';
import { NgImageSliderModule } from 'ng-image-slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { ImageCropperModule } from 'ngx-image-cropper';

export const appImports = [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgbModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    DataTablesModule,
    ToastrModule.forRoot(),
    BlockUIModule.forRoot({ message: 'Loading...' }),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DragulaModule.forRoot(),
    ImageCropperModule,
    appRouting,
    ChartModule,
    BlobModule.forRoot(),
    NgImageSliderModule,
    ColorPickerModule,
    InternationalPhoneNumberModule,
    
];

