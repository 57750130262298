import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService, VendorService } from '../../../../services';

@Component({
    selector: 'app-dashboard-company-vendor-tile',
    templateUrl: './dashboard.company.vendor.tile.component.html'
})
export class DashboardCompanyVendorTileComponent {
    @Input() companyId: number;
    @Output() tileLoaded = new EventEmitter<any>();

    isModelLoaded = false;
    totalVendors = 0;
    showPersonalData = false;

    constructor(private vendorService: VendorService,
        private masterService: MasterService,
        private router: Router) { }

    loadVendorCounts(showPersonalData: boolean) {
        this.showPersonalData = showPersonalData;
        this.vendorService.getCountByCompany(this.companyId, showPersonalData)
            .subscribe(
                (data: number) => {
                    this.totalVendors = data;
                    this.isModelLoaded = true;
                    this.tileLoaded.emit();
                },
                error => {
                    console.log('DaskboardCompanyVendorTileComponent load failed');
                    console.log(error);
                });
    }

    onVendorTileClicked () {
        this.masterService.setVendorShowPersonnalData(this.showPersonalData);
        this.router.navigate(['/vendor/manage', this.companyId]);
    }
}
