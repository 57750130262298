<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-facility-edit [companyId]="companyId">
            </app-breadcrumb-facility-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Facility
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="submit(f)" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row">
                                <label class="col-md-3">Company ID:</label>
                                <div class="col-md-9">
                                    <span class="badge badge-default fs-14 m-0 p-0">
                                        <strong>ID{{model.companyId}}</strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && faclityName.invalid}">
                                <label class="col-md-3">Facility Name:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="faclityName" class="form-control" maxlength="100"
                                        autocomplete="off" [(ngModel)]="model.name" #faclityName="ngModel"
                                        appAlphaNumericsLevelThree required />
                                    <div class="validation-error-message" *ngIf="f.submitted && faclityName.invalid">
                                        Facility name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && address.invalid}">
                                <label class="col-md-3">Address*:</label>
                                <div class="col-md-9">
                                    <input type="text" name="address" class="form-control" maxlength="200"
                                        autocomplete="off" [(ngModel)]="model.address" #address="ngModel"
                                        appAlphaNumericsLevelFour required />
                                    <div class="validation-error-message" *ngIf="f.submitted && address.invalid">
                                        The address is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && zipCode.invalid}">
                                <label class="col-md-3">Zip Code*:</label>
                                <div class="col-md-6">
                                    <input type="text" name="zipCode" class="form-control" maxlength="10"
                                        autocomplete="off" [(ngModel)]="model.zipCode" #zipCode="ngModel" appZipCodeOnly
                                        required />
                                    <div class="validation-error-message" *ngIf="f.submitted && zipCode.invalid">
                                        Zip code is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && typeOfConstruction.invalid}">
                                <label class="col-md-3">Type of Construction*:</label>
                                <div class="col-md-6">
                                    <input type="text" name="typeOfConstruction" class="form-control" maxlength="20"
                                        autocomplete="off" [(ngModel)]="model.typeOfConstruction"
                                        #typeOfConstruction="ngModel" appAlphaNumericsOnly required />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && typeOfConstruction.invalid">
                                        Type of construction is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3">Climate Controller:</label>
                                <div class="col-md-9">
                                    <input type="checkbox" name="climateControlled"
                                        [(ngModel)]="model.climateControlled" #climateControlled="ngModel" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3">User Groups Assigned:</label>
                                <div class="col-md-6">
                                    <ng-multiselect-dropdown name="ClientUserGroupsAssigned" [placeholder]="'[Select]'"
                                        [(ngModel)]="selectedUserGroups"
                                        [settings]="multiSelectDropdownConfigs"
                                        [data]="userGroups"
                                        (onSelect)="onUserGroupSelectDeSelect()" (onSelectAll)="onUserGroupSelectDeSelectAll($event)"
                                        (onDeSelect)="onUserGroupSelectDeSelect()" (onDeSelectAll)="onUserGroupSelectDeSelectAll($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && propertyManager.invalid}">
                                <label class="col-md-3">Property Manager*:</label>
                                <div class="col-md-6">
                                    <select class="form-control" name="propertyManager" aria-placeholder="[Select]"
                                        [(ngModel)]="model.propertyManagerUserId" #propertyManager="ngModel">
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of companyUsers" [value]="item.key">
                                            {{item.value}}</option>
                                    </select>                                   
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && propertyManager.invalid">
                                        <span *ngIf="propertyManager.errors.required">
                                            Property manager is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && defaultAssigneeId.invalid}">
                                <label class="col-3">Default work order assignee:</label>
                                <div class="col-6">
                                    <select class="form-control" name="defaultAssigneeId" (change)="setPrevDefaultAssignee()"
                                        [(ngModel)]="model.defaultAssigneeId" #defaultAssigneeId="ngModel">
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of defaultAssigneeClientUsers" [value]="item.key">
                                            {{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && complaintsAssigneeId.invalid}">
                                <label class="col-3">Default Assignee for customers complaints:</label>
                                <div class="col-6">
                                    <select class="form-control" name="complaintsAssigneeId" (change)="setPrevComplainAssignee()"
                                        [(ngModel)]="model.complaintsAssigneeId" #complaintsAssigneeId="ngModel">
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of complaintsAssigneeClientUsers" [value]="item.key">
                                            {{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && moveOutAssigneeId.invalid}">
                                <label class="col-3">Default Assignee for Move Out:</label>
                                <div class="col-6">
                                    <select class="form-control" name="moveOutAssigneeId"  (change)="setPrevMoveOutAssignee()"
                                        [(ngModel)]="model.moveOutAssigneeId" #moveOutAssigneeId="ngModel">
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of moveOutAssigneeClientUsers" [value]="item.key">
                                            {{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && moveInAssigneeId.invalid}">
                                <label class="col-3">Default Assignee for Move In:</label>
                                <div class="col-6">
                                    <select class="form-control" name="moveInAssigneeId"  (change)="setPrevMoveInAssignee()"
                                        [(ngModel)]="model.moveInAssigneeId" #moveInAssigneeId="ngModel">
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of moveInAssigneeClientUsers" [value]="item.key">
                                            {{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && phoneNumber.invalid}">
                                <label class="col-md-3">Jive Line:</label>
                                <div class="col-md-6" style="margin-left: -54px;">
                                    <international-phone-number [(ngModel)]="model.phone" placeholder="Enter phone number" 
                                    [maxlength]="15" [defaultCountry]="'us'" #phoneNumber="ngModel" name="phone_number">
                                    </international-phone-number>
                                    <div class="validation-error-message" *ngIf="f.submitted && phoneNumber.invalid">
                                        Phone number should be valid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/facility/manage',companyId]"
                                    class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>