import { Component, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { QuickBooksConfigurationModel, SelectListItemModel } from "src/models";

@Component({
    selector: 'app-quickbooks-edit',
    templateUrl: './quickbooks.edit.component.html'
})
export class QuickBooksEditComponent implements OnInit {
    @BlockUI('container-blockui-edit') blockUI: NgBlockUI;
model:QuickBooksConfigurationModel=new QuickBooksConfigurationModel();

@Input() facilities;

    constructor(private route: ActivatedRoute,){
        this.route.params.subscribe((params) => {
            this.model.internalCompanyId = params['companyId'];
            this.model.facilityId=params['facilityId'];
            console.log(this.facilities);
        });  
    }


    ngOnInit(){
    }

    

}