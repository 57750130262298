import { FacilityService } from '@10fed/services/facility.service';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { InfoModalComponent } from 'src/components/shared';
import { RoleMatrix, AppUtils } from 'src/helpers';
import { FacilityNoteModel } from 'src/models/facility/facility.note.model';

@Component({
  selector: 'app-facility-note-history',
  templateUrl: './facility-note-history.component.html'
})
export class FacilityNoteHistoryComponent implements OnInit {
  roleMatrix: RoleMatrix;
  @HostBinding('class.full_width') full_width = true;
  @ViewChild('addWorkOrderNote') addWorkOrderNote: any;
  @BlockUI('container-blockui') blockUI: NgBlockUI;
  @BlockUI('container-blockui-note') noteBlockUI: NgBlockUI;

  companyId = -1;
  facilityId = -1;
  isModelLoaded = false;
  notes: Array<FacilityNoteModel> = new Array<FacilityNoteModel>();
  currentUserId: string;

  constructor(private router: Router,
      private route: ActivatedRoute,
      private modalService: NgbModal,
      private toastr: ToastrService,
      private facilityService: FacilityService,
      private appUtils: AppUtils) {
      this.roleMatrix = new RoleMatrix();

      this.route.params.subscribe((params) => {
          this.companyId = params['companyId'];
          this.facilityId = params['id'];
      });

      if (this.roleMatrix.companyId !== this.companyId.toString()) {
          this.router.navigate(['/error/404']);
      }

      this.currentUserId = this.appUtils.getUserId();
  }

  ngOnInit() {
      this.loadNotes();
  }

  loadNotes() {
      this.blockUI.start();
      this.facilityService.getNotes(this.facilityId)
          .subscribe(
              (data: any) => {
                  this.blockUI.stop();
                  this.notes = new Array<FacilityNoteModel>();
                  Object.assign(this.notes, data);
                  this.isModelLoaded = true;
                  this.notes.map((item) => {
                      if ((item.createdByName !== 'System' && item.createdByRole === 'SUPERADMIN') ||
                               item.createdByRole === 'ADMIN' || item.createdByRole === 'USER' ||
                              (item.createdByName !== 'System' && item.createdByRole === 'SuperAdmin') ||
                               item.createdByRole === 'Admin' || item.createdByRole === 'User') {
                              item.createdByName = 'Admin';
                          }
                      item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                  });
              },
              error => {
                  this.blockUI.stop();
                  this.appUtils.ProcessErrorResponse(this.toastr, error);
              });
  }

  showNote(note: string) {
      const modalRef = this.modalService.open(InfoModalComponent, { size: 'lg' });
      const componentInstance = (modalRef.componentInstance as InfoModalComponent);
      componentInstance.title = 'Note';
      componentInstance.info = note;
  }
}
