<ng-template #modal let-modal>
    <div *blockUI="'container-blockui-notifications-preferences'">
        <div class="modal-header">
            <h5 class="modal-title">Turn Off Notification</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12" *ngIf="isCreator">
                <div class="form-group row">
                    <label>
                        Send read receipt notifications to me:
                    </label>
                    <div class="col-12">
                        <div class="float-left">
                            <label class="fs-md">Disable</label>
                            <label class="switch switch-sm ml-2 mr-2">
                                <input type="checkbox" id="enableReadNotification"
                                name="enableReadNotification" [(ngModel)]="notificationsPreferences.enableReadNotification" class="primary"/>
                                <span class="slider slider-sm round"></span>
                            </label>
                        </div>
                        <label class="float-left" for="enableReadNotification">
                            Enable
                        </label>
                    </div>    
                    <!-- <div class="col-12">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox"
                                    id="sendReadReceiptEmailNotifications"
                                    name="sendReadReceiptEmailNotifications"
                                    [(ngModel)]="notificationsPreferences.sendReadReceiptEmailNotifications" />
                                <span></span>
                            </label>
                        </div>
                        <label class="float-left m-t-4" for="sendReadReceiptEmailNotifications">
                            Send email notification
                        </label>
                    </div>
                    <div class="col-12">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox"
                                    id="sendReadReceiptInAppNotifications"
                                    name="sendReadReceiptInAppNotifications"
                                    [(ngModel)]="notificationsPreferences.sendReadReceiptInAppNotifications" />
                                <span></span>
                            </label>
                        </div>
                        <label class="float-left m-t-4" for="sendReadReceiptInAppNotifications">
                            Send In app notification
                        </label>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-12">
                <div class="form-group row">
                    <label>
                        Send edit workorder notifications to me:
                    </label>
                    <div class="col-12">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox"
                                    id="sendEmailNotificationsToWatchers"
                                    name="sendEmailNotificationsToWatchers"
                                    [(ngModel)]="notificationsPreferences.sendEmailNotificationsToWatchers" />
                                <span></span>
                            </label>
                        </div>
                        <label class="float-left m-t-4" for="sendEmailNotificationsToWatchers">
                            Send email notification
                        </label>
                    </div>
                    <div class="col-12">
                        <div class="float-left">
                            <label class="kt-checkbox kt-checkbox--brand">
                                <input type="checkbox"
                                    id="sendInAppNotificationsToWatchers"
                                    name="sendInAppNotificationsToWatchers"
                                    [(ngModel)]="notificationsPreferences.sendInAppNotificationsToWatchers" />
                                <span></span>
                            </label>
                        </div>
                        <label class="float-left m-t-4" for="sendInAppNotificationsToWatchers">
                            Send In app notification
                        </label>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="modal-footer">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5" (click)="closeModal()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="saveNotificationsPreferences()">Submit</button>
            </div>
        </div>
    </div>
</ng-template>
