import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from '../../../helpers';
import { SignageService, KioskService } from '../../../services';
import { SignageSummaryListItemModel, KioskChangeApplicationModel } from '../../../models';
import { ImageViewerModalComponent, VideoViewerModalComponent, PdfViewerModalComponent } from '../../partial';

declare var appConfig: any;

@Component({
    selector: 'app-kiosk-detail-signage',
    templateUrl: './kiosk.detail.signage.component.html'
})

export class KioskDetailSignageComponent implements OnInit {
    @BlockUI('container-blockui-signage') blockUI: NgBlockUI;
    @ViewChild('imageViewerModal', { static: true }) imageViewerModal: ImageViewerModalComponent;
    @ViewChild('videoViewerModal', { static: true }) videoViewerModal: VideoViewerModalComponent;
    @ViewChild('pdfViewerModal', { static: true }) pdfViewerModal: PdfViewerModalComponent;
    @Input() companyId: number;
    @Input() facilityId: number;
    @Input() kioskId: number;
    @Output() refreshKioskDetail = new EventEmitter<number>();

    isModelLoaded = false;
    isSignageType = false;
    signages: Array<SignageSummaryListItemModel> = new Array<SignageSummaryListItemModel>();
    showActionColumn = true;

    constructor(private toastr: ToastrService,
        private appUtils: AppUtils,
        private signageService: SignageService,
        private kioskService: KioskService) { }

    ngOnInit() {
        this.loadSignages();
    }

    loadSignages() {
        this.blockUI.start();
        this.signageService.getSummaryList(this.kioskId)
            .subscribe(
                (data: any) => {
                    Object.assign(this.signages, data);
                    this.signages.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDate(item.createdOn);
                        if (item.contentFileType === 'PDF') {
                            item.thumbnailFileUrl = './assets/media/pdf-thumbnail.png';
                        } else if (item.contentFileType === 'Image' && !item.thumbnailFileUrl) {
                            item.thumbnailFileUrl = item.contentFileUrl;
                        }
                    });
                    setTimeout(() => {
                        appConfig.initKTDefaults();
                    }, 1000);
                    this.isModelLoaded = true;
                    this.blockUI.stop();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    showContent(selectedItem: SignageSummaryListItemModel) {
        if (selectedItem.contentFileType === 'Image') {
            this.imageViewerModal.openImageViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        } else if (selectedItem.contentFileType === 'Video') {
            this.videoViewerModal.openVideoViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        } else if (selectedItem.contentFileType === 'PDF') {
            this.pdfViewerModal.openPdfViewerModal(selectedItem.title, selectedItem.contentFileUrl);
        }
    }

    chagneApplicationType() {
        const model = new KioskChangeApplicationModel();

        model.id = this.kioskId;
        model.applicationType = 2;
        model.isActive = this.isSignageType;

        this.blockUI.start();
        this.kioskService.changeApplicationType(model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success('Application type has been changed successfully');
                    this.refreshKioskDetail.emit();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateApplicationType(applicationType: number) {
        this.isSignageType = applicationType && (applicationType === 2 || applicationType === 3);
    }

    public updateShowActionColumn() {
        this.showActionColumn = false;
    }
}
