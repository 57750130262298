import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../helpers';
import { NotificationPagedResult } from 'src/models';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient,
    private appSettings: AppSettings) { }

  getAll() {
    return this.http.get(environment.apiBaseUrl + 'notification/get-all');
  }

  getPagedResult(pageNumber: number, pageSize: number) {
    return this.http.get<NotificationPagedResult>(`${environment.apiBaseUrl}notification/get-paged-result/${pageNumber}/${pageSize}`);
  }

  markAsRead(notificationId: string) {
    return this.http.post(environment.apiBaseUrl + 'notification/mark-as-read/' + notificationId, null);
  }

  markAllAsRead() {
    return this.http.post(`${environment.apiBaseUrl}notification/mark-all-as-read`, null);
  }

  removeAssignee(notificationId: string) {
    return this.http.post(environment.apiBaseUrl + 'notification/remove-assignee/' + notificationId, null);
  }

}
