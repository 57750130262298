import { UserGroupMemberListItemModel } from './user.group.member.list.item.model';
import { RecordStatus } from './record.status';

export class UserGroupModel {
  public id: string;
  public name: string;
  public companyId: number;
  public members: Array<UserGroupMemberListItemModel>;
  public status: RecordStatus;
  public lowPriorityWorkOrdersReminderInterval: number;
  public mediumPriorityWorkOrdersReminderInterval: number;
  public highPriorityWorkOrdersReminderInterval: number;

  public raiseEvent: boolean;
  public onlyUserGroupFacilities: Array<FacilityAssigneesValidationDto>;
  public multipleUserGroupFacilities: Array<FacilityAssigneesValidationDto>;
}

export class UserGroupDefaultAssigneeValidationModel {
  public userGroupInOperation: string;
  public companyId: number;
  public facilityAssigneeValidationModels: FacilityAssigneeValidationModel[];
}

export class FacilityAssigneeValidationModel {
  public id: number;
  public defaultAssigneeId: string;
  public defaultAssigneeCustomerComplaints: string;
  public defaultAssigneeMoveOut: string;
  public defaultAssigneeMoveIn: string;
  public userGroupIds: string[];
}

export class FacilityAssigneesValidationDto {
  public id: number;
  public defaultAssigneeId: string;
  public defaultAssigneeCustomerComplaints: string;
  public defaultAssigneeMoveOut: string;
  public defaultAssigneeMoveIn: string;
  public defaultAssigneeExistsInOtherUserGroup: boolean;
  public defaultAssigneeCustomerComplaintExistsInOtherUserGroup: boolean;
  public defaultAssigneeMoveOutExistsInOtherUserGroup: boolean;
  public defaultAssigneeMoveInExistsInOtherUserGroup: boolean;
}
