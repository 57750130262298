import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { AppSettings } from '../helpers';
import { MoveinAmenityAddModel, MasterDataModel, FieldDetailModel, DocumentFileModel, SkinningColorModel, SkinningLogoModel, CallCenterPhoneNumberModel } from 'src/models';

@Injectable()
export class MoveInService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    getAmenityTypes(facilityId: number, kioskId: number) {
        return this.http.get(environment.apiBaseUrl + `movein/get-amenity-type-select-list-items/${facilityId}/${kioskId}`);
    }

    uploadIcon(file: any) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(environment.apiBaseUrl + 'movein/upload-amenity-icon', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    changeDocumentFile(model: DocumentFileModel) {
        return this.http.post(environment.apiBaseUrl + 'movein/change-document-file', model);
    }

    addAmenity(model: MoveinAmenityAddModel) {
        return this.http.post(environment.apiBaseUrl + 'movein/add-amenity', model);
    }

    deleteAmenity(id: number) {
        return this.http.post(environment.apiBaseUrl + 'movein/delete-amenity/' + id, null);
    }

    getConfiguration(model: MasterDataModel) {
        const queryParams = `?companyId=${model.companyId}&facilityId=${model.facilityId}&kioskId=${model.kioskId}`;
        return this.http.get(environment.apiBaseUrl + `movein/get-kiosk-configuration${queryParams}`);
    }

    getMandatoryFields(model: MasterDataModel) {
        const queryParams = `?companyId=${model.companyId}&facilityId=${model.facilityId}&kioskId=${model.kioskId}`;
        return this.http.get(environment.apiBaseUrl + `movein/get-mandatory-fields${queryParams}`);
    }

    getAmenityCount(model: MasterDataModel) {
        const queryParams = `?companyId=${model.companyId}&facilityId=${model.facilityId}&kioskId=${model.kioskId}`;
        return this.http.get(environment.apiBaseUrl + `movein/get-total-amenities${queryParams}`);
    }

    changeStateView(id: number, stateView: number) {
        return this.http.post(environment.apiBaseUrl + `movein/change-state-view/${id}/${stateView}`, null);
    }

   

    changeKeyboard(id: number, keyboard: number) {
        return this.http.post(environment.apiBaseUrl + `movein/change-keyboard/${id}/${keyboard}`, null);
    }

    changeMandatoryFieldStatus(fieldDetail: FieldDetailModel) {
        return this.http.post(environment.apiBaseUrl + `movein/change-mandatory-field-status`, fieldDetail);
    }

    saveAmenitySkinningColor(model: MasterDataModel, skinningColorModel: SkinningColorModel) {        
        return this.http.post(environment.apiBaseUrl + `movein/${model.companyId}/${model.facilityId}/${model.kioskId}/amenity-skinning-color`, skinningColorModel);
    }

    saveAmenitySkinningLogo(model: MasterDataModel, skinningLogoModel: SkinningLogoModel) {        
        return this.http.post(environment.apiBaseUrl + `movein/${model.companyId}/${model.facilityId}/${model.kioskId}/amenity-skinning-logo`, skinningLogoModel);
    }

    saveCallCenterPhoneNumber(model: MasterDataModel, callCenterPhoneNumberModel: CallCenterPhoneNumberModel){
        return this.http.post(environment.apiBaseUrl + `movein/${model.companyId}/${model.facilityId}/${model.kioskId}/call-center-phone-number`, callCenterPhoneNumberModel);
    }
}
