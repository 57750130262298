import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: 'hyperlink'
})
export class HyperLinkPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) { }

    transform(value: string, limitChars: number): SafeHtml {
        if(!value) return value;
        // Trim text on the basis of character limit provided and append ellipsis
        // Also keeping check if the word breaks due to limit then including the complete word to show
        if (limitChars && value.length > limitChars) {
            let spacebreakIndices = this.getIndicesOf(' ', value, false);
            let linebreakIndices = this.getIndicesOf('\n', value, false);
            let breakInputIndices = (spacebreakIndices.concat(linebreakIndices)).sort(function(a, b){return a - b});
            if (breakInputIndices.length > 0) {
                let extendedLimit = breakInputIndices.find(x => x >= limitChars);
                if (!extendedLimit) {
                    extendedLimit = breakInputIndices.filter(x => x < limitChars).reverse()[0];
                }
                value = value.substr(0, extendedLimit) + ' ... ';
            }
        }

        // parse links in the text and replace link with anchor tag
        let linebreakArray = value.split('\n');
        linebreakArray.forEach((x, i) => {
            let spacebreakArray = x.split(' ');
            if (spacebreakArray.filter(x => x.indexOf("http") >= 0).length > 0) {
                spacebreakArray.forEach((y, j) => {
                    if(x.indexOf("http") >= 0)
                    {
                        try {
                            let url = new URL(y);
                            spacebreakArray[j] = "<a href='" + y + "' target='_blank'>" + y + "</a>";
                        } catch (error) { }
                    }
                })
                linebreakArray[i] = spacebreakArray.join(' ');
            }
        });
        value = linebreakArray.join('<br />');
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }

    //get all indices in array for a charater present in a string
    getIndicesOf(searchStr: string, str: string, caseSensitive: boolean): number[] {
        var searchStrLen = searchStr.length;
        if (searchStrLen == 0) {
            return [];
        }
        var startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }
}