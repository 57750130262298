import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService, UserGroupService } from '../../../services';
import { UserDetailModel, UserImageUploadModel } from '../../../models';
import { AppUtils, RoleMatrix, RoutingState } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { first } from 'rxjs/operators';
@Component({
    selector: 'app-company-user-profile-detail',
    templateUrl: './company.user.profile.detail.component.html'
})

export class CompanyUesrProfileDetailComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model: UserDetailModel = new UserDetailModel();
    imageUploadModel: UserImageUploadModel = new UserImageUploadModel();
    isModelLoaded = false;
    priviousPageUrl = '/';
    showActionColumn = true;
    userGroups: string[] = [];
    notificationPreferencesText = '';
    notificationPreferencesAsWatcherText = '';

    timeZoneFormattedName: string;

    constructor(private router: Router,
        private toastr: ToastrService,
        private routingState: RoutingState,
        private appUtils: AppUtils,
        private userService: UserService,
        private userGroupService: UserGroupService,
        private timeZoneService: TimeZoneService) {

        this.model.id = this.appUtils.getUserId();

        this.roleMatrix = new RoleMatrix();
        if (this.appUtils.is10FedUser(this.roleMatrix.role)) {
            this.router.navigate(['/error/404']);
        }

        this.imageUploadModel.canEditProfileImage = true;
    }

    ngOnInit(): void {
        this.priviousPageUrl = this.routingState.getPreviousUrl();
        this.blockUI.start();
        this.userService.getCompanyUserDetail(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();
                Object.assign(this.model, data);
                this.model.createdOn = this.appUtils.getFormattedDateTime(this.model.createdOn);
                this.model.role = this.appUtils.getNormalizedRole(this.model.role);
                this.imageUploadModel.userId = this.model.id;
                this.imageUploadModel.fileUrl = this.model.profileImageUrl;
                this.isModelLoaded = true;

                this.loadTimeZoneFormattedName();
                this.setNotificationText();
                this.updateConfigureAllowActions();

                if (this.model.status === 3) {
                    this.showActionColumn = false;
                    this.imageUploadModel.canEditProfileImage = false;
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
        this.loadUsersGroups();
    }

    setNotificationText() {
        var tempArr = [];
        if (this.model.sendEmailNotification) {
            tempArr.push('Email');
        }
        if (this.model.sendInAppNotification) {
            tempArr.push('In App');
        }
        if (this.model.sendPushNotification) {
            tempArr.push('Push');
        }
        this.notificationPreferencesAsWatcherText = tempArr.join(',&nbsp;')

        tempArr = [];
        if (this.model.enableEmailNotification) {
            tempArr.push('Email');
        }
        if(this.model.enableInAppNotification){
            tempArr.push('In-App');
        }
        if(this.model.enablePushNotification) {
            tempArr.push('Push')
        }
        this.notificationPreferencesText = tempArr.join(',&nbsp;')
    }

    updateConfigureAllowActions() {
        if (this.model.status === 3) {
            this.roleMatrix.configureAllowActionsForOrgUser();
            this.roleMatrix.configureAllowActionsForOrgAdmin();
            this.imageUploadModel.canEditProfileImage = false;
            this.showActionColumn = false;
        }
    }

    loadUsersGroups() {
        this.blockUI.start();
        this.userGroupService.getUsersGroups(this.model.id).subscribe((data: any) => {
            this.blockUI.stop();
            this.userGroups = data;
        }, error => {
            this.blockUI.stop();
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
    }

    private loadTimeZoneFormattedName() {
        this.timeZoneService.getTimeZoneFormattedName(this.model.timeZone)
            .pipe(first())
            .subscribe(
                name => {
                    this.timeZoneFormattedName = name;
                }
            );
    }
}
