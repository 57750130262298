import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FacilityService, MasterService } from '../../../../services';

@Component({
    selector: 'app-dashboard-company-facility-tile',
    templateUrl: './dashboard.compnay.facility.tile.component.html'
})
export class DashboardCompanyFacilityTileComponent {
    @Input() companyId: number;
    @Output() tileLoaded = new EventEmitter<any>();

    isModelLoaded = false;
    totalFacilities = 0;
    totalUnits = 0;
    showPersonalData = false;

    constructor(private facilityService: FacilityService,
        private masterService: MasterService,
        private router: Router) { }

    loadFacilitiesCount(showPersonalData: boolean) {
        this.showPersonalData = showPersonalData;
        this.facilityService.getCountByCompanyId(this.companyId, showPersonalData)
            .subscribe(
                (data: any) => {
                    this.totalFacilities = data;
                    this.isModelLoaded = true;
                    this.tileLoaded.emit();
                },
                error => {
                    console.log('DashboardCompanyFacilityTileComponent load failed');
                    console.log(error);
                });
    }

    onFacilityTileClicked () {
        this.masterService.setFacilityShowPersonnalData(this.showPersonalData);
        this.router.navigate(['/facility/manage', this.companyId]);
    }
}
