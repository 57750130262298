<div class="col-md-12">
    <div
        class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
        <div class="kt-ribbon__target m-t-15 l--20">
            <span class="kt-ribbon__inner"></span> State View
        </div>
        <div class="kt-portlet__head-label">
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">
        <div class="row">
            <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                <div class="form-group col-md-12">
                    <p>
                        Choose how you want to display all the states in the kiosk machine
                    </p>
                </div>
                <div class="col-md-12">
                    <div class="kt-radio-list">
                        <div class="form-group">
                            <label class="kt-radio kt-radio--brand">
                                <input type="radio" name="stateView" [(ngModel)]='stateViewModel.stateView' [value]="0"
                                    (change)='changeStateView(0)'> Dropdown View
                                <span></span>
                            </label>
                            <div class="row">
                                <div class="col-md-12">
                                    The states will be visible in a dropdown to the users
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="kt-radio kt-radio--brand">
                                <input type="radio" name="stateView" [(ngModel)]='stateViewModel.stateView' [value]="1"
                                    (change)='changeStateView(1)'> Pop Up View
                                <span></span>
                            </label>
                            <div class="row">
                                <div class="col-md-12">
                                    A pop up will open up displaying all the states in the kiosk machine with
                                    button to confirm or discard the selection.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-md-12" *blockUI="'container-blockui-addon'">
    <div
        class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
        <div class="kt-ribbon__target m-t-15 l--20">
            <span class="kt-ribbon__inner"></span> Mandatory Fields
        </div>
        <div class="kt-portlet__head-label">
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">
        <div class="row ">
            <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                <div class="form-group" *ngFor="let item of fieldDetails">
                    <div class="col-md-12">
                        <label class="kt-checkbox kt-checkbox--brand">
                            <input type="checkbox" [(ngModel)]="item.isRequired" (change)="changeMandatoryField(item)"
                                #isRequired="ngModel" /> {{item.name}}
                            <span></span>
                        </label>
                    </div>
                    <div class="col-md-12">
                        {{item.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div
        class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
        <div class="kt-ribbon__target m-t-15 l--20">
            <span class="kt-ribbon__inner"></span> Keyboard Preference
        </div>
        <div class="kt-portlet__head-label">
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">
        <div class="row">
            <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                <div class="form-group col-md-12">
                    <p>
                        Select the keyboard that you want to use for your kiosk machine
                    </p>
                </div>
                <div class="col-md-12">
                    <div class="kt-radio-list">
                        <div class="form-group">
                            <label class="kt-radio kt-radio--brand">
                                <input type="radio" name="keyboardPreference"
                                    [(ngModel)]='keyboardModel.keyboardPreference' [value]="0"
                                    (change)='changeKeyboardPreference(0)'> Software Keyboard
                                <span></span>
                            </label>
                            <div class="row">
                                <div class="col-md-12">
                                    Selecting the option will open up the software keyboard in the kiosk machine
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="kt-radio kt-radio--brand">
                                <input type="radio" name="keyboardPreference"
                                    [(ngModel)]='keyboardModel.keyboardPreference' [value]="1"
                                    (change)='changeKeyboardPreference(1)'> Hardware Keyboard
                                <span></span>
                            </label>
                            <div class="row">
                                <div class="col-md-12">
                                    Selecting this option will open up the hardware keyboard in the kiosk machine
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12" *blockUI="'container-blockui-addon'">
    <div
        class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
        <div class="kt-ribbon__target m-t-15 l--20">
            <span class="kt-ribbon__inner"></span> Document File
        </div>
        <div class="kt-portlet__head-label">
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">
        <div class="row ">
            <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                <div class="form-group" *ngFor="let item of fieldDetails">
                    <div class="col-md-12" *ngIf="configurationModel.documentFileName && !isChangeDocumentFile">
                        <button type="button" class="btn btn-xs btn-outline-brand" (click)="openPdfViewer()">
                            View
                        </button>
                        <button type="button" class="btn btn-xs btn-outline-brand ml-3" (click)="changeDocumentFile()">
                            Change
                        </button>
                    </div>
                    <div class="col-md-12" *ngIf="isChangeDocumentFile">
                        <button type="button" class="btn btn-sm btn-outline-brand m-b-5"
                            [disabled]="isDocumentUploading" (click)="documentFileName.click()">
                            Choose file
                        </button>
                        <ngb-progressbar class="m-t-5 m-b-5" type="info" [value]="documentUploadPercent" height="5px"
                            *ngIf="documentUploadPercent > 0 && documentUploadPercent < 100">
                        </ngb-progressbar>
                        <input type="file" name="documentFileName" class="d-none" accept="application/pdf,"
                            #documentFileName (change)="onDoculmentSelected($event)" />
                    </div>
                    <div class="col-md-12" *ngIf="configurationModel.documentFileName && isChangeDocumentFile">
                        <button type="button" class="btn btn-xs btn-outline-dark mt-2 ml-3"
                            (click)="resetDocumentFile()">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12" *blockUI="'container-blockui-addon'">
    <div
        class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
        <div class="kt-ribbon__target m-t-15 l--20">
            <span class="kt-ribbon__inner"></span> Call Center Phone Number
        </div>
        <div class="kt-portlet__head-label">
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">

        <div class="row ">
            <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                <div class="form-group col-md-12">
                    <div class="row col-md-4">
                        <input type="text" name="callCenterPhoneNumber" class="form-control" minlength="7"
                            maxlength="13" autocomplete="off" [(ngModel)]="callCenterPhoneNumberModel.phoneNumber"
                            #callCenterPhoneNumber="ngModel"
                            (change)="callCenterPhoneNumber.valid && changeCallCenterPhoneNumber()"
                            appPhoneNumberOnly />
                            <span class="validation-error-message" *ngIf="callCenterPhoneNumber.invalid && callCenterPhoneNumber.errors.minlength">
                                Phone must be greater than 7 characters
                            </span>
                    </div>
                    <div class="row col-md-12 m-t-15">
                        <p>
                            Add the phone number that the customer will use for contacting the call center for any
                            issues.
                        </p>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
</div>

<app-pdf-viewer-modal #pdfViewerModal></app-pdf-viewer-modal>