export class SignageAddModel {
    public companyId: number;
    public facilityId: number;
    public kioskId: number;
    public originalContentFileName: string;
    public contentFileName: string;
    public contentFileType: string;
    public originalThumbnailFileName: string;
    public thumbnailFileName: string;
    public title: string;
    public description: string;
    public internalDescription: string;
}
