import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DonutChartDataItemModel, KioskHealthStatisticsModel } from '../../../models';
import { ListenerService, KioskService, MasterService } from '../../../services';
import { AppUtils } from '../../../helpers';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-dashboard-kiosk-health-status',
    templateUrl: './dashboard.kiosk.health.status.component.html'
})
export class DashboardKioskHealthStatusComponent implements AfterViewInit, OnDestroy {
    @Input() companyId: number;
    @Input() showCallMachineData: boolean;
    @BlockUI('container-blockui-tiles-kiosk-health-status') blockUI: NgBlockUI;
    isChartInitiated = false;
    chart: Chart;

    totalCount = 0;
    items = new Array<DonutChartDataItemModel>();

    showPersonalData: boolean;

    subscriptions: Subscription[] = [];

    constructor(private toatr: ToastrService,
        private listenerService: ListenerService,
        private kioskService: KioskService,
        private masterService: MasterService,
        private appUtils: AppUtils,
        private router: Router) {

        let subscription = this.masterService.DashboardShowPersonnalData$.subscribe(showPersonalData => {
            this.showPersonalData = showPersonalData;
            this.loadHealthStatus();
        });
        this.subscriptions.push(subscription);

        subscription = this.listenerService
            .listenSignalRevent
            .subscribe(() => {
                this.loadHealthStatus();
            });
        this.subscriptions.push(subscription);
    }

    ngAfterViewInit() {
        this.loadHealthStatus();
    }

    loadHealthStatus() {
        if (!this.companyId) {
            return;
        }

        this.blockUI.start();
        this.kioskService.getHealthStatisticsByCompany(this.companyId, this.showPersonalData, this.showCallMachineData)
            .subscribe(
                (data: KioskHealthStatisticsModel) => {
                    this.blockUI.stop();
                    this.totalCount = data.totalCount;
                    this.loadChartData(data);
                    if (this.chart) {
                        this.updateChart();
                    } else {
                        this.initChart();
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toatr, error);
                });
    }

    loadChartData(data: KioskHealthStatisticsModel) {
        this.items = new Array<DonutChartDataItemModel>();

        let chartItem = new DonutChartDataItemModel();
        chartItem.y = data.totalOnlineCount;
        chartItem.name = `Online (${chartItem.y})`;
        chartItem.color = '#1dc9b7';
        this.items.push(chartItem);

        chartItem = new DonutChartDataItemModel();
        chartItem.y = data.totalCount - data.totalOnlineCount;
        chartItem.name = `Offline (${chartItem.y})`;
        chartItem.color = '#fd397a';
        this.items.push(chartItem);
    }

    initChart() {
        const chart = new Chart({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                height: 200
            },
            title: {
                text: null,
                align: 'center',
                verticalAlign: 'middle',
                y:0
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                            fontSize: '10px'
                        }
                    },
                    center: ['50%', '50%'],
                    size: '100%',
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: 'Health Status',
                innerSize: '40%',
                data: this.items
            }]
        });

        chart.ref$.subscribe(console.log);
        setTimeout(() => {
            chart.ref.setTitle({ text: this.totalCount.toFixed(0) });
        });

        this.chart = chart;
        this.isChartInitiated = true;
    }

    updateChart() {
        const series = this.chart.ref.series[0];
        series.setData(this.items);
        this.chart.ref.setTitle({ text: this.totalCount.toFixed(0) });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    viewAllClicked() {
        this.masterService.setKioskShowPersonnalData(this.showPersonalData);
        let idList = this.showCallMachineData ? [4] : [1, 2, 3];
        this.router.navigate(['/kiosk/manage', this.companyId], {queryParams: {'filter': idList} });
        
    }
}
