<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor p-t-30">
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet kt-portlet--mobile">
                    <div class="kt-portlet__head kt-portlet__head--lg">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                KPI Dashboard
                            </h3>
                        </div>
                    </div>
                    <div class="p-2">
                        <ngb-tabset type="string" class="custom-tab-set" [destroyOnHide]="false"
                            [activeId]="activeTabId" (tabChange)="onTabChange($event)">
                            <ngb-tab id="1" title="Facilities Leadership Board">
                                <ng-template ngbTabContent>
                                    <app-facilities-total-open-work-orders></app-facilities-total-open-work-orders>
                                </ng-template>
                            </ngb-tab>
                            <ngb-tab id="2" title="Users Leadership Board">
                                <ng-template ngbTabContent>
                                    <app-users-total-open-work-orders></app-users-total-open-work-orders>
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
