<ng-template #addNoteModal let-modal>
    <form class="kt-form form-horizontal" name="form" (ngSubmit)="submit(f)" #f="ngForm" novalidate>
        <div *blockUI="'container-blockui-recurrance-popup'">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="!isEditMode">Add Facility Note</h5>
                <h5 class="modal-title" *ngIf="isEditMode">Edit Facility Note</h5>
                <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && facilityNote.invalid}">
                    <div class="col-12">
                        <textarea type="text" name="facilityNote" class="form-control" rows="4" autocomplete="off" [(ngModel)]="note"
                            #facilityNote="ngModel" required></textarea>
                    </div>
                    <div class="col-12 validation-error-message" *ngIf="f.submitted && facilityNote.invalid">
                        Note is required
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-default m-r-5" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    </form>
</ng-template>