import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI,NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { JiveCommunicationModel } from 'src/models/jive-communication/jivecommunication.model';


@Component({
    selector: 'app-jive-configuration',
    templateUrl: './jive.configuration.component.html'
})

export class JiveConfigurationComponent implements OnInit {
    @BlockUI('container-blockui-configuration') blockUI: NgBlockUI;
    model:JiveCommunicationModel=new JiveCommunicationModel();
    isHiddenJive:boolean=true;
    showActionColumn = true;
    constructor(private toastr: ToastrService,
        private kpiReportService: KpiReportService,
        private route: ActivatedRoute,
        private appUtils: AppUtils) 
        { 
            this.route.params.subscribe((params) => {
                this.model.internalCompanyId = params['companyId'];
            });       
        }

    
    
    ngOnInit() {
        this.loadJiveCommunicationConfigure();
    }

    loadJiveCommunicationConfigure(){
        this.blockUI.start();
        this.kpiReportService.getJiveCommunicationConfigure(this.model.internalCompanyId)
            .subscribe(
                (data: JiveCommunicationModel) => {
                    this.blockUI.stop();
                    if (!data) {
                        return;
                    }
                    this.model.clientId = data.clientId;
                    this.model.secretKey = data.secretKey;
                    this.model.JiveCommunicationConfigured = true;
                    this.model.IsAuthorized = data.IsAuthorized;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        this.blockUI.start();
        this.kpiReportService.addJiveCommunicationconfigure(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success("Data Enter Successfully...!!!");
                    this.model.JiveCommunicationConfigured = true;
                },
               
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    OnJiveAuthorize() {
        this.blockUI.start();
        this.kpiReportService.doJiveConnection(this.model)
            .subscribe(
                (data:any)=>{
                        this.blockUI.stop();
                        if(data != null || data !='')
                        {
                            window.location.href = data;
                        }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                }
            );
    }
}