import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ImageCropperModel } from '../../../models';

@Component({
    selector: 'app-signage-image-cropper',
    templateUrl: './signage.image.cropper.component.html'
})

export class SignageImageCropperComponent {
    @ViewChild('signageImageCropper', { static: true }) signageImageCropper: any;
    @Input() processingDimension = new ImageCropperModel();
    @Output() croppingCancelled = new EventEmitter<any>();
    modalReferece: NgbModalRef;

    imageChangedEvent: any = '';
    showCropper = false;

    constructor(private toastr: ToastrService,
        private modalService: NgbModal) {
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageLoaded(e: any) {
        this.showCropper = true;
        this.processingDimension.processingWidth = e.original.size.width;
        this.processingDimension.processingHeight = e.original.size.height;
    }

    openImageCropper(event: any) {
       this.modalReferece = this.modalService.open(
           this.signageImageCropper,
           {
               size: 'lg',
               backdrop: 'static',
               keyboard: false
            });
        this.fileChangeEvent(event);
    }

    closeImageCropper(isCancelling: boolean) {
        if (isCancelling) {
            this.croppingCancelled.emit();
        }
        this.showCropper = false;
        this.modalReferece.close();
    }

    loadImageFailed() {
        this.modalReferece.close();
        this.toastr.error('Failed to load image');
    }

    imageCropped(e: any) {
        this.processingDimension.dimensionX1 = e.imagePosition.x1;
        this.processingDimension.dimensionX2 = e.width;
        this.processingDimension.dimensionY1 = e.imagePosition.y1;
        this.processingDimension.dimensionY2 = e.height;
    }
}

