export class CheckboxListItemModel {
    public key: string;
    public value: string;
    public isSelected: boolean;

    public groupId: string;
    public groupName: string;

    public rowNumber: number;
}
