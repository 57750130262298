<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-signage-manage [companyId]="companyId" [facilityId]="facilityId" [kioskId]="kioskId">
            </app-breadcrumb-signage-manage>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="col-md-12">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Manage Content
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <a [routerLink]="['/signage/add', companyId, facilityId, kioskId]"
                                    class="btn btn-brand btn-elevate btn-icon-sm">
                                    <em class="la la-plus"></em>
                                    Add Content
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-12" *blockUI="'container-blockui'">
                            <div class="row" #containerSignageContentList id="container-signage-content-list"
                                [dragula]="'signage_contents'">
                                <div class="col-md-12 cursor-pointer signage-content-item" [id]='item.id'
                                    *ngFor="let item of signages">
                                    <div class="row">
                                        <div class="col-md-2 text-center" (click)="showContent(item)">
                                            <img class="signage-thumbnail rounded" [src]="item.contentFileUrl"
                                                *ngIf="!item.thumbnailFileUrl">
                                            <img class="signage-thumbnail rounded" [src]="item.thumbnailFileUrl"
                                            *ngIf="item.thumbnailFileUrl">
                                        </div>
                                        <div class="col-md-8 pl-0">
                                            <div class="card-block pr-3">
                                                <h4 class="card-title text-break">{{item.title}}</h4>
                                                <p class="card-text text-break">{{item.description}}</p>
                                                <div class="row">
                                                    <div class="col-3">
                                                        <label>Order: </label>
                                                        <span class="m-l-5 ">{{item.displayOrder}}</span>
                                                    </div>
                                                    <div class="col-3">
                                                        <label class="control-label">Released: </label>
                                                        <span class="m-l-10">{{item.createdOn}}</span>
                                                    </div>
                                                    <div class="col-3">
                                                        <label class="control-label">Content Type: </label>
                                                        <span class="m-l-10">{{item.contentFileType}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="d-flex align-items-center h-100">
                                                <a (click)="edit(item.id)">
                                                    <div class="row justify-content-center">
                                                        <em class='fa fa-edit cursor-pointer' title='Edit'></em>
                                                        <p class="w-100 pt-1 text-center">Edit</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="d-flex align-items-center h-100">
                                                <a (click)="delete(item.id)">
                                                    <div class="row justify-content-center">
                                                        <em class='fa fa-trash cursor-pointer' title='Delete'></em>
                                                        <p class="w-100 pt-1 text-center">Delete</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-separator m-t-20">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" *ngIf="isModelLoaded && signages.length === 0">
                            <p>
                                No record found
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<app-image-viewer-modal #imageViewerModal></app-image-viewer-modal>
<app-video-viewer-modal #videoViewerModal></app-video-viewer-modal>
<app-pdf-viewer-modal #pdfViewerModal></app-pdf-viewer-modal>