import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from '../../../models';

@Component({
    selector: 'app-activity-log-workorder-activities',
    templateUrl: './activity.log.workorder.activities.component.html'
})

export class ActivityLogWorkOrderActivitiesComponent implements OnInit {
    @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

    ngOnInit() {
        this.model.entityLink = '/work-order/detail/1/' + this.model.companyId + '/' + this.model.entityId;

        if (this.model.fieldEntityType) {
            if (this.model.fieldEntityType === 4) {
                this.model.oldValueLink = '/facility/detail/' + this.model.companyId + '/' + this.model.oldId;
                this.model.newValueLink = '/facility/detail/' + this.model.companyId + '/' + this.model.newId;
            } else if (this.model.fieldEntityType === 5) {
                this.model.oldValueLink = '/unit/detail/' + this.model.companyId + '/' + this.model.oldParentId + '/' + this.model.oldId;
                this.model.newValueLink = '/unit/detail/' + this.model.companyId + '/' + this.model.newParentId + '/' + this.model.newId;
            } else if (this.model.fieldEntityType === 2) {

                const queryParams = '{"companyId" : "' + this.model.companyId + '", "userType":"any"}';

                if (this.model.oldId) {

                    this.model.oldValueLink = '/company-user/detail/' + this.model.oldId;
                    this.model.oldValueParams = JSON.parse(queryParams);
                }

                if (this.model.newId) {
                    this.model.newValueLink = '/company-user/detail/' + this.model.newId;
                    this.model.newValueParams = JSON.parse(queryParams);
                }
            }
        }
    }
}
