import { Component, ViewChild, OnInit, HostBinding, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, CompanyUserFilterModel } from '../../../../models';
import { UserService, CompanyService } from '../../../../services';
import { AppUtils, AppSettings, RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-company-user-manage-administrator',
    templateUrl: './company.user.manage.administrator.component.html'
})

export class CompanyUserManageAdministratorComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    gridState: any;
    gridStateName = 'gridStateCompanyAdministrators';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: CompanyUserFilterModel = new CompanyUserFilterModel();
    filterUserType: string;
    showActionColumn = false;

    constructor(private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private tostr: ToastrService,
        private userService: UserService,
        private appUtils: AppUtils,
        private appSettings: AppSettings,
        private companyService: CompanyService) {

        this.filterUserType = 'admin';
        this.filterModel.role = 'OrgAdmin';

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewOrgAdmin
            && !this.roleMatrix.canViewOrgUser) {
            this.router.navigate(['/error/403']);
        }

        this.route.queryParams.subscribe((params) => {
            if (params['companyId']) {
                this.filterModel.companyId = params['companyId'];
            }
        });

        if (!this.filterModel.companyId
            || ((this.roleMatrix.role === 'ORGSUPERADMIN'
                || this.roleMatrix.role === 'ORGADMIN'
                || this.roleMatrix.role === 'ORGUSER')
                && (this.roleMatrix.companyId !== this.filterModel.companyId.toString()))) {
            this.router.navigate(['/error/404']);
        }

        if (this.roleMatrix.canChangeStatusOfOrgAdmin
            || this.roleMatrix.canEditOrgAdmin
            || this.roleMatrix.canDeleteOrgAdmin
            || this.roleMatrix.canResetOrgUserPassword) {
            this.showActionColumn = true;
        }

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/company-user/detail/') !== -1
                    || event.url.indexOf('/company-user/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
            if (event instanceof NavigationEnd) {
                let gridState: any = localStorage.getItem(this.gridStateName);
                if (!gridState) {
                    return;
                }
                gridState = JSON.parse(gridState);
                this.rowIndex = gridState.start;
                this.pageLength = gridState.length;

                if (gridState.status) {
                    this.filterModel.status = gridState.status;
                }
                if (gridState.filterKey) {
                    this.filterModel.filterKey = gridState.filterKey;
                }
            }
        });
    }

    ngOnInit(): void {

        const self = this;
        this.checkCompanyStatus();

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter users...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[2, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                } else {
                    dataTablesParameters.companyId = self.filterModel.companyId;
                    dataTablesParameters.role = self.filterModel.role;
                    dataTablesParameters.status = self.filterModel.status;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                self.http
                    .post<DataTableResponseModel>
                    (environment.apiBaseUrl + 'account/company-user-paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'firstName',
                    title: 'Name',
                    width: '25%',
                    render: function (data, type, row) {
                        const fullName = row.firstName + (row.lastName ? ' ' + row.lastName : '');
                        return `<span class='link' action-type='link-detail'>${fullName}</a>`;
                    }
                },
                {
                    data: 'email',
                    title: 'Email',
                    width: '25%'
                },
                {
                    data: 'createdOn',
                    title: 'Created On',
                    width: '15%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'role',
                    title: 'Role',
                    width: '15%',
                    render: function (data) {
                        return data === 'OrgAdmin' ? 'Administrator' : data === 'OrgUser' ? 'Personnel' : data;
                    }
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        const statusHtml =
                            row.status === 2
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === 3 && self.roleMatrix.is10FedUser
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '';

                        const htmlString = (self.roleMatrix.canChangeStatusOfOrgAdmin ? statusHtml : '')
                            + (self.roleMatrix.canEditOrgAdmin && row.status !== 3
                                ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteOrgAdmin
                                ? `<em class='fa fa-trash cursor-pointer m-r-3' title='Delete' action-type='delete'></em>` : '')
                            + (self.roleMatrix.canResetOrgAdminPassword && row.status !== 3
                                ? `<em class='fa fa-unlock-alt cursor-pointer m-r-3' title='Reset Password' action-type='reset-password'></em>` : '');

                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/company-user/edit', data.id],
                        { queryParams: { companyId: data.companyId, userType: self.filterUserType } });
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.delete(data.id, dataIndex);
                });

                const detailElem = $(row).find('[action-type = link-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/company-user/detail', data.id],
                        { queryParams: { companyId: data.companyId, userType: self.filterUserType } });
                });

                const resetPassElem = $(row).find('[action-type = reset-password]');
                $(resetPassElem).unbind('click');
                $(resetPassElem).on('click', function () {
                    self.resetPassword(data.id);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            },
            stateSaveParams: function (settings, data: any) {
                data.status = self.filterModel.status;
                data.filterKey = self.filterModel.filterKey;
            },
            stateLoadParams: function (settings, data: any) {
                if (data.status) {
                    self.filterModel.status = data.status;
                }
                if (data.filterKey) {
                    self.filterModel.filterKey = data.filterKey;
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.userService.toggleStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.tostr.success(`User has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    delete(id: number, dataIndex: number): void {
        if (!confirm('Are you sure you want to delete the selected user?')) {
            return;
        }
        this.blockUI.start();
        this.userService.delete(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (dataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.tostr.success('User has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    resetPassword(id: number): void {
        if (!confirm('Are you sure you want to reset password for the selected user?')) {
            return;
        }
        this.blockUI.start();
        this.userService.resetPasswordForUser(id).subscribe(
            (resp: string) => {
                this.blockUI.stop();
                this.tostr.success(resp);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    doFilter() {
        this.dtInstance.ajax.reload();
    }

    resetFilter() {
        this.filterModel.status = '';
        this.filterModel.filterKey = '';
        this.doFilter();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.filterModel.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForOrgAdmin();
                }
            }
        );
    }
}
