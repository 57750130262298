<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title" *ngIf="!showCallMachineData">
                Kiosk Health Status
            </h3>
            <h3 class="kt-portlet__head-title" *ngIf="showCallMachineData">
                Call Machine Health Status
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar text-right">
            <button class="btn btn-label-primary btn-sm btn-bold" style="font-size:0.7rem; padding: 0.5rem 0.15rem;" (click)="viewAllClicked()">
                View All
            </button>
        </div>
    </div>
    <div class="kt-portlet__body p-b-0 p-t-10" *blockUI="'container-blockui-tiles-kiosk-health-status'">
        <div class="row">
            <div class="col-12">
                <p *ngIf="!showCallMachineData" class="m-b-0">Current status of the kiosk machines</p>
                <p *ngIf="showCallMachineData" class="m-b-0">Current status of the call machines</p>
            </div>
        </div>
        <div [chart]="chart" style="width: 100%;"></div>
    </div>
</div>