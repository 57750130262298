export class DashboardWorkOrderStatusCountModel {
    public name: string;
    public bgColor: string;
    public count: number;
    public percentage: number;
    public redirectUrl: any;
    public redirectQueryParams: any;
    public isLoaded: boolean;

    constructor() {
        this.percentage = -1;
        this.isLoaded = false;
    }
}
