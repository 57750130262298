export class WorkOrderSummaryModel {
    public id: number;
    public title: string;
    public workOrderStatus: number;
    public workOrderPriority: number;
    public companyId: number;
    public companyName: string;
    public facilityId: number;
    public facilityName: string;
    public unitId: number;
    public unitName: string;
    public assignedToId: string;
}
