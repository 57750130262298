<div class="row">
    <div class="col-11 offset-1">
        <div class="row">
            <div class="col-12" *ngIf='model.actionType === 1'>
                <p class="kt-widget3__text">
                    New user
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.entityValue}}
                    </a>
                    was created
                </p>
            </div>
            <div class="col-12 m-l-2" *ngIf='model.actionType === 2'>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Name'">
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.newValue}}'s
                    </a> name has been changed from
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.oldValue}}
                    </a>
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Role'">
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.entityValue}}'s
                    </a>
                    role has been changed from {{model.oldValue}} to {{model.newValue}}
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Phone'">
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.entityValue}}'s
                    </a>
                    contact number has been changed from {{model.oldValue}} to {{model.newValue}}
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Email'">
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.entityValue}}'s
                    </a>
                    email has been changed from {{model.oldValue}} to {{model.newValue}}
                    by
                    <app-activity-log-author [model]="model"> </app-activity-log-author>
                </p>
                <p class="kt-widget3__text" *ngIf="model.fieldName === 'Status'">
                    <a [routerLink]="[model.entityLink]" [queryParams]="model.entityParams"> {{model.entityValue}}'s
                    </a> account has been
                    <span *ngIf="model.newValue === 'Active'">activated</span>
                    <span *ngIf="model.newValue === 'Inactive'">deactivated</span>
                </p>
            </div>
            <div class="col-12" *ngIf='model.actionType === 3'>
                <p class="kt-widget3__text">
                    {{model.entityValue}}'s account has been deleted
                </p>
            </div>
        </div>
    </div>
</div>