<form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
    <div class="kt-portlet__body" *blockUI="'container-blockui-configuration'">
        <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && companyId.invalid}">
            <label class="col-md-3" style="padding-left: 97px; margin-top: 11px; ">Company ID:*</label>
            <div class="col-md-6">
                <input type="text" name="companyId" class="form-control" maxlength="50" autocomplete="off"
                    [(ngModel)]="model.storedgeCompanyId" #companyId="ngModel" required
                    [disabled]="model.storedgCompanyConfigured" appAnythingButWhiteSpace />
                <div class=" validation-error-message" *ngIf="f.submitted && companyId.invalid">
                    <span *ngIf="companyId.errors.required"> Compnay ID is required</span>
                </div>
                <span class="m-t-2">
                    Enter your company ID in StorEdge</span>
                <p class="m-t-2"><b>**</b> This will configure your application with your data in StorEdge and the same will be reflected in the application</p>

            </div>
        </div>
    </div>
    <div class="kt-portlet__foot" *ngIf="!model.storedgCompanyConfigured">
        <div class="kt-form__actions text-center">
            <button type="submit" class="btn btn-primary" [disabled]="model.storedgCompanyConfigured && !showActionColumn">
                <span class="kt-menu__link-icon">
                    <em class="kt-menu__link-icon flaticon-eye"></em>
                </span>Configure</button>
        </div>
    </div>
</form>
