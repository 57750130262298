import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-work-order-list-closed',
    templateUrl: './breadcrumb.work.order.list.closed.component.html'
})

export class BreadcrumbWorkOrderListClosedComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
