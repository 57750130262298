<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-company-user-profile-detail></app-breadcrumb-company-user-profile-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                My Profile
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar" *ngIf="showActionColumn">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a [routerLink]="['/company-user/profile-edit']" class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item">
                                        <a [routerLink]="['/account/change-password']" class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-key"></em>
                                            <span class="kt-nav__link-text">
                                                Change Password
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body " *ngIf='isModelLoaded'>
                        <div class="kt-form detail-form">
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Profile Image:</label>
                                <div class="col-md-9">
                                    <app-user-image-upload [model]='imageUploadModel'></app-user-image-upload>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">First Name:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.firstName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Last Name:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.lastName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Email:</label>
                                <div class="col-md-9">
                                    <p class="kt-portlet__data">{{model.email}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Phone:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.phone}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Role:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.role}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Time Zone:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{timeZoneFormattedName}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Status:</label>
                                <div class="col-md-6">
                                    <label class="kt-badge kt-badge--success kt-badge--inline"
                                        *ngIf="model.status === 2">Active</label>
                                    <label class="kt-badge kt-badge--danger kt-badge--inline"
                                        *ngIf="model.status === 3">Inactive</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Created On:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">{{model.createdOn}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Company Account ID:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">ID{{model.companyId}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label break-word">Notification Preferences :</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data" [innerHTML]="notificationPreferencesText"></p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label break-word">Notification Preference as watchers:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data" [innerHTML]="notificationPreferencesAsWatcherText"></p>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="userGroups.length">
                                <label class="col-md-3 kt-portlet__label">User Groups Assigned:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">
                                        <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                            *ngFor="let item of userGroups">
                                            {{item}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 kt-portlet__label">Facility Assigned:</label>
                                <div class="col-md-6">
                                    <p class="kt-portlet__data">
                                        <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                            *ngFor="let item of model.assignedFacilities">
                                            {{item.name}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot d-none">
                        <div class="kt-form__actions text-right">
                            <a [routerLink]="[priviousPageUrl]" class="btn btn-secondary m-r-5">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>