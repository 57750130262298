import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ActivityLogModel } from '../../../models';

import { KioskService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-kiosk-activity-log',
    templateUrl: './kiosk.activity.log.component.html'
})

export class KioskActivityLogComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    companyId = -1;
    facilityId = -1;
    kioskId = -1;
    isModelLoaded = true;
    activityLogs: Array<ActivityLogModel> = new Array<ActivityLogModel>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private kioskService: KioskService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddKiosk) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId']
            this.kioskId = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.blockUI.start();
        //get all activity logs for kisosk machine
        this.kioskService.getActivityLogs(this.kioskId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();

                    Object.assign(this.activityLogs, data);

                    if (this.activityLogs.length === 0) {
                        return;
                    }

                    this.activityLogs.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

    }
}
