import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KioskOfflineTimestampModel, KioskOfflineLogModel } from '../../../models';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-kiosk-offline-timestamp',
    templateUrl: './kiosk.offline.timestamp.component.html'
})

export class KioskOfflineTimestampComponent {
    @Input() timestamps: Array<KioskOfflineTimestampModel>;
    @ViewChild('kioskTimestampModal', { static: true }) kioskTimestampModal: any;

    modalReference: any;
    timestampLogs: Array<KioskOfflineTimestampModel> = new Array<KioskOfflineTimestampModel>();

    constructor(private modalService: NgbModal,
        private appUtils: AppUtils) { }

    openTimestampModal() {
        if (this.modalService.hasOpenModals()) {
            return;
        }

        this.timestampLogs = new Array<KioskOfflineTimestampModel>();

        this.timestamps.forEach((timestampLog) => {
            const offlineTimestampModel = new KioskOfflineTimestampModel();

            offlineTimestampModel.date = timestampLog.date;

            timestampLog.offlineLogs.forEach((offlineLog) => {
                const kioskOfflineLogModel = new KioskOfflineLogModel();

                kioskOfflineLogModel.connectedAt = offlineLog.connectedAt;
                kioskOfflineLogModel.disconnectedAt = offlineLog.disconnectedAt;

                offlineTimestampModel.offlineLogs.push(kioskOfflineLogModel);
            });

            this.timestampLogs.push(offlineTimestampModel);
        });

        this.timestampLogs.forEach((offlineTimestamp) => {

            offlineTimestamp.date = this.appUtils.getFormattedDate(offlineTimestamp.date);

            // const length = offlineTimestamp.offlineLogs.length;


            // let index = 0;

            // format datetime
            offlineTimestamp.offlineLogs.forEach((offlineLog) => {
                offlineLog.connectedAt = this.appUtils.getFormattedTimeFull(offlineLog.connectedAt);
                if (offlineLog.disconnectedAt) {
                    offlineLog.disconnectedAt = this.appUtils.getFormattedTimeFull(offlineLog.disconnectedAt);
                }
            });

            // // arrange uptime and downtime
            // offlineTimestamp.offlineLogs.forEach((offlineLog) => {
            //     if (index < (length - 1)) {
            //         offlineLog.connectedAt = offlineTimestamp.offlineLogs[index + 1].connectedAt;
            //     } else {
            //         offlineLog.connectedAt = null;
            //     }
            //     index += 1;
            // });

        });

        this.modalReference = this.modalService.open(this.kioskTimestampModal,
            {
                backdrop: 'static',
                keyboard: false
            });
    }

    closeTimestampModal() {
        this.modalReference.close();
    }
}
