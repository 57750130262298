import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { SelectListItemModel, FacilityAddModel, UserGroupSelectList, UserSelectListItemModel } from '../../../models';

import { FacilityService, CompanyService, UserGroupService, UserService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';
import { isNullOrUndefined } from  '../../../helpers/tools';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-facility-add',
    templateUrl: './facility.add.component.html'
})

export class FacilityAddComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    multiSelectDropdownConfigs = {};

    companyId = -1;

    clientUsers: SelectListItemModel[] = [];

    userGroups: UserGroupSelectList[] = [];
    selectedUserGroups: UserGroupSelectList[] = [];
    prevMoveOutAssigneeId: any;
    prevMoveInAssigneeId: any;
    prevComplaintAssigneeId: any;
    prevDefaultAssigneeId: string;
    prevPropertyManagerId: string = '';

    model: FacilityAddModel = new FacilityAddModel();
    companyUsers: Array<UserSelectListItemModel> = new Array<UserSelectListItemModel>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private userGroupService: UserGroupService,
        private userService: UserService,
        private appUtils: AppUtils,
        private companyService: CompanyService) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddFacility) {
            this.router.navigate(['/error/403']);

        }
        this.route.params.subscribe((params) => {
            this.companyId = params['id'];
            this.model.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'key',
            textField: 'value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit() {
        this.checkCompanyStatus();
        this.loadCompanyUsers();
        this.loadUserGroups();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.router.navigate(['/error/404']);
                }
            },
            error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }
        );
    }

    loadUserGroups() {
        this.blockUI.start();
        this.userGroupService.getCompanyUserGroupsSelectItem(this.companyId, true).subscribe(
            data => {
                this.blockUI.stop();
                this.userGroups = data;
                //this.setUsersFromUserGroups([]);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    submit(formData: NgForm) {        
        if (this.model.propertyManagerUserId == null || this.model.propertyManagerUserId.length == 0) {
            formData.form.controls['propertyManager'].setErrors({ 'required': true });
            formData.form.controls['propertyManager'].markAsTouched();            
        } else {
            formData.form.controls['propertyManager'].setErrors(null);
        }

        if (formData.form.invalid) {
            return;
        }

        //Update Redundant Properties
        let user = this.companyUsers.find(x => x.key == this.model.propertyManagerUserId);
        this.model.propertyManagerName = user.value;
        this.model.propertyManagerEmail = user.email;
        this.model.propertyManagerPhone = user.phoneNumber;

        this.model.clientUserGroupsAssigned = [];
        if (this.selectedUserGroups) {
            this.model.clientUserGroupsAssigned = this.selectedUserGroups.map(item => item.key);
        }

        this.blockUI.start();
        this.facilityService.add(this.model)
            .subscribe(
                data => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/facility/detail/', this.companyId, data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Facility has been added successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onUserGroupSelectDeSelect() {
        this.setUsersFromUserGroups(this.selectedUserGroups.map(x => x.key));
    }

    onUserGroupSelectDeSelectAll(items: SelectListItemModel[]) {
        this.setUsersFromUserGroups(items.map(x => x.key));
    }

    setPrevMoveOutAssignee() {
        this.prevMoveOutAssigneeId = this.model.moveOutAssigneeId;
    }

    setPrevMoveInAssignee() {
        this.prevMoveInAssigneeId = this.model.moveInAssigneeId;
    }

    setPrevComplainAssignee() {
        this.prevComplaintAssigneeId = this.model.complaintsAssigneeId;
    }

    setPrevDefaultAssignee() {
        this.prevDefaultAssigneeId = this.model.defaultAssigneeId;
    }

    private setUsersFromUserGroups(selectedUserGroupsIds: string[]) {
        this.loadCompanyUsers();

        if(selectedUserGroupsIds.length <= 0){
            this.clientUsers = [];
            return;
        }
            
        const selectedUsers: SelectListItemModel[] = [];

        if (selectedUserGroupsIds.length === 0) {
            this.userGroups.forEach(x => selectedUsers.push(...x.users));
        } else {
            selectedUserGroupsIds.forEach(x => selectedUsers.push(...this.userGroups.find(ug => ug.key === x).users));
        }

        const distinctSelectedUsers: SelectListItemModel[] = [];
        const map = new Map();
        for (const item of selectedUsers) {
            if (!map.has(item.key)) {
                map.set(item.key, true);
                distinctSelectedUsers.push({
                    id: null,
                    key: item.key,
                    value: item.value,
                    name: item.name
                });
            }
        }

        distinctSelectedUsers.sort((a, b) => a.value.localeCompare(b.value));

        this.clientUsers = distinctSelectedUsers;

        const currentMoveOutAssignee = this.clientUsers.find(x => x.key === this.model.moveOutAssigneeId);
        const currentMoveInAssignee = this.clientUsers.find(x => x.key === this.model.moveInAssigneeId);
        const currentComplaintsAssignee = this.clientUsers.find(x => x.key === this.model.complaintsAssigneeId);
        const currentDefaultAssignee = this.clientUsers.find(x => x.key === this.model.defaultAssigneeId);

        if (isNullOrUndefined(currentMoveOutAssignee)) {
            if (isNullOrUndefined(this.prevMoveOutAssigneeId)) {
                this.model.moveOutAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.prevMoveOutAssigneeId);
                this.model.moveOutAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevMoveOutAssigneeId;
            }
        }
        else{
            this.model.moveOutAssigneeId = '';
            setTimeout(() => {
                this.model.moveOutAssigneeId = currentMoveOutAssignee.key;
            }, 100);  
        }

        if (isNullOrUndefined(currentMoveInAssignee)) {
            if (isNullOrUndefined(this.prevMoveInAssigneeId)) {
                this.model.moveInAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.prevMoveInAssigneeId);
                this.model.moveInAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevMoveInAssigneeId;
            }
        }
        else{
            this.model.moveInAssigneeId = '';
            setTimeout(() => {
                this.model.moveInAssigneeId = currentMoveInAssignee.key;
            }, 100);  
        }

        if (isNullOrUndefined(currentComplaintsAssignee)) {
            if (isNullOrUndefined(this.prevComplaintAssigneeId)) {
                this.model.complaintsAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.prevComplaintAssigneeId);
                this.model.complaintsAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevComplaintAssigneeId;
            }
        }
        else{
            this.model.complaintsAssigneeId = '';
            setTimeout(() => {
                this.model.complaintsAssigneeId = currentComplaintsAssignee.key;
            }, 100);  
        }
        

        if (isNullOrUndefined(currentDefaultAssignee)) {
            if (isNullOrUndefined(this.prevDefaultAssigneeId)) {
                this.model.defaultAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.prevDefaultAssigneeId);
                this.model.defaultAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.prevDefaultAssigneeId;
            }
        }
        else{
            this.model.defaultAssigneeId = '';
            setTimeout(() => {
                this.model.defaultAssigneeId = currentDefaultAssignee.key;
            }, 100);  
        }
    }

    loadCompanyUsers() {
        if (this.selectedUserGroups != null && this.selectedUserGroups.length > 0){
            this.blockUI.start();
            this.userGroupService.getCompanyUsersOfUserGroups(this.companyId, this.selectedUserGroups.map(x=>x.key))
                .subscribe((data: Array<any>) => {
                    this.blockUI.stop();
                    if (!data || data.length === 0) {
                        return;
                    }
                    this.companyUsers = data.map((x) => { return {
                        id: x.id,
                        name: `${x.firstName} ${x.lastName ?? ''}`,
                        key: x.id,
                        value: `${x.firstName} ${x.lastName ?? ''}`,
                        email: x.email,
                        phoneNumber: x.phone
                    } as UserSelectListItemModel });
                    this.setPropertyManager();
                }, error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
        }
        else{
            this.blockUI.start();
            this.userService.getCompanyUsersSelectItem(this.companyId)
                .subscribe((data: Array<any>) => {
                    this.blockUI.stop();
                    if (!data || data.length === 0) {
                        return;
                    }
                    this.companyUsers = data;
                    this.setPropertyManager();
                }, error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
        }
    }

    private setPropertyManager(){
        if(!isNullOrUndefined(this.model.propertyManagerUserId) && this.model.propertyManagerUserId != '')
        {
            this.prevPropertyManagerId = this.model.propertyManagerUserId;
        }

        if(this.selectedUserGroups.length > 0 && this.model.propertyManagerUserId != '' &&
            !this.companyUsers.some(x => x.id == this.model.propertyManagerUserId)){
                this.model.propertyManagerUserId = '';
        }
        else {
            this.model.propertyManagerUserId = '';
            setTimeout(() => {
                if(this.prevPropertyManagerId != '') {
                    this.model.propertyManagerUserId = this.prevPropertyManagerId;
                }
            }, 100);
        }
    }
}
