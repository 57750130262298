<div *blockUI="'container-blockui'">
  <div class="col-md-12">
    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="row p-t-25">
            <div class="col-3">
                <div class="form-group form-group-md">
                    <select class="form-control" name="propertyManagerId" [(ngModel)]="propertyManagerId">
                        <option value="">[Property Manager]</option>
                        <option *ngFor="let user of companyUsers" [value]="user.key">
                            {{user.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group form-group-md">
                    <input type="text" class="form-control" placeholder="Filter Facilities"
                        [(ngModel)]="filterText" autocomplete="Off" maxlength="50"
                        (keydown.enter)="applyFilter()" />
                </div>
            </div>
            <div class="col-6">
              <div class="form-group form-group-md">
                  <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" (click)="applyFilter()">
                      <em class="la la-search"></em> Filter
                  </button>
                  <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="resetFilter()">
                      <em class="la la-close"></em> Reset
                  </button>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-12">
              <table datatable [dtOptions]="dtOptions" data-page="2" class="table table-hover break-all"
                id="kpi-facilities-total-open-work-order-table">
              </table>
          </div>
      </div>
    </div>
  </div>
</div>
