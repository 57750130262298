<div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed " data-ktheader-minimize="on">
    <div class="kt-container">
        <div class="kt-header__brand " id="kt_header_brand">
            <div class="kt-header__brand-logo">
                <a [routerLink]="['/']" class="kt-layout__logo">
                    <img alt="Logo" src="./assets/media/logos/logo.png" class="logo" />
                </a>
            </div>
        </div>
        <div class="kt-header__topbar">
            <div class="kt-header__topbar-item dropdown " ngbDropdown [autoClose]="'inside'"
                #notificationDropdown="ngbDropdown">
                <div class="kt-header__topbar-wrapper " data-offset="30px,0px" ngbDropdownToggle>
                    <span class="kt-header__topbar-icon kt-bg-brand"><i class="fa fa-bell kt-font-light"></i></span>
                    <span class="kt-badge kt-badge--danger kt-badge--notify">{{notificationsCount}}</span>
                </div>
                <app-notification (updateNotificationCount)='updateNotificationCount($event)'
                    (closeNotificationDropdown)='closeNotificationDropdown()'></app-notification>
            </div>

            <div class="kt-header__topbar-item kt-header__topbar-item--user">
                <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px">
                    <span class="kt-header__topbar-welcome kt-visible-desktop">Hi,</span>
                    <span class="kt-header__topbar-username kt-visible-desktop">{{currentUsername}}</span>
                    <img alt="Pic" *ngIf='!currentUserProfilePhoto' src="./assets/media/users/default.jpg" />
                    <img alt="Pic" *ngIf='currentUserProfilePhoto' [src]="currentUserProfilePhoto" />
                    <span class="kt-header__topbar-icon kt-bg-brand kt-hidden"><strong>S</strong></span>
                </div>
                <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
                    <div class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
                        <div class="kt-user-card__avatar">
                            <img alt="Pic" *ngIf='!currentUserProfilePhoto' src="./assets/media/users/default.jpg" />
                            <img alt="Pic" *ngIf='currentUserProfilePhoto' [src]="currentUserProfilePhoto" />
                            <span
                                class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden">S</span>
                        </div>
                        <div class="kt-user-card__name">
                            {{currentUsername}}
                            <p class="m-b-0 fs-12 text-muted">{{roleMatrix.normalizedRole}}</p>
                        </div>

                    </div>

                    <div class="kt-notification">
                        <a [routerLink]="[profileUrl]" class="kt-notification__item">
                            <div class="kt-notification__item-icon">
                                <em class="flaticon2-calendar-3 kt-font-success"></em>
                            </div>
                            <div class="kt-notification__item-details">
                                <div class="kt-notification__item-title kt-font-bold">
                                    My Profile
                                </div>
                                <div class="kt-notification__item-time">
                                    Account settings and more
                                </div>
                            </div>
                        </a>

                        <div class="kt-notification__custom kt-space-between">
                            <a [routerLink]="['/account/logout']"
                                class="btn btn-label btn-label-brand btn-sm btn-bold">Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>