import { StoredgeUnitPreview } from './storedge.unit.preview.model';

export class StoredgeFacilityPreview {
    public id: string;
    public companyId: number;
    public storedgeId: string;
    public name: string;
    public status: number;
    public lastUpdated: string;
    public isSelected: boolean;

    public isOpen: boolean;
    public selectAllUnits: boolean;

    public propertyManagerUserId: string;
    public propertyManagerName: string;
    public propertyManagerEmail: string;
    public propertyManagerPhone: string;
    public typeOfConstruction: string;
    public climateControlled: boolean;
    public clientUserGroupsAssigned: Array<string>;
    public clientUsersAssigned: Array<string>;
    public defaultAssigneeId: string;
    public complaintsAssigneeId: string;
    public moveOutAssigneeId: string;

    public units: Array<StoredgeUnitPreview>;

    public modelState: number;

    constructor() {
        this.isOpen = false;
        this.clientUserGroupsAssigned = new Array<string>();
        this.units = new Array<StoredgeUnitPreview>();
        this.clientUsersAssigned = new Array<string>();
        this.modelState = 0;
        this.complaintsAssigneeId = '';
        this.moveOutAssigneeId = '';
    }
}
