import { Component, ViewChild, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from '../../../helpers';
import { MoveInService, KioskService } from '../../../services';
import { MasterDataModel, FieldDetailModel, KioskChangeApplicationModel, ConfigurationModel } from '../../../models';
import { PdfViewerModalComponent } from 'src/components/partial';

@Component({
    selector: 'app-kiosk-detail-move-in',
    templateUrl: './kiosk.detail.move.in.component.html'
})

export class KioskDetailMoveInComponent implements OnInit {
    @BlockUI('container-blockui-movein') blockUI: NgBlockUI;
    @ViewChild('pdfViewerModal', { static: true }) pdfViewerModal: PdfViewerModalComponent;
    @Input() companyId: number;
    @Input() facilityId: number;
    @Input() kioskId: number;
    @Output() refreshKioskDetail = new EventEmitter<number>();

    configurationModel: ConfigurationModel = new ConfigurationModel();
    fieldDetails: Array<FieldDetailModel> = new Array<FieldDetailModel>();
    masterModel: MasterDataModel = new MasterDataModel();
    totalAmenity: number;
    isMoveInType: boolean;
    isModelLoaded = false;
    showActionColumn = true;

    constructor(private appUtils: AppUtils,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private moveInService: MoveInService,
        private kioskService: KioskService) {

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['id'];

            this.masterModel.companyId = this.companyId;
            this.masterModel.facilityId = this.facilityId;
            this.masterModel.kioskId = this.kioskId;
        });
    }

    ngOnInit() {
        this.loadCongiuration();
        this.loadMandatoryFields();
        this.getAmenityCount();
    }

    loadCongiuration() {
        this.moveInService.getConfiguration(this.masterModel)
            .subscribe(
                (data: any) => {
                    Object.assign(this.configurationModel, data);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadMandatoryFields() {
        this.blockUI.start();
        this.moveInService.getMandatoryFields(this.masterModel)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.fieldDetails, data);
                    this.fieldDetails = this.fieldDetails.filter(field => {
                        if (field.isRequired === true) {
                            return field;
                        }
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    getAmenityCount() {
        this.blockUI.start();
        this.moveInService.getAmenityCount(this.masterModel)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.totalAmenity = data;
                    this.isModelLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    chagneApplicationType() {
        const model = new KioskChangeApplicationModel();

        model.id = this.kioskId;
        model.applicationType = 1;
        model.isActive = this.isMoveInType;

        this.blockUI.start();
        this.kioskService.changeApplicationType(model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success('Application type has been changed successfully');
                    this.refreshKioskDetail.emit();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateApplicationType(applicationType: number) {
        this.isMoveInType = applicationType && (applicationType === 1 || applicationType === 3);
    }

    public updateShowActionColumn() {
        this.showActionColumn = false;
    }

    openPdfViewer() {
        this.pdfViewerModal.openPdfViewerModal('Document File', this.configurationModel.documentFileName);
    }

}
