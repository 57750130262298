<div class="kt-content kt-grid__item kt-grid__item--fluid p-0" id="kt_content">
    <div class="row">
        <div class="col-12" *blockUI="'container-blockui-signage'">
            <div class="kt-portlet">
                <div class="kt-portlet__head head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Signage Application
                        </h3>
                        <p> Please enable the toggle button in order to activate the move-in-application on the kiosk
                            machine. </p>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions">
                                <label class="switch ">
                                    <input type="checkbox" [(ngModel)]="isSignageType" class="primary"
                                        (change)="chagneApplicationType()" [disabled]="signages.length === 0 || !showActionColumn">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" data-scroll="true" data-height="500" data-mobile-height="250">
                    <div class="row justify-content-center row-data-configure"
                        *ngIf="isModelLoaded && signages.length === 0 && showActionColumn">
                        <a [routerLink]="['/signage/add', companyId, facilityId, kioskId]"
                            class="btn btn-lg btn-primary">
                            <i class="la la-cloud-upload"></i>
                            Upload Content
                        </a>
                        <p class="w-100 p-3 text-center">
                            No contents are added for this application. Please
                            click on the upload button to upload content.
                        </p>
                    </div>
                    <div class="row" *ngIf="isModelLoaded && signages.length !== 0">
                        <div class="col-12 text-right form-group">
                            <a [routerLink]="['/signage/manage', companyId, facilityId, kioskId]" *ngIf="showActionColumn"
                                class="btn btn-sm btn-outline-primary">Manage</a>
                        </div>
                        <div class="col-4" *ngFor="let item of signages">
                            <div class="form-group row">
                                <div class="col-12 m-b-15 text-center cursor-pointer" (click)="showContent(item)">
                                    <img class="w-100 mw-100 mh-100 rounded" [src]="item.thumbnailFileUrl" />
                                </div>
                                <div class="col-12 m-b-5">
                                    <span class="kt-font-bold text-break">{{item.title}}</span>
                                </div>
                                <div class="col-12">
                                    <span class="kt-font-bold">Created On:</span>
                                    <span class="m-l-10">{{item.createdOn}}</span>
                                </div>
                                <div class="col-12">
                                    <span class="kt-font-bold">Content Type:</span>
                                    <span class="m-l-10">{{item.contentFileType}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-image-viewer-modal #imageViewerModal></app-image-viewer-modal>
<app-video-viewer-modal #videoViewerModal></app-video-viewer-modal>
<app-pdf-viewer-modal #pdfViewerModal></app-pdf-viewer-modal>