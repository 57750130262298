import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DataSource } from 'src/models/kpi-report/datasource.list.model';
import { DavinciLockConfigurationModel, FacilityDetailModel, QuickBooksAccountModel, QuickBooksConfigurationModel, QuickBooksModel, ReportQuery } from 'src/models';
import { QuickBooksAddRefreshTokenModel } from 'src/models/quickbooks/quickbooks.AddRefreshToken.model';
import { JiveCommunicationModel } from 'src/models/jive-communication/jivecommunication.model';
import { JiveAddRefreshTokenModel } from 'src/models/jive-communication/jive.addrefreshtoken.model';

@Injectable()
export class KpiReportService {
    
    private basURL = environment.apiBaseUrl + 'Reports/datasource-picker';
    
    constructor(public http: HttpClient) { }

    synchronizeStorEdge(companyId: number) {
        return this.http.post(environment.apiBaseUrl + 'Reports/seed-storedge-database/' + companyId, null);
    }

    synchronizeWorkOrder(companyId:number){
        return this.http.post(environment.apiBaseUrl + 'Reports/seed-work-order-database/' + companyId, null);
    }

    synchronizeDavinciLock(companyId:number){
        return this.http.post(environment.apiBaseUrl + 'Reports/seed-davinci-lock-database/' + companyId, null);
    }

    synchronizeQuickBooks(companyId:number){
        return this.http.post(environment.apiBaseUrl + 'Reports/seed-quick-books-database/' + companyId, null);
    }

    synchronizeJive(companyId:number){
        return this.http.post(environment.apiBaseUrl + 'Reports/seed-jive-database/' + companyId, null);
    }

    getDataSourceSynchnorize(companyId: number){
        return this.http.get(environment.apiBaseUrl + 'Reports/get-datasource-sync-history/' + companyId);
    }

    downloadReports(companyId: number,reportQueryModel:ReportQuery){
        let queryString = '?';
        queryString= queryString +'&dataSource='+reportQueryModel.datasource;
        if(reportQueryModel != null && reportQueryModel.datacolumn.length > 0){
            reportQueryModel.datacolumn.forEach((data)=>{
                queryString = queryString +'&dataColumns=' + data;
            });
        }
        
        queryString=queryString +'&startDate='+ reportQueryModel.startdate.toString();
        queryString=queryString +'&endDate='+ reportQueryModel.enddate.toString();

        return this.http.get(environment.apiBaseUrl + 'Reports/generate-report/' + companyId + queryString, { responseType: 'blob' as 'json' });
    }


    getdatasourceList():Observable<DataSource[]>{
        return this.http.get<DataSource[]>(`${this.basURL}`);
    }

    configureCompany(model: DavinciLockConfigurationModel) {
        return this.http.post(environment.apiBaseUrl + 'Reports/davinci-lock-configure-company', model);
    }

    getDavinciLockCompany(companyId: number):Observable<DavinciLockConfigurationModel>{
        return this.http.get<DavinciLockConfigurationModel>(environment.apiBaseUrl+'Reports/get-davinci-lock-company-mapping/'+companyId);
    }
    
    addQuickbooksconfigureCompany(model: QuickBooksConfigurationModel)
    {
        return this.http.post(environment.apiBaseUrl + 'Reports/add-quick-books-configure-company', model);
    }
    
    addQuickbooksconfigure(model:QuickBooksModel)
    {
        return this.http.post(environment.apiBaseUrl + 'Reports/add-quick-books-configure', model);
    }

    getQuickBooksConfigure(companyId:number):Observable<QuickBooksModel>{
        return this.http.get<QuickBooksModel>(environment.apiBaseUrl + 'Reports/get-quick-books-configure/'+companyId);
    }

    doQuickBooksConnection(model:QuickBooksModel){
        return this.http.post(environment.apiBaseUrl + 'Reports/do-quick-books-connection',model,{responseType: 'text'});
    }
    addQuickBooksRefreshToken(model:QuickBooksAddRefreshTokenModel){
        return this.http.post(environment.apiBaseUrl + 'Reports/add-quick-books-refresh-token',model);
    }

    getQuickBooksAccountSelectList(companyId:number,facilityId:string){
        return this.http.get(environment.apiBaseUrl + 'Reports/get-quick-books-account-select-list-items/'+ companyId +'/'+facilityId);
    }

    addQuickBooksAccountList(model:Array<QuickBooksAccountModel>){
        return this.http.post(environment.apiBaseUrl + 'Reports/add-quick-books-account-select-items',model);
    }

    getQuickBooksAccountData(companyId:number,facilityId:string){
        return this.http.get(environment.apiBaseUrl + 'Reports/get-quick-books-account-select-list-items-data/'+ companyId +'/'+facilityId);
    }
    
    addJiveCommunicationconfigure(model:JiveCommunicationModel)
    {
       return this.http.post(environment.apiBaseUrl + 'Reports/add-jive-communication-configure', model);
    }

    getJiveCommunicationConfigure(companyId:number):Observable<JiveCommunicationModel>
    {
       return this.http.get<JiveCommunicationModel>(environment.apiBaseUrl + 'Reports/get-jive-communication-configure/'+companyId);
    }

    doJiveConnection(model:JiveCommunicationModel){
        return this.http.post(environment.apiBaseUrl + 'Reports/do-jive-connection',model,{responseType: 'text'});
    }

    addJiveRefreshToken(model:JiveAddRefreshTokenModel){
        return this.http.post(environment.apiBaseUrl + 'Reports/add-jive-refresh-token',model);
    }
}
