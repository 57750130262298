<div class="row break-word">
    <div class="col-12" *ngFor="let item of activityLogs; let $index=index">
        Updated by <span class="kt-font-bold">{{item.createdByName}}</span> on
        <span class="kt-font-bold"> {{item.createdOn}}</span>
        <div *ngIf="item.subTaskId">
            <div class="row m-t-10" *ngIf="item.activityType == activityTypes.Insert">
                <div class="col-1"></div>
                <div class="col-11">
                    <div>
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">Subtask </span>
                        added by 
                        <span class="kt-font-bold">{{item.createdByName}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="item.activityType == activityTypes.Update || item.activityType == activityTypes.Delete">
                <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                    <div class="col-1"></div>
                    <div class="col-11" *ngIf="activity.oldValue && activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">Subtask {{activity.fieldName}} </span>
                        <span> has been updated </span>
                        <span>from <em>{{activity.oldValue}} </em></span>
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div class="col-11" *ngIf="activity.oldValue && !activity.newValue && activity.fieldName === 'UnitId' ">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">Subtask </span>
                        <span> for </span>
                        <span class="kt-font-bold"> Unit </span>
                        <span> {{activity.oldValue}} deleted by </span>
                        <span class="kt-font-bold"> {{item.createdByName}} </span>
                    </div>
                </div>
            </div>
            <div *ngIf="item.activityType == activityTypes.StatusUpdated">
                <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                    <div class="col-1"></div>
                    <div class="col-11" *ngIf="isStatusOpen(item.activityInfos); else subTaskClosed">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">Subtask </span>
                        <span> for </span>
                        <span class="kt-font-bold"> Unit </span>
                        <span> {{activity.oldValue}} has been reopened by </span>
                        <span class="kt-font-bold"> {{item.createdByName}} </span>
                    </div>
                    <ng-template #subTaskClosed class="col-11">
                        <div>
                            <em class="la la-check fs-13 m-r-10"></em>
                            <span class="kt-font-bold">Subtask </span>
                            <span> for </span>
                            <span class="kt-font-bold"> Unit </span>
                            <span> {{activity.oldValue}} marked as done by </span>
                            <span class="kt-font-bold"> {{item.createdByName}} </span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="!item.subTaskId">
            <div class="row m-t-10" *ngFor="let activity of item.activityDetails">
                <div class="col-1"></div>
                <div class="col-11" *ngIf="activity.fieldName !== 'Status' 
                        && activity.fieldName !== 'Priority' 
                        && activity.fieldName !== 'Assignee'
                        && activity.fieldName !== 'Category'
                        && activity.fieldName !== 'ReadReceipt'
                        && activity.fieldName !== 'ReportIssue'
                        && activity.fieldName !== 'Unit'
                        && activity.fieldName !== 'Description'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}} </span>
                    <span> changed </span>
                    <span *ngIf="activity.oldValue">from <em>{{activity.oldValue}} </em></span>
                    <span> to <em>{{activity.newValue}} </em></span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Unit'">
                    <div *ngIf="activity.oldValue && activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <span> changed </span>
                        <span>from <em>{{activity.oldValue}} </em></span>
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.oldValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span> changed
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <em> {{activity.oldValue}} </em>
                        <span> removed </span>
                    </div>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Assignee'">
                    <div *ngIf="activity.oldValue && activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <span> changed </span>
                        <span *ngIf="activity.oldValue">from <em>{{activity.oldValue}} </em></span>
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.oldValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span> changed
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <span> removed </span>
                    </div>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Status'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}} </span>
                    <span> changed </span>
                    <span> from </span>
                    <span
                        [ngClass]="{ 'text-danger' : activity.oldValue === 'NotStarted', 'text-info' : activity.oldValue === 'InProgress', 'text-warning' : activity.oldValue === 'OnHold', 'text-success' : activity.oldValue === 'Completed'}">
                        <em *ngIf="activity.oldValue === 'NotStarted'">Not Started </em>
                        <em *ngIf="activity.oldValue === 'InProgress'">In Progress </em>
                        <em *ngIf="activity.oldValue === 'OnHold'">On Hold </em>
                        <em *ngIf="activity.oldValue === 'Completed'">Completed </em>
                    </span>
                    <span> to </span>
                    <span
                        [ngClass]="{ 'text-danger' : activity.newValue === 'NotStarted', 'text-info' : activity.newValue === 'InProgress', 'text-warning' : activity.newValue === 'OnHold', 'text-success' : activity.newValue === 'Completed'}">
                        <em *ngIf="activity.newValue === 'NotStarted'">Not Started </em>
                        <em *ngIf="activity.newValue === 'InProgress'">In Progress </em>
                        <em *ngIf="activity.newValue === 'OnHold'">On Hold </em>
                        <em *ngIf="activity.newValue === 'Completed'">Completed </em>
                    </span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Priority'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}} </span>
                    <span> changed from </span>
                    <span
                        [ngClass]="{ 'text-success' : activity.oldValue === 'Low', 'text-warning' : activity.oldValue === 'Medium', 'text-danger' : activity.oldValue === 'High'}">
                        <em>{{activity.oldValue}} </em></span>
                    <span> to </span>
                    <span
                        [ngClass]="{ 'text-success' : activity.newValue === 'Low', 'text-warning' : activity.newValue === 'Medium', 'text-danger' : activity.newValue === 'High'}">
                        <em>{{activity.newValue}} </em>
                    </span>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Category'">
                    <div *ngIf="activity.oldValue && activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <span> changed </span>
                        <span *ngIf="activity.oldValue">from <em>{{activity.oldValue}} </em></span>
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.oldValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span> changed
                        <span> to <em>{{activity.newValue}} </em></span>
                    </div>
                    <div *ngIf="!activity.newValue">
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span class="kt-font-bold">{{activity.fieldName}} </span>
                        <span> removed from </span>
                        <em>{{activity.oldValue}}</em>
                    </div>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'ReadReceipt'">
                    <div>
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span> Workorder has been viewed by the </span>
                        <span> <em>{{activity.newValue}} </em></span>
                    </div>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'ReportIssue'">
                    <div>
                        <em class="la la-check fs-13 m-r-10"></em>
                        <span> Email sent to {{activity.newValue}}. </span>
                    </div>
                </div>
                <div class="col-11" *ngIf="activity.fieldName === 'Description'">
                    <em class="la la-check fs-13 m-r-10"></em>
                    <span class="kt-font-bold">{{activity.fieldName}} </span>
                    <span> changed </span>
                    <span *ngIf="activity.oldValue">from <em [innerHTML]="activity.oldValue | hyperlink"></em></span>
                    <span> to <em [innerHTML]="activity.newValue | hyperlink"></em></span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(activityLogs.length - 1) > $index">
            <div class="col-12">
                <div class="kt-divider m-t-15 m-b-15">
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="activityLogs.length === 0">
        <p>No log found</p>
    </div>
</div>