import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountRecoverModel, LeftBannerModel } from '../../../models';
import { AccountService } from '../../../services';
import { AppUtils } from '../../../helpers';


@Component({
    selector: 'app-account-recover',
    templateUrl: './account.recover.component.html',
    styleUrls: ['../../../assets/css/layout.public.css']
})

export class AccountRecoverComponent {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    formSubmitted = false;
    model: AccountRecoverModel = new AccountRecoverModel();
    leftBannerModel: LeftBannerModel = new LeftBannerModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private accountService: AccountService) {
        if (this.appUtils.isUserAuthenticated()) {
            this.router.navigate(['/']);
        }
    }

    submit() {
        this.blockUI.start();
        this.accountService.recover(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.formSubmitted = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
