<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader kt-grid__item">
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Not Found
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"
                                    style="background-image: url(./assets/media/error/bg5.jpg);">
                                    <div class="kt-error_container">
                                        <span class="kt-error_title">
                                            <h1 class="text-center">404</h1>
                                        </span>
                                        <p class="kt-error_subtitle text-center">
                                            Not Found
                                        </p>
                                        <p class="kt-error_description text-center">
                                            There may be a misspelling in the URL entered,<br>
                                            or the page you are looking for may no longer exist
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>