import { FacilityAssigneesValidationDto } from "..";

export class UserGroupEditModel {
  public id: string;
  public name: string;
  public companyId: number;
  public members: Array<string>;
  public lowPriorityWorkOrdersReminderInterval: number;
  public mediumPriorityWorkOrdersReminderInterval: number;
  public highPriorityWorkOrdersReminderInterval: number;

  public raiseEvent: boolean;
  public onlyUserGroupFacilities: Array<FacilityAssigneesValidationDto>;
  public multipleUserGroupFacilities: Array<FacilityAssigneesValidationDto>;
}