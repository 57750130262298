<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Work Orders
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body  kt-portlet__body--fit" *blockUI="'container-blockui-tiles-work-orders'">
        <div class="row row-no-padding row-col-separator-lg">
            <div class="col-lg-2" *ngFor="let item of tiles; let $index=index" [ngClass]="{'col-lg-4': $index === 0}">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                <a [routerLink]="item.redirectUrl" [queryParams]="item.redirectQueryParams">
                                    {{item.name}}
                                </a>
                            </h4>
                            <span class="kt-widget24__stats kt-font-{{item.bgColor}}">
                                {{item.count}}
                            </span>
                        </div>
                    </div>
                    <ngb-progressbar class="m-t-5" [type]="item.bgColor" [value]="item.percentage"
                        *ngIf="item.percentage !== -1">
                    </ngb-progressbar>
                    <div class="kt-widget24__action" *ngIf='item.percentage != -1'>
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">
                            {{item.percentage}}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>