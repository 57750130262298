import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { DataTableResponseModel, UserGroupSelectList, WorkOrderFilterModel } from 'src/models';
import { MasterService, UserGroupService } from 'src/services';

@Component({
  selector: 'app-users-total-open-work-orders',
  templateUrl: './users-total-open-work-orders.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./users-total-open-work-orders.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsersTotalOpenWorkOrdersComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  pageLength = 10;

  roleMatrix: RoleMatrix;

  filterText = '';
  userGroupId = '';
  userGroups: UserGroupSelectList[] = [];

  constructor(private http: HttpClient,
              private router: Router,
              private toastrService: ToastrService,
              private masterService: MasterService,
              private userGroupService: UserGroupService,
              private changeDetector: ChangeDetectorRef,
              private appUtils: AppUtils) {
    this.roleMatrix = new RoleMatrix();
  }

  ngOnInit() {
    this.initDataTable();
    this.loadUserGroups();
  }

  ngAfterViewInit() {
    this.datatableElement.dtInstance
      .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
  }

  loadUserGroups() {
    this.userGroupService.getCompanyUserGroupsSelectItemFacilityWise(+this.roleMatrix.companyId, false).subscribe(
        data => {
            this.userGroups = data;
            this.changeDetector.markForCheck();
        },
        error => {
            this.appUtils.ProcessErrorResponse(this.toastrService, error);
        });
  }

  initDataTable() {
    const self = this;

    this.dtOptions = {
        dom: '<"top">rt<"bottom"lip><"clear">',
        serverSide: true,
        processing: true,
        language: {
            loadingRecords: '&nbsp;',
            processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
            searchPlaceholder: 'Filter category...',
            paginate: {
                first: '<i class="fa fa-angle-double-left">',
                last: '<i class="fa fa-angle-double-right">',
                previous: '<i class="fa fa-angle-left">',
                next: '<i class="fa fa-angle-right">'
            }
        },
        search: { search: null },
        searching: false,
        displayStart: self.rowIndex,
        paging: true,
        pagingType: 'full_numbers',
        pageLength: self.pageLength,
        lengthMenu: [10, 15, 25, 50, 100],
        order: [[1, 'desc']],
        stateSave: true,
        stateLoaded: function(settings: any, data: any) {
          settings.iInitDisplayStart = 0;
        },
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.companyId = this.roleMatrix.companyId;
          dataTablesParameters.filterText = this.filterText;
          dataTablesParameters.userGroupId = this.userGroupId;

          const url = `${environment.apiBaseUrl}users/reports/generate-users-total-open-work-orders-report`;
          self.http
              .post<DataTableResponseModel>(url, dataTablesParameters, {})
              .subscribe(resp => {
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: resp.data
                  });
              }, error => {
                self.appUtils.ProcessErrorResponse(self.toastrService, error);
              });
        },
        columns: [
            {
                data: 'userFullName',
                title: 'Name',
                width: '25%',
                render: function (userFullName, type, row, meta) {
                  return `<span class='link' action-type='link-detail'>${userFullName}</a>`;
                }
            },
            {
                data: 'openWorkOrdersCount',
                title: 'Open Workorders',
                width: '15%',
                className: 'text-center'
            },
            {
                data: 'medianOpenDuration',
                title: 'Median Open Duration',
                width: '15%',
                className: 'text-center'
            },
            {
                data: 'noOfOpenWorkOrdersPerUnit',
                title: 'No. Of Open Work Orders Per Unit',
                width: '15%',
                className: 'text-center',
                render: function (noOfOpenWorkOrdersPerUnit) {
                  return noOfOpenWorkOrdersPerUnit.toFixed(2);
                }
            },
            {
                data: 'closedWorkOrdersLast7And30Days',
                title: 'Closed Work Orders (7/30 days)',
                width: '15%',
                className: 'text-center',
                defaultContent: '',
                orderable: false,
                render: function (cellData, type, rowData) {
                  return `${rowData.closedWorkOrdersLast7Days} / ${rowData.closedWorkOrdersLast30Days}`;
                }
            },
            {
                data: 'newWorkOrdersLast7And30Days',
                title: 'New Work Orders (7/30 days)',
                width: '15%',
                className: 'text-center',
                defaultContent: '',
                orderable: false,
                render: function (cellData, type, rowData) {
                  return `${rowData.newWorkOrdersLast7Days} / ${rowData.newWorkOrdersLast30Days}`;
                }
            }
        ],
        rowCallback: function (row, data: any, dataIndex: number) {
            const detailElem = $(row).find('[action-type = link-detail]');
            $(detailElem).unbind('click');
            $(detailElem).on('click', function () {
              self.masterService.setWorkOrderShowPersonnalData(false);
              const workOrderFilterModel = new WorkOrderFilterModel();
              workOrderFilterModel.assigneeIds = [data.userId];
              localStorage.setItem('filterModel', JSON.stringify(workOrderFilterModel));
              self.router.navigate(['work-order', 'grid-view', self.roleMatrix.companyId]);
            });
        },
        drawCallback: function () {
            if ($('.pagination li').length <= 5) {
                $('.pagination').hide();
            }
        }
    };
  }

  applyFilter() {
    this.dtInstance.ajax.reload();
  }

  resetFilter() {
    this.filterText = '';
    this.userGroupId = '';
    this.applyFilter();
  }
}
