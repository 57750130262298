import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-report-manage',
  templateUrl: './breadcrumb.report.manage.component.html'  
})
export class BreadcrumbReportManageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
