<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-kiosk-add [companyId]="model.companyId"> </app-breadcrumb-kiosk-add>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Register Kiosk
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="row">
                                <div class="col-8">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && facilityId.invalid}">
                                        <label class="col-md-3 text-right">Select Facility:*</label>
                                        <div class="col-md-9">
                                            <select name="facilityId" class="form-control"
                                                [(ngModel)]="model.facilityId" #facilityId="ngModel" required>
                                                <option value="">[Select]</option>
                                                <option *ngFor="let item of facilities" [value]="item.id">
                                                    {{item.name}}</option>
                                            </select>
                                            <span>Select the facility for which the kiosk is being set up</span>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && facilityId.invalid">
                                                <span *ngIf="facilityId.errors.required"> Facility is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && registrationKey.invalid}">
                                        <label class="col-md-3 text-right">Registration Key:*</label>
                                        <div class="col-md-9">
                                            <input type="text" name="registrationKey" class="form-control" maxlength="6"
                                                autocomplete="off" [(ngModel)]="model.registrationKey"
                                                #registrationKey="ngModel" appNumbersOnly required />
                                            <span>
                                                Enter the registration key generated by the kiosk machine at the time of
                                                installation
                                            </span>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && registrationKey.invalid">
                                                <span *ngIf="registrationKey.errors.required">
                                                    Registration key is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && tag.invalid}">
                                        <label class="col-md-3 text-right">Tag:*</label>
                                        <div class="col-md-9">
                                            <input type="text" name="tag" class="form-control" maxlength="20"
                                                autocomplete="off" [(ngModel)]="model.tag" #tag="ngModel"
                                                appAlphaNumericsLevelThree required />
                                            <span>
                                                Add a tag to the kiosk machine
                                            </span>
                                            <div class="validation-error-message" *ngIf="f.submitted && tag.invalid">
                                                <span *ngIf="tag.errors.required"> Tag is required</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <div class="row">
                                    <div class="col-8">
                                        <button type=" submit" class="btn btn-primary">
                                            <span class="kt-menu__link-icon">
                                                <em class="kt-menu__link-icon flaticon-eye"></em>
                                            </span>Configure
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>