import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils, AppSettings } from 'src/helpers';
import { UserService, ListenerService } from 'src/services';
import { UserImageUploadModel } from '../../../models';
import { isNullOrUndefined } from  '../../../helpers/tools';
import * as jwt_decode from 'jwt-decode';

@Component({
    selector: 'app-user-image-upload',
    templateUrl: './user.image.upload.component.html'
})
export class UserImageUploadComponent implements OnInit {
    @ViewChild('userImageElem', { static: true }) userImageElem: ElementRef;
    @ViewChild('userImageCropper', { static: true }) userImageCropper: any;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @Input() model: UserImageUploadModel;
    modalReferece: NgbModalRef;
    fileUploadRequest: any;

    imageChangedEvent: any = '';
    showCropper = false;

    constructor(private toastr: ToastrService,
        private modalService: NgbModal,
        private appUtils: AppUtils,
        private appSettings: AppSettings,
        private userService: UserService,
        private listenerService: ListenerService) {
    }

    ngOnInit() {
        this.updateHeaderProfilePhoto();
    }

    closeImageCropperModal(cancelUpload: boolean) {
        this.modalReferece.close();
        this.removeSelectedImage();
        if (cancelUpload && this.fileUploadRequest) {
            this.fileUploadRequest.unsubscribe();
        }
        this.showCropper = false;
    }

    loadImageFailed() {
        this.showCropper = false;
        this.modalReferece.close();
        this.toastr.error('Failed to load image');
    }

    onImageSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            this.model.file = null;
            return;
        }
        this.model.file = event.target.files.item(0);
        const fileType = this.model.file.type;

        if (fileType !== 'image/png'
            && fileType !== 'image/jpg'
            && fileType !== 'image/jpeg') {
            this.toastr.error('Only JPG, JPEG and PNG formats are allowed');
            this.removeSelectedImage();
            return;
        }

        if (this.model.file.size > this.appSettings.defaultImageSize) {
            this.removeSelectedImage();
            this.toastr.error('Image must be less than or equal to 15 MB');
        }

        this.modalReferece = this.modalService.open(
            this.userImageCropper,
            {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        this.fileChangeEvent(event);
    }

    uploadImage() {
        this.fileUploadRequest = this.userService.uploadFile(this.model)
            .subscribe(
                (e: HttpEvent<any>) => {
                    switch (e.type) {
                        case HttpEventType.UploadProgress:
                            const percentDone = Math.round(100 * e.loaded / e.total);
                            this.model.fileUploadPercent = percentDone;
                            if (percentDone >= 100) {
                                this.closeImageCropperModal(false);
                                this.blockUI.start();
                            }
                            break;
                        case HttpEventType.Response:
                            this.model.fileName = e.body.fileName;
                            this.model.fileUrl = e.body.fileUrl;
                            this.removeSelectedImage();
                            this.updateHeaderProfilePhoto();
                            setTimeout(() => {
                                this.blockUI.stop();
                            }, 1000);
                            break;
                    }
                },
                (error) => {
                    this.closeImageCropperModal(false);
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                    this.blockUI.stop();
                });
    }

    removeSelectedImage() {
        this.model.file = null;
        this.model.originalFileName = null;
        this.userImageElem.nativeElement.value = null;
    }

    deleteFile() {
        if (isNullOrUndefined(this.model.userId) || this.model.userId === '') {
            this.model.fileUrl = null;
            this.removeSelectedImage();
            return;
        }
        this.blockUI.start();
        this.userService.deleteFile(this.model.userId)
            .subscribe(
                () => {
                    this.model.fileName = null;
                    this.model.fileUrl = null;
                    this.updateHeaderProfilePhoto();
                    this.blockUI.stop();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    updateHeaderProfilePhoto() {
        const authToken = localStorage.getItem('AuthToken');
        if (!authToken) {
            return;
        }
        const decodedToken = jwt_decode(authToken);
        if (decodedToken.nameid === this.model.userId) {
            this.listenerService.updateUserInfoListner.next();
        }
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageLoaded(e: any) {
        this.showCropper = true;
        this.model.processingWidth = e.original.size.width;
        this.model.processingHeight = e.original.size.height;
    }

    imageCropped(e: any) {
        this.model.dimensionX1 = e.imagePosition.x1;
        this.model.dimensionX2 = e.width;
        this.model.dimensionY1 = e.imagePosition.y1;
        this.model.dimensionY2 = e.height;
    }
}
