import { Component, Input } from '@angular/core';
import { KioskActivityDetailModel, KioskActivityLogModel } from '../../../models';

@Component({
    selector: 'app-kiosk-activity-log-item',
    templateUrl: './kiosk.activity.log.item.component.html'
})

export class KioskActivityLogItemComponent {
    @Input() activityLogs: Array<KioskActivityLogModel>;

    checkForApplicationType(KioskActivityDetails : Array<KioskActivityDetailModel>, type: string){
        return KioskActivityDetails.filter(x=>x.fieldName == type).length > 0;
    }
}
