<!-- <div class="kt-subheader__breadcrumbs">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>QuickBooks Accounts</span>
</div> -->

<div class="kt-subheader__breadcrumbs"
    *ngIf="roleMatrix.role === 'SUPERADMIN' || roleMatrix.role === 'ADMIN' || roleMatrix.role === 'USER'">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
   <span class="kt-subheader__breadcrumbs-separator"></span>
   <a [routerLink]="['/storedge/intergration',companyId]" class="kt-subheader__breadcrumbs-link">
    Settings </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>QuickBooks Accounts</span>
</div>
<div class="kt-subheader__breadcrumbs"
    *ngIf="roleMatrix.role === 'ORGSUPERADMIN' || roleMatrix.role === 'ORGADMIN' || roleMatrix.role === 'ORGUSER'">
    <a [routerLink]="['/']" class="kt-subheader__breadcrumbs-home">
        <em class="flaticon2-shelter"></em>
    </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
   <a [routerLink]="['/storedge/intergration',companyId]" class="kt-subheader__breadcrumbs-link">
    Settings </a>
    <span class="kt-subheader__breadcrumbs-separator"></span>
    <span>QuickBooks Accounts</span>
</div>