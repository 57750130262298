
import { NgModule } from '@angular/core';

import { appDeclarations } from './app.declarations';
import { appImports } from './app.imports';
import { appProviders } from './app.providers';

import { AppComponent } from './app.component';
import { InfoModalComponent } from 'src/components/shared';
import { AddSubTaskComponent } from '@10fed/work-order/sub-tasks/add/add-sub-task.component';
import { EditSubTaskComponent } from '@10fed/work-order/sub-tasks/edit/edit-sub-task.component';

@NgModule({
    declarations: appDeclarations,
    imports: appImports,
    providers: appProviders,
    bootstrap: [AppComponent]
})
export class AppModule { }
