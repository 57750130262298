import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RoleMatrix } from 'src/helpers';
import { MasterService } from 'src/services';

@Component({
  selector: 'app-kpi-dashboard',
  templateUrl: './kpi-dashboard.component.html'
})
export class KpiDashboardComponent {

  @HostBinding('class.full_width') full_width = true;

  activeTabId: string;

  constructor(private router: Router,
              private masterService: MasterService) {
    this.activeTabId = this.masterService.kpiDashboardSelectedTabIndex;

    const roleMatrix = new RoleMatrix();
    if (!roleMatrix.canViewKpiDashboard) {
        this.router.navigate(['/error/404']);
    }
  }

  onTabChange(event: NgbTabChangeEvent) {
    this.activeTabId = event.nextId;
    this.masterService.kpiDashboardSelectedTabIndex = event.nextId;
  }
}
