export class ReportQuery{
    public datasource:number;
    public datacolumn:Array<any>;
    public startdate:string;
    public enddate:string;

    constructor(){
        this.datacolumn = [];

    }


 }