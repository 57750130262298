import { Component, Input, OnInit } from '@angular/core';
import { isNumber } from '../../../../helpers/tools';

@Component({
    selector: 'app-work-order-status-label',
    templateUrl: './work.order.status.label.component.html'
})

export class WorkOrderStatusLabelComponent implements OnInit {
    @Input() workOrderStatus: any;

    status: number;

    ngOnInit() {

        if (isNumber(this.workOrderStatus)) {
            this.status = this.workOrderStatus;
            return;
        }

        switch (this.workOrderStatus) {
            case 'NotStarted':
                this.status = 1;
                break;
            case 'InProgress':
                this.status = 2;
                break;
            case 'OnHold':
                this.status = 3;
                break;
            case 'Completed':
                this.status = 4;
                break;
        }
    }
}

