import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
    UserAddModel, UserEditModel, CompanyUserAddModel,
    CompanyUserEditModel, UserImageUploadModel
} from '../models';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    checkUserHealth() {
        return this.http.get(environment.apiBaseUrl + 'account/check-user-health');
    }

    add(model: UserAddModel) {
        return this.http.post(environment.apiBaseUrl + 'account/add', model);
    }

    toggleStatus(id: any) {
        return this.http.post(environment.apiBaseUrl + 'account/toggle-status/' + id, null);
    }

    delete(id: any) {
        return this.http.post(environment.apiBaseUrl + 'account/delete/' + id, null);
    }

    getForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'account/get-for-edit/' + id);
    }

    edit(model: UserEditModel) {
        return this.http.post(environment.apiBaseUrl + 'account/edit', model);
    }

    getDetail(id: any) {
        return this.http.get(environment.apiBaseUrl + 'account/get-detail/' + id);
    }

    addCompanyUser(model: CompanyUserAddModel) {
        return this.http.post(environment.apiBaseUrl + 'account/add-company-user', model);
    }

    getCompanyUserDetail(id: any) {
        return this.http.get(environment.apiBaseUrl + 'account/get-company-user-detail/' + id);
    }

    getCompanyAccountOwner<T>(companyId: number) {
        return this.http.get<T>(`${environment.apiBaseUrl}account/get-company-account-owner/${companyId}`);
    }

    getCompanyUserForEdit(id: number) {
        return this.http.get<CompanyUserEditModel>(environment.apiBaseUrl + 'account/get-company-user-for-edit/' + id);
    }

    editCompanyUser(model: CompanyUserEditModel) {
        return this.http.post(environment.apiBaseUrl + 'account/edit-company-user', model);
    }

    getCompanyAdministratorsCount(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'account/get-company-administrators-count/' + companyId);
    }

    getCompanyUsersCount(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'account/get-company-users-count/' + companyId);
    }

    getCompanyUsersSelectItem(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'account/get-company-users-select-item/' + companyId);
    }

    getCompanyUsersSelectItemFacilitywise(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'account/get-company-users-select-item-facilitywise/' + companyId);
    }

    uploadFile(model: UserImageUploadModel) {
        const formData: FormData = new FormData();
        formData.append('userId', model.userId);
        formData.append('file', model.file);
        formData.append('processingWidth', model.processingWidth.toString());
        formData.append('processingHeight', model.processingHeight.toString());
        formData.append('dimensionX1', model.dimensionX1.toString());
        formData.append('dimensionX2', model.dimensionX2.toString());
        formData.append('dimensionY1', model.dimensionY1.toString());
        formData.append('dimensionY2', model.dimensionY2.toString());

        return this.http.post(environment.apiBaseUrl + 'account/upload-file', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    deleteFile(userId: string) {
        return this.http.post(environment.apiBaseUrl + 'account/delete-file/' + userId, null);
    }

    followCompanyUser(followingUserId: string, isFollowing: boolean) {
        return this.http.post(`${environment.apiBaseUrl}account/follow-company-user/${followingUserId}/${isFollowing}`, null);
    }

    resetPasswordForUser(userId: any) {
        return this.http.get(`${environment.apiBaseUrl}account/reset-password-for-user/${userId}`);
    }

}
