import { Component, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-storedge-confirm-import',
    templateUrl: './storedge.confirm.import.component.html'
})

export class StoredgeConfirmImportComponent {
    @ViewChild('facilityImportConfirmationModal', { static: true }) facilityImportConfirmationModal: any;
    @Output() importFacilities = new EventEmitter<any>();
    facilities: Array<string>;
    modalReference: NgbModalRef;

    constructor(private modalService: NgbModal) { }

    openImportConfirmationModal(facilities: Array<string>) {
        this.facilities = facilities;
        this.modalReference = this.modalService.open(this.facilityImportConfirmationModal);
    }

    closeImportConfirmationModal() {
        this.modalReference.close();
    }

    import() {
        this.closeImportConfirmationModal();
        this.importFacilities.emit();
    }
}
