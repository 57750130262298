import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { FacilityAddModel, FacilityEditModel, FacilityAssignedToUserGroupModel, ValidateUserGroupModel, FacilityDetailLight } from '../models';
import { AppSettings } from '../helpers';
import { FacilityNoteAddModel } from 'src/models/facility/facility.note.add.model';
import { FacilityUserGroupsAssignedUsersModel } from 'src/models/facility/FacilityUserGroupsAssignedUsers.model';

@Injectable()
export class FacilityService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    add(model: FacilityAddModel) {
        return this.http.post(environment.apiBaseUrl + 'facility/add', model);
    }

    edit(model: FacilityEditModel) {
        return this.http.post(environment.apiBaseUrl + 'facility/edit', model);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-detail/' + id);
    }

    getDetailLight(facilityId: number) {
        return this.http.get<FacilityDetailLight>(`${environment.apiBaseUrl}facility/get-facility-detail-light/${facilityId}`);
    }

    getForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-for-edit/' + id);
    }

    getCountByCompanyId(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(`${environment.apiBaseUrl}facility/get-count-by-company/${companyId}/${considerOnlyPersonalData}`);
    }

    toggleStatus(id: number) {
        return this.http.post(environment.apiBaseUrl + 'facility/toggle-status/' + id, null);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'facility/delete/' + id, null);
    }

    getSelectListItems(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-select-list-items/' + companyId);
    }

    getSelectListItemsImportedOnly(companyId: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-select-list-items-imported-only/' + companyId);
    }

    getSelectListItemsWithAssignedToWorkOrder(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-select-list-items-with-assigned-to-work-order/' + companyId +
            `?considerOnlyPersonalData=${considerOnlyPersonalData}`);
    }

    getAssignedUsersList(facilityId: number, onlyActiveUserGroups: boolean, onlyActiveUsers: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}facility/get-assigned-users-list/${facilityId}/${onlyActiveUserGroups}/${onlyActiveUsers}`);
    }

    getUsersAndUserGroupsList(facilityId: number, onlyActiveUserGroups: boolean, onlyActiveUsers: boolean, skipGroupMembers: boolean) {
        return this.http.get(
            `${environment.apiBaseUrl}facility/get-users-and-user-groups-list/${facilityId}/${onlyActiveUserGroups}/${onlyActiveUsers}?skipGroupMembers=${skipGroupMembers}`);
    }

    getAssignedUsersListForMultiSelect(facilityIds: Array<number>, onlyActiveUserGroups: boolean, onlyActiveUsers: boolean) {
        let queryString = '?';
       
        facilityIds.map((facilityId) => {
        queryString += 'facilityIds=' + facilityId + '&';
        });
       
        return this.http.get(
            environment.apiBaseUrl+'facility/get-assigned-users-list-by-facility-ids-multi-select/' + queryString+'onlyActiveUserGroups='+onlyActiveUserGroups+'&onlyActiveUsers='+onlyActiveUsers);
    }

    getAssignedUsersListByFacilityIds(facilityIds: Array<string>) {
        let queryString = '?';
        facilityIds.map((facilityId) => {
            queryString += 'facilityIds=' + facilityId + '&';
        });
        return this.http.get(environment.apiBaseUrl + 'facility/get-assigned-users-list-by-facility-ids' + queryString);
    }

    getPropertyManagerDetail(facilityId: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-property-manager-detail/' + facilityId);
    }

    getDetailByStoredgeIds(ids: Array<string>) {
        return this.http.post(environment.apiBaseUrl + 'facility/get-detail-by-storedge-ids', ids);
    }

    getFacilityStatisticsOfActiveRecords(id: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-facility-statistics-of-active-records/' + id);
    }

    getFacilitiesUserGroups(companyId: number, userGroupId: string) {
        return this.http.get<ValidateUserGroupModel>(environment.apiBaseUrl + 'facility/' + companyId + '/user-group/' + userGroupId);
    }

    getStatus(id: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-status/' + id);
    }

    isUserAssignedToFacility(userId: string, facilityId: number) {
        return this.http.get<boolean>(`${environment.apiBaseUrl}facility/is-user-assigned-to-facility/${userId}/${facilityId}`);
    }

    followCompanyUser(facilityId: number, isFollowing: boolean) {
        return this.http.post(`${environment.apiBaseUrl}facility/follow/${facilityId}/${isFollowing}`, null);
    }

    addNote(model: FacilityNoteAddModel) {
        return this.http.post(environment.apiBaseUrl + 'facility/addNote', model);
    }

    getNotes(id: number) {
        return this.http.get(environment.apiBaseUrl + 'facility/get-notes-history/' + id);
    }

    getFacilityUserGroupsAndUserForEditFacility(model: FacilityUserGroupsAssignedUsersModel){
        return this.http.post(environment.apiBaseUrl + 'facility/get-facility-user-groups-users-for-edit-facility', model);
    }

    public getCompanyUsersOfUserGroups = (companyId: number, facilityId: number, groupIds: string[]) =>
    this.http.get(`${environment.apiBaseUrl}facility/get-company-users-of-user-groups/${companyId}/${facilityId}?groupIds=${groupIds.join('&groupIds=')}`)
  
}
