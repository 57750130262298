import { Component, Input } from '@angular/core';

import { RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-dashboard-company-user',
    templateUrl: './dashboard.company.user.component.html'
})

export class DashboardCompanyUserComponent {
    roleMatrix: RoleMatrix;
    @Input() companyId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}

