export class SignageSummaryListItemModel {
    public id: number;
    public contentFileName: string;
    public contentFileUrl: string;
    public contentFileType: string;
    public thumbnailFileName: string;
    public thumbnailFileUrl: string;
    public title: string;
    public createdOn: string;
}
