import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivityLogService } from '../../../services';
import { ActivityLogDetailModel } from '../../../models';
import { AppUtils } from '../../../helpers';
import { isNullOrUndefined } from  '../../../helpers/tools';
declare var appConfig: any;

@Component({
    selector: 'app-activity-log-recent',
    templateUrl: './activity.log.recent.component.html'
})

export class ActivityLogRecentComponent implements OnInit, OnDestroy {
    @BlockUI('container-blockui-activity-log') blockUI: NgBlockUI;

    activityLogs: Array<ActivityLogDetailModel> = new Array<ActivityLogDetailModel>();

    offset = 0;
    limit = 20;
    showLoadMore = false;
    timeInterval: any;

    constructor(private toastr: ToastrService,
        private appUtils: AppUtils,
        private activityLogService: ActivityLogService) {
    }

    ngOnInit() {
        this.loadActivityLogs(true);
        setTimeout(() => {
            appConfig.initKTDefaults();
        });
        this.startActivitiyLogTimer();
    }

    loadActivityLogs(isNonTimerCall: boolean) {
        if (isNonTimerCall) {
            this.blockUI.start();
        }
        let offset = 0;
        if (isNonTimerCall) {
            offset = this.offset;
        }
        this.activityLogService.getList(offset, this.limit)
            .subscribe(
                (data: Array<ActivityLogDetailModel>) => {
                    if (isNonTimerCall) {
                        this.blockUI.stop();
                    }
                    data.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                        item.is10FedUser = this.appUtils.is10FedUser(item.createdByRole);
                        if (isNonTimerCall) {
                            this.activityLogs.push(item);
                        } else {
                            const isNewItem = isNullOrUndefined(this.activityLogs.find(x => x.activityLogId === item.activityLogId));
                            if (isNewItem) {
                                this.activityLogs.unshift(item);
                            }
                        }
                    });
                    this.showLoadMore = (data && data.length > 19);
                },
                error => {
                    if (isNonTimerCall) {
                        this.blockUI.stop();
                    }
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadMore() {
        this.offset += this.limit;
        this.loadActivityLogs(true);
    }

    reloadActivityLogs() {
        this.offset = 0;
        this.limit = 20;
        this.activityLogs = new Array<ActivityLogDetailModel>();
        this.loadActivityLogs(true);
        clearInterval(this.timeInterval);
        this.startActivitiyLogTimer();
    }

    startActivitiyLogTimer() {
        this.timeInterval = setInterval(() => {
            this.loadActivityLogs(false);
        }, 60000);
    }

    ngOnDestroy() {
        clearInterval(this.timeInterval);
        this.blockUI.stop();
    }
}
