<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-vendor-edit [companyId]="companyId"></app-breadcrumb-vendor-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Vendor
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && vendorName.invalid}">
                                <label class="col-md-3">Name:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="vendorName" class="form-control" maxlength="100"
                                        autocomplete="off" [(ngModel)]="model.name" #vendorName="ngModel" required />
                                    <div class="validation-error-message" *ngIf="f.submitted && vendorName.invalid">
                                        Vendor name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && vendorTypeId.invalid}">
                                <label class="col-md-3">Type:*</label>
                                <div class="col-md-9">
                                    <select name="vendorTypeId" class="form-control" [(ngModel)]="model.vendorTypeId"
                                        #vendorTypeId="ngModel" required>
                                        <option value="">[Select]</option>
                                        <option *ngFor="let item of vendorTypes" [value]="item.key">{{item.value}}
                                        </option>
                                    </select>
                                    <div class="validation-error-message" *ngIf="f.submitted && vendorTypeId.invalid">
                                        Vendor type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && contactNumber.invalid}">
                                <label class="col-md-3">Contact Number:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="contactNumber" class="form-control" maxlength="13"
                                        minlength="7" autocomplete="off" [(ngModel)]="model.phone"
                                        #contactNumber="ngModel" appPhoneNumberOnly required />
                                    <div class="validation-error-message" *ngIf="f.submitted && contactNumber.invalid">
                                        <span *ngIf="contactNumber.errors.required">
                                            Contact number is required
                                        </span>
                                        <span *ngIf="contactNumber.errors.minlength">
                                            Contact number must be in between 7 to 13 characters
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && email.invalid}">
                                <label class="col-md-3">Email:</label>
                                <div class="col-md-9">
                                    <input type="email" name="email" class="form-control" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.email" #email="ngModel" />
                                    <div class="validation-error-message" *ngIf="f.submitted && email.invalid">
                                        <span *ngIf="!email.errors.minlength">
                                            Invalid email address
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && Location.invalid}">
                                <label class="col-md-3">Location:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="Location" class="form-control" maxlength="250"
                                        autocomplete="off" [(ngModel)]="model.address" #Location="ngModel"
                                        appAlphaNumericsLevelFour required />
                                    <div class="validation-error-message" *ngIf="f.submitted && Location.invalid">
                                        Location is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && BankAccount.invalid}">
                                <label class="col-md-3"> Account Number:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="BankAccount" class="form-control" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.bankAccount" #BankAccount="ngModel"
                                        appAlphaNumericsOnly required />
                                    <div class="validation-error-message" *ngIf="f.submitted && BankAccount.invalid">
                                        Account Number is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && W9Number.invalid}">
                                <label class="col-md-3">W-9 Number:</label>
                                <div class="col-md-9">
                                    <input type="text" name="W9Number" class="form-control" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.w9Number" #W9Number="ngModel" />
                                    <div class="validation-error-message" *ngIf="f.submitted && W9Number.invalid">
                                        W-9 Number is invalid
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && InsurancePolicyName.invalid}">
                                <label class="col-md-3">Insurance Policy Name:</label>
                                <div class="col-md-9">
                                    <input type="text" name="InsurancePolicyName" class="form-control" maxlength="100"
                                        autocomplete="off" [(ngModel)]="model.insurancePolicyName"
                                        #InsurancePolicyName="ngModel" />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && InsurancePolicyName.invalid">
                                        Insurance Policy Name is invalid
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && InsurancePolicyNumber.invalid}">
                                <label class="col-md-3">Insurance Policy Number:</label>
                                <div class="col-md-9">
                                    <input type="text" name="InsurancePolicyNumber" class="form-control" maxlength="100"
                                        autocomplete="off" [(ngModel)]="model.insurancePolicyNumber"
                                        #InsurancePolicyNumber="ngModel" />
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && InsurancePolicyNumber.invalid">
                                        Insurance Policy Number is invalid
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && InsurancePolicyExpiryDate.invalid}">
                                <label class="col-md-3">Insurance Policy Expiry Date:</label>
                                <div class="col-md-9">
                                    <div class="input-group date">
                                        <input class="form-control" placeholder="MM/DD/YYYY"
                                            name="InsurancePolicyExpiryDate"
                                            [(ngModel)]="model.insurancePolicyExpiryDate" ngbDatepicker
                                            #d="ngbDatepicker" #InsurancePolicyExpiryDate="ngModel" (focus)="d.open()"
                                            autocomplete="off" [minDate]="minDueDate" />
                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer" (click)="d.toggle()">
                                                <i class="la la-calendar"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="validation-error-message"
                                        *ngIf="f.submitted && InsurancePolicyExpiryDate.invalid">
                                        Insurance Policy Expiry Date is invalid
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted}">
                                <label class="col-md-3">Facility Assigned:</label>
                                <div class="col-md-9">
                                    <ng-multiselect-dropdown name="selectedFacilities" [placeholder]="'[Select]'"
                                        [(ngModel)]="selectedFacilities"
                                        [settings]="multiSelectDropdownConfigs"
                                        [data]="facilities">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12">
                                    <span class="kt-font-bold m-b-5">Add Attachments
                                        <button type="button"
                                            class="btn btn-xs btn-outline-brand btn-elevate btn-icon m-l-5"
                                            (click)="vendorInsuranceDocumentAttachment.click()">
                                            <i class="la la-plus"></i>
                                        </button>
                                    </span>
                                    <div class="kt-divider m-t-10 m-b-0"
                                        *ngIf="model.insurancePolicyDocuments && model.insurancePolicyDocuments.length > 0">
                                        <span></span>
                                    </div>
                                    <div class="m-t-10" *ngIf="uploadedPercent > 0 && uploadedPercent < 100">
                                        <ngb-progressbar type="info" [value]="uploadedPercent" height="5px">
                                        </ngb-progressbar>
                                    </div>
                                    <input type="file" name="file" class="d-none"
                                        accept=".doc,.docx,image/jpeg,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        #vendorInsuranceDocumentAttachment (change)="onAttachmentSelect($event)" />
                                </div>
                            </div>

                            <div class="form-group row"
                                *ngIf="model.insurancePolicyDocuments && model.insurancePolicyDocuments.length">
                                <div *ngFor="let item of model.insurancePolicyDocuments; let $index = index">
                                    <p class="kt-portlet__data">
                                        <a [href]="item.fileUrl" target="_blank">
                                            <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5">
                                                {{item.originalFileName}}
                                            </span>
                                        </a>
                                        <button type="button" title="Delete {{item.originalFileName}}"
                                            class="btn btn-xs btn-outline-danger btn-elevate btn-icon m-t-5 m-r-5 m-b-5"
                                            (click)="deleteAttachment(item.fileName, $index)">
                                            <i class="la la-trash"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/vendor/manage',companyId]"
                                    class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>