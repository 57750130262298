import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService, UserGroupService } from '../../../services';
import { UserDetailModel, UserImageUploadModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { TimeZoneService } from 'src/services/timezone.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user.detail.component.html'
})
export class UserDetailComponent implements OnInit {
    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    roleMatrix: RoleMatrix;
    model: UserDetailModel = new UserDetailModel();
    imageUploadModel: UserImageUploadModel = new UserImageUploadModel();
    isModelLoaded = false;
    showActionColumn = false;
    userGroups: string[] = [];
    notificationPreferencesText = '';
    notificationPreferencesAsWatcherText = '';
    timeZoneFormattedName: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private userGroupService: UserGroupService,
        private userService: UserService,
        private timeZoneService: TimeZoneService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewAdmin
            && !this.roleMatrix.canViewUser
            && !this.roleMatrix.canViewPersonnel) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe(params => this.model.id = params['id']);
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.userService.getDetail(this.model.id).subscribe(
            (data: any) => {
                this.blockUI.stop();
                Object.assign(this.model, data);
                this.model.createdOn = this.appUtils.getFormattedDateTime(this.model.createdOn);
                this.model.normalizedRole = this.appUtils.getNormalizedRole(this.model.role);
                this.imageUploadModel.userId = this.model.id;
                this.imageUploadModel.fileUrl = this.model.profileImageUrl;
                this.isModelLoaded = true;

                if ((this.model.role === 'Admin' && this.roleMatrix.canEditAdmin)
                    || (this.model.role === 'User' && this.roleMatrix.canEditUser)) {
                    this.imageUploadModel.canEditProfileImage = true;
                }

                this.loadTimeZoneFormattedName();
                this.setNotificationText();
                this.updateConfigureAllowActions();
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });

        this.loadUsersGroups();
    }

    setNotificationText() {
        var tempArr = [];
        if (this.model.sendEmailNotification) {
            tempArr.push('Email');
        }
        if (this.model.sendInAppNotification) {
            tempArr.push('In App');
        }
        if (this.model.sendPushNotification) {
            tempArr.push('Push');
        }
        this.notificationPreferencesAsWatcherText = tempArr.join(',&nbsp;')

        tempArr = [];
        if (this.model.enableEmailNotification) {
            tempArr.push('Email');
        }
        if(this.model.enableInAppNotification){
            tempArr.push('In-App');
        }
        if(this.model.enablePushNotification) {
            tempArr.push('Push')
        }
        this.notificationPreferencesText = tempArr.join(',&nbsp;')
    }

    toggleStatus(): void {
        this.blockUI.start();
        this.userService.toggleStatus(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.model.status = this.model.status === 3 ? 2 : 3;
                this.toastr.success(`User has been ${(this.model.status === 2 ? 'activated' : 'deactivated')} successfully.`);
                if (this.model.status === 3) {
                    this.updateConfigureAllowActions();
                } else {
                    this.roleMatrix = new RoleMatrix();
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    delete(): void {
        if (!confirm('Are you sure you want to delete the selected user?')) {
            return;
        }
        this.blockUI.start();
        this.userService.delete(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.router.navigate(['/user/manage']);
                setTimeout(() => {
                    this.toastr.success('User has been deleted successfully.');
                }, 100);

            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    updateShowActionColumn() {
        if (((this.model.role === 'Admin'
            && (this.roleMatrix.canChangeStatusOfAdmin
                || this.roleMatrix.canEditAdmin
                || this.roleMatrix.canDeleteAdmin))
            || (this.model.role === 'Personnel' || this.model.role === 'User'
                && (this.roleMatrix.canChangeStatusOfUser
                    || this.roleMatrix.canEditUser
                    || this.roleMatrix.canDeleteUser
                    || this.roleMatrix.canChangeStatusOfPersonnel
                    || this.roleMatrix.canEditPersonnel
                    || this.roleMatrix.canDeletePersonnel)))) {
            this.showActionColumn = true;
        }
    }

    updateConfigureAllowActions() {
        if (this.model.status === 3) {
            this.roleMatrix.configureAllowActionsForUser();
            this.roleMatrix.configureAllowActionsForPersonel();
            this.imageUploadModel.canEditProfileImage = false;
        }
        this.updateShowActionColumn();
    }

    loadUsersGroups() {
        if (this.roleMatrix.is10FedUser) {
            this.blockUI.start();
            this.userGroupService.getUsersGroups(this.model.id).subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.userGroups = data;
                    if (this.model.status === 3) {
                        this.showActionColumn = false;
                        this.imageUploadModel.canEditProfileImage = false;
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
        }
    }

    private loadTimeZoneFormattedName() {
        this.timeZoneService.getTimeZoneFormattedName(this.model.timeZone)
            .pipe(first())
            .subscribe(
                name => {
                    this.timeZoneFormattedName = name;
                }
            );
    }
}
