import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkOrderService } from 'src/services';
import { AppUtils } from 'src/helpers';
import { WorkOrderDetailModel, NotificationsPreferences } from 'src/models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-set-notification-preference',
  templateUrl: './set-notification-preference.component.html'
})
export class SetNotificationPreferenceComponent implements OnInit {

  modalReference: any;

  notificationsPreferences: NotificationsPreferences;

  @Input('isCreator') isCreator: boolean;
  @Input('workOrder') workOrder: WorkOrderDetailModel;

  @ViewChild('modal', { static: true }) modal: any;

  @BlockUI('container-blockui-notifications-preferences') blockUI: NgBlockUI;

  constructor(private modalService: NgbModal,
    private toastr: ToastrService,
    private workOrderService: WorkOrderService,
    private appUtils: AppUtils) { }

  ngOnInit() {
    this.notificationsPreferences = new NotificationsPreferences();
  }

  openModal() {
    this.notificationsPreferences = {
      workOrderId: this.workOrder.id,
      enableReadNotification: this.workOrder.enableReadNotification
    };

    this.modalReference = this.modalService.open(this.modal,
        {
            backdrop: 'static',
            keyboard: false
        });
  }

  closeModal() {
    this.modalReference.close();
  }

  saveNotificationsPreferences() {
    this.blockUI.start();
    this.workOrderService.saveNotificationsPreferences(this.notificationsPreferences)
      .subscribe(
        () => {
          this.blockUI.stop();
          this.workOrder.enableReadNotification = this.notificationsPreferences.enableReadNotification;

          this.toastr.success('Notification preferences changed successfully');
          setTimeout(() => {
              this.closeModal();
          }, 100);
        }, error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
  }
}
