<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-company-edit></app-breadcrumb-company-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui'">
        <div class="row">
            <div class="col-md-10">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit Company
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && name.invalid}">
                                <label class="col-md-3">Name:*</label>
                                <div class="col-md-9">
                                    <input type="text" id="name" name="name" class="form-control" maxlength="50"
                                        minlength="5" autocomplete="off" [(ngModel)]="model.name" #name="ngModel"
                                        appAlphaNumericsLevelOne required autofocus />
                                    <div class="validation-error-message" *ngIf="f.submitted && name.invalid">
                                        <span *ngIf="name.errors.required">
                                            Company name is required
                                        </span>
                                        <span *ngIf="name.errors.minlength">
                                            Company name must be greater than 4 characters
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && address.invalid}">
                                <label class="col-md-3">Address:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="address" class="form-control" minlength="5" maxlength="200"
                                        autocomplete="off" [(ngModel)]="model.address" #address="ngModel"
                                        appAlphaNumericsLevelTwo required />
                                    <div class="validation-error-message" *ngIf="f.submitted && address.invalid">
                                        <span *ngIf="address.errors.required">
                                            Company address is required
                                        </span>
                                        <span *ngIf="address.errors.minlength">
                                            Company address must be greater than 4
                                            characters</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && zipCode.invalid}">
                                <label class="col-md-3">Zip Code:*</label>
                                <div class="col-md-6">
                                    <input type="text" name="zipCode" class="form-control" minlength="5" maxlength="10"
                                        autocomplete="off" [(ngModel)]="model.zipCode" #zipCode="ngModel"
                                        appAlphaNumericsOnly required />
                                    <div class="validation-error-message" *ngIf="f.submitted && zipCode.invalid">
                                        <span *ngIf="zipCode.errors.required">
                                            Zip code is required
                                        </span>
                                        <span *ngIf="zipCode.errors.minlength">
                                            Minimum 5 characters are required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && website.invalid}">
                                <label class="col-md-3">Website:</label>
                                <div class="col-md-9">
                                    <input type="text" name="website" class="form-control" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.website" #website="ngModel"
                                        [pattern]="appUtils.urlRegexPattern" appWebUrlOnly />
                                    <div class="validation-error-message" *ngIf="f.submitted && website.invalid">
                                        <span *ngIf="website.errors.pattern">
                                            Invalid website URL
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && contactNumber.invalid}">
                                <label class="col-md-3">Contact Number:*</label>
                                <div class="col-md-6">
                                    <input type="tel" name="contactNumber" class="form-control" maxlength="13"
                                        minlength="7" autocomplete="off" [(ngModel)]="model.contactNumber"
                                        #contactNumber="ngModel" appPhoneNumberOnly required />
                                    <div class="validation-error-message" *ngIf="f.submitted && contactNumber.invalid">
                                        <span *ngIf="contactNumber.errors.required">
                                            Contact number is required
                                        </span>
                                        <span *ngIf="contactNumber.errors.minlength">
                                            Contact number must be in between 7 to 13 characters
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && email.invalid}">
                                <label class="col-md-3">Email:*</label>
                                <div class="col-md-9">
                                    <input type="email" name="email" class="form-control" minlength="5" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.email" #email="ngModel" required />
                                    <div class="validation-error-message" *ngIf="f.submitted && email.invalid">
                                        <span *ngIf="email.errors.required">
                                            Email is required
                                        </span>
                                        <span *ngIf="email.errors.minlength">
                                            Email address must be greater than 4 characters
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/company/detail', model.id]"
                                    class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>