import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UnitService } from '../../../services';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-unit-challenge-delete',
    templateUrl: './unit.challenge.delete.component.html'
})

export class UnitChallengeDeleteComponent {
    @BlockUI('container-blockui-delete-popup') blockUI: NgBlockUI;
    @ViewChild('unitDeleteConfirmationModal', { static: true }) unitDeleteConfirmationModal: any;
    @Output() deleteUnit = new EventEmitter<any>();
    modalReference: NgbModalRef;
    deleteChallengeMessage: string;
    deleteConfirmationMessage: string;
    isModelLoaded = false;
    dualConfirmationRequired = true;

    constructor(private toastr: ToastrService,
        private modalService: NgbModal,
        private unitService: UnitService,
        private appUtils: AppUtils) { }

    challengeDelete(id: number, unitName: string): void {
        this.blockUI.start();
        this.unitService.getUnitStatisticsOfActiveRecords(id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.deleteConfirmationMessage = this.getDeleteConfirmationMessage(data, unitName);
                    this.isModelLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    getDeleteConfirmationMessage(data: any, unitName: string) {
        let htmlMessage = '';
        if (data.totalWorkOrders === 0) {
            this.deleteChallengeMessage = 'DELETE';
            this.dualConfirmationRequired = false;
            htmlMessage = 'Are you sure you want to delete the unit? This action is irreversible.';
        } else {
            let flag = false;
            htmlMessage = unitName + ' has ';
            if (data.totalWorkOrders > 0) {
                flag = true;
                htmlMessage += data.totalWorkOrders + ' active ' + (data.totalWorkOrders === 1 ? 'work order ' : 'work orders ');
            }
            if (htmlMessage.endsWith(',') || htmlMessage.endsWith(', ')) {
                htmlMessage = htmlMessage.trim().substr(0, htmlMessage.length - 1);
            }

            htmlMessage += 'associated. Deleting ' + unitName + ' will delete all the data associated with it. \n'
                + 'Are you sure you want to proceed?';
        }

        return htmlMessage;
    }

    openUnitDeleteConfirmationModal(id: number, unitName: string) {
        this.modalReference = this.modalService.open(this.unitDeleteConfirmationModal);
        this.challengeDelete(id, unitName);
    }

    delete() {
        this.closeUnitDeleteConfirmationModal();
        setTimeout(() => {
            this.deleteUnit.emit();
        });
    }

    closeUnitDeleteConfirmationModal() {
        this.modalReference.close();
        this.deleteChallengeMessage = null;
        this.deleteConfirmationMessage = null;
        this.isModelLoaded = false;
        this.dualConfirmationRequired = true;
    }

    deleteChallengeMessageChanged() {
        this.deleteChallengeMessage = this.deleteChallengeMessage.toUpperCase();
    }

}
