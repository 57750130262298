<ng-template #signageImageCropper let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-overflow">{{processingDimension.fileName}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeImageCropper(true)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-md-12">
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="false"
                [onlyScaleDown]="true"
                [roundCropper]="false"
                [alignImage]="'left'"
                [style.display]="showCropper ? null : 'none'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="closeImageCropper(false)">Upload</button>
            </div>
        </div>
    </div>
</ng-template>