import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[appMustNotMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: AppMustNotMatchDirective, multi: true }]
})
export class AppMustNotMatchDirective implements Validator {
    @Input('appMustNotMatch')
    appMustNotMatch: string[] = [];

    validate(formGroup: FormGroup): ValidationErrors {
        return MustNotMatch(this.appMustNotMatch[0], this.appMustNotMatch[1])(formGroup);
    }
}

export function MustNotMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        // return null if controls haven't initialised yet
        if (!control || !matchingControl) {
          return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustNotMatch) {
            return null;
        }

        // set error on matchingControl if validation fails
        if (control.value === matchingControl.value && !!control.value) {
            matchingControl.setErrors({ mustNotMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}
