import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { VendorAddModel, VendorEditModel, VendorNoteModel, VendorImageUploadModel, VendorDetailModel } from '../models';
import { AppSettings } from '../helpers';
import { Observable } from 'rxjs';

@Injectable()
export class VendorService {
    constructor(private http: HttpClient) { }

    add(model: VendorAddModel) {
        return this.http.post(environment.apiBaseUrl + 'vendor/add', model);
    }

    edit(model: VendorEditModel) {
        return this.http.post(environment.apiBaseUrl + 'vendor/edit', model);
    }

    deleteAttachment(fileKey: string) {
        return this.http.post(environment.apiBaseUrl + 'vendor/delete-attachment/' + fileKey, fileKey);
    }

    getDetail(id: number) {
        return this.http.get(environment.apiBaseUrl + 'vendor/get-detail/' + id);
    }

    getDetailForEdit(id: number) {
        return this.http.get(environment.apiBaseUrl + 'vendor/get-detail-for-edit/' + id);
    }

    getCountByCompany(companyId: number, considerOnlyPersonalData: boolean) {
        return this.http.get(`${environment.apiBaseUrl}vendor/get-count-by-company/${companyId}/${considerOnlyPersonalData}`);
    }

    getNotes(vendorId: number) {
        return this.http.get(environment.apiBaseUrl + 'vendornote/list/' + vendorId);
    }

    addNote(model: VendorNoteModel) {
        return this.http.post(environment.apiBaseUrl + 'vendornote/add', model);
    }

    editNote(model: VendorNoteModel) {
        return this.http.post(environment.apiBaseUrl + 'vendornote/edit', model);
    }

    deleteNote(noteId: number) {
        return this.http.post(environment.apiBaseUrl + 'vendornote/delete/' + noteId, null);
    }

    toggleStatus(id: number) {
        return this.http.post(environment.apiBaseUrl + 'vendor/toggle-status/' + id, null);
    }

    delete(id: number) {
        return this.http.post(environment.apiBaseUrl + 'vendor/delete/' + id, null);
    }

    getTypeSelectItems() {
        return this.http.get(environment.apiBaseUrl + 'vendor/get-type-select-list-items');
    }

    uploadFile(model: VendorImageUploadModel) {
        const formData: FormData = new FormData();
        if (model.vendorId) {
            formData.append('vendorId', model.vendorId.toString());
        }
        formData.append('file', model.file);
        formData.append('processingWidth', model.processingWidth.toString());
        formData.append('processingHeight', model.processingHeight.toString());
        formData.append('dimensionX1', model.dimensionX1.toString());
        formData.append('dimensionX2', model.dimensionX2.toString());
        formData.append('dimensionY1', model.dimensionY1.toString());
        formData.append('dimensionY2', model.dimensionY2.toString());

        return this.http.post(environment.apiBaseUrl + 'vendor/upload-file', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    deleteFile(vendorId: number) {
        return this.http.post(environment.apiBaseUrl + 'vendor/delete-file/' + vendorId, null);
    }

    getStatus(id: number) {
        return this.http.get(environment.apiBaseUrl + 'vendor/get-status/' + id);
    }

    getVendorsByFacilityId(id: number): Observable<VendorDetailModel[]> {
        return this.http.get<VendorDetailModel[]>(environment.apiBaseUrl + 'vendor/facility/' + id);
    }
}
