import { ConfigurationService } from '@10fed/services/configuration.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils, ConfigType, RoleMatrix } from 'src/helpers';
import { Configuration } from 'src/models/config/configuration.model';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  @HostBinding('class.full_width') full_width = true;

  @BlockUI('container-blockui-wo-config') blockUI: NgBlockUI;

  companyId: number;
  dayOfMonthsForJob1: number[];
  dayOfMonths: number[];
  times: string[] = [];

  jobRunDate1Config: Configuration;
  jobRunDate2Config: Configuration;

  userTimeZone: string;
  localDay1: string;
  localTime1: string;
  localDay2: string;
  localTime2: string;
  
  constructor(private configurationService: ConfigurationService,
              private appUtility: AppUtils,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private router: Router) {

    const roleMatrix = new RoleMatrix();
    if (!roleMatrix.canAccessWorkOrderConfiguration) {
        this.router.navigate(['/error/403']);
    }

    this.companyId = roleMatrix.is10FedUser
      ? this.route.snapshot.params["companyId"]
      : roleMatrix.companyId;
    this.jobRunDate1Config = new Configuration();
    this.jobRunDate2Config = new Configuration();
    this.dayOfMonthsForJob1 = Array.from(Array(27).keys()).map(x => x + 1);
    this.dayOfMonths = Array.from(Array(31).keys()).map(x => x + 1);

    //generate times array
    let startTime = 0;
    let ap = ['AM', 'PM'];
    for (let i=0;startTime<24*60; i++) {
      var hh = Math.floor(startTime/60);
      var mm = (startTime%60);
      this.times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)];
      startTime = startTime + 60;
    }
  }

  ngOnInit() {
    this.blockUI.start();
    this.configurationService.fetchConfigs(this.companyId).subscribe(
      configs => {
        this.jobRunDate1Config = configs.find(x => x.configType === ConfigType.CronOverLockWorkOrderRunDate1);
        if(this.jobRunDate1Config){
          let dateTime1Arr = this.jobRunDate1Config.value.split("|");
          this.jobRunDate1Config.dateValue = dateTime1Arr[0];
          this.jobRunDate1Config.timeValue = dateTime1Arr.length > 1 ? dateTime1Arr[1] : '00:00 AM';
        }
        this.onJobDate1Change();

        this.jobRunDate2Config = configs.find(x => x.configType === ConfigType.CronOverLockWorkOrderRunDate2);
        if(this.jobRunDate2Config){
          let dateTime2Arr = this.jobRunDate2Config.value.split("|");
          this.jobRunDate2Config.dateValue = dateTime2Arr[0];
          this.jobRunDate2Config.timeValue = dateTime2Arr.length > 1 ? dateTime2Arr[1] : '00:00 AM';
        }
        this.onJobDate2Change();
        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        this.appUtility.ProcessErrorResponse(this.toastr, error);
      });
    this.userTimeZone = this.appUtility.getUserTimeZone();
  }

  submit() {
    const configs: Configuration[] = [];

    this.jobRunDate1Config.value = this.jobRunDate1Config.dateValue + "|" + this.jobRunDate1Config.timeValue;
    this.jobRunDate2Config.value = this.jobRunDate2Config.dateValue + "|" + this.jobRunDate2Config.timeValue;  

    configs.push(this.jobRunDate1Config);
    configs.push(this.jobRunDate2Config);
    this.blockUI.start();
    this.configurationService.updateConfigs(this.companyId, configs).subscribe(
      response => {
        this.blockUI.stop();
        this.toastr.success("Configurations have been updated successfully.");
      }, error => {
        this.blockUI.stop();
        this.appUtility.ProcessErrorResponse(this.toastr, error);
      });
  }

  onJobDate1Change(){
    let date = new Date("01/" + this.jobRunDate1Config.dateValue + "/2000 UTC" + this.jobRunDate1Config.timeValue);
    let day = this.appUtility.getFormattedDay(date);
    this.localDay1 = this.ordinalSuffixOf(day);
    this.localTime1 = this.appUtility.getFormattedTime(date);
  }

  onJobDate2Change(){
    //appended month and year to the day and time value to parse them as the datetime for computing local user day and time
    let date = new Date("01/" + this.jobRunDate2Config.dateValue + "/2000 UTC" + this.jobRunDate2Config.timeValue);
    let day = this.appUtility.getFormattedDay(date);
    this.localDay2 = this.ordinalSuffixOf(day);
    this.localTime2 = this.appUtility.getFormattedTime(date);
  }
  
  ordinalSuffixOf(n:string) {
    let i = parseInt(n);
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
  }

}
