import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-kiosk-edit-tag',
    templateUrl: './kiosk.edit.tag.html'
})
export class KioskEditTagComponent {

    @BlockUI('container-blockui-edit-tag-popup') blockUI: NgBlockUI;
    @ViewChild('editTagModal', { static: true }) editTagModal: any;
    @Output() change = new EventEmitter<string>();
    modalReference: NgbModalRef;
    tag: string;

    constructor(private modalService: NgbModal) { }

    openModal(tag: string) {
        this.tag = tag;
        this.modalReference = this.modalService.open(this.editTagModal);
    }

    submit(f: NgForm) {
        if (f.form.invalid) {
            return;
        }
        this.close();
        setTimeout(() => {
            this.change.emit(this.tag);
        });
    }

    close() {
        this.modalReference.close();
    }
}
