import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from '../../../models';

@Component({
    selector: 'app-activity-log-company-user-activities',
    templateUrl: './activity.log.company.user.activities.component.html'
})

export class ActivityLogCompanyUserActivitiesComponent implements OnInit {
    @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

    ngOnInit() {
        const queryParams = '{"companyId" : "' + this.model.companyId + '", "userType":"any"}';
        this.model.entityLink = '/company-user/detail/' + this.model.entityId;
        this.model.entityParams = JSON.parse(queryParams);
    }
}
