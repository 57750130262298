import { Component, HostBinding, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { isNullOrUndefined } from  '../../../helpers/tools';
import { AppUtils, RoleMatrix, AppSettings } from '../../../helpers';
import { MoveInService, CloudStorageService } from 'src/services';
import { StateViewModel, MasterDataModel, FieldDetailModel, KeyboardModel, ConfigurationModel, DocumentFileModel, CallCenterPhoneNumberModel } from 'src/models';
import { UploadParams, BlobService, UploadConfig } from 'angular-azure-blob-service';
import { PdfViewerModalComponent } from 'src/components/partial';
import { Guid } from 'guid-typescript';


@Component({
    selector: 'app-movein-addon',
    templateUrl: './movein.addon.component.html'
})

export class MoveinAddonComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui-addon') blockUI: NgBlockUI;
    @ViewChild('pdfViewerModal', { static: true }) pdfViewerModal: PdfViewerModalComponent;

    fieldDetails: Array<FieldDetailModel> = new Array<FieldDetailModel>();
    configurationModel: ConfigurationModel = new ConfigurationModel();
    stateViewModel: StateViewModel = new StateViewModel();
    keyboardModel: KeyboardModel = new KeyboardModel();
    masterModel: MasterDataModel = new MasterDataModel();
    documentFileModel: DocumentFileModel = new DocumentFileModel();
    callCenterPhoneNumberModel: CallCenterPhoneNumberModel = new CallCenterPhoneNumberModel();
    documentUploadPercent = 0;
    isChangeDocumentFile = false;
    isDocumentUploading = false;

    companyId = -1;
    facilityId = -1;
    kioskId = -1;

    documentUploadRequest: any;
    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private moveInService: MoveInService,
        private cloudStorageService: CloudStorageService,
        private blobService: BlobService,
        private appSettings: AppSettings) {

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.kioskId = params['kioskId'];

            this.masterModel.companyId = this.companyId;
            this.masterModel.facilityId = this.facilityId;
            this.masterModel.kioskId = this.kioskId;
        });

        this.roleMatrix = new RoleMatrix();

    }

    ngOnInit() {
        this.loadCongiuration();
        this.loadMandatoryFields();
    }

    loadCongiuration() {
        this.moveInService.getConfiguration(this.masterModel)
            .subscribe(
                (data: any) => {
                    Object.assign(this.configurationModel, data);
                    this.stateViewModel.id = this.configurationModel.id;
                    this.keyboardModel.id = this.configurationModel.id;
                    this.documentFileModel.id = this.configurationModel.id;
                    this.stateViewModel.stateView = this.configurationModel.stateView;
                    this.keyboardModel.keyboardPreference = this.configurationModel.keyboardPreference;

                    this.callCenterPhoneNumberModel.id = this.configurationModel.id;
                    this.callCenterPhoneNumberModel.phoneNumber = this.configurationModel.callCenterPhoneNumber;

                    if (isNullOrUndefined(this.configurationModel.documentFileName)) {
                        this.isChangeDocumentFile = true;
                    }
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadMandatoryFields() {
        this.blockUI.start();
        this.moveInService.getMandatoryFields(this.masterModel)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.fieldDetails, data);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    changeCallCenterPhoneNumber() {
        this.moveInService.saveCallCenterPhoneNumber(this.masterModel, this.callCenterPhoneNumberModel)
            .subscribe(
                (data: any) => {
                    this.loadCongiuration();
                    this.toastr.success('Call center phone number has been changed successfully.');
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    changeStateView(state: number) {
        this.moveInService.changeStateView(this.stateViewModel.id, state)
            .subscribe(
                (data: any) => {
                    this.loadCongiuration();
                    this.toastr.success('State view has been changed successfully.');
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    changeKeyboardPreference(keyboard: number) {
        this.moveInService.changeKeyboard(this.keyboardModel.id, keyboard)
            .subscribe(
                (data: any) => {
                    this.loadCongiuration();
                    this.toastr.success('Keyboard has been changed successfully.');
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    changeMandatoryField(fieldDetail: FieldDetailModel) {
        this.moveInService.changeMandatoryFieldStatus(fieldDetail)
            .subscribe(
                (data: any) => {
                    this.loadMandatoryFields();
                    this.toastr.success('Field status has been changed successfully.');
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onDoculmentSelected(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }
        this.isDocumentUploading = true;
        const file = event.target.files.item(0);
        this.blockUI.start();
        this.cloudStorageService.getSasToken()
            .subscribe(
                async (data) => {
                    const CloudConfig: UploadParams = {
                        sas: data.toString(),
                        storageAccount: this.appSettings.AzureAccount,
                        containerName: 'move-in'
                    };
                    const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
                    const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
                    AppSettings.NonBearerUrl = blobUrl;
                    this.config = {
                        baseUrl: `${blobUrl}?`,
                        sasToken: CloudConfig.sas,
                        blockSize: 1024 * 64,
                        file: file,
                        complete: () => {
                            this.documentFileModel.fileName = fileName;
                            this.changeDocumentFileDetail();
                            this.isChangeDocumentFile = false;
                            this.isDocumentUploading = false;
                        },
                        error: (err) => {
                            console.log(err);
                            this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                            this.isDocumentUploading = false;
                        },
                        progress: (percent) => {
                            this.documentUploadPercent = percent;
                        }
                    };
                    // start upload
                    setTimeout(() => {
                        this.blockUI.stop();
                        this.documentUploadRequest = this.blobService.upload(this.config);
                    });
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    changeDocumentFile() {
        this.documentUploadPercent = 0;
        this.isChangeDocumentFile = true;
    }

    resetDocumentFile() {
        if (this.documentUploadRequest) {
            this.documentUploadRequest.cancel();
        }
        this.documentUploadPercent = 0;
        this.isChangeDocumentFile = false;
        this.isDocumentUploading = false;
    }

    openPdfViewer() {
        this.pdfViewerModal.openPdfViewerModal('Document File', this.configurationModel.documentFileName);
    }

    changeDocumentFileDetail() {
        if (!isNullOrUndefined(this.documentFileModel.fileName)) {
            this.moveInService.changeDocumentFile(this.documentFileModel)
                .subscribe(
                    (data: any) => {
                        this.configurationModel.documentFileName = data.fileUrl;
                        this.toastr.success('Document file uploaded successfully');
                    },
                    error => {
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        }
    }

}
