import { VendorInsurancePolicyDocumentModel } from "..";

export class VendorAddModel {
    public companyId: number;
    public name: string;
    public vendorTypeId: string;
    public phone: string;
    public email: string;
    public address: string;
    public bankAccount: string;
    public profileImage: string;
    public assignedFacilities: Array<number>;
    public w9Number: string;
    public insurancePolicyName: string;
    public insurancePolicyNumber: string;
    public insurancePolicyExpiryDate: string;
    public insurancePolicyDocuments: Array<VendorInsurancePolicyDocumentModel>;
    constructor() {
        this.vendorTypeId = '';
        this.assignedFacilities = new Array<number>();
        this.insurancePolicyDocuments = new Array<VendorInsurancePolicyDocumentModel>();
    }
}
