import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { WorkOrderService } from 'src/services';
import { SelectListItemModel } from 'src/models';
import { AppUtils } from 'src/helpers';

@Component({
    selector: 'app-comp-wo-opt-global-category',
    templateUrl: './company.wo.opt.global.category.component.html'
})
export class CompanyWoOptGlobalCategoryComponent implements OnInit {

    companyId: number;
    alreadySelectedCategoriesIds: number[];

    globalCategories: SelectListItemModel[];

    availableCategories: SelectListItemModel[];
    selectedCategories: SelectListItemModel[];

    checkedAvailableCategories: number[];
    checkedSelectedCategories: number[];

    @BlockUI('container-blockui-opt-global-category') blockUI: NgBlockUI;

    constructor(private route: ActivatedRoute,
                private workOrderService: WorkOrderService,
                private toastr: ToastrService,
                private appUtils: AppUtils) {
        this.globalCategories = [];
        this.availableCategories = [];
        this.selectedCategories = [];
        this.checkedAvailableCategories = [];
        this.checkedSelectedCategories = [];
        this.companyId = this.route.snapshot.params['companyId'];
    }

    ngOnInit() {
        this.loadGlobalCategories();
        this.loadSelectedGlobalCategories();
    }

    loadGlobalCategories() {
        this.blockUI.start();
        this.workOrderService.getGlobalCategorySelectListItems(true, true)
            .subscribe(data => {
                this.blockUI.stop();
                this.globalCategories = data as SelectListItemModel[];
                this.setAvailableCategories();
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    loadSelectedGlobalCategories() {
        this.blockUI.start();
        this.workOrderService.getGlobalCategorySelectListItems(false, true, this.companyId)
            .subscribe(data => {
                this.blockUI.stop();
                this.selectedCategories = data as SelectListItemModel[];
                this.alreadySelectedCategoriesIds = this.selectedCategories.map(x => +x.id);
                this.setAvailableCategories();
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    checkAvailableCategory(id: number) {
        const index = this.checkedAvailableCategories.indexOf(id);
        if (index >= 0) {
            this.checkedAvailableCategories.splice(index, 1);
        } else {
            this.checkedAvailableCategories.push(id);
        }
    }

    checkSelectedCategory(id: number) {
        const index = this.checkedSelectedCategories.indexOf(id);
        if (index >= 0) {
            this.checkedSelectedCategories.splice(index, 1);
        } else {
            this.checkedSelectedCategories.push(id);
        }
    }

    selectAvailableCategories(selectAll: boolean) {
        if (selectAll) {
            this.checkedAvailableCategories = [];
            this.checkedSelectedCategories = [];
            this.availableCategories = [];
            this.selectedCategories = [...this.globalCategories];
            return;
        }

        this.selectedCategories.push(...this.globalCategories.filter(x =>
            this.checkedAvailableCategories.includes(x.id as any as number)));
        this.appUtils.sort(this.selectedCategories, 'name');

        this.availableCategories = this.availableCategories.filter(x =>
            !this.checkedAvailableCategories.includes(x.id as any as number));
        this.checkedAvailableCategories = [];
    }

    deselectSelectedCategories(selectAll: boolean) {
        if (selectAll) {
            this.checkedAvailableCategories = [];
            this.checkedSelectedCategories = [];
            this.availableCategories = [...this.globalCategories];
            this.selectedCategories = [];
            return;
        }

        console.log(this.globalCategories);
        console.log(this.availableCategories);
        console.log(this.selectedCategories);
        console.log(this.checkedAvailableCategories);
        console.log(this.checkedSelectedCategories);
        console.log(this.globalCategories.filter(x => this.checkedSelectedCategories.includes(x.id as any as number)));

        this.availableCategories.push(...this.globalCategories.filter(x =>
            this.checkedSelectedCategories.includes(x.id as any as number)));
        this.availableCategories.sort((item1, item2) => {
            const text1 = item1.name.toUpperCase();
            const text2 = item2.name.toUpperCase();
            return (text1 < text2)
                ? -1
                : (text1 > text2) ? 1 : 0;
        });

        this.selectedCategories = this.selectedCategories.filter(x =>
            !this.checkedSelectedCategories.includes(x.id as any as number));

        this.checkedSelectedCategories = [];
    }

    saveSelectedGlobalCategories() {
        if (this.alreadySelectedCategoriesIds.length === this.selectedCategories.length &&
            this.selectedCategories.filter(x => this.alreadySelectedCategoriesIds.includes(+x.id)).length
                === this.selectedCategories.length) {
            this.toastr.success(this.selectedCategories.length === 0
                ?'Please select categories'
                : 'Categories already added');
            return;
        }

        this.blockUI.start();
        this.workOrderService.saveSelectedGlobalCategories(this.companyId, this.selectedCategories.map(x => +x.id))
            .subscribe(data => {
                this.blockUI.stop();
                this.alreadySelectedCategoriesIds = this.selectedCategories.map(x => +x.id);
                this.toastr.success('Categories have been imported successfully.');
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    private setAvailableCategories() {
        if (this.globalCategories.length === 0) {
            return;
        }

        this.availableCategories = [...this.globalCategories.filter(x => !this.selectedCategories.find(c => c.id === x.id))];
    }

}
