import { ActivityLogActionTypeEnum } from 'src/helpers';
import { ActivityDetailModel, ActivityInfoModel } from './activity.detail.model';

export class ActivityLogModel {
    public createdOn: string;
    public createdByName: string;
    public subTaskId?: number;
    public activityType: ActivityLogActionTypeEnum;
    public activityDetails: Array<ActivityDetailModel>;
    public activityInfos: ActivityInfoModel[];

    constructor() {
        this.activityDetails = new Array<ActivityDetailModel>();
        this.activityInfos = [];
    }
}
