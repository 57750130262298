import { Component, ViewChild } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-video-viewer-modal',
    templateUrl: './video.viewer.modal.component.html'
})

export class VideoViewerModalComponent {
    @ViewChild('videoViewerModal', { static: true }) videoViewerModal: any;

    modalReferece: NgbModalRef;
    title: string;
    videoUrl: string;

    constructor(private modalService: NgbModal) { }

    openVideoViewerModal(title: string, videoUrl: string) {
        this.title = title;
        this.videoUrl = videoUrl;
        this.modalReferece = this.modalService
            .open(this.videoViewerModal,
                {
                    size: 'lg'
                });
    }

    closeVideoViewerModal() {
        this.modalReferece.close();
    }
}

