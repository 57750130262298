import { QuickBooksAccountTypesEnum } from "src/helpers";


export class QuickBooksAccountModel {
    public internalCompanyId: number;
    public facilityId:string;
    public accountId:string;
    public accountType:QuickBooksAccountTypesEnum    
    
    constructor() {

    }
}