import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { MasterService } from 'src/services';
import { DashboardCompanyFacilityTileComponent } from './facility-tile/dashboard.compnay.facility.tile.component';
import { DashboardCompanyVendorTileComponent } from './vendor-tile/dashboard.company.vendor.tile.component';

@Component({
    selector: 'app-dashboard-company-tiles',
    templateUrl: './dashboard.company.tiles.component.html'
})

export class DashboardCompanyTilesComponent implements AfterViewInit, OnDestroy {
    @Input() companyId: number;
    @BlockUI('container-blockui-tiles-company') blockUI: NgBlockUI;

    @ViewChild('vendorTile') VendorTile: DashboardCompanyVendorTileComponent;
    @ViewChild('facilityTile') FacilityTile: DashboardCompanyFacilityTileComponent;

    isAdminTileLoaded = false;
    isPersonalTileLoaded = false;
    isFacitlityTileLoaded = false;
    isVendorTileLoaded = false;

    subscriptions: Subscription[] = [];

    constructor(private masterService: MasterService) { }

    ngAfterViewInit() {
        const subscription = this.masterService.DashboardShowPersonnalData$.subscribe(showPersonalData => {
            this.blockUI.start();

            this.isFacitlityTileLoaded = false;
            this.FacilityTile.loadFacilitiesCount(showPersonalData);

            this.isVendorTileLoaded = false;
            this.VendorTile.loadVendorCounts(showPersonalData);

        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    adminTileLoaded() {
        this.isAdminTileLoaded = true;
        this.stopLoader();
    }

    personalTileLoaded() {
        this.isPersonalTileLoaded = true;
        this.stopLoader();
    }

    facitlityTileLoaded() {
        this.isFacitlityTileLoaded = true;
        this.stopLoader();
    }

    vendorTileLoaded() {
        this.isVendorTileLoaded = true;
        this.stopLoader();
    }

    stopLoader() {
        if (this.isAdminTileLoaded
            && this.isPersonalTileLoaded
            && this.isFacitlityTileLoaded
            && this.isVendorTileLoaded) {
            this.blockUI.stop();
        }
    }
}

