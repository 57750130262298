import { KpiReportService } from '@10fed/services/kpi-report.service';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { DavinciLockConfigurationModel } from '../../../models';


@Component({
    selector: 'app-davincilock-configuration',
    templateUrl: './davincilock.configuration.component.html'
})

export class DavinciLockConfigurationComponent implements OnInit {
    @BlockUI('container-blockui-configuration') blockUI: NgBlockUI;
    model:DavinciLockConfigurationModel= new DavinciLockConfigurationModel();
    
    showActionColumn = true;


    constructor(private toastr: ToastrService,
        private kpiReportService: KpiReportService,
        private route: ActivatedRoute,
        private appUtils: AppUtils) { 

            this.route.params.subscribe((params) => {
                this.model.internalCompanyId = params['companyId'];
            });    
        }

    isHiddenDavinci:boolean=true;

    
    ngOnInit() {
        this.loadDavinciLockCompany();
    }

    loadDavinciLockCompany()
    {
        this.blockUI.start();
        this.kpiReportService.getDavinciLockCompany(this.model.internalCompanyId)
            .subscribe(
                (data: DavinciLockConfigurationModel) => {
                    this.blockUI.stop();
                    if (!data) {
                        return;
                    }
                    this.model.clientId = data.clientId;
                    this.model.secretKey = data.secretKey;
                    this.model.davinciLockCompanyConfigured = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        this.blockUI.start();
        this.kpiReportService.configureCompany(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.model.davinciLockCompanyConfigured = true;
                    
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}