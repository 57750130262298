<div class="kt-portlet__body" *blockUI="'container-blockui-opt-global-category'">
    <div class="row">
        <div class="col-4 offset-1 custom-list-box">
            <div class="custom-list-box-heading">
                Available Categories
            </div>
            <ul class="custom-list-box-body" style="height: 400px;">
                <li *ngFor="let category of availableCategories"
                    class="cursor-pointer"
                    [ngClass]="{'selected': checkedAvailableCategories.includes(category.id)}"
                    (click)="checkAvailableCategory(category.id)">
                    {{category.name}}
                </li>
            </ul>
        </div>
        <div class="col-2 d-flex flex-col justify-content-md-center">
            <input type="button"
                class="btn btn-label-primary btn-block"
                value="Add All"
                [disabled]="availableCategories.length === 0"
                (click)="selectAvailableCategories(true)" />
            <input type="button"
                class="btn btn-label-primary btn-block"
                value="Add"
                [disabled]="checkedAvailableCategories.length === 0"
                (click)="selectAvailableCategories(false)" />
            <input type="button"
                class="btn btn-label-danger btn-block"
                value="Remove"
                [disabled]="checkedSelectedCategories.length === 0"
                (click)="deselectSelectedCategories(false)" />
            <input type="button"
                class="btn btn-label-danger btn-block"
                value="Remove All"
                [disabled]="selectedCategories.length === 0"
                (click)="deselectSelectedCategories(true)" />
        </div>
        <div class="col-4 custom-list-box">
            <div class="custom-list-box-heading">
                Selected Categories
            </div>
            <ul class="custom-list-box-body" style="height: 400px;">
                <li *ngFor="let category of selectedCategories"
                    class="cursor-pointer"
                    [ngClass]="{'selected': checkedSelectedCategories.includes(category.id) }"
                    (click)="checkSelectedCategory(category.id)">
                    {{category.name}}
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="kt-portlet__body separator">
    <div class="row flex-r">
        <input type="button" class="btn btn-primary" value="Save Selected Categories" (click)="saveSelectedGlobalCategories()" />
    </div>
</div>
