import { Component, OnInit, Input } from '@angular/core';
import { KioskService } from '../../../../services';

@Component({
    selector: 'app-company-kiosk-tile',
    templateUrl: './company.kiosk.tile.component.html'
})

export class CompanyKioskTileComponent implements OnInit {
    @Input() companyId: number;

    isModelLoaded = false;
    totalKiosks = 0;

    constructor(private kioskService: KioskService) { }

    ngOnInit(): void {
        this.kioskService.getCountByCompany(this.companyId)
            .subscribe(
                (data: number) => {
                    this.isModelLoaded = true;
                    this.totalKiosks = data;
                },
                error => {
                    console.log('Kiosk count failed');
                    console.log(error);
                });
    }
}
