<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
*blockUI="'container-blockui-manage-user-group'"
>
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
    <div class="kt-subheader__main">
      <app-breadcrumb-user-group-manage [companyId]="companyId">
      </app-breadcrumb-user-group-manage>
    </div>
    <div class="kt-subheader__toolbar">
      <div class="kt-subheader__wrapper">
      </div>
    </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid">
    <div class="col-md-12 p-0">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              User Groups
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar" *ngIf="roleMatrix.canAddUserGroup">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions">
                <a [routerLink]="['/company', companyId, 'user-group', 'add']" class="btn btn-brand btn-elevate btn-icon-sm">
                  <em class="la la-plus"></em>
                  New User Group
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="row">
              <div class="col-md-12">
                <table datatable [dtOptions]="dtOptions" data-page="2" class="table table-hover break-all">
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-user-group-challenge-delete #userGroupChallengeDeleteModal (delete)="delete($event)">
</app-user-group-challenge-delete>

<app-user-group-challenge-deactivate #userGroupChallengeDeactivateModal (deactivate)="initiateToggleStatus($event)">
</app-user-group-challenge-deactivate>