export class QuickBooksConfigurationModel {

    public realmId:string
    public internalCompanyId: number;
    public quickBooksCompanyConfigured: boolean;
    public facilityId:string;
    public facilityName:string;
    public refreshToken:string;

    constructor() {
        this.quickBooksCompanyConfigured = false;
        this.facilityId = '';
    }
}