
    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui-preview'">
        <div class="row">
            
            <div class="col-md-12">
                <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                    <thead>
                        <tr>
                            <th style="width: 10%;">
                                &nbsp;
                            </th>
                            <th style="width: 55%;">
                               
                                <div class="float-left m-t-4">
                                    Accounts
                                </div>
                            </th>
                          
                            <th style="width: 20%;">
                               Select Sub Account
                            </th>
                        </tr>
                    </thead>
                </table>
                <ngb-accordion >
                    <ngb-panel>
                        <ng-template ngbPanelHeader>
                            <form name="form" (ngSubmit)="submit()" #f="ngForm" novalidate>
                                <table class="table table-hover break-all dataTable no-footer m-t-0 m-b-0 p-t-0 p-b-0">
                                    <tbody>
                                        <tr>
                                            <td style="width: 55%;">
                                                <div class="float-left m-t-4">
                                                    <label><input type="hidden" name="revenue" >Chart of Account Code total for Total Revenue</label>
                                                </div>
                                            </td>
                                            <td style="width: 20%;">
                                                <div class="col-md-12">
                                                    <select class="form-control" name="selectedRevenue" [(ngModel)]="selectedRevenue" >
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of accountSelectList" [value]="item.id">
                                                            
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>



                                        <tr>
                                          
                                            <td style="width: 55%;">
                                            <div class="float-left m-t-4">
                                                <label><input type="hidden" name="repairAndMaintenanceCost"  >Chart of Account Code total for Total Repairs and Maintenance costs</label>
                                                    
                                                </div>
                                            </td>
                                            <td style="width: 20%;">
                                                <div class="col-md-12">
                                                    <select class="form-control"  name="selectedRepair" [(ngModel)]="selectedRepair" >
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of accountSelectList"  [value]="item.id">
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>


                                        <tr>
                                         <td style="width: 55%;">

                                                <div class="float-left m-t-4">
                                                    <label><input type="hidden"  name="googleAdCost">Chart of Account Code total for Google Advertising Costs</label>
                                                    
                                                </div>
                                            </td>
                                            <td style="width: 20%;">
                                               
                                                <div class="col-md-12">
                                                    <select class="form-control"  name="selectedGoogle" [(ngModel)]="selectedGoogle" >
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of accountSelectList"  [value]="item.id">
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                          
                                            <td style="width: 55%;">
                                                <div class="float-left m-t-4">
                                                    <label><input type="hidden" name="sparefootAdCost" > Chart of Account Code total for Sparefoot Advertising Costs</label>
                                                    
                                                </div>
                                            </td>
                                            
                                            <td style="width: 20%;">
                                              <div class="col-md-12">
                                                    <select class="form-control"  name="selectedSpare" [(ngModel)]="selectedSpare">
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of accountSelectList"  [value]="item.id">
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            
                                            <td style="width: 55%;">
                                               
                                                <div class="float-left m-t-4">
                                                    <label><input type="hidden" name="totalMarketing" >Chart of Account Code total for Total Marketing Costs</label>
                                                    
                                                </div>
                                            </td>
                                           
                                            <td style="width: 20%;">
                                                
                                                <div class="col-md-12">
                                                    <select class="form-control"  name="selectedMarketing" [(ngModel)]="selectedMarketing">
                                                        <option value="">[Select]</option>
                                                        <option *ngFor="let item of accountSelectList"  [value]="item.id">
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                        <div class="kt-portlet__foot" style="margin-left: 50%;">
                                            <div class="kt-form__actions text-center">
                                                <button type="submit" class="btn btn-primary">
                                                    <span class="kt-menu__link-icon">
                                                    
                                                    </span>Save
                                                </button>
                                
                                                <button type="reset" class="btn btn-default btn-elevate btn-icon-sm m-l-5">
                                                        <span class="kt-menu__link-icon">
                                                        
                                                    </span>Cancel
                                                </button>    
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                              </form>  
                        </ng-template>
                   </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
</div>


 