import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-work-order-note',
    templateUrl: './breadcrumb.work.order.note.component.html'
})

export class BreadcrumbWorkOrderNoteComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;
    @Input() workOrderId: number;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
