<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
    <div class="kt-subheader__main">
      <app-breadcrumb-user-group-edit [companyId]="companyId">
      </app-breadcrumb-user-group-edit>
    </div>
    <div class="kt-subheader__toolbar">
      <div class="kt-subheader__wrapper">
      </div>
    </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid">
    <div class="col-md-12">
      <div class="kt-portlet kt-portlet--mobile">

        <div class="kt-portlet__head kt-portlet__head--lg">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              Edit User Group
            </h3>
          </div>
        </div>

        <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
          <div class="kt-portlet__body" *blockUI="'container-blockui-add-user'">
            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && userGroupName.invalid}">
              <label class="col-md-3">Group Name:*</label>
              <div class="col-md-6">
                <input type="text" name="userGroupName" class="form-control" minlength="2" maxlength="30"
                  autocomplete="off" [(ngModel)]="model.name" #userGroupName="ngModel" required
                  appAlphabatesWithNumberAndSpaceOnly />
                <div class=" validation-error-message" *ngIf="f.submitted && userGroupName.invalid">
                  <span *ngIf="userGroupName.errors.required">User Group name is required</span>
                  <span *ngIf="userGroupName.errors.minlength">User Group name should be minimum 2 characters</span>
                  <span *ngIf="userGroupName.errors.maxlength">Maximum 30 characters are allowed</span>
                </div>
              </div>
            </div>
            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && selectedClients.length === 0}">
              <label class="col-md-3">Member Assigned:</label>
              <div class="col-md-6">
                <ng-multiselect-dropdown name="userGroupMemebrs" [placeholder]="'[Select]'" 
                  [(ngModel)]="selectedClients" [settings]="multiSelectDropdownConfigs"
                  [data]="clientUsers">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <span class="kt-font-bold">Add Work Orders Settings</span>
                  <div class="kt-divider m-t-5 m-b-25">
                      <span></span>
                  </div>
              </div>
            </div>
            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && lowPriorityWorkOrdersReminderInterval.invalid}">
              <label class="col-md-3">Reminder Interval for low priority work orders (days):*</label>
              <div class="col-md-6">
                <select class="form-control" name="lowPriorityWorkOrdersReminderInterval"
                    [(ngModel)]="model.lowPriorityWorkOrdersReminderInterval" #lowPriorityWorkOrdersReminderInterval="ngModel" required>
                    <option value="">[Select]</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                </select>
                <div class="validation-error-message" *ngIf="f.submitted && lowPriorityWorkOrdersReminderInterval.invalid">
                  Reminder Interval for low priority work orders is required
                </div>
              </div>
            </div>
            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && mediumPriorityWorkOrdersReminderInterval.invalid}">
              <label class="col-md-3">Reminder Interval for medium priority work orders (days):*</label>
              <div class="col-md-6">
                <select class="form-control" name="mediumPriorityWorkOrdersReminderInterval"
                    [(ngModel)]="model.mediumPriorityWorkOrdersReminderInterval" #mediumPriorityWorkOrdersReminderInterval="ngModel" required>
                    <option value="">[Select]</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                </select>
                <div class="validation-error-message" *ngIf="f.submitted && mediumPriorityWorkOrdersReminderInterval.invalid">
                  Reminder Interval for medium priority work orders is required
                </div>
              </div>
            </div>
            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && highPriorityWorkOrdersReminderInterval.invalid}">
              <label class="col-md-3">Reminder Interval for high priority work orders (days):*</label>
              <div class="col-md-6">
                <select class="form-control" name="highPriorityWorkOrdersReminderInterval"
                    [(ngModel)]="model.highPriorityWorkOrdersReminderInterval" #highPriorityWorkOrdersReminderInterval="ngModel" required>
                    <option value="">[Select]</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                </select>
                <div class="validation-error-message" *ngIf="f.submitted && highPriorityWorkOrdersReminderInterval.invalid">
                  Reminder Interval for high priority work orders is required
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__foot">
            <div class="kt-form__actions text-right">
              <a [routerLink]="['/company', companyId, 'user-group']" class="btn btn-secondary m-r-5">Cancel</a>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-user-group-challenge-delete-user #userDeleteChallengeModal (delete)="sendModelForUpdation()">
</app-user-group-challenge-delete-user>