<div *blockUI="'container-blockui-skinning'">

    <div class="col-md-12">
        <div
            class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
            <div class="kt-ribbon__target m-t-15 l--20">
                <span class="kt-ribbon__inner"></span>Logo
            </div>
            <div class="kt-portlet__head-label">
            </div>
        </div>
        <div class="kt-portlet__body kt-portlet__body--fit-top">
            <div class="kt-content kt-grid__item kt-grid__item--fluid p-0 row">
                <div class="form-group col-md-8">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <!-- No logo selected -->
                            <button *ngIf="!skinningLogoModel.skinningLogoFileUrl" type="button"
                                class="btn btn-sm btn-outline-brand m-b-5" [disabled]="isDocumentUploading"
                                (click)="documentFileName.click()">
                                Choose file
                            </button>

                            <!-- Logo selected Start -->
                            <button *ngIf="skinningLogoModel.skinningLogoFileUrl" type="button"
                                class="btn btn-sm btn-outline-brand  m-b-5" [disabled]="isDocumentUploading"
                                (click)="documentFileName.click()">
                                Change
                            </button>
                            <button *ngIf="skinningLogoModel.skinningLogoFileUrl" type="button"
                                class="btn btn-sm btn-outline-danger m-b-5 m-l-15" (click)="deleteAttachment()">
                                Remove
                            </button>
                            <!-- Logo selected End  -->

                            <ngb-progressbar class="m-t-5 m-b-5" type="info" [value]="documentUploadPercent"
                                height="5px" *ngIf="documentUploadPercent > 0 && documentUploadPercent < 100">
                            </ngb-progressbar>
                            <input type="file" name="documentFileName" class="d-none" accept="image/*" #documentFileName
                                (change)="onAttachmentSelected($event)" />
                        </div>
                        <div class="form-group col-md-12">
                            <p>
                                The logo that you upload will be visible on the kiosk machine for the Move In and
                                Signage
                                Application
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4" *ngIf="skinningLogoModel.skinningLogoFileUrl">
                    <img [src]="skinningLogoModel.skinningLogoFileUrl" class="profile-image" />
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div
            class="kt-portlet__head kt-portlet__head--right kt-portlet__head--noborder  kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--info">
            <div class="kt-ribbon__target m-t-15 l--20">
                <span class="kt-ribbon__inner"></span>Primary color
            </div>
            <div class="kt-portlet__head-label">
            </div>
        </div>
        <div class="kt-portlet__body kt-portlet__body--fit-top">
            <div class="row">
                <div class="kt-content kt-grid__item kt-grid__item--fluid col-md-12 p-0">
                    <div class="form-group col-md-12">
                        <button type="button" class="btn btn-sm btn-outline-brand m-b-5" [(colorPicker)]="color"
                            [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-default btn-xs btn-outline-brand'"
                            [cpCancelButton]="cpDisableInput" [cpSaveClickOutside]="false" [cpOKButton]="true"
                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                            (colorPickerCancel)="colorPickerCancel($event);" [cpOKButtonText]="'Submit'"
                            [cpCancelButtonText]="'Cancel'" (colorPickerSelect)="saveColorModel($event);"
                            [cpPosition]="'top-right'" [cpPositionOffset]="'50%'" [cpAlphaChannel]="'disabled'"
                            cpOutputFormat="hex">
                            Choose color
                        </button>

                        <button *ngIf="skinningColorModel.skinningPrimaryColor" type="button"
                            class="btn btn-sm btn-outline-danger m-b-5 m-l-15" (click)="deletePrimaryColor()">
                            Remove color
                        </button>
                    </div>
                    <div class="form-group col-md-12">
                        <p>
                            The color code that you select will be the primary color of the elements in the kiosk
                            machine for the Move In and Signage Application
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>