<div class="kt-grid kt-grid--ver kt-grid--root">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

            <app-left-banner [model]="leftBannerModel"></app-left-banner>

            <div
                class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <div class="kt-login__body" *blockUI="'container-blockui'">
                    <div class="kt-login__form">
                        <div class="kt-login__title">
                            <h3>Reset Password</h3>
                        </div>
                        <form class="kt-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
                            <div class="form-group"
                                [ngClass]="{ 'validation-error' : f.submitted && !newPassword.valid}">
                                <input type="password" name="newPassword" class="form-control" minlength="8"
                                    maxlength="20" autocomplete="off" placeholder="New Password*"
                                    [(ngModel)]="model.newPassword" #newPassword="ngModel" appAnythingButWhiteSpace
                                    required />
                                <div class="validation-error-message" *ngIf="f.submitted && newPassword.invalid">
                                    <span *ngIf="newPassword.errors.required">
                                        Please enter new password
                                    </span>
                                    <span *ngIf="newPassword.errors.minlength">
                                        Minimum 8 characters are required
                                    </span>
                                    <span *ngIf="newPassword.errors.maxlength">
                                        Maximum 20 characters are allowed
                                    </span>
                                </div>
                            </div>
                            <div class="form-group"
                                [ngClass]="{ 'validation-error' : f.submitted && !confirmPassword.valid}">
                                <input type="password" name="confirmPassword" class="form-control" minlength="8"
                                    maxlength="20" autocomplete="off" placeholder="Confirm Password*"
                                    [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
                                    appAnythingButWhiteSpace required />
                                <div class="validation-error-message" *ngIf="f.submitted && confirmPassword.invalid">
                                    <span *ngIf="confirmPassword.errors.required">
                                        Please enter password again
                                    </span>
                                    <span *ngIf="confirmPassword.errors.minlength">
                                        Minimum 8 characters are required
                                    </span>
                                    <span *ngIf="confirmPassword.errors.maxlength">
                                        Maximum 20 characters are allowed
                                    </span>
                                </div>
                                <div class="validation-error-message"
                                    *ngIf="f.submitted && confirmPassword.valid && newPassword.valid  && model.newPassword !==  model.confirmPassword">
                                    <span>
                                        New password and confirm password does not match
                                    </span>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <a [routerLink]="['/account/login']" class="kt-link kt-login__link-forgot d-none">
                                    Back to Login
                                </a>
                                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
                                    Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>