import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { VendorNoteModel } from '../../../models';
import { VendorService } from '../../../services';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-vendor-note-add',
    templateUrl: './vendor.note.add.component.html'
})

export class VendorNoteAddComponent {
    @BlockUI('container-blockui-note') noteBlockUI: NgBlockUI;
    @Input() vendorId: number;
    @Output() reloadNotes = new EventEmitter<any>();
    @ViewChild('vendorNoteModal', { static: true }) vendorNoteModal: any;

    note: string;
    modalReference: any;

    constructor(private modalService: NgbModal,
        private toastr: ToastrService,
        private vendorService: VendorService,
        private appUtils: AppUtils) {

    }

    openVendorNoteModal() {
        this.modalReference = this.modalService.open(this.vendorNoteModal,
            {
                backdrop: 'static',
                keyboard: false
            });
    }

    closeVendorNoteModal(form: NgForm) {
        this.note = '';
        form.resetForm();
        this.modalReference.close();
    }

    addNote(form: NgForm) {
        const vendorNote = new VendorNoteModel();
        vendorNote.vendorId = this.vendorId;
        vendorNote.note = this.note;
        this.noteBlockUI.start();
        this.vendorService.addNote(vendorNote)
            .subscribe(
                () => {
                    this.noteBlockUI.stop();
                    this.toastr.success('Note has been added successfully');
                    setTimeout(() => {
                        this.closeVendorNoteModal(form);
                        this.reloadNotes.emit();
                    }, 100);

                },
                error => {
                    this.noteBlockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
