import { Component, ViewChild, OnInit, AfterViewInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../../helpers/tools';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from '../../../helpers';
import {
    StoredgeFacilityPreview, SelectListItemModel, StoredgeConfigurationModel, FacilityDetailModel, UserGroupSelectList, UserSelectListItemModel
} from '../../../models';
import { UserService, FacilityService, UserGroupService } from '../../../services';

declare var appConfig: any;

@Component({
    selector: 'app-storedge-verify-facility',
    templateUrl: './storedge.verify.facility.component.html',
    styleUrls: ['./storedge.verify.facility.component.css']
})
export class StoredgeVerifyFacilityComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('f') f: NgForm;
    @BlockUI('container-blockui-verify') blockUI: NgBlockUI;
    @Input() facilities: Array<StoredgeFacilityPreview>;
    @Input() configurationModel: StoredgeConfigurationModel;
    @Input() totalFacilities: number;

    @Output() goBackToPreview = new EventEmitter();

    formSubscription: Subscription;
    isModelLoaded = false;
    isImporting = false;

    multiSelectDropdownConfigs = {};
    clientUsers: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    clientUserGroups: UserGroupSelectList[] = [];
    selectedClientUserGroups: UserGroupSelectList[] = [];
    complaintAssigneeId: string;
    moveOutAssigneeId: string;
    defaultAssigneeId: string;

    currentItemIndex = 0;
    totalSelectedFacilities = 0;

    model = new StoredgeFacilityPreview();

    constructor(private toastr: ToastrService,
        private appUtils: AppUtils,
        private userService: UserService,
        private facilityService: FacilityService,
        private userGroupService: UserGroupService) {

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'key',
            textField: 'value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit() {
        this.facilities = this.facilities.filter(facility => {
            if (facility.isSelected) {
                facility.companyId = this.configurationModel.internalCompanyId;
                return facility;
            }
        });

        this.totalSelectedFacilities = this.facilities.length;

        this.model = this.facilities[0];

        this.changeFacilitiesValiadtionStatus();
        this.getImportedFacilitiesData();

        setTimeout(() => {
            appConfig.initKTDefaults();
        }, 100);

        this.loadUserGroups();
        this.loadCompanyUsers();
        this.facilities.forEach(facility => {
            facility.complaintsAssigneeId = '';
            facility.moveOutAssigneeId = '';
            facility.propertyManagerUserId = '';
            facility.defaultAssigneeId = '';
        });
    }

    ngAfterViewInit() {
        this.formSubscription = this.f.statusChanges.subscribe((status) => {
            if (status === 'VALID') {
                this.model.modelState = 2;
            } else {
                this.model.modelState = 0;
            }
            const facility = this.facilities.find(x => x.id === this.model.id);
            facility.modelState = this.model.modelState;
        });
    }

    initModelWithDefault() {
        this.model.propertyManagerName = 'Anupam Shukla';
        this.model.propertyManagerEmail = 'anupam@gmail.com';
        this.model.propertyManagerPhone = '123456788';
        this.model.typeOfConstruction = 'Test';
        this.model.climateControlled = true;
        this.model.complaintsAssigneeId = 'b35f2ed0-76cf-4849-9d9a-e57a7885f021';
        this.model.moveOutAssigneeId = '10c825ca-2193-4afc-9ff3-c9b6e7944dec';
    }

    selectFacility(index: number, form: NgForm) {
        this.currentItemIndex = index;
        this.model = this.facilities[this.currentItemIndex];
        this.trackAssignees();
        this.resetClientUserGroupsAssigned();
    }

    setPropertyManagerFields() {
        if (this.model.propertyManagerUserId !== null && this.model.propertyManagerUserId.length) {
            //Update Redundant Properties
            let user = this.companyUsers.find(x => x.key == this.model.propertyManagerUserId);
            if (user) {
                this.model.propertyManagerName = user.value;
                this.model.propertyManagerEmail = user.email;
                this.model.propertyManagerPhone = user.phoneNumber;
            }
        }
    }

    validatePropertyManager(form: NgForm){
        if (this.model.propertyManagerUserId == null || this.model.propertyManagerUserId.length == 0) {
            form.form.controls['propertyManager'].setErrors({ 'required': true });
            form.form.controls['propertyManager'].markAsTouched();
        } else {
            form.form.controls['propertyManager'].setErrors(null);
        }
    }

    next(form: NgForm) {
        if (this.currentItemIndex === (this.totalSelectedFacilities - 1)) {
            return;
        }

        this.validatePropertyManager(form);
        if (form.form.invalid) {
            return;
        }

        this.currentItemIndex += 1;
        this.model = this.facilities[this.currentItemIndex];
        this.trackAssignees();
        this.resetClientUserGroupsAssigned();
    }    

    prev(form: NgForm) {
        if (this.currentItemIndex === 0) {
            return;
        }
        this.validatePropertyManager(form);
        if (form.form.invalid) {
            return;
        }

        this.currentItemIndex -= 1;
        this.model = this.facilities[this.currentItemIndex];
        this.trackAssignees();
        this.resetClientUserGroupsAssigned();
    }

    getChangesOnMemberAssigned(items: any) {
        this.model.clientUserGroupsAssigned = [];

        if (items) {
            items.map((item: any) => {
                this.model.clientUserGroupsAssigned.push(item.key);
            });
        }
    }

    trackAssignees() {
        if (isNullOrUndefined(this.model.moveOutAssigneeId)) {
            this.model.moveOutAssigneeId = '';
        } else {
            this.moveOutAssigneeId = this.model.moveOutAssigneeId;
        }

        if (isNullOrUndefined(this.model.complaintsAssigneeId)) {
            this.model.complaintsAssigneeId = '';
        } else {
            this.complaintAssigneeId = this.model.complaintsAssigneeId;
        }

        if (isNullOrUndefined(this.model.defaultAssigneeId)) {
            this.model.defaultAssigneeId = '';
        } else {
            this.defaultAssigneeId = this.model.defaultAssigneeId;
        }
    }

    resetClientUserGroupsAssigned() {
        this.selectedClientUserGroups = [];
        if (!this.model.clientUserGroupsAssigned) {
            this.setUsersFromUserGroups([]);
            return;
        }

        this.model.clientUserGroupsAssigned.map((item) => {
            const clientUserGroup = this.clientUserGroups.find(x => x.key === item);
            if (clientUserGroup) {
                this.selectedClientUserGroups.push(clientUserGroup);
            }
        });

        this.setUsersFromUserGroups(this.selectedClientUserGroups.map(x => x.key));
    }

    validateFacility(form: NgForm) {
        if (form.valid) {
            this.model.modelState = 2;
        } else {
            this.model.modelState = 0;
        }
    }

    updateSelectedUserGroups() {
        if (!this.clientUserGroups || this.clientUserGroups.length === 0) {
            return;
        }

        if (!this.model.clientUserGroupsAssigned || this.model.clientUserGroupsAssigned.length === 0) {
            this.setUsersFromUserGroups([]);
            return;
        }

        this.selectedClientUserGroups = [];
        this.model.clientUserGroupsAssigned.map((item) => {
            const clientUserGroup = this.clientUserGroups.find(x => x.key === item);
            if (clientUserGroup) {
                this.selectedClientUserGroups.push(clientUserGroup);
            }
        });
        this.setUsersFromUserGroups(this.selectedClientUserGroups.map(x => x.key));
    }

    changeFacilitiesValiadtionStatus() {
        this.facilities.forEach(facility => {
            if (this.isValid(facility)) {
                facility.modelState = 1;
            } else {
                facility.modelState = 0;
            }
        });
    }

    isValid(facility: StoredgeFacilityPreview) {

        if (!isNullOrUndefined(facility.propertyManagerUserId) && facility.propertyManagerUserId !== '' &&
            !isNullOrUndefined(facility.propertyManagerName) && facility.propertyManagerName !== '' &&
            !isNullOrUndefined(facility.propertyManagerEmail) && facility.propertyManagerEmail !== '' &&
            !isNullOrUndefined(facility.propertyManagerPhone) && facility.propertyManagerPhone !== '' &&
            !isNullOrUndefined(facility.typeOfConstruction) && facility.typeOfConstruction !== '' &&
            !isNullOrUndefined(facility.moveOutAssigneeId) && facility.moveOutAssigneeId !== '' &&
            !isNullOrUndefined(facility.complaintsAssigneeId) && facility.complaintsAssigneeId !== '' &&
            !isNullOrUndefined(facility.defaultAssigneeId) && facility.defaultAssigneeId !== '') {
            return true;
        }
        return false;
    }

    loadUserGroups() {
        this.blockUI.start();
        this.userGroupService.getCompanyUserGroupsSelectItem(this.configurationModel.internalCompanyId, true).subscribe(
            data => {
                this.clientUserGroups = data;
                this.updateSelectedUserGroups();
                this.blockUI.stop();
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }
        );
    }

    getImportedFacilitiesData() {
        const importedFacilityStoredgeIds
            = this.facilities.filter(x => x.status === 3)
                .map(facility => {
                    return facility.storedgeId;
                });

        if (importedFacilityStoredgeIds.length === 0) {
            return;
        }
        this.facilityService.getDetailByStoredgeIds(importedFacilityStoredgeIds).subscribe(
            (data) => {
                const facilitiesDetails = new Array<FacilityDetailModel>();
                Object.assign(facilitiesDetails, data);
                importedFacilityStoredgeIds.forEach(storedgeId => {
                    const facilityIndex = this.facilities.indexOf(this.facilities.filter(x => x.storedgeId === storedgeId)[0]);
                    const tempFacilityDetail = facilitiesDetails.filter(x => x.storedgeId === storedgeId)[0];
                    this.facilities[facilityIndex].propertyManagerName = tempFacilityDetail.propertyManagerName;
                    this.facilities[facilityIndex].propertyManagerEmail = tempFacilityDetail.propertyManagerEmail;
                    this.facilities[facilityIndex].propertyManagerPhone = tempFacilityDetail.propertyManagerPhone;
                    this.facilities[facilityIndex].typeOfConstruction = tempFacilityDetail.typeOfConstruction;
                    this.facilities[facilityIndex].clientUserGroupsAssigned = tempFacilityDetail.clientUserGroupsAssigned;
                    this.facilities[facilityIndex].climateControlled = tempFacilityDetail.climateControlled;
                    if (!isNullOrUndefined(tempFacilityDetail.complaintsAssigneeId)) {
                        this.facilities[facilityIndex].complaintsAssigneeId = tempFacilityDetail.complaintsAssigneeId;
                    }
                    if (!isNullOrUndefined(tempFacilityDetail.moveOutAssigneeId)) {
                        this.facilities[facilityIndex].moveOutAssigneeId = tempFacilityDetail.moveOutAssigneeId;
                    }
                    if (!isNullOrUndefined(tempFacilityDetail.defaultAssigneeId)) {
                        this.facilities[facilityIndex].defaultAssigneeId = tempFacilityDetail.defaultAssigneeId;
                    }
                });

                this.changeFacilitiesValiadtionStatus();
                this.trackAssignees();
                this.updateSelectedUserGroups();
            },
            error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    removeFacility(facility: StoredgeFacilityPreview) {
        const index = this.facilities.indexOf(facility);
        if (facility.status !== 3) {
            this.facilities[index].isSelected = false;
        }
        this.facilities[index].units.forEach(unit => {
            if (unit.status !== 3) {
                unit.isSelected = false;
            }
        });
        this.facilities.splice(index, 1);
        if (this.facilities.length === 0) {
            this.goBackToPreview.next();
            return;
        }
        if (this.currentItemIndex === index) {
            this.currentItemIndex = 0;
        }
        if (this.currentItemIndex > index) {
            this.currentItemIndex--;
        }
        this.model = this.facilities[this.currentItemIndex];
        this.trackAssignees();
        this.resetClientUserGroupsAssigned();
        this.totalSelectedFacilities = this.facilities.length;
    }

    ngOnDestroy() {
        this.blockUI.stop();
        this.formSubscription.unsubscribe();
    }

    facilityImportingStart() {
        this.isImporting = true;
    }
    facilityImportingEnd() {
        this.isImporting = false;
    }

    onUserGroupSelectDeSelect() {
        this.setUsersFromUserGroups(this.selectedClientUserGroups.map(x => x.key));
    }

    onUserGroupSelectDeSelectAll(items: SelectListItemModel[]) {
        this.setUsersFromUserGroups(items.map(x => x.key));
    }

    setDefaultAssignee() {
        this.model.defaultAssigneeId = this.defaultAssigneeId;
    }

    setComplaintsAssignee() {
        this.model.complaintsAssigneeId = this.complaintAssigneeId;
    }

    setMoveOutAssignee() {
        this.model.moveOutAssigneeId = this.moveOutAssigneeId;
    }

    setUsersFromUserGroups(selectedUserGroupsIds: string[]) {
        const selectedUsers: SelectListItemModel[] = [];

        if (selectedUserGroupsIds.length === 0) {
            this.clientUserGroups.forEach(x => selectedUsers.push(...x.users));
        } else {
            selectedUserGroupsIds.forEach(x => selectedUsers.push(...this.clientUserGroups.find(ug => ug.key === x).users));
        }

        const distinctSelectedUsers: SelectListItemModel[] = [];
        const map = new Map();
        for (const item of selectedUsers) {
            if (!map.has(item.key)) {
                map.set(item.key, true);
                distinctSelectedUsers.push({
                    id: null,
                    key: item.key,
                    value: item.value,
                    name: item.name
                });
            }
        }

        distinctSelectedUsers.sort((a, b) => a.value.localeCompare(b.value));

        this.clientUsers = distinctSelectedUsers;

        const currentMoveOutAssignee = this.clientUsers.find(x => x.key === this.moveOutAssigneeId);
        const currentComplaintsAssignee = this.clientUsers.find(x => x.key === this.complaintAssigneeId);
        const currentDefaultAssignee = this.clientUsers.find(x => x.key === this.defaultAssigneeId);

        if (isNullOrUndefined(currentMoveOutAssignee)) {
            if (isNullOrUndefined(this.model.moveOutAssigneeId)) {
                this.moveOutAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.model.moveOutAssigneeId);
                this.moveOutAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.model.moveOutAssigneeId;
            }
        }

        if (isNullOrUndefined(currentComplaintsAssignee)) {
            if (isNullOrUndefined(this.model.complaintsAssigneeId)) {
                this.complaintAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.model.complaintsAssigneeId);
                this.complaintAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.model.complaintsAssigneeId;
            }
        }

        if (isNullOrUndefined(currentDefaultAssignee)) {
            if (isNullOrUndefined(this.model.defaultAssigneeId)) {
                this.defaultAssigneeId = '';
            } else {
                const checkForPrev = this.clientUsers.find(x => x.key === this.model.defaultAssigneeId);
                this.defaultAssigneeId = isNullOrUndefined(checkForPrev) ? '' : this.model.defaultAssigneeId;
            }
        }
    }

    companyUsers: Array<UserSelectListItemModel> = new Array<UserSelectListItemModel>();
    loadCompanyUsers() {
        this.blockUI.start();
        this.userService.getCompanyUsersSelectItem(this.configurationModel.internalCompanyId)
            .subscribe((data: Array<any>) => {
                this.blockUI.stop();
                if (!data || data.length === 0) {
                    return;
                }
                this.companyUsers = data;
            }, error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }
}
