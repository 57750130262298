import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WoSubTaskState } from 'src/helpers';
import { WoSubTask, WoSubTaskDto } from 'src/models/work-order/sub-tasks/work.order.sub.task.model';

@Injectable({
  providedIn: 'root'
})
export class SubTasksService {

  constructor(private $http: HttpClient) { }

  getSubTask = (subTaskId: number) => this.$http.get<WoSubTaskDto>(`${environment.apiBaseUrl}work-order/sub-tasks/${subTaskId}`);

  getSubTasks = (workOrderId: number, sortByUnit: boolean) => this.$http.get<WoSubTaskDto[]>(`${environment.apiBaseUrl}work-order/${workOrderId}/sub-tasks?sortByUnit=${sortByUnit}`);

  addSubTask = (subTask: WoSubTask) => this.$http.post<WoSubTaskDto>(`${environment.apiBaseUrl}work-order/sub-tasks`, subTask);
  
  updateSubTask = (subTask: WoSubTask) => this.$http.put(`${environment.apiBaseUrl}work-order/sub-tasks/${subTask.id}`, subTask);

  updateSubTaskState = (subTaskId: number, state: WoSubTaskState) => 
    this.$http.put<WoSubTaskDto>(`${environment.apiBaseUrl}work-order/sub-tasks/${subTaskId}/${state}`, {});

  deleteSubTask = (subTaskId: number) => this.$http.delete(`${environment.apiBaseUrl}work-order/sub-tasks/${subTaskId}`);
}
