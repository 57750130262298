<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-unit-deatil [companyId]="model.companyId" [facilityId]="model.facilityId">
            </app-breadcrumb-unit-deatil>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui'">
        <div class="row">
            <div class="col-md-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Unit Detail
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar" *ngIf="showActionColumn">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canEditUnit">
                                        <a [routerLink]="['/unit/edit',model.companyId, model.facilityId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canChangeStatusOfUnit">
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===3">
                                            <em class="kt-nav__link-icon fa fa-check"></em>
                                            <span class="kt-nav__link-text">
                                                Activate
                                            </span>
                                        </a>
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===2">
                                            <em class="kt-nav__link-icon fa fa-ban"></em>
                                            <span class="kt-nav__link-text">
                                                Deactivate
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canDeleteUnit">
                                        <a href="javascript:;" class="kt-nav__link" (click)="challengeDelete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="form-group row">
                            <label class="col-md-3 kt-portlet__label">Unit Name:</label>
                            <div class="col-md-9">
                                <p class="kt-portlet__data">{{model.name}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 kt-portlet__label">Work Orders:</label>
                            <div class="col-md-9">
                                <p class="kt-portlet__data">{{model.totalWorkOrders}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 kt-portlet__label">Status:</label>
                            <div class="col-md-6">
                                <label class="badge badge-success" *ngIf="model.status === 2">Active</label>
                                <label class="badge badge-danger" *ngIf="model.status === 3">Inactive</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 kt-portlet__label">Is Imported:</label>
                            <div class="col-md-6">
                                <p class="kt-portlet__data" *ngIf="model.isImported">Yes</p>
                                <p class="kt-portlet__data" *ngIf="!model.isImported">No</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 kt-portlet__label">Facility Name:</label>
                            <div class="col-md-9">
                                <p class="kt-portlet__data">{{model.facilityName}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-unit-challenge-delete #unitChallengeDeleteModal (deleteUnit)="delete()"></app-unit-challenge-delete>