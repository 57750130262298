export class KioskAddModel {
    public companyId: number;
    public facilityId: string;
    public registrationKey: string;
    public tag: string;

    constructor() {
        this.facilityId = '';
    }
}
