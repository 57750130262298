<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-user-edit></app-breadcrumb-user-edit>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-8" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Edit User
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate>
                        <div class="kt-portlet__body">
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && !firstName.valid}">
                                <label class="col-md-3">First Name:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="firstName" class="form-control" maxlength="250"
                                        autocomplete="off" [(ngModel)]="model.firstName" #firstName="ngModel"
                                        appAlphabatesWithSpaceOnly required />
                                    <div class="validation-error-message" *ngIf="f.submitted && !firstName.valid">
                                        First name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3">Last Name:</label>
                                <div class="col-md-9">
                                    <input type="text" name="lastName" class="form-control" maxlength="250"
                                        autocomplete="off" [(ngModel)]="model.lastName" #lastName="ngModel"
                                        appAlphabatesWithSpaceOnly />
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && email.invalid}">
                                <label class="col-md-3">Email:*</label>
                                <div class="col-md-9">
                                    <input type="text" name="email" class="form-control" minlength="4" maxlength="50"
                                        autocomplete="off" [(ngModel)]="model.email" #email="ngModel" required />
                                    <div class="validation-error-message" *ngIf="f.submitted && email.invalid">
                                        <span *ngIf="email.errors.required">
                                            Email is required
                                        </span>
                                        <span *ngIf="email.errors.minlength">
                                            Email must be greater than 4 characters
                                        </span>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && phone.invalid}">
                                <label class="col-md-3">Phone:*</label>
                                <div class="col-md-6">
                                    <input type="tel" name="phone" class="form-control" minlength="7" maxlength="13"
                                        autocomplete="off" [(ngModel)]="model.phone" #phone="ngModel" appPhoneNumberOnly
                                        required />
                                    <div class="validation-error-message" *ngIf="f.submitted && phone.invalid">
                                        <span *ngIf="phone.errors.required">
                                            Phone is required
                                        </span>
                                        <span *ngIf="phone.errors.minlength">
                                            Phone must be greater than 7 characters
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" [ngClass]="{ 'validation-error' : f.submitted && !role.valid}">
                                <label class="col-md-3">Role:*</label>
                                <div class="col-md-6">
                                    <select name="role" class="form-control" autocomplete="off" [(ngModel)]="model.role"
                                        #role="ngModel" required>
                                        <option value="">[Select]</option>
                                        <option value="Admin">Administrator</option>
                                        <option value="User">Personnel</option>
                                    </select>
                                    <div class="validation-error-message" *ngIf="f.submitted && !role.valid">
                                        Role is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row"
                                [ngClass]="{ 'validation-error' : f.submitted && timezone.invalid}">
                                <label class="col-md-3">Time Zone:*</label>
                                <div class="col-md-9">
                                        <select name="timezone" class="form-control"
                                            [(ngModel)]="model.timeZone"
                                            #timezone="ngModel"
                                            required>
                                            <option *ngFor="let timeZone of timeZones"
                                                [value]="timeZone.standardName">
                                                ({{timeZone.currentUtcOffset}}) {{timeZone.standardName}}
                                            </option>
                                        </select>
                                    <div class="validation-error-message" *ngIf="f.submitted && timezone.invalid">
                                        <span *ngIf="timezone.errors.required">
                                            Time zone is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-12">
                                    Notification Preference:
                                </label>
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="enableEmailNotification"
                                                        name="enableEmailNotification"
                                                        (change)="onEmailNotificationDisabled()"
                                                        [(ngModel)]="model.enableEmailNotification" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="enableEmailNotification">
                                                Enable email notification
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="enableInappNotification"
                                                        name="enableInappNotification"
                                                        checked="true"
                                                        disabled />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4">
                                                Enable In-App notifications
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="enablePushNotification"
                                                        name="enablePushNotification"
                                                        (change)="onPushNotificationDisabled()"
                                                        [(ngModel)]="model.enablePushNotification" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="enablePushNotification">
                                                Enable iOS/Android push notification
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-12">
                                    Alert me of changes to work orders I'm watching by:
                                </label>
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendEmailNotification"
                                                        name="sendEmailNotification"
                                                        (change)="onWatchersEmailNotificationEnabled()"
                                                        [(ngModel)]="model.sendEmailNotification" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendEmailNotification">
                                                Emails
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendInAppNotification"
                                                        name="sendInAppNotification"
                                                        [(ngModel)]="model.sendInAppNotification" 
                                                        disabled/>
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendInAppNotification">
                                                In-App notifications
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="float-left">
                                                <label class="kt-checkbox kt-checkbox--brand">
                                                    <input type="checkbox" id="sendPushNotification"
                                                        name="sendPushNotification"
                                                        (change)="onWatchersPushNotificationEnabled()"
                                                        [(ngModel)]="model.sendPushNotification" />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <label class="float-left m-t-4" for="sendPushNotification">
                                                iOS/Android push notifications
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/user/manage']" class="btn btn-secondary m-r-5">Cancel</a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>