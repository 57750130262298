import { CategoryType } from 'src/helpers';

export class SelectListItemModel {
    public id: string;
    public name: string;
    public key: string;
    public value: string;
}

export class CategorySelectListItemModel {
    public id: string;
    public name: string;
    public type: CategoryType;
    public dueDateDuration: number;
    public workOrderPriority: any;
    public isUnrentable: boolean;
}

export class UserSelectListItemModel {
    public id: string;
    public name: string;
    public key: string;
    public value: string;
    public email: string;
    public phoneNumber: string;
}

export class AssigneeSelectListItemModel {
    public id: string;
    public name: string;
    public type: number;
    public image: string;
}