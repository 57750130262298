<div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile activity-log-widget">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Activity Log
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <em class="la la-refresh fs-20 cursor-pointer" (click)="reloadActivityLogs()"></em>
        </div>
    </div>
    <div class="kt-portlet__body" *blockUI="'container-blockui-activity-log'" data-scroll="true"
    data-height="532" data-mobile-height="200">
        <div class="kt-widget3">
            <div class="kt-widget3__item" *ngFor="let item of activityLogs">
                <div class="kt-widget3__header">
                    <div class="kt-widget3__user-img">
                        <em class="la la-user fs-25"></em>
                    </div>
                    <div class="kt-widget3__info">
                        <span class="kt-widget3__username text-capitalize">
                            {{item.createdByName}}
                        </span><br>
                        <span class="kt-widget3__time">
                            {{item.createdOn}}
                        </span>
                    </div>
                    <span class="kt-widget3__status">
                        &nbsp;
                    </span>
                </div>
                <div class="kt-widget3__body">
                    <app-activity-log-company-activities [model]="item" *ngIf='item.entityType === 3'>
                    </app-activity-log-company-activities>
                    <app-activity-log-company-user-activities [model]="item" *ngIf='item.entityType === 2'>
                    </app-activity-log-company-user-activities>
                    <app-activity-log-facility-activities [model]="item" *ngIf='item.entityType === 4'>
                    </app-activity-log-facility-activities>
                    <app-activity-log-unit-activities [model]="item" *ngIf='item.entityType === 5'>
                    </app-activity-log-unit-activities>
                    <app-activity-log-workorder-activities [model]="item" *ngIf='item.entityType === 6'>
                    </app-activity-log-workorder-activities>
                    <app-activity-log-vendor-activities [model]="item" *ngIf='item.entityType === 7'>
                    </app-activity-log-vendor-activities>
                </div>
            </div>
            <div class="kt-widget3__item text-center" *ngIf="showLoadMore">
                <div class="kt-widget3__header text-center">
                    <button type="button" class="btn btn-outline-primary btn-wide btn-sm" (click)="loadMore()">Load
                        More</button>
                </div>
            </div>
        </div>
    </div>
</div>