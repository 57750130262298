<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-company-detail></app-breadcrumb-company-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'container-blockui'">
        <div class="row">
            <div class="col-md-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Company Detail
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar" *ngIf="roleMatrix.canChangeStatusOfCompany                                        
                                        || roleMatrix.canDeleteCompany">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canChangeStatusOfCompany">
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===3">
                                            <em class="kt-nav__link-icon fa fa-check"></em>
                                            <span class="kt-nav__link-text">
                                                Activate
                                            </span>
                                        </a>
                                        <a href="javascript:;" class="kt-nav__link" (click)="toggleStatus()"
                                            *ngIf="model.status===2">
                                            <em class="kt-nav__link-icon fa fa-ban"></em>
                                            <span class="kt-nav__link-text">
                                                Deactivate
                                            </span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canDeleteCompany">
                                        <a href="javascript:;" class="kt-nav__link" (click)="challengeDelete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <ngb-tabset *ngIf="isModelLoaded">
                            <ngb-tab>
                                <ng-template ngbTabTitle>
                                    Company Overview
                                </ng-template>
                                <ng-template ngbTabContent>
                                    <div class="col-md-12 detail-form">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-building" title="Company Name"></em> {{model.name}}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-phone-square" title="Company Contact Number"></em>
                                                    {{model.contactNumber}}
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <em class="fa fa-id-card" title="AccountID"></em> ID{{model.id}}
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-right">
                                                <a [routerLink]="['/company/edit',model.id]"
                                                    class="btn btn-sm btn-outline-brand btn-elevate btn-icon"
                                                    *ngIf="roleMatrix.canEditCompany">
                                                    <i class="la la-pencil"></i></a>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-map-marker" title="Company Address"></em>
                                                    {{model.address}}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-envelope" title="Company Email"></em>
                                                    {{model.email}}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-lock m-r-3" title="Status"></em> <span
                                                        class="badge badge-success"
                                                        *ngIf="model.status===2">Active</span>
                                                    <span class="badge badge-danger"
                                                        *ngIf="model.status===3">Inactive</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-globe" title="Company Website"></em>
                                                    {{model.website}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 m-t-10">
                                                <h5>Account Owner Detail</h5>
                                            </div>
                                            <div class="col-md-6 m-t-10">
                                                <button type="button" class="btn btn-label-primary btn-sm btn-bold mr-3 float-right"
                                                    (click)="followUser()"
                                                    *ngIf="showFollowButton">
                                                    {{ accountOwner.isFollowing ? 'Unfollow' : 'Follow' }}
                                                </button>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="kt-separator m-t-5">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-user" title="Account Owner Name"></em>
                                                    {{model.accountOwnerName}}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <em class="fa fa-envelope" title="Account Owner Email"></em>
                                                    {{model.accountOwnerEmail}}
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <em class="fa fa-phone-square"
                                                        title="Account Owner Contact Number"></em>
                                                    {{model.accountOwnerContactNumber}}
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-right">
                                                <a [routerLink]="['/company-owner/edit',model.id]"
                                                    class="btn btn-sm btn-outline-brand btn-elevate btn-icon"
                                                    *ngIf="roleMatrix.canEditOrgSuperAdmin">
                                                    <i class="la la-pencil"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                            <ngb-tab>
                                <ng-template ngbTabTitle>
                                    Payments
                                </ng-template>
                                <ng-template ngbTabContent>
                                    <div class="col-md-12 m-t-10">
                                        <p class="text-muted">No information found</p>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                            <ngb-tab>
                                <ng-template ngbTabTitle>
                                    Subscription
                                </ng-template>
                                <ng-template ngbTabContent>
                                    <div class="col-md-12 m-t-10">
                                        <p class="text-muted">No information found</p>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"
            *ngIf="roleMatrix.role === 'SUPERADMIN' || roleMatrix.role === 'ADMIN' || roleMatrix.role === 'USER'">
            <div class="col-4">
                <app-company-work-order-tile [companyId]="model.id"></app-company-work-order-tile>
            </div>
            <div class="col-4">
                <app-company-admin-tile [companyId]="model.id"></app-company-admin-tile>
            </div>
            <div class="col-4">
                <app-company-user-tile [companyId]="model.id"></app-company-user-tile>
            </div>
            <div class="col-4">
                <app-company-facility-tile [companyId]="model.id"></app-company-facility-tile>
            </div>
            <div class="col-4">
                <app-company-unit-tile [companyId]="model.id"></app-company-unit-tile>
            </div>
            <div class="col-4">
                <app-company-vendor-tile [companyId]="model.id"> </app-company-vendor-tile>
            </div>
            <div class="col-4">
                <app-company-kiosk-tile [companyId]="model.id"> </app-company-kiosk-tile>
            </div>
            <div class="col-4">
                <app-company-configuration-tile [companyId]="model.id"></app-company-configuration-tile>
            </div>
            <div class="col-4">
                <app-company-category-tile [companyId]="model.id"></app-company-category-tile>
            </div>
            <div class="col-4">
                <app-company-user-group-tile [companyId]="model.id"></app-company-user-group-tile>
            </div>
            <div class="col-4">
                <app-wo-configuration-tile [companyId]="model.id"></app-wo-configuration-tile>
            </div>
        </div>
    </div>
</div>

<app-company-challenge-delete #companyChallengeDeleteModal (deleteCompany)="delete()">
</app-company-challenge-delete>