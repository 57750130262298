<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breacrumb-company-user-manage [companyId]="filterModel.companyId" [filterUserType]="filterUserType">
            </app-breacrumb-company-user-manage>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="col-md-12">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Personnel
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div class="kt-portlet__head-actions" *ngIf="filterModel.companyId">
                                <a [routerLink]="['/company-user/add']"
                                    [queryParams]="{companyId: filterModel.companyId, userType: filterUserType}"
                                    class="btn btn-brand btn-elevate btn-icon-sm" *ngIf="roleMatrix.canAddOrgUser">
                                    <em class="la la-plus"></em>
                                    New User
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer" *blockUI="'container-blockui'">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <select class="form-control" name="status" [(ngModel)]="filterModel.status">
                                            <option value="">[Status]</option>
                                            <option value="2">Active</option>
                                            <option value="3">Inactive</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control" placeholder="Filter users"
                                            [(ngModel)]="filterModel.filterKey" autocomplete="Off" maxlength="50"
                                            (keydown.enter)="doFilter()" />
                                    </div>
                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-brand btn-elevate btn-icon-sm"
                                            (click)="doFilter()">
                                            <em class="la la-search"></em> Filter
                                        </button>
                                        <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5"
                                            (click)="resetFilter()">
                                            <em class="la la-close"></em> Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table datatable [dtOptions]="dtOptions" data-page="2"
                                    class="table table-hover break-all">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>