export class MoveinAmenityAddModel {
    public companyId: number;
    public facilityId: number;
    public kioskId: number;
    public amenityTypeId: string;
    public originalIconName: string;
    public iconName: string;
    public isParkingAmenity: boolean;
    public callCenterPhoneNumber: string;
    constructor() {
        this.amenityTypeId = '';
        this.isParkingAmenity = false;
    }
}

export class SkinningColorModel {
    public configurationId: number;
    public skinningPrimaryColor: string;
}

export class SkinningLogoModel {
    public configurationId: number;
    public skinningLogoFileName: string;
    public skinningLogoOriginalFileName: string;
    public skinningLogoFileUrl: string;
}