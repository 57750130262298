import { Component, OnInit, Input } from '@angular/core';
import { FacilityService, UnitService } from '../../../../services';

@Component({
    selector: 'app-company-facility-tile',
    templateUrl: './company.facility.tile.component.html'
})

export class CompanyFacilityTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;
    totalFacilities = 0;
    totalUnits = 0;
    constructor(private facilityService: FacilityService,
        private unitService: UnitService) { }

    ngOnInit(): void {
        this.loadFacilitiesCount();
        this.loadUnitsCount();
    }

    loadFacilitiesCount() {
        this.facilityService.getCountByCompanyId(this.companyId, false)
            .subscribe(
                (data: any) => {
                    this.isModelLoaded = true;
                    this.totalFacilities = data;
                },
                error => {
                    console.log('Facilities count failed');
                    console.log(error);
                });
    }

    loadUnitsCount() {
        this.unitService.getCountByCompany(this.companyId)
            .subscribe(
                (data: any) => {
                    this.isModelLoaded = true;
                    this.totalUnits = data;
                },
                error => {
                    console.log('Units count failed');
                    console.log(error);
                });
    }
}
