<div class="kt-grid kt-grid--ver kt-grid--root">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

            <app-left-banner [model]="leftBannerModel"></app-left-banner>

            <div
                class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper w-25">
                <div class="kt-login__body" *blockUI="'container-blockui'">
                    <div class="kt-login__form">
                        <div class="kt-login__title">
                            <h3>Reset Password</h3>
                        </div>
                        <div class="row" *ngIf="formSubmitted">
                            <div class="col-12">
                                <p>A verification link has been sent to your registered email. Please click on the link
                                    to proceed with the verification process</p>
                            </div>
                            <div class="col-12 m-t-25">
                                <a [routerLink]="['/account/login']" class="kt-link kt-login__link-forgot">
                                    Back to Login
                                </a>
                            </div>
                        </div>
                        <form class="kt-form" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate
                            *ngIf="!formSubmitted">
                            <div class="form-group" [ngClass]="{ 'validation-error' : f.submitted && !email.valid}">
                                <input type="email" name="email" class="form-control" maxlength="50" autocomplete="off"
                                    placeholder="Email*" [(ngModel)]="model.email" #email="ngModel" required />
                                <div class="validation-error-message" *ngIf="f.submitted && email.invalid">
                                    <span *ngIf="!email.errors.minlength && email.errors.required">
                                        Enter your email address
                                    </span>
                                    <span *ngIf="!email.errors.minlength">
                                        Invalid email address
                                    </span>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <a [routerLink]="['/account/login']" class="kt-link kt-login__link-forgot">
                                    Back to Login
                                </a>
                                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
                                    Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>