import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RoleMatrix } from 'src/helpers';
import { StoredgeConfigurationModel } from '../../../models';
import { CompanyService } from 'src/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-storedge-integration',
    templateUrl: './storedge.integration.component.html'
})

export class StoredgeIntegrationComponent implements OnInit {
    @BlockUI('blockui')blockUI:NgBlockUI;
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;

    model: StoredgeConfigurationModel = new StoredgeConfigurationModel();

    activeTab = '1';
    showActionColumn = true;
    isHiddenStoredge:boolean=true;
    
    constructor(private route: ActivatedRoute,
        private router: Router,
        private companyService: CompanyService) {

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canConfigureStoredgeCompany) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe((params) => {
            this.model.internalCompanyId = params['companyId'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.internalCompanyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.blockUI.start();
        
        this.companyService.getStatus(this.model.internalCompanyId).subscribe(
            data => {
                if (data === 3) {
                    this.showActionColumn = false;
                }
            });
            setTimeout(() => {
                this.blockUI.stop(); // Stop blocking
            }, 2000);
    }

    openPreviewTab() {
        this.activeTab = '2';
    }
}
