import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { WorkOrderService } from 'src/services';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { DataTableResponseModel } from 'src/models';

@Component({
    selector: 'app-comp-wo-category-list',
    templateUrl: './company.wo.category.list.component.html'
})
export class CompanyWoCategoryListComponent implements OnInit, AfterViewInit {

    companyId: number;

    @BlockUI('container-blockui-category-list') blockUI: NgBlockUI;

    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;

    rowIndex = 0;
    pageLength = 10;
    search: any = null;

    roleMatrix: RoleMatrix;

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils) {
        this.companyId = this.route.snapshot.params['companyId'];
        this.roleMatrix = new RoleMatrix();
    }

    ngOnInit(): void {

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter category...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[1, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {
                const url = `${environment.apiBaseUrl}workOrder/get-categories?companyId=${this.companyId}`;
                self.http
                    .post<DataTableResponseModel>(url, dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Category Name',
                    width: '20%'
                },
                {
                    data: 'createdOn',
                    title: 'Added On',
                    width: '13%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'dueDateDuration',
                    title: 'Default Due Date',
                    width: '13%',
                    className: 'text-center',
                },
                {
                    data: 'isUnrentable',
                    title: 'Unit Rentability',
                    width: '15%',
                    render: function (data) {
                        return data ? '<span class="badge badge-danger">Unrentable</span>'
                            : '<span class="badge badge-success">Rentable</span>'
                    }
                },
                {
                    data: 'workOrderPriority',
                    title: 'Priority',
                    width: '8%',
                    render: function (data) {
                        if (data) {
                            return self.appUtils.getPriorityList().find(x => x.id == data).name;
                        }
                        return '';
                    }
                },
                {
                    data: 'createdByName',
                    title: 'Created By',
                    width: '15%'
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '8%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '10%',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, row) {
                        const statusHtml = (row.status === 2
                            ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                            : row.status === 3
                                ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                : '');

                        const followHtml = self.roleMatrix.is10FedUser || (row.status === 3 && !row.isUserFollowing)
                            ? ''
                            : (row.isUserFollowing
                                ? `<em class='fa fa-bell-slash cursor-pointer m-r-5 ${row.status === 3 ? 'color-grey-disabled' : ''}'` +
                                `title='Unfollow' action-type='toggle-follow'></em>`
                                : `<em class='fa fa-bell cursor-pointer m-r-5' title='Follow' action-type='toggle-follow'></em>`);

                        return row.isDefault
                            ? followHtml  +
                            `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>`
                            : followHtml +
                            statusHtml +
                            `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` +
                            `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>`;

                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                if (data.status === 2) {
                    const toggleFollowElem = $(row).find('[action-type = "toggle-follow"]');
                    $(toggleFollowElem).unbind('click').on('click', function () {
                        self.toggleFollow(data.id, data.isUserFollowing);
                    });
                }

                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).off('click').on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).off('click').on('click', function () {
                    self.delete(data.id, dataIndex);
                });

                $(row).find('[action-type = "edit"]').off('click').on('click', function () {
                    self.router.navigate(['/work-order-comp-categories', self.companyId, 'edit', data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    toggleFollow(id: number, isUserFollowing: boolean) {
        this.blockUI.start();
        this.workOrderService.toggleCategoryFollow(id, this.companyId).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(isUserFollowing
                    ? 'You have unfollowed the category.'
                    : 'You have started following the category.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.workOrderService.toggleCategoryStatus(id, this.companyId).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(`Category has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    delete(id: number, dataIndex: number): void {
        if (!confirm('Are you sure you want to delete the selected category?')) {
            return;
        }

        this.blockUI.start();
        this.workOrderService.deleteLocalCategory(id, this.companyId).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (dataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.toastr.success('Category has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    refreshTableData() {
        this.dtInstance.ajax.reload(null, false);
    }

    redirectToAddCategory() {

    }
}
