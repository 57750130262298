import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router,ActivatedRoute } from '@angular/router';
import { RoleMatrix } from '../../../helpers';
import { KpiReportService } from '@10fed/services/kpi-report.service';
import { QuickBooksAddRefreshTokenModel } from "src/models/quickbooks/quickbooks.AddRefreshToken.model";
import { AppUtils } from 'src/helpers';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-quick-callback',
    templateUrl: './quick.callback.component.html'
})

export class QuickBooksCallBackComponent implements OnInit{
    roleMatrix: RoleMatrix;
    model:QuickBooksAddRefreshTokenModel = new QuickBooksAddRefreshTokenModel();

    constructor(private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private kpiReportService: KpiReportService,
        private appUtils: AppUtils)
        {
            this.roleMatrix = new RoleMatrix();
            this.route.queryParams
                .subscribe(params => {
                        this.model.code = params.code;   
                        this.model.state = params.state; 
                        this.model.realmId = params.realmId;
                        this.model.internalCompanyId = parseInt(this.roleMatrix.companyId);  
                        });
        }

    
    ngOnInit() {
        this.addQuickBooksRefreshToken();
    }

    addQuickBooksRefreshToken(){
        if(this.model.state.length > 0 && (this.model.code != '' || this.model.code != null || this.model != undefined))
        {
            this.kpiReportService.addQuickBooksRefreshToken(this.model)
                .subscribe(
                    ()=>{
                        this.toastr.success("Refresh Token Add Successfully...!!!");
                        this.router.navigate(['/storedge/intergration/'+this.roleMatrix.companyId]);
                    },
                    error => {
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    }
                );
        }

        this.router.navigate(['']);
    }
}