export class CompanyAddModel {
    public name: string;
    public address: string;
    public zipCode: string;
    public website: string;
    public contactNumber: string;
    public email: string;
    public accountOwnerName: string;
    public accountOwnerEmail: string;
    public accountOwnerContactNumber: string;
    public accountOwnerTimeZone: string;
}
