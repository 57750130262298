export class ConfigurationModel {
    public id: number;
    public stateView: number;
    public keyboardPreference: number;
    public documentFileName: string;

    public callCenterPhoneNumber: string;
    public skinningLogoFileName: string;
    public skinningLogoFileUrl: string;
    public skinningLogoOriginalFileName: string;
    public skinningPrimaryColor: string;
}
