export class WorkOrderListItemModel {
    public id: number;
    public unitName: string;
    public facilityName: string;
    public companyName: string;
    public title: string;
    public description: string;
    public createdBy: string;
    public createdOn: string;
    public updatedOn: string;
    public workOrderStatus: number;
    public workOrderPriority: number;
    public assignedToId: string;
    public assignedToName: string;
    public assignedToGroupId: string;
    public assignedToGroupName: string;
    public status: number;
}

export class WorkOrderSelectedListItemModel {
    // This is a local property for work order selection tracking
    public selected: boolean;

    public id: number;
    public unitName: string;
    public facilityName: string;
    public companyName: string;
    public title: string;
    public description: string;
    public createdBy: string;
    public createdOn: string;
    public updatedOn: string;
    public closedOn: string;
    public workOrderStatus: number;
    public workOrderPriority: number;
    public workOrderCategoryName: string;
    public assignedToId: string;
    public assignedToName: string;
    public status: number;
}
