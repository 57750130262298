import { Component } from '@angular/core';
import { RoleMatrix } from 'src/helpers';

@Component({
    selector: 'app-breadcrumb-company-edit',
    templateUrl: './breadcrumb.company.edit.component.html'
})

export class BreadcrumbCompanyEditComponent {
    roleMatrix: RoleMatrix;
    isClientUser: boolean;
    constructor() {
        this.roleMatrix = new RoleMatrix();
        if (this.roleMatrix.role === 'ORGSUPERADMIN' || this.roleMatrix.role === 'ORGADMIN' ||
            this.roleMatrix.role === 'OrgSuperAdmin' || this.roleMatrix.role === 'OrgAdmin') {
            this.isClientUser = true;
        } else {
            this.isClientUser = false;
        }
    }
}
