import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from '@aspnet/signalr';
import { environment } from 'src/environments/environment';

import { ListenerService } from '../services';
import { RoutingState, AppUtils, AppSettings } from '../helpers';
declare var appConfig: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  routerSubscription: Subscription;
  hubConnection: HubConnection;
  layoutType = 1;
  signalRRetryCounter = 0;

  constructor(private router: Router,
    private renderer2: Renderer2,
    private routingState: RoutingState,
    private appUtils: AppUtils,
    private appSettings: AppSettings,
    private listenerService: ListenerService) {

    this.routingState.loadRouting();

    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    this.subscription = this.listenerService

      .listenLayout.subscribe(() => { this.setLayoutType(); });

    this.routerSubscription = this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        if (this.appUtils.isUserAuthenticated()) {
          this.listenerService.notificationMenuListner.next();
        }
      }

      if (event instanceof NavigationEnd) {
        this.setLayoutType();
      }
    });
  }

  setLayoutType() {
    if (this.appUtils.isUserAuthenticated()) {
      this.renderer2.addClass(document.body, 'kt-header--fixed');
      this.layoutType = 2;
    } else {
      this.renderer2.removeClass(document.body, 'kt-header--fixed');
      this.layoutType = 1;
    }
    appConfig.adjustContainerHeight();
  }

  ngOnInit() {

    this.setLayoutType();

    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        const token = localStorage.getItem('AuthToken');
        if (token === null || token === undefined) {
          this.router.navigate(['/account/logout']);
        } else {
        }
      }
    }, false);

    this.startSignalRConnection();
  }

  startSignalRConnection(): void {
    const self = this;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalRHubUrl, HttpTransportType.ServerSentEvents)
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 2;
    this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 1;

    this.hubConnection.on('reloadWebAppData', () => {
      this.listenerService.signalReventListner.next();
      console.log('reloadWebAppData called');
    });

    this.hubConnection
      .start()
      .then(() => {
        console.log('Hub connection started');
        self.signalRRetryCounter = 0;
      })
      .catch(err => {
        console.log('Error while establishing connection');
        console.log(err);
        console.log('Retrying...');
        setTimeout(function () {
          self.startSignalRConnection();
        }, 30000);
      });

    this.hubConnection.onclose(
      function (e) {
        console.log('SignalR connection closed.\n' + e);
        if (self.signalRRetryCounter <= 10) {
          console.log('Retrying to start. Retry counter =' + self.signalRRetryCounter);
          self.signalRRetryCounter++;
          self.startSignalRConnection();
        } else {
          console.log('Maximum number of retry exceeded. Retry counter =' + self.signalRRetryCounter);
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        console.log('Hub connection stopped');
      })
        .catch(err => {
          console.log('Error while stopping the connection- ' + err);
        });
    }
  }
}
