import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ActivityLogModel } from '../../../models';

import { WorkOrderService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-work-order-activity-log',
    templateUrl: './work.order.activity.log.component.html'
})

export class WorkOrderActivityLogComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    companyId = -1;
    workOrderId = -1;
    isModelLoaded = true;
    activityLogs: Array<ActivityLogModel> = new Array<ActivityLogModel>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddWorkOrder) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.workOrderId = params['id'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit() {
        this.blockUI.start();
        this.workOrderService.getActivityLogs(this.workOrderId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();

                    Object.assign(this.activityLogs, data);

                    if (this.activityLogs.length === 0) {
                        return;
                    }

                    this.activityLogs.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

    }
}
