import { Component, OnInit, Input } from '@angular/core';

import { WorkOrderService } from '../../../../services';

@Component({
    selector: 'app-company-work-order-tile',
    templateUrl: './company.work.order.tile.component.html'
})

export class CompanyWorkOrderTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;
    totalWorkOrdersNotStarted = 0;
    totalWorkOrdersInProgress = 0;

    constructor(private workOrderService: WorkOrderService) {

    }

    ngOnInit(): void {
        this.workOrderService.getCountByCompanyNotStarted(this.companyId, false)
            .subscribe(
                (data: number) => {
                    this.isModelLoaded = true;
                    this.totalWorkOrdersNotStarted = data;
                },
                error => {
                    console.log('Work order (not started) count failed');
                    console.log(error);
                });

        this.workOrderService.getCountByCompanyInProgress(this.companyId, false)
            .subscribe(
                (data: number) => {
                    this.isModelLoaded = true;
                    this.totalWorkOrdersInProgress = data;
                },
                error => {
                    console.log('Work order (in progress) count failed');
                    console.log(error);
                });
    }
}
