import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppUtils, RoleMatrix } from 'src/helpers';
import { DataTableResponseModel, UserSelectListItemModel } from 'src/models';
import { MasterService, UserService } from 'src/services';
import { isNullOrUndefined } from 'util';
import { WorkOrderFilterModel } from '../../../models';

@Component({
  selector: 'app-facilities-total-open-work-orders',
  templateUrl: './facilities-total-open-work-orders.component.html'
})
export class FacilitiesTotalOpenWorkOrdersComponent implements OnInit, AfterViewInit {

  @BlockUI('container-blockui') blockUI: NgBlockUI;

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  pageLength = 10;

  roleMatrix: RoleMatrix;

  companyUsers: UserSelectListItemModel[] = [];
  propertyManagerId = '';
  filterText = '';

  constructor(private http: HttpClient,
              private router: Router,
              private toastrService: ToastrService,
              private masterService: MasterService,
              private userService: UserService,
              private toastr: ToastrService,
              private appUtils: AppUtils) {
      this.roleMatrix = new RoleMatrix();
  }

  ngOnInit() {
    this.initDataTable();
    this.loadCompanyUsers();
  }

  ngAfterViewInit() {
    this.datatableElement.dtInstance
      .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
  }

  initDataTable() {
    const self = this;
    this.dtOptions = {
        dom: '<"top">rt<"bottom"lip><"clear">',
        serverSide: true,
        processing: true,
        language: {
            loadingRecords: '&nbsp;',
            processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
            searchPlaceholder: 'Filter category...',
            paginate: {
                first: '<i class="fa fa-angle-double-left">',
                last: '<i class="fa fa-angle-double-right">',
                previous: '<i class="fa fa-angle-left">',
                next: '<i class="fa fa-angle-right">'
            }
        },
        search: { search: null },
        searching: false,
        displayStart: self.rowIndex,
        paging: true,
        pagingType: 'full_numbers',
        pageLength: self.pageLength,
        lengthMenu: [10, 15, 25, 50, 100],
        order: [[2, 'desc']],
        stateSave: true,
        stateLoaded: function(settings: any, data: any) {
          settings.iInitDisplayStart = 0;
        },
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.companyId = this.roleMatrix.companyId;
          dataTablesParameters.propertyManagerId = this.propertyManagerId;
          dataTablesParameters.filterText = this.filterText;

          const url = `${environment.apiBaseUrl}facility/reports/generate-facilities-total-open-work-orders-report`;
          self.http
              .post<DataTableResponseModel>(url, dataTablesParameters, {})
              .subscribe(resp => {
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: resp.data
                  });
              }, error => {
                self.appUtils.ProcessErrorResponse(self.toastrService, error);
              });
        },
        columns: [
            {
                data: 'facilityName',
                title: 'Facility Name',
                width: '25%',
                render: function (facilityName, type, row, meta) {
                  return `<span class='link' action-type='link-detail'>${facilityName}</a>`;
                }
            },
            {
                data: 'propertyManagerName',
                title: 'Property Manager',
                width: '20%'
            },
            {
                data: 'openWorkOrdersCount',
                title: 'Open Workorders',
                width: '12%',
                className: 'text-center'
            },
            {
                data: 'medianOpenDuration',
                title: 'Median Open Duration',
                width: '13%',
                className: 'text-center'
            },
            {
                data: 'closedWorkOrdersLast7And30Days',
                title: 'Closed Work Orders (7/30 days)',
                width: '15%',
                className: 'text-center',
                defaultContent: '',
                orderable: false,
                render: function (cellData, type, rowData) {
                  return `${rowData.closedWorkOrdersLast7Days} / ${rowData.closedWorkOrdersLast30Days}`;
                }
            },
            {
                data: 'newWorkOrdersLast7And30Days',
                title: 'New Work Orders (7/30 days)',
                width: '15%',
                className: 'text-center',
                defaultContent: '',
                orderable: false,
                render: function (cellData, type, rowData) {
                  return `${rowData.newWorkOrdersLast7Days} / ${rowData.newWorkOrdersLast30Days}`;
                }
            }
        ],
        rowCallback: function (row, data: any, dataIndex: number) {
            const detailElem = $(row).find('[action-type = link-detail]');
            $(detailElem).unbind('click');
            $(detailElem).on('click', function () {
              self.masterService.setWorkOrderShowPersonnalData(false);
              const workOrderFilterModel = new WorkOrderFilterModel();
              workOrderFilterModel.facilityIds = [data.facilityId];
              localStorage.setItem('filterModel', JSON.stringify(workOrderFilterModel));
              self.router.navigate(['work-order', 'grid-view', self.roleMatrix.companyId]);
            });
        },
        drawCallback: function () {
            if ($('.pagination li').length <= 5) {
                $('.pagination').hide();
            }
        }
    };
  }

  loadCompanyUsers() {
      //this.blockUI.start();
      this.userService.getCompanyUsersSelectItemFacilitywise(+this.roleMatrix.companyId)
          .subscribe((data: Array<any>) => {
             // this.blockUI.stop();
              this.companyUsers = data;
          }, error => {
            //  this.blockUI.stop();
              this.appUtils.ProcessErrorResponse(this.toastr, error);
          });
  }

  applyFilter() {
    this.dtInstance.ajax.reload();
  }

  resetFilter() {
    this.propertyManagerId = '';
    this.filterText = '';
    this.applyFilter();
  }
}
