<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-report-manage></app-breadcrumb-report-manage>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content" *blockUI="'loader-blockui'">
        <div class="col-md-12 p-0">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Reports
                        </h3>
                    </div>
               </div>
                <div class="kt-portlet__body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <select class="form-control" style="height:40px;" id="data" name="data" [(ngModel)]="reportQueryModel.datasource" (change)="onSelectDatasource($event.target.value)">
                                            <option value=0>[Data Source]</option>
                                            <option *ngFor="let datasource of datasources" [value]="datasource.id">{{datasource.name}}</option>
                                        </select>
                                    </div>
                                    
                                    <div class="col-md-3">
                                      <div class="mulidropdownColumn">
                                      <ng-multiselect-dropdown name="selectedDataColumns" class="datacolumn-dropdown" [placeholder]="'[Data Column]'"
                                          [(ngModel)]="selectedDataColumns"
                                          [settings]="multiSelectDropdownConfigs" 
                                          [data]="datacolumn" (onSelect)="onItemSelect($event)"
                                          (onSelectAll)="onSelectAll()" (onDeSelect)="onItemDeSelect($event)">
                                      </ng-multiselect-dropdown>
                                    </div>
                                  </div>
                                  
                                    <!-- calender -->
                                       <div class="form-group hidden">
                                          <div class="input-group">
                                            <input name="datepicker"
                                                   class="form-control"
                                                   ngbDatepicker
                                                   #datepicker="ngbDatepicker"
                                                   [autoClose]="'outside'"
                                                   [maxDate]="maxDate"
                                                   (dateSelect)="onDateSelection($event)"
                                                   [displayMonths]="2"
                                                   [dayTemplate]="t"
                                                   outsideDays="hidden"
                                                   [startDate]="fromDate!"
                                                   tabindex="-1">
                                            <ng-template #t let-date let-focused="focused">
                                              <span class="custom-day"
                                                    [class.focused]="focused"
                                                    [class.range]="isRange(date)"
                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                    [class.maxDate]="isUIDisabled(date)"
                                                    (mouseenter)="hoveredDate = date"
                                                    (mouseleave)="hoveredDate = null">
                                                    {{ date.day }}
                                              </span>
                                            </ng-template>
                                          </div>
                                        </div>
                                      
                                        <div class="form-group">
                                          <div class="input-group">
                                          <input #dpFromDate
                                                   class="form-control" placeholder="MM/DD/YYYY"
                                                   name="dpFromDate"
                                                   (keydown)="disableDate()"
                                                   [value]="formatter.format(fromDate)"
                                                   (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                            <div class="input-group-append">
                                              <span class="input-group-text cursor-pointer" (click)="datepicker.toggle()">
                                                <i class="la la-calendar"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <div class="form-group">
                                          <div class="input-group">
                                            <input #dpToDate
                                                   class="form-control" placeholder="MM/DD/YYYY"
                                                   name="dpToDate"
                                                   (keydown)="disableDate()"
                                                   [value]="formatter.format(toDate)"
                                                   (input)="toDate = validateInput(toDate, dpToDate.value)">
                                            <div class="input-group-append">
                                              <span class="input-group-text cursor-pointer" (click)="datepicker.toggle()">
                                                <i class="la la-calendar"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                     <hr>
                                     &nbsp;&nbsp;&nbsp;
                                    
                                    <div class="col-md-6">
                                      <button type="submit" [disabled]="!(selectedDataColumns.length > 0)" class="btn btn-brand btn-elevate btn-icon-sm"
                                        (click)="kpiReports()">
                                     <em class="la la-search"></em> Generate & Download
                                    </button>
                                    <button type="button" class="btn btn-default btn-elevate btn-icon-sm m-l-5" (click)="resetFields()">
                                        <em class="la la-close"></em> Reset
                                    </button>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

