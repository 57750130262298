import { UserInfoSynchronizer } from "src/synchronizers/user-info.synchronizer";

export function loadPreInfoFactory(userInfoSynchronizer: UserInfoSynchronizer) {
    return () => new Promise((resolve, reject) => {
            userInfoSynchronizer.loadUserInfo$()
                .subscribe(
                    data => resolve(undefined),
                    error => reject());
        }
    );
}
