<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-vendor-detail [companyId]="model.companyId"></app-breadcrumb-vendor-detail>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-md-12" *blockUI="'container-blockui'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Vendor Detail
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle"
                                data-toggle="dropdown">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                <ul class="kt-nav">
                                    <li class="kt-nav__item">
                                        <a [routerLink]="['/vendor/note',model.companyId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-sticky-note"></em>
                                            <span class="kt-nav__link-text">Notes</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canEditVendor">
                                        <a [routerLink]="['/vendor/edit',model.companyId, model.id]"
                                            class="kt-nav__link">
                                            <em class="kt-nav__link-icon fa fa-edit"></em>
                                            <span class="kt-nav__link-text">Edit</span>
                                        </a>
                                    </li>
                                    <li class="kt-nav__item" *ngIf="roleMatrix.canDeleteVendor">
                                        <a href="javascript:;" class="kt-nav__link" (click)="delete()">
                                            <em class="kt-nav__link-icon fa fa-trash"></em>
                                            <span class="kt-nav__link-text">
                                                Delete
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body" *ngIf="isModelLoaded">
                        <div class="form-group row">
                            <label class="col-md-2 kt-portlet__label">Profile Image:</label>
                            <div class="col-md-10">
                                <app-vendor-image-upload [model]='imageUploadModel'></app-vendor-image-upload>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Vendor Name:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.name}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Vendor Type:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.vendorTypeName}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Phone:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.phone}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Email:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.email}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Location:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.address}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Account Number:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.bankAccount}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">W-9 Number:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.w9Number}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Insurance Policy Name:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.insurancePolicyName}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Insurance Policy Number:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.insurancePolicyNumber}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Insurance Policy Expiry Date:</label>
                            <div class="col-10">
                                <p class="kt-portlet__data">{{model.insurancePolicyExpiryDate | date:'MM/dd/yyyy' }}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-2 kt-portlet__label">Facility Assigned:</label>
                            <div class="col-md-10">
                                <p class="kt-portlet__data">
                                    <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                                        *ngFor="let item of selectedFacilities">
                                        {{item.name}}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div class="form-group row"
                            *ngIf="model.insurancePolicyDocuments && model.insurancePolicyDocuments.length > 0">
                            <div class="col-12">
                                <span class="kt-font-bold">Attachments</span>
                                <div class="kt-divider m-t-10 m-b-0">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"
                            *ngIf="model.insurancePolicyDocuments && model.insurancePolicyDocuments.length">
                            <p class="kt-portlet__data">
                                <a *ngFor="let item of model.insurancePolicyDocuments; let $index = index"
                                    [href]="item.fileUrl" target="_blank">
                                    <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5">
                                        {{item.originalFileName}}
                                    </span>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div class="kt-portlet__foot d-none">
                        <div class="kt-form__actions text-right">
                            <a [routerLink]="['/vendor/manage',model.companyId]"
                                class="btn btn-secondary m-r-5">Back</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-6" *ngIf="isModelLoaded">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Notes
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button type="button" class="btn btn-label-primary btn-sm btn-bold m-r-5"
                                *ngIf="roleMatrix.canEditVendor" (click)="openVendorNoteModal()">
                                Add Note
                            </button>
                            <a [routerLink]="['/vendor/note',model.companyId, model.id]"
                                class="btn btn-label-primary btn-sm btn-bold" *ngIf="model.notes">
                                View All
                            </a>
                        </div>
                    </div>
                    <div class="kt-portlet__body" data-scroll="true" data-height="300" data-mobile-height="200">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of model.notes; let $index=index">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="word-wrap">{{item.note}}</p>
                                        <p class="badge badge-light m-b-5">
                                            <span>{{item.createdByName}}</span>
                                            ({{item.createdOn}})</p>
                                    </div>
                                </div>
                                <div class="row" *ngIf="(model.notes.length - 1) > $index">
                                    <div class="col-12">
                                        <div class="kt-divider m-t-10 m-b-10">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="!model.notes || model.notes.length == 0">No note found</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<app-vendor-note-add #addVendorNote [vendorId]="model.id" (reloadNotes)="loadVendor()">
</app-vendor-note-add>