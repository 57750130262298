<ng-template #userDeleteConfirmationModal let-modal>
    <div *blockUI="'container-blockui-delete-popup'">
        <div class="modal-header">
            <h5 class="modal-title">Confirm Delete</h5>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group">
                    {{deleteConfirmationMessage}}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="close()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="deleteLastUser()">Update</button>
            </div>
        </div>
    </div>
</ng-template>