import { Component, ViewChild, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyService, UserService } from '../../../services';
import { CompanyDetailModel } from '../../../models';
import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-company-detail',
    templateUrl: './company.detail.component.html'
})
export class CompanyDetailComponent implements OnInit {
    @HostBinding('class.full_width')
    full_width = true;

    @BlockUI('container-blockui')
    blockUI: NgBlockUI;

    @ViewChild('companyChallengeDeleteModal', { static: true })
    companyChallengeDeleteModal: any;

    roleMatrix: RoleMatrix;
    model: CompanyDetailModel = new CompanyDetailModel();
    isModelLoaded = false;

    accountOwner: { id: string; isFollowing: boolean };
    showFollowButton: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private companyService: CompanyService,
        private userService: UserService) {

        this.roleMatrix = new RoleMatrix();
        this.route.params.subscribe(params => this.model.id = params['id']);

        this.accountOwner = { id: null, isFollowing: false };
        this.showFollowButton = false;

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.id.toString()) {
            this.router.navigate(['/error/404']);
        }
    }

    ngOnInit(): void {
        this.blockUI.start();
        this.companyService.getDetail(this.model.id)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.model, data);
                    if (this.model.address && this.model.zipCode) {
                        this.model.address = this.model.address + ', ' + this.model.zipCode;
                    }
                    if (this.model.status === 3) {
                        this.roleMatrix.configureAllowActionsForCompany();
                    }
                    this.isModelLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });

        if  (this.roleMatrix.is10FedUser) {
            this.showFollowButton = false;
        } else {
            this.blockUI.start();
            this.userService.getCompanyAccountOwner<any>(this.model.id)
                    .subscribe(data => {
                        this.blockUI.stop();
                        this.accountOwner = {
                            id: data.id,
                            isFollowing: data.isFollowing
                        };
                        this.showFollowButton = this.appUtils.getUserId().toLowerCase() !== this.accountOwner.id.toLowerCase();
                    }, error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
        }
    }


    toggleStatus(): void {
        this.blockUI.start();
        this.companyService.toggleStatus(this.model.id).subscribe(
            () => {
                this.blockUI.stop();
                this.model.status = this.model.status === 3 ? 2 : 3;
                this.toastr.success(`Company has been ${(this.model.status === 2 ? 'activated' : 'deactivated')} successfully.`);
                if (this.model.status === 3) {
                    this.roleMatrix.configureAllowActionsForCompany();
                } else {
                    this.roleMatrix = new RoleMatrix();
                }
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    challengeDelete(): void {
        this.companyChallengeDeleteModal.openCompanyDeleteConfirmationModal(this.model.id, this.model.name);
    }

    delete() {
        this.blockUI.start();
        this.companyService.delete(this.model.id)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/company/manage']);
                    });
                    setTimeout(() => {
                        this.toastr.success('Company has been deleted successfully.');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    followUser() {
        this.blockUI.start();
        this.accountOwner.isFollowing = !this.accountOwner.isFollowing;
        this.userService.followCompanyUser(this.accountOwner.id, this.accountOwner.isFollowing)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success(this.accountOwner.isFollowing
                        ? 'You have started following the account owner.'
                        : 'You have unfollowed the account owner.');
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
