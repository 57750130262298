export class Notification {
    id: string;
    notificationType: string;
    content: string;
    entityId: string;
    createdOn: string;
    action: string;
    isRead: boolean;
    currentDateTime: string;
}
