import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router,ActivatedRoute } from '@angular/router';
import { RoleMatrix } from '../../../helpers';
import { KpiReportService } from '@10fed/services/kpi-report.service';
import { JiveAddRefreshTokenModel } from "src/models/jive-communication/jive.addrefreshtoken.model";
import { AppUtils } from 'src/helpers';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-jive-callback',
    templateUrl: './jive.callback.component.html'
})

export class JiveCallBackComponent implements OnInit{
    roleMatrix: RoleMatrix;
    model:JiveAddRefreshTokenModel = new JiveAddRefreshTokenModel();

    constructor(private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private kpiReportService: KpiReportService,
        private appUtils: AppUtils)
        {
            this.roleMatrix = new RoleMatrix();
            this.route.queryParams
                .subscribe(params => {
                        this.model.code = params.code;   
                        this.model.internalCompanyId = parseInt(this.roleMatrix.companyId);  
                        });
        }

    
    ngOnInit() {
        this.addJiveRefreshToken();
    }

    addJiveRefreshToken(){
        if(this.model.code != '' || this.model.code != null || this.model != undefined)
        {
            this.kpiReportService.addJiveRefreshToken(this.model)
                .subscribe(
                    ()=>{
                        this.toastr.success("Refresh Token Add Successfully...!!!");
                        this.router.navigate(['/storedge/intergration/'+this.roleMatrix.companyId]);
                    },
                    error => {
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    }
                );
        }

        this.router.navigate(['']);
    }
}