import { StoredgeFacilitySyncModel } from './storedge.facility.sync.model';

export class SyncModel {
    public internalCompanyId: number;
    public storedgeCompanyId: string;

    public facilities: Array<StoredgeFacilitySyncModel>;

    constructor() {
        this.facilities = new Array<StoredgeFacilitySyncModel>();
    }
}
