import { SubTasksService } from '@10fed/services/sub-tasks.service';
import { UnitService } from '@10fed/services/unit.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils, WorkOrderStatus, WoSubTaskState } from 'src/helpers';
import { UnitDetailModel, WorkOrderDetailModel } from 'src/models';
import { WoSubTaskDto } from 'src/models/work-order/sub-tasks/work.order.sub.task.model';
import { AddSubTaskComponent } from '../add/add-sub-task.component';
import { EditSubTaskComponent } from '../edit/edit-sub-task.component';

@Component({
  selector: 'app-manage-sub-tasks',
  templateUrl: './manage-sub-tasks.component.html'
})
export class ManageSubTasksComponent implements OnInit {

  @Input()
  workOrder: WorkOrderDetailModel;

  @Output()
  refresh = new EventEmitter<string>();

  @BlockUI('container-blockui') blockUI: NgBlockUI;

  @ViewChild('subTaskList') subTaskListEl:ElementRef;

  subTasks: WoSubTaskDto[];

  units: UnitDetailModel[];

  get WorkOrderStatuses() {
    return WorkOrderStatus;
  }

  get WoSubTaskStates() {
    return WoSubTaskState;
  }

  isNewSubTaskAdded: boolean = false;

  constructor(private modalService: NgbModal,
              private subTasksService: SubTasksService,
              private unitService: UnitService,
              private toastr: ToastrService,
              private appUtils: AppUtils) { }

  ngOnInit() {
    this.loadSubTasks();
    this.loadUnits();
  }

  addSubTask() {
    const modal = this.modalService.open(AddSubTaskComponent, {
      size: 'lg'
    });

    const comp = (modal.componentInstance as AddSubTaskComponent);
    comp.workOrderId = this.workOrder.id;
    comp.workOrderUnitId = this.workOrder.unitId;
    comp.units = this.units;

    modal.result
      .then((subTaskDto) => {
        if (subTaskDto) {
          this.subTasks = [subTaskDto, ...this.subTasks];
          setTimeout(() => {
            this.isNewSubTaskAdded = true;
            this.refresh.emit();
            this.subTaskListEl.nativeElement.scroll(0,0);
          }, 100);
        }
      })
      .catch(() => {});
  }

  editSubTask(subTask: WoSubTaskDto, index: number) {
    const modal = this.modalService.open(EditSubTaskComponent, {
      size: 'lg'
    });

    const comp = (modal.componentInstance as EditSubTaskComponent);
    comp.subTask = subTask;
    comp.workOrderUnitId = this.workOrder.unitId;
    comp.units = this.units;

    modal.result
      .then((subTaskDto) => {
        if (subTaskDto) {
          this.subTasks[index] = subTaskDto;
          setTimeout(() => {
            this.refresh.emit();
          }, 100);
        }
      })
      .catch(() => {});
  }

  deleteSubTask(subTaskId: number, index: number) {
    if (!confirm('Are you sure you want to delete the selected sub task?')) {
      return;
    }

    this.blockUI.start();
    this.subTasksService.deleteSubTask(subTaskId).subscribe(
      data => {
        this.blockUI.stop();
        this.subTasks.splice(index, 1);
        this.toastr.success('Sub task has been deleted successfully.');
        setTimeout(() => {
          this.refresh.emit();
        }, 100);
      },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      }
    );
  }

  updateSubTaskState(subTask: WoSubTaskDto, state: WoSubTaskState) {
    this.blockUI.start();
    this.subTasksService.updateSubTaskState(subTask.id, state).subscribe(
      data => {
        this.blockUI.stop();
        subTask.state = state;
        this.toastr.success(`Sub task has been ${subTask.state === WoSubTaskState.Open ? 're-opened' : 'closed'}.`);
        setTimeout(() => {
          this.refresh.emit();
        }, 100);
      },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.refreshData();
      }
    );
  }

  toggleSubTaskState(subTask: WoSubTaskDto) {
    const state = subTask.state === WoSubTaskState.Open
      ? WoSubTaskState.Closed
      : WoSubTaskState.Open;

    this.updateSubTaskState(subTask, state);
  }

  refreshData() {
    this.loadSubTasks();
  }

  private loadSubTasks() {
    this.blockUI.start();
    this.subTasksService.getSubTasks(this.workOrder.id, !this.isNewSubTaskAdded)
      .subscribe(subTasks => {
        this.blockUI.stop();
        this.subTasks = subTasks;
      }, error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  private loadUnits() {
    this.blockUI.start();
    this.unitService.getSelectListItems(this.workOrder.facilityId).subscribe(
      (data: any) => {
          this.blockUI.stop();
          this.units = data;

          const unitToRemove = this.units.find(x => x.name === 'Default');
          const index = this.units.indexOf(unitToRemove);
          if (index !== -1 && !this.workOrder.createdAutomatically) {
              this.units.splice(index, 1);
          }
      },
      error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }

  sortSubTaskByUnit(){
    this.isNewSubTaskAdded = false;
    this.subTaskListEl.nativeElement.scroll(0,0);
    this.refreshData();
  }
}
