import { StoredgeUnitSyncModel } from './storedge.unit.sync.model';

export class StoredgeFacilitySyncModel {
    public internalId: string;
    public storedgeId: string;
    public status: number;

    public propertyManagerUserId: string;
    public propertyManagerName: string;
    public propertyManagerEmail: string;
    public propertyManagerPhone: string;
    public typeOfConstruction: string;
    public climateControlled: boolean;
    public clientUserGroupsAssigned: Array<string>;
    public clientUsersAssigned: Array<string>;
    public defaultAssigneeId: string;
    public complaintsAssigneeId: string;
    public moveOutAssigneeId: string;

    public units: Array<StoredgeUnitSyncModel>;

    constructor() {
        this.units = new Array<StoredgeUnitSyncModel>();
        this.complaintsAssigneeId = '';
        this.moveOutAssigneeId = '';
    }
}
