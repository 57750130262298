import { VendorInsurancePolicyDocumentModel } from "..";

export class VendorEditModel {
    public id: number;
    public companyId: number;
    public name: string;
    public vendorTypeId: string;
    public phone: string;
    public email: string;
    public address: string;
    public bankAccount: string;
    public status: number;
    public assignedFacilities: Array<number>;
    public w9Number: string;
    public insurancePolicyName: string;
    public insurancePolicyNumber: string;
    public insurancePolicyExpiryDate: string;
    public insurancePolicyDocuments: Array<VendorInsurancePolicyDocumentModel>;
    constructor() {
        this.insurancePolicyDocuments = new Array<VendorInsurancePolicyDocumentModel>();
    }
}
