<div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl notification-dropdown overflow-visible"
    ngbDropdownMenu>
    <div *ngIf="!notifications || notifications.length == 0" class="kt-head kt-head--skin-light kt-head--fit-x">
        <h3 class="kt-head__title">
            <span class="btn btn-label-primary btn-sm btn-bold btn-font-md">No new notifications.</span>
        </h3>
    </div>
    <div class="row" *ngIf="totalUnreadNotifications > 0">
        <div class="col-12 text-center kt-notification">
            <div class="pt-3 pb-3">
                <button class="btn btn-outline-primary btn-wide btn-sm" (click)="markAllAsRead()">Mark all as Read</button>
            </div>
        </div>
    </div>
    <div class="kt-notification kt-margin-b-10 kt-scroll notification-menu" data-scroll="true"
        data-height="400" data-mobile-height="250" *blockUI="'container-notification-blockui'"
        [ngClass]="{ 'kt-margin-t-10': totalUnreadNotifications === 0 }">
        <div *ngFor="let item of notifications; let $index = index">
            <div class="row">
                <div class="col-10">
                    <div class="kt-notification__item after-none" [ngClass]="{'cursor-pointer' : item.action}"
                        (click)="doAction(item)">
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-content" [ngClass]="{' kt-font-bolder' : !item.isRead}"
                                [innerHTML]="item.content | safeHtml">
                            </div>
                            <div class="kt-notification__item-time m-t-2">
                                {{item.createdOn}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-2 text-right">
                    <div ngbDropdown>
                        <a class="btn btn-sm btn-clean btn-icon btn-icon-md m-t-5" ngbDropdownToggle></a>
                        <div ngbDropdownMenu class="dropdown-menu-notfication-action">
                            <button ngbDropdownItem (click)="markAsRead(item)" *ngIf="!item.isRead">
                                Mark as read
                            </button>
                            <button ngbDropdownItem (click)="removeNotification(item, $index)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-broder-bottom" *ngIf="totalPages > 1 && pageNumber < totalPages">
            <div class="col-12 text-center kt-margin-t-10">
                <button class="btn btn-outline-primary btn-wide btn-sm" (click)="loadMoreNotifications()">Load More</button>
            </div>
        </div>
    </div>
</div>
