<div class="modal-header">
      <h5 class="modal-title">{{title ? title : 'Information'}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="col-md-12" style="white-space: pre-wrap; word-wrap: break-word;">{{info}}</div>
  </div>
  <div class="modal-footer">
      <div class="col-md-12 text-right">
          <button type="button" 
            class="btn btn-default m-r-5"
            (click)="activeModal.close()">
            Close
          </button>
      </div>
  </div>
  