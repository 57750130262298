import { Component, HostBinding, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { DataTableResponseModel, FacilityFilterModel } from '../../../models';
import { FacilityService, CompanyService, MasterService } from '../../../services';
import { AppUtils, RoleMatrix, Roles } from '../../../helpers';
import { isNullOrUndefined } from  '../../../helpers/tools';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-facility-manage',
    templateUrl: './facility.manage.component.html'
})
export class FacilityManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    @ViewChild('facilityChallengeDeleteModal', { static: true }) facilityChallengeDeleteModal: any;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    gridState: any;
    gridStateName = 'gridStateFacilities';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: FacilityFilterModel = new FacilityFilterModel();
    showActionColumn = false;
    facilityIdToDelete: number;
    deletingDataIndex: number;
    showPersonalData: boolean;
    subscriptions: Subscription[] = [];

    companyId = -1;

    get roles() {
        return Roles;
    }

    constructor(private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private companyService: CompanyService,
        private masterService: MasterService,
        private appUtils: AppUtils) {

        this.masterService.FacilityhowPersonnalData$
            .pipe(take(1))
            .subscribe(showPersonalData => this.showPersonalData = showPersonalData);

        this.roleMatrix = new RoleMatrix();

        this.route.params.subscribe((params) => {
            this.companyId = params['id'];
            this.filterModel.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        if (this.roleMatrix.canEditFacility
            || this.roleMatrix.canChangeStatusOfFacility
            || this.roleMatrix.canDeleteFacility) {
            this.showActionColumn = true;
        }

        const subscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/facility/detail/') !== -1
                    || event.url.indexOf('/facility/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
            if (event instanceof NavigationEnd) {
                let gridState: any = localStorage.getItem(this.gridStateName);
                if (!gridState) {
                    return;
                }
                gridState = JSON.parse(gridState);
                this.rowIndex = gridState.start;
                this.pageLength = gridState.length;

                if (gridState.status) {
                    this.filterModel.status = gridState.status;
                }
                if (gridState.filterKey) {
                    this.filterModel.filterKey = gridState.filterKey;
                }
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit(): void {

        const self = this;
        this.checkCompanyStatus();

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter facilities...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            searching: false,
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[0, 'asc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                } else {
                    dataTablesParameters.companyId = self.filterModel.companyId;
                    dataTablesParameters.status = self.filterModel.status;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                dataTablesParameters.considerOnlyPersonalData = this.showPersonalData;

                self.http.post<DataTableResponseModel>
                    (`${environment.apiBaseUrl}facility/paged-result`, dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Facility Name',
                    width: '20%',
                    render: function (data) {
                        return `<span class='link' action-type='view-detail'>${data}</a>`;
                    }
                },
                {
                    data: 'storeNumber',
                    title: 'Store Number',
                    width: '15%'
                },
                {
                    data: 'propertyManagerName',
                    title: 'Property Manager',
                    width: '20%'
                },
                {
                    data: 'totalUnits',
                    title: 'Units Associated',
                    width: '20%'
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: self.showActionColumn,
                    render: function (data, type, row) {
                        const statusHtml =
                            row.status === 2
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === 3
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '';

                        const htmlString = (self.roleMatrix.canChangeStatusOfFacility ? statusHtml : '')
                            + (self.roleMatrix.canEditFacility && row.status !== 3
                                ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteFacility
                                ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');

                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/facility/edit', self.companyId, data.id]);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.challengeDelete(data.id, data.name, dataIndex);
                });

                const detailElem = $(row).find('[action-type = view-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/facility/detail', self.companyId, data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            },
            stateSaveParams: function (settings, data: any) {
                data.status = self.filterModel.status;
                data.filterKey = self.filterModel.filterKey;
            },
            stateLoadParams: function (settings, data: any) {
                if (data.status) {
                    self.filterModel.status = data.status;
                }
                if (data.filterKey) {
                    self.filterModel.filterKey = data.filterKey;
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.facilityService.toggleStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.toastr.success(`Facility has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    challengeDelete(id: number, facilityName: string, dataIndex: number): void {
        this.facilityIdToDelete = id;
        this.deletingDataIndex = dataIndex;
        this.facilityChallengeDeleteModal.openFacilityDeleteConfirmationModal(id, facilityName);
    }

    delete(): void {
        this.blockUI.start();
        this.facilityService.delete(this.facilityIdToDelete).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (this.deletingDataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                });
                this.toastr.success('Facility has been deleted successfully.');
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    doFilter() {
        this.dtInstance.ajax.reload();
    }

    resetFilter() {
        this.filterModel.status = '';
        this.filterModel.filterKey = '';
        this.doFilter();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.roleMatrix.configureAllowActionsForFacility();
                    this.showActionColumn = this.roleMatrix.canEditFacility || this.roleMatrix.canChangeStatusOfFacility;
                    setTimeout(() => {
                        if (!isNullOrUndefined(this.dtInstance)) {
                            this.dtInstance.column(5).visible(this.showActionColumn);
                        }
                    }, 100);
                }
            },
            error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }
        );
    }

    showPersonalDataChanged() {
        this.masterService.setFacilityShowPersonnalData(this.showPersonalData);
        this.dtInstance.ajax.reload();
    }
}
