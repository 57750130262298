<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                Dashboard
            </h3>
            <span class="kt-subheader__separator kt-hidden"></span>
        </div>
        <!-- <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper d-flex align-items-center" *ngIf="isAdmin">
                <label class="fs-md">All Data</label>
                <label class="switch switch-sm ml-2 mr-2">
                    <input type="checkbox" [(ngModel)]="showPersonalData" class="primary"
                        (change)="showPersonalDataChanged()" />
                    <span class="slider slider-sm round"></span>
                </label>
                <label class="fs-md">Facility Filtered Data</label>
            </div>
        </div> -->
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <app-dashboard-company-user [companyId]="roleMatrix.companyId" *ngIf="!roleMatrix.is10FedUser">
                </app-dashboard-company-user>
            </div>
        </div>
    </div>
</div>