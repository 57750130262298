import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from '../../../helpers';
import {
    StoredgeConfigurationModel, StoredgeFacilityPreview,
    SyncSingleModel, StoredgeFacilitySyncModel, StoredgeUnitSyncModel
} from '../../../models';
import { StoredgeService } from '../../../services';
import { isNullOrUndefined } from '../../../helpers/tools';

@Component({
    selector: 'app-storedge-preview-facility',
    templateUrl: './storedge.preview.facility.component.html'
})

export class StoredgePreviewFacilityComponent implements OnInit, OnDestroy {
    @ViewChild('storedgeConfirmImport', { static: true }) storedgeConfirmImport: any;
    @ViewChild('storedgeVerifyFacility') storedgeVarifyFacility: any;
    @BlockUI('container-blockui-preview') blockUI: NgBlockUI;
    @Input() model: StoredgeConfigurationModel;

    isModelLoaded = false;
    selectAllFacilities: boolean;
    isSynching = false;
    isImporting = false;
    pbMaxVal = 0;
    pbCurrentVal = 0;
    readyToImport = false;
    totalFacilities = 0;

    facilities = new Array<StoredgeFacilityPreview>();
    seletedFacilities = new Array<StoredgeFacilityPreview>();

    syncSingleModel: SyncSingleModel = new SyncSingleModel();

    constructor(private toastr: ToastrService,
        private storedgeService: StoredgeService,
        private appUtils: AppUtils) { }

    ngOnInit() {
        this.loadFacilities();
    }

    ngOnDestroy() {
        this.blockUI.stop();
    }

    loadFacilities() {
        this.isModelLoaded = false;
        this.blockUI.start();
        this.facilities = new Array<StoredgeFacilityPreview>();
        this.storedgeService.getFacilities(this.model.storedgeCompanyId, this.model.internalCompanyId)
            .subscribe(
                (data) => {
                    this.blockUI.stop();

                    Object.assign(this.facilities, data);

                    this.facilities.map((facility) => {

                        if (facility.status === 3) {
                            facility.isSelected = true;
                        }

                        if (!isNullOrUndefined(facility.lastUpdated)) {
                            facility.lastUpdated = this.appUtils.getFormattedDateTime(facility.lastUpdated);
                        }

                        facility.units.map((unit) => {

                            if (unit.status === 3) {
                                unit.isSelected = true;
                            }

                            if (!isNullOrUndefined(unit.lastUpdated)) {
                                unit.lastUpdated = this.appUtils.getFormattedDateTime(unit.lastUpdated);
                            }

                        });
                    });
                    this.totalFacilities = this.facilities.length;
                    this.isModelLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                }
            );
    }

    onSelectAllFacilitiesChanged() {

        this.facilities.map((facility) => {

            if (facility.status !== 3) {
                facility.isSelected = this.selectAllFacilities;
            }

            facility.selectAllUnits = this.selectAllFacilities;

            facility.units.map((unit) => {
                if (unit.status === 3) {
                    return;
                }

                unit.isSelected = this.selectAllFacilities;
            });

        });
    }

    onFacilityChanged(facility: StoredgeFacilityPreview) {
        facility.selectAllUnits = facility.isSelected;
        facility.units.map((unit) => {
            unit.isSelected = facility.isSelected;
        });
        if (!facility.isSelected) {
            this.selectAllFacilities = false;
        }
    }

    synchronize() {
        this.toastr.info('Synchronization has been started. It may take minutes, please wait till the process get completed');
        const failedIds = new Array<string>();
        this.blockUI.start();
        this.storedgeService.synchronizeFacilities(this.model.internalCompanyId)
            .subscribe(
                async (data: Array<string>) => {
                    if (data.length === 0) {
                        this.toastr.info('No record found to sync');
                        return;
                    }

                    this.pbMaxVal = data.length;
                    this.pbCurrentVal = 0;
                    this.isSynching = true;
                    this.isImporting = false;

                    for (const facilityId of data) {
                        try {
                            await this.synchronizeUnits(facilityId)
                                .then(() => {
                                    this.pbCurrentVal += 1;
                                }).catch(error => {
                                    this.toastr.error(error);
                                    failedIds.push(facilityId);
                                });
                        } catch (e) {
                            console.log('Unit synchronization failed for facility: ' + facilityId);
                            console.log(e);
                        }
                    }

                    this.isSynching = false;
                    this.blockUI.stop();

                    setTimeout(() => {
                        this.loadFacilities();
                        this.readyToImport = false;
                        this.toastr.success('Facilities and units has been synchronized successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error, 'Unable to sync company facility. Please try agrain later.');
                }
            );
    }

    async synchronizeUnits(facilityId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storedgeService.synchronizeUnits(facilityId)
                .subscribe(
                    () => {
                        resolve(true);
                    },
                    error => {
                        // this.appUtils.ProcessErrorResponse(this.toastr, error);
                        reject(error);
                    }
                );
        });
    }

    verifyDetail() {
        this.seletedFacilities.splice(0, this.seletedFacilities.length);
        for (const facility of this.facilities) {
            const unitsforImport = facility.units.filter(unit => {
                if (unit.isSelected && unit.status !== 3) {
                    return unit;
                }
            });
            if (!facility.isSelected || facility.status === 3 && unitsforImport.length === 0) {
                continue;
            }
            this.seletedFacilities.push(facility);
        }

        if (this.seletedFacilities.length > 0) {
            this.readyToImport = true;
        } else {
            this.toastr.error('Please select facility to continue');
        }
    }

    goBackToPreview() {
        this.readyToImport = false;
        if (this.facilities.filter(facility => facility.isSelected).length !== this.facilities.length) {
            this.selectAllFacilities = false;
        }
        this.facilities.forEach(facility => {
            if (facility.selectAllUnits) {
                const selectedUnits = facility.units.filter(unit => {
                    if (unit.isSelected) {
                        return unit;
                    }
                });
                if (selectedUnits.length !== facility.units.length) {
                    facility.selectAllUnits = false;
                }
            }
            facility.isOpen = false;
        });
    }

    async initImport() {
        this.verifyDetail();
        const incompletedFacilities = new Array<string>();
        this.seletedFacilities.forEach((facility: StoredgeFacilityPreview) => {
            if (facility.modelState !== 2) {
                incompletedFacilities.push(facility.name);
            }
        });

        if (incompletedFacilities.length === 0) {
            await this.import();
        } else {
            this.storedgeConfirmImport.openImportConfirmationModal(incompletedFacilities);
        }
    }

    async import() {
        this.blockUI.start();

        this.syncSingleModel.internalCompanyId = this.model.internalCompanyId;
        this.syncSingleModel.storedgeCompanyId = this.model.storedgeCompanyId;

        let counter = 1;
        let isAnyFacilityImportFailed = false;

        this.pbMaxVal = 0;
        this.pbCurrentVal = 0;
        this.isSynching = false;
        this.isImporting = true;

        this.storedgeVarifyFacility.facilityImportingStart();

        // get facility count to be imported
        for (const facility of this.facilities) {

            const unitsforImport = facility.units.filter(unit => {
                if (unit.isSelected && unit.status !== 3) {
                    return unit;
                }
            });

            if (!facility.isSelected || facility.status === 3 && unitsforImport.length === 0) {
                continue;
            }

            this.pbMaxVal += 1;
        }

        // import facilities
        for (const facility of this.facilities) {

            const unitsforImport = facility.units.filter(unit => {
                if (unit.isSelected && unit.status !== 3) {
                    return unit;
                }
            });

            if (!facility.isSelected || facility.status === 3 && unitsforImport.length === 0) {
                continue;
            }

            const syncFacilityModel = new StoredgeFacilitySyncModel();

            syncFacilityModel.internalId = facility.id;
            syncFacilityModel.storedgeId = facility.storedgeId;
            syncFacilityModel.propertyManagerUserId = facility.propertyManagerUserId;
            syncFacilityModel.propertyManagerName = facility.propertyManagerName;
            syncFacilityModel.propertyManagerEmail = facility.propertyManagerEmail;
            syncFacilityModel.propertyManagerPhone = facility.propertyManagerPhone;
            syncFacilityModel.typeOfConstruction = facility.typeOfConstruction;
            syncFacilityModel.climateControlled = facility.climateControlled;
            syncFacilityModel.clientUserGroupsAssigned = facility.clientUserGroupsAssigned;
            syncFacilityModel.clientUsersAssigned = facility.clientUsersAssigned;
            syncFacilityModel.defaultAssigneeId = facility.defaultAssigneeId;
            syncFacilityModel.moveOutAssigneeId = facility.moveOutAssigneeId;
            syncFacilityModel.complaintsAssigneeId = facility.complaintsAssigneeId;

            syncFacilityModel.status = facility.status;
            syncFacilityModel.units = new Array<StoredgeUnitSyncModel>();

            facility.units.map((unit) => {
                if (!unit.isSelected || unit.status === 3) {
                    return;
                }

                const syncUnitModel = new StoredgeUnitSyncModel();
                syncUnitModel.internalId = unit.id;
                syncUnitModel.storedgeId = unit.storedgeId;
                syncFacilityModel.units.push(syncUnitModel);
            });

            this.syncSingleModel.facility = syncFacilityModel;
            try {
                await this.importSingle().then(() => {
                    this.pbCurrentVal += 1;
                }).catch(error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                    isAnyFacilityImportFailed = true;
                });
            } catch (e) {
                console.log(e);
            }

            counter += 1;
        }

        this.isImporting = false;
        this.storedgeVarifyFacility.facilityImportingEnd();
        this.blockUI.stop();

        if (counter > 1 && !isAnyFacilityImportFailed) {
            this.seletedFacilities.splice(0, this.seletedFacilities.length);
            this.readyToImport = false;
            this.toastr.success('Selected facilities has been imported successfully');
            setTimeout(() => {
                this.loadFacilities();
            }, 100);
        } else if (isAnyFacilityImportFailed) {
            this.toastr.error('Some facilities are not imported. Please try again');
        } else {
            this.toastr.error('Please select facility to continue');
        }
    }

    async importSingle(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storedgeService.importSingle(this.syncSingleModel)
                .subscribe(
                    () => {
                        resolve(true);
                    },
                    error => {
                        console.log(error);
                        //   this.appUtils.ProcessErrorResponse(this.toastr, error);
                        reject(error);
                    }
                );
        });
    }
}
