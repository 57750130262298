import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from '../../../models';

@Component({
    selector: 'app-activity-log-unit-activities',
    templateUrl: './activity.log.unit.activities.component.html'
})

export class ActivityLogUnitActivitiesComponent implements OnInit {
    @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

    ngOnInit() {
        this.model.entityLink = '/unit/detail/' + this.model.companyId + '/' + this.model.parentEntityId + '/' + this.model.entityId;
        this.model.parentEntityLink = '/facility/detail/' + this.model.companyId + '/' + this.model.parentEntityId;
    }
}
