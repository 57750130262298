import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { AppSettings } from '../helpers';

@Injectable()
export class ActivityLogService {
    constructor(private http: HttpClient,
        private appSettings: AppSettings) { }

    getList(offset: number, limit: number) {
        return this.http.get(environment.apiBaseUrl + 'activityLog/get-list?offset=' + offset + '&limit=' + limit);
    }

}
