import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from 'src/models';

@Component({
  selector: 'app-activity-log-company-activities',
  templateUrl: './activity.log.company.activities.component.html'
})
export class ActivityLogCompanyActivitiesComponent implements OnInit {
  @Input('model') model: ActivityLogDetailModel = new ActivityLogDetailModel();

ngOnInit() {
     this.model.entityLink = '/company/detail/' + this.model.companyId;
    }
}

