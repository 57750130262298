import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppSettings } from './app.settings';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = localStorage.getItem('AuthToken');
        if (token && (AppSettings.NonBearerUrl === '' || !request.url.includes(AppSettings.NonBearerUrl))) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request)
            .pipe(catchError(response => {
                if (response.status === 401) {
                    setTimeout(() => {
                        this.router.navigate(['/account/logout']);
                    }, 100);
                } else if (response.status === 404) {
                    setTimeout(() => {
                        this.router.navigate(['/error/404']);
                    }, 100);
                }
                throw response;
            }));
    }
}
