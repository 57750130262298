import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-datasource-manage',
  templateUrl: './breadcrumb.datasource.manage.component.html'  
})
export class BreadcrumbDataSourceManageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
