import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-storedge-record-status',
    templateUrl: './storedge.record.status.component.html'
})

export class StoredgeRecordStatusComponent implements OnInit {
    @Input() status: any;

    ngOnInit() {

    }
}

