import { Component, HostBinding, ViewChild, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as moment from 'moment';
import {
    SelectListItemModel, AssignedUserModel, WorkOrderPhotoModel, WorkOrderAddModel,
    WorkOrderRecurrenceModel, CategorySelectListItemModel, AssignedUserGroupModel, AssigneeSelectListItemModel
} from '../../../models';

import { FacilityService, UnitService, WorkOrderService, CloudStorageService, CompanyService } from '../../../services';

import { AppUtils, RoleMatrix, AppSettings, CategoryType, WorkOrderAssigneeType } from '../../../helpers';
import { Guid } from 'guid-typescript';
import { UploadParams, UploadConfig, BlobService } from 'angular-azure-blob-service';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { isNullOrUndefined } from '../../../helpers/tools';
import { FormBuilder, FormGroup, NgForm, NgModel } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-work-order-add',
    templateUrl: './work.order.add.component.html'
})
export class WorkOrderAddComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('workOrderPhoto') workOrderPhoto: ElementRef;
    @ViewChild('workOrderRecurrenceModal', { static: true }) workOrderRecurrenceModal: any;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    multiSelectDropdownConfigs: IDropdownSettings;

    companyId = -1;
    viewType = 1;

    selectedCategory: CategorySelectListItemModel;
    workOrderCategories: Array<CategorySelectListItemModel> = new Array<CategorySelectListItemModel>();

    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    units: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    facilityUsers: Array<AssignedUserModel> = new Array<AssignedUserModel>();
    facilityGroups: Array<AssignedUserGroupModel> = new Array<AssignedUserGroupModel>();
    workOrderAssignees: Array<AssigneeSelectListItemModel> = Array<AssigneeSelectListItemModel>();
    facilityWatchers: Array<AssignedUserModel> = new Array<AssignedUserModel>();
    selectedAssignee: AssigneeSelectListItemModel;

    selectedClientWatchers: SelectListItemModel[] = [];

    oldAssigneeId: string;
    oldFollowerId: string;
    oldFollowerInstruction: string;
    model: WorkOrderAddModel = new WorkOrderAddModel();
    note = '';
    uploadedPercent = 0;
    currentUserId: string;
    isSelectedCategoryUnrentable: boolean;
    isRecurringWOAllowed: boolean = true;
    isUnitSelectAllowed: boolean = true; 

    minDueDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };

    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private unitService: UnitService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils,
        private cloudStorageService: CloudStorageService,
        private appSettings: AppSettings,
        private blobService: BlobService,
        private companyService: CompanyService,
        private cd: ChangeDetectorRef) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddWorkOrder) {
            this.router.navigate(['/error/403']);
        }
        this.route.params.subscribe((params) => {
            this.viewType = params['viewType'];
            this.companyId = params['id'];
            this.model.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'fullName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            noDataAvailablePlaceholderText: '[Select]'
        };

        this.currentUserId = this.appUtils.getUserId();
    }

    ngOnInit() {
        this.checkCompanyStatus();
        this.loadFacilities();
        this.loadWorkOrderCategories();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.router.navigate(['/error/404']);
                }
            }
        );
    }

    loadWorkOrderCategories() {
        this.workOrderService.getCategorySelectListItems(this.companyId, false, false)
            .subscribe(
                (data: any) => {
                    Object.assign(this.workOrderCategories, data);
                    this.workOrderCategories = this.workOrderCategories.filter(c => c.type !== CategoryType.Reminder);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFacilities() {
        this.facilityService.getSelectListItems(this.companyId)
            .subscribe(
                (data: any) => {
                    Object.assign(this.facilities, data);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadUnits() {
        this.blockUI.start();
        this.unitService.getSelectListItems(Number(this.model.facilityId))
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.units, data);
                    const unitToRemove = this.units.find(x => x.name === 'Default');
                    const index = this.units.indexOf(unitToRemove);
                    if (index !== -1) {
                        this.units.splice(index, 1);
                    }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFacilityUsers() {
        this.blockUI.start();
        this.facilityService.getUsersAndUserGroupsList(Number(this.model.facilityId), true, true, true)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.facilityGroups = data.userGroups;
                    this.facilityUsers = data.users;
                    this.facilityUsers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                    this.facilityWatchers = this.facilityUsers;
                    if(data.watchers != null && this.roleMatrix.role === 'ORGSUPERADMIN')
                    {
                        this.facilityWatchers = this.facilityWatchers.concat(data.watchers);
                        this.facilityWatchers.forEach(x => x.fullName = `${x.firstName} ${x.lastName}`);
                        this.facilityWatchers.sort((t1, t2) => {
                            const name1 = t1.firstName.toLowerCase();
                            const name2 = t2.firstName.toLowerCase();
                            if (name1 > name2) { return 1; }
                            if (name1 < name2) { return -1; }
                            return 0;
                          });
                    }
                    
                    this.workOrderAssignees = this.facilityGroups.map(x => 
                        {
                            let model = new AssigneeSelectListItemModel();
                            model.id = x.id,
                            model.name = x.name,
                            model.type = WorkOrderAssigneeType.group
                            model.image = location.origin + "/assets/media/icons/user-group.png"
                            return model;
                        })
                        .concat(
                            this.facilityUsers.map(x=>{
                            let model = new AssigneeSelectListItemModel();
                            model.id = x.id,
                            model.name = x.fullName,
                            model.type = WorkOrderAssigneeType.user,
                            model.image = location.origin + "/assets/media/icons/user.png"
                            return model;
                            })
                        );

                    this.setAssignee();
                    this.setFollower();
                },
                error => {
                    this.blockUI.stop();
                    this.facilityUsers = new Array<AssignedUserModel>();
                    this.facilityGroups = new Array<AssignedUserGroupModel>();
                    this.workOrderAssignees = new Array<AssigneeSelectListItemModel>();
                    this.facilityWatchers = new Array<AssignedUserModel>();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    setAssignee() {
        if(isNullOrUndefined(this.model.facilityId) || this.model.facilityId == ''){
            this.model.assignedToId = null;
            this.model.assignedToGroupId = null;
            this.selectedAssignee = null;
            return;
        }

        if (!isNullOrUndefined(this.oldAssigneeId) && this.workOrderAssignees.find(x => x.id === this.oldAssigneeId)) {
            this.selectedAssignee = this.workOrderAssignees.find(x => x.id == this.oldAssigneeId);
            if(this.selectedAssignee.type == WorkOrderAssigneeType.user) {
                this.model.assignedToId = this.oldAssigneeId;
                this.model.assignedToGroupId = null;
            }
            else {
                this.model.assignedToId = null;
                this.model.assignedToGroupId = this.oldAssigneeId;
            }
            this.setReminderInterval();
            return;
        }

        this.blockUI.start();
        this.facilityService.getDetailLight(+this.model.facilityId)
            .subscribe(data => {
                this.blockUI.stop();
                this.model.assignedToId = data.defaultAssigneeId ? data.defaultAssigneeId : '';
                this.model.assignedToGroupId = null;
                this.selectedAssignee = this.workOrderAssignees.find(x => x.id == this.model.assignedToId);
                this.setReminderInterval();
            }, error => {
                this.blockUI.stop();
            });
    }

    onAssigneeChange(assignee: AssigneeSelectListItemModel) {
        if(assignee) {
            this.selectedAssignee = assignee;
            this.oldAssigneeId = this.model.assignedToId;
            
            if(this.selectedAssignee.type == WorkOrderAssigneeType.group) {
                this.model.assignedToGroupId = assignee.id;
                this.model.assignedToId = null;
            }
            else{
                this.model.assignedToId = assignee.id;
                this.model.assignedToGroupId = null;
            }
            this.setReminderInterval();
        }
        else
        {
            this.selectedAssignee =  null;
            this.model.assignedToId = null;
            this.model.assignedToGroupId = null;
        }
    }

    setFollower() {
        if (isNullOrUndefined(this.model.facilityId) || this.model.facilityId == '' || 
            isNullOrUndefined(this.oldFollowerId) || !this.facilityUsers.find(x => x.id === this.oldFollowerId)) {
            this.model.followUpAssigneeId = '';
            this.model.followUpInstruction = '';
            return;
        }

        this.model.followUpAssigneeId = this.oldFollowerId;
        this.model.followUpInstruction = this.oldFollowerInstruction;
    }

    setOldFollower() {
        this.oldFollowerId = this.model.followUpAssigneeId;
        if (!this.model.followUpAssigneeId) {
            this.oldFollowerInstruction = '';
            this.model.followUpInstruction = '';
        }
    }

    setOldInstruction() {
        this.oldFollowerInstruction = this.model.followUpInstruction;
    }

    onFacitlityChanged() {
        this.model.unitId = '';
        this.units = new Array<SelectListItemModel>();
        this.facilityUsers = new Array<AssignedUserModel>();
        this.selectedClientWatchers = [];
        if (!this.model.facilityId) {
            this.setAssignee();
            this.setFollower();
            this.facilityUsers = new Array<AssignedUserModel>();
            this.facilityGroups = new Array<AssignedUserGroupModel>();
            this.workOrderAssignees = new Array<AssigneeSelectListItemModel>();
            this.facilityWatchers = new Array<AssignedUserModel>();
            return;
        }
        this.loadUnits();
        this.loadFacilityUsers();
    }

    onPhotoSelect(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }

        const workOrderPhoto = new WorkOrderPhotoModel();
        workOrderPhoto.originalFileName = event.target.files[0].name;
        const file = event.target.files.item(0);
        this.blockUI.start();

        this.cloudStorageService.getSasToken()
            .subscribe(
                async (data) => {
                    const containerName = 'work-order';
                    const CloudConfig: UploadParams = {
                        sas: data.toString(),
                        storageAccount: this.appSettings.AzureAccount,
                        containerName: containerName
                    };
                    const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
                    const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
                    AppSettings.NonBearerUrl = blobUrl;

                    this.config = {
                        baseUrl: `${blobUrl}?`,
                        sasToken: CloudConfig.sas,
                        blockSize: 1024 * 64,
                        file: file,
                        complete: () => {
                            workOrderPhoto.fileName = fileName;
                            this.cloudStorageService.getPublicUrl(containerName, fileName)
                                .subscribe(res => {
                                    workOrderPhoto.fileUrl = res.toString();
                                });
                            this.model.photos.push(workOrderPhoto);
                            this.blockUI.stop();
                            this.workOrderPhoto.nativeElement.value = null;
                        },
                        error: (err) => {
                            console.log(err);
                            this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                            this.blockUI.stop();
                        },
                        progress: (percent) => {
                            this.uploadedPercent = percent;
                        }
                    };
                    setTimeout(() => {
                        this.blobService.upload(this.config);
                    });

                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    deletePhoto(fileKey: string, index: number) {
        this.blockUI.start();
        this.workOrderService.deletePhoto(fileKey)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.model.photos.splice(index, 1);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }


    workOrderRecurranceChange(workOrderRecurrenceModel: WorkOrderRecurrenceModel) {
        this.model.workOrderRecurrence = workOrderRecurrenceModel;
    }

    isRecurring: string = 'false';
    onRecurranceChange($event: any, ngModelDir: NgModel) {
        ngModelDir.control.markAsTouched();
        this.model.isRecurring = this.isRecurring === 'true';
        if (this.model.isRecurring) {
            this.workOrderRecurrenceModal.openModal(this.model.workOrderRecurrence);
        } else {
            this.model.workOrderRecurrence = null;
        }
    }

    openRecurrance() {
        this.workOrderRecurrenceModal.openModal(this.model.workOrderRecurrence);
    }

    onCategoryChange(f: NgForm, category: CategorySelectListItemModel) {
        this.selectedCategory = category;
        
        if (!category) {
            this.model.workOrderCategoryId = '';
            this.isSelectedCategoryUnrentable = false;
            return;
        }
        
        this.model.workOrderCategoryId = category.id;
        this.model.dueDate = category && category.dueDateDuration
            ? moment.utc().add('days', category.dueDateDuration).format('YYYY-M-D')
            : null;
        f.form.controls['DueDate'].markAsUntouched();

        this.model.workOrderPriority = category.workOrderPriority || '';
        f.form.controls['workOrderPriority'].markAsUntouched();
        f.form.controls['unitId'].markAsUntouched();
        this.isSelectedCategoryUnrentable = !!category.isUnrentable;
        this.isRecurringWOAllowed = !(category.type == CategoryType.OverlockUnits);
        this.isUnitSelectAllowed = !(category.type == CategoryType.OverlockUnits);
        if(!this.isRecurringWOAllowed){
            this.isRecurring = 'false';
            this.model.isRecurring = false;
            this.model.workOrderRecurrence = null;
        }
        if(!this.isUnitSelectAllowed){
            this.model.unitId = ""; 
        }

        this.cd.detectChanges();
    }

    // Apply reminder settings for work order on the basis of the WO priority and assignee selected.  
    setReminderInterval() {
        if(this.selectedAssignee && this.selectedAssignee.type == WorkOrderAssigneeType.group) {
            let group = this.facilityGroups.find(x => x.id == this.selectedAssignee.id); 
            if(this.model.workOrderPriority == "1")
                this.model.reminderInterval = group.lowPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "2")
                this.model.reminderInterval = group.mediumPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "3")
                this.model.reminderInterval = group.highPriorityWorkOrdersReminderInterval;
        }
        if(this.selectedAssignee && this.selectedAssignee.type == WorkOrderAssigneeType.user){
            let user = this.facilityUsers.find(x=> x.id == this.selectedAssignee.id);
            if(this.model.workOrderPriority == "1")
                this.model.reminderInterval = user.lowPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "2")
                this.model.reminderInterval = user.mediumPriorityWorkOrdersReminderInterval;
            else if(this.model.workOrderPriority == "3")
                this.model.reminderInterval = user.highPriorityWorkOrdersReminderInterval;
        }
    }

    submit(f: NgForm) {
        this.blockUI.start();

        if (this.selectedClientWatchers) {
            this.model.watchersIds = this.selectedClientWatchers.map(x => x.id);
        } else {
            this.model.watchersIds = [];
        }

        this.workOrderService.add(this.model)
            .subscribe(
                data => {
                    this.blockUI.stop();
                    this.toastr.success('Work Order has been added successfully');
                    setTimeout(() => {
                        this.router.navigate(['/work-order/detail', 1, this.companyId, data]);
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}


