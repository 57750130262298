import { KpiReportService } from '@10fed/services/kpi-report.service';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/helpers';
import { DataTableResponseModel, FacilityDetailModel, QuickBooksConfigurationModel, QuickBooksModel, SelectListItemModel } from 'src/models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { FacilityService } from '@10fed/services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
    selector: 'app-quickbooks-facility',
    templateUrl: './quickbooks.facility.component.html'
})

export class QuickBooksFacilityComponent implements OnInit , AfterViewInit{
   
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    model:QuickBooksConfigurationModel=new QuickBooksConfigurationModel();
    @Input('model') quickBooksConfigure:QuickBooksModel=new QuickBooksModel();
    showActionColumn = true;
    showAuthorizedColumn = true;
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    quickBooksList:any[];
    
    
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    facilityDataTableParameter:any={
          CompanyId:Number,
          ConsiderOnlyPersonalData:Boolean,
          FilterKey:String,
          Status:Number
      }
   
    constructor(private toastr: ToastrService,
        private route: ActivatedRoute,
        private kpiReportService: KpiReportService,
        private appUtils: AppUtils,
        private http: HttpClient,
        private facilityService:FacilityService,
        private router:Router){
        
        this.route.params.subscribe((params) => {
            this.model.internalCompanyId = params['companyId'];
        });            
    }
    
    
    ngOnInit(){
        this.loadQuickBooksFacility();
        this.loadQuickBooksCompany();
    }

    loadQuickBooksFacility(){
        this.blockUI.start();
        this.facilityService.getSelectListItems(this.model.internalCompanyId)
        .subscribe(
            (data: any) => {
                this.blockUI.stop();
                // Object.assign(this.facilities, data);
                this.facilities=data;
                
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            });
    }

    loadQuickBooksCompany(){
      
        const self = this;
        this.dtOptions = {
           
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters = self.model.internalCompanyId;
                self.http.get<DataTableResponseModel>
                    (environment.apiBaseUrl + 'Reports/get-quick-books-company-mapping-list/' + dataTablesParameters,{})
                    .subscribe(resp => {
                        this.quickBooksList=resp.data
                        callback({
                            
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                        {
                            data: 'facilityName',
                            title: 'Facility Name',
                            width: '10%',
                            render: function (data) {
                                return data;
                            }
                        },
                        {
                            data: 'realmId',
                            title: 'Realm Id',
                            width: '10%',
                            render: function (data) {
                                return data;
                            }
                        },
                        {
                            data: 'isAuthorized',
                            title: 'Authorize Status',
                            width: '1%',
                            render: function (data) {
                                if(data == true)
                                {
                                    self.showAuthorizedColumn = false;
                                }
                                else
                                {
                                    self.showAuthorizedColumn = true;
                                }
                                return data === true
                                    ? '<span class="badge badge-success">Authorized</span>'
                                    : '<span class="badge badge-danger">UnAuthorized</span>';
                            }
                        },
                        {
                            data: null,
                            title: 'Action',
                            width: '5%',
                            orderable: false,
                            className: 'text-center',
                            visible: self.showActionColumn,
                            render: function (data) {
                                const htmlString = 
                                        `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>`
                                   
                                return htmlString;
                            }
                        },
                        {
                            data: 'isAuthorized',
                            title: 'Authorize',
                            width: '5%',
                            orderable: false,
                            className: 'text-center',
                            visible: self.showActionColumn,
                            render: function (data) {
                                const htmlString = 
                                            data === true
                                            ? `<button type='button' class='btn btn-primary' disabled action-type='authorize'>Authorize</button>`
                                            : `<button type='button' class='btn btn-primary' action-type='authorize'>Authorize</button>`
                                return htmlString;
                            }
                        }
                     ],
                     rowCallback: function (row, data: any) {
                        const editElem = $(row).find('[action-type = "edit"]');
                        $(editElem).unbind('click');
                        $(editElem).on('click', function () {
                            self.router.navigate(['/quickbooks/edit', self.model.internalCompanyId, data.facilityId]);
                        });

                        const authElem = $(row).find('[action-type = "authorize"]');
                        $(authElem).unbind('click');
                        $(authElem).on('click', function () {
                            self.OnQuickBookAuthorize();
                        });
                     }
          };
        
    }

    ngAfterViewInit() {
      
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    
    }

    submit() {
        if(this.quickBooksList.some(d=>d.realmId==this.model.realmId)){
            this.toastr.error("Facility and RealmId is alrady Exist..!!!");
        }
        else
        {
                let obj = this.facilities.find(x=>x.id==this.model.facilityId); 
                this.model.facilityName=obj.name;
                this.blockUI.start();
                this.kpiReportService.addQuickbooksconfigureCompany(this.model)
                .subscribe(
                    () => {
                        this.blockUI.stop();
                        this.toastr.success("Data Mapped Successfully...!!!")
                        setTimeout(() => {
                            this.dtInstance.ajax.reload(null, false);
                        });
                    },
                   
                    error => {
                        this.blockUI.stop();
                        this.appUtils.ProcessErrorResponse(this.toastr, error);
                    });
            
            
        }
        
    }
        
    OnQuickBookAuthorize() {
        this.blockUI.start();
        this.kpiReportService.doQuickBooksConnection(this.quickBooksConfigure)
            .subscribe(
                (data:any)=>{
                        this.blockUI.stop();
                        if(data != null || data !='')
                        {
                            window.location.href = data;
                        }
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                }
            );
    }   
}