<ng-template #workOrderNoteModal let-modal>
    <form name="form" #noteModalF="ngForm" (ngSubmit)="noteModalF.form.valid && addNote(noteModalF)" novalidate
        *blockUI="'container-blockui-note'">
        <div class="modal-header">
            <h5 class="modal-title">Add Note</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeWorkOrderNoteModal(noteModalF)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group"
                    [ngClass]="{ 'validation-error' : noteModalF.submitted && workOrderNote.invalid}">
                    <label class="control-label">Note *</label>
                    <textarea name="workOrderNote" class="form-control" rows="5" autocomplete="Off"
                        [(ngModel)]="note" #workOrderNote="ngModel" required>
                        </textarea>
                    <div class="validation-error-message" *ngIf="noteModalF.submitted && workOrderNote.invalid">
                        Note is required
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-default m-r-5"
                    (click)="closeWorkOrderNoteModal(noteModalF)">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
</ng-template>