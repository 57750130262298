import { Component, Input, OnInit } from '@angular/core';
import { isNumber } from '../../../../helpers/tools';

@Component({
    selector: 'app-work-order-priority-text',
    templateUrl: './work.order.priority.text.component.html'
})

export class WorkOrderPriorityTextComponent implements OnInit {
    @Input() workOrderPriority: any;

    priority: number;

    ngOnInit() {
        if (isNumber(this.workOrderPriority)) {
            this.priority = this.workOrderPriority;
            return;
        }
        switch (this.workOrderPriority) {
            case 'Low':
                this.priority = 1;
                break;
            case 'Medium':
                this.priority = 2;
                break;
            case 'High':
                this.priority = 3;
                break;
        }
    }
}

