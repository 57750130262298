import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DashboardWorkOrderStatusCountModel } from '../../../models';
import { MasterService, WorkOrderService } from 'src/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard-work-orders',
    templateUrl: './dashboard.work.orders.component.html'
})
export class DashboardWorkOrdersComponent implements OnInit, OnDestroy {
    @Input() companyId: number;
    @BlockUI('container-blockui-tiles-work-orders') blockUI: NgBlockUI;

    tiles: Array<DashboardWorkOrderStatusCountModel> = new Array<DashboardWorkOrderStatusCountModel>();

    subscriptions: Subscription[] = [];

    constructor(private workOrderService: WorkOrderService,
                private masterService: MasterService) { }

    ngOnInit() {
        const subscription = this.masterService.DashboardShowPersonnalData$.subscribe(showPersonalData => {
            this.tiles = [];
            this.laodDaskboardWorkOrderTiles(showPersonalData);
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    laodDaskboardWorkOrderTiles(considerOnlyPersonalData: boolean) {
        this.blockUI.start();

        let tile = new DashboardWorkOrderStatusCountModel();
        tile.name = 'Total Work Orders';
        tile.redirectUrl = `/work-order/manage/${this.companyId}`;
        tile.redirectQueryParams = { resetFilter: true }; 
        this.tiles.push(tile);
        this.laodTotalCount(tile, considerOnlyPersonalData);

        tile = new DashboardWorkOrderStatusCountModel();
        tile.name = 'Not Started';
        tile.bgColor = 'danger';
        tile.redirectUrl = ['/work-order/manage', this.companyId];
        tile.redirectQueryParams = { status: '1' };
        this.tiles.push(tile);
        this.loadNotStartedCount(tile, considerOnlyPersonalData);

        tile = new DashboardWorkOrderStatusCountModel();
        tile.name = 'In Progress';
        tile.bgColor = 'primary';
        tile.redirectUrl = ['/work-order/manage', this.companyId];
        tile.redirectQueryParams = { status: '2' };
        this.tiles.push(tile);
        this.loadInProgressCount(tile, considerOnlyPersonalData);

        tile = new DashboardWorkOrderStatusCountModel();
        tile.name = 'On Hold';
        tile.bgColor = 'warning';
        tile.redirectUrl = ['/work-order/manage', this.companyId];
        tile.redirectQueryParams = { status: '3' };
        this.tiles.push(tile);
        this.loadOnHoldCount(tile, considerOnlyPersonalData);

        tile = new DashboardWorkOrderStatusCountModel();
        tile.name = 'Complaints';
        tile.bgColor = 'purple';
        tile.redirectUrl = `/work-order/manage/${this.companyId}`;
        tile.redirectQueryParams = { category: 'customer complaint' };
        this.tiles.push(tile);
        this.loadComplaintCount(tile, considerOnlyPersonalData);
    }

    laodTotalCount(item: DashboardWorkOrderStatusCountModel, considerOnlyPersonalData: boolean) {
        this.workOrderService.getCountByCompany(this.companyId, considerOnlyPersonalData)
            .subscribe(
                (data: any) => {
                    item.count = data;
                    item.isLoaded = true;
                    this.updatePercentile();
                },
                error => {
                    console.log(error);
                });
    }

    loadNotStartedCount(item: DashboardWorkOrderStatusCountModel, considerOnlyPersonalData: boolean) {
        this.workOrderService.getCountByCompanyNotStarted(this.companyId, considerOnlyPersonalData)
            .subscribe(
                (data: any) => {
                    item.count = data;
                    item.isLoaded = true;
                    this.updatePercentile();
                },
                error => {
                    console.log(error);
                });
    }

    loadInProgressCount(item: DashboardWorkOrderStatusCountModel, considerOnlyPersonalData: boolean) {
        this.workOrderService.getCountByCompanyInProgress(this.companyId, considerOnlyPersonalData)
            .subscribe(
                (data: any) => {
                    item.count = data;
                    item.isLoaded = true;
                    this.updatePercentile();
                },
                error => {
                    console.log(error);
                });
    }

    loadOnHoldCount(item: DashboardWorkOrderStatusCountModel, considerOnlyPersonalData: boolean) {
        this.workOrderService.getCountByCompanyOnHold(this.companyId, considerOnlyPersonalData)
            .subscribe(
                (data: any) => {
                    item.count = data;
                    item.isLoaded = true;
                    this.updatePercentile();
                },
                error => {
                    console.log(error);
                });
    }

    loadComplaintCount(item: DashboardWorkOrderStatusCountModel, considerOnlyPersonalData: boolean) {
        this.workOrderService.getCountByCompanyComplaint(this.companyId, considerOnlyPersonalData)
            .subscribe(
                (data: any) => {
                    item.count = data;
                    item.isLoaded = true;
                    this.updatePercentile();
                },
                error => {
                    console.log(error);
                });
    }

    updatePercentile() {
        const loadedTilesCount = this.tiles.filter(x => x.isLoaded === true).length;

        if (loadedTilesCount < 5) {
            return;
        }

        this.blockUI.stop();
        const totalWorkOrders = this.tiles[0].count;
        this.tiles.forEach((item) => {
            if (!item.bgColor) {
                return;
            }
            if (item.count > 0) {
                item.percentage = Number(((item.count / totalWorkOrders) * 100).toFixed(2));
            } else {
                item.percentage = 0;
            }
        });
    }
}
