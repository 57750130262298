import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services';

@Component({
    selector: 'app-dashboard-company-user-tile',
    templateUrl: './dashboard.company.user.tile.component.html'
})
export class DashboardCompanyUserTileComponent implements OnInit {
    @Input() companyId: number;
    @Output() tileLoaded = new EventEmitter<any>();

    isModelLoaded = false;
    totalUsers = 0;

    constructor(private userService: UserService,
                private router: Router) { }

    ngOnInit(): void {
        this.userService.getCompanyUsersCount(this.companyId)
            .subscribe(
                (data: any) => {
                    this.totalUsers = data;
                    this.isModelLoaded = true;
                    this.tileLoaded.emit();
                },
                error => {
                    console.log('DaskboardCompanyUserTileComponent load failed');
                    console.log(error);
                });
    }

    onPersonnelTileClicked () {
        this.router.navigateByUrl(`/company-user/manage-personnel?companyId=${this.companyId}`);
    }
}
