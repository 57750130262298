<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
            <app-breadcrumb-work-order-report-issue [companyId]="companyId" [workOrderId]="workOrderId">
            </app-breadcrumb-work-order-report-issue>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Report Issue
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                        </div>
                    </div>
                    <form class="kt-form form-horizontal" name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
                        novalidate *blockUI="'container-blockui'">
                        <div class="kt-portlet__body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && receivers.invalid}">
                                        <label class="col-md-3">To*:</label>
                                        <div class="col-md-9">
                                            <ng-multiselect-dropdown name="receivers"
                                                [placeholder]="'[Select]'"
                                                [(ngModel)]="selectedReceivers"
                                                [settings]="multiSelectDropdownConfigs"
                                                [data]="facilityUsers"
                                                #receivers="ngModel"
                                                required>
                                            </ng-multiselect-dropdown>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && receivers.invalid">
                                                <span *ngIf="receivers.errors.required">
                                                    Please select receivers
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && subject.invalid}">
                                        <label class="col-md-3">Subject*:</label>
                                        <div class="col-md-9">
                                            <input type="text" name="subject" class="form-control" maxlength="256"
                                                autocomplete="off" [(ngModel)]="model.subject" #subject="ngModel"
                                                required />
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && subject.invalid">
                                                <span *ngIf="subject.errors.required">
                                                    Please enter subject
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row"
                                        [ngClass]="{ 'validation-error' : f.submitted && content.invalid}">
                                        <label class="col-md-3">Content*:</label>
                                        <div class="col-md-9">
                                            <textarea type="text" name="content" class="form-control" rows="10"
                                                autocomplete="off" [(ngModel)]="model.body" #content="ngModel"
                                                required></textarea>
                                            <div class="validation-error-message"
                                                *ngIf="f.submitted && content.invalid">
                                                <span *ngIf="content.errors.required">
                                                    Please enter content
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__foot">
                            <div class="kt-form__actions text-right">
                                <a [routerLink]="['/work-order/detail/1', companyId, workOrderId]"
                                    class="btn btn-secondary m-r-5">
                                    Cancel
                                </a>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>