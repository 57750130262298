import { Component, Input } from '@angular/core';
import { RoleMatrix } from '../../../../helpers';

@Component({
    selector: 'app-breadcrumb-work-order-add',
    templateUrl: './breadcrumb.work.order.add.component.html'
})

export class BreadcrumbWorkOrderAddComponent {

    roleMatrix: RoleMatrix;

    @Input() companyId: number;
    @Input() viewType: number;
    
    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
