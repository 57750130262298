import { Component, ViewChild, OnInit, HostBinding, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { DataTableResponseModel, CompanyFilterModel } from '../../../models';
import { CompanyService } from '../../../services';
import { AppUtils, AppSettings, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-company-manage',
    templateUrl: './company.manage.component.html'
})

export class CompanyManageComponent implements OnInit, AfterViewInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    @ViewChild('companyChallengeDeleteModal', { static: true }) companyChallengeDeleteModal: any;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    routerSubscription: Subscription;
    gridState: any;
    gridStateName = 'gridStateCompanies';
    rowIndex = 0;
    pageLength = 10;
    search: any = null;
    filterModel: CompanyFilterModel = new CompanyFilterModel();
    companyIdToDelete: number;
    deletingDataIndex: number;

    constructor(private http: HttpClient,
        private router: Router,
        private tostr: ToastrService,
        private companyService: CompanyService,
        private appUtils: AppUtils,
        private appSettings: AppSettings) {

        this.roleMatrix = new RoleMatrix();

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('/company/detail/') !== -1
                    || event.url.indexOf('/company/edit/') !== -1) {
                    localStorage.setItem(this.gridStateName, JSON.stringify(this.gridState));
                }
            }
            if (event instanceof NavigationEnd) {
                let gridState: any = localStorage.getItem(this.gridStateName);
                if (!gridState) {
                    return;
                }
                gridState = JSON.parse(gridState);
                this.rowIndex = gridState.start;
                this.pageLength = gridState.length;

                if (gridState.status) {
                    this.filterModel.status = gridState.status;
                }
                if (gridState.filterKey) {
                    this.filterModel.filterKey = gridState.filterKey;
                }
            }
        });
    }

    ngOnInit(): void {

        if (this.roleMatrix.role !== 'SUPERADMIN'
            && this.roleMatrix.role !== 'ADMIN'
            && this.roleMatrix.role !== 'USER'
            && this.roleMatrix.role !== 'PERSONNEL') {
            this.router.navigate(['/error/403']);
            return;
        }

        const self = this;

        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter companies...',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            search: { search: self.search },
            displayStart: self.rowIndex,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: self.pageLength,
            lengthMenu: [10, 15, 25, 50, 100],
            order: [[3, 'desc']],
            stateSave: true,
            ajax: (dataTablesParameters: any, callback) => {

                let gridState: any = localStorage.getItem(self.gridStateName);

                if (gridState) {
                    gridState = JSON.parse(gridState);
                    localStorage.removeItem(self.gridStateName);
                    dataTablesParameters = gridState;
                } else {
                    dataTablesParameters.status = self.filterModel.status;
                    dataTablesParameters.filterKey = self.filterModel.filterKey;
                }

                self.gridState = dataTablesParameters;

                self.http
                    .post<DataTableResponseModel>(environment.apiBaseUrl + 'company/paged-result', dataTablesParameters, {})
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
            },
            columns: [
                {
                    data: 'id',
                    title: 'ID',
                    width: '5%',
                    render: function (data, type, row) {
                        return `ID${data}`;
                    }
                },
                {
                    data: 'name',
                    title: 'Name',
                    width: '20%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'accountOwnerName',
                    title: 'Account Owner',
                    width: '20%'
                },
                {
                    data: 'createdOn',
                    title: 'Created On',
                    width: '15%',
                    render: function (data) {
                        return self.appUtils.getFormattedDate(data);
                    }
                },
                {
                    data: 'plan',
                    title: 'Plan',
                    width: '15%'
                },
                {
                    data: 'status',
                    title: 'Status',
                    width: '10%',
                    render: function (data) {
                        return data === 2
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: (self.roleMatrix.canChangeStatusOfCompany
                        || self.roleMatrix.canEditCompany
                        || self.roleMatrix.canDeleteCompany),
                    render: function (data, type, row) {
                        const statusHtml =
                            row.status === 2
                                ? `<em class='fa fa-ban cursor-pointer m-r-5' title='Deactivate' action-type='toggle-status'></em>`
                                : row.status === 3
                                    ? `<em class='fa fa-check cursor-pointer m-r-5' title='Activate' action-type='toggle-status'></em>`
                                    : '';
                        const htmlString = (self.roleMatrix.canChangeStatusOfCompany ? statusHtml : '')
                            + (self.roleMatrix.canEditCompany  && row.status !== 3
                                ? `<em class='fa fa-edit cursor-pointer m-r-3' title='Edit' action-type='edit'></em>` : '')
                            + (self.roleMatrix.canDeleteCompany
                                ? `<em class='fa fa-trash cursor-pointer' title='Delete' action-type='delete'></em>` : '');
                        return htmlString;
                    }
                }
            ],
            rowCallback: function (row, data: any, dataIndex: number) {
                const toggleStatusElem = $(row).find('[action-type = "toggle-status"]');
                $(toggleStatusElem).unbind('click');
                $(toggleStatusElem).on('click', function () {
                    self.toggleStatus(data.id, data.status);
                });

                const editElem = $(row).find('[action-type = "edit"]');
                $(editElem).unbind('click');
                $(editElem).on('click', function () {
                    self.router.navigate(['/company/edit', data.id]);
                });

                const delElem = $(row).find('[action-type = delete]');
                $(delElem).unbind('click');
                $(delElem).on('click', function () {
                    self.challengeDelete(data.id, data.name, dataIndex);
                });

                const detailElem = $(row).find('[action-type = link-detail]');
                $(detailElem).unbind('click');
                $(detailElem).on('click', function () {
                    self.router.navigate(['/company/detail', data.id]);
                });
            },
            drawCallback: function () {
                if ($('.pagination li').length <= 5) {
                    $('.pagination').hide();
                }
            },
            stateSaveParams: function (settings, data: any) {
                data.status = self.filterModel.status;
                data.filterKey = self.filterModel.filterKey;
            },
            stateLoadParams: function (settings, data: any) {
                if (data.status) {
                    self.filterModel.status = data.status;
                }
                if (data.filterKey) {
                    self.filterModel.filterKey = data.filterKey;
                }
            }
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance
            .then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    toggleStatus(id: number, status: number): void {
        this.blockUI.start();
        this.companyService.toggleStatus(id).subscribe(
            () => {
                this.blockUI.stop();
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.tostr.success(`Company has been ${(status === 2 ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.blockUI.stop();
                this.appUtils.ProcessErrorResponse(this.tostr, error);
            });
    }

    challengeDelete(id: number, companyName: string, dataIndex: number): void {
        this.companyIdToDelete = id;
        this.deletingDataIndex = dataIndex;
        this.companyChallengeDeleteModal.openCompanyDeleteConfirmationModal(id, companyName);
    }

    delete() {
        this.blockUI.start();
        this.companyService.delete(this.companyIdToDelete)
            .subscribe(
                () => {
                    this.companyIdToDelete = null;
                    this.blockUI.stop();
                    const currentPage = this.dtInstance.page();
                    const recordsCountOnPage = this.dtInstance.page(currentPage).rows().count();
                    if (this.deletingDataIndex === 0 && recordsCountOnPage === 1 && currentPage > 0) {
                        this.dtInstance.page(currentPage - 1).draw('page');
                    } else {
                        this.dtInstance.ajax.reload(null, false);
                    }
                    this.deletingDataIndex = null;
                    this.tostr.success('Company has been deleted successfully.');
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.tostr, error);
                });
    }

    doFilter() {
        this.dtInstance.ajax.reload();
    }

    resetFilter() {
        this.filterModel.status = '';
        this.filterModel.filterKey = '';
        this.doFilter();
    }
}
