import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ListenerService {

    public topNavlistner = new Subject<any>();
    public layoutlistner = new Subject<any>();
    public signalReventListner = new Subject<any>();
    public notificationMenuListner = new Subject<any>();
    public updateUserInfoListner = new Subject<any>();

    get listenTopNav() {
        return this.topNavlistner.asObservable();
    }

    get listenLayout() {
        return this.layoutlistner.asObservable();
    }

    get listenNotificationMenu() {
        return this.notificationMenuListner.asObservable();
    }

    get listenSignalRevent() {
        return this.signalReventListner.asObservable();
    }

    get listenUpdateUserInfo() {
        return this.updateUserInfoListner.asObservable();
    }
}
