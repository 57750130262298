import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';
import { FacilityNoteDetailModel } from 'src/models/facility/facility.detail.model';

@Component({
    selector: 'app-facility-add-note',
    templateUrl: './facility.add.note.component.html'
})
export class FacilityAddNoteComponent {

    @BlockUI('container-blockui-add-note-popup') blockUI: NgBlockUI;
    @ViewChild('addNoteModal', { static: true }) addNoteModal: any;
    @Output() change = new EventEmitter<string>();
    modalReference: NgbModalRef;
    note: string;
    isEditMode = false;

    constructor(private modalService: NgbModal) { }

    openModal(facilityNoteDetails: FacilityNoteDetailModel) {
        this.isEditMode = facilityNoteDetails != null;
        this.note = facilityNoteDetails == null ? "" : facilityNoteDetails.note;
        this.modalReference = this.modalService.open(this.addNoteModal);
    }

    submit(f: NgForm) {
        if (f.form.invalid) {
            return;
        }
        this.close();
        setTimeout(() => {
            this.change.emit(this.note);
        });
    }

    close() {
        this.modalReference.close();
    }
}
