import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { WorkOrderNoteModel } from '../../../models';
import { WorkOrderService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { InfoModalComponent } from 'src/components/shared';

@Component({
    selector: 'app-work-order-note',
    templateUrl: './work.order.note.component.html'
})

export class WorkOrderNoteComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @ViewChild('addWorkOrderNote', { static: true }) addWorkOrderNote: any;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @BlockUI('container-blockui-note') noteBlockUI: NgBlockUI;

    companyId = -1;
    workOrderId = -1;
    workOrderNoteId = -1;
    isModelLoaded = false;
    note: string;
    modalReference: any;
    notes: Array<WorkOrderNoteModel> = new Array<WorkOrderNoteModel>();
    showActionColumn = true;
    currentUserId: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewWorkOrder) {
            this.router.navigate(['/error/403']);
        }

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.workOrderId = params['id'];

        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.currentUserId = this.appUtils.getUserId();
    }

    ngOnInit() {
        this.checkWorkOrderStatus();
        this.loadNotes();
    }

    checkWorkOrderStatus() {
        this.workOrderService.getStatus(this.workOrderId).subscribe(
            data => {
                if (data === 3) {
                    this.showActionColumn = false;
                }
            });
    }

    loadNotes() {
        this.blockUI.start();
        this.workOrderService.getNotes(this.workOrderId)
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    this.notes = new Array<WorkOrderNoteModel>();
                    Object.assign(this.notes, data);
                    this.isModelLoaded = true;
                    this.notes.map((item) => {
                        if ((item.createdByName !== 'System' && item.createdByRole === 'SUPERADMIN') ||
                                 item.createdByRole === 'ADMIN' || item.createdByRole === 'USER' ||
                                (item.createdByName !== 'System' && item.createdByRole === 'SuperAdmin') ||
                                 item.createdByRole === 'Admin' || item.createdByRole === 'User') {
                                item.createdByName = 'Admin';
                            }
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                    });
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    openWorkOrderNoteModal() {
        this.addWorkOrderNote.openWorkOrderNoteModal();
    }

    openWorkOrderNoteEditModal(modelContent: any, item: WorkOrderNoteModel) {
        this.workOrderNoteId = item.id;
        this.note = item.note;
        this.modalReference = this.modalService.open(modelContent,
            {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });
    }

    closeWorkOrderNoteEditModal(form: NgForm) {
        this.workOrderNoteId = -1;
        this.note = '';
        form.resetForm();
        this.modalReference.close();
    }

    editNote(form: NgForm) {
        const workOrderNote = new WorkOrderNoteModel();
        workOrderNote.id = this.workOrderNoteId;
        workOrderNote.workOrderId = this.workOrderId;
        workOrderNote.note = this.note;
        this.noteBlockUI.start();
        this.workOrderService.editNote(workOrderNote)
            .subscribe(
                () => {
                    this.noteBlockUI.stop();
                    this.toastr.success('Note has been edit successfully');
                    setTimeout(() => {
                        this.closeWorkOrderNoteEditModal(form);
                        this.loadNotes();
                    }, 100);

                },
                error => {
                    this.noteBlockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    showNote(modelContent: any, item: WorkOrderNoteModel) {
        this.note = item.note;
        this.modalService.open(modelContent,
            {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });
    }
}
