export class FacilityAddModel {
    public companyId: number;
    public name: string;
    public address: string;
    public zipCode: string;    
    public propertyManagerUserId: string;
    public propertyManagerName: string;
    public propertyManagerEmail: string;
    public propertyManagerPhone: string;
    public typeOfConstruction: string;
    public climateControlled: boolean;
    public clientUserGroupsAssigned: string[];
    public defaultAssigneeId: string;
    public complaintsAssigneeId: string;
    public moveOutAssigneeId: string;
    public moveInAssigneeId: string;
    public phone:string;
    constructor() {
        this.complaintsAssigneeId = '';
        this.moveOutAssigneeId = '';
        this.moveInAssigneeId = '';
        this.propertyManagerUserId = '';
        this.defaultAssigneeId = '';
    }
}
