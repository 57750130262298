import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TimeZone } from 'src/models/timezone.model';
import { of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TimeZoneService {

    private timeZones: TimeZone[];

    constructor(private http: HttpClient) { }

    getTimeZones = () => {
        if (this.timeZones && this.timeZones.length > 0) {
            return of(this.timeZones);
        }

        return this.http.get<TimeZone[]>(`${environment.apiBaseUrl}timezones`)
            .pipe(tap(timeZones => this.timeZones = timeZones));
    }

    getTimeZone = (standardTimeZoneName: string) => this.getTimeZones()
        .pipe(
            map((timeZones) => timeZones.filter(x => x.standardName === standardTimeZoneName)[0]),
            first())

    getTimeZoneFormattedName = (standardTimeZoneName: string) => this.getTimeZone(standardTimeZoneName)
        .pipe(
            map(timeZone => `(${timeZone.currentUtcOffset}) ${timeZone.standardName}`),
            first())
}
