import { Component, Input, OnInit } from '@angular/core';
import { ActivityLogDetailModel } from '../../../models';

@Component({
    selector: 'app-activity-log-author',
    templateUrl: './activity.log.author.component.html'
})

export class ActivityLogAuthorComponent implements OnInit {
    @Input() model: ActivityLogDetailModel;
    createdByName: string;

    ngOnInit() {
        this.createdByName = this.model.createdByName;

        if (this.model.is10FedUser) {
            this.createdByName
                = (this.createdByName.trim().toUpperCase() === 'SYSTEM')
                    ? 'System'
                    : 'an admin';
            return;
        }

        const queryParams = '{"companyId" : "' + this.model.companyId + '", "userType":"any"}';
        this.model.createdByLink = '/company-user/detail/' + this.model.createdById;
        this.model.createdByParams = JSON.parse(queryParams);
    }
}

