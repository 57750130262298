<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
      <div class="kt-subheader__main">
          <app-breadcrumb-facility-note [companyId]="companyId" [facilityId]="facilityId"></app-breadcrumb-facility-note>
      </div>
      <div class="kt-subheader__toolbar">
          <div class="kt-subheader__wrapper">
          </div>
      </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row">
          <div class="col-12" *blockUI="'container-blockui'">
              <div class="kt-portlet">
                  <div class="kt-portlet__head">
                      <div class="kt-portlet__head-label">
                          <h3 class="kt-portlet__head-title">
                              All Notes <span *ngIf="notes.length === 1">(1 Note)</span> <span
                                  *ngIf="notes.length > 1">({{notes.length}} Notes)</span>
                          </h3>
                      </div>
                      <div class="kt-portlet__head-toolbar">
                          <button type="button" class="btn btn-primary" (click)="openWorkOrderNoteModal()" *ngIf="showActionColumn">
                              Add
                          </button>
                      </div>
                  </div>
                  <div class="kt-portlet__body" *ngIf="isModelLoaded">
                      <div class="row">
                          <div class="col-12" *ngFor="let item of notes; let $index=index">
                              <div class="row">
                                  <div class="col-12">
                                      <p class="word-wrap">
                                          {{item.note | maxStringLength: 150}}
                                          <a href="javascript:0"
                                              *ngIf="item.note.length > 100" 
                                              (click)="showNote(item.note)">see more</a>
                                      </p>
                                      <p class="badge badge-light m-b-5">
                                          <span>{{item.createdByName}}</span>
                                          ({{item.createdOn}})
                                      </p>
                                  </div>
                              </div>
                              <div class="row" *ngIf="(notes.length - 1) > $index">
                                  <div class="col-12">
                                      <div class="kt-divider m-t-10 m-b-10">
                                          <span></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12">
                              <p *ngIf="notes.length === 0">No note found</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>