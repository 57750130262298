import { Component, Input } from '@angular/core';
import { RoleMatrix } from 'src/helpers';

@Component({
    selector: 'app-breadcrumb-user-group-add',
    templateUrl: './breadcrumb.user.group.add.component.html'
})

export class BreadcrumbUserGroupAddComponent {
    @Input() companyId: number;
    roleMatrix: RoleMatrix;

    constructor() {
        this.roleMatrix = new RoleMatrix();
    }
}
