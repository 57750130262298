import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { WorkOrderNoteModel } from '../../../models';
import { WorkOrderService } from '../../../services';
import { AppUtils } from '../../../helpers';

@Component({
    selector: 'app-work-order-note-add',
    templateUrl: './work.order.note.add.component.html'
})

export class WorkOrderNoteAddComponent {
    @BlockUI('container-blockui-note') noteBlockUI: NgBlockUI;
    @Input() workOrderId: number;
    @Output() reloadNotes = new EventEmitter<any>();
    @Output() updateWorkOrderStatus = new EventEmitter<any>();
    @ViewChild('workOrderNoteModal', { static: true }) workOrderNoteModal: any;

    note: string;
    modalReference: any;
    notes: Array<WorkOrderNoteModel> = new Array<WorkOrderNoteModel>();
    isWorkOrderStatusOnHold: false;

    constructor(private modalService: NgbModal,
        private toastr: ToastrService,
        private workOrderService: WorkOrderService,
        private appUtils: AppUtils) {

    }

    openWorkOrderNoteModal() {
        this.modalReference = this.modalService.open(this.workOrderNoteModal,
            {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });
    }

    closeWorkOrderNoteModal(form: NgForm) {
        this.note = '';
        form.resetForm();
        this.modalReference.close();
    }

    addNote(form: NgForm) {
        const workOrderNote = new WorkOrderNoteModel();
        workOrderNote.workOrderId = this.workOrderId;
        workOrderNote.note = this.note;
        this.noteBlockUI.start();
        this.workOrderService.addNote(workOrderNote)
            .subscribe(
                () => {
                    this.noteBlockUI.stop();
                    this.toastr.success('Note has been added successfully');
                    setTimeout(() => {
                        this.closeWorkOrderNoteModal(form);
                        this.reloadNotes.emit();
                        if (this.isWorkOrderStatusOnHold) {
                            this.updateWorkOrderStatus.emit();
                        }
                    }, 100);

                },
                error => {
                    this.noteBlockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
