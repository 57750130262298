import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { RoleMatrix, Roles } from '../../../helpers';

@Component({
    selector: 'app-side-nav',
    templateUrl: './sidenav.component.html'
})

export class SideNavComponent implements OnInit {
    roleMatrix: RoleMatrix;
    currentUrl = '/';
    isCollapsed = true;
    isKPICollapsed = true;

    get Roles() {
        return Roles;
    }

    constructor(private router: Router) {
        this.roleMatrix = new RoleMatrix();
    }

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });
    }
}
