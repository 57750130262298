import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ListenerService, AccountService, UserService } from '../../../services';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { UserHealthModel } from '../../../models';
import * as jwt_decode from 'jwt-decode';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('notificationDropdown', { static: true })
    notificationDropdown: any;

    is10FedUsers = false;
    roleMatrix: RoleMatrix;

    currentUsername = '';
    currentUserProfilePhoto = '';
    profileUrl: string;
    topNavListnersubscription: Subscription;
    notificationMenuListnerSubscription: Subscription;
    timerSubscription: Subscription;
    updateUserInfoListnerSubscription: Subscription;
    notificationsCount: any;

    constructor(private router: Router,
        private messageService: ListenerService,
        private userService: UserService,
        private accountService: AccountService,
        private appUtils: AppUtils) {
        this.roleMatrix = new RoleMatrix();

        this.topNavListnersubscription = this.messageService
            .listenTopNav
            .subscribe(() => {
                this.loadCurrentUserProfile();
            });

        this.notificationMenuListnerSubscription
            = this.messageService.listenNotificationMenu
                .subscribe(() => {
                    this.closeNotificationDropdown();
                });

        this.updateUserInfoListnerSubscription
            = this.messageService.listenUpdateUserInfo
                .subscribe(() => {
                    this.updateUserInfo();
                });

        this.roleMatrix = new RoleMatrix();
        this.is10FedUsers = this.appUtils.is10FedUser(this.roleMatrix.role);
        this.profileUrl = this.is10FedUsers ? '/user/profile' : '/company-user/profile-detail';
    }

    ngOnInit(): void {
        this.loadCurrentUserProfile();

        this.timerSubscription = timer(0, 5000).pipe(
            switchMap(() => this.userService.checkUserHealth())
        ).subscribe((userHealth: UserHealthModel) => {
            if (userHealth.status !== 2) {
                this.router.navigate(['/account/logout']);
            }
        });
    }

    ngOnDestroy(): void {
        this.topNavListnersubscription.unsubscribe();
        this.notificationMenuListnerSubscription.unsubscribe();
        this.timerSubscription.unsubscribe();
    }

    updateUserInfo() {
        this.accountService.getUpdatedToken().subscribe(
            (data: any) => {
                localStorage.setItem('AuthToken', data.toString());
                this.loadCurrentUserProfile();
            },
            error => {
            });

    }

    loadCurrentUserProfile() {
        const authToken = localStorage.getItem('AuthToken');
        if (!authToken) {
            return;
        }
        const decodedToken = jwt_decode(authToken);
        this.currentUsername = decodedToken.given_name;
        this.currentUserProfilePhoto = decodedToken.profile_photo;
    }

    updateNotificationCount(notificationCount: number) {
        if (notificationCount > 99) {
            this.notificationsCount = '99+';
        } else {
            this.notificationsCount = notificationCount;
        }
    }

    closeNotificationDropdown() {
        this.notificationDropdown.close();
    }
}

