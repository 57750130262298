<div class="kt-grid kt-grid--ver kt-grid--root">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

            <app-left-banner [model]="leftBannerModel" id="webView"></app-left-banner>

            <div *ngIf="!showResetPasswordForm"
                class="kt-grid__item kt-grid__item--fluid  
                kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper w-auto">
                <div class="kt-login__body" *blockUI="'frm-login'">
                    <div class="kt-login__form">
                        <div class="kt-login__title">
                            <h3>Sign In</h3>
                        </div>
                        <form class="kt-form" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                            <div class="form-group" [ngClass]="{ 'validation-error' : f.submitted && !userName.valid}">
                                <input type="text" name="userName" class="form-control" maxlength="250"
                                    autocomplete="off" placeholder="Username" [(ngModel)]="model.userName"
                                    #userName="ngModel" required />
                                <div class="validation-error-message" *ngIf="f.submitted && !userName.valid">
                                    Username is required
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'validation-error' : f.submitted && !password.valid}">
                                <input type="password" name="password" class="form-control" maxlength="50"
                                    autocomplete="off" placeholder="Password" [(ngModel)]="model.password"
                                    #password="ngModel" appAnythingButWhiteSpace required />
                                <div class="validation-error-message" *ngIf="f.submitted && !password.valid">
                                    Password is required
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <a [routerLink]="['/account/recover']" class="kt-link kt-login__link-forgot">
                                    Forgot Password ?
                                </a>
                                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
                                    <em class="la la-sign-in"></em>Sign In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div *ngIf="showResetPasswordForm"
                class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <div class="kt-login__body" *blockUI="'container-blockui'">
                    <div class="kt-login__form">
                        <div class="kt-login__title">
                            <h3>Change Password</h3>
                        </div>
                        <form class="kt-form" name="form" (ngSubmit)="f.form.valid && submitResetPasswordForm()" #f="ngForm" novalidate>
                            <div class="form-group"
                                [ngClass]="{ 'validation-error' : f.submitted && !newPassword.valid}">
                                <input type="password" name="newPassword" class="form-control" minlength="8"
                                    maxlength="20" autocomplete="off" placeholder="New Password*"
                                    [(ngModel)]="resetPasswordModel.newPassword" #newPassword="ngModel" appAnythingButWhiteSpace
                                    required />
                                <div class="validation-error-message" *ngIf="f.submitted && newPassword.invalid">
                                    <span *ngIf="newPassword.errors.required">
                                        Please enter new password
                                    </span>
                                    <span *ngIf="newPassword.errors.minlength">
                                        Minimum 8 characters are required
                                    </span>
                                    <span *ngIf="newPassword.errors.maxlength">
                                        Maximum 20 characters are allowed
                                    </span>
                                </div>
                            </div>
                            <div class="form-group"
                                [ngClass]="{ 'validation-error' : f.submitted && !confirmPassword.valid}">
                                <input type="password" name="confirmPassword" class="form-control" minlength="8"
                                    maxlength="20" autocomplete="off" placeholder="Confirm Password*"
                                    [(ngModel)]="resetPasswordModel.confirmPassword" #confirmPassword="ngModel"
                                    appAnythingButWhiteSpace required />
                                <div class="validation-error-message" *ngIf="f.submitted && confirmPassword.invalid">
                                    <span *ngIf="confirmPassword.errors.required">
                                        Please enter password again
                                    </span>
                                    <span *ngIf="confirmPassword.errors.minlength">
                                        Minimum 8 characters are required
                                    </span>
                                    <span *ngIf="confirmPassword.errors.maxlength">
                                        Maximum 20 characters are allowed
                                    </span>
                                </div>
                                <div class="validation-error-message"
                                    *ngIf="f.submitted && confirmPassword.valid && newPassword.valid  && resetPasswordModel.newPassword !==  resetPasswordModel.confirmPassword">
                                    <span>
                                        New password and confirm password does not match
                                    </span>
                                </div>
                            </div>
                            <div class="kt-login__actions">
                                <a [routerLink]="['/account/login']" class="kt-link kt-login__link-forgot d-none">
                                    Back to Login
                                </a>
                                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
                                    Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <app-left-banner [model]="leftBannerModel" id="mobileView"></app-left-banner>

        </div>
    </div>
</div>