import { VendorNoteModel } from './vendor.note.model';
import { VendorInsurancePolicyDocumentModel } from '..';

export class VendorDetailModel {
    public id: number;
    public companyId: number;
    public name: string;
    public vendorTypeName: string;
    public phone: string;
    public email: string;
    public address: string;
    public bankAccount: string;
    public notes: Array<VendorNoteModel>;
    public assignedFacilities: Array<number>;
    public profileImageUrl: string;
    public status: number;

    public w9Number: string;
    public insurancePolicyName: string;
    public insurancePolicyNumber: string;
    public insurancePolicyExpiryDate: string;
    public insurancePolicyDocuments: Array<VendorInsurancePolicyDocumentModel>;
    constructor() {
        this.notes = new Array<VendorNoteModel>();
        this.assignedFacilities = new Array<number>();
    }
}
