import { Component, HostBinding, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, RoleMatrix } from '../../../helpers';
import { ListenerService, KioskService, CompanyService } from '../../../services';
import { KioskActivityLogModel, KioskDetailModel, KioskTagAddModel } from '../../../models';

@Component({
    selector: 'app-kiosk-detail',
    templateUrl: './kiosk.detail.component.html'
})

export class KioskDetailComponent implements OnInit, OnDestroy {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('moveInDetail') moveInDetail: any;
    @ViewChild('signageDetail') signageDetail: any;
    @ViewChild('kioskEditTagModal', { static: true }) kioskEditTagModal: any;

    signalREventSubscription: Subscription;

    model: KioskDetailModel = new KioskDetailModel();

    companyId = -1;
    facilityId = -1;
    showActionColumn = true;
    isDataLoaded: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private appUtils: AppUtils,
        private listenerService: ListenerService,
        private kioskService: KioskService,
        private companyService: CompanyService) {

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.facilityId = params['facilityId'];
            this.model.id = params['id'];
        });

        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canViewKiosk) {
            this.router.navigate(['/error/403']);
        }

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.signalREventSubscription = this.listenerService
            .listenSignalRevent
            .subscribe(() => {
                this.checkCompanyStatus();
                this.loadKioskDetail(true);
            });

        this.isDataLoaded = false;
    }

    ngOnInit() {
        this.checkCompanyStatus();
        this.loadKioskDetail(false);
    }

    loadKioskDetail(forceToDetectChange: boolean = false) {
        this.blockUI.start();
        this.kioskService.getDetail(this.model.id)
            .subscribe(
                (data) => {
                    this.blockUI.stop();

                    Object.assign(this.model, data);

                    if (this.model.createdOn) {
                        this.model.createdOn
                            = this.appUtils.getFormattedDate(this.model.createdOn);
                    }
                    if (this.model.updatedOn) {
                        this.model.updatedOn
                            = this.appUtils.getFormattedDate(this.model.updatedOn);
                    }
                    if (forceToDetectChange) {
                        this.changeDetectorRef.detectChanges();
                    }
                    if (this.model.applicationType != 4) {
                        setTimeout(() => {
                            this.moveInDetail.updateApplicationType(this.model.applicationType);
                            this.signageDetail.updateApplicationType(this.model.applicationType);
                        });
                    }
                    if (this.model.status === 3) {
                        this.showActionColumn = false;
                        this.moveInDetail.updateShowActionColumn();
                        this.signageDetail.updateShowActionColumn();
                    }
                    //get formatted date in user's time zone set for his profile 
                    this.model.activityLogs.map((item) => {
                        item.createdOn = this.appUtils.getFormattedDateTimeFull(item.createdOn);
                    });
                    this.isDataLoaded = true;
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    applyChangesToKiosk() {
        this.blockUI.start();
        this.kioskService.applyChangesToKiosk(this.model.registrationKey)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.toastr.success('Changes has been applied successfully');
                    this.loadKioskDetail();
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    ngOnDestroy() {
        this.signalREventSubscription.unsubscribe();
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.showActionColumn = false;
                    this.moveInDetail.updateShowActionColumn();
                    this.signageDetail.updateShowActionColumn();
                }
            }
        );
    }

    editTag() {
        this.kioskEditTagModal.openModal(this.model.tag);
    }

    saveTag(tag: string) {
        let kioskTagAddModel = new KioskTagAddModel();
        kioskTagAddModel.id = this.model.id;
        kioskTagAddModel.tag = tag;
        kioskTagAddModel.registrationKey = this.model.registrationKey;
        this.blockUI.start();
        this.kioskService.saveTag(kioskTagAddModel).subscribe(() => {
            this.model.tag = tag;
            this.toastr.success('Tag has been changed successfully.');
            this.loadKioskDetail();
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
