<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Offline Kiosk Log
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                <li class="nav-item">
                    <button (click)="onViewRecordClicked()"
                        class="btn btn-label-primary btn-sm btn-bold m-r-5">
                        View Record
                    </button>
                </li>
                <li class="nav-item">
                    <select name="month" [(ngModel)]="selectedMonth" class="form-control form-control-sm"
                        (change)="loadSeriesData()">
                        <option *ngFor="let m of months" [value]="m.id">{{m.name}}</option>
                    </select>
                </li>
            </ul>
        </div>
    </div>
    <div class="kt-portlet__body" *blockUI="'container-blockui-tiles-kiosk-offline-status'">
        <div [chart]="chart" style="width: 100%;"></div>
    </div>
</div>