import { FacilityService, KioskService, MasterService } from '../../../services';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { KioskFilterModel } from 'src/models/kiosk/kiosk.filter.model';
import { SelectListItemModel } from 'src/models/select.list.item.model';
import { AppUtils } from 'src/helpers/app.utils';
import { Subscription } from 'rxjs/internal/Subscription';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-kiosk-filter-box',
    templateUrl: './kiosk.filter.box.component.html'
})
export class KioskFilterBoxComponent implements OnInit, OnDestroy {

    @Input() companyId: number;
    @Input() filterModel: KioskFilterModel = new KioskFilterModel();

    multiSelectDropdownConfigs = {};

    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    applicationTypes: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedFacilities : Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedApplicationTypes : Array<SelectListItemModel> = new Array<SelectListItemModel>();
    
    @Output() applyFilterEmitter = new EventEmitter<boolean>();
    @Output() resetFilterEmitter = new EventEmitter<boolean>();

    subscriptions: Subscription[] = [];

    constructor(private appUtils: AppUtils,
        private toastr: ToastrService,
        private facilityService: FacilityService,
        private kioskService: KioskService,
        private masterService: MasterService) {
        
        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit(): void {
        this.loadApplicationTypes();
        const subscription = this.masterService.WorkOrderShowPersonnalData$
            .subscribe(showPersonalData => this.loadFacilities(showPersonalData));
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    loadFacilities(showPersonalData: boolean) {
        this.facilityService.getSelectListItemsWithAssignedToWorkOrder(this.companyId, showPersonalData)
            .subscribe(
                (data: any) => {
                    this.facilities = data;
                    if (this.filterModel.facilityIds && this.filterModel.facilityIds.length > 0 && !this.facilities.find(f => this.filterModel.facilityIds.includes(f.id))) {
                        this.filterModel.facilityIds = [];
                    }
                    this.applyFilter(false);
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadApplicationTypes() {
        this.kioskService.getApplicationTypes()
            .subscribe(
                (data: any) => {
                    this.applicationTypes = data.map((x) => ({id: x.id.toString(), name: x.name, key: x.name, value: x.id.toString()}));
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    loadFilter(){
        this.selectedFacilities = this.facilities.filter(x => this.filterModel.facilityIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });

        this.selectedApplicationTypes = this.applicationTypes.filter(x => this.filterModel.applicationIds.indexOf(x.id) != -1)
                .map((y) => { return { id: y.id, name: y.name } as SelectListItemModel; });
    }

    resetFilter(isManualTrigger: boolean) {
        this.filterModel.facilityIds = [];
        this.filterModel.applicationIds = [];
        this.filterModel.filterKey = null;
        this.selectedFacilities = [];
        this.selectedApplicationTypes = [];
        this.resetFilterEmitter.next(isManualTrigger);  
        this.applyFilterEmitter.next(isManualTrigger);
    }

    applyFilter(isManualTrigger: boolean) {
        if(isManualTrigger)
        {
            this.filterModel.applicationIds = [];
            this.filterModel.facilityIds = [];
            this.selectedApplicationTypes.map((item) => {
                this.filterModel.applicationIds.push(item.id)
            });
            this.selectedFacilities.map((item) => {
                this.filterModel.facilityIds.push(item.id)
            });
        }
        this.applyFilterEmitter.next(isManualTrigger);
    }
}






