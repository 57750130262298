import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../../services';

@Component({
    selector: 'app-company-admin-tile',
    templateUrl: './company.admin.tile.component.html'
})

export class CompanyAdminTileComponent implements OnInit {
    @Input() companyId: number;
    isModelLoaded = false;
    totalAdmins = 0;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.getCompanyAdministratorsCount(this.companyId)
            .subscribe(
                (data: any) => {
                    this.isModelLoaded = true;
                    this.totalAdmins = data;
                },
                error => {
                    console.log('CompanyAdminTileComponent laod failed');
                    console.log(error);
                });
    }
}
