import { Component, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { UnitAddModel } from '../../../models';

import { UnitService, FacilityService } from '../../../services';

import { AppUtils, RoleMatrix } from '../../../helpers';

@Component({
    selector: 'app-unit-add',
    templateUrl: './unit.add.component.html'
})

export class UnitAddComponent {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    model: UnitAddModel = new UnitAddModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private unitService: UnitService,
        private appUtils: AppUtils,
        private facilityService: FacilityService) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddFacility) {
            this.router.navigate(['/error/403']);

        }
        this.route.params.subscribe((params) => {
            this.model.companyId = params['companyId'];
            this.model.facilityId = params['facilityId'];
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.model.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }
        this.checkFacilityStatus();
    }

    checkFacilityStatus() {
        this.facilityService.getStatus(this.model.facilityId).subscribe(
            data => {
                if (data === 3) {
                    this.router.navigate(['/error/404']);
                }
            },
            error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }
        );
    }

    submit() {
        this.blockUI.start();
        this.unitService.add(this.model)
            .subscribe(
                data => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/unit/detail/', this.model.companyId, this.model.facilityId, data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Unit has been added successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }
}
