<div class="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  <div class="kt-subheader   kt-grid__item" id="kt_subheader">
    <div class="kt-subheader__main">
      <app-breadcrumb-user-group-detail [companyId]="companyId">
      </app-breadcrumb-user-group-detail>
    </div>
    <div class="kt-subheader__toolbar">
      <div class="kt-subheader__wrapper">
      </div>
    </div>
  </div>
  <div class="kt-content kt-grid__item kt-grid__item--fluid">
    <div class="col-md-12">
      <div class="kt-portlet kt-portlet--mobile">

        <div class="kt-portlet__head kt-portlet__head--lg">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              User Group Detail
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar" *ngIf="showActionColumn">
            <button type="button" class="btn btn-label-primary btn-sm btn-bold dropdown-toggle" data-toggle="dropdown">
              Action
            </button>
            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
              <ul class="kt-nav">
                <li class="kt-nav__item" *ngIf="roleMatrix.canEditUserGroup &&showActionColumn">
                  <a [routerLink]="['/company', companyId, 'user-group', groupId, 'edit']" class="kt-nav__link">
                    <em class="kt-nav__link-icon fa fa-edit"></em>
                    <span class="kt-nav__link-text">Edit</span>
                  </a>
                </li>
                <li class="kt-nav__item" *ngIf="roleMatrix.canDeleteUserGroup && showActionColumn">
                  <a (click)="challengeDelete()" class="kt-nav__link">
                    <em class="kt-nav__link-icon fa fa-trash"></em>
                    <span class="kt-nav__link-text">
                      Delete
                    </span>
                  </a>
                </li>
                <li class="kt-nav__item" *ngIf="roleMatrix.canChangeStatusOfUserGroup && isActive">
                  <a (click)="toggleStatus()" class="kt-nav__link">
                    <em class="kt-nav__link-icon fa fa-ban"></em>
                    <span class="kt-nav__link-text">
                      Deactivate
                    </span>
                  </a>
                </li>
                <li class="kt-nav__item" *ngIf="roleMatrix.canChangeStatusOfUserGroup && !isActive">
                  <a (click)="toggleStatus()" class="kt-nav__link">
                    <em class="kt-nav__link-icon fa fa-check"></em>
                    <span class="kt-nav__link-text">
                      Activate
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="kt-portlet__body" *blockUI="'container-blockui-add-user'">
          <div class="form-group row">
            <label class="col-md-3">Group Name:</label>
            <label class="col-md-6">{{model.name}}</label>
          </div>
          <div class="form-group row">
            <label class="col-md-3">Member Assigned:</label>
            <div class="col-md-6">
              <p class="kt-portlet__data">
                <span class="kt-badge kt-badge--primary kt-badge--inline m-r-5 m-b-5"
                  *ngFor="let item of model.members">
                  {{item.userName}}
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <span class="kt-font-bold">Work Orders Settings</span>
                <div class="kt-divider m-t-5 m-b-25">
                    <span></span>
                </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3">Reminder Interval for low priority work orders:</label>
            <label class="col-md-6">{{model.lowPriorityWorkOrdersReminderInterval}}
              <span *ngIf="model.lowPriorityWorkOrdersReminderInterval > 1"> days</span>
              <span *ngIf="model.lowPriorityWorkOrdersReminderInterval == 1"> day</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-3">Reminder Interval for medium priority work orders:</label>
            <label class="col-md-6">{{model.mediumPriorityWorkOrdersReminderInterval}}
              <span *ngIf="model.mediumPriorityWorkOrdersReminderInterval > 1"> days</span>
              <span *ngIf="model.mediumPriorityWorkOrdersReminderInterval == 1"> day</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-3">Reminder Interval for high priority work orders:</label>
            <label class="col-md-6">{{model.highPriorityWorkOrdersReminderInterval}}
              <span *ngIf="model.highPriorityWorkOrdersReminderInterval > 1"> days</span>
              <span *ngIf="model.highPriorityWorkOrdersReminderInterval == 1"> day</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-user-group-challenge-delete #userGroupChallengeDeleteModal (delete)="delete($event)">
</app-user-group-challenge-delete>

<app-user-group-challenge-deactivate #userGroupChallengeDeactivateModal (deactivate)="initiateToggleStatus($event)">
</app-user-group-challenge-deactivate>