import { ActivityLogActionTypeEnum } from "src/helpers";

export class KioskDetailModel {
    public id: number;
    public registrationKey: string;
    public facilityId: string;
    public facilityName: string;
    public facilityAddress: string;
    public applicationType: number;
    public tag: string;
    public status: number;
    public isOnline: boolean;
    public createdOn: string;
    public updatedOn: string;
    public activityLogs: Array<KioskActivityLogModel>;
}

export class KioskTagAddModel {
    public id: number;
    public tag: string;
    public registrationKey: string;
}

export class KioskActivityLogModel {
    public createdOn: string;
    public createdByName: string;
    public amenityId : number
    public configurationId : number
    public fieldDetailId : number
    public signageId : number
    public kioskId : number
    public activityType: ActivityLogActionTypeEnum;
    public activityDetails: Array<KioskActivityDetailModel>;

    constructor() {
        this.activityDetails = new Array<KioskActivityDetailModel>();
    }
}

export class KioskActivityDetailModel {
    public fieldName: string;
    public oldValue: string;
    public newValue: string;
}
