import { Component, HostBinding, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { SelectListItemModel, VendorAddModel, VendorImageUploadModel, VendorInsurancePolicyDocumentModel } from '../../../models';
import { VendorService, CompanyService, FacilityService, CloudStorageService } from '../../../services';
import { AppUtils, RoleMatrix, AppSettings } from '../../../helpers';
import { BlobService, UploadParams, UploadConfig } from 'angular-azure-blob-service';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-vendor-add',
    templateUrl: './vendor.add.component.html'
})

export class VendorAddComponent implements OnInit {
    roleMatrix: RoleMatrix;
    @HostBinding('class.full_width') full_width = true;
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('vendorInsuranceDocumentAttachment') vendorInsuranceDocumentAttachment: ElementRef;

    companyId = -1;
    vendorTypes: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    model: VendorAddModel = new VendorAddModel();
    imageUploadModel: VendorImageUploadModel = new VendorImageUploadModel();

    multiSelectDropdownConfigs = {};
    facilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();
    selectedFacilities: Array<SelectListItemModel> = new Array<SelectListItemModel>();

    minDueDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };
    uploadedPercent = 0;
    config: UploadConfig;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private vendorService: VendorService,
        private cloudStorageService: CloudStorageService,
        private appSettings: AppSettings,
        private blobService: BlobService,
        private appUtils: AppUtils,
        private facilityService: FacilityService,
        private companyService: CompanyService) {
        this.roleMatrix = new RoleMatrix();

        if (!this.roleMatrix.canAddVendor) {
            this.router.navigate(['/error/403']);
        }

        this.imageUploadModel.canEditProfileImage = this.roleMatrix.canAddVendor;

        this.route.params.subscribe((params) => {
            this.companyId = params['companyId'];
            this.model.companyId = this.companyId;
        });

        if (!this.roleMatrix.is10FedUser
            && this.roleMatrix.companyId !== this.companyId.toString()) {
            this.router.navigate(['/error/404']);
        }

        this.multiSelectDropdownConfigs = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnInit() {
        this.checkCompanyStatus();
        this.loadFacilities();
        this.loadVendorTypes();
    }

    loadFacilities() {
        this.facilityService.getSelectListItems(this.companyId)
            .subscribe(
                (data: any) => {
                    this.facilities = data;
                },
                error => {
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    checkCompanyStatus() {
        this.companyService.getStatus(this.companyId).subscribe(
            data => {
                if (data === 3) {
                    this.router.navigate(['/error/404']);
                }
            },
            error => {
                this.appUtils.ProcessErrorResponse(this.toastr, error);
            }
        );
    }

    loadVendorTypes() {
        this.blockUI.start();
        this.vendorService.getTypeSelectItems()
            .subscribe(
                (data: any) => {
                    this.blockUI.stop();
                    Object.assign(this.vendorTypes, data);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    submit() {
        //Add Selected Facilities
        this.model.assignedFacilities = new Array<number>();
        if (this.selectedFacilities && this.selectedFacilities.length > 0) {
            this.model.assignedFacilities = this.selectedFacilities.map(item => +item.id);
        }

        this.model.profileImage = this.imageUploadModel.fileName;
        this.blockUI.start();
        this.vendorService.add(this.model)
            .subscribe(
                data => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/vendor/detail', this.companyId, data]);
                    }, 10);
                    setTimeout(() => {
                        this.toastr.success('Vendor has been added successfully');
                    }, 100);
                },
                error => {
                    this.blockUI.stop();
                    this.appUtils.ProcessErrorResponse(this.toastr, error);
                });
    }

    onAttachmentSelect(event: any) {
        if (!event.target.files
            || event.target.files.length === 0) {
            return;
        }

        // if (this.model.insurancePolicyDocuments.length >= 5) {
        //     this.toastr.error('Maximum 5 documents can be uploaded');
        //     return;
        // }

        let attachment = new VendorInsurancePolicyDocumentModel();
        attachment.originalFileName = event.target.files[0].name;
        const file = event.target.files.item(0);
        
        this.blockUI.start();
        this.cloudStorageService.getSasToken().subscribe(async (data) => {
            const containerName = 'vendor';
            const CloudConfig: UploadParams = {
                sas: data.toString(),
                storageAccount: this.appSettings.AzureAccount,
                containerName: containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(CloudConfig, fileName);
            AppSettings.NonBearerUrl = blobUrl;

            this.config = {
                baseUrl: `${blobUrl}?`,
                sasToken: CloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    attachment.fileName = fileName;
                    this.cloudStorageService.getPublicUrl(containerName, fileName)
                        .subscribe(res => {
                            attachment.fileUrl = res.toString();
                        });
                    this.model.insurancePolicyDocuments.push(attachment);
                    this.blockUI.stop();
                    this.vendorInsuranceDocumentAttachment.nativeElement.value = null;
                },
                error: (err) => {
                    console.log(err);
                    this.appUtils.ProcessErrorResponse(this.toastr, 'File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(this.config);
            });
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }

    deleteAttachment(fileKey: string, index: number) {
        this.blockUI.start();
        this.vendorService.deleteAttachment(fileKey).subscribe(() => {
            this.model.insurancePolicyDocuments.splice(index, 1);
        }, error => {
            this.appUtils.ProcessErrorResponse(this.toastr, error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
